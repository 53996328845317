import { createSelector } from "reselect";
import { isEmpty, first } from 'lodash'
import { AppState } from "../index";
import { CurrencyId, CurrencyRequests, Employee } from "./types";
import { CURRENCY_EUR, CURRENCY_RUB, CURRENCY_USD } from "../../constants/currencyCodes";

export const getBatchOperations = (state: AppState) => state.batchOperations.batchOperations.data;
export const getBatchOperationsLoading = (state: AppState) => state.batchOperations.batchOperations.loading;

export const getCurrencyId = (_: any, currencyId: CurrencyId) => currencyId;

export const haveBatchOperations = createSelector(
  getBatchOperations,
  (items: CurrencyRequests[]) => {
    const filtredItems = items.filter((item: CurrencyRequests) => item.Requests.length > 1);

    return !isEmpty(filtredItems);
  }
);

export const getRubBatchOperations = createSelector(
  getBatchOperations,
  (items: CurrencyRequests[]) => {
    const filtredData = items.filter((item: CurrencyRequests) => item.Currency === CURRENCY_RUB);

    return first(filtredData);
  }
);

export const getUsdBatchOperations = createSelector(
  getBatchOperations,
  (items: CurrencyRequests[]) => {
    const filtredData = items.filter((item: CurrencyRequests) => item.Currency === CURRENCY_USD);

    return first(filtredData);
  }
);

export const getEurBatchOperations = createSelector(
  getBatchOperations,
  (items: CurrencyRequests[]) => {
    const filtredData = items.filter((item: CurrencyRequests) => item.Currency === CURRENCY_EUR);

    return first(filtredData);
  }
);

export const getBatchOperationsByCurrency = createSelector(
  getBatchOperations,
  getCurrencyId,
  (items: CurrencyRequests[], currencyId: CurrencyId) => {
    const filtredData = items.filter((item: CurrencyRequests) => item.Currency === currencyId);

    return first(filtredData);
  }
);


export const getEmployees = (state: AppState) => state.batchOperations.employees.data;
export const getEmployeesLoading = (state: AppState) => state.batchOperations.employees.loading;

export const getEmployeesConfigureData = createSelector(
  getEmployees,
  (items: Employee[]) => items.map((item) => ([item.FullName, item.Id]))
);

export const getSubmitMessage = (state: AppState) => state.batchOperations.message;
export const getSubmitLoading = (state: AppState) => state.batchOperations.submiting;