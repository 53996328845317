import * as React from 'react';
import { FormContainer } from '../../modules/Clients/components/Views';
import FormItem from './FormItem';
import { FieldType } from '../../modules/Clients/types';
import RegionsProvider from '../RegionsProvider';

export interface FormProps<T> {
  data: T;
  fields: Map<FieldType<T>, string>;
  disabled?: boolean;
  onChange?: (fieldName: FieldType<T>, value: any) => void;
  requiredFields?: Array<FieldType<T>>;
  disableFields?: Array<FieldType<T>>;
  requiredFieldsError?: Array<FieldType<T>>;
  valueLabels?: {
    [key: string]: Map<number, string>;
  };
}

export default class Form<T> extends React.PureComponent<FormProps<T>> {
  public render() {
    const {
      data, disabled, fields, requiredFields, requiredFieldsError, valueLabels, disableFields
    } = this.props;

    const regionData = getRegionData(data);

    return (
      <FormContainer>
        {data && (
          <RegionsProvider {...regionData}>
            {regionLabels =>
              [...fields].map(([fieldName, label]) => (
                <FormItem
                  key={fieldName.toString()}
                  disabled={disabled || (Array.isArray(disableFields) && disableFields.indexOf(fieldName) > -1)}
                  fieldName={fieldName}
                  data={data}
                  label={label}
                  isRequired={
                    Array.isArray(requiredFields) && requiredFields.indexOf(fieldName) > -1
                  }
                  error={
                    Array.isArray(requiredFieldsError) &&
                    requiredFieldsError.indexOf(fieldName) > -1
                  }
                  valueLabels={valueLabels}
                  onChange={this.props.onChange}
                  regionLabels={regionLabels}
                  regionData={regionData}
                />
              ))
            }
          </RegionsProvider>
        )}
      </FormContainer>
    );
  }
}

const getRegionData = (data: any): {
countryId?: number;
regionId?: number;
cityId?: number;
} => {
  const result: {
  countryId?: number;
  regionId?: number;
  cityId?: number;
  } = {
    countryId: undefined,
    regionId: undefined,
    cityId: undefined,
  };

  if (typeof data !== 'object' || data === null) {
    return {};
  }

  if (data.hasOwnProperty('CountryId')) {
    result.countryId = Number(data.CountryId);
  }

  if (data.hasOwnProperty('RegionId')) {
    result.regionId = Number(data.RegionId);
  }
  if (data.hasOwnProperty('CityId')) {
    result.cityId = Number(data.CityId);
  }

  return result;
};
