import * as React from "react";
import styled from "../../styled/styled-components";
import Spinner from "../Spinner";
import { prop } from "styled-tools";

export interface LoadingOverlayProps
  extends React.HTMLAttributes<HTMLDivElement> {
  loading?: boolean;
  size?: React.CSSProperties["width"];
}

const Container = styled.div`
  position: relative;
`;

const StyledSpinner = styled(Spinner)``;

const View: React.SFC<{ size: React.CSSProperties["width"] }> = ({
  size,
  ...props
}) => <div {...props} />;

const StyledView = styled(View)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.35);
  transition: background 0.2s easy-in-out;

  ${StyledSpinner} {
    width: ${prop("size", "80px")};
    height: ${prop("size", "80px")};
  }
`;

const LoadingOverlay: React.SFC<LoadingOverlayProps> = React.memo(
  ({ loading, size, children, ...props }) => (
    <Container {...props}>
      {children}
      {loading && (
        <StyledView size={size}>
          <StyledSpinner active />
        </StyledView>
      )}
    </Container>
  )
);

export default LoadingOverlay;
