import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
`;
const THead = styled.thead`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
`;
const TBody = styled.tbody``;
const Td = styled.td`
  height: 2rem;
  min-height: 30px;
  padding: 5px;
  text-align: center;

  border-right: 1px solid ${({ theme }) => theme.colors.lightGray};
  &:last-child {
    border-right: none;
  }
`;
const Th = styled.th`
  width: auto;
  min-width: 50px;
  height: 2rem;
  min-height: 30px;
  padding: 5px;
  text-align: center;

  border-right: 1px solid ${({ theme }) => theme.colors.lightGray};
  &:last-child {
    border-right: none;
  }
`;
const Tr = styled.tr``;

const TFoot = styled.tfoot`
  font-weight: bold;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

export { Table, THead, TBody, TFoot, Th, Tr, Td };
