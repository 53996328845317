import httpTransport from "../../../services/axios"; // todo di
import { Transport } from "../types";
import { DateStr } from "../../../types/DateStr";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

interface ConstructorParams {
  transport: Transport,
}

interface IRequestParams {
  currency?: number,
  periodType?: number,
  compare?: boolean,
  dateFrom?: DateStr | string,
  dateTo?: DateStr | string,
}

const removeUndefined = (obj: object) => (Object.keys(obj) as Array<keyof typeof obj>)
  .reduce((acc, key) => obj[key] === undefined ? acc : ({ ...acc, [key]: obj[key] }), {});

class RequestParams implements IRequestParams {
  public currency?: number;
  public periodType?: number;
  public compare?: boolean;
  public dateFrom?: DateStr;
  public dateTo?: DateStr;

  constructor(params: IRequestParams) {
    Object.assign(this, {
      periodType: 0,
      compare: false
    }, params);
  }

  public toPlainRequestParams() {
    return removeUndefined(this);
  }
}

const responseHandler = (result: any) => result.data;

class ApiClient {
  private transport: Transport;

  public constructor(params: ConstructorParams) {
    this.transport = params.transport;
  }

  public getSalaryAmount(rawParams: IRequestParams) {
    const params = new RequestParams(rawParams);

    return this.transport
      .get(`${API_ENDPOINT}/analytics/salary/amount`, { params: params.toPlainRequestParams() })
      .then(responseHandler);
  }

  public getSalaryPartners(rawParams: IRequestParams) {
    const params = new RequestParams(rawParams);

    return this.transport
      .get(`${API_ENDPOINT}/analytics/salary/partners`, { params: params.toPlainRequestParams() })
      .then(responseHandler);
  }

  public getSalaryBalance(rawParams: IRequestParams) {
    const params = new RequestParams(rawParams);

    return this.transport
      .get(`${API_ENDPOINT}/analytics/salary/balance`, { params: params.toPlainRequestParams() })
      .then(responseHandler);
  }

  public getSalaryGender(rawParams: IRequestParams) {
    const params = new RequestParams(rawParams);

    return this.transport
      .get(`${API_ENDPOINT}/analytics/salary/gender`, { params: params.toPlainRequestParams() })
      .then(responseHandler);
  }

  public getSalaryInactiveAccounting() {
    return this.transport
      .get(`${API_ENDPOINT}/analytics/salary/inactive?type=accounting`)
      .then(responseHandler);
  }

  public getSalaryInactiveRefill() {
    return this.transport
      .get(`${API_ENDPOINT}/analytics/salary/inactive?type=refill`)
      .then(responseHandler);
  }

  public getSalarySums(rawParams: IRequestParams) {
    const params = new RequestParams(rawParams);

    return this.transport
      .get(`${API_ENDPOINT}/analytics/salary/sums`, { params: params.toPlainRequestParams() })
      .then(responseHandler);
  }

  public getDepositsGender(rawParams: IRequestParams) {
    const params = new RequestParams(rawParams);

    return this.transport
      .get(`${API_ENDPOINT}/analytics/deposits/gender`, { params: params.toPlainRequestParams() })
      .then(responseHandler);
  }

  public getDepositsCurrencies(rawParams: IRequestParams) {
    const params = new RequestParams(rawParams);

    return this.transport
      .get(`${API_ENDPOINT}/analytics/deposits/currencies`, { params: params.toPlainRequestParams() })
      .then(responseHandler);
  }

  public getDepositsAverage(rawParams: IRequestParams) {
    const params = new RequestParams(rawParams);

    return this.transport
      .get(`${API_ENDPOINT}/analytics/deposits/average`, { params: params.toPlainRequestParams() })
      .then(responseHandler);
  }

  public getDepositsCount(rawParams: IRequestParams) {
    const params = new RequestParams(rawParams);

    return this.transport
      .get(`${API_ENDPOINT}/analytics/deposits/count`, { params: params.toPlainRequestParams() })
      .then(responseHandler);
  }

  public getDepositsDuration(rawParams: IRequestParams) {
    const params = new RequestParams(rawParams);

    return this.transport
      .get(`${API_ENDPOINT}/analytics/deposits/duration`, { params: params.toPlainRequestParams() })
      .then(responseHandler);
  }

  public getLoansGender(rawParams: IRequestParams) {
    const params = new RequestParams(rawParams);

    return this.transport
      .get(`${API_ENDPOINT}/analytics/loans/gender`, { params: params.toPlainRequestParams() })
      .then(responseHandler);
  }

  public getLoansCurrencies(rawParams: IRequestParams) {
    const params = new RequestParams(rawParams);

    return this.transport
      .get(`${API_ENDPOINT}/analytics/loans/currencies`, { params: params.toPlainRequestParams() })
      .then(responseHandler);
  }

  public getLoansAverage(rawParams: IRequestParams) {
    const params = new RequestParams(rawParams);

    return this.transport
      .get(`${API_ENDPOINT}/analytics/loans/average`, { params: params.toPlainRequestParams() })
      .then(responseHandler);
  }

  public getLoansCount(rawParams: IRequestParams) {
    const params = new RequestParams(rawParams);

    return this.transport
      .get(`${API_ENDPOINT}/analytics/loans/count`, { params: params.toPlainRequestParams() })
      .then(responseHandler);
  }

  public getLoansDuration(rawParams: IRequestParams) {
    const params = new RequestParams(rawParams);

    return this.transport
      .get(`${API_ENDPOINT}/analytics/deposits/duration`, { params: params.toPlainRequestParams() })
      .then(responseHandler);
  }
}

const AnalyticsApiClient = new ApiClient({ transport: httpTransport }); // todo di
export default AnalyticsApiClient;
