import { Reducer } from "redux";
import { ActionTypes } from "./actions";

const initialState = {
  search: {
    loading: false,
    data: undefined,
    error: null,
  },
  loanForm: {
    form: undefined,
    products: {
      data: undefined,
      loading: false,
      error: null,
    },
    conditions: {
      data: undefined,
      loading: false,
      error: null,
    }
  },
  tableForm: {
    activeRow: undefined,
  }
};

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SEARCH_PARTNERS_REQUESTS:
      return {
        ...state,
        search: {
          loading: true,
          data: undefined,
          error: null,
        }
      };
    case ActionTypes.SEARCH_PARTNERS_SUCCESS:
      return {
        ...state,
        search: {
          loading: false,
          data: action.payload,
        }
      };
    case ActionTypes.SEARCH_PARTNERS_ERROR:
      return {
        ...state,
        search: {
          loading: false,
          data: undefined,
          error: action.payload,
        }
      };


    case ActionTypes.GET_LOAN_PRODUCTS_REQUESTS:
      return {
        ...state,
        loanForm: {
          ...state.loanForm,
          products: {
            ...state.loanForm.products,
            data: undefined,
            loading: true,
            error: null,
          }
        }
      };
    case ActionTypes.GET_LOAN_PRODUCTS_SUCCESS:
      return {
        ...state,
        loanForm: {
          ...state.loanForm,
          products: {
            ...state.loanForm.products,
            data: action.payload,
            loading: false,
            error: null,
          },
          form: {
            ...state.loanForm.form,
            ...action.payload,
          },
        }
      };
    case ActionTypes.GET_LOAN_PRODUCTS_ERROR:
      return {
        ...state,
        loanForm: {
          ...state.loanForm,
          products: {
            ...state.loanForm.products,
            data: undefined,
            loading: false,
            error: action.payload,
          }
        }
      };
    case ActionTypes.CLEAN_LOAN_PRODUCTS:
      return {
        ...state,
        loanForm: {
          ...state.loanForm,
          products: {
            ...state.loanForm.products,
            data: undefined
          }

        }
      };

    case ActionTypes.GET_LOAN_PRODUCT_CONDITIONS_REQUESTS:
      return {
        ...state,
        loanForm: {
          ...state.loanForm,
          conditions: {
            ...state.loanForm.conditions,
            data: undefined,
            loading: true,
            error: null,
          }
        }
      };
    case ActionTypes.GET_LOAN_PRODUCT_CONDITIONS_SUCCESS:
      return {
        ...state,
        loanForm: {
          ...state.loanForm,
          conditions: {
            ...state.loanForm.conditions,
            data: action.payload,
            loading: false,
            error: null,
          },
        }
      };
    case ActionTypes.GET_LOAN_PRODUCT_CONDITIONS_ERROR:
      return {
        ...state,
        loanForm: {
          ...state.loanForm,
          conditions: {
            ...state.loanForm.conditions,
            data: undefined,
            loading: false,
            error: action.payload,
          }
        }
      };

    case ActionTypes.UPDATE_ACTIVE_ROW:
      return {
        ...state,
        tableForm: {
          ...state.tableForm,
          activeRow: action.payload,
        }
      };
    case ActionTypes.CLEAR_ACTIVE_ROW:
      return {
        ...state,
        tableForm: {
          ...state.tableForm,
          activeRow: undefined,
        }
      };
    default:
      return state;
  }
};

export { reducer as formsReducer };
