import * as React from "react";
import styled from "styled-components";
import Button, { ButtonProps } from "../../../components/Button/Button";
import { Heading } from "../../../components/Typography";
import BackLink from "../../../components/BackLink";

const Container = styled.header`
  margin: -24px -24px 0;
  padding: 14px 15px 7px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #e7eaec;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const HeaderButton = styled(Button)`
  margin-left: 5px;
`;

const ListHeader: React.SFC<{
  printButton?: React.ReactNode;
}> = React.memo(({ printButton }) => (
  <Container>
    <BackLink title={document.title} >
      <Heading>Заявки</Heading>
    </BackLink>
    <ButtonsWrapper>
      <HeaderButton href="/applications/create" routerLink type="success">
        Новый клиент
      </HeaderButton>
      {printButton}
    </ButtonsWrapper>
  </Container>
));

export class PrintButton extends React.Component<ButtonProps> {
  public render() {
    return (
      <HeaderButton {...this.props} type="success">
        Печать
      </HeaderButton>
    );
  }
}

export default ListHeader;
