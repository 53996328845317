import React, { Component } from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';

import apiClient from '../../services/api';
import CheckResultsTable from './CheckResultsTable/CheckResultsTable';
import UploadButton from '../../components/UploadButton/UploadButton';
import Pagination from '../../components/Pagination/Pagination';
import LoadingOverlay from '../../components/LoadingOverlay';

export default class Finmonitoring extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      lastCheckResults: [],
      lastCheckResultsCount: 0,
      lastCheckResultsStatus: 'ok',
      lastCheckResultsDate: '',

      checkResults: [],
      checkStatus: 'ok',

      pagination: {
        offset: 1,
        limit: 10,
      },
    };
  }

  componentDidMount() {
    this.loadCheckResults();
  }

  successCheck(resultRaw) {
    try {
      let result;
      if (typeof resultRaw === 'object') {
        result = resultRaw;
      } else {
        result = {
          Concurrences: [],
        };
      }

      result.Date = moment().format('DD.MM.YYYY');
      this.setState({
        lastCheckResults: [result].concat(this.state.lastCheckResults),
        lastCheckResultsCount: this.state.lastCheckResultsCount + 1,
        lastCheckResultsStatus: 'ok',
        lastCheckResultsDate: result.Date,

        checkStatus: 'ok',
        checkResults: result,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async loadCheckResults() {
    try {
      this.setState({ lastCheckResultsStatus: 'waiting' });
      const result = await apiClient.getLastCheckResultWeapons(this.state.pagination);
      const { Count = 0, Checks = [], LastCheck } = result;

      this.setState({
        lastCheckResults: Checks,
        lastCheckResultsCount: Count,
        lastCheckResultsStatus: 'ok',
        lastCheckResultsDate: LastCheck,
      });
    } catch (e) {
      this.setState({
        lastCheckResults: [],
        lastCheckResultsCount: 0,
        lastCheckResultsStatus: 'error',
        lastCheckResultsDate: '',
      });
    }
  }

  changePage(page) {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          offset: page,
        },
      },
      () => this.loadCheckResults(),
    );
  }

  render() {
    const {
      lastCheckResults,
      lastCheckResultsCount,
      lastCheckResultsStatus,
      lastCheckResultsDate,
      checkStatus,

      pagination: { offset, limit },
    } = this.state;

    return (
      <LoadingOverlay loading={checkStatus === 'waiting' || lastCheckResultsStatus === 'waiting'}>
        <div className="finmonitoring__content">
          <hr />
          <div className="row">
            <div className="col-7">
              <UploadButton
                name="Загрузить XML файл"
                actionUpload={apiClient.uploadFileForCheckWeapons}
                onUploading={() => this.setState({ checkStatus: 'waiting' })}
                onSuccess={this.successCheck}
                onError={() => this.setState({ checkStatus: 'error' })}
              />
            </div>
            <div className="col-5">
                <span>
                  Последняя проверка: <span>{lastCheckResultsDate}</span>
                </span>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-12">
              <h5>Результаты предыдущих проверок ({lastCheckResultsCount}):</h5>
              <CheckResultsTable results={lastCheckResults} status={lastCheckResultsStatus} />
              <Pagination
                page={offset}
                size={limit}
                count={lastCheckResultsCount}
                callback={this.changePage}
              />
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}
