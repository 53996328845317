import React, { PureComponent, Fragment } from "react";
import styled from "../../styled/styled-components";
import Modal from "../../components/Modal";
import DatePicker from "../../components/DatePicker/DatePicker";
import { convertToUtc, parseServerDate } from "../../utils";
import { NONE_VALUE } from "../../constants";

import moment from 'moment';
import Button from "../Button/Button";
import PrintButton from "../PrintButton/PrintButton";

export interface PrintAccountStatementProps {
  BillCode: string;
}

export interface PrintAccountStatementState {
  showModal: boolean;
  dateFrom?: string;
  dateTo?: string;
}

const Buttons = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

export default class PrintAccountStatement extends PureComponent<PrintAccountStatementProps, PrintAccountStatementState> {

  public state = {
    showModal: false,
    dateFrom: moment().subtract(1, 'years').format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD'),
  };


  public render() {
    const { dateFrom, dateTo, showModal } = this.state;
    const { BillCode } = this.props;
    return (
      <Fragment>
        <Button type="success" className='d-block w-100' onClick={this.toggleModal}>
          Выписка по счету на русском языке
        </Button>
        <Modal
          header="Важно! По умолчанию берётся период за год!"
          isOpen={showModal}
          onBackgroundClick={this.toggleModal}
          onEscapeKeydown={this.toggleModal}
        >
          <div className='row align-items-center ml-2'>
            За период с
            <DatePicker useStyledInput style={{'width': '150px', 'marginLeft': '5px', 'marginRight': '5px'}} onChange={this.privateChangeDateFrom} selected={dateFrom && typeof dateFrom === 'string' ? parseServerDate(dateFrom) : null} />
            по
            <DatePicker useStyledInput style={{'width': '150px', 'marginLeft': '5px', 'marginRight': '5px'}} onChange={this.privateChangeDateTo} selected={dateTo && typeof dateTo === 'string' ? parseServerDate(dateTo) : null} />
          </div>
          <Buttons>
            <PrintButton
              type="success"
              href={`/template/BillOperationsRussian/${BillCode}?dateFrom=${dateFrom}&dateTo=${dateTo}`}
            >
              Печать
            </PrintButton>
          </Buttons>
        </Modal>
      </Fragment>
    );
  }


  private toggleModal = () => {
    this.setState((state) => ({
      showModal: !state.showModal,
    }))
  };

  private privateChangeDateFrom = (date: Date, event: any) => {
    if (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value)) {
      const value = date ? convertToUtc(date).toISOString() : undefined;
      this.setState({
        'dateFrom': value === NONE_VALUE || value === "" ? undefined : value,
      });
    }
  };

  private privateChangeDateTo = (date: Date, event: any) => {
    if (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value)) {
      const value = date ? convertToUtc(date).toISOString() : undefined;
      this.setState({
        'dateTo': value === NONE_VALUE || value === "" ? undefined : value,
      });
    }
  };
}
