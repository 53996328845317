import styled from "styled-components";
import { ifProp, prop } from "styled-tools";
import { css } from "../../styled/styled-components";

export const Table = styled.table`
  width: 100%;
  font-size: 13px;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
`;

export const TableBody = styled.tbody``;

export const TableHead = styled.thead`
  border-bottom: 2px solid #dee2e6;
`;

export interface TableRowProps {
  notActive?: boolean;
}

export const TableRow = styled.tr<TableRowProps>`
      ${ifProp('notActive', css`
      background-color: #f8d7da;
  `)}
`;

export const TableCell = styled.td`
  position: relative;
  border-bottom: 1px solid #dee2e6;
  padding: 5px;
  text-align: ${prop("align", "left")};
`;

export const TableCellHead = styled(TableCell)`
  text-align: ${prop("align", "center")};
  font-size: 12px;
  border-top: 1px solid #dee2e6;
  font-weight: bolder;
  padding-bottom: 10px;
`;

export default {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableCellHead
};
