import React, { Fragment } from 'react';
import { PartnerBankAccount } from '../../../types';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import { Table, TableBody, TableCell, TableCellHead, TableHead, TableRow } from '../../../../../components/Table';
import Button from '../../../../../components/Button/Button';
import CommentField, { onChangeCommentField } from './CommentField';
import { Select, SelectOption, SimpleMaskedInput } from '../../../../../components/FormControls/FormControls';
import { CurrencyId } from '../../../../../types';

const PARTNER_BANK_ACCOUNTS_FIELDS = new Map<string, string>([
  ['Account', 'Номер счета'],
  ['Currency', 'Валюта'],
  ['Comment', 'Комментарий']
]);

const CURRENCIES = [
  'Не указано',
  'Рубли',
  'Доллары США',
  'Евро',
];

interface AccountFieldProps {
  editing: boolean;
  data: PartnerBankAccount,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const AccountField = ({ editing, data, onChange }: AccountFieldProps) => (
  editing
    ? (
      <SimpleMaskedInput
        onChange={onChange}
        value={data.Bill}
        mask="99999999999999999999"
      />
    )
    : (
      <span>{data.Bill}</span>
    )
);

interface CurrencyFieldProps {
  editing: boolean;
  data: PartnerBankAccount,
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const CurrencyField = ({ editing, data, onChange }: CurrencyFieldProps) => (
  editing
    ? (
      <Select value={data.CurrencyId} onChange={onChange}>
        {CURRENCIES.map((label, index) => (
          <SelectOption key={index} value={index}>{label}</SelectOption>
        ))}
      </Select>
    )
    : (
      <span>{CURRENCIES[data.CurrencyId]}</span>
    )
);

export interface AccountsTableProps {
  data: PartnerBankAccount[];
  editing: boolean;
  onChange: (accounts: PartnerBankAccount[]) => void;
}

export default function AccountsTable(props: AccountsTableProps) {
  const { data, editing, onChange } = props;

  const confirmDialogRef = React.createRef<ConfirmDialog>();

  const deleteRow = (index: number) => () => {
    if (!data[index]) {
      return;
    }

    if (!confirmDialogRef.current) {
      return;
    }

    const deleteItem = () => {
      const newCards = data.filter(
        (_item, _index) => _index !== index
      );
      onChange(newCards);
    };

    if (data[index].Id === null) {
      deleteItem();
    } else {
      confirmDialogRef.current.open(
        'Вы действительно хотите удалить выбранный элемент?',
        (allow: boolean) => allow && deleteItem()
      );
    }
  };

  const handleChangeAccount = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newData = [...data];
    newData[index].Bill = event.currentTarget.value;
    onChange(newData);
  };

  const handleChangeCurrency = (index: number) => (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newData = [...data];
    newData[index].CurrencyId = parseInt(event.currentTarget.value, 10) as CurrencyId;
    onChange(newData);
  };

  const handleChangeComment = onChangeCommentField<PartnerBankAccount>(data, onChange);

  return (
    <Fragment>
      <Table>
        <TableHead>
          <TableRow>
            {[...PARTNER_BANK_ACCOUNTS_FIELDS.entries()].map(([fieldName, label]) => (
              <TableCellHead key={fieldName}>
                {label}
              </TableCellHead>
            ))}
            {editing && <TableCellHead>Действие</TableCellHead>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={item.Id || -index}>
              <TableCell align="center">
                <AccountField
                  editing={editing}
                  data={item}
                  onChange={handleChangeAccount(index)}
                />
              </TableCell>
              <TableCell align="center">
                <CurrencyField
                  editing={editing}
                  data={item}
                  onChange={handleChangeCurrency(index)}
                />
              </TableCell>
              <TableCell align="center">
                <CommentField editing={editing} data={item} onChange={handleChangeComment(index)} />
              </TableCell>
              {editing && (
                <TableCell align="center">
                  <Button
                    onClick={deleteRow(index)}
                    type="success"
                  >
                    Удалить
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmDialog ref={confirmDialogRef} />
    </Fragment>
  );
}
