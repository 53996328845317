import * as React from "react";
import { PartnerContext } from "../../pages/Partner";
import Panel from "../../../../components/Panel";
import { FormWrapper, FormColumn } from "../Views";
import {
  ADDRESS_FIELDS,
  REQUIRED_FIELDS
} from "../../constants";
import Form from "../../../../components/PartnersAndClients/Form";
import Button from "../../../../components/Button/Button";
import styled from "../../../../styled/styled-components";

const CopyButton = styled(Button)`
  display: block;
  margin: 0 0 0 auto;
`;

export default class AddressTab extends React.PureComponent {
  public render() {
    return (
      <PartnerContext.Consumer>
        {({
          editing,
          userData,
          handleChange = () => void 0,
          errors,
          valueLabels,
          copyAddress = () => void 0
        }) => (
          <FormWrapper>
            <FormColumn>
              <Panel type="primary" heading={"Адрес регистрации"}>
                {userData && (
                  <Form
                    data={userData.PartnerAddressRegistration}
                    fields={ADDRESS_FIELDS}
                    disabled={!editing}
                    onChange={handleChange.bind(
                      null,
                      "PartnerAddressRegistration"
                    )}
                    requiredFields={REQUIRED_FIELDS.get("PartnerAddress")}
                    requiredFieldsError={errors.get("PartnerAddress")}
                    valueLabels={valueLabels}
                  />
                )}
                <CopyButton
                  type="success"
                  disabled={!editing}
                  onClick={copyAddress}
                >
                  Копировать в адрес для корреспонденции
                </CopyButton>
              </Panel>
            </FormColumn>
            <FormColumn>
              <Panel type="primary" heading={"Адрес для корреспонденции"}>
                {userData && (
                  <Form
                    data={userData.PartnerAddressMail}
                    fields={ADDRESS_FIELDS}
                    disabled={!editing}
                    onChange={handleChange.bind(null, "PartnerAddressMail")}
                    valueLabels={valueLabels}
                  />
                )}
              </Panel>
            </FormColumn>
          </FormWrapper>
        )}
      </PartnerContext.Consumer>
    );
  }
}
