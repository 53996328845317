import * as React from "react";
import { PartnerContext } from "../../pages/Product";
import { MAIN_FORM_FIELDS, REQUIRED_FIELDS, READONLY_FIELDS } from "../../constants";
import { FormColumn, FormWrapper } from "../Views";
import Form from "../../../../components/PartnersAndClients/Form";

export default class MainTab extends React.PureComponent {
  public render() {
    return (
      <PartnerContext.Consumer>
        {({
          editing,
          isModeEdit,
          productData,
          handleChange = () => void 0,
          errors,
          valueLabels
        }) => (
          <FormWrapper>
            {productData &&
              MAIN_FORM_FIELDS.map((fieldSet, index) => (
                <FormColumn key={index}>
                  <Form
                    data={productData}
                    fields={fieldSet}
                    disabled={!editing}
                    onChange={handleChange.bind(null, null)}
                    requiredFields={REQUIRED_FIELDS.get("productData")}
                    disableFields={isModeEdit ? READONLY_FIELDS.get("productData") : undefined}
                    requiredFieldsError={errors.get("productData")}
                    valueLabels={valueLabels}
                  />
                </FormColumn>
              ))}
          </FormWrapper>
        )}
      </PartnerContext.Consumer>
    );
  }
}
