import React from 'react';

import Modal from '../../../components/Modal';
import {
  CurrencyId,
} from "../../../store/batchOperations";
import BatchOperationsComponent from "./BatchOperationsComponent";
import { PaymentRequest } from "../../../store/paymentRequests";
import BatchOperationsForm from "../../CreateForms/components/BatchOperationsForm";

interface BatchOperationsCheckboxesModalProps {
  isOpen: boolean;
  onClose: (currencyId?: CurrencyId) => void;
  paymentRequests: PaymentRequest[]
}

interface BatchOperationsCheckboxesModalState {
  activeCurrency: CurrencyId | null,
}
class BatchOperationsCheckboxesModal extends React.Component<BatchOperationsCheckboxesModalProps> {
  public state: BatchOperationsCheckboxesModalState = {
    activeCurrency: null,
  };

  public render() {
    const { isOpen, paymentRequests } = this.props;
    const { activeCurrency } = this.state;
    return (
      <Modal
        header={`Пакетное проведение операций`}
        isOpen={isOpen}
        onBackgroundClick={this.onClose}
        onEscapeKeydown={this.onClose}
        fullWidth
      >
        {!activeCurrency && <React.Fragment>
          <BatchOperationsComponent onClick={this.setActiveCurrency} batchOperations={this.prepareData(paymentRequests, 1)} />
          <BatchOperationsComponent onClick={this.setActiveCurrency} batchOperations={this.prepareData(paymentRequests, 2)} />
          <BatchOperationsComponent onClick={this.setActiveCurrency} batchOperations={this.prepareData(paymentRequests, 3)} />
        </React.Fragment>}
        {activeCurrency && <BatchOperationsForm
          currencyId={activeCurrency}
          paymentRequests={this.prepareData(paymentRequests, activeCurrency)}
          goToBack={() => this.onClose(undefined, true)}
        />}
      </Modal>
    )
  }

  private onClose = (e: any, hard?: boolean) => {
    const { onClose } = this.props;
    const { activeCurrency } = this.state;
    this.setActiveCurrency(null);
    if (hard && activeCurrency) {
      onClose(activeCurrency);
    }
    onClose();
  };

  private setActiveCurrency = (activeCurrency: CurrencyId | null) => this.setState({ activeCurrency });

  private prepareData = (paymentRequests: PaymentRequest[], currencyId: CurrencyId) => {
    const currencyRequest = paymentRequests.filter((item:PaymentRequest) => item.CurrencyId === currencyId);
    const TotalSum = currencyRequest
      .reduce((sum: number, item: PaymentRequest) => sum + item.Total, 0);

    return {
      Currency: currencyId,
      TotalSum,
      Requests: currencyRequest.map((item: PaymentRequest) => ({
        Id: item.Id,
        FullName: item.PartnerName,
        Sum: item.Total,
      }))
    }
  }
}

export default BatchOperationsCheckboxesModal;
