import React, { PureComponent } from 'react';
import { toast } from "react-toastify";

import styled from "../../../../styled/styled-components";
import { ProductFormState, ProductFormProps, Product } from "../../types";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import ApiClient from "../../../../services/api";
import FormRow from "./components/FormRow/FormRow";
import AdditionalForm from "../AdditionalForm";

export const ProductWrapper = styled.div`
  border: 1px solid black;
  height: 400px;
  overflow: scroll;
`;

export interface ProductFormExtraProps {
  loadFreeProducts: () => void;
}

const ErrorToastAdd = 'Необходимо выбрать продукт';
const SuccessToastAdd = 'Продукт успешно добавлен';
const SuccessToastRemove = 'Продукт успешно удален';

export default class ProductsForm extends PureComponent<ProductFormProps & ProductFormExtraProps, ProductFormState>{
  public state: ProductFormState = {
    data: [],
    error: null,
    loading: false,
  };

  public componentDidMount(): void {
    this.getData();
  }

  public render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    const { loading, data } = this.state;
    const { title, freeProducts  } = this.props;
    return (
      <LoadingOverlay loading={loading}>
        <h4>
          {title}
        </h4>
        <ProductWrapper>
          {data.map((item: Product) => (<FormRow removeProduct={this.removeProduct} key={item.Id} {...item} />))}
        </ProductWrapper>
        <AdditionalForm addNewProduct={this.addNewProduct} freeProducts={freeProducts} />
      </LoadingOverlay>
    );
  }

  private removeProduct = async (Id: number) => {
    const { loadFreeProducts } =  this.props;
    this.setState({
      loading: true,
    });
    try {
      await ApiClient.removeCalculationsProduct(Id);
      toast(SuccessToastRemove, {
        type: toast.TYPE.SUCCESS,
      });
      await loadFreeProducts();
      await this.getData();
    } catch (e) {
      const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
      toast(errorMessage, {
        type: toast.TYPE.ERROR,
      });
    }
  };

  private addNewProduct = async (value?: Product) => {
    const { type, loadFreeProducts } =  this.props;
    if (!value) {
      toast(ErrorToastAdd, {
        type: toast.TYPE.ERROR,
      });

      return ;
    }
    this.setState({
      loading: true,
    });
    try {
      await ApiClient.addCalculationsProduct(value.Id, type);
      toast(SuccessToastAdd, {
        type: toast.TYPE.SUCCESS,
      });
      await loadFreeProducts();
      await this.getData();
    } catch (e) {
      const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
      toast(errorMessage, {
        type: toast.TYPE.ERROR,
      });
    }
  };

  private getData = async () => {
    const { type } =  this.props;
    try {
      this.setState({
        loading: true,
      });
      const data = await ApiClient.getCalculationsProductByType(type);
      this.setState({
        data
      })
    } catch (e) {
      this.setState({
        error: e,
        data: [],
      })
    } finally {
      this.setState({
        loading: false,
      })
    }
  }

}
