import React, { PureComponent } from "react";
import moment from "moment";

import LoadingOverlay from "../../../../components/LoadingOverlay";
import { parseServerDate } from "../../../../utils";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import ApiClient from "../../../../services/api";
import { toast } from "react-toastify";
import Button from "../../../../components/Button/Button";
import Modal from "../../../../components/Modal";
import styled from "../../../../styled/styled-components";

export interface LoanEarlyCloseProps {
  BillId: string,
  type: string,
}

export interface CloseCalendar {
  CloseDate: string;
  CloseBalanceStart: number;
  ClosePercentRate: number;
  CloseMemberRate: number;
  ClosePercent: number;
  ClosePit: number;
  CloseMember: number;
  CloseBalanceEnd: number;
  Percent: number;
  Pit: number;
  Member: number;
  PaymentPaid: number;
  SumToPay: number;
}

export interface DepositEarlyCloseState {
  data: CloseCalendar;
  loading: boolean;
  error?: any;
  showModal: boolean;
  Date?: string;
}

const Buttons = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;


export default class DepositEarlyClose extends PureComponent<LoanEarlyCloseProps, DepositEarlyCloseState> {

  public state = {
    data: {
      CloseDate: '',
      CloseBalanceStart: 0,
      ClosePercentRate: 0,
      CloseMemberRate: 0,
      ClosePercent: 0,
      ClosePit: 0,
      CloseMember: 0,
      CloseBalanceEnd: 0,
      Percent: 0,
      Pit: 0,
      Member: 0,
      PaymentPaid: 0,
      SumToPay: 0,
    },
    loading: false,
    error: false,
    showModal: false,
    Date: moment().format("YYYY-MM-DD"),
  };

  public render() {

    const { data, loading, showModal } = this.state;
    const { type } = this.props;
    const isDeposit = type === 'deposit';
    return (
      <LoadingOverlay loading={loading}>
        <div className="mt-4">
          <div className="panel-title text-center mb-4">
            <h3 className="d-inline-flex row align-items-center">
              Расчет досрочного закрытия на
              <DatePicker useStyledInput className="ml-2" onChange={this.handleChangeDate}
                          selected={data && data.CloseDate ? parseServerDate(data.CloseDate) : null}/>
            </h3>

          </div>
          <div className="panel-body">
            {data.CloseDate && <div className="table-responsive"><table className="table table-stripped">
              <thead>
              <tr>
                <th>Дата</th>
                <th>Сумма размещения</th>
                <th>Процентная ставка</th>
                {isDeposit && <th>Cтавка ЧВ</th>}
                <th>Компенсация</th>
                {isDeposit && <th>НДФЛ</th>}
                {isDeposit && <th>ЧВ</th>}
                <th>Баланс при закрытии</th>
                <th>Начисленная компенсация</th>
                {isDeposit && <th>Удерж. НДФЛ</th>}
                {isDeposit && <th>Удерж. ЧВ</th>}
                <th>Выплаченная компенсация</th>
                <th>К выплате</th>
              </tr>
              </thead>
              <tbody>
              <tr className="text-left">
                <td>{moment(data.CloseDate).format("DD.MM.YYYY")}</td>
                <td>
                  {data && (data.CloseBalanceStart).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                <td>
                  {data && (data.ClosePercentRate).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                {isDeposit && <td>
                  {data && (data.CloseMemberRate).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>}
                <td>
                  {data && (data.ClosePercent).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                {isDeposit && <td>
                  {data && (data.ClosePit).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>}
                {isDeposit && <td>
                  {data && (data.CloseMember).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>}
                <td>
                  {data && (data.CloseBalanceEnd).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                <td>
                  {data && (data.Percent).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                {isDeposit && <td>
                  {data && (data.Pit).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>}
                {isDeposit && <td>
                  {data && (data.Member).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>}
                <td>
                  {data && (data.PaymentPaid).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                <td>
                  {data && (data.SumToPay).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
              </tr>
              </tbody>
            </table></div>}
          </div>
          <Button type="success" onClick={this.toggleModal}>
            Провести досрочное закрытие
          </Button>
        </div>
        <Modal
          header={isDeposit ? "Досрочное закрытие сберегательной программы" : "Досрочное погашение счетов привлечения средств"}
          isOpen={showModal}
          onBackgroundClick={this.toggleModal}
          onEscapeKeydown={this.toggleModal}
        >
          <div className='mx-2'>
            Вы уверены, что хотите перевести пересчитанную сумму к выплате пайщику на текущий счет с закрытием {isDeposit ? "сберегательной программы" : "инвестиционношго займа"}?
          </div>
          <Buttons>
            <Button type="success" className="mr-2" onClick={this.closeEarlyDeposit}>
              Да
            </Button>
            <Button type="default" onClick={this.toggleModal}>
              Нет
            </Button>
          </Buttons>
        </Modal>
      </LoadingOverlay>
    );
  }

  public componentDidMount(): void {
    this.requestData(moment().format("YYYY-MM-DD"));
  }

  private handleChangeDate = (date: Date, event: any) => {
    if (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value)) {
      this.setState({ Date: moment(date).format("YYYY-MM-DD")});
      this.requestData(moment(date).format("YYYY-MM-DD"));
    }
  };

  private toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }))
  };

  private requestData = async (date: string) => {
    const { BillId, type } = this.props;
    try {
      this.setState({
        loading: true,
      });
      const result = type === 'deposit' ? await ApiClient.getDepositCloseCalendar(BillId, date) : await ApiClient.getRaisingCloseCalendar(BillId, date) ;
      this.setState({
        data: result,
        loading: false,
        error: undefined,
      })
    } catch (e) {
      this.setState({
        loading: false,
        error: e,
      });
      const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
      toast(errorMessage, {
        type: toast.TYPE.ERROR
      });
    }
  };

  private closeEarlyDeposit = async () => {
    const { BillId, type } = this.props;
    const { Date } = this.state;
    try {
      this.setState({
        loading: true,
      });
      const result = type === 'deposit' ? await ApiClient.closeDepositCalendar(BillId, Date) : await ApiClient.closeRaisingCalendar(BillId, Date);
      toast(result.Message, {
        type: toast.TYPE.SUCCESS
      });
      this.setState({
        loading: false,
      });
      setTimeout(() => window.location.reload(), 500);
    } catch (e) {
      this.setState({
        loading: false,
        error: e,
      });
      const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
      toast(errorMessage, {
        type: toast.TYPE.ERROR
      });
    } finally {
      this.setState({
        showModal: false,
      })
    }
  }
}
