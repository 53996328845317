import { AxiosRequestConfig } from 'axios';
import jwtDecode from 'jwt-decode';
import axios from './axios';


export class TokenStorage {


  public static readonly LOCAL_STORAGE_TOKEN = 'access_token';
  public static readonly LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token';

  public static isAuthenticated(): boolean {
    return this.getToken() !== null;
  }

  public static getAuthentication(): AxiosRequestConfig {
    return {
      headers: { 'Authorization': 'Bearer ' + this.getToken() }
    };
  }

  public static getNewToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/auth/token', { params: {refreshToken: this.getRefreshToken() }})
        .then((response: any) => {

          this.storeToken(response.data.access_token);
          this.storeRefreshToken(response.data.refresh_token);

          resolve(response.data.access_token);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  public static storeToken(token: string): void {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token);
  }

  public static storeRefreshToken(refreshToken: string): void {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
  }

  public static clear():void {
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  public static needTokenRequest(): boolean {
    const token = TokenStorage.getToken();
    if (!token) {

      return true;
    }
    const { exp} = jwtDecode(token);

    return exp < TokenStorage.getNow();

  }

  public static getToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
  }

  public static getBarear(): string | null {
    return `Bearer ${TokenStorage.getToken()}`;
  }

  private static getNow(): number {
    return (new Date().getTime() + 1) / 1000
  }

  private static getRefreshToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }
}
