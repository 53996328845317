import * as React from "react";
import moment from 'moment';
import { noop } from 'lodash';
import { PartnerContext } from "../../pages/Partner";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCellHead,
  TableCell
} from "../../../../components/Table";
import { RELATIVES_PARTNERS_FIELDS, RELATION_LABELS } from "../../constants";
import { RelativePartner } from "../../types";
import {
  SimpleInput,
  Select,
  SelectOption,
  SimpleMaskedInput
} from "../../../../components/FormControls/FormControls";
import { renderValue, getTableCellAlign } from "../../utils";
import Button from "../../../../components/Button/Button";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import styled from "../../../../styled/styled-components";
import { convertToUtc, parseServerDate } from "../../../../utils";
import DatePicker from '../../../../components/DatePicker/DatePicker';
import Link from "../../../../components/Link";

interface RelativesTableProps {
  relativesPartners: RelativePartner[];
  editing: boolean;
  onChange: (partners: RelativePartner[]) => void;
}

const ButtonWrapper = styled.div`
  margin-bottom: 15px;
`;

const StyledDatePicker = styled(DatePicker)`
  display: inline;
`;

const getControl = (
  fieldName: keyof RelativePartner,
  data: RelativePartner,
  editing: boolean,
  changeHandler: (value: any, fieldName: keyof RelativePartner) => void
) => {
  if (!editing && data) {
    return renderValue(fieldName, data);
  }

  let value = data[fieldName];

  if (value === null) {
    value = '';
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    changeHandler(event.target.value, fieldName);
  };

  switch (fieldName) {
    case "BirthDate":
      const changeHandlerDate = (date: Date, event: any) => {
        if (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value)) {
          if (changeHandler) {
            changeHandler(date ? convertToUtc(date).toISOString() : null, "BirthDate");
          }
        }
      };
      // @ts-ignore
      return (<StyledDatePicker maxDate={new Date()} selected={value && parseServerDate(value.toString())}
          onChange={changeHandlerDate}
          showYearDropdown
          showMonthDropdown
          disabledKeyboardNavigation
          name={fieldName.toString()}
          id={fieldName.toString()}
          disabled={!!data.BillNumber}
        />
      );

    case "RelationTypeId":
      // @ts-ignore
      return ( <Select onChange={handleChange} value={value} style={{ width: "100%" }}>
          {[...RELATION_LABELS.entries()].map(([id, label]) => (
            <SelectOption key={id} value={id}>
              {label}
            </SelectOption>
          ))}
        </Select>
      );

    case "Phone":
      // @ts-ignore
      return (<SimpleMaskedInput onChange={handleChange} value={value}
          mask="+7 999 999-99-99"
        />
      );

    case "Age":
      // @ts-ignore
      return <SimpleInput disabled onChange={noop} value={moment().diff(data.BirthDate, "years")} />;


    default:
      // @ts-ignore
      return <SimpleInput onChange={handleChange} maxLength={255} value={value} />;
  }
};

const RelativesTable: React.SFC<RelativesTableProps> = React.memo(
  ({ relativesPartners, editing, onChange }) => {
    const confirmDialogRef = React.createRef<ConfirmDialog>();

    const deleteHandler = (index: number) => {
      if (!relativesPartners[index]) {
        return;
      }

      if (!confirmDialogRef.current) {
        return;
      }

      const deleteItem = () => {
        const newPartners = relativesPartners.filter(
          (_item, _index) => _index !== index
        );
        onChange(newPartners);
      };

      if (relativesPartners[index].Id === null) {
        deleteItem();
      } else {
        confirmDialogRef.current.open(
          "Вы действительно хотите удалить выбранный элемент?",
          (allow: boolean) => allow && deleteItem()
        );
      }
    };

    const changeHandler = (
      index: number,
      value: any,
      fieldName: keyof RelativePartner
    ) => {
      const newPartners = relativesPartners.map((item, _index) => {
        if (index === _index) {
          return {
            ...item,
            [fieldName]: value
          };
        }

        return item;
      });

      onChange(newPartners);
    };

    return (
      <React.Fragment>
        <Table>
          <TableHead>
            <TableRow>
              {[...RELATIVES_PARTNERS_FIELDS.entries()].map(
                ([fieldName, label]) => (
                  <TableCellHead
                    align={getTableCellAlign(fieldName)}
                    key={fieldName}
                  >
                    {label}
                  </TableCellHead>
                )
              )}
              {editing && <TableCellHead>Действие</TableCellHead>}
            </TableRow>
          </TableHead>
          <TableBody>
            {relativesPartners.map((item, index) => (
              <TableRow key={index}>
                {[...RELATIVES_PARTNERS_FIELDS.keys()].map(fieldName => (
                  <TableCell
                    align={getTableCellAlign(fieldName)}
                    key={fieldName}
                  >
                    {getControl(
                      fieldName,
                      item,
                      editing,
                      changeHandler.bind(null, index)
                    )}
                  </TableCell>
                ))}
                {editing && (
                  <TableCell align="center">
                    {item.BillNumber ?
                      (<React.Fragment>
                        Открыт счет <Link type="success" href={`/Bill/Details/${item.BillNumber}`}>
                        {item.BillNumber}
                      </Link>
                      </React.Fragment>)
                      :
                      <Button
                      onClick={deleteHandler.bind(null, index)}
                      type="success"
                    >
                      Удалить
                    </Button>}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <ConfirmDialog ref={confirmDialogRef} />
      </React.Fragment>
    );
  }
);

interface RelativesTabProps {}
interface RelativesTabState {}
export default class RelativesTab extends React.PureComponent<
  RelativesTabProps,
  RelativesTabState
> {
  private parnerInitialData: RelativePartner = {
    Id: null,
    FullName: "",
    RelationTypeId: 1,
    Phone: "",
    Email: "",
    BirthDate: undefined,
    Age: "",
    Document: "",
    Comment: "",
  };
  public render() {
    return (
      <PartnerContext.Consumer>
        {({ editing, userData, handleChange = () => void 0 }) =>
          userData && (
            <React.Fragment>
              {editing && (
                <ButtonWrapper>
                  <Button
                    onClick={handleChange.bind(
                      null,
                      null,
                      "RelativesPartners",
                      [...userData.RelativesPartners, this.parnerInitialData]
                    )}
                    type="success"
                  >
                    Добавить
                  </Button>
                </ButtonWrapper>
              )}
              <RelativesTable
                editing={editing}
                relativesPartners={userData.RelativesPartners}
                onChange={handleChange.bind(null, null, "RelativesPartners")}
              />
            </React.Fragment>
          )
        }
      </PartnerContext.Consumer>
    );
  }
}
