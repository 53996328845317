import React, { Component } from 'react';
import styled from 'styled-components';

import { Filter, Label, Button, BtnGroup } from '../../styled/Filter';

const currencies = {
  RUB: 'RUB',
  USD: 'USD',
  EUR: 'EUR',
};

const CurrencyButton = ({ code, active, onChange }) => (
  <Button
    type="button"
    active={active === code}
    onClick={() => onChange(code)}
  >
    {code === 'all' ? 'Все' : code}
  </Button>
);

export default class CurrencyFilter extends Component {
  render() {
    const { active, onChange } = this.props;

    const buttons = Object.keys(currencies)
      .map(code => <CurrencyButton key={code} code={code} active={active} onChange={onChange} />);

    return (
      <Container>
        <Label htmlFor="currency-filter">Валюта:</Label>
        <BtnGroup role="group" id="currency-filter">
          <CurrencyButton code="all" active={active} onChange={onChange} />
          {buttons}
        </BtnGroup>
      </Container>
    );
  }
}

const Container = styled(Filter)``;
