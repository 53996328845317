import React, { PureComponent } from 'react';
import { Product } from "../../../../types";
import Button from "../../../../../../components/Button/Button";
import styled from "../../../../../../styled/styled-components";

export const NameWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export interface FormRowExtraProps {
  removeProduct: (Id: number) => void;
}

export default class FormRow extends PureComponent<Product & FormRowExtraProps>{

  public render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    const { Name } = this.props;
    return (
      <div className="row no-gutters">
        <NameWrapper className="col-7 py-2 pl-2">
          {Name}
        </NameWrapper>
        <div className="col-5 py-2 pl-2">
          <Button type="error" onClick={this.remove}>Удалить</Button>
        </div>
      </div>
    );
  }

  private remove = () =>  {
    const { removeProduct, Id }  = this.props;
    removeProduct(Id);
  }
}
