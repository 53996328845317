import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from "redux";
import ChildCalculator from './ChildCalculator';
import { AppState } from "../../../../store";
import { fetchCalculatorDataClear } from "../../../../store/calculators";


const mapStateToProps = (state: AppState) => ({
  loading: state.calculators.loading,
  calculatingData: state.calculators.calculatingPensionData,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchCalculatorDataClear,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(ChildCalculator);
