import React, { Component } from 'react';

import './CheckResultsTable.scss';

import ModalSpinner from '../../../components/ModalSpinner/ModalSpinner';
import CheckResultsRow from '../CheckResultsRow/CheckResultsRow';
import getRandomKey from '../../../helpers/getRandomKey';

export default class CheckResultsTable extends Component {
  render() {
    const { results, status } = this.props;

    if (status === 'waiting') {
      return <ModalSpinner show />;
    }

    let content;
    if (status === 'error') {
      content = <span>Не удалось загрузить информацию с сервера</span>;
    } else if (results.length <= 0) {
      content = <span>Нет предыдущих проверок</span>;
    } else {
      const rows = results.map(row => (
        <CheckResultsRow
          key={getRandomKey('check', 'results')}
          date={row.Date}
          results={row.Concurrences}
        />));
      content = (
        <table className="table">
          <tbody>{rows}</tbody>
        </table>);
    }

    return (<div className="check-results-table">{content}</div>);
  }
}
