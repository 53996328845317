import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from "redux";

import LoanEarlyClose from './LoanEarlyClose';
import { AppState } from "../../../../store";
import { fetchLoanEarlyCloseRequest } from "../../../../store/billDetails";


const mapStateToProps = (state: AppState) => ({
  loanEarlyClose: state.billDetails.loanEarlyClose,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchLoanEarlyCloseRequest,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(LoanEarlyClose);
