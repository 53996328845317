import * as React from "react";
import { RouteComponentProps } from "react-router";
import moment from "moment";

import { AnalyticsState, DepositDurationLine } from "../types";
import Chart from "../../../components/Chart/Chart";
import CurrencyFilter from "../../../components/CurrencyFilter/CurrencyFilter";
import PeriodFilter from "../../../components/PeriodFilter/PeriodFilter";
import CompareFilter from "../../../components/CompareFilter/CompareFilter";
import {
  getTickInterval,
  handleChangeCompare,
  handleChangeCurrency,
  handleChangePeriod, getCurrencyCode, getPeriodType
} from "../../../helpers/chartHelpers";
import PieChart from "../../../components/Chart/PieChart";
import { Container, Header } from "../components/Views";
import { getDefaultState, getLineChartData, getPieChartData } from "../utils";
import AnalyticsApiClient from "../services/api";
import { showErrorToast } from "../../../components/ErrorToast";
import LoadingOverlay from "../../../components/LoadingOverlay";

const commonValueAccumulatorCount = (a: any, line: DepositDurationLine) => {
  a.y = a.y + line.Count;
  a.name = `${line.Deposit} дней, за все время`;
  return a;
};

const partValueMapperCount = (line: DepositDurationLine, firstDate: string, lastDate: string) => ({
  y: line.Count,
  name: `${line.Deposit} дней за период ${firstDate} - ${lastDate} (${line.Currency})`
});

const commonValueAccumulatorSum = (a: any, line: DepositDurationLine) => {
  a.y = a.y + line.Sum;
  a.name = `${line.Deposit} дней, за все время`;
  return a;
};

const partValueMapperSum = (line: DepositDurationLine, firstDate: string, lastDate: string) => ({
  y: line.Sum,
  name: `${line.Deposit} дней за период ${firstDate} - ${lastDate} (${line.Currency})`
});

const nameFormatter = (line: DepositDurationLine, firstDate?: string, lastDate?: string) =>
  `Количество вкладов (${line.Currency}, ${line.Deposit} дней): ${firstDate} - ${lastDate}`;

export interface DepositsDurationProps extends RouteComponentProps {
}

export interface DepositsDurationState extends AnalyticsState {
  data: DepositDurationLine[],
}

export default class DepositsDuration extends React.Component<DepositsDurationProps, DepositsDurationState> {
  private readonly handleChangeCompare: any;
  private readonly handleChangePeriod: any;
  private readonly handleChangeCurrency: any;

  constructor(props: Readonly<DepositsDurationProps>) {
    super(props);

    this.state = getDefaultState();

    this.handleChangeCompare = handleChangeCompare.bind(this);
    this.handleChangeCurrency = handleChangeCurrency.bind(this);
    this.handleChangePeriod = handleChangePeriod.bind(this);
  }

  public componentDidMount() {
    this.loadData();
  }

  public render() {
    const {
      filters: {
        currency,
        period,
        dateFrom,
        dateTo,
        compare
      },
      data = [],
      loading,
    } = this.state;

    return (
      <Container>
        <LoadingOverlay loading={loading === "pending"}>
          <Header>
            <CurrencyFilter active={currency} onChange={this.handleChangeCurrency} />
            <PeriodFilter
              activePeriod={period}
              dateFrom={dateFrom}
              dateTo={dateTo}
              onChange={this.handleChangePeriod}
            />
            {period !== "year" && period !== "currentYear"
              ? null
              : <CompareFilter compare={compare} onChange={this.handleChangeCompare} />}
          </Header>
          <Chart
            series={getLineChartData(data, nameFormatter)}
            minTickInterval={getTickInterval(period)}
            title="Количество продуктов по валютам"
            xLabel="Период"
            yLabel="Количество"
            xDateFormat={period !== "month" && period !== "currentMonth" ? "%B" : "%d %B"}
          />
          <PieChart
            title="Распределение вкладов (количество)"
            series={getPieChartData(data, "Deposit", commonValueAccumulatorCount, partValueMapperCount)}
          />
          <PieChart
            title="Распределение вкладов (сумма)"
            series={getPieChartData(data, "Deposit", commonValueAccumulatorSum, partValueMapperSum)}
          />
        </LoadingOverlay>
      </Container>
    );
  }

  private async loadData() {
    try {
      const {
        currency,
        period,
        dateFrom,
        dateTo,
        compare
      } = this.state.filters;

      const params = {
        currency: getCurrencyCode(currency),
        periodType: getPeriodType(period),
        dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
        dateTo: moment(dateTo).format("YYYY-MM-DD"),
        compare: period !== "year" && period !== "currentYear" ? undefined : compare
      };
      this.setState({ loading: "pending" });
      const data = await AnalyticsApiClient.getDepositsDuration(params);
      this.setState({ data });
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: "ok" });
    }
  }

}
