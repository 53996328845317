import React from 'react';
import { connect } from "react-redux";

import Modal from '../../../components/Modal';
import { AppState } from "../../../store";
import {
  CurrencyRequests,
  getEurBatchOperations,
  getRubBatchOperations,
  getUsdBatchOperations
} from "../../../store/batchOperations";
import BatchOperationsComponent from "./BatchOperationsComponent";

interface BatchOperationsModalProps {
  rubBatchOperations?: CurrencyRequests;
  usdBatchOperations?: CurrencyRequests;
  eurBatchOperations?: CurrencyRequests;
  isOpen: boolean;
  onClose: () => void;
}
class BatchOperationsModal extends React.Component<BatchOperationsModalProps> {
  public render() {
    const { isOpen, onClose, rubBatchOperations, eurBatchOperations, usdBatchOperations } = this.props;
    return (
      <Modal
        header={`Пакетное проведение операций`}
        isOpen={isOpen}
        onBackgroundClick={onClose}
        onEscapeKeydown={onClose}
        fullWidth
      >
        <BatchOperationsComponent batchOperations={rubBatchOperations} />
        <BatchOperationsComponent batchOperations={eurBatchOperations} />
        <BatchOperationsComponent batchOperations={usdBatchOperations} />
      </Modal>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  rubBatchOperations: getRubBatchOperations(state),
  usdBatchOperations: getUsdBatchOperations(state),
  eurBatchOperations: getEurBatchOperations(state),
});

export default connect(
  mapStateToProps,
)(BatchOperationsModal);
