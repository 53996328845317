import React, { Component } from 'react';
import Spinner from 'react-spinkit';

export default class PlainSpinner extends Component {
  render() {
    return (
      <div className="centered pt-5">
        <Spinner name="line-spin-fade-loader" />
      </div>
    );
  }
}
