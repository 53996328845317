import * as React from "react";
import styled, { css } from "../../styled/styled-components";
import { prop, ifProp } from "styled-tools";

export const FormLabelView: React.SFC<
  React.AllHTMLAttributes<HTMLLabelElement> & { required?: boolean }
> = ({ required, width, ...props }) => <label {...props} />;

export const FormLabel = styled(FormLabelView)`
  padding-right: 20px;
  text-align: right;
  width: ${prop("width", "180px")};
  margin: 4px 0;

  ${ifProp(
    "required",
    css`
      &::after {
        content: " *";
        display: inline-block;
        width: 10px;
        margin-right: -10px;
        color: ${({ theme }) => theme.colors.errorRed};
      }
    `
  )};
`;
