import * as React from "react";
import ListHeader, { PrintButton } from "../components/ListHeader";
import ApiClient from "../../../services/api";
import {
  DEFAULT_FILTER_FIELDS,
  DEFAULT_SORT_FIELD_NAME,
  DEFAULT_SORT_DIRECTION,
  AUTOCOMPLETE_FILTER_FIELDS,
  CLIENT_FIELD_LABELS
} from "../constants";
import {
  ClientListItem,
  FilterFieldName,
  FilterRequest,
  OperatorType,
  FilterItemData
} from "../types";
import ReactToPrint from "react-to-print";
import FilterConfigurator from "../../../components/PartnersAndClients/FilterConfigurator";
import { showErrorToast } from "../../../components/ErrorToast";
import { ButtonProps } from "../../../components/Button/Button";
import TableList from "../../../components/PartnersAndClients/TableList";
import Link from "../../../components/Link";
import Pagination from "../../../components/Pagination/Pagination";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { RouteComponentProps } from "react-router";
import { requestDataToQuery } from "../../../utils";
import Helmet from 'react-helmet';
import ConfirmModal from "../../../components/PartnersAndClients/ConfirmModal";

export interface ClientsListProps extends RouteComponentProps {
}

export interface ClientsListState {
  clients: ClientListItem[];
  filters: FilterRequest;
  cachedFilters: FilterRequest;
  visibleFields: Map<FilterFieldName<ClientListItem>, boolean>;
  filtersShown: boolean;
  fieldFilters: Map<FilterFieldName<ClientListItem>, FilterItemData>;
  autocompleteItems: {
    [fieldName in FilterFieldName<ClientListItem>]?: string[]
  };
  loading: boolean;
  showRejectConfirmModal: ClientListItem | null;
  deleteConfirmModal: ClientListItem | null;
}

const DEFAULT_FILTERS = {
  CurrentPage: 1,
  FieldInfos: [
    {
      FieldName: "FullName" as FilterFieldName<ClientListItem>,
      Method: 1,
      Value: null,
      ValueType: 1
    },
    {
      FieldName: "PartnerNumber" as FilterFieldName<ClientListItem>,
      Value: null,
      Method: 3,
      ValueType: 1
    },
    {
      FieldName: "Phone" as FilterFieldName<ClientListItem>,
      Value: null,
      Method: 10,
      ValueType: 1
    },
    {
      FieldName: "Mail" as FilterFieldName<ClientListItem>,
      Value: null,
      Method: 1,
      ValueType: 1
    },
    {
      FieldName: "Birthday" as FilterFieldName<ClientListItem>,
      Value: null,
      Method: 4,
      ValueType: 4
    },
    {
      FieldName: "PassportNumber" as FilterFieldName<ClientListItem>,
      Value: null,
      Method: 1,
      ValueType: 1
    },
    {
      FieldName: "InternationalPassportNumber" as FilterFieldName<ClientListItem>,
      Value: null,
      Method: 1,
      ValueType: 1
    },
    {
      FieldName: "Gender" as FilterFieldName<ClientListItem>,
      Value: null,
      Method: 0,
      ValueType: 5
    },
    {
      FieldName: "IsActive" as FilterFieldName<ClientListItem>,
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  IsPaging: true,
  Operator: 1 as OperatorType,
  PageSize: 25,
  SortDirection: DEFAULT_SORT_DIRECTION,
  SortFieldName: DEFAULT_SORT_FIELD_NAME,
  TotalPages: 1
};

const getDefaultVisibleFields = (
  map: Map<FilterFieldName<ClientListItem>, FilterItemData>
) => {
  const result = new Map<FilterFieldName<ClientListItem>, boolean>();

  map.forEach(item => {
    result.set(item.FieldName, true);
  });

  return result;
};

export default class ClientsList extends React.Component<ClientsListProps,
  ClientsListState> {
  public state: ClientsListState = {
    clients: [],
    visibleFields: getDefaultVisibleFields(DEFAULT_FILTER_FIELDS),
    filtersShown: false,
    filters: DEFAULT_FILTERS,
    cachedFilters: DEFAULT_FILTERS,
    fieldFilters: DEFAULT_FILTER_FIELDS,
    autocompleteItems: {},
    loading: false,
    showRejectConfirmModal: null,
    deleteConfirmModal: null
  };

  private listFieldLabels = CLIENT_FIELD_LABELS;
  private clientsTableElement = React.createRef<TableList<ClientListItem>>();
  private confirmRejectModal = React.createRef<ConfirmModal>();
  private confirmDeleteModal = React.createRef<ConfirmModal>();

  public componentDidMount() {
    this.getData();
  }

  public render() {
    return (
      <React.Fragment>
        <Helmet defer={false}>
          <title>Заявки</title>
        </Helmet>
        <ConfirmModal
          message="Вы действительно хотите отклонить заявку?"
          successLabel="Да"
          cancelLabel="Нет"
          onConfirm={this.rejectApplication}
          onCancel={this.closeRejectConfirmModal}
          ref={this.confirmRejectModal}
        />
        <ConfirmModal
          message="Вы действительно хотите удалить заявку?"
          successLabel="Да"
          cancelLabel="Нет"
          onConfirm={this.daleteApplication}
          onCancel={this.closeDeleteConfirmModal}
          ref={this.confirmDeleteModal}
        />
        <LoadingOverlay loading={this.state.loading}>
          <ListHeader printButton={this.renderPrintButton()}/>

          <FilterConfigurator
            labels={this.listFieldLabels}
            fields={this.state.visibleFields}
            handleChangeColumns={this.handleChangeColumns}
            filtersShown={this.state.filtersShown}
            toggleFiltersVisible={this.toggleFiltersVisible}
            handleChangeOperator={this.handleChangeOperator}
            operatorId={this.state.filters.Operator}
            resetAllFilters={this.resetAllFilters}
            acceptFilter={this.acceptFilter}
          />
          <TableList
            data={this.state.clients}
            visibleFields={this.state.visibleFields}
            createLink={this.createLink}
            renderActions={this.renderActions}
            sortProps={{
              sortFieldName: this.state.filters.SortFieldName,
              sortDirection: this.state.filters.SortDirection,
              onChangeSort: this.handleChangeSort
            }}
            filtersProps={{
              fields: [...this.state.fieldFilters.values()],
              onChangeFilter: this.handleChangeFilter,
              acceptFilter: this.acceptFilter,
              resetFilter: this.resetFilter,
              filtersShown: this.state.filtersShown,
              autocompleteItems: this.state.autocompleteItems
            }}
            ref={this.clientsTableElement}
          />
          {this.state.filters.TotalPages > 1 && (
            <Pagination
              size={this.state.filters.PageSize}
              count={this.state.filters.TotalPages * this.state.filters.PageSize}
              page={this.state.filters.CurrentPage}
              callback={this.handleChangePage}
            />
          )}
        </LoadingOverlay>
      </React.Fragment>
    );
  }

  private getData = async (
    filter: Partial<FilterRequest> = {},
    forceGoToDetails?: boolean
  ) => {
    const queryString = requestDataToQuery({
      SortFieldName: this.state.filters.SortFieldName,
      SortDirection: this.state.filters.SortDirection,
      CurrentPage: this.state.filters.CurrentPage,
      PageSize: this.state.filters.PageSize,
      IsPaging: this.state.filters.IsPaging,
      ...filter
    });

    this.setState({ loading: true });
    try {
      const result = await ApiClient.getClients(queryString);

      if (forceGoToDetails && result.DataSource.length === 1) {
        this.setState({ loading: false }, () => {
          this.props.history.replace(
            this.getLinkToDetails(result.DataSource[0])
          );
        });
      }

      this.setState({
        clients: result.DataSource,
        filters: result.FilterRequest,
        cachedFilters: result.FilterRequest,
        fieldFilters: this.extractFieldFilters(result.FilterRequest)
      });
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private handleChangeColumns = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: columnName, checked } = event.target;

    this.setState(state => {
      const filterFieldName = columnName as FilterFieldName<ClientListItem>;
      const newVisibleFields = new Map(state.visibleFields);

      newVisibleFields.set(filterFieldName, checked);

      return {
        visibleFields: newVisibleFields
      };
    });
  };

  private toggleFiltersVisible = () => {
    this.setState(state => ({ filtersShown: !state.filtersShown }));
  };

  private handleChangeOperator = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(event.target.value, 10);

    this.setState(state => ({
      filters: {
        ...state.filters,
        Operator: value as OperatorType
      }
    }));
  };

  private handleChangeFilter = (
    filterItemData: FilterItemData,
    forceFiltering?: boolean
  ) => {
    forceFiltering = forceFiltering || filterItemData.ValueType === 4 || filterItemData.ValueType === 5
    let forceGoToDetails = false;
    if (forceFiltering && filterItemData.FieldName === "FullName") {
      forceGoToDetails = true;
    }

    this.setState(
      state => {
        const newFilters = new Map(state.fieldFilters);
        const currentFilterItem = newFilters.get(filterItemData.FieldName);

        if (currentFilterItem) {
          newFilters.set(filterItemData.FieldName, filterItemData);
        }

        return {
          fieldFilters: newFilters,
          filters: {
            ...state.filters,
            FieldInfos: this.getFieldInfos(newFilters)
          }
        };
      },
      () => {
        this.proccessAutocomplete(filterItemData);
        if (forceFiltering) {
          this.acceptFilter(forceGoToDetails);
        }
      }
    );
  };

  private acceptFilter = (forceGoToDetails?: boolean) => {
    const { FieldInfos, Operator } = this.state.filters;

    this.getData(
      {
        Operator,
        FieldInfos: FieldInfos.filter(item => item && item.Value !== null),
        CurrentPage: 1
      },
      forceGoToDetails
    );
  };

  private resetAllFilters = () => {
    this.getData();
  };

  private extractFieldFilters = (filterRequest: FilterRequest) => {
    const fieldsData = new Map(DEFAULT_FILTER_FIELDS);

    filterRequest.FieldInfos.forEach(item => {
      if (fieldsData.get(item.FieldName)) {
        fieldsData.set(item.FieldName, item);
      }
    });

    return fieldsData;
  };

  private getFieldInfos = (
    fieldFilters: Map<FilterFieldName<ClientListItem>, FilterItemData>
  ) => {
    return [...fieldFilters.values()];
  };

  private handleChangeSort = (fieldNamme: FilterFieldName<ClientListItem>) => {
    this.setState(
      state => {
        const currentSortFieldName = state.filters.SortFieldName;
        const curretSortDirection = state.filters.SortDirection;

        const newSortDirection =
          currentSortFieldName !== fieldNamme
            ? 1
            : curretSortDirection === 1
            ? 2
            : 1;

        return {
          filters: {
            ...state.filters,
            SortFieldName: fieldNamme,
            SortDirection: newSortDirection
          }
        };
      },
      () => {
        this.getData({
          Operator: this.state.filters.Operator,
          FieldInfos: this.state.filters.FieldInfos.filter(
            item => item.Value !== null
          )
        });
      }
    );
  };

  private proccessAutocomplete = async (filterItemData: FilterItemData) => {
    const fieldName = filterItemData.FieldName;
    const value = filterItemData.Value;

    if (AUTOCOMPLETE_FILTER_FIELDS.indexOf(fieldName) === -1) {
      return;
    }

    if (!value || value.length < 2) {
      this.setState({
        autocompleteItems: {
          [fieldName]: []
        }
      });

      return;
    }

    const {
      FieldInfos,
      Operator,
      SortFieldName,
      // tslint:disable-next-line:no-shadowed-variable
      SortDirection
    } = this.state.filters;

    const queryString = requestDataToQuery({
      SortFieldName,
      SortDirection,
      Operator,
      FieldInfos: FieldInfos.filter(item => item.Value !== null),
      propertyName: fieldName
    });

    try {
      this.setState({
        autocompleteItems: {
          [fieldName]: await ApiClient.getAutocompleteForClients(queryString)
        }
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  private renderPrintButton = () => {
    if (this.clientsTableElement.current) {
      const content = () => this.clientsTableElement.current;
      const trigger = () =>
        <PrintButton/> as React.ComponentElement<ButtonProps, PrintButton>;

      return (
        <ReactToPrint
          pageStyle="@page { padding: 50px }"
          content={content}
          trigger={trigger}
        />
      );
    }
  };

  private createLink = (item: ClientListItem) => {
    return {
      link: this.getLinkToDetails(item),
      fieldName: "FullName" as keyof ClientListItem
    };
  };

  private renderActions = (item: ClientListItem) => (
    <React.Fragment>
      <Link routerLink href={this.getLinkToDetails(item)}>
        Просмотр
      </Link>
      <br/>
      {!item.IsDeleted && <Link routerLink href={this.getLinkToEdit(item)}>
        Изменить
      </Link>}
      {item.IsDeleted && <Link routerLink onClick={this.restoreItem(item)}>
        Восстановить
      </Link>}
      <br/>
      {!item.IsDeleted && <Link onClick={this.openRejectConfirmModal(item)}>
        Отклонить
      </Link>}
      {item.IsDeleted && <Link onClick={this.openDeleteConfirmModal(item)}>
        Удалить
      </Link>}
    </React.Fragment>
  );

  private restoreItem = (item: ClientListItem) => async () => {

    this.setState({ loading: true });
    await ApiClient.rejectClientData(item.Id);
    this.getData();
  }

  private rejectApplication = async () => {
    const { showRejectConfirmModal } = this.state;
    if (!showRejectConfirmModal) {
      return ;
    }

    this.setState({ loading: true });
    await ApiClient.rejectClientData(showRejectConfirmModal.Id);
    this.getData();
  };

  private daleteApplication = async () => {
    const { deleteConfirmModal } = this.state;
    if (!deleteConfirmModal) {
      return ;
    }

    this.setState({ loading: true });
    await ApiClient.deleteClientData(deleteConfirmModal.Id);
    this.getData();
  };

  private openRejectConfirmModal = (item: ClientListItem) => () => {
    this.setState({
      showRejectConfirmModal: item
    }, () => {
      const confirmModal = this.confirmRejectModal.current;
      if (confirmModal && item) {
        confirmModal.open();
      }
    })
  };

  private closeRejectConfirmModal = () => {
    this.setState({
      showRejectConfirmModal: null,
    })
  };

  private openDeleteConfirmModal = (item: ClientListItem) => () => {
    this.setState({
      deleteConfirmModal: item
    }, () => {
      const confirmModal = this.confirmDeleteModal.current;
      if (confirmModal && item) {
        confirmModal.open();
      }
    })
  };

  private closeDeleteConfirmModal = () => {
    this.setState({
      deleteConfirmModal: null,
    })
  };

  private resetFilter = (fieldName: keyof ClientListItem) => {
    const filterItemData = DEFAULT_FILTERS.FieldInfos.find(
      ({ FieldName }) => FieldName === fieldName
    );

    if (filterItemData) {
      this.handleChangeFilter(filterItemData, true);
    }
  };

  private handleChangePage = (page: number) => {
    this.getData({
      Operator: this.state.filters.Operator,
      FieldInfos: this.state.filters.FieldInfos.filter(
        item => item.Value !== null
      ),
      CurrentPage: page
    });
  };

  private getLinkToDetails = (item: ClientListItem) => {
    return `/applications/info/${item.Id}`;
  };
  private getLinkToEdit = (item: ClientListItem) => {
    return `/applications/edit/${item.Id}`;
  };
}
