export const PARTNER_INPUT_MASK = "999-9999-999999";

export const NONE_VALUE = "none";

export const GENDER_LABELS = new Map([[1, "Мужской"], [2, "Женский"]]);
export const PRIVILEGE_LEVEL_LABELS = new Map([[1, "Standart"], [2, "Silver"], [3, "Gold"], [4, "Platinum"]]);
export const MARITAL_LABELS = new Map([["Женат", "Женат"], ["Не женат", "Не женат"], ["Не женат", "Не женат"], ["Гражданский брак", "Гражданский брак"], ["В отношениях", "В отношениях"], ["Без пары", "Без пары"]]);
export const IS_ACTIVE_LABELS = new Map([[0, "Неактивный"], [1, "Активный"]]);
export const HAVE_CHILDS = new Map([[0, "Нет"], [1, "Есть"]]);
export const IS_MAIN = new Map([[0, "Нет"], [1, "Да"]]);
export const MESSENGERS_LABELS = new Map([["WhatsApp", "WhatsApp"], ["Viber", "Viber"], ["Telegram", "Telegram"], ["Другое", "Другое"]]);
export const SOCIAL_LABELS = new Map([["Инстаграмм", "Инстаграмм"], ["Вконтакте", "Вконтакте"], ["Фэйсбук", "Фэйсбук"], ["Сайт", "Сайт"], ["Реклама в интернете", "Реклама в интернете"], ["Билборд", "Билборд"], ["ТВ", "ТВ"], ["Радио", "Радио"], ["Рекомендация других", "Рекомендация других"]]);
export const LIVING_PLACE_LABELS = new Map([
  ["Своя квартира,дом", "Своя квартира,дом"],
  ["Съемная квартира", "Съемная квартира"],
  ["У своих родственников", "У своих родственников"],
  ["У родственников жены", "У родственников жены"],
  ["Другое", "Другое"]
]);

export const FEEDBACK_LABELS = new Map([
  ["От знакомых", "От знакомых"],
  ["Журнал", "Журнал"],
  ["Сайт", "Сайт"],
  ["Поисковики", "Поисковики"],
  ["Наружная реклама", "Наружная реклама"],
  ["Электронная рассылка", "Электронная рассылка"],
  ["Контекстная реклама", "Контекстная реклама"],
  ["Имиджевое мероприятие", "Имиджевое мероприятие"],
  ["Буклет/листовка", "Буклет/листовка"],
  ["Другое", "Другое"]
]);


export const COLUMN_LABELS = new Map([
  ["FullName", "ФИО"],
  ["PrivilegeLevel", "Статус пайщика"],
  ["PartnerNumber", "Номер пайщика"],
  ["Phone", "Номер телефона"],
  ["Mail", "E-mail"],
  ["Birthday", "Дата рождения"],
  ["RegistrationDate", "Дата поступления"],
  ["PassportNumber", "Номер паспорта"],
  ["InternationalPassportNumber", "Номер загранпаспорта"],
  ["Gender", "Пол"],
  ["IsActive", "Статус"],
  ["PartnerName", "ФИО"],
  ["CurrentBalance", "Сумма"],
  ["CurrencyId", "Валюта"],
  ["OpeningDate", "Дата создания"],
  ["ClientType", "Тип клиента"],
  ["AgentOneFullName", "Доверенное лицо 1 (ФИО)"],
  ["PowerOfAttorneyStartDate", "Дата начала доверенности"],
  ["PowerOfAttorneyEndDate", "Дата окончания доверенности"],
  ["ClearBalance", "Сумма"],
]);

export const CURRENCY_IDS = new Map([[1, "RUB"], [2, "USD"], [3, "EUR"]]);
