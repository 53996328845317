import { UserItem, FilterFieldName, FilterItemData, SortDirection } from "../types";

export const AUTOCOMPLETE_FILTER_FIELDS = [
  "PartnerNumber"
];

export const AUDIT_USER_FIELD_LABELS = new Map<keyof UserItem, string>([
  ["UserName", "Пользователь"],
  ["UserFullName", "ФИО"],
  ["Modified", "Дата"],
  ["Action", "Действие"],
  ["EntityName", "Объект"],
  ["Description", "Информация"]
]);

export const DEFAULT_FILTER_FIELDS = new Map<
  FilterFieldName<UserItem>,
  FilterItemData
  >([
  [
    "UserName",
    {
      FieldName: "UserName",
      Method: 1,
      Value: null,
      ValueType: 1
    }
  ],
  [
    "UserFullName",
    {
      FieldName: "UserFullName",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "Modified",
    {
      FieldName: "Modified",
      Value: null,
      Method: 4,
      ValueType: 4
    }
  ],
  [
    "Action",
    {
      FieldName: "Action",
      Value: null,
      Method: 1,
      ValueType: 5
    }
  ],
  [
    "EntityName",
    {
      FieldName: "EntityName",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "Description",
    {
      FieldName: "Description",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
]);

export const DEFAULT_SORT_DIRECTION: SortDirection = 2;

export const DEFAULT_SORT_FIELD_NAME: FilterFieldName<UserItem> =
  "Modified";
