import * as React from "react";
import { PartnerContext } from "../../pages/Partner";
import Panel from "../../../../components/Panel";
import { FormWrapper, FormColumn } from "../Views";
import { UserData } from "../../types";
import {
  ADDRESS_FIELDS,
  AGENT_PERSONAL_DATA_FIELDS,
  PASSPORT_FIELDS,
  INTERNATIONAL_PASSPORT_FIELDS
} from "../../constants";
import Form from "../../../../components/PartnersAndClients/Form";
import styled from "../../../../styled/styled-components";
import Button from "../../../../components/Button/Button";
import { getPartnerNumberFromUserdata } from "../../utils";
import ApiClient from "../../../../services/api";
import { showErrorToast } from "../../../../components/ErrorToast";
import Spinner from "../../../../components/Spinner";
import PrintButton from "../../../../components/PrintButton/PrintButton";

export interface AgentBaseTabState {
  printLoading: boolean;
}

const PrintButtonContainer = styled.div`
  width: 100%;
  text-align: right;
  margin-bottom: 15px;
`;

export default class AgentBaseTab extends React.PureComponent<
  {},
  AgentBaseTabState
> {
  public state = {
    printLoading: false
  };
  public printLabel = "";
  public agentNumber = 0;
  public render() {
    return (
      <PartnerContext.Consumer>
        {({ editing, userData, handleChange = () => void 0 }) => (
          <FormWrapper>
            <PrintButtonContainer>
              <Button
                onClick={this.printPowerOfAttorney.bind(null, userData)}
                type="success"
                className="mx-2"
                disabled={this.state.printLoading || editing}
              >
                {this.printLabel} <Spinner active={this.state.printLoading} />
              </Button>
              {userData &&<PrintButton type="success" className="mx-2" href={`/Template/RevokeAttorney/${this.getParthnerId(userData)}/${this.getAgentId(userData)}`}>
                Заявление на отзыв доверенности
              </PrintButton>}
            </PrintButtonContainer>
            <FormColumn>
              <Panel type="primary" heading="Доверенное лицо">
                {userData && (
                  <Form
                    data={this.getData(userData)}
                    fields={AGENT_PERSONAL_DATA_FIELDS}
                    disabled={!editing}
                    onChange={handleChange.bind(null, this.getSectionName())}
                  />
                )}
              </Panel>
            </FormColumn>
            <FormColumn>
              <Panel type="primary" heading="Адрес регистрации">
                {userData && (
                  <Form
                    fields={ADDRESS_FIELDS}
                    data={this.getData(userData).PartnersAddress}
                    disabled={!editing}
                    onChange={handleChange.bind(null, [
                      this.getSectionName(),
                      "PartnersAddress"
                    ])}
                  />
                )}
              </Panel>
              <Panel type="primary" heading="Удостоверение личности">
                <Panel heading="Гражданский паспорт">
                  {userData && (
                    <Form
                      data={this.getData(userData).Passport}
                      fields={PASSPORT_FIELDS}
                      disabled={!editing}
                      onChange={handleChange.bind(null, [
                        this.getSectionName(),
                        "Passport"
                      ])}
                    />
                  )}
                </Panel>
                <Panel heading="Загранпаспорт">
                  {userData && (
                    <Form
                      data={this.getData(userData).InternationalPassport}
                      fields={INTERNATIONAL_PASSPORT_FIELDS}
                      disabled={!editing}
                      onChange={handleChange.bind(null, [
                        this.getSectionName(),
                        "InternationalPassport"
                      ])}
                    />
                  )}
                </Panel>
              </Panel>
            </FormColumn>
          </FormWrapper>
        )}
      </PartnerContext.Consumer>
    );
  }

  public getData = (userData: UserData) => userData.AgentFirst;
  public getAgentId = (userData: UserData) => userData.AgentFirst.Id;
  public getParthnerId = (userData: UserData) => `${userData.NumberRegion}-${userData.NumberYear}-${userData.PartnerId}`;
  public getSectionName: () => keyof UserData = () =>
    "AgentFirst" as keyof UserData;

  private printPowerOfAttorney = async (userData: UserData | null) => {
    if (!userData || this.state.printLoading) {
      return;
    }

    const partnerNumber = getPartnerNumberFromUserdata(userData);
    this.setState({ printLoading: true });

    try {
      const result = await ApiClient.printPowerOfAttorney(
        partnerNumber,
        this.agentNumber
      );

      const blob = new Blob([result], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      window.open(url);
    } catch (error) {
      showErrorToast({ Message: error });
    } finally {
      this.setState({
        printLoading: false
      });
    }
  };
}
