import styled, { css } from '../../styled/styled-components';
import * as React from 'react';
import Clickable from '../Clickable';
import { ifProp } from 'styled-tools';
import { lighten } from 'polished';

const disabledStyles = css`
  background-color: ${({ theme }) => theme.colors.fields.disabled.background};
  color: ${({ theme }) => theme.colors.fields.disabled.font};
  border-color: ${({ theme }) => theme.colors.fields.disabled.borders};
  cursor: not-allowed;
`;

export const fieldStyles = css`
  height: 34px;
  font-size: 14px;
  background: #fff;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  ${ifProp('disabled', disabledStyles)}
  ${ifProp('greenBorder', css`
    &:focus {
      border-color: #1ab394;
      outline: none;
    }
  `)}
`;

const RootView: React.SFC<React.HTMLAttributes<HTMLSpanElement> & { error?: boolean }> = ({
  error,
  ...props
}) => <span {...props} />;

const Root = styled(RootView)`
  ${fieldStyles}
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  ${ifProp(
    'error',
    css`
      border-color: ${({ theme }) => theme.colors.errorRed};
    `,
  )}
`;

const Input = styled.input`
  && {
    padding: 6px 12px;
    background: none;
    border: 0;
    box-shadow: none;
    outline: none;

    ${ifProp('disabled', disabledStyles)}
  }
`;

const CloseButton = styled(Clickable)`
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  font-size: 8px;
  margin-right: -6px;
  line-height: 1;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  transition: all ease-in-out 0.15s;
  color: #fff;
  text-align: center;

  &:hover {
    background: rgba(0, 0, 0, 0.5);
  }

  &:focus {
    outline: none;
  }
`;

export interface InputViewProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onClear?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  innerRef?: (instance: HTMLInputElement | null) => void;
  error?: boolean;
}

export const InputView: React.SFC<InputViewProps> = ({
  onClear,
  innerRef: ref,
  style,
  error,
  ...props
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClear) {
      onClear(event);
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLButtonElement>) => {
    event.stopPropagation();
  };

  return (
    <Root error={error} style={style}>
      <Input {...props} />
      {onClear && (
        <CloseButton onFocus={handleFocus} onClick={handleClick}>
          x
        </CloseButton>
      )}
    </Root>
  );
};

export const CheckboxView: React.SFC<React.InputHTMLAttributes<HTMLInputElement>> = ({
  type,
  ...props
}) => <input type="checkbox" {...props} />;

export const TextareaView: React.SFC<
React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
error?: boolean;
disableResize?: boolean;
}
> = ({ error, disableResize, ...props }) => <textarea {...props} />;

const StyledRadioInput = styled.input.attrs({ type: 'radio' })`
  display: none;
`;

const RadioBadge = styled.span`
  display: inline-block;
  vertical-align: middle;
  width: 17px;
  height: 17px;
  padding: 2px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 50%;
  background-color: #fff;
  background-origin: content-box;
  background-clip: content-box;
  transition: all 0.2s ease-out;

  input:checked + & {
    background-color: #555;
  }

  input:hover + & {
    padding: 0;
    background-color: ${({ theme }) => theme.colors.lightGray};
  }

  input:checked:hover + & {
    padding: 2px;
    background-color: ${lighten(0.15, '#555')};
  }

  input:active + & {
    background-color: ${lighten(0.15, '#555')};
    padding: 0;
  }

  input:checked:active {
    padding: 0;
  }
`;

export const RadioView: React.SFC<React.InputHTMLAttributes<HTMLInputElement>> = ({
  style,
  className,

  type,

  ...props
}) => (
  <label style={style} className={className}>
    <StyledRadioInput {...props} />
    <RadioBadge />
  </label>
);
