import styled from "../../styled/styled-components";
import Clickable from "../Clickable";

const Link = styled(Clickable)`
  color: ${({ theme }) => theme.colors.link.default};

  &:hover {
    color: ${({ theme }) => theme.colors.link.hover};
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
  }

  &:focus,
  &.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: ${({ theme }) => theme.colors.link.disabled};
    pointer-events: none;
  }
`;

export default Link;
