export const PERIOD_MONTH = 1;
export const PERIOD_6_MONTH = 6;
export const PERIOD_YEAR = 12;
export const PERIOD_ALL = null;
export const PERIOD_CUSTOM = null;

export default {
  month: PERIOD_MONTH,
  '6 month': PERIOD_6_MONTH,
  year: PERIOD_YEAR,
  all: PERIOD_ALL,
  custom: PERIOD_CUSTOM,
};


export const DAYLY_TERM = 'дней';
export const MONTHLY_TERM = 'месяцев';

export const terms = {
  [DAYLY_TERM]: 1,
  [MONTHLY_TERM]: 2,
};
