import { createAction } from "typesafe-actions";

export const ActionTypes = {
  FETCH_LABELS_REQUESTS: "@@LABELS/FETCH_LABELS_REQUESTS",
  FETCH_LABELS_SUCCESS: "@@LABELS/FETCH_LABELS_SUCCESS",
  FETCH_LABELS_ERROR: "@@LABELS/FETCH_LABELS_ERROR"
};

export const fetchLabelsRequest = createAction(
  ActionTypes.FETCH_LABELS_REQUESTS,
  resolve => (section: string = "common", billType?: string) => resolve(section, billType)
);

export const fetchLabelsSuccess = createAction(
  ActionTypes.FETCH_LABELS_SUCCESS,
  resolve => (labels: Map<string | number, string>, section: string) =>
    resolve(labels, section)
);
