import React, { Component } from 'react';
import autoBind from 'react-autobind';
import Spinner from 'react-spinkit';
import Modal from 'react-modal';

import './ModalSpinner.scss';

Modal.setAppElement('#root');

export default class ModalSpinner extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    return (
      <Modal
        isOpen={this.props.show}
        className="modal-transparent centered"
      >
        <div className="modal-content">
          <Spinner name="line-spin-fade-loader" />
        </div>
      </Modal>
    );
  }
}
