import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from "redux";

import { AppState } from "../../../../store";
import { fetchCalculatorDataRequest, fetchCalculatorLoanDataRequest, fetchCalculatorRaisingDataRequest } from "../../../../store/calculators";

import CalculatorForm from './CalculatorForm';

const mapStateToProps = (state: AppState) => ({
  error: state.calculators.error,
  loading: state.calculators.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchCalculatorDataRequest,
      fetchCalculatorLoanDataRequest,
      fetchCalculatorRaisingDataRequest,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculatorForm);
