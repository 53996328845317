import { all, takeEvery, call, put, takeLatest, select, race, take } from "redux-saga/effects";
import moment from "moment";
import { AnyAction } from "redux";
import {
  ActionTypes,
  getAllChatsRequestSuccess, getAllChatsRequestError,
  getAllChatMessageSuccess, getAllChatMessageError, updateUnreadCount,
} from "./actions";
import { Message } from "./types";
import ApiClient from "../../services/api";
import { putError } from "../global/actions";
import { delay } from "../../utils";
import { getAuthData } from "../auth";

function* fetchChatsSaga() {
  try {
    const result: Message[] = yield call(ApiClient.getAllChats);

    const offset = moment().utcOffset();
    const data = result.map((message: Message) => ({
      ...message,
      Date: moment(message.Date).add(offset - 120,'minutes').format("YYYY-MM-DDTHH:mm:ss"),
    }));

    yield put(getAllChatsRequestSuccess(data));
  } catch (error) {
    yield put(putError(error));
    yield put(getAllChatsRequestError(error));
  }
}

function* fetchChatMessagesSaga(action: AnyAction) {
  try {
    const result: Message[] = yield call(ApiClient.getChatsMessages, action.payload);

    const offset = moment().utcOffset();
    const data = result.map((message: Message) => ({
      ...message,
      Date: moment(message.Date).add(offset - 120,'minutes').format("YYYY-MM-DDTHH:mm:ss"),
    }));

    yield put(getAllChatMessageSuccess(data));
  } catch (error) {
    yield put(putError(error));
    yield put(getAllChatMessageError(error));
  }
}

function* getUnreadedCount() {
  const authData = yield select(getAuthData);
  try {
    if (authData) {
      const response = yield call(ApiClient.getUnreadedChatsCount);
      yield put(updateUnreadCount(response));
    }
  } catch (error) {
    return;
  }
}

function* readChat(action: AnyAction) {
  try {
    yield call(ApiClient.readChats, action.payload);
    yield getUnreadedCount();
  } catch (error) {
    yield put(putError(error));
  }
}

function* pollSaga() {
  while (true) {
    try {
      yield getUnreadedCount();

      yield call(delay, 15000);
    } catch (error) {
      yield put({
        type: ActionTypes.POOL_STOP,
        error
      });
    }
  }
}

function* pollSagaWatch() {
  while (true) {
    const action = yield take(ActionTypes.POOL_START);
    // @ts-ignore
    yield race([call(pollSaga, action), take(ActionTypes.POOL_STOP)]);
  }
}


function* chatSaga() {
  yield all([
    pollSagaWatch(),
    yield takeEvery(ActionTypes.CHATS_GET_ALL_REQUEST, fetchChatsSaga),
    yield takeEvery(ActionTypes.READ_CHAT_MESSAGES, readChat),
    yield takeLatest(ActionTypes.GET_ALL_CHAT_MESSAGE_REQUEST, fetchChatMessagesSaga),
  ]);
}

export default chatSaga;
