import React from 'react';
import { isEmpty } from 'lodash';
import { CurrencyId, CurrencyRequests } from "../../../store/batchOperations";
import Button from "../../../components/Button/Button";
import { CURRENCY_IDS } from "../../../constants";
import BatchOperationsTable from "./BatchOperationsTable";

interface BatchOperationsComponentProps {
  batchOperations?: CurrencyRequests,
  onClick?: (currencyId: CurrencyId) => void,
}

export default class BatchOperationsComponent extends React.Component<BatchOperationsComponentProps> {
  public render(){
    const { batchOperations, onClick } = this.props;
    if (!batchOperations || isEmpty(batchOperations.Requests)) {

      return null;
    }
    return (
      <div className="container mb-4">
        <h2>Заявки в {CURRENCY_IDS.get(batchOperations.Currency)}</h2>
        <BatchOperationsTable batchOperations={batchOperations} />
        {onClick && <Button type="success" onClick={() => onClick(batchOperations.Currency)} className="w-100 my-3">Провести заявки на {this.getSumInCurrency(batchOperations.TotalSum)}</Button>}
        {!onClick && <Button routerLink href={`/Operation/BatchOperation/${batchOperations.Currency}`} type="success" className="w-100 my-3">Провести заявки на {this.getSumInCurrency(batchOperations.TotalSum)}</Button>}
      </div>
    );
  }

  private getSumInCurrency = (sum: number) => {
    const { batchOperations } = this.props;
    if (!batchOperations || isEmpty(batchOperations.Requests)) {

      return null;
    }
    return sum.toLocaleString('ru', {
      style: 'currency',
      currency: CURRENCY_IDS.get(batchOperations.Currency),
      minimumFractionDigits: 2
    })
  }
}
