import { combineReducers, Reducer } from "redux";
import { globalReducer, GlobalState } from "./global";
import { regionsReducer, RegionsState } from "./regions";
import { LabelsState, labelsReducer } from "./labels";
import { CalculatorsState, calculatorsReducer } from "./calculators";
import { BillDetailsState, billDetailsReducer } from "./billDetails";
import { FormState, formsReducer } from "./forms";
import { AuthState, authReducer } from "./auth";
import { BatchOperationsState, batchOperationsReducer } from "./batchOperations";
import { BillsState, billsReducer } from "./bills";
import { OtherActionsState, otherActionsReducer } from "./otherActions";
import { ChatsState, chatReducer } from "./chat";
import {
  paymentRequestsReducer,
  PaymentRequestsState
} from "./paymentRequests";
import { all } from "redux-saga/effects";
import regionsSaga from "./regions/sagas";
import paymentRequestsSaga from "./paymentRequests/sagas";
import labelsSaga from "./labels/sagas";
import calculatorsSaga from "./calculators/sagas";
import formsSaga from "./forms/sagas";
import billDetailSaga from "./billDetails/sagas";
import AuthSaga from "./auth/sagas";
import batchOperationsSaga from "./batchOperations/sagas";
import billsSaga from "./bills/sagas";
import otherActionsSaga from "./otherActions/sagas";
import chatSaga from "./chat/sagas";

export interface AppState {
  global: GlobalState;
  regions: RegionsState;
  paymentRequests: PaymentRequestsState;
  labels: LabelsState;
  calculators: CalculatorsState;
  forms: FormState;
  billDetails: BillDetailsState;
  auth: AuthState;
  batchOperations: BatchOperationsState;
  bills: BillsState;
  otherActions: OtherActionsState;
  chat: ChatsState;
}
export const rootReducer: Reducer<AppState> = combineReducers({
  global: globalReducer,
  regions: regionsReducer,
  paymentRequests: paymentRequestsReducer,
  labels: labelsReducer,
  calculators: calculatorsReducer,
  forms: formsReducer,
  billDetails: billDetailsReducer,
  auth: authReducer,
  batchOperations: batchOperationsReducer,
  bills: billsReducer,
  otherActions: otherActionsReducer,
  chat: chatReducer,
});

export function* rootSaga() {
  yield all([
    regionsSaga(),
    paymentRequestsSaga(),
    labelsSaga(),
    calculatorsSaga(),
    formsSaga(),
    billDetailSaga(),
    AuthSaga(),
    batchOperationsSaga(),
    billsSaga(),
    otherActionsSaga(),
    chatSaga(),
  ]);
}
