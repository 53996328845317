import { Reducer } from "redux";
import { ActionTypes } from "./actions";
import { CalculatorsState } from "./types";

const initialState: CalculatorsState = {
  loading: false,
  formData: undefined,
  calculatingData: undefined,
  calculatingLoanData: undefined,
  calculatingRaisingData: undefined,
  calculatingPensionData: undefined,
  error: null,
};

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_CALCULATOR_DATA_REQUESTS:
      return {
        ...state,
        loading: true,
        calculatingData: undefined,
        error: null,
      };

    case ActionTypes.FETCH_CALCULATOR_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        calculatingData: action.payload,
        error: null,
      };

    case ActionTypes.FETCH_CALCULATOR_DATA_ERROR:
      return {
        ...state,
        loading: false,
        calculatingData: undefined,
        error: action.payload,
      };

    case ActionTypes.FETCH_CALCULATOR_LOAN_DATA_REQUESTS:
      return {
        ...state,
        loading: true,
        calculatingLoanData: undefined,
        error: null,
      };

    case ActionTypes.FETCH_CALCULATOR_LOAN_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        calculatingLoanData: action.payload,
        error: null,
      };

    case ActionTypes.FETCH_CALCULATOR_LOAN_DATA_ERROR:
      return {
        ...state,
        loading: false,
        calculatingLoanData: undefined,
        error: action.payload,
      };


    case ActionTypes.FETCH_CALCULATOR_RAISING_DATA_REQUESTS:
      return {
        ...state,
        loading: true,
        calculatingRaisingData: undefined,
        error: null,
      };

    case ActionTypes.FETCH_CALCULATOR_RAISING_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        calculatingRaisingData: action.payload,
        error: null,
      };

    case ActionTypes.FETCH_CALCULATOR_RAISING_DATA_ERROR:
      return {
        ...state,
        loading: false,
        calculatingRaisingData: undefined,
        error: action.payload,
      };

    case ActionTypes.FETCH_CALCULATOR_PENSION_DATA_REQUESTS:
      return {
        ...state,
        loading: true,
        calculatingPensionData: undefined,
        error: null,
      };

    case ActionTypes.FETCH_CALCULATOR_PENSION_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        calculatingPensionData: action.payload,
        error: null,
      };

    case ActionTypes.FETCH_CALCULATOR_PENSION_DATA_ERROR:
      return {
        ...state,
        loading: false,
        calculatingPensionData: undefined,
        error: action.payload,
      };

    case ActionTypes.FETCH_CALCULATOR_DATA_CLEAR:
      return {
        ...state,
        loading: false,
        formData: undefined,
        calculatingData: undefined,
        calculatingLoanData: undefined,
        calculatingRaisingData: undefined,
        calculatingPensionData: undefined,
        error: null,
      };

    default:
      return state;
  }
};

export { reducer as calculatorsReducer };
