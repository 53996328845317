import React, { Fragment } from 'react';
import moment from 'moment';

import { Table, TableBody, TableCell, TableCellHead, TableHead, TableRow } from '../../../../../components/Table';
import { PartnerCard } from '../../../types';
// import { getTableCellAlign } from '../../../utils';
import Button from '../../../../../components/Button/Button';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import DatePicker from '../../../../../components/DatePicker/DatePicker';
import styled from '../../../../../styled/styled-components';
import CommentField, { onChangeCommentField } from './CommentField';
import CardNumberField, { onChangeCardNumberField } from './CardNumberField';

const StyledDatePicker = styled(DatePicker)`
  display: inline;
`;

const PARTNER_CARD_FIELDS = new Map<string, string>([
  ['FullNumber', 'Номер карты'],
  ['ExpirationDate', 'Истекает'],
  ['Comment', 'Комментарий']
]);

export interface CardsTableProps {
  data: PartnerCard[];
  editing: boolean;
  onChange: (cards: PartnerCard[]) => void;
}

interface ExpirationDateProps {
  editing: boolean;
  data: PartnerCard;
  onChange: (date: Date, event: any) => void;
}

const ExpirationDate = ({ editing, data, onChange }: ExpirationDateProps) => editing
  ? (
    <StyledDatePicker
      selected={moment(data.ExpirationDate).toDate()}
      onChange={onChange}
      dateFormat="MM/yy"
      showYearDropdown
      showMonthDropdown
      maxDate={moment().add(6, 'y').toDate()}
      minDate={moment().subtract(6, 'y').toDate()}
      disabledKeyboardNavigation
    />
  )
  : (
    <span>{data.ExpirationDate ? moment(data.ExpirationDate).format('MM/YY') : ''}</span>
  );

export default function CardsTable(props: CardsTableProps) {
  const { data, editing, onChange } = props;

  const confirmDialogRef = React.createRef<ConfirmDialog>();

  const deleteRow = (index: number) => () => {
    if (!data[index]) {
      return;
    }

    if (!confirmDialogRef.current) {
      return;
    }

    const deleteItem = () => {
      const newCards = data.filter(
        (_item, _index) => _index !== index
      );
      onChange(newCards);
    };

    if (data[index].Id === null) {
      deleteItem();
    } else {
      confirmDialogRef.current.open(
        'Вы действительно хотите удалить выбранный элемент?',
        (allow: boolean) => allow && deleteItem()
      );
    }
  };

  const handleChangeCardNumberField = onChangeCardNumberField(data, onChange);

  const handleChangeExpirationDate = (index: number) => (date: Date, event: any) => {
    const newData = [...data];
    newData[index].ExpirationDate = moment(date).format('YYYY-MM');
    onChange(newData);
  };

  const handleChangeComment = onChangeCommentField<PartnerCard>(data, onChange);

  return (
    <Fragment>
      <Table>
        <TableHead>
          <TableRow>
            {[...PARTNER_CARD_FIELDS.entries()].map(([fieldName, label]) => (
              <TableCellHead key={fieldName}>
                {label}
              </TableCellHead>
            ))}
            {editing && <TableCellHead>Действие</TableCellHead>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={item.Id || -index}>
              <TableCell align="center">
                <CardNumberField
                  editing={editing}
                  data={item}
                  onChange={handleChangeCardNumberField(index)}
                />
              </TableCell>
              <TableCell align="center">
                <ExpirationDate
                  editing={editing}
                  data={item}
                  onChange={handleChangeExpirationDate(index)}
                />
              </TableCell>
              <TableCell align="center">
                <CommentField
                  editing={editing}
                  data={item}
                  onChange={handleChangeComment(index)}
                />
              </TableCell>
              {editing && (
                <TableCell align="center">
                  <Button
                    onClick={deleteRow(index)}
                    type="success"
                  >
                    Удалить
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmDialog ref={confirmDialogRef} />
    </Fragment>
  );
}
