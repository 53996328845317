import * as React from 'react';

const CurrencyLabel: React.SFC<{ currency?: string }> = ({ currency, children, ...props }) => (
  <span {...props}>
    {typeof children === 'number'
      ? children.toLocaleString('ru', {
          style: currency ? 'currency' : 'decimal',
          currency,
        })
      : children}
  </span>
);

export default CurrencyLabel;
