import { Reducer } from "redux";
import { ActionTypes } from "./actions";
import { AuthState } from "./types";

const initialState: AuthState = {
  data: undefined,
  loading: false,
  isAuthenticated: false,
  error: null,
  accessDenied: false,
  accessSections: {
    data: [],
    error: null,
    loading: false,
  }
};

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.AUTH_FETCH_USER_REQUEST:
      return {
        ...state,
        data: undefined,
        loading: true,
        isAuthenticated: false,
        error: null,
      };

    case ActionTypes.AUTH_FETCH_USER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        isAuthenticated: true,
        error: null,
      };

    case ActionTypes.AUTH_FETCH_USER_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };

    case ActionTypes.ACCESS_DENIED:
      return {
        ...state,
        accessDenied: true,
      };

    case ActionTypes.GET_ACCESS_SECTIONS_REQUEST:
      return {
        ...state,
        accessSections: {
          data: [],
          error: null,
          loading: true,
        }
      };

    case ActionTypes.GET_ACCESS_SECTIONS_SUCCESS:
      return {
        ...state,
        accessSections: {
          data: action.payload,
          error: null,
          loading: false,
        }
      };

    case ActionTypes.GET_ACCESS_SECTIONS_ERROR:
      return {
        ...state,
        accessSections: {
          data: [],
          error: action.payload,
          loading: false,
        }
      };

    default:
      return state;
  }
};

export { reducer as authReducer };
