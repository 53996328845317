import { all, call, put, takeEvery } from "redux-saga/effects";
import { AnyAction } from "redux";
import {
  searchPartnersSuccess,
  searchPartnersError,
  getLoanProductsSuccess,
  getLoanProductsError,
  getLoanProductConditionsSuccess,
  getLoanProductConditionsError,
  ActionTypes
} from "./actions";
import { Partner, LoanProducts, LoanProductConditions } from "./types";
import ApiClient from "../../services/api";

function* searchParthners(action: AnyAction) {
  try {
    const result: Partner[] = yield call(ApiClient.clientBaseFilter, { filter: action.payload.Number });

    yield put(searchPartnersSuccess(result));
  } catch (error) {
    yield put(searchPartnersError(error));
  }
}

function* getLoanProducts(action: AnyAction) {
  try {
    const result: LoanProducts = yield call(ApiClient.getLoanProducts, action.payload);

    yield put(getLoanProductsSuccess(result));
  } catch (error) {
    yield put(getLoanProductsError(error));
  }
}

function* getLoanProductConditions(action: AnyAction) {
  try {
    const result: LoanProductConditions = yield call(ApiClient.getLoanProductConditions, action.payload);

    yield put(getLoanProductConditionsSuccess(result));
  } catch (error) {
    yield put(getLoanProductConditionsError(error));
  }
}

function* formsSaga() {
  yield all([
    yield takeEvery(ActionTypes.SEARCH_PARTNERS_REQUESTS, searchParthners),
    yield takeEvery(ActionTypes.GET_LOAN_PRODUCTS_REQUESTS, getLoanProducts),
    yield takeEvery(ActionTypes.GET_LOAN_PRODUCT_CONDITIONS_REQUESTS, getLoanProductConditions),
  ]);
}

export default formsSaga;
