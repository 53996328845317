import * as React from "react";
import { Country, Region } from "../../store/regions";
import { connect } from "react-redux";
import { AppState } from "../../store";
import { Dispatch } from "redux";
import { fetchRegionsRequest } from "../../store/regions/actions";
import Autocomplete from "../Autocomplete";
import { Nullable } from "../../types/typeUtils";

interface RegionSelectorProps {
  countryId: number;
  countries: Country[];
  fetchRegions: typeof fetchRegionsRequest;
  value?: number;
  onChange?: (value: Nullable<number>) => void;
  disabled?: boolean;
}

const RegionSelector: React.SFC<RegionSelectorProps> = props => {
  const country = props.countries.find(({ id }) => id === props.countryId);

  if (country && !country.regions.length) {
    props.fetchRegions(country.id);
    return null;
  }

  const getItems = (query: string) => {
    if (!country) {
      return [];
    }

    return country.regions.filter(
      item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  };

  if (country) {
    const selectedRegion = country.regions.find(
      item => item.id === props.value
    );
    const handleChange = (item: Region) => {
      if (props.onChange) {
        props.onChange(item ? item.id : null);
      }
    };

    return (
      <Autocomplete
        onSelectItem={handleChange}
        value={selectedRegion}
        getItems={getItems}
        disabled={props.disabled}
      />
    );
  }

  return null;
};

const mapStateToProps = (state: AppState) => ({
  countries: state.regions.countries
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchRegions: (countryId: number) => dispatch(fetchRegionsRequest(countryId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionSelector);
