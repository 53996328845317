import * as React from "react";
import { RouteComponentProps } from "react-router";
import moment from "moment";

import { Container, Header } from "../components/Views";
import Chart from "../../../components/Chart/Chart";
import CurrencyFilter from "../../../components/CurrencyFilter/CurrencyFilter";
import PeriodFilter from "../../../components/PeriodFilter/PeriodFilter";
import CompareFilter from "../../../components/CompareFilter/CompareFilter";
import {
  getTickInterval,
  handleChangeCompare,
  handleChangeCurrency,
  handleChangePeriod,
  getCurrencyCode,
  getPeriodType
} from "../../../helpers/chartHelpers";
import { AnalyticsState, DepositCountLine, SalarySumsLine } from "../types";
import { getDefaultState, getLineChartData } from "../utils";
import AnalyticsApiClient from "../services/api";
import { showErrorToast } from "../../../components/ErrorToast";
import LoadingOverlay from "../../../components/LoadingOverlay";

const nameFormatter = (line: DepositCountLine, firstDate?: string, lastDate?: string) =>
  `Остатки по счетам (${line.Currency}): ${firstDate} - ${lastDate}`;

export interface SalarySumsProps extends RouteComponentProps {
}

export interface SalarySumsState extends AnalyticsState {
  data: SalarySumsLine[],
}

export default class SalarySums extends React.Component<SalarySumsProps, SalarySumsState> {
  private readonly handleChangeCompare: any;
  private readonly handleChangePeriod: any;
  private readonly handleChangeCurrency: any;

  constructor(props: Readonly<SalarySumsProps>) {
    super(props);

    this.state = getDefaultState();

    this.handleChangeCompare = handleChangeCompare.bind(this);
    this.handleChangeCurrency = handleChangeCurrency.bind(this);
    this.handleChangePeriod = handleChangePeriod.bind(this);
  }

  public componentDidMount() {
    this.loadData();
  }

  public render() {
    const {
      filters: {
        currency, period, dateFrom, dateTo, compare
      },
      data = [],
      loading,
    } = this.state;

    return (
      <Container>
        <LoadingOverlay loading={loading === "pending"}>
          <Header>
            <CurrencyFilter active={currency} onChange={this.handleChangeCurrency} />
            <PeriodFilter
              activePeriod={period}
              dateFrom={dateFrom}
              dateTo={dateTo}
              onChange={this.handleChangePeriod}
            />
            {period !== "year" && period !== "currentYear" ? null : (
              <CompareFilter compare={compare} onChange={this.handleChangeCompare} />
            )}
          </Header>
          <Chart
            series={getLineChartData(data, nameFormatter)}
            minTickInterval={getTickInterval(period)}
            title="Остатки по счетам"
            xLabel="Период"
            yLabel="Сумма"
            xDateFormat={period !== "month" && period !== "currentMonth" ? "%B" : "%d %B"}
          />
        </LoadingOverlay>
      </Container>
    );
  }

  private async loadData() {
    try {
      const {
        currency, period, dateFrom, dateTo, compare
      } = this.state.filters;

      const params = {
        currency: getCurrencyCode(currency),
        periodType: getPeriodType(period),
        dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
        dateTo: moment(dateTo).format("YYYY-MM-DD"),
        compare: period !== "year" && period !== "currentYear" ? undefined : compare
      };
      this.setState({ loading: "pending" });
      const data = await AnalyticsApiClient.getSalarySums(params);

      this.setState({ data });
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: "ok" });
    }
  }
}
