import React, { PureComponent } from 'react';
import { toLower } from  'lodash';

import { AdditionalFormProps, Product } from "../../types";
import styled from "../../../../styled/styled-components";
import Button from "../../../../components/Button/Button";
import Autocomplete from "../../../../components/Autocomplete";

export const FormWrapper = styled.div`
  border: 1px solid black;
  border-top: none;
`;

export interface AdditionalFormState {
  value?: Product;
}

export interface AdditionalFormExtraProps {
  addNewProduct: (value?: Product) => void;
}

export default class AdditionalForm extends PureComponent<AdditionalFormProps & AdditionalFormExtraProps, AdditionalFormState>{
  public state: AdditionalFormState = {
    value: undefined,
  };

  public render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    const { value } = this.state;

    const getAutocompleteItems = (query: string) => {
      const { freeProducts } = this.props;

      return freeProducts.filter((item: Product) => (toLower(item.Name).includes(toLower(query))));
    };

    const renderItem = (item: Product) => {

      return item ? item.Name : '';
    };

    const renderDropDownItem = (item: Product) => {

      return item ? item.Name : '';
    };

    return (
      <FormWrapper className="row no-gutters">
        <div className="col-md-7 p-2 col-12">
          <Autocomplete
            value={value}
            getItems={getAutocompleteItems}
            renderDropDownItem={renderDropDownItem}
            onSelectItem={this.onSelectItem}
            renderItem={renderItem}
            onInputChange={this.onInputChange}
          />
        </div>
        <div className="col-md-5 p-2 col-12">
          <Button className="w-100" type="success" onClick={this.addNew}>Добавить</Button>
        </div>
      </FormWrapper>
    );
  }

  private onSelectItem = (value: Product) => {
    this.setState({
      value
    })
  };

  private onInputChange = (inputValue: string) => {
    const { value } = this.state;
    if (value && value.Name !== inputValue) {
      this.setState({
        value: undefined,
      })
    }
  };

  private addNew = () => {
    const { addNewProduct } = this.props;
    const { value } = this.state;
    addNewProduct(value);
    this.setState({
      value: undefined,
    })
  }
}
