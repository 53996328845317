import * as React from 'react';
import { ChangeEvent } from "react";
import { pick } from 'lodash';
import LoadingOverlay from "../../components/LoadingOverlay";
import Helmet from "react-helmet";
import styled from "../../styled/styled-components";
import Button from "../../components/Button/Button";
import Input from "../../components/FormControls/Input";
import ApiClient from "../../services/api";
import { TokenStorage } from "../../services/TokenStorage";

export interface LoginState {
  loading: boolean;
  Login: string;
  Password: string;
  Code: string;
  Error: string;
  NeedCode: boolean;
}
export interface LoginProps {
  updateIsAuthenticated: () => void;
}

const LoginWrapper = styled.div`
  width: 300px;
  max-width: 400px;
  z-index: 100;
  margin: 0 auto;
  padding-top: 40px;
  text-align: center;
`;

const ButtonW100 = styled(Button)`
  width: 100%;
`;

const ErrorWrapper = styled.div`
  width: 100%;
  color: #ED5565;
  text-align: center;
  font-size: 14px;
`;

const NEED_CODE_MESSAGE = 'Отправлен код подтвеждения по СМС';
const TIME_CODE_MESSAGE = 'Истек срок действия кода подтверждения';

export default class Login extends React.PureComponent<LoginProps, LoginState> {
  public state: LoginState = {
    loading: false,
    Login: '',
    Password: '',
    Code: '',
    Error: '',
    NeedCode: false,
  };

  public render() {
    const { Error, Login, Password, Code, NeedCode } = this.state;
    return (
      <LoadingOverlay loading={this.state.loading}>
        <Helmet defer={false}>
          <title>Вход</title>
        </Helmet>
        <LoginWrapper>
          Вход
          {Error && <ErrorWrapper>{Error}</ErrorWrapper>}
          <Input disabled={NeedCode} value={Login} onChange={this.onChange} style={{ width: '100%', marginBottom: '15px', marginTop: '15px' }} name='Login' placeholder="Логин" />
          <Input disabled={NeedCode} value={Password} onChange={this.onChange} style={{ width: '100%', marginBottom: '15px' }} name='Password' type="Password" placeholder="Пароль" />
          {NeedCode && <Input disabled={!NeedCode} value={Code} onChange={this.onChange} style={{ width: '100%', marginBottom: '15px' }} name='Code' placeholder="Введите код" />}
          <ButtonW100 onClick={this.submitForm} type="success">
            Вход
          </ButtonW100>
        </LoginWrapper>
      </LoadingOverlay>
    );
  }

  private onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    // @ts-ignore
    // @ts-ignore
    this.setState({ [name]: value});
  };

  private submitForm = async () => {
    this.setState({
      loading: true,
      Error: '',
    });
    try {
      const result = await ApiClient.loginUser(pick(this.state, ['Login', 'Password', 'Code']));
      if (result.Message === NEED_CODE_MESSAGE) {
        this.setState({
          NeedCode: true,
        });
      } else {
        TokenStorage.storeToken(result[TokenStorage.LOCAL_STORAGE_TOKEN]);
        TokenStorage.storeRefreshToken(result[TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN]);
      }
    } catch (e) {
      if (e.Message === TIME_CODE_MESSAGE) {
        this.setState({
          NeedCode: false,
          Code: '',
        });
      }
      const Error = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
      this.setState({
        Error,
      });
    } finally {
      this.setState({
        loading: false,
      }, () => this.props.updateIsAuthenticated());

    }

  }
}
