import { GroupItem, FilterFieldName, FilterItemData, SortDirection } from "../types";

export const AUTOCOMPLETE_FILTER_FIELDS = [
  "PartnerNumber"
];

export const GROUP_FIELD_LABELS = new Map<keyof GroupItem, string>([
  ["GroupName", "Название группы"],
  ["CreateDate", "Дата создания"],
  ["IsActive", "Статус"]
]);

export const DEFAULT_FILTER_FIELDS = new Map<
  FilterFieldName<GroupItem>,
  FilterItemData
  >([
  [
    "GroupName",
    {
      FieldName: "GroupName",
      Method: 1,
      Value: null,
      ValueType: 1
    }
  ],
  [
    "CreateDate",
    {
      FieldName: "CreateDate",
      Value: null,
      Method: 4,
      ValueType: 4
    }
  ],
  [
    "IsActive",
    {
      FieldName: "IsActive",
      Value: null,
      Method: 0,
      ValueType: 3
    }
  ]
]);

export const DEFAULT_SORT_DIRECTION: SortDirection = 2;

export const DEFAULT_SORT_FIELD_NAME: FilterFieldName<GroupItem> =
  "CreateDate";
