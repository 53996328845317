import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { ChevronDown } from 'styled-icons/feather';
import Item from './Item';

class TopSubmenu extends Component {
  render() {
    const { items } = this.props;
    return (
      <Fragment>
        <StyledIcon />
        <StyledMenu>{items.map(item => <Item {...item} />)}</StyledMenu>
      </Fragment>
    );
  }
}

const StyledIcon = styled(ChevronDown)`
  display: flex;
  align-self: flex-end;
  width: 20px;
  height: 20px;
`;
const StyledMenu = styled(Flex).attrs({ justifyStart: true, full: true, column: true })`
   display: none;
   position: absolute;
   top: 30px;
   left: -2px;

   min-width: 200px;
   height: auto;

   border-radius: 3px;
   border: 2px solid ${({ theme }) => theme.colors.lightGray};
   background-color: ${({ theme }) => theme.colors.white};
 `;

export default TopSubmenu;
export { StyledMenu as StyledTopSubmenu };
