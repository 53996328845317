import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import autoBind from 'react-autobind';
import moment from 'moment';
import Helmet from 'react-helmet';

import './Publications.scss';
import apiClient from '../../services/api';
import getRandomKey from '../../helpers/getRandomKey';
import PlainSpinner from '../../components/PlainSpinner/PlainSpinner';
import BackLink from '../../components/BackLink';

class Publications extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      publications: [],
      type: 'all',
      status: 'ok',
    };
  }

  componentDidMount() {
    this.loadPublications();
  }

  async loadPublications() {
    try {
      this.setState({ status: 'waiting' });
      const result = await apiClient.getPublications(this.state.type);

      this.setState({
        status: 'ok',
        publications: result.publications,
      });
    } catch (e) {
      this.setState({
        status: 'error',
      });
    }
  }

  renderDate(date) {
    return date ? moment(date).format('YYYY-MM-DD') : '';
  }

  buildPublications() {
    const { publications } = this.state;

    if (publications.length < 1) {
      return (
        <tr>
          <td colSpan={5}>Нет публикаций</td>
        </tr>
      );
    }

    return this.state.publications.map(item => (
      <tr className="d-flex" key={getRandomKey('publications')}>
        <td className="col-4">{item.Title}</td>
        <td className="col-2">{this.renderDate(item.StartDate)}</td>
        <td className="col-2">{this.renderDate(item.EndDate)}</td>
        <td className="col-2">
          {item.IsNews ? 'Новость' : item.IsNotification ? 'Уведомление' : ''}
        </td>
        <td className="col-2">
          <button
            className="publications__button-edit"
            onClick={() => {
              this.props.history.push(`/tools/publications/${item.Id}`);
            }}
          >
            Редактировать
          </button>
          {/* <button>Удалить</button> */}
        </td>
      </tr>
    ));
  }

  handleChangeType(event) {
    const { value } = event.target;

    this.setState({ type: value }, () => this.loadPublications());
  }

  render() {
    const { status } = this.state;
    return (
      <div className="publications">
        <Helmet >
          <title>Публикации</title>
        </Helmet>
        <div className="publications__header">
          <BackLink title={document.title} >
            <h4>Управление публикациями</h4>
          </BackLink>
          <button
            className="publications__add"
            onClick={() => {
              this.props.history.push('/tools/publications/new');
            }}
          >
            Добавить публикацию
          </button>
        </div>
        <hr />
        <div className="publications__content">
          <div className="row pb-3">
            <div className="col">
              <select value={this.state.type} onChange={this.handleChangeType}>
                <option value="all">Все публикации</option>
                <option value="news">Только новости</option>
                <option value="notices">Только уведомления</option>
                {/* <option>Только рассылки</option> */}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {status === 'waiting' ? (
                <PlainSpinner />
              ) : (
                <table className="table">
                  <thead>
                    <tr className="d-flex">
                      <th className="col-4">Заголовок</th>
                      <th className="col-2">Начало публикации</th>
                      <th className="col-2">Конец публикации</th>
                      <th className="col-2">Тип</th>
                      <th className="col-2" />
                    </tr>
                  </thead>
                  <tbody>{this.buildPublications()}</tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const PublicationsWithRouter = withRouter(Publications);
export default PublicationsWithRouter;
