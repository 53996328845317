import { createAction } from "typesafe-actions";
import { Bill } from "./types";

export const ActionTypes = {
    GET_COMMON_BILL_FOR_OPERATION_REQUEST: "@@BILLS/GET_COMMON_BILL_FOR_OPERATION_REQUEST",
    GET_COMMON_BILL_FOR_OPERATION_SUCCESS: "@@BILLS/GET_COMMON_BILL_FOR_OPERATION_SUCCESS",
    GET_COMMON_BILL_FOR_OPERATION_ERROR: "@@BILLS/GET_COMMON_BILL_FOR_OPERATION_ERROR",
};

export const getCommonBillForOperationRequest = createAction(
    ActionTypes.GET_COMMON_BILL_FOR_OPERATION_REQUEST
);

export const getCommonBillForOperationSuccess = createAction(
    ActionTypes.GET_COMMON_BILL_FOR_OPERATION_SUCCESS,
    resolve => (data: Bill[]) =>
        resolve(data)
);

export const getCommonBillForOperationError = createAction(
    ActionTypes.GET_COMMON_BILL_FOR_OPERATION_ERROR,
    resolve => (error: any) =>
        resolve(error)
);