import React from 'react';

import { Consumer } from './context';

export default function withActiveInfo(WComponent) {
  return function ConnectedComponent(props) {
    return (
      <Consumer>
        {({ activeId }) => <WComponent {...props} active={activeId.indexOf(props.url) >= 0} />}
      </Consumer>
    );
  };
}
