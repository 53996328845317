import * as React from "react";
import { ModalProvider } from "styled-react-modal";
import apiClient from "../../services/api";
import OperationsTable from "./OperationsTable";
import Pagination from "../../components/Pagination/Pagination";
import { RouteComponentProps } from "react-router";
import operationTypeCodes from "../../constants/operationTypeCodes";
import SummaryType from "./Summary";
import DetailsModal from "./DetailsModal";
import RemoveDialog from "./RemoveDialog";
import Button from "../../components/Button/Button";
import styled from "styled-components";
import DownloadButton from "./DownloadButton";
import { NONE_VALUE } from "../../constants";
import Helmet from 'react-helmet';
import BackLink from "../../components/BackLink";

export interface Operation {
  OperationId: number;
  NumberOfPartner: string;
  BillNumber: string;
  OperationType: string;
  OperationName: string;
  PayOut: number | null;
  PayIn: number | null;
  CurrencyName: string;
  StaffName: string;
  OperationDate: string;
  Canceled: boolean;
}

export interface SummaryType {
  Currency: string;
  SumPayIn: number;
  SumPayOut: number;
}

export interface FilterDateValue {
  dateStart?: string;
  dateEnd?: string;
}

export interface Filter {
  currency?: number | null;
  operationId?: number | null;
  numberBill?: number | null;
  numberOfPartner?: string | null;
  staffName?: string | null;
  operationName?: string | null;
  dateStart?: string;
  dateEnd?: string;
  type?: keyof typeof operationTypeCodes;
  operationSum?: number | null;
}

export interface QueryParams extends Filter {
  page?: number | null;
}

export interface OperationStaffProps extends RouteComponentProps {}

export interface OperationStaffState {
  operations: Operation[];
  pageSize: number;
  totalCount: number;
  currentPage: number;
  filter: Filter;
  summary: SummaryType[];
  modalOpened: boolean;
  modalOperationId?: number;
  loading: boolean;
  loadingFile: boolean;
  removedOperationIds: number[];
}

export interface OperationStaffContext {
  operations: Operation[];
  toggleModal?: (operationId?: number) => void;
  onRemoveClick?: (operationId?: number) => void;
  removedOperationIds: number[];
}

export const OperationContext = React.createContext<OperationStaffContext>({
  operations: [],
  removedOperationIds: []
});

const TopButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px 0;

  & > div {
    ${Button} {
      margin-left: 10px;
    }
  }
`;

export default class OperationStaff extends React.Component<
  OperationStaffProps,
  OperationStaffState
> {
  private detailsModal: DetailsModal | null = null;
  private removeDialog: RemoveDialog | null = null;

  constructor(props: OperationStaffProps) {
    super(props);

    const initialState = {
      operations: [],
      pageSize: 0,
      totalCount: 0,
      currentPage: this.getCurrentPage(props.location.search),
      filter: {},
      summary: [],
      modalOpened: false,
      loading: false,
      loadingFile: false,
      removedOperationIds: []
    };

    this.state = this.getNewState(initialState, props.location.search);
  }

  public componentDidMount() {
    this.getOperations();
  }

  public render() {
    const returnUrl = `${encodeURIComponent(document.location.pathname)}${encodeURIComponent(document.location.search)}`;
    const operationUrl = `/Operation/OperationProcess?returnUrl=${returnUrl}`;
    return (
      <ModalProvider>
        <Helmet >
          <title>Операции</title>
        </Helmet>
        <TopButtons>
          <BackLink title={document.title} >
            <DownloadButton
              disabled={this.state.loadingFile}
              onClick={this.handleClickDownload}
            />
          </BackLink>
          <div>
            <Button href={operationUrl} type="primary">
              Добавить операцию
            </Button>
            <Button
              disabled={!this.hasFilters()}
              onClick={this.resetAllFilters}
              type="primary"
            >
              Очистить
            </Button>
          </div>
        </TopButtons>
        <OperationContext.Provider
          value={{
            operations: this.state.operations,
            toggleModal: this.openDetailsModal,
            onRemoveClick: this.openRemoveDialog,
            removedOperationIds: this.state.removedOperationIds
          }}
        >
          <OperationsTable
            onChangeFilter={this.handleChangeFilter}
            filter={this.state.filter}
          />
        </OperationContext.Provider>
        <SummaryType data={this.state.summary} />
        <Pagination
          page={this.state.currentPage}
          size={this.state.pageSize}
          count={this.state.totalCount}
          callback={this.handleChangePage}
        />
        <DetailsModal ref={this.refDetailsModal} />
        <RemoveDialog
          onRemoveOperation={this.handleRemoveOperations}
          ref={this.refRemoveDialog}
        />
      </ModalProvider>
    );
  }

  private openDetailsModal = (operationId?: number) => {
    if (this.detailsModal) {
      this.detailsModal.open(operationId);
    }
  };

  private openRemoveDialog = (operationId?: number) => {
    if (this.removeDialog && operationId !== undefined) {
      this.removeDialog.open(operationId);
    }
  };

  private getOperations = async () => {
    const queryParams: Filter & { page?: number } = {
      ...this.state.filter
    };

    if (this.state.currentPage !== 1) {
      queryParams.page = this.state.currentPage;
    }

    const result = await apiClient.getOperations(queryParams);

    this.filtersToSearch();

    this.setState({
      operations: result.InfoList,
      pageSize: result.PageSize,
      totalCount: result.TotalRecords,
      summary: result.Sums
    });
  };

  private handleChangePage = (page: number) => {
    this.setState(
      {
        currentPage: page
      },
      this.getOperations
    );
  };

  private filtersToSearch = () => {
    const { filter, currentPage } = this.state;
    const queryParams = new URLSearchParams();

    for (const name in filter) {
      if (filter.hasOwnProperty(name)) {
        const value = filter[name as keyof Filter];

        if (value !== null && value !== undefined) {
          queryParams.set(name, value.toString());
        } else {
          queryParams.delete(name);
        }
      }
    }

    if (currentPage && currentPage !== 1) {
      queryParams.set("page", currentPage.toString());
    }

    this.props.history.replace(
      `${this.props.location.pathname}?${queryParams.toString()}`
    );
  };

  private getCurrentPage = (search: string) => {
    const urlParams = new URLSearchParams(search);
    const currentPage = urlParams.get("page");

    return currentPage ? parseInt(currentPage, 10) : 1;
  };

  private handleChangeFilter = (
    name: string,
    value: string | number | FilterDateValue
  ) => {
    const getStateItem = () => {
      const stateItem: Partial<OperationStaffState["filter"]> = {};
      if (name === "date" && typeof value === "object") {
        // not allow date start greate then date end
        if (value.dateStart
          && value.dateEnd
          && (new Date(value.dateStart.replace( /(\d{2}).(\d{2}).(\d{4})/, "$2/$1/$3")) > new Date(value.dateEnd.replace( /(\d{2}).(\d{2}).(\d{4})/, "$2/$1/$3")))) {
          stateItem.dateStart = value.dateEnd;
          stateItem.dateEnd = value.dateStart;
        } else {
          stateItem.dateStart = value.dateStart;
          stateItem.dateEnd = value.dateEnd;
        }
      } else {
        stateItem[name as keyof OperationStaffState["filter"]] = (!value ||
        value === NONE_VALUE
          ? undefined
          : value) as string | number | undefined;
      }

      return stateItem;
    };
    this.setState(
      state => ({
        filter: {
          ...state.filter,
          ...getStateItem()
        },
        // TODO: Завести константу под первую страницу
        currentPage: 1
      }),
      this.getOperations
    );
  };

  private resetAllFilters = () => {
    this.setState({ filter: {} }, this.getOperations);
  };

  private getNewState = (
    currentState: OperationStaffState,
    searchString: string
  ) => {
    const newState: OperationStaffState = {
      ...currentState,
      filter: {
        ...currentState.filter
      }
    };

    const urlParams = new URLSearchParams(searchString);

    urlParams.forEach((value, key) => {
      switch (key) {
        case "operationId":
        case "numberBill":
        case "operationSum":
          newState.filter![key as keyof Filter] = value
            ? parseInt(value, 10)
            : null;
          break;

        case "currency":
        case "type":
          newState.filter![key as keyof Filter] = value
            ? parseInt(value, 10)
            : NONE_VALUE;
          break;

        case "dateStart":
        case "dateEnd":
        case "staffName":
        case "operationName":
        case "numberOfPartner":
          newState.filter![key as keyof Filter] = value;
          break;

        case "page":
          const page = parseInt(value, 10);
          if (page && page !== 1) {
            newState.currentPage = page;
          }
          break;

        default:
          break;
      }
    });

    // not allow date start greate then date end
    if (newState.filter.dateStart
      && newState.filter.dateEnd
      && (new Date(newState.filter.dateStart.replace( /(\d{2}).(\d{2}).(\d{4})/, "$2/$1/$3")) > new Date(newState.filter.dateEnd.replace( /(\d{2}).(\d{2}).(\d{4})/, "$2/$1/$3")))) {
      const tempData = newState.filter.dateStart;
      newState.filter.dateStart =  newState.filter.dateEnd;
      newState.filter.dateEnd = tempData;
    }

    return newState;
  };

  private refDetailsModal = (element: DetailsModal) => {
    this.detailsModal = element;
  };

  private refRemoveDialog = (element: RemoveDialog) => {
    this.removeDialog = element;
  };

  private hasFilters = () => {
    const { filter } = this.state;

    return Object.entries(filter).some(([key, value]) => value !== undefined);
  };

  private handleRemoveOperations = (operationId: number) => {
    this.setState(state => ({
      removedOperationIds: [...state.removedOperationIds, operationId]
    }));
    this.getOperations();
  };

  private handleClickDownload = () => {
    this.downLoadFile();
  };

  private downLoadFile = async () => {
    const queryParams: Filter = {
      ...this.state.filter
    };

    try {
      this.setState({ loadingFile: true });
      await apiClient.downloadOperations(queryParams);
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.log(error);
    } finally {
      this.setState({ loadingFile: false });
    }
  };
}
