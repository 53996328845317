import { call, put, all, take, cancel, fork } from "redux-saga/effects";
import { RegionsActionTypes, ServerResponse, StreetResponse } from "./types";
import ApiClient from "../../services/api";
import {
  fetchCountriesSuccess,
  fetchRegionsSuccess,
  fetchCitiesSuccess,
  fetchStreetsSuccess, saveCountriesSuccess,
} from "./actions";
import { AnyAction } from "redux";

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))


function* fetchCountries() {
  try {
    const result = yield call(ApiClient.getCountries);

    yield put(fetchCountriesSuccess(result as ServerResponse));
    yield put(saveCountriesSuccess(result));
  } catch (error) {
    yield put({ type: RegionsActionTypes.FETCH_COUNTRIES_ERROR });
  }
}

function* fetchRegions(action: AnyAction) {
  const countryId = action.payload;
  try {
    const result = yield call(ApiClient.getRegions.bind(null, countryId));

    yield put(fetchRegionsSuccess(result as ServerResponse, countryId));
  } catch (error) {
    yield put({ type: RegionsActionTypes.FETCH_REGIONS_ERROR });
  }
}

function* fetchCities(action: AnyAction) {
  const { regionId, countryId } = action.payload;
  try {
    const result = yield call(ApiClient.getCities.bind(null, regionId));

    yield put(
      fetchCitiesSuccess(result as ServerResponse, regionId, countryId)
    );
  } catch (error) {
    yield put({ type: RegionsActionTypes.FETCH_CITIES_ERROR });
  }
}

function* fetchStreets(action: AnyAction) {
  yield call(delay, 500)
  const { query, cityId } = action.payload;
  try {

    let result = yield call(ApiClient.getStreets.bind(null, query, cityId));

    result = result.map((item: any) => item[1]);

    yield put(
      fetchStreetsSuccess(result as StreetResponse)
    );
  } catch (error) {
      yield put({ type: RegionsActionTypes.FETCH_STREETS_ERROR });
  }
}

function* watchFetchCountries() {
  while (true) {
    const action = yield take(RegionsActionTypes.FETCH_COUNTRIES_REQUEST);
    yield call(fetchCountries, action);
  }
}

function* watchFetchRegions() {
  const accumulator: number[] = [];

  while (true) {
    const action = yield take(RegionsActionTypes.FETCH_REGIONS_REQUEST);

    const countryId = action.payload;

    if (accumulator.includes(countryId)) {
      return;
    }
    accumulator.push(countryId);
    yield call(fetchRegions, action);
  }
}

function* watchFetchCities() {
  const accumulator: number[] = [];

  while (true) {
    const action = yield take(RegionsActionTypes.FETCH_CITIES_REQUEST);

    const { regionId } = action.payload;

    if (accumulator.includes(regionId)) {
      return;
    }
    accumulator.push(regionId);
    yield call(fetchCities, action);
  }
}

function* watchFetchStreets() {
  let previousQuery = '';
  let task;

  while (true) {
    const action = yield take(RegionsActionTypes.FETCH_STREETS_REQUEST);

    const { query } = action.payload;

    if (query !== previousQuery) {
      if (task) {
        yield cancel(task);
      }

      task = yield fork(fetchStreets, action);
      previousQuery = query;
    }
  }
}

function* regionsSaga() {
  yield all(
    [
      watchFetchCountries(),
      watchFetchRegions(),
      watchFetchCities(),
      watchFetchStreets(),
    ]);
}

export default regionsSaga;
