import { connect } from 'react-redux';
import { Dispatch } from "redux";

import { AppState } from "../../../../store";
import { fetchLabelsRequest } from "../../../../store/labels/actions";

import CreateUsersForm from './CreateUsersForm';

const mapStateToProps = (state: AppState) => ({
  labels: state.labels.labels,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchLabelsRequest: (section: string, billType?: string) => dispatch(fetchLabelsRequest(section, billType)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUsersForm);
