import React, { PureComponent } from "react";

import LoadingOverlay from "../../../components/LoadingOverlay";
import { AccessWrapper } from "../styled/styled";
import styled from "../../../styled/styled-components";


interface ActionNamesListProps {
  selectedActions: string[];
  removeAccessRule: (ActionName: string) => (e: any) => void;
}

const Row = styled.div`
  padding: 3px;
  display: flex;
  align-items: center;
`;

const Button = styled.div`
    font-size: 14px;
    color: #fff;
    background-color: #dc3545;
    border: 0;
    border-radius: 3px;
    height: 30px;
    width: 30px;
    padding: 0 10px;
    user-select: none;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export default class SelectedActionNamesList extends PureComponent<ActionNamesListProps> {
  public render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    const { removeAccessRule, selectedActions } = this.props;
    return (
      <LoadingOverlay loading={false}>
        <AccessWrapper>
          {selectedActions.map((item: string) => (
            <div className="row no-gutters">
              <div className="col-3 py-2 pl-2">
                <Button onClick={removeAccessRule(item)}>X</Button>
              </div>
              <Row className="col-9">
                {item}
              </Row>
            </div>))}
        </AccessWrapper>
      </LoadingOverlay>
    );
  }
}
