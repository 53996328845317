import "babel-polyfill";
import "react-app-polyfill/ie11";
import * as React from "react";
import ReactDOM from "react-dom";
import Main from "./main";
import store from "./configureStore";
import { Provider } from "react-redux";

// try {
//   if ("serviceWorker" in navigator && process.env.NODE_ENV !== "development") {
//     console.log('index.js')
//     navigator.serviceWorker
//       .register("./firebase-messaging-sw.js")
//       .then(function (registration) {
//         console.log("Registration successful, scope is:", registration.scope);
//       })
//       .catch(function (err) {
//         console.log("Service worker registration failed, error:", err);
//       });
//   }
// } catch (e) {
//
// }

ReactDOM.render(
  <Provider store={store}>
    <Main />
  </Provider>,
  document.getElementById("root")
);
