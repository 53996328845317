import * as React from "react";
import { get, isEmpty, isArray, set, debounce, toNumber, isNaN, omit, isNull, find } from "lodash";
import moment from "moment";
import { ifProp } from "styled-tools";
import { toast } from 'react-toastify';

import FormControl from "../FormControl";
import Modal from "../../../../components/Modal";
import {
  LoanFormsState,
  Partner, PartnersSearch,
  LoanProducts, LoanProduct
} from "../../../../store/forms";
import { convertToUtc } from "../../../../utils";
import Button from "../../../../components/Button/Button";
import ErrorMessage from "../../../../modules/Errors/components/ErrorMessage";
import styled, { css } from "../../../../styled/styled-components";

import currencyCodes, { currencyTypes } from "../../../../constants/currencyCodes";
import ApiClient from "../../../../services/api";
import { PENSION_PROGRAM, DEPOSIT__PROGRAM, CHILD_PROGRAM } from "../../../../constants/structurProgramCodes";
import { NONE_VALUE } from "../../../../constants";

export interface LoanFormState {
  userBalance?: number;
  Products: any;
  Childs: any;
  ChildBirthDateEditable: boolean;
  IdAspPartner?: number;
  IdPartner?: string;
  IsMain?: boolean;
  ProductCondId?: number;
  IdParthner?: string;
  Famale?: string,
  Name?: string,
  Patronymic?: string,
  ChildNameId?: number;
  valueTerm: number;
  ChildBirthDate?: string;
  OpeningDate?: string;
  DepositDay?: string;
  CurrencyId?: number;
  Sum?: number;
  DateEndEdit?: string;
  InformPartner?: boolean;
  NdflCount?: boolean;
  ProductId?: number;
  Percent?: number;
  Fine?: number;
  MemberPercent?: number;
  FuncPayName?: string;
  loading: boolean;
  showModal: boolean;
  formErrors: {
    IdAspPartner: string,
    IsMain: string,
    ProductCondId: string,
    IdPartner: string,
    DateEndEdit: string,
    Famale: string,
    Name: string,
    ChildNameId: string,
    ChildBirthDate: string,
    Patronymic: string,
    OpeningDate: string,
    CurrencyId: string,
    Sum: string,
    DepositDay: string,
    InformPartner: string,
    ProductId: string,
    Percent: string,
    MemberPercent: string,
    FuncPayName: string,
    Fine: string,
  },
  formValid: boolean;
  errorMessage: string;
  formLoading: boolean;
}

export interface LoanFormProps {
  type: string;
  partnerNumber?: string;
  searchPartnersRequest: (data: Partner) => void;
  goToList: (path: string, state: any) => void;
  search: PartnersSearch;
  loanForm: LoanFormsState;
}

interface WrapperProps {
  loading?: boolean;
}

const LoadingWrapper = styled.div<WrapperProps>`
    ${ifProp("loading", css`
      background-color: #eee;
  `)}
`;

export default class DepositForm extends React.PureComponent<LoanFormProps> {
  public state:  LoanFormState = {
    userBalance: undefined,
    Products: [],
    Childs: [],
    IdAspPartner: undefined,
    ChildBirthDate: undefined,
    ChildBirthDateEditable: false,
    IsMain: false,
    ProductCondId: undefined,
    IdPartner: "",
    Famale: "",
    Name: "",
    Patronymic: "",
    DateEndEdit: convertToUtc((new Date())).toISOString(),
    OpeningDate: convertToUtc((new Date())).toISOString(),
    CurrencyId: 1,
    Sum: undefined,
    DepositDay: undefined,
    InformPartner: false,
    ProductId: undefined,
    Percent: undefined,
    MemberPercent: undefined,
    ChildNameId: undefined,
    FuncPayName: "",
    Fine: undefined,
    loading: false,
    showModal: false,
    valueTerm: 0,
    formErrors: {
      IdAspPartner: "",
      IsMain: "",
      ChildNameId: "",
      ProductCondId: "",
      DateEndEdit: "",
      IdPartner: "",
      Famale: "",
      ChildBirthDate: "",
      Name: "",
      Patronymic: "",
      OpeningDate: "",
      CurrencyId: "",
      Sum: "",
      DepositDay: "",
      InformPartner: "",
      ProductId: "",
      Percent: "",
      MemberPercent: "",
      FuncPayName: "",
      Fine: "",
    },
    formValid: true,
    errorMessage: "",
    formLoading: false
  };

  public componentDidMount() {
    this.loadPartner(() => {
      const { type } = this.props;

      if (type === CHILD_PROGRAM) {
        this.getUserChilds();
      } else if (type === PENSION_PROGRAM) {
        this.loadPensionPartner();
      }
    });
  }

  public getCurrentUserBalance = debounce(async () => {
    const { CurrencyId, IdPartner } = this.state;
    if (CurrencyId && IdPartner) {
      try {
        const result = await ApiClient.getCurrentBalance({
          currency: CurrencyId,
          partnerNumber: IdPartner,
        });

        this.setState({
          userBalance: result.Balance,
        })
      } catch (e) {
        this.setState({
          userBalance: undefined,
        })
      }
    } else {
      this.setState({
        userBalance: undefined,
      })
    }
  }, 1000);


  public getUserChilds = debounce(async () => {
    const { IdPartner, OpeningDate, ChildNameId: settedChildId } = this.state;
    if (IdPartner) {
      try {
        let result = (await ApiClient.getPartnerChilds(IdPartner)) || [];
        result = result.filter(this.filterChild);
        if (isEmpty(result)) {
          toast('Данных по детям не найдено. Для заведения программы сначала необходимо добавить ребёнка на странице пайщика', {
            type: toast.TYPE.ERROR
          });
          return this.setState({
            formValid: false,
            errorMessage: 'Данных по детям не найдено. Для заведения программы сначала необходимо добавить ребёнка на странице пайщика',
            Childs: result,
            ChildNameId: undefined,
            ChildBirthDate: undefined,
            ChildBirthDateEditable: false,
          })
        }


        if (settedChildId) {
          const userExist = find(result, (item) => { return get(item, 'Id') === settedChildId})

          if (userExist) {
            this.setState({
              Childs: result,
            });
          }
        return ;
        }

        const ChildNameId = get(result, '[0].Id', undefined);
        const ChildBirthDate = get(result, '[0].BirthDate', undefined);
        const ChildBirthDateEditable = !get(result, '[0].BirthDate', false);
        const DateEndEdit = moment(ChildBirthDate).add('years', 18);
        const DepositDay = moment(DateEndEdit).diff(moment(OpeningDate), "days");

        this.setState({
          Childs: result,
          ChildNameId,
          ChildBirthDate: moment(ChildBirthDate).toISOString(),
          ChildBirthDateEditable,
          DateEndEdit: DateEndEdit.toISOString(),
          DepositDay,
        });
      } catch (e) {
        this.setState({
          Childs: [],
          ChildNameId: undefined,
          ChildBirthDate: undefined,
          ChildBirthDateEditable: false,
        })
      }
    } else {
      this.setState({
        Childs: [],
        ChildNameId: undefined,
        ChildBirthDate: undefined,
        ChildBirthDateEditable: false,
      })
    }
  }, 1000);

  public filterChild = (child: any) => (child.RelationTypeId === 1 || child.RelationTypeId === 2) && (isNull(child.BirthDate) || moment(child.BirthDate).add('years', 18).isAfter(moment()));

  public componentDidUpdate(prevProps: any) {
    if (prevProps.type !== PENSION_PROGRAM && this.props.type === PENSION_PROGRAM) {
      this.loadPensionPartner();
      this.setState({
        valueTerm: 0,
      });
    }

    if (prevProps.type !== CHILD_PROGRAM && this.props.type === CHILD_PROGRAM) {
      this.getUserChilds();
      this.setState({
        valueTerm: 0,
      });
    }

    if (prevProps.type !== this.props.type) {
      this.loadProducts();
      this.setState({
        formValid: true,
        errorMessage: '',
        Products: [],
        Childs: [],
        formLoading: false
      })
    }
  }

  public render() {
    const {
      formErrors,
      CurrencyId,
      Famale,
      IdPartner,
      Name,
      Patronymic,
      OpeningDate,
      Sum,
      DepositDay,
      InformPartner,
      Percent,
      Fine,
      FuncPayName,
      MemberPercent,
      IsMain,
      ProductId,
      formValid,
      errorMessage,
      formLoading,
      DateEndEdit,
      ChildNameId,
      ChildBirthDate,
      ChildBirthDateEditable,
      loading,
      ProductCondId,
      valueTerm,
      showModal
    } = this.state;
    const { search: { data: searchData }, type } = this.props;
    const products = get(this.state, "Products", []) || [];
    const data = products.map((item: LoanProduct) => ([item.Text, item.Value]));


    const childs = get(this.state, "Childs", []) || [];
    const dataChilds = childs.filter(this.filterChild).map((item: any) => ([item.FullName, item.Id]));
    return (
      <div>
        <Modal
          isOpen={showModal}
          fullWidth
        >
          <div>Выбран срок заведения продукта {DepositDay} дней.
            Если вы вводили срок в месяцах, необходимо вернутся к редактированию и проверить указанный срок</div>
          <div className="col mt-4 text-right">
            <Button type="success" className="ml-3" onClick={this.submitForm} loading={loading}>
              Открыть продукт
            </Button>
            <Button type="error" className="ml-3" onClick={this.closeModal} loading={loading}>
              Вернутся к редактированию
            </Button>
          </div>
        </Modal>
        <FormControl
          onChange={this.handleChangeAndFind("IdPartner")}
          onFocus={this.onFocus("IdPartner")}
          handleChangeAutoSelect={this.handleChangeAutoSelect}
          fieldName="Number"
          value={IdPartner}
          label="Пайщик"
          error={formErrors.IdPartner}
          searchData={searchData}
          type="autocomplete"
        />
        <FormControl
          onChange={this.handleChangeAndFind("Famale")}
          onFocus={this.onFocus("Famale")}
          handleChangeAutoSelect={this.handleChangeAutoSelect}
          value={Famale}
          fieldName="Famale"
          label="Фамилия"
          error={formErrors.Famale}
          searchData={searchData}
          // type="autocomplete"
          disabled
        />
        <FormControl
          onChange={this.handleChangeAndFind("Name")}
          onFocus={this.onFocus("Name")}
          handleChangeAutoSelect={this.handleChangeAutoSelect}
          value={Name}
          fieldName="Name"
          label="Имя"
          error={formErrors.Name}
          searchData={searchData}
          // type="autocomplete"
          disabled
        />
        <FormControl
          onChange={this.handleChangeAndFind("Patronymic")}
          handleChangeAutoSelect={this.handleChangeAutoSelect}
          value={Patronymic}
          fieldName="Patronymic"
          label="Отчество"
          error={formErrors.Patronymic}
          searchData={searchData}
          // type="autocomplete"
          disabled
        />
        <FormControl
          onChange={this.handleChangeDate("OpeningDate")}
          value={OpeningDate}
          label="Дата открытия"
          type="date"
          disabled
        />
        {type === CHILD_PROGRAM && (
          <FormControl
            onChange={this.handleChange("ChildNameId", true)}
            value={ChildNameId}
            data={isArray(dataChilds) && !isEmpty(dataChilds) ? dataChilds : [["Нет", "Нет"]]}
            label="Дети"
            type="select"
          />
        )}
        {type === CHILD_PROGRAM && ChildBirthDateEditable && ChildNameId && (
          <FormControl
            onChange={this.handleChangeBirthDate("ChildBirthDate")}
            value={ChildBirthDate}
            error={formErrors.ChildBirthDate}
            label="День рождения ребенка"
            type="date"
          />
        )}
        {(type === PENSION_PROGRAM || type === CHILD_PROGRAM) && (
          <FormControl
            onChange={this.handleChangeDate("DateEndEdit")}
            value={DateEndEdit}
            error={formErrors.DateEndEdit}
            label="Дата закрытия"
            disabled
            type="date"
          />
        )}
        <FormControl
          onChange={this.handleChange("CurrencyId", true)}
          value={CurrencyId}
          data={Object.entries(currencyCodes)}
          label="Валюта"
          type="select"
          handleBlur={this.loadProducts}
        />
        <FormControl
          onChange={this.handleChange("Sum")}
          onFocus={this.onFocus("Sum")}
          value={Sum}
          label="Сумма"
          error={formErrors.Sum}
          isNumber
          handleBlur={this.loadProducts}
        />
        <FormControl
          onChange={this.handleChange("DepositDay")}
          onFocus={this.onFocus("DepositDay")}
          value={DepositDay}
          label="Срок"
          valueTerm={valueTerm}
          onChangeTerm={this.handleChange("valueTerm")}
          error={formErrors.DepositDay}
          isNumber
          handleBlur={this.loadProducts}
          disabled={type === PENSION_PROGRAM || type === CHILD_PROGRAM}
          isTerm
        />
        <FormControl
          onChange={this.handleChangeCheckbox("InformPartner")}
          value={InformPartner}
          label="Информировать клиента"
          error={formErrors.InformPartner}
          type="checkbox"
        />
        <LoadingWrapper loading={loading ? loading : undefined}>
          <FormControl
            onChange={this.handleChange("ProductId")}
            value={ProductId}
            data={isArray(products) && !isEmpty(products) ? data : [["Нет", "Нет"]]}
            label="Продукт"
            type="select"
          />
          <FormControl
            onChange={this.handleChangeCheckbox("IsMain", true)}
            value={IsMain}
            label="Только основные продукты"
            error={formErrors.IsMain}
            type="checkbox"
          />
          <FormControl
            onChange={this.handleChange("Percent")}
            value={Percent}
            label="Процентная ставка"
            error={formErrors.Percent}
            disabled
          />
          <FormControl
            onChange={this.handleChange("MemberPercent")}
            value={MemberPercent}
            label="Членский взнос"
            error={formErrors.MemberPercent}
            disabled
          />
          <FormControl
            onChange={this.handleChange("FuncPayName")}
            value={FuncPayName}
            label="Тип выплат"
            error={formErrors.FuncPayName}
            disabled
          />
          <FormControl
            onChange={this.handleChange("Fine")}
            value={Fine}
            label="Пеня"
            error={formErrors.Fine}
            disabled
          />
        </LoadingWrapper>
        {(!formValid || errorMessage) && (
          <div className="alert alert-danger mt-3 d-print-none">
            <ErrorMessage
              errorData={{ message: "Ошибка", verbose: errorMessage || "Произошла ошибка. Попробуйте снова" }}/>
          </div>
        )}
        <div className="row">
          <div className="col justify-content-end">
            <Button type="success" loading={formLoading} disabled={!IdPartner || !Famale || !Name || !DepositDay || !OpeningDate || !ProductCondId} onClick={this.print}>
              Печать договора
            </Button>
            <Button type="success" className="ml-3" loading={formLoading} disabled={!formValid} onClick={this.submit}>
              Создать СП
            </Button>
          </div>
        </div>
      </div>
    );
  };

  private loadPartner = async (calback?: () => void) => {
    const { partnerNumber } = this.props;
    if (partnerNumber) {
      const partner = await ApiClient.getPartner(partnerNumber);

      if (partner) {
        this.setState({
          Famale: get(partner, "LastName"),
          Name: get(partner, "FirstName"),
          Patronymic: get(partner, "Patronymic"),
          IdPartner: partnerNumber
        });
        this.getCurrentUserBalance();
        if (calback) {
          calback();
        }
      }
    }
  };

  private submit = () => {
    if (!this.validateForm()) {
      return;
    }

    const { valueTerm, DepositDay } = this.state;

    if (Number(valueTerm) === 0 && Number(DepositDay) <= 60) {
      this.setState({
        showModal: true,
      });

      return;
    }

    this.submitForm();
  };

  private closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  private print = async () => {
    const { IdPartner, CurrencyId, Sum, OpeningDate, ProductCondId, ChildNameId, Percent, MemberPercent, DateEndEdit } = this.state;
    const { type } = this.props;
    await this.setState({ formLoading: true });
    const period = this.getPeriodInDay();
    const partner = await ApiClient.getPartner(IdPartner);
    if (partner) {
      let billCode;
      if (type === DEPOSIT__PROGRAM) {
        const result = await ApiClient.getDepositCode({ partnerId: partner.Id, currencyId: CurrencyId });
        billCode = result.DepositCode;
        await ApiClient.downloadFile(`/Template/AppForReplenishment/${IdPartner}/${billCode}/${get(currencyTypes, `${CurrencyId}`)}?openingSum=${Sum}&openingDate=${moment(OpeningDate).format("DD.MM.YYYY")}&closingDate=${moment(OpeningDate).add(period, 'days').format("DD.MM.YYYY")}&period=${period}&prodCondition=${ProductCondId}`);
      } else if (type === PENSION_PROGRAM || type === CHILD_PROGRAM) {
        await ApiClient.downloadFile(`/Template/PensionAgreement/${IdPartner}/${get(currencyTypes, `${CurrencyId}`)}?sum=${Sum}&percent=${Percent}&memberPercent=${MemberPercent}&endDate=${DateEndEdit}&isChild=${type === CHILD_PROGRAM}&childId=${ChildNameId}`);
      }
    }
    await this.setState({ formLoading: false });

  };

  private submitForm = async () => {
    this.setState({ formLoading: true });
    const {
      IsMain,
      ProductCondId,
      Famale,
      Name,
      Patronymic,
      OpeningDate,
      CurrencyId,
      Sum,
      InformPartner,
      ProductId,
      Percent,
      MemberPercent,
      FuncPayName,
      IdPartner,
      ChildNameId,
      ChildBirthDate,
      DateEndEdit,
    } = this.state;
    const { goToList, type } = this.props;

    if (type === DEPOSIT__PROGRAM) {
      try {
        const { Id: IdAspPartner } = await ApiClient.getPartner(IdPartner);
        if (!IdAspPartner) {
          throw new Error("Пайщик не найден");
        }

        const result = await ApiClient.createDeposit({
          IdAspPartner,
          IsMain,
          ProductCondId,
          IdPartner,
          Famale,
          Name,
          Patronymic,
          OpeningDate: moment(OpeningDate).format("DD.MM.YYYY"),
          CurrencyId,
          Sum: Sum && Sum.toString().replace('.', ','),
          DepositDay: this.getPeriodInDay(),
          NdflCount: true,
          InformPartner,
          ProductId,
          Percent,
          MemberPercent,
          FuncPayName,
        });
        this.setState({ formLoading: false });
        toast(result.Result || 'Сберегательная программа успешно создана', {
          type: toast.TYPE.SUCCESS
        });
        goToList(`/Bill/Details/${result.BillCode}`,undefined);
      } catch (e) {
        const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
        this.setState({
          formLoading: false,
          errorMessage,
        });
        toast(errorMessage, {
          type: toast.TYPE.ERROR
        });
      }
    }

    if (type === PENSION_PROGRAM || type === CHILD_PROGRAM) {
      try {
        const { Id: IdAspPartner } = await ApiClient.getPartner(IdPartner);
        if (!IdAspPartner) {
          throw new Error("Пайщик не найден");
        }

        const result = await ApiClient.createPension({
          IdAspPartner,
          IsMain,
          ProductCondId,
          IdPartner,
          Famale,
          Name,
          Patronymic,
          ChildNameId: type === CHILD_PROGRAM ? ChildNameId : null,
          ChildBirthDate: type === CHILD_PROGRAM ? ChildBirthDate : null,
          OpeningDate: moment(OpeningDate).format("DD.MM.YYYY"),
          CurrencyId,
          Sum: Sum && Sum.toString().replace('.', ','),
          DateEndEdit: moment(DateEndEdit).format("DD.MM.YYYY"),
          InformPartner,
          NdflCount: true,
          ProductId,
          Percent,
          MemberPercent,
          FuncPayName,
        });
        toast(result.Result || (type === CHILD_PROGRAM ? 'Детская программа успешно создана' : 'Пенсионная программа успешно создана'), {
          type: toast.TYPE.SUCCESS
        });
        this.setState({ formLoading: false });
        goToList(`/Bill/Details/${result.BillCode}`,undefined);
      } catch (e) {
        const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
        this.setState({
          formLoading: false,
          errorMessage,
        });
        toast(errorMessage, {
          type: toast.TYPE.ERROR
        });
      }
    }

  };

  private loadPensionPartner = async () => {
    const { OpeningDate, IdPartner, Famale, Name } = this.state;
    if (IdPartner && Famale && Name) {
      const partner = await ApiClient.getPartner(IdPartner);
      const DateEndEdit = moment(partner.BirthDate).add(partner.Gender === 1 ? 65 : 60, 'years')
      const DepositDay = DateEndEdit.diff(moment(OpeningDate), "days");
      this.setState({
        DepositDay,
        DateEndEdit: DateEndEdit.toISOString(),
      });
    }

  };

  private validateForm = () => {
    const { type } = this.props;

    const requiredFields = ["IdPartner", "Famale", "Name", "Sum"];
    if (type === DEPOSIT__PROGRAM) {
      requiredFields.push('DepositDay');
    }

    const state = this.state;
    const { userBalance, Sum } = this.state;
    const formErrors = {};

    if (!this.isNumeric(userBalance) || !this.isNumeric(Sum)) {
      set(formErrors, 'Sum', "Данные не валидны или пайщик не найден");
    }

    requiredFields.map((item) => {
      if (!get(state, item, "")) {
        set(formErrors, item, "Поле обязательно для заполнения");
      }
    });

    if (Sum && userBalance && this.isNumeric(userBalance) && this.isNumeric(Sum) && Sum > userBalance) {
      set(formErrors, 'Sum', "Недостаточно средств на счете");
    }



    this.setState(prevState => ({
      ...prevState,
      formErrors: {
        ...state.formErrors,
        ...formErrors
      },
      formValid: isEmpty(formErrors),
      errorMessage: ""
    }));

    return isEmpty(formErrors);
  };

  private handleChange = (fieldName: string, watcher: boolean = false) => (e: any) => {
    const { target: { value } } = e;
    const { type } = this.props;
    this.setState({
      [fieldName]: value
    }, () => {
      if (watcher) {
        this.loadProducts();
        this.getCurrentUserBalance();
        if (type === CHILD_PROGRAM) {
          this.getUserChilds()
        }
      }
      if (fieldName === "ProductId") {
        this.loadProductConditions();
      }
      if (fieldName === "ChildNameId") {
        const { Childs, OpeningDate } = this.state;
        const child = find(Childs, (item: any) => Number(item.Id) === Number(value));

        const ChildBirthDateEditable = !get(child, 'BirthDate', false);
        const DateEndEdit = moment(child.BirthDate).add('years', 18);
        const DepositDay = moment(DateEndEdit).diff(moment(OpeningDate), "days");

        this.setState({
          ChildNameId: Number(child.Id),
          ChildBirthDate: moment(child.BirthDate).toISOString(),
          ChildBirthDateEditable,
          DateEndEdit: DateEndEdit.toISOString(),
          DepositDay,
        });

      }
    });
  };

  private onFocus = (fieldName: string) => (e: any) => {
    const { formErrors } = this.state;
    let isValid = true;
    const obj = omit(formErrors, [`${fieldName}`]);
    for (const key in obj) {
      // @ts-ignore
      if (obj[key] !== '') {
        isValid = false;
      }
    }
    this.setState(() => ({
      formErrors: {
        ...formErrors,
        [fieldName]: ""
      },
      formValid: isValid,
    }));
  };

  private handleChangeAndFind = (fieldName: string) => (value: any) => {
    const { searchPartnersRequest } = this.props;
    const { IdPartner, Famale, Name, Patronymic } = this.state;
    if (get(this.state, fieldName) !== value) {
        this.setState({
          Famale: '',
          Name: '',
          Patronymic: '',
          formValid: true,
          errorMessage: '',
        });
        if (value !== '') {
        const findField = fieldName === "IdPartner" ? "Number" : fieldName;
        searchPartnersRequest({
          Number: IdPartner,
          Famale,
          Name,
          Patronymic,
          [findField]: value
        });
      }
    }
    this.setState({
      [fieldName]: value
    });
  };

  private handleChangeCheckbox = (fieldName: string, watcher: boolean = false) => (e: any) => {
    const { target: { checked } } = e;
    this.setState({
      [fieldName]: checked
    }, () => {
      if (watcher) {
        this.loadProducts();
      }
    });
  };

  private loadProducts = async () => {
    const { IsMain, Sum, CurrencyId, DepositDay } = this.state;
    const { type } = this.props;

    if (type === DEPOSIT__PROGRAM) {
      if (Sum && DepositDay) {
        this.setState({
          loading: true
        });
        try {
          const result: LoanProducts = await ApiClient.getDepositProducts({
            sum: Sum,
            currency: CurrencyId,
            period: this.getPeriodInDay(),
            isMain: IsMain
          });
          this.setState((prevState) => ({
            ...prevState,
            ...result,
            loading: false,
            formValid: true,
            errorMessage: '',
          }));
        } catch (e) {
          this.setState({
            loading: false,
            formValid: false,
            errorMessage: e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова"
          }, this.cleanProducts);

        }
      }
    } else {
      this.cleanProducts();
    }

    if (type === PENSION_PROGRAM || type === CHILD_PROGRAM) {
      if (!Sum) {
        return this.cleanProducts();
      }
      this.setState({
        loading: true
      });
      try {
        const result: LoanProducts = await ApiClient.getPensionProducts({
          sum: Sum,
          currency: CurrencyId,
          isMain: IsMain
        });

        const product = find(result.Products, (item) => { return item.Text.toLowerCase().includes(type === PENSION_PROGRAM ? 'пенс' : 'детск'); });

        if (product) {
          this.setState((prevState) => ({
            ...prevState,
            Products: result.Products,
            loading: false,
            formValid: true,
            errorMessage: '',
          }));
          this.handleChange('ProductId')({ target: { value: product.Value } });
        } else {
          this.setState((prevState) => ({
            ...prevState,
            ...result,
            loading: false,
            formValid: true,
            errorMessage: '',
          }));
        }
      } catch (e) {
        this.setState({
          loading: false,
          formValid: false,
          errorMessage: e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова"
        }, this.cleanProducts);

      }
    }
  };

  private cleanProducts = () => {
    this.setState({
      Products: []
    });
    this.cleanProductConditions();
  };

  private cleanProductConditions = () => {
    this.setState({
      Percent: undefined,
      MemberPercent: undefined,
      FuncPayName: "",
      Fine: undefined
    })
  };

  private loadProductConditions = async () => {
    const { IsMain, Sum, CurrencyId, DepositDay, ProductId } = this.state;
    const { type } = this.props;

    if (type === DEPOSIT__PROGRAM) {
      if (Sum && DepositDay && ProductId) {
        this.setState({
          loading: true
        });
        try {
          const result: LoanProducts = await ApiClient.getDepositProductConditions({
            productId: ProductId,
            sum: Sum,
            currency: CurrencyId,
            period: this.getPeriodInDay(),
            isMain: IsMain
          });
          this.setState((prevState) => ({
            ...prevState,
            ...result,
            loading: false,
            formValid: true,
            errorMessage: '',
          }));
        } catch (e) {
          this.setState({
            loading: false,
            formValid: false,
            errorMessage: e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова"
          }, this.cleanProductConditions);
        }
      }
    }

    if (type === PENSION_PROGRAM || type === CHILD_PROGRAM) {
      if (Sum && ProductId) {
        this.setState({
          loading: true
        });
        try {
          const result: LoanProducts = await ApiClient.getPensionProductConditions({
            productId: ProductId,
            sum: Sum,
            currency: CurrencyId,
            isMain: IsMain
          });
          this.setState((prevState) => ({
            ...prevState,
            ...result,
            loading: false,
            formValid: true,
            errorMessage: '',
          }));
        } catch (e) {
          this.setState({
            loading: false,
            formValid: false,
            errorMessage: e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова"
          }, this.cleanProductConditions);
        }
      }
    }
  };

  private handleChangeAutoSelect = (partner: any) => {
    const { type } = this.props;
    this.setState({
      ...partner,
      IdPartner: partner.Number
    }, () => {
      this.getCurrentUserBalance();
      if (type === CHILD_PROGRAM) {
        this.getUserChilds();
      }
      if (type === PENSION_PROGRAM) {
        this.loadPensionPartner();
      }
    });
  };

  private handleChangeDate = (fieldName: string) => (date: Date, event: any) => {
    const { OpeningDate, formErrors } = this.state;
    if (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value)) {
      const value = date ? convertToUtc(date).toISOString() : null;
      if (value && moment(value).isSameOrAfter(moment(OpeningDate))) {
        const DepositDay = moment(value).diff(moment(OpeningDate), "days");
        this.setState({
          [fieldName]: value === NONE_VALUE || value === "" ? null : value,
          DepositDay,
          formErrors: {
            ...formErrors,
            [fieldName]: '',
          }
        });
      } else {
        this.setState({
          formErrors: {
            ...formErrors,
            [fieldName]: "Дата закрытия не может быть раньше даты открытия",
          }
        })
      }
    }
  };

  private handleChangeBirthDate = (fieldName: string) => (date: Date, event: any) => {
    const { OpeningDate, formErrors } = this.state;
    if (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value)) {
      const value = date ? convertToUtc(date).toISOString() : null;
      if (value && moment(value).isBefore(moment(OpeningDate))) {
        const DateEndEdit = moment(value).add('years', 18);
        const DepositDay = moment(DateEndEdit).diff(moment(OpeningDate), "days");
        this.setState({
          [fieldName]: value === NONE_VALUE || value === "" ? null : value,
          DepositDay,
          DateEndEdit: DateEndEdit.toISOString(),
          formErrors: {
            ...formErrors,
            [fieldName]: '',
          }
        });
      } else {
        this.setState({
          DateEndEdit: convertToUtc((new Date())).toISOString(),
          DepositDay: 0,
          formErrors: {
            ...formErrors,
            [fieldName]: "Дата рождения не может быть позже даты открытия",
          }
        })
      }
    }
  };

  private isNumeric = (val: any) => {
    return !isNaN(toNumber(val));
  };

  private getPeriodInDay = () => {
    const { DepositDay, valueTerm } = this.state;

    if (Number(valueTerm) === 0) {

      return DepositDay;
    }

    return Math.floor(Number(DepositDay) / 12) * 365 + (Number(DepositDay) % 12 * 30);
  }
}
