import * as React from 'react';

import styled from '../../../styled/styled-components';
import { UserComment } from '../types';

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.errorRed};
  position: fixed;
  right: 0;
  top: 60%;
  height: 210px;
  border-radius: 10px 0 0 10px;
  padding: 5px;
  display: flex;
  flex-direction: row;
`;

const Header = styled.div`
  color: ${({ theme }) => theme.colors.white};
  writing-mode: vertical-lr;
  text-orientation: upright;
  width: 25px;
  cursor: pointer;
`;

interface ContentProps {
  isOpen: boolean;
}

const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: ${(props: ContentProps) => props.isOpen ? 'block' : 'none'};
  color: ${({ theme }) => theme.colors.white};
  width: 250px;
  height: 100%;
  padding: 3px;
  color: ${({ theme }) => theme.colors.defaultFont};
`;

interface CommentPopupProps {
  comment?: UserComment;
}

interface CommentPopupState {
  isOpen: boolean;
}

class CommentPopup extends React.Component<CommentPopupProps, CommentPopupState> {
  public state: CommentPopupState = {
    isOpen: false
  };

  public constructor(props: CommentPopupProps) {
    super(props);

    this.state.isOpen = !!props.comment;
  }

  public render() {
    const { isOpen } = this.state;
    return (
      <Container>
        <Header onClick={this.toggle}>Комментарий</Header>
        <Content isOpen={isOpen}>{this.props.comment}</Content>
      </Container>
    );
  }

  private toggle = () => {
    this.setState((state) => ({ isOpen: !state.isOpen }));
  };
}

export default CommentPopup;
