import { createAction } from "typesafe-actions";

import { CalculatorFormData, CalculatingData, CalculatorPensionFormData } from './types'

export const ActionTypes = {
  FETCH_CALCULATOR_DATA_REQUESTS: "@@CALCULATOR/FETCH_CALCULATOR_DATA_REQUESTS",
  FETCH_CALCULATOR_DATA_SUCCESS: "@@CALCULATOR/FETCH_CALCULATOR_DATA_SUCCESS",
  FETCH_CALCULATOR_DATA_ERROR: "@@CALCULATOR/FETCH_CALCULATOR_DATA_ERROR",

  FETCH_CALCULATOR_LOAN_DATA_REQUESTS: "@@CALCULATOR/FETCH_CALCULATOR_LOAN_DATA_REQUESTS",
  FETCH_CALCULATOR_LOAN_DATA_SUCCESS: "@@CALCULATOR/FETCH_CALCULATOR_LOAN_DATA_SUCCESS",
  FETCH_CALCULATOR_LOAN_DATA_ERROR: "@@CALCULATOR/FETCH_CALCULATOR_LOAN_DATA_ERROR",

  FETCH_CALCULATOR_RAISING_DATA_REQUESTS: "@@CALCULATOR/FETCH_CALCULATOR_RAISING_DATA_REQUESTS",
  FETCH_CALCULATOR_RAISING_DATA_SUCCESS: "@@CALCULATOR/FETCH_CALCULATOR_RAISING_DATA_SUCCESS",
  FETCH_CALCULATOR_RAISING_DATA_ERROR: "@@CALCULATOR/FETCH_CALCULATOR_RAISING_DATA_ERROR",

  FETCH_CALCULATOR_PENSION_DATA_REQUESTS: "@@CALCULATOR/FETCH_CALCULATOR_PENSION_DATA_REQUESTS",
  FETCH_CALCULATOR_PENSION_DATA_SUCCESS: "@@CALCULATOR/FETCH_CALCULATOR_PENSION_DATA_SUCCESS",
  FETCH_CALCULATOR_PENSION_DATA_ERROR: "@@CALCULATOR/FETCH_CALCULATOR_PENSION_DATA_ERROR",

  FETCH_CALCULATOR_DATA_CLEAR: "@@CALCULATOR/FETCH_CALCULATOR_DATA_CLEAR",
};

export const fetchCalculatorDataRequest = createAction(
  ActionTypes.FETCH_CALCULATOR_DATA_REQUESTS,
  resolve => (data: CalculatorFormData) => resolve(data)
);

export const fetchCalculatorDataSuccess = createAction(
  ActionTypes.FETCH_CALCULATOR_DATA_SUCCESS,
  resolve => (data: CalculatingData) =>
    resolve(data)
);

export const fetchCalculatorDataError = createAction(
  ActionTypes.FETCH_CALCULATOR_DATA_ERROR,
  resolve => (error: any) =>
    resolve(error)
);

export const fetchCalculatorLoanDataRequest = createAction(
  ActionTypes.FETCH_CALCULATOR_LOAN_DATA_REQUESTS,
  resolve => (data: CalculatorFormData) => resolve(data)
);

export const fetchCalculatorLoanDataSuccess = createAction(
  ActionTypes.FETCH_CALCULATOR_LOAN_DATA_SUCCESS,
  resolve => (data: CalculatingData) =>
    resolve(data)
);

export const fetchCalculatorLoanDataError = createAction(
  ActionTypes.FETCH_CALCULATOR_LOAN_DATA_ERROR,
  resolve => (error: any) =>
    resolve(error)
);

export const fetchCalculatorRaisingDataRequest = createAction(
  ActionTypes.FETCH_CALCULATOR_RAISING_DATA_REQUESTS,
  resolve => (data: CalculatorFormData) => resolve(data)
);

export const fetchCalculatorRaisingDataSuccess = createAction(
  ActionTypes.FETCH_CALCULATOR_RAISING_DATA_SUCCESS,
  resolve => (data: CalculatingData) =>
    resolve(data)
);

export const fetchCalculatorRaisingDataError = createAction(
  ActionTypes.FETCH_CALCULATOR_RAISING_DATA_ERROR,
  resolve => (error: any) =>
    resolve(error)
);

export const fetchCalculatorPensionDataRequest = createAction(
  ActionTypes.FETCH_CALCULATOR_PENSION_DATA_REQUESTS,
  resolve => (data: CalculatorPensionFormData) => resolve(data)
);

export const fetchCalculatorPensionDataSuccess = createAction(
  ActionTypes.FETCH_CALCULATOR_PENSION_DATA_SUCCESS,
  resolve => (data: CalculatingData) =>
    resolve(data)
);

export const fetchCalculatorPensionDataError = createAction(
  ActionTypes.FETCH_CALCULATOR_PENSION_DATA_ERROR,
  resolve => (error: any) =>
    resolve(error)
);

export const fetchCalculatorDataClear = createAction(
  ActionTypes.FETCH_CALCULATOR_DATA_CLEAR,
);
