import React from 'react';
import moment from 'moment';

import Panel from '../../../../../components/Panel';
import { ButtonAdd } from './styled';
import CardsTable, { CardsTableProps } from './CardsTable';
import { UserData } from '../../../types';
import { PartnerChangeHandlerArgs } from './types';

const CardsTableMemoized: React.FunctionComponent<CardsTableProps> = React.memo(CardsTable);

const getInitCard = (PartnerId: string) => ({
  PartnerId,
  FirstDigits: '',
  LastDigits: '',
  ExpirationDate: moment().format('YYYY-MM'),
  FullNumber: '',
  Comment: '',
  Id: null
});

interface CardsBlockProps {
  editing: boolean;
  userData: UserData;
  handleChange: PartnerChangeHandlerArgs,
}

export default function CardsBlock(props: CardsBlockProps) {
  const { editing, userData, handleChange } = props;
  return (
    <Panel type="primary" heading="Дебетовые и кредитные карты">
      {editing && (
        <ButtonAdd
          type="success"
          onClick={handleChange.bind(
            null,
            null,
            'PartnerCards',
            [...userData.PartnerCards, getInitCard(userData.PartnerId)]
          )}
        >
          Добавить
        </ButtonAdd>
      )}
      <CardsTableMemoized
        data={userData.PartnerCards}
        editing={editing}
        onChange={handleChange.bind(null, null, 'PartnerCards')}
      />
    </Panel>
  );
}
