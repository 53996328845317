import * as React from "react";
import Button from "../../../../components/Button/Button";
import ApiClient from "../../../../services/api";
import Spinner from "../../../../components/Spinner";
import styled from "../../../../styled/styled-components";
import { showErrorToast } from "../../../../components/ErrorToast";
import ConfirmModal from "../../../../components/PartnersAndClients/ConfirmModal";
import { toast } from "react-toastify";
import Dropdown from "../../../../components/Dropdown";

interface ActionsPanelProps {
  partnerNumber?: string;
  partnerId?: number;
  disabled?: boolean;
}

interface ActionsPanelState {
  loadingDocuments: DocumentType[];
  activationLoading: boolean;
}

const Container = styled.div`
  display: flex;
  margin: 16px 0;
`;

const PrintButton = styled(Button)`
  width: 100%;
  border-radius: 0;
`;

const Right = styled.div`
  margin: 0 0 0 auto;
`;

type DocumentType =
  | "TitlePage"
  | "ConsentPersonalDataProcessing"
  | "ConsentBureau"
  | "PartnerEntryApplication"
  | "DecisionKPK"
  | "IndividualQuestionnaire"
  | "DecOfAccession"
  | "AccessionAgr"
  | "RequisitesAlfaBankUSD"
  | "RequisitesAlfaBankEUR"
  | "RequisitesAlfaBankRUB"
  | "ExcludingDecisionKPK"
  | "StatementExclusionFromShareholders";

const printButtons = new Map<DocumentType, string>([
  ["TitlePage", "Титульный лист"],
  ["ConsentPersonalDataProcessing", "СОПД"],
  ["ConsentBureau", "Согласие БКИ"],
  ["PartnerEntryApplication", "Заявление на вступление в пайщики"],
  ["DecisionKPK", "Печать решение прием КПК"],
  ["IndividualQuestionnaire", "Анкета клиента ФЛ"],
  ["DecOfAccession", "Заявление на присоединение к з/п проекту"],
  ["AccessionAgr", "Договор на присоединение к з/п-проекту"],
  ["RequisitesAlfaBankUSD", "Реквизиты Альфа-Банка (USD)"],
  ["RequisitesAlfaBankEUR", "Реквизиты Альфа-Банка (EUR)"],
  ["RequisitesAlfaBankRUB", "Реквизиты Альфа-Банка (RUB)"],
  ["StatementExclusionFromShareholders", "Заявление на исключение из КПК"],
  ["ExcludingDecisionKPK", "Решение на исключение из КПК"]
]);

export default class ActionsPanel extends React.Component<
  ActionsPanelProps,
  ActionsPanelState
> {
  public state: ActionsPanelState = {
    loadingDocuments: [],
    activationLoading: false
  };

  private confirmModal = React.createRef<ConfirmModal>();

  public render() {
    return (
      <Container>

        <Dropdown caption={"Распечатать документ ⬇︎"}>
          {[...printButtons.entries()].map(([documentType, label], index) => {
            const isLoading = this.isLoading(documentType);
            const clickHandler = () =>
              !isLoading && this.printDocument(documentType);

            return (
                <div key={index}>
                  <PrintButton
                    onClick={clickHandler}
                    type="success"
                    disabled={isLoading || this.props.disabled}
                  >
                    {label} <Spinner active={isLoading} />
                  </PrintButton>
                </div>
            );
          })}
        </Dropdown>
        <Right>
          <Button
            onClick={this.openConfirmModal}
            type="success"
            disabled={
              this.props.disabled || typeof this.props.partnerId !== "number"
            }
          >
            Активация <Spinner active={this.state.activationLoading} />
          </Button>
        </Right>
        <ConfirmModal
          message="Вы действительно хотите активировать пользователя?"
          successLabel="Да"
          cancelLabel="Нет"
          onConfirm={this.activatePartner}
          ref={this.confirmModal}
        />
      </Container>
    );
  }

  private isLoading = (documentType: DocumentType) =>
    this.state.loadingDocuments.indexOf(documentType) > -1;

  private printDocument = async (documentType: DocumentType) => {
    const { partnerNumber, disabled } = this.props;
    const apiEndPoint = this.getApiEndpoint(documentType);

    if (disabled || !partnerNumber || !apiEndPoint) {
      return;
    }

    try {
      this.setState(state => ({
        loadingDocuments: [...state.loadingDocuments, documentType]
      }));

      const result = await apiEndPoint(partnerNumber);

      const blob = new Blob([result], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      window.open(url);
    } catch (error) {
      showErrorToast({ Message: error });
    } finally {
      this.setState(state => ({
        loadingDocuments: state.loadingDocuments.filter(
          item => item !== documentType
        )
      }));
    }
  };

  private getApiEndpoint = (documentType: DocumentType) => {
    const {partnerId} = this.props;
    switch (documentType) {
      case "TitlePage":
        return ApiClient.printClientTitlePage;
      case "ConsentPersonalDataProcessing":
        return ApiClient.printConsentPersonalDataProcessing;
      case "ConsentBureau":
        return () => ApiClient.printConsentBureau(partnerId);
      case "PartnerEntryApplication":
        return () => ApiClient.printPartnerEntryApplication(partnerId);
      case "DecisionKPK":
        return ApiClient.printDecisionKPK;
      case "IndividualQuestionnaire":
        return () => ApiClient.printIndividualQuestionnaire(partnerId);
      case "DecOfAccession":
        return ApiClient.printDecOfAccession;
      case "AccessionAgr":
        return () => ApiClient.printAccessionAgr();
      case "RequisitesAlfaBankUSD":
        return () => ApiClient.printRequisitesAlfaBank(partnerId, 'USD');
      case "RequisitesAlfaBankEUR":
        return () => ApiClient.printRequisitesAlfaBank(partnerId, 'EUR');
      case "RequisitesAlfaBankRUB":
        return () => ApiClient.printRequisitesAlfaBank(partnerId, 'RUB');
      case "ExcludingDecisionKPK":
        return ApiClient.printExcludingDecisionKPK;
      case "StatementExclusionFromShareholders":
        return ApiClient.printStatementExclusionFromShareholders;

      default:
        return null;
    }
  };

  private openConfirmModal = () => {
    if (this.confirmModal.current) {
      this.confirmModal.current.open();
    }
  };

  private activatePartner = async () => {
    const { partnerId } = this.props;

    if (typeof partnerId !== "number") {
      return;
    }

    this.setState({
      activationLoading: true
    });
    try {
      await ApiClient.activatePartner(this.props.partnerId);
      toast("Пайщик успешно активирован", {
        type: toast.TYPE.SUCCESS
      });
    } catch (error) {
      if (error.ExceptionMessage) {
        showErrorToast({ Message: error.ExceptionMessage });
      } else {
        showErrorToast(error);
      }
    } finally {
      this.setState({ activationLoading: false });
    }
  };
}
