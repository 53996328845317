import React, { Component } from 'react';

import './CheckResultsRow.scss';

import getRandomKey from '../../../helpers/getRandomKey';

export default class CheckResultsRow extends Component {
  render() {
    const { date, results = [] } = this.props;
    let resultsHeader = null;
    let resultsTable = null;

    if (results.length === 0) {
      resultsHeader = <span className="check-results-row__status_success">Совпадений не найдено</span>;
    } else {
      resultsHeader = <span className="check-results-row__status_error">Выявлены совпадения:</span>;
      const rows = results.map(row => (
        <tr key={getRandomKey('check', 'results', 'info')}>
          <td>
            <div className="row">{row.Local.FullName}</div>
            <div className="row">{row.Local.BirthDate}</div>
            <div className="row">{row.Local.Address}</div>
            <div className="row">{row.Local.Passport}</div>
          </td>
          <td>
            <div className="row">{row.List.FullName}</div>
            <div className="row">{row.List.BirthDate}</div>
          </td>
        </tr>
      ));

      resultsTable = (
        <table className="table">
          <thead>
          <tr>
            <th>Пайщик</th>
            <th>ФинМониторинг</th>
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </table>
      );
    }

    return (
      <tr className="row">
        <td className="col-2">{date}</td>
        <td className="col-2">Проверены все пайщики</td>
        <td className="col-7">
          <div className="row">{resultsHeader}</div>
          <div className="row">{resultsTable}</div>
        </td>
      </tr>
    );
  }
}
