import * as React from "react";
import {Helmet} from "react-helmet";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import ListHeader from "../components/ListHeader";
import FilterConfigurator from "../../../components/PartnersAndClients/FilterConfigurator";
import ApiClient from "../../../services/api";
import {
  DEFAULT_FILTER_FIELDS,
  DEFAULT_SORT_FIELD_NAME,
  DEFAULT_SORT_DIRECTION,
  PRODUCT_FIELD_LABELS
} from "../constants";
import { ProductListItem, FilterRequest, productData } from "../types";
import {
  FilterFieldName as GenericFilterFieldName,
  FilterItemData as GenericFilterItemData
} from "../../../types/PartnersAndClients";
import { showErrorToast } from "../../../components/ErrorToast";
import Link from "../../../components/Link";
import Pagination from "../../../components/Pagination/Pagination";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { RouteComponentProps } from "react-router";
import { requestDataToQuery } from "../../../utils";
import { OperatorType } from "../../../types";
import styled from "../../../styled/styled-components";
import TableList from "../../../components/BillTables/TableList";
import { fetchLabelsRequest } from "../../../store/labels/actions";
import { toast } from "react-toastify";

type FilterFieldName = GenericFilterFieldName<ProductListItem>;
type FilterItemData = GenericFilterItemData<ProductListItem>;

export interface PartnersProps extends RouteComponentProps {
  fetchLabelsRequest: (section: string, billType?: string) => void;
}

export interface PartnersState {
  partners: ProductListItem[];
  filters: FilterRequest;
  cachedFilters: FilterRequest;
  visibleFields: Map<FilterFieldName, boolean>;
  filtersShown: boolean;
  fieldFilters: Map<FilterFieldName, FilterItemData>;
  autocompleteItems: { [fieldName in FilterFieldName]?: string[] };
  loading: boolean;
}

const Footer = styled.div`
  position: relative;
`;

const DEFAULT_FILTERS = {
  CurrentPage: 1,
  FieldInfos: [
    {
      FieldName: "Name" as FilterFieldName,
      Method: 1,
      Value: null,
      ValueType: 1
    },
    {
      FieldName: "BillTypeId" as FilterFieldName,
      Method: 0,
      Value: null,
      ValueType: 5
    },
    {
      FieldName: "Currencies" as FilterFieldName,
      Method: 0,
      Value: null,
      ValueType: 5
    },
    {
      FieldName: "FuncPayId" as FilterFieldName,
      Method: 0,
      Value: null,
      ValueType: 5
    },
    {
      FieldName: "IsActive" as FilterFieldName,
      Method: 0,
      Value: null,
      ValueType: 5
    },
  ],
  IsPaging: true,
  Operator: 1 as OperatorType,
  PageSize: 25,
  SortDirection: DEFAULT_SORT_DIRECTION,
  SortFieldName: DEFAULT_SORT_FIELD_NAME,
  TotalPages: 1
};

const getDefaultVisibleFields = (map: Map<FilterFieldName, FilterItemData>) => {
  const result = new Map<FilterFieldName, boolean>();
  map.forEach(item => {
    result.set(item.FieldName, !item.NotDefaultVisible);
  });

  return result;
};

class ProductList extends React.Component<
  PartnersProps,
  PartnersState
> {
  public state: PartnersState = {
    partners: [],
    visibleFields: getDefaultVisibleFields(DEFAULT_FILTER_FIELDS),
    filtersShown: false,
    filters: DEFAULT_FILTERS,
    cachedFilters: DEFAULT_FILTERS,
    fieldFilters: DEFAULT_FILTER_FIELDS,
    autocompleteItems: {},
    loading: false
  };

  private listFieldLabels = PRODUCT_FIELD_LABELS;
  // @ts-ignore
  private partnersTableElement = React.createRef<TableList<ProductListItem>>();

  private dataApiEndpoint = ApiClient.getProducts;

  public componentDidMount() {
    this.getData();
    const { fetchLabelsRequest } = this.props;
    fetchLabelsRequest('FUNC_PAY');
  }

  public render() {
    return (
      <LoadingOverlay loading={this.state.loading}>
        <Helmet defer={false}>
          <title>Продукты</title>
        </Helmet>
        <ListHeader removeProduct={this.removeProduct} />
        <FilterConfigurator
          labels={this.listFieldLabels}
          fields={this.state.visibleFields}
          handleChangeColumns={this.handleChangeColumns}
          filtersShown={this.state.filtersShown}
          toggleFiltersVisible={this.toggleFiltersVisible}
          handleChangeOperator={this.handleChangeOperator}
          operatorId={this.state.filters.Operator}
          resetAllFilters={this.resetAllFilters}
          acceptFilter={this.acceptFilter}
        />
        <TableList
          data={this.state.partners}
          visibleFields={this.state.visibleFields}
          renderActions={this.renderActions}
          sortProps={{
            sortFieldName: this.state.filters.SortFieldName,
            sortDirection: this.state.filters.SortDirection,
            onChangeSort: this.handleChangeSort
          }}
          filtersProps={{
            fields: [...this.state.fieldFilters.values()],
            onChangeFilter: this.handleChangeFilter,
            acceptFilter: this.acceptFilter,
            resetFilter: this.resetFilter,
            filtersShown: this.state.filtersShown,
            autocompleteItems: this.state.autocompleteItems
          }}
          ref={this.partnersTableElement}
          labelsSection={'PRODUCT_FIELD_LABELS'}
          getRowStyles={this.getTableRowStyles}
        />
        {this.state.filters.TotalPages > 1 && (
          <Footer>
            <Pagination
              size={this.state.filters.PageSize}
              count={this.state.filters.TotalPages * this.state.filters.PageSize}
              page={this.state.filters.CurrentPage}
              callback={this.handleChangePage}
            />
          </Footer>
        )}

      </LoadingOverlay>
    );
  }

  private removeProduct = async (Id?: number) => {
    if (!Id) {
      toast('Выберите продукт', {
        type: toast.TYPE.ERROR,
      });

      return;
    }

    try {
      this.setState({ loading: true });
      await ApiClient.deleteProduct(Id);
      toast( 'Продукт успешно удален', {
        type: toast.TYPE.ERROR
      });
      this.getData();
    } catch (e) {
      const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
      toast(errorMessage, {
        type: toast.TYPE.ERROR
      });
      this.setState({ loading: false });
    }
  };

  private getTableRowStyles = ({ IsMain, IsActive }: productData) => {
    if (IsMain && IsActive) {
      return {
        background: '#c8f7bf'
      };
    }

    if (IsMain && !IsActive) {
      return {
        background: '#f8d7da'
      };
    }
  };

  private getData = async (
    filter: Partial<FilterRequest> = {},
    forceGoToDetails?: boolean
  ) => {
    const queryString = requestDataToQuery({
      SortFieldName: this.state.filters.SortFieldName,
      SortDirection: this.state.filters.SortDirection,
      CurrentPage: this.state.filters.CurrentPage,
      PageSize: this.state.filters.PageSize,
      IsPaging: this.state.filters.IsPaging,
      ...filter
    });

    this.setState({ loading: true });
    try {
      const result = await this.dataApiEndpoint(queryString);
      if (forceGoToDetails && result.DataSource.length === 1) {
        this.setState({ loading: false });
      }

      this.setState({
        partners: result.DataSource,
        filters: result.FilterRequest,
        cachedFilters: result.FilterRequest,
        fieldFilters: this.extractFieldFilters(result.FilterRequest)
      });
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private handleChangeColumns = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: columnName, checked } = event.target;

    this.setState(state => {
      const filterFieldName = columnName as FilterFieldName;
      const newVisibleFields = new Map(state.visibleFields);

      newVisibleFields.set(filterFieldName, checked);

      return {
        visibleFields: newVisibleFields
      };
    });
  };

  private toggleFiltersVisible = () => {
    this.setState(state => ({ filtersShown: !state.filtersShown }));
  };

  private handleChangeOperator = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(event.target.value, 10);

    this.setState(state => ({
      filters: {
        ...state.filters,
        Operator: value as OperatorType
      }
    }));
  };

  private handleChangeFilter = (
    filterItemData: FilterItemData,
    forceFiltering?: boolean
  ) => {
    forceFiltering = forceFiltering || filterItemData.ValueType === 4 || filterItemData.ValueType === 5
    const forceGoToDetails = false;


    this.setState(
      state => {
        const newFilters = new Map(state.fieldFilters);
        const currentFilterItem = newFilters.get(filterItemData.FieldName);

        if (currentFilterItem) {
          newFilters.set(filterItemData.FieldName, filterItemData);
        }

        return {
          fieldFilters: newFilters,
          filters: {
            ...state.filters,
            FieldInfos: this.getFieldInfos(newFilters)
          }
        };
      },
      () => {
        if (forceFiltering) {
          this.acceptFilter(forceGoToDetails);
        }
      }
    );
  };

  private acceptFilter = (forceGoToDetails?: boolean) => {
    const { FieldInfos, Operator } = this.state.filters;

    this.getData(
      {
        Operator,
        FieldInfos: FieldInfos.filter(item => item && item.Value !== null),
        CurrentPage: 1
      },
      forceGoToDetails
    );
  };

  private resetAllFilters = () => {
    this.getData();
  };

  private extractFieldFilters = (filterRequest: FilterRequest) => {
    const fieldsData = new Map(DEFAULT_FILTER_FIELDS);

    filterRequest.FieldInfos.forEach(item => {
      if (item && fieldsData.get(item.FieldName)) {
        fieldsData.set(item.FieldName, item);
      }
    });

    return fieldsData;
  };

  private getFieldInfos = (
    fieldFilters: Map<FilterFieldName, FilterItemData>
  ) => {
    return [...fieldFilters.values()];
  };

  private handleChangeSort = (fieldNamme: FilterFieldName) => {
    this.setState(
      state => {
        const currentSortFieldName = state.filters.SortFieldName;
        const curretSortDirection = state.filters.SortDirection;

        const newSortDirection =
          currentSortFieldName !== fieldNamme
            ? 1
            : curretSortDirection === 1
            ? 2
            : 1;

        return {
          filters: {
            ...state.filters,
            SortFieldName: fieldNamme,
            SortDirection: newSortDirection
          }
        };
      },
      () => {
        this.getData({
          Operator: this.state.filters.Operator,
          FieldInfos: this.state.filters.FieldInfos.filter(
            item => item && item.Value !== null
          )
        });
      }
    );
  };

  private renderActions = (item: ProductListItem) => (
    <React.Fragment>
      <Link routerLink href={this.getLinkToEdit(item)}>
        Изменить
      </Link>
      <br />
      <Link routerLink href={this.getLinkToCopy(item)}>
        Копировать
      </Link>
    </React.Fragment>
  );

  private resetFilter = (fieldName: keyof ProductListItem) => {
    const filterItemData = DEFAULT_FILTERS.FieldInfos.find(
      ({ FieldName }) => FieldName === fieldName
    );

    if (filterItemData) {
      this.handleChangeFilter(filterItemData, true);
    }
  };

  private handleChangePage = (page: number) => {
    this.getData({
      Operator: this.state.filters.Operator,
      FieldInfos: this.state.filters.FieldInfos.filter(
        item => item && item && item.Value !== null
      ),
      CurrentPage: page
    });
  };
  private getLinkToEdit = (item: ProductListItem) => {
    return `/Product/edit/${item.Id}`;
  };

  private getLinkToCopy = (item: ProductListItem) => {
    return `/Product/copy/${item.Id}`;
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchLabelsRequest: (section: string, billType?: string) => dispatch(fetchLabelsRequest(section, billType)),
});

export default connect(undefined, mapDispatchToProps)(ProductList)
