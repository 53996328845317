import * as React from "react";
import { HeaderButtonsWrapper, HeaderButtonsItem } from "./Views";
import Button from "../../../components/Button/Button";

interface PartnerHeaderButtonsProps {
  isEditing: boolean;
  isCreating: boolean;
  isDisabledSave: boolean;
  handleClickSave: () => void;
  handleClickCancel: () => void;
  productId?: string;
  returnUrl?: string;
  hash?: string;
}

const PartnerHeaderButtons: React.SFC<PartnerHeaderButtonsProps> = React.memo(
  ({
    isEditing,
    isCreating,
    isDisabledSave,
    productId,
    returnUrl,
    handleClickSave,
    handleClickCancel,
    hash
  }) => (
    <HeaderButtonsWrapper>
      {isEditing || isCreating ? (
        <React.Fragment>
          <HeaderButtonsItem>
            <Button
              onClick={handleClickSave}
              disabled={isDisabledSave}
              type="primary"
            >
              Сохранить
            </Button>
          </HeaderButtonsItem>
          <HeaderButtonsItem>
            <Button onClick={handleClickCancel} type="error">
              Оменить
            </Button>
          </HeaderButtonsItem>
        </React.Fragment>
      ) : (
        <HeaderButtonsItem>
          <Button
            routerLink
            href={`/Product/edit/${productId}${hash}`}
            type="primary"
          >
            Редактировать
          </Button>
        </HeaderButtonsItem>
      )}
    </HeaderButtonsWrapper>
  )
);

export default PartnerHeaderButtons;
