import { setup } from "axios-cache-adapter";
import { TokenStorage } from "./TokenStorage";
import store from '../configureStore';
import { accessDenied } from "../store/auth";

const { dispatch } = store;

const axios = setup({
  cache: {
    maxAge: 15 * 60 * 1000
  }
});

let isRefreshing: boolean = false;
let failedQueue: any = [];

const processQueue = (error: any, token?: string) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const responseConfig = (config: any) => new Promise((resolve, reject) => {
  axios.request(config).then((response: any) => {
    resolve(response);
    // tslint:disable-next-line:no-shadowed-variable
  }).catch((error: any) => {
    reject(error);
  });
});

axios.interceptors.request.use((config: any) => {
  config.headers.Authorization = TokenStorage.getBarear();
  return config;
});

axios.interceptors.response.use(
  (response: any) => response,
  (error: any) => {

    if (error.response.status === 403) {
      dispatch(accessDenied());
    }

    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401 || error.config._retry) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // Logout user if token refresh didn't work or user is disabled
    if (error.config.url.includes("api/auth/token?refreshToken=")) {
      TokenStorage.clear();

      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
    
    if (isRefreshing) {
      // If I'm refreshing the token I send request to a queue
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then(() => {
          error.config.headers.Authorization = TokenStorage.getBarear();
          return responseConfig(error.config);
        })
        .catch(err => err);
    }
    // If header of the request has changed, it means I've refreshed the token
    if (error.config.headers.Authorization !== TokenStorage.getBarear()) {
      error.config.headers.Authorization = TokenStorage.getBarear();
      return responseConfig(error.config);
    }

    error.config._retry = true; // mark request a retry
    isRefreshing = true; // set the refreshing var to true

    // Try request again with new token
    return TokenStorage.getNewToken()
      .then((token) => {

        // New request with new token
        const config = error.config;
        processQueue(null, token); // Resolve queued
        /* tslint:disable:no-string-literal */
        config.headers["Authorization"] = `Bearer ${token}`;

        return responseConfig(config);

      })
      .catch((e) => {
        processQueue(e); // Resolve queued
        TokenStorage.clear();
        const currentPath = `${window.location.pathname}${window.location.search}`;
        window.location.replace(`${currentPath}`);
      })
      .finally(() => {
        isRefreshing = false;
      });
  }
);

export default axios;
