import { all, takeEvery, call, put } from "redux-saga/effects";
import {
  ActionTypes,
  fetchCalculatorDataSuccess, fetchCalculatorDataError, fetchCalculatorLoanDataSuccess,
  fetchCalculatorLoanDataError, fetchCalculatorRaisingDataSuccess, fetchCalculatorRaisingDataError,
  fetchCalculatorPensionDataSuccess, fetchCalculatorPensionDataError
} from "./actions";
import { AnyAction } from "redux";
import ApiClient from "../../services/api";
import { CalculatingData } from "./types";

function* fetchCalculatorData(action: AnyAction) {
  try {
    const result: CalculatingData = yield call(ApiClient.getDepositCalculator, action.payload);

    yield put(fetchCalculatorDataSuccess(result));
  } catch (error) {
    yield put(fetchCalculatorDataError(error));
  }
}

function* fetchCalculatorLoanData(action: AnyAction) {
  try {
    const result: CalculatingData = yield call(ApiClient.getLoanCalculator, action.payload);

    yield put(fetchCalculatorLoanDataSuccess(result));
  } catch (error) {
    yield put(fetchCalculatorLoanDataError(error));
  }
}

function* fetchCalculatorRaisingData(action: AnyAction) {
  try {
    const result: CalculatingData = yield call(ApiClient.getRaisingCalculator, action.payload);

    yield put(fetchCalculatorRaisingDataSuccess(result));
  } catch (error) {
    yield put(fetchCalculatorRaisingDataError(error));
  }
}

function* fetchCalculatorPensionData(action: AnyAction) {
  try {
    const result: CalculatingData = yield call(ApiClient.getPensionCalculator, action.payload);

    yield put(fetchCalculatorPensionDataSuccess(result));
  } catch (error) {
    yield put(fetchCalculatorPensionDataError(error));
  }
}

function* labelsSaga() {
  yield all([
    yield takeEvery(ActionTypes.FETCH_CALCULATOR_DATA_REQUESTS, fetchCalculatorData),
    yield takeEvery(ActionTypes.FETCH_CALCULATOR_LOAN_DATA_REQUESTS, fetchCalculatorLoanData),
    yield takeEvery(ActionTypes.FETCH_CALCULATOR_RAISING_DATA_REQUESTS, fetchCalculatorRaisingData),
    yield takeEvery(ActionTypes.FETCH_CALCULATOR_PENSION_DATA_REQUESTS, fetchCalculatorPensionData),
  ]);
}

export default labelsSaga;
