import * as React from "react";
import { get } from 'lodash';
import { PartnerContext } from "../../pages/Partner";
import { QUESTINARY_FORM_FIELDS, REQUIRED_FIELDS } from "../../constants";
import { FormColumn, FormWrapper } from "../Views";
import Form from "../../../../components/PartnersAndClients/Form";

export default class QuestionaryTab extends React.PureComponent {
  public render() {
    return (
      <PartnerContext.Consumer>
        {({
          editing,
          userData,
          handleChange = () => void 0,
          errors,
          valueLabels
        }) => (
          <FormWrapper>
            {userData &&
            QUESTINARY_FORM_FIELDS.map((fieldSet, index) => (
                <FormColumn key={index}>
                  <Form
                    data={get(userData, 'PartnerQuestionnaire', {})}
                    fields={fieldSet}
                    disabled={!editing}
                    onChange={handleChange.bind(null, 'PartnerQuestionnaire')}
                    requiredFields={REQUIRED_FIELDS.get("UserData")}
                    requiredFieldsError={errors.get("UserData")}
                    valueLabels={valueLabels}
                  />
                </FormColumn>
              ))}
          </FormWrapper>
        )}
      </PartnerContext.Consumer>
    );
  }
}
