import { UserData } from "../../types";
import AgentBaseTab from "./AgentBaseTab";

export default class AgentFirstTab extends AgentBaseTab {
  public printLabel = "Печать доверенности 1";
  public agentNumber = 1;
  public getData = (userData: UserData) => userData.AgentFirst;
  public getAgentId = (userData: UserData) => userData.AgentFirst.Id;

  public getSectionName: () => keyof UserData = () =>
    "AgentFirst" as keyof UserData;
}
