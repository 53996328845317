import React, { PureComponent } from 'react';
import Helmet from "react-helmet";
import ProductForm from "../components/ProductsForm";
import { Heading } from "../../../components/Typography";
import { ProductFormState } from "../types";
import ApiClient from "../../../services/api";
import BackLink from "../../../components/BackLink";

const PAGE_TITLE = 'Калькуляторы на сайте';

export default class SiteCalendars extends PureComponent<{}, ProductFormState> {
  public state: ProductFormState = {
    loading: false,
    data: [],
    error: null,
  };

  public componentDidMount(): void {
    this.loadFreeProducts();
  }

  public render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    const { data } = this.state;
    return (
      <div>
        <Helmet defer={false}>
          <title>{PAGE_TITLE}</title>
        </Helmet>
        <BackLink title={document.title} >
          <Heading>{PAGE_TITLE}</Heading>
        </BackLink>
        <div className="row mt-4">
          <div className="col-xl-4 col-sm-6 col-12 mb-3">
            <ProductForm type={1} title="Личный капитал" freeProducts={data} loadFreeProducts={this.loadFreeProducts} />
          </div>
          <div className="col-xl-4 col-sm-6 col-12 mb-3">
            <ProductForm type={2} title="Летний отпуск" freeProducts={data} loadFreeProducts={this.loadFreeProducts} />
          </div>
          <div className="col-xl-4 col-sm-6 col-12 mb-3">
            <ProductForm type={3} title="Юбилейный-10" freeProducts={data} loadFreeProducts={this.loadFreeProducts} />
          </div>
          <div className="col-xl-4 col-sm-6 col-12 mb-3">
            <ProductForm type={4} title="Пенсионный" freeProducts={data} loadFreeProducts={this.loadFreeProducts} />
          </div>
          <div className="col-xl-4 col-sm-6 col-12 mb-3">
            <ProductForm type={5} title="Детский" freeProducts={data} loadFreeProducts={this.loadFreeProducts} />
          </div>
        </div>
      </div>
    );
  }

  private loadFreeProducts = async () =>  {
    try {
      this.setState({
        loading: true,
      });
      const data = await ApiClient.getCalculationsProductByType(0);
      this.setState({
        data
      })
    } catch (e) {
      this.setState({
        error: e,
        data: [],
      })
    } finally {
      this.setState({
        loading: false,
      })
    }
  }

}
