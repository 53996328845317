import { Reducer } from "redux";
import { ActionTypes } from "./actions";
import { ChatsState, Message } from "./types";

const initialState: ChatsState = {
  allChats: {
    loading: false,
    error: null,
    data: [],
  },
  chatMessages: {
    loading: false,
    error: null,
    data: [],
  },
  activeChat: 1,
  unreadCount: 0,
};

const createOrUpdateChats = (stateLocal: ChatsState, actionLocal: any) => {
  let chatWasFound = false;
  const arrayChats =  stateLocal.allChats.data.map((item: Message) => {
    if (item.ChatId === actionLocal.payload.ChatId) {
      chatWasFound = true;
      return {
        ...actionLocal.payload,
        Id: actionLocal.payload.Id || item.Id,
        IsMassive: actionLocal.payload.IsMassive || item.IsMassive,
        UserToName: actionLocal.payload.UserToName || item.UserToName,
      }
    }

    return item;
  });

  if (!chatWasFound) {
    return [...stateLocal.allChats.data, actionLocal.payload]
  }

  return arrayChats;
};

const addMessageToChat = (stateLocal: ChatsState, actionLocal: any) => {
  if (stateLocal.activeChat === actionLocal.payload.ChatId) {
    return [ ...stateLocal.chatMessages.data, actionLocal.payload ];

  }

  return stateLocal.chatMessages.data;
};

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CHATS_GET_ALL_REQUEST:
      return {
        ...state,
        allChats: {
          ...state.allChats,
          loading: true,
          error: null,
        }
      };
    case ActionTypes.CHATS_GET_ALL_SUCCESS:
      return {
        ...state,
        allChats: {
          ...state.allChats,
          loading: false,
          data: action.payload,
        }
      };
    case ActionTypes.CHATS_GET_ALL_ERROR:
      return {
        ...state,
        allChats: {
          ...state.allChats,
          loading: false,
          error: action.payload,
        }
      };

    case ActionTypes.GET_ALL_CHAT_MESSAGE_REQUEST:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          loading: true,
          error: null,
          data: [],
        },
        activeChat: action.payload
      };
    case ActionTypes.GET_ALL_CHAT_MESSAGE_SUCCESS:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          loading: false,
          data: action.payload,
        }
      };
    case ActionTypes.GET_ALL_CHAT_MESSAGE_ERROR:
      return {
        ...state,
        chatMessages: {
          ...state.chatMessages,
          loading: false,
          error: action.payload,
        }
      };

    case ActionTypes.ADD_CHAT_MESSGE:
      return {
        ...state,
        allChats: {
          ...state.allChats,
          data: createOrUpdateChats(state, action),
        },
        chatMessages: {
          ...state.chatMessages,
          data: addMessageToChat(state, action),
        }
      };

    case ActionTypes.UPDATE_UNREADED_MESSAGES_COUNT:
      return {
        ...state,
        unreadCount: action.payload,
      };

    case ActionTypes.READ_CHAT_MESSAGES:
      return {
        ...state,
        allChats: {
          ...state.allChats,
          data: state.allChats.data.map((item: Message) => {
            if (item.ChatId === action.payload) {
              return {
                ...item,
                IsReaded: true,
              }
            }
            return item;
          })
        }
      };

    default:
      return state;
  }
};


export { reducer as chatReducer };
