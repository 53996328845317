import React, { Component } from 'react';

export default class SubMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuShowed: false,
    };
  }

  isActive() {
    const { activeKey, id } = this.props;
    return activeKey.indexOf(id) === 0;
  }

  toggleMenu(value) {
    if (value === this.state.menuShowed) return;
    this.setState({ menuShowed: value });
  }

  render() {
    const { icon, collapsed, title, onClick, children } = this.props;
    const { menuShowed } = this.state;

    let content;
    let submenu;

    if (collapsed) {
      content = (
        <div
          className="menu-sub__header_collapsed"
          onClick={onClick}
          onMouseOver={() => this.toggleMenu(true)}
          onMouseOut={() => this.toggleMenu(false)}
        >
          <div className="menu-sub__icon">{icon}</div>
          <div className="menu-sub__title">{title}</div>
          <div className={`menu-sub__dropdown${menuShowed ? '_showed' : ''}`}>{children}</div>
        </div>
      );

    } else {
      content = (
        <div className="menu-sub__header" onClick={onClick}>
          <div className="menu-sub__icon">{icon}</div>
          <div className="menu-sub__title">{title}</div>
          <i className={`fa fa-angle-${this.isActive() ? 'down' : 'left'} menu-sub__arrow`} />
        </div>
      );

      if (this.isActive()) {
        submenu = <div className="menu-sub__submenu">{children}</div>;
      }
    }

    return (
      <div
        className={`menu-sub ${this.isActive() ? 'menu-sub_active' : ''}`}
      >
        {content}
        {submenu}
      </div>
    );
  }
}
