export const CAPITALIATION_CODE = 1;
export const CAPITALIATION_LABEL = 'Капитализация';
export const MONTHLY_CODE = 2;
export const MONTHLY_LABEL = 'Ежемесячно';
export const AT_THE_END_CODE = 15;
export const AT_THE_END_LABEL = 'В конце срока';

export default {
  [CAPITALIATION_LABEL]: CAPITALIATION_CODE,
  [MONTHLY_LABEL]: MONTHLY_CODE,
  [AT_THE_END_LABEL]: AT_THE_END_CODE,
};

export const funcPayLoan = {
  'Аннуитет': 3,
  'Дифференцированно': 4,
  'Свободный график': 5,
  'Аннуитет в конце месяца': 12,
  'Дифференцированно в конце месяца': 13,
};


export const funcPayRaising = {
  'По окончанию': 10,
  'Ежемесячно': 16,
  'Ежеквартально': 21,
};
