const white = "#fff";

export default {
  colors: {
    white,
    defaultFont: "#676a6c",
    lightGray: "#e7eaec",
    disabledGray: "#e9ecef",
    errorRed: "#dc3545",
    primaryColor: "#1c84c6",
    successGreen: "#1ab394",
    filters: {
      buttonBg: "#6c757d",
      buttonBgHovered: "#5a6268",
      buttonBgActive: "#545b62",
      buttonBorder: "#6c757d",
      buttonBorderHovered: "#545b62",
      buttonBorderActive: "#4e555b",
      textInverted: white
    },
    link: {
      default: "#007bff",
      hover: "#0056b3",
      disabled: "#6c757d"
    },
    fields: {
      disabled: {
        background: "#e9ecef",
        font: "#495057",
        borders: "#ced4da"
      }
    },
    panel: {
      error: {
        background: "#f8d7da",
        font: "#721c24",
        borders: "#f5c6cb"
      },
      success: {
        background: "#d4edda",
        font: "#155724",
        borders: "#c3e6cb"
      }
    }
  }
};
