import moment, { MomentInput } from "moment";
import { Line, LineNameFormatter, LoadingStatus, Point } from "../types";
import { DataPoint, PieChartSeriesOptions } from "highcharts";

export const getMaxDate = (lines: Line[]): number =>
  Math.max(...(lines.map((line: Line) => moment(line.StartDate).valueOf())));

export const getPeriodDates = (line: Line) => {
  const firstDate = moment(line.StartDate).format("MMM YYYY");
  const lastDate = moment(line.EndDate).format("MMM YYYY");
  return { firstDate, lastDate };
};

export const toChartPoints = (line: Line, maxDate: MomentInput) =>
  line.Values.map((point: Point) => [
    moment(point.Date).valueOf() + moment(maxDate).diff(moment(line.StartDate)),
    point.Value
  ]);

export const getLineChartData = (lines: Line[] = [], nameFormatter: LineNameFormatter) => {
  const maxDate = getMaxDate(lines);
  return lines.map((line: Line) => {
    const { firstDate, lastDate } = getPeriodDates(line);
    return {
      name: nameFormatter(line, firstDate, lastDate),
      data: toChartPoints(line, maxDate)
    };
  });
};

export const getPieChartData = (
  lines: Line[] = [],
  field: string,
  commonValueAccumulator: (...args: any[]) => any,
  partValueMapper: (...args: any[]) => any
) => {
  const commonPieDefault = { data: [] as DataPoint, size: "60%" } as PieChartSeriesOptions;
  const partsPieDefault = { data: [] as DataPoint, size: "80%", innerSize: "70%" } as PieChartSeriesOptions;
  const possibleValues = lines.reduce((acc, line) => {
    // @ts-ignore todo
    if (!acc.includes(line[field])) {
      // @ts-ignore todo
      acc.push(line[field]);
    }
    return acc;
  }, []);

  const [commonPie, partsPie] = possibleValues.reduce((acc, value) => {
    // @ts-ignore todo
    const filtered = lines.filter((line) => line[field] === value);

    const commonItem = { y: 0, name: "" };
    const common = filtered.reduce(commonValueAccumulator, commonItem);

    const parts = filtered.map((line: Line) => {
      const { firstDate, lastDate } = getPeriodDates(line);
      return partValueMapper(line, firstDate, lastDate);
    });

    acc[0].data = [...acc[0].data, common];
    acc[1].data = [...acc[1].data, ...parts];
    return acc;
  }, [commonPieDefault, partsPieDefault]);

  return [commonPie, partsPie];
};

export const getDefaultState = () => ({
  filters: {
    currency: "USD",
    period: "year",
    dateFrom: moment()
      .startOf("month")
      .add(1, "month")
      .subtract(1, "y")
      .format("YYYY-MM-DD"),
    dateTo: moment()
      .endOf("month")
      .format("YYYY-MM-DD"),
    compare: false
  },
  data: [],
  loading: "ok" as LoadingStatus
});
