import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter } from 'react-router-dom';
import ChatListBlock from './ChatListBlock';

import { AppState } from '../../../../store';
import {
  getAllChatsMessages,
  getAllChatsLoading,
  getAllChatsRequest,
  getAllChatMessageRequest, readChatMessages
} from "../../../../store/chat";

const mapStateToProps = (state: AppState) => ({
  chats: getAllChatsMessages(state),
  loading: getAllChatsLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAllChatsRequest,
      getAllChatMessageRequest,
      readChatMessages,
    },
    dispatch
  );

const ChatWithRouter = withRouter(ChatListBlock);
export default connect(mapStateToProps, mapDispatchToProps)(ChatWithRouter);
