import * as React from 'react';
import { remove, join } from 'lodash';
import { FormLine } from '../../modules/Clients/components/Views';
import { Strong } from '../Typography';
import { renderValue } from '../../modules/Clients/utils';
import { FormLabel } from '../FormLabel';
import DatePicker from '../DatePicker/DatePicker';
import styled from '../../styled/styled-components';
import {
  READONLY_FIELDS,
  PARTNER_TYPE_LABELS,
  RELATION_LABELS,
  DIPLOMA_TYPES_LABELS, SECURITY_SERVICE_STATUS, FEE_PAID_STATUS,
} from '../../modules/Partner/constants';
import { FieldType } from '../../modules/Clients/types';
import { CountriesSelector, RegionSelector, CitySelector, StreetSelector } from "../RegionsSelectors";
import Input from '../Input';
import { IS_ACTIVE_LABELS, GENDER_LABELS, NONE_VALUE, MARITAL_LABELS, HAVE_CHILDS, LIVING_PLACE_LABELS, FEEDBACK_LABELS, MESSENGERS_LABELS, SOCIAL_LABELS, IS_MAIN } from '../../constants';
import Select from '../Select';
import { parseServerDate, convertToUtc } from '../../utils';
import { Checkbox } from "../FormControls/FormControls";
import { BILL_TYPES } from "../../modules/Products/constants";
import PhoneInput from '../PhoneInput';
import { AdditionalPhone } from "../../modules/Partner/types";

const StyledDatePicker = styled(DatePicker)`
  display: inline;
`;

export interface FormItemProps<T> {
  fieldName: keyof T;
  data: T;
  disabled?: boolean;
  label?: string;
  isRequired?: boolean;
  error?: boolean;
  valueLabels?: { [key: string]: Map<number, string> };
  onChange?: (fieldName: FieldType<T>, value: any, additionalData?: any) => void;
  regionLabels?: {
    countries: Map<any, any>;
    regions: Map<any, any>;
    cities: Map<any, any>;
  };
  regionData?: {
    countryId?: number;
    regionId?: number;
    cityId?: number;
  };
}

const ControlWrapper = styled.div`
  width: 77%;
`;

const StyledFormLabel = styled(FormLabel)`
  width: 33%;
`;

const Menu = styled.div`
  padding: 8px 10px;
`;

const MenuItem = styled.label`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 8px;
  cursor: pointer;
`;

const MenuItemCheckbox = styled(Checkbox)`
  margin-right: 15px;
`;

export default class FormItem<T> extends React.PureComponent<FormItemProps<T>> {
  public render() {
    const { fieldName, label, isRequired } = this.props;
    return (
      <FormLine>
        <StyledFormLabel required={isRequired} htmlFor={fieldName.toString()}>
          <Strong>{label}</Strong>
        </StyledFormLabel>
        <ControlWrapper>{this.renderControl()}</ControlWrapper>
      </FormLine>
    );
  }

  private handleChangeSelect = (
    fieldName: FieldType<T>,
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    if (this.props.onChange) {
      const { value } = event.target;
      this.props.onChange(fieldName, value === NONE_VALUE ? null : value);
    }
  };

  private handleChangeCheckBox = (
    fieldName: FieldType<T>,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {
      data
    } = this.props;

    const { value: messager, checked } = event.target;

    let value = renderValue(fieldName, data);
    const inResult = value.toString().includes(messager);
    value = value.toString().split(', ');
    if (checked && !inResult) {
      value.push(messager)
    }

    if (!checked && inResult) {
      value = remove(value, (item) => {
        return item !== messager;
      })
    }
    if (this.props.onChange) {
      this.props.onChange(fieldName, join(value, ', '));
    }
  };

  private renderControl = () => {
    const {
      fieldName, disabled, data, isRequired, regionData, onChange,
    } = this.props;
    const fieldNameAsString = fieldName.toString() as FieldType<T>;
    const value = renderValue(fieldNameAsString, data);
    const labels = this.getLabels(fieldNameAsString);
    const isReadOnly = READONLY_FIELDS.indexOf(fieldNameAsString) > -1;

    const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(fieldNameAsString, event.target.value);
      }
    };

    const getAdditionalPhoneByType = (type: number) => {
      // @ts-ignore
      const additionalPhone = data.AdditionalPhones.find((phone: AdditionalPhone) => phone.Type === type) || {};
      return  {
        Number: '',
        CountryId: 1,
        ...additionalPhone,
        Type: type,
      }
    }

    const commonProps = {
      name: fieldNameAsString,
      id: fieldNameAsString,
      disabled,
      value,
      required: isRequired,
    };

    switch (fieldNameAsString) {
      case 'DateOfIssue':
      case 'DateObtain':
      case 'IssuedDate':
      case 'DateStart':
      case 'DateClose':
      case 'DateEnd':
      case 'DateCreate':
      case 'BirthDate':
      case 'RegistrationDate':
      case 'PowerOfAttorneyStartDate':
      case 'PowerOfAttorneyEndDate':
      case 'ExpirationDate':
      case 'RegDate': {
        const changeHandler = (date: Date, event: any) => {
          if (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value)) {
            if (this.props.onChange) {

              this.props.onChange(fieldNameAsString, date ? convertToUtc(date).toISOString() : null);
            }
          }
        };

        return (
          <StyledDatePicker
            selected={data[fieldNameAsString] && parseServerDate(data[fieldNameAsString].toString())}
            onChange={changeHandler}
            showYearDropdown
            showMonthDropdown
            disabledKeyboardNavigation
            name={fieldNameAsString}
            id={fieldNameAsString}
            required={isRequired}
            disabled={isReadOnly || disabled}
            readOnly={isReadOnly || disabled}
            mask={'99.99.9999'}
          />
        );
      }

      case 'FillInStaff':
      case 'ResponsableStaff':
        if (labels) {
          return (
            <Select
              selectProps={{
                ...commonProps,
                value: value == null ? NONE_VALUE : value,
                onChange: this.handleChangeSelect.bind(null, fieldNameAsString),
              }}
              fullWidth
              items={[
                {
                  key: NONE_VALUE,
                  label: '---',
                },
                ...[...labels].map(([key, label]) => ({
                  key,
                  label,
                })),
              ]}
            />
          );
        }
        break;

      case 'NationalityId':
      case 'CountryId':
        return (
          <CountriesSelector
            {...commonProps}
            value={value}
            onChange={
              this.props.onChange ? this.props.onChange.bind(null, fieldNameAsString) : undefined
            }
          />
        );

      case 'RegionId':
        if (!regionData || regionData.countryId === undefined) {
          return null;
        }
        return (
          <RegionSelector
            {...commonProps}
            value={value}
            countryId={regionData.countryId}
            onChange={
              this.props.onChange ? this.props.onChange.bind(null, fieldNameAsString) : undefined
            }
          />
        );

      case 'CityId':
        if (
          !regionData ||
          regionData.countryId === undefined ||
          regionData.regionId === undefined
        ) {
          return null;
        }

        return (
          <CitySelector
            {...commonProps}
            countryId={regionData.countryId}
            regionId={regionData.regionId}
            value={value}
            onChange={
              this.props.onChange ? this.props.onChange.bind(null, fieldNameAsString) : undefined
            }
          />
        );

      case 'StreetName':
        if (
          !regionData ||
          regionData.countryId === undefined ||
          regionData.regionId === undefined ||
          regionData.cityId === undefined
        ) {
          return null;
        }

        return (
          <StreetSelector
            {...commonProps}
            cityId={regionData.cityId}
            value={value}
            onChange={
              this.props.onChange ? this.props.onChange.bind(null, fieldNameAsString) : undefined
            }
          />
        );

      case 'Sum':
        return (
          <Input
            {...commonProps}
            onChange={inputChangeHandler}
            type="number"
            error={this.props.error}
          />
        );

      case 'Gender':
      case 'PartnerTypeId':
      case 'IsActive':
      case 'IsMain':
      case 'UseMFPercent':
      case 'UseProgressive':
      case 'UseCapital':
      case 'IsClosed':
      case 'RelationId':
      case 'Type':
      case 'BillTypeId':
      case 'FuncPayId':
      case 'CurrencyId':
        if (labels) {
          return (
            <Select
              selectProps={{
                ...commonProps,
                value: value == null ? NONE_VALUE : value,
                onChange: this.handleChangeSelect.bind(null, fieldNameAsString),
              }}
              fullWidth
              items={[...labels].map(([key, label]) => ({
                key,
                label,
              }))}
            />
          );
        }
        break;
      case 'MaritalStatus':
        if (labels) {
          return (
            <Select
              selectProps={{
                ...commonProps,
                value: value == null ? NONE_VALUE : value,
                onChange: this.handleChangeSelect.bind(null, fieldNameAsString),
              }}
              fullWidth
              items={[...labels].map(([key, label]) => ({
                key,
                label,
              }))}
            />
          );
        }
        break;
      case 'HaveChildrens':
      case 'IsEntryFeePaid':
        if (labels) {
          return (
            <Select
              selectProps={{
                ...commonProps,
                value: value ? 1 : 0,
                onChange: this.handleChangeSelect.bind(null, fieldNameAsString),
              }}
              fullWidth
              items={[...labels].map(([key, label]) => ({
                key,
                label,
              }))}
            />
          );
        }
        break;
      case 'SecurityServiceStatus':
      case 'VideoVerificationStatus':
        if (labels) {
          return (
            <Select
              selectProps={{
                ...commonProps,
                value: value ? value : 0,
                onChange: this.handleChangeSelect.bind(null, fieldNameAsString),
              }}
              fullWidth
              items={[...labels].map(([key, label]) => ({
                key,
                label,
              }))}
            />
          );
        }
        break;
      case 'LivingPlace':
        if (labels) {
          return (
            <Select
              selectProps={{
                ...commonProps,
                value: value == null ? NONE_VALUE : value,
                onChange: this.handleChangeSelect.bind(null, fieldNameAsString),
              }}
              fullWidth
              items={[...labels].map(([key, label]) => ({
                key,
                label,
              }))}
            />
          );
        }
        break;
      case 'Feedback':
        if (labels) {
          return (
            <Select
              selectProps={{
                ...commonProps,
                value: value == null ? NONE_VALUE : value,
                onChange: this.handleChangeSelect.bind(null, fieldNameAsString),
              }}
              fullWidth
              items={[...labels].map(([key, label]) => ({
                key,
                label,
              }))}
            />
          );
        }
        break;
      case 'Messengers':
          return (
            <Menu>
              {[...labels].map(([key, label]) => (
                <MenuItem key={key}>
                  <MenuItemCheckbox
                    checked={value.toString().includes(label.toString())}
                    disabled={commonProps.disabled || isReadOnly}
                    value={label.toString()}
                    onChange={this.handleChangeCheckBox.bind(null, fieldNameAsString)}
                  />{" "}
                  {label}
                </MenuItem>
              ))}
            </Menu>
          );
        break;

      case 'SocialNetworks':
        return (
          <Menu>
            {[...labels].map(([key, label]) => (
              <MenuItem key={key}>
                <MenuItemCheckbox
                  checked={value.toString().includes(label.toString())}
                  disabled={commonProps.disabled || isReadOnly}
                  value={label.toString()}
                  onChange={this.handleChangeCheckBox.bind(null, fieldNameAsString)}
                />{" "}
                {label}
              </MenuItem>
            ))}
          </Menu>
        );
        break;

      case 'PhoneNumber':
        return (
          <PhoneInput
            onChange={(phonenumber: any, additionalData: any) => onChange ? onChange(fieldNameAsString, phonenumber, additionalData) : null}
            value={value}
            // @ts-ignore
            PhoneCountryId={data.PhoneCountryId || 1}
            disabled={commonProps.disabled || isReadOnly}
            hasError={this.props.error}
            disableCountryCode
          />
        );

      case 'PhoneMobileAdd':
        const PhoneMobileAdd = getAdditionalPhoneByType(1);
        return (
          <PhoneInput
            onChange={(phonenumber: any, additionalData: any) => onChange ? onChange(fieldNameAsString, phonenumber, additionalData) : null}
            value={PhoneMobileAdd.Number}
            PhoneCountryId={PhoneMobileAdd.CountryId}
            disabled={commonProps.disabled || isReadOnly}
            hasError={this.props.error}
            disableCountryCode
          />
        );

      case 'PhoneHome':
        const PhoneHome = getAdditionalPhoneByType(2);
        return (
          <PhoneInput
            onChange={(phonenumber: any, additionalData: any) => onChange ? onChange(fieldNameAsString, phonenumber, additionalData) : null}
            value={PhoneHome.Number}
            PhoneCountryId={PhoneHome.CountryId}
            disabled={commonProps.disabled || isReadOnly}
            hasError={this.props.error}
            disableCountryCode
          />
        );

      default:
        return (
          <Input
            {...commonProps}
            readOnly={commonProps.disabled || isReadOnly}
            disabled={commonProps.disabled || isReadOnly}
            error={this.props.error}
            fullWidth
            onChange={inputChangeHandler}
          />
        );
    }
  };

  private getLabels = (fieldName: string) => {
    switch (fieldName) {
      case 'Gender':
        return GENDER_LABELS;

      case 'Feedback':
        return FEEDBACK_LABELS;

      case 'Messengers':
        return MESSENGERS_LABELS;

      case 'SocialNetworks':
        return SOCIAL_LABELS;

      case 'MaritalStatus':
        return MARITAL_LABELS;

      case 'HaveChildrens':
        return HAVE_CHILDS;

      case 'LivingPlace':
        return LIVING_PLACE_LABELS;

      case 'IsMain':
      case 'UseMFPercent':
      case 'UseProgressive':
      case 'UseCapital':
      case 'IsClosed':
        return IS_MAIN;

      case 'PartnerTypeId':
        return this.props.valueLabels
          ? this.props.valueLabels.partnerTypes || PARTNER_TYPE_LABELS
          : PARTNER_TYPE_LABELS;

      case 'BillTypeId':
        return this.props.valueLabels
          ? this.props.valueLabels.BillTypeId || BILL_TYPES
          : BILL_TYPES;

      case 'FuncPayId':
        return this.props.valueLabels
          ? this.props.valueLabels.FuncPayId
          : null;

      case 'IsActive':
        return IS_ACTIVE_LABELS;

      case 'RelationId':
        return RELATION_LABELS;

      case 'Type':
        return DIPLOMA_TYPES_LABELS;

      case 'SecurityServiceStatus':
      case 'VideoVerificationStatus':
        return SECURITY_SERVICE_STATUS;

      case 'IsEntryFeePaid':
        return FEE_PAID_STATUS;

      case 'ResponsableStaff':
        return this.props.valueLabels && this.props.valueLabels.managers;

      case 'FillInStaff':
        return this.props.valueLabels && this.props.valueLabels.users;

      case 'CurrencyId':
        return this.props.valueLabels && this.props.valueLabels.currencies;

      default:
        break;
    }
  };
}
