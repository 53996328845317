import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import Button  from "../../../components/Button/Button";
import { Heading } from "../../../components/Typography";
import { AppState } from "../../../store";
import { ActiveRow } from "../../../store/forms";
import BackLink from "../../../components/BackLink";

const Container = styled.header`
  margin: -24px -24px 0;
  padding: 14px 15px 7px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #e7eaec;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const HeaderButton = styled(Button)`
  margin-left: 5px;
`;

const ListHeader: React.SFC<{
  activeRow?: ActiveRow,
  removeProduct: (id?: number) => void,
}> = React.memo(({ activeRow, removeProduct }) => (
  <Container>
    <BackLink title={document.title} >
      <Heading>Продукты</Heading>
    </BackLink>
    <ButtonsWrapper>
      <HeaderButton href="/Product/create" type="success">
        Добавить
      </HeaderButton>
      <HeaderButton onClick={() => removeProduct(activeRow && activeRow.Id)} type="success">
        Удалить
      </HeaderButton>
    </ButtonsWrapper>
  </Container>
));

const mapStateToProps = (state: AppState) => ({
  // @ts-ignore
  activeRow: state.forms.tableForm.activeRow,
});

export default connect(mapStateToProps)(ListHeader)
