import * as React from "react";
import { get, isEmpty, isArray, set } from "lodash";
import moment from "moment";
import { ifProp } from "styled-tools";
import { toast } from "react-toastify";

import FormControl from "../FormControl";
import {
  FormLoan,
  Partner,
  FormState,
  LoanProductsParams,
  LoanProductsConditionsParams
} from "../../../../store/forms";
import { NONE_VALUE } from "../../../../constants";
import { convertToUtc } from "../../../../utils";
import Button from "../../../../components/Button/Button";
import ErrorMessage from "../../../../modules/Errors/components/ErrorMessage";
import styled, { css } from "../../../../styled/styled-components";

import currencyCodes from "../../../../constants/currencyCodes";
import ApiClient from "../../../../services/api";

export interface LoanFormState {
  loading: boolean;
  formErrors: {
    IdAspPartner: string,
    IsMain: string,
    ProductCondId: string,
    IdPartner: string,
    Famale: string,
    Name: string,
    Patronymic: string,
    OpeningDate: string,
    CurrencyId: string,
    SumOpen: string,
    LoanMonth: string,
    InformPartner: string,
    IsAutoPayLoanCurrent: string,
    IsAutoPayLoanSalary: string,
    ProductId: string,
    Percent: string,
    MemberPercent: string,
    FuncPayName: string,
    Fine: string,
    FirstPaymentDate: string,
  },
  formValid: boolean;
  errorMessage: string;
  formLoading: boolean;
}

export interface LoanFormProps extends FormState {
  searchPartnersRequest: (data: Partner) => void;
  getLoanProductsRequest: (data: LoanProductsParams) => void;
  getLoanProductConditionsRequest: (data: LoanProductsConditionsParams) => void;
  cleanLoanProducts: () => void;
  goToList: (path: string, state: any) => void;
  partnerNumber?: string;
}

interface WrapperProps {
  loading?: boolean;
}

const LoadingWrapper = styled.div<WrapperProps>`
    ${ifProp("loading", css`
      background-color: #eee;
  `)}
`;

export default class LoanForm extends React.PureComponent<LoanFormProps> {
  public state: FormLoan & LoanFormState = {
    IdAspPartner: undefined,
    IsMain: false,
    ProductCondId: undefined,
    IdPartner: "",
    Famale: "",
    Name: "",
    Patronymic: "",
    OpeningDate: convertToUtc((new Date())).toISOString(),
    CurrencyId: 1,
    SumOpen: undefined,
    LoanMonth: undefined,
    InformPartner: false,
    IsAutoPayLoanCurrent: true,
    IsAutoPayLoanSalary: true,
    ProductId: undefined,
    Percent: undefined,
    MemberPercent: undefined,
    FuncPayName: "",
    Fine: undefined,
    FirstPaymentDate: convertToUtc(moment().add(1, 'months').toDate()).toISOString(),
    loading: false,
    formErrors: {
      IdAspPartner: "",
      IsMain: "",
      ProductCondId: "",
      IdPartner: "",
      Famale: "",
      Name: "",
      Patronymic: "",
      OpeningDate: "",
      CurrencyId: "",
      SumOpen: "",
      LoanMonth: "",
      InformPartner: "",
      IsAutoPayLoanCurrent: "",
      IsAutoPayLoanSalary: "",
      ProductId: "",
      Percent: "",
      MemberPercent: "",
      FuncPayName: "",
      Fine: "",
      FirstPaymentDate: ""
    },
    formValid: true,
    errorMessage: "",
    formLoading: false
  };

  public componentDidMount() {
    this.loadPartner();
  }

  public componentDidUpdate(prevProps: any, prevState: any) {
    const { loanForm, search, cleanLoanProducts } = this.props;
    if (prevProps.loanForm.products.loading && !loanForm.products.loading && !isEmpty(loanForm.products.data)) {
      const data = loanForm.products.data;
      // tslint:disable-next-line:no-shadowed-variable
      this.setState((prevState) => ({
        ...prevState,
        ...data
      }));
    }
    if (prevProps.loanForm.conditions.loading && !loanForm.conditions.loading && !isEmpty(loanForm.conditions.data)) {
      const data = loanForm.conditions.data;
      // tslint:disable-next-line:no-shadowed-variable
      this.setState((prevState) => ({
        ...prevState,
        ...data
      }));
    }
    if (isEmpty(prevProps.search.error) && !isEmpty(search.error)) {
      this.setState({
        formValid: false,
        errorMessage: search.error.ExceptionMessage
      });
    }
    if (isEmpty(prevProps.loanForm.conditions.error) && !isEmpty(loanForm.conditions.error)) {
      this.setState({
        formValid: false,
        errorMessage: loanForm.conditions.error.ExceptionMessage
      })
    }
    if (isEmpty(prevProps.loanForm.products.error) && !isEmpty(loanForm.products.error)) {
      this.setState({
        formValid: false,
        errorMessage: loanForm.products.error.ExceptionMessage,
        Percent: "",
        MemberPercent: "",
        FuncPayName: "",
        Fine: ""

      })
    }
    if (isEmpty(loanForm.products.error) && isEmpty(loanForm.conditions.error) && isEmpty(search.error) && !prevState.formValid) {
      this.setState({
        formValid: true,
        errorMessage: ""
      });
    }
    if ((prevState.SumOpen && !this.state.SumOpen)
        || (prevState.LoanMonth && !this.state.LoanMonth)) {
      this.setState({
        Percent: "",
        MemberPercent: "",
        FuncPayName: "",
        Fine: "",
        errorMessage: ""
      }, cleanLoanProducts)
    }
  }

  public render() {
    const {
      formErrors,
      CurrencyId,
      Famale,
      IdPartner,
      Name,
      Patronymic,
      OpeningDate,
      SumOpen,
      LoanMonth,
      InformPartner,
      IsAutoPayLoanCurrent,
      IsAutoPayLoanSalary,
      Percent,
      Fine,
      FuncPayName,
      MemberPercent,
      FirstPaymentDate,
      IsMain,
      ProductId,
      formValid,
      errorMessage,
      formLoading,
      ProductCondId
    } = this.state;
    const { search: { data: searchData } } = this.props;
    const loading = get(this.props, "loanForm.products.loading", false)
      || get(this.props, "loanForm.conditions.loading", false);
    const products = get(this.props, "loanForm.products.data.Products", []);
    const data = products.map((item: any) => ([item.Text, item.Value]));
    return (
      <div>
        <FormControl
          onChange={this.handleChangeAndFind("IdPartner")}
          onFocus={this.onFocus("IdPartner")}
          handleChangeAutoSelect={this.handleChangeAutoSelect}
          fieldName="Number"
          value={IdPartner}
          label="Пайщик"
          error={formErrors.IdPartner}
          searchData={searchData}
          type="autocomplete"
        />
        <FormControl
          onChange={this.handleChangeAndFind("Famale")}
          onFocus={this.onFocus("Famale")}
          handleChangeAutoSelect={this.handleChangeAutoSelect}
          value={Famale}
          fieldName="Famale"
          label="Фамилия"
          error={formErrors.Famale}
          searchData={searchData}
          // type="autocomplete"
          disabled
        />
        <FormControl
          onChange={this.handleChangeAndFind("Name")}
          onFocus={this.onFocus("Name")}
          handleChangeAutoSelect={this.handleChangeAutoSelect}
          value={Name}
          fieldName="Name"
          label="Имя"
          error={formErrors.Name}
          searchData={searchData}
          // type="autocomplete"
          disabled
        />
        <FormControl
          onChange={this.handleChangeAndFind("Patronymic")}
          handleChangeAutoSelect={this.handleChangeAutoSelect}
          value={Patronymic}
          fieldName="Patronymic"
          label="Отчество"
          error={formErrors.Patronymic}
          searchData={searchData}
          // type="autocomplete"
          disabled
        />
        <FormControl
          onChange={this.handleChangeDate("OpeningDate")}
          value={OpeningDate}
          label="Дата открытия"
          type="date"
          disabled
        />
        <FormControl
          onChange={this.handleChange("CurrencyId", true)}
          value={CurrencyId}
          data={Object.entries(currencyCodes)}
          label="Валюта"
          type="select"
          handleBlur={this.loadLoanProducts}
        />
        <FormControl
          onChange={this.handleChange("SumOpen")}
          onFocus={this.onFocus("SumOpen")}
          value={SumOpen}
          label="Сумма займа"
          error={formErrors.SumOpen}
          isNumber
          handleBlur={this.loadLoanProducts}
        />
        <FormControl
          onChange={this.handleChange("LoanMonth")}
          onFocus={this.onFocus("LoanMonth")}
          value={LoanMonth}
          label="Срок(количество месяцев)"
          error={formErrors.LoanMonth}
          isNumber
          handleBlur={this.loadLoanProducts}
        />
        <FormControl
          onChange={this.handleChangeDate("FirstPaymentDate")}
          value={FirstPaymentDate}
          label="Число первого платежа"
          type="date"
          maxDate={moment().add(2, 'months').toDate()}
          minDate={moment().add(1, 'months').add(1, 'days').toDate()}
        />
        <FormControl
          onChange={this.handleChangeCheckbox("InformPartner")}
          value={InformPartner}
          label="Информировать клиента"
          error={formErrors.InformPartner}
          type="checkbox"
        />
        <FormControl
          onChange={this.handleChangeCheckbox("IsAutoPayLoanCurrent")}
          value={IsAutoPayLoanCurrent}
          label="Автоматическое списание с ТС"
          error={formErrors.IsAutoPayLoanCurrent}
          type="checkbox"
        />
        <FormControl
          onChange={this.handleChangeCheckbox("IsAutoPayLoanSalary")}
          value={IsAutoPayLoanSalary}
          label="Автоматическое списание с ЗП"
          error={formErrors.IsAutoPayLoanSalary}
          type="checkbox"
        />
        <LoadingWrapper loading={loading ? loading : undefined}>
          <FormControl
            onChange={this.handleChange("ProductId")}
            value={ProductId}
            data={isArray(products) && !isEmpty(products) ? data : [["Нет", "Нет"]]}
            label="Продукт"
            type="select"
          />
          <FormControl
            onChange={this.handleChangeCheckbox("IsMain", true)}
            value={IsMain}
            label="Только основные продукты"
            error={formErrors.IsMain}
            type="checkbox"
          />
          <FormControl
            onChange={this.handleChange("Percent")}
            value={Percent}
            label="Процентная ставка"
            error={formErrors.Percent}
            disabled
          />
          <FormControl
            onChange={this.handleChange("MemberPercent")}
            value={MemberPercent}
            label="Членский взнос"
            error={formErrors.MemberPercent}
            disabled
          />
          <FormControl
            onChange={this.handleChange("FuncPayName")}
            value={FuncPayName}
            label="Тип начисления процентов"
            error={formErrors.FuncPayName}
            disabled
          />
          <FormControl
            onChange={this.handleChange("Fine")}
            value={Fine}
            label="Пеня"
            error={formErrors.Fine}
            disabled
          />
        </LoadingWrapper>
        {(!formValid || errorMessage) && (
          <div className="alert alert-danger mt-3 d-print-none">
            <ErrorMessage
              errorData={{ message: "Ошибка", verbose: errorMessage || "Произошла ошибка. Попробуйте снова" }}/>
          </div>
        )}
        <div className="row">
          <div className="col justify-content-end">
            <Button type="success" loading={formLoading} disabled={!IdPartner || !Famale || !Name || !ProductCondId || !OpeningDate || !LoanMonth} onClick={this.print}>
              Печать договора займа
            </Button>
            <Button type="success" className="ml-3" loading={formLoading} disabled={!formValid} onClick={this.submit}>
              Создать займ
            </Button>
          </div>
        </div>
      </div>
    );
  };

  private loadPartner = async () => {
    const { partnerNumber } = this.props;
    if (partnerNumber) {
      const partner = await ApiClient.getPartner(partnerNumber);

      if (partner) {
        this.setState({
          Famale: get(partner, "LastName"),
          Name: get(partner, "FirstName"),
          Patronymic: get(partner, "Patronymic"),
          IdPartner: partnerNumber
        });
      }
    }
  };


  private submit = () => {
    if (!this.validateForm()) {
      return false;
    }
    this.submitForm();
  };

  private print = async () => {
    const { IdPartner, CurrencyId, SumOpen, OpeningDate, LoanMonth, ProductCondId } = this.state;
    await this.setState({ formLoading: true });
    const partner = await ApiClient.getPartner(IdPartner);
    if (partner) {
      const result = await ApiClient.getLoanCode({ partnerId: partner.Id, currencyId: CurrencyId });
      await ApiClient.downloadFile(`/Template/LoanAgreement/${IdPartner}/${result.LoanCode}?openingSum=${SumOpen}&openingDate=${moment(OpeningDate).format("YYYY-MM-DD")}&closingDate=${moment(OpeningDate).add(LoanMonth, "month").format("YYYY-MM-DD")}&period=${LoanMonth}&prodCondition=${ProductCondId}`)
    }
    await this.setState({ formLoading: false });
  };

  private submitForm = async () => {
    this.setState({ formLoading: true });
    const { IdPartner } = this.state;
    try {
      const { Id: IdAspPartner } = await ApiClient.getPartner(IdPartner);
      if (!IdAspPartner) {
        throw new Error("Пайщик не найден");
      }
      const {
        IsMain,
        ProductCondId,
        Famale,
        Name,
        Patronymic,
        OpeningDate,
        CurrencyId,
        SumOpen,
        LoanMonth,
        InformPartner,
        IsAutoPayLoanCurrent,
        IsAutoPayLoanSalary,
        ProductId,
        Percent,
        MemberPercent,
        FuncPayName,
        Fine,
        FirstPaymentDate
      } = this.state;
      const result = await ApiClient.createLoan({
        IdAspPartner,
        IsMain,
        ProductCondId,
        IdPartner,
        Famale,
        Name,
        Patronymic,
        OpeningDate: moment(OpeningDate).format("DD.MM.YYYY"),
        CurrencyId,
        SumOpen: SumOpen && SumOpen.toString().replace('.', ','),
        LoanMonth,
        InformPartner,
        IsAutoPayLoanCurrent,
        IsAutoPayLoanSalary,
        ProductId,
        Percent,
        MemberPercent,
        FuncPayName,
        Fine,
        FirstPaymentDate: moment(FirstPaymentDate).format("DD.MM.YYYY")
      });
      this.setState({ formLoading: false });
      const { goToList } = this.props;
      toast(result.Result || 'Займ успешно открыт', {
        type: toast.TYPE.SUCCESS
      });
      goToList(`/Bill/Details/${result.BillCode}`,undefined);
    } catch (e) {
      this.setState({
        formLoading: false,
        errorMessage: e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова"
      });
    }
  };

  private validateForm = () => {
    const requiredFields = ["IdPartner", "Famale", "Name", "SumOpen", "LoanMonth"];
    const state = this.state;
    const formErrors = {};
    requiredFields.map((item) => {
      if (!get(state, item, "")) {
        set(formErrors, item, "Поле обязательно для заполнения");
      }
    });

    this.setState(prevState => ({
      ...prevState,
      formErrors: {
        ...state.formErrors,
        ...formErrors
      },
      formValid: isEmpty(formErrors),
      errorMessage: ""
    }));

    return isEmpty(formErrors);
  };

  private handleChange = (fieldName: string, watcher: boolean = false) => (e: any) => {
    const { target: { value } } = e;
    this.setState({
      [fieldName]: value
    }, () => {
      if (watcher) {
        this.loadLoanProducts();
      }
      if (fieldName === "ProductId") {
        this.loadLoanProductConditions();
      }
    });
  };

  private onFocus = (fieldName: string) => (e: any) => {
    const { formErrors } = this.state;
    this.setState(() => ({
      formErrors: {
        ...formErrors,
        [fieldName]: ""
      },
      formValid: true
    }));
  };

  private handleChangeAndFind = (fieldName: string) => (value: any) => {
    const { searchPartnersRequest } = this.props;
    const { IdPartner, Famale, Name, Patronymic, formErrors } = this.state;
    if (get(this.state, fieldName) !== value) {
      const findField = fieldName === "IdPartner" ? "Number" : fieldName;
      searchPartnersRequest({
        Number: IdPartner,
        Famale,
        Name,
        Patronymic,
        [findField]: value
      });
      this.setState({
        Famale: "",
        Name: "",
        Patronymic: "",
        formErrors: {
          ...formErrors,
          Famale: "",
          Name: "",
          Patronymic: ""
        }
      });
    }

    this.setState({
      [fieldName]: value
    });
  };

  private handleChangeCheckbox = (fieldName: string, watcher: boolean = false) => (e: any) => {
    const { target: { checked } } = e;
    this.setState({
      [fieldName]: checked
    }, () => {
      if (watcher) {
        this.loadLoanProducts();
      }
    });
  };

  private loadLoanProducts = () => {
    const { getLoanProductsRequest } = this.props;
    const { IsMain, SumOpen, CurrencyId, LoanMonth } = this.state;
    if (SumOpen && LoanMonth) {
      getLoanProductsRequest({
        sum: SumOpen,
        currency: CurrencyId,
        period: LoanMonth,
        isMain: IsMain
      });
    }
  };

  private loadLoanProductConditions = () => {
    const { getLoanProductConditionsRequest } = this.props;
    const { IsMain, SumOpen, CurrencyId, LoanMonth, ProductId } = this.state;
    if (SumOpen && LoanMonth && ProductId) {
      getLoanProductConditionsRequest({
        productId: ProductId,
        sum: SumOpen,
        currency: CurrencyId,
        period: LoanMonth,
        isMain: IsMain
      });
    }
  };

  private handleChangeAutoSelect = (partner: any) => {
    this.setState({
      ...partner,
      IdPartner: partner.Number
    });
  };

  private handleChangeDate = (fieldName: string) => (date: Date, event: any) => {
    const { formErrors } = this.state;
    if (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value)) {
      const value = date ? convertToUtc(date).toISOString() : null;
      this.setState({
        [fieldName]: value === NONE_VALUE || value === "" ? null : value,
        formErrors: {
          ...formErrors,
          [fieldName]: '',
        }
      });
    }
  };


}
