import React, { Fragment } from 'react';

import { PartnerContext } from '../../../pages/Product';
import ConditionBlockFirst from './ConditionBlockFirst';
import ConditionBlockSecond from './ConditionBlockSecond';
import ConditionBlockThird from "./ConditionBlockThird";
import { CURRENCY_RUB } from "../../../../../constants/currencyCodes";

export default class ConditionsBaseTab extends React.PureComponent {
  public currencyId = CURRENCY_RUB;
  public render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return (
      <PartnerContext.Consumer>
        {
          ({ editing, productData, handleChange = () => void 0, getMaxOrderId }) =>
            productData && (
              <Fragment>
                <ConditionBlockFirst editing={editing} productData={productData} handleChange={handleChange} getMaxOrderId={getMaxOrderId} currencyId={this.currencyId} />
                <ConditionBlockSecond editing={editing} productData={productData} handleChange={handleChange} getMaxOrderId={getMaxOrderId} currencyId={this.currencyId} />
                <ConditionBlockThird editing={editing} productData={productData} handleChange={handleChange} getMaxOrderId={getMaxOrderId} currencyId={this.currencyId} />
              </Fragment>
            )
        }
      </PartnerContext.Consumer>
    );
  }
}
