import { all, takeEvery, call, put } from "redux-saga/effects";
import { ActionTypes, fetchUserSuccess, fetchUserError, getAccessSectionsSuccess, getAccessSectionsError } from "./actions";
import { UserData, SectionAccess } from "./types";
import ApiClient from "../../services/api";
import { putError } from "../global/actions";

function* fetchUserDataSaga() {
  try {
    const result: UserData = yield call(ApiClient.getUser);

    yield put(fetchUserSuccess(result));
  } catch (error) {
    yield put(fetchUserError(error));
  }
}

function* getAccessSections() {
  try {
    const result: SectionAccess[] = yield call(ApiClient.getAccessSections);

    yield put(getAccessSectionsSuccess(result));
  } catch (error) {
    yield put(putError(error));
    yield put(getAccessSectionsError(error));
  }
}

function* authSaga() {
  yield all([
    yield takeEvery(ActionTypes.AUTH_FETCH_USER_REQUEST, fetchUserDataSaga),
    yield takeEvery(ActionTypes.GET_ACCESS_SECTIONS_REQUEST, getAccessSections),
  ]);
}

export default authSaga;
