import React, { Component } from 'react';
import Helmet from "react-helmet";
import { RouteComponentProps } from "react-router";
import { toNumber } from "lodash";

import LoadingOverlay from "../../../../components/LoadingOverlay";
import SectionHeader from "../../../../components/SectionHeader";
import BatchOperationsForm from '../../components/BatchOperationsForm';
import { CurrencyId, CurrencyRequests } from "../../../../store/batchOperations";

interface BatchOperationsRouterProps {
  currency: string;
}

export interface BatchOperationsProps extends RouteComponentProps<BatchOperationsRouterProps> {
  paymentRequests?: CurrencyRequests,
  getOperationsByCurrencyRequest: (currencyId: CurrencyId) => void,
}

export default class BatchOperations extends Component<BatchOperationsProps> {
  public componentDidMount() {

    const { getOperationsByCurrencyRequest } = this.props;
    const currencyId = toNumber(this.props.match.params.currency);
    getOperationsByCurrencyRequest(currencyId as CurrencyId);
  }

  public render() {
    const currencyId = toNumber(this.props.match.params.currency);
    return (
      <div>
        <Helmet defer={false}>
          <title>{this.getHeading()}</title>
        </Helmet>
        <SectionHeader
          heading={this.getHeading()}
        />
        <LoadingOverlay loading={false}>
          <div className="row">
            <div className="col-md-10">
              <BatchOperationsForm
                currencyId={currencyId as CurrencyId}
                goToBack={this.goToBack}
                paymentRequests={this.props.paymentRequests}
              />
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }

  private getHeading = () => {
    return 'Пакетное проведение операций';
  };

  private goToBack = () => {
    this.props.history.replace('/PaymentRequest');
  };
}
