import { all, takeEvery, call, put } from "redux-saga/effects";
import { ActionTypes, fetchLabelsSuccess } from "./actions";
import { AnyAction } from "redux";
import ApiClient from "../../services/api";
import { serializeLabels } from "./utils";
import { ServerResponse } from "./types";

function* fetchLabels(action: AnyAction) {
  const section = action.payload;
  const billType = action.meta;

  try {
    const result: ServerResponse = yield call(ApiClient.getLabels, section, billType);

    yield put(fetchLabelsSuccess(serializeLabels(result), section));
  } catch (error) {
    yield put({ type: ActionTypes.FETCH_LABELS_ERROR, payload: error });
  }
}

function* wathcFetchLabels() {
  yield takeEvery(ActionTypes.FETCH_LABELS_REQUESTS, fetchLabels);
}

function* labelsSaga() {
  yield all([wathcFetchLabels()]);
}

export default labelsSaga;
