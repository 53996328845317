import { LabelSection } from "./types";

export const COMMON: LabelSection = new Map([
  ["Date", "Дата"],
  ["BillCode", "Номер счета"],
  ["CurrencyId", "Валюта"],
  ["Type", "Тип"],
  ["Status", "Статус"]
]);

export const PAYMENT_REQUESTS_LIST_COLUMNS: LabelSection = new Map([
  ["Id", "№"],
  ["PartnerName", "Пайщик"],
  ["Total", "Сумма"],
  ["Operator", "ФИО оператора"],
  ["IsRequisites", "Тип заявки"]
]);

export const PAYMENT_REQUESTS_STATUS: LabelSection = new Map([
  [0, "Заявка"],
  [1, "Исполнено"],
  [2, "Отклонено"],
  [3, "Отменено"]
]);

export const PAYMENT_REQUEST_BILL_TYPE: LabelSection = new Map([
  [0, "Зарплатный"],
  [1, "Текущий"]
]);

export const PAYMENT_REQUESTS_TYPE: LabelSection = new Map([
  [0, 'true'],
  [1, 'false'],
]);

export const GENDER = new Map([[1, "Мужской"], [2, "Женский"]]);
export const IS_ACTIVE = new Map([['false', "Неактивный"], ['true', "Активный"]]);
export const OPERATION_TYPES = new Map([['false', "Расходная"], ['true', "Приходная"]]);
export const CURRENCY = new Map([[1, "RUB"], [2, "USD"], [3, "EUR"]]);
export const ACTIVITY = new Map([[1, "Создание"], [2, "Редактирование"], [3, "Удаление"]]);
export const YESNO = new Map([[1, "Да"], [0, "Нет"]]);
export const YESNO_BOOLEAN = new Map([['true', "Да"], ['false', "Нет"]]);
export const EVENT_NAMES = new Map([
  ['Сберегательная программа(ежемесячно)', "Сберегательная программа (ежемесячно)"],
  ['Сберегательная программа(в конце срока)', "Сберегательная программа (в конце срока)"],
  ['Привлечение средств(ежемесячно)', "Привлечение средств (ежемесячно)"],
  ['Привлечение средств(в конце срока)', "Привлечение средств (в конце срока)"],
  ['Зарплатная программа(ежемесячно)', "Зарплатная программа (ежемесячно)"],
  ['Займы', "Займы"],
]);

export const REPORT_FIELD_LABELS = new Map([
  ["PartnerFullName", "Пайщик"],
  ["BillCode", "Номер счета"],
  ["Currency", "Валюта"],
  ["CurrentBalance", "Баланс"],
  ["OpeningDate", "Дата размещения"],
]);

export const CLIENTS_TYPE: LabelSection = new Map([
  [1, 'Пайщики'],
  [2, 'ЗП проект'],
]);
