import * as React from "react";
import Helmet from 'react-helmet';
import { RouteComponentProps } from "react-router"
import DatePicker from "react-datepicker"
import moment from 'moment';
import { noop, get, isEmpty, flatten } from 'lodash';
import { connect } from "react-redux";
import { Dispatch } from "redux";

import ListHeader  from "../components/ListHeader";

import FilterConfigurator from "../../../../components/BillTables/FilterConfigurator";
import { showErrorToast } from "../../../../components/ErrorToast";
import Pagination from "../../../../components/Pagination/Pagination";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import { requestDataToQuery } from "../../../../utils";
import TableList from "../../../../components/BillTables/TableList";
import styled from "../../../../styled/styled-components";

import ApiClient from "../../../../services/api";

import {
  DEFAULT_FILTER_FIELDS,
  DEFAULT_SORT_FIELD_NAME,
  DEFAULT_SORT_DIRECTION,
  AUTOCOMPLETE_FILTER_FIELDS,
  REPORT_MONTHLY_FIELD_LABELS,
  SUB_TABLE_FIELDS
} from "../constants";
import {
  BillCurrentListItem,
  FilterFieldName,
  FilterRequest,
  OperatorType,
  FilterItemData
} from "../types";
import Button from "../../../../components/Button/Button";
import { AllFuncPaySummary } from "../../../../store/paymentRequests";
import { fetchLabelsRequest } from "../../../../store/labels/actions";

export interface ReportListProps extends RouteComponentProps {
  fetchLabelsRequest: (section: string, billType?: string) => void;
}

export interface ReportListState {
  reports: BillCurrentListItem[];
  reportsPeriod: AllFuncPaySummary[];
  filters: FilterRequest;
  cachedFilters: FilterRequest;
  visibleFields: Map<FilterFieldName<BillCurrentListItem>, boolean>;
  filtersShown: boolean;
  fieldFilters: Map<FilterFieldName<BillCurrentListItem>, FilterItemData>;
  autocompleteItems: {
    [fieldName in FilterFieldName<BillCurrentListItem>]?: string[]
  };
  loading: boolean;
  dateStart?: Date;
  dateEnd?: Date;
}

const DateControl = styled.div`
`;

const DEFAULT_FILTERS = {
  CurrentPage: 1,
  FieldInfos: [
    {
      FieldName: "PartnerNumber" as FilterFieldName<BillCurrentListItem>,
      Method: 1,
      Value: null,
      ValueType: 1
    },
    {
      FieldName: "PartnerName" as FilterFieldName<BillCurrentListItem>,
      Value: null,
      Method: 3,
      ValueType: 1
    },
    {
      FieldName: "BillCode" as FilterFieldName<BillCurrentListItem>,
      Value: null,
      Method: 10,
      ValueType: 1
    },
    {
      FieldName: "CurrentBalance" as FilterFieldName<BillCurrentListItem>,
      Value: null,
      Method: 4,
      ValueType: 7
    },
    {
      FieldName: "CurrencyId" as FilterFieldName<BillCurrentListItem>,
      Value: null,
      Method: 0,
      ValueType: 5
    },
    {
      FieldName: "OpeningDate" as FilterFieldName<BillCurrentListItem>,
      Value: null,
      Method: 4,
      ValueType: 4
    },
    {
      FieldName: "IsActive" as FilterFieldName<
        BillCurrentListItem
      >,
      Value: null,
      Method: 0,
      ValueType: 3
    }
  ],
  IsPaging: false,
  Operator: 1 as OperatorType,
  PageSize: 25,
  SortDirection: DEFAULT_SORT_DIRECTION,
  SortFieldName: DEFAULT_SORT_FIELD_NAME,
  TotalPages: 1
};

const getDefaultVisibleFields = (
  map: Map<FilterFieldName<BillCurrentListItem>, FilterItemData>
) => {
  const result = new Map<FilterFieldName<BillCurrentListItem>, boolean>();

  map.forEach(item => {
    result.set(item.FieldName, true);
  });

  return result;
};

class RaisingReportMonthly extends React.Component<
  ReportListProps,
  ReportListState
> {
  public state: ReportListState = {
    reports: [],
    reportsPeriod: [],
    visibleFields: getDefaultVisibleFields(DEFAULT_FILTER_FIELDS),
    filtersShown: false,
    filters: DEFAULT_FILTERS,
    cachedFilters: DEFAULT_FILTERS,
    fieldFilters: DEFAULT_FILTER_FIELDS,
    autocompleteItems: {},
    loading: false,
    dateStart: (new Date()),
    dateEnd: (new Date()),
  };

  private listFieldLabels = REPORT_MONTHLY_FIELD_LABELS;
  // @ts-ignore
  private reportsTableElement = React.createRef<TableList<BillCurrentListItem>>();

  public componentDidMount() {
    this.getData();

    // tslint:disable-next-line:no-shadowed-variable
    const { fetchLabelsRequest } = this.props;
    fetchLabelsRequest('FUNC_PAY', '7');
  }

  public render() {
    return (
      <LoadingOverlay loading={this.state.loading}>
        <Helmet defer={false}>
          <title>Начисления по инвестиционным займам помесячно</title>
        </Helmet>
        <ListHeader />
        <div className="mt-3">
        Отчет за период:
          <DateControl>
            С {' '} <DatePicker
              locale="ru"
              selected={this.state.dateStart}
              onChange={this.handleChangeDate('dateStart')}
              selectsStart
              startDate={this.state.dateStart}
              endDate={this.state.dateEnd}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
            {' '} до {' '}
            <DatePicker
              locale="ru"
              selected={this.state.dateEnd}
              onChange={this.handleChangeDate('dateEnd')}
              selectsEnd
              startDate={this.state.dateStart}
              endDate={this.state.dateEnd}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            /> {' '}
            <Button type="success" onClick={this.getDataClick}>Составить отчет</Button>
          </DateControl>
        </div>
        <FilterConfigurator
          labels={this.listFieldLabels}
          fields={this.state.visibleFields}
          handleChangeColumns={this.handleChangeColumns}
          filtersShown={this.state.filtersShown}
          toggleFiltersVisible={this.toggleFiltersVisible}
          handleChangeOperator={this.handleChangeOperator}
          operatorId={this.state.filters.Operator}
          resetAllFilters={this.resetAllFilters}
          acceptFilter={this.acceptFilter}
        />
        <TableList
          data={this.state.reports}
          visibleFields={this.state.visibleFields}
          labelsSection="DEPOSIT_REPORT_MONTHLY_FIELD_LABELS"
          // @ts-ignore
          createLink={this.createLink}
          sortProps={{
            sortFieldName: this.state.filters.SortFieldName,
            sortDirection: this.state.filters.SortDirection,
            onChangeSort: this.handleChangeSort
          }}
          filtersProps={{
            fields: [...this.state.fieldFilters.values()],
            onChangeFilter: this.handleChangeFilter,
            acceptFilter: this.acceptFilter,
            resetFilter: this.resetFilter,
            filtersShown: this.state.filtersShown,
            autocompleteItems: this.state.autocompleteItems
          }}
          ref={this.reportsTableElement}
          notUseActive
        />
        {!isEmpty(this.state.reportsPeriod) && <div className="mt-4">
          <TableList
            data={this.state.reportsPeriod}
            visibleFields={getDefaultVisibleFields(SUB_TABLE_FIELDS)}
            labelsSection="DEPOSIT_REPORT_MONTHLY_FIELD_LABELS"
            sortProps={{
              sortFieldName: this.state.filters.SortFieldName,
              sortDirection: this.state.filters.SortDirection,
              onChangeSort: noop
            }}
            notUseActive
            filtersProps={{
              fields: [...SUB_TABLE_FIELDS.values()],
              onChangeFilter: noop,
              acceptFilter: noop,
              resetFilter: noop,
              filtersShown: this.state.filtersShown,
              autocompleteItems: this.state.autocompleteItems
            }}
            withoutFilters
          />
        </div>}
        {this.state.filters.TotalPages > 1 && this.state.filters.IsPaging && (
          <Pagination
            size={this.state.filters.PageSize}
            count={this.state.filters.TotalPages * this.state.filters.PageSize}
            page={this.state.filters.CurrentPage}
            callback={this.handleChangePage}
          />
        )}
      </LoadingOverlay>
    );
  }

  private getDataClick = () => {
    this.getData();
  };

  private getData = async (
    filter: Partial<FilterRequest> = {},
    forceGoToDetails?: boolean
  ) => {
    const queryString = requestDataToQuery({
      SortFieldName: this.state.filters.SortFieldName,
      SortDirection: this.state.filters.SortDirection,
      CurrentPage: this.state.filters.CurrentPage,
      PageSize: this.state.filters.PageSize,
      IsPaging: this.state.filters.IsPaging,
      ...filter
    }, 'filterRequest');

    this.setState({ loading: true });
    try {
      const { dateStart, dateEnd } = this.state;
      const result = await ApiClient.getMonthPaymentAggregationRaisingReport(queryString, moment(dateStart).startOf('month').toISOString(), moment(dateEnd).endOf('month').toISOString());

      if (forceGoToDetails && result.DataSource.length === 1) {
        this.setState({ loading: false });
      }

      this.setState({
        reports: result.DataSource,
        reportsPeriod: flatten(get(result, 'AllFuncPaySummary', []).map((item: any) => item.SummariesByCurrency)),
        filters: result.FilterRequest,
        cachedFilters: result.FilterRequest,
        fieldFilters: this.extractFieldFilters(result.FilterRequest)
      });
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private handleChangeDate = (fieldName: string) => (date: Date) => {
    // @ts-ignore
    this.setState({ [fieldName]: date });
  };

  private handleChangeColumns = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: columnName, checked } = event.target;

    this.setState(state => {
      const filterFieldName = columnName as FilterFieldName<BillCurrentListItem>;
      const newVisibleFields = new Map(state.visibleFields);

      newVisibleFields.set(filterFieldName, checked);

      return {
        visibleFields: newVisibleFields
      };
    });
  };

  private toggleFiltersVisible = () => {
    this.setState(state => ({ filtersShown: !state.filtersShown }));
  };

  private handleChangeOperator = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(event.target.value, 10);

    this.setState(state => ({
      filters: {
        ...state.filters,
        Operator: value as OperatorType
      }
    }));
  };

  private handleChangeFilter = (
    filterItemData: FilterItemData,
    forceFiltering?: boolean
  ) => {
    forceFiltering = forceFiltering || filterItemData.ValueType === 4 || filterItemData.ValueType === 5
    let forceGoToDetails = false;
    if (forceFiltering && filterItemData.FieldName === "PartnerFullName") {
      forceGoToDetails = true;
    }

    this.setState(
      state => {
        const newFilters = new Map(state.fieldFilters);
        const currentFilterItem = newFilters.get(filterItemData.FieldName);

        if (currentFilterItem) {
          newFilters.set(filterItemData.FieldName, filterItemData);
        }

        return {
          fieldFilters: newFilters,
          filters: {
            ...state.filters,
            FieldInfos: this.getFieldInfos(newFilters)
          }
        };
      },
      () => {
        this.proccessAutocomplete(filterItemData);
        if (forceFiltering) {
          this.acceptFilter(forceGoToDetails);
        }
      }
    );
  };

  private acceptFilter = (forceGoToDetails?: boolean) => {
    const { FieldInfos, Operator } = this.state.filters;

    this.getData(
      {
        Operator,
        FieldInfos: FieldInfos.filter(item => item && item.Value !== null),
        CurrentPage: 1
      },
      forceGoToDetails
    );
  };

  private resetAllFilters = () => {
    this.getData();
  };

  private extractFieldFilters = (filterRequest: FilterRequest) => {
    const fieldsData = new Map(DEFAULT_FILTER_FIELDS);

    filterRequest.FieldInfos.forEach(item => {
      if (fieldsData.get(item.FieldName)) {
        fieldsData.set(item.FieldName, item);
      }
    });

    return fieldsData;
  };

  private getFieldInfos = (
    fieldFilters: Map<FilterFieldName<BillCurrentListItem>, FilterItemData>
  ) => {
    return [...fieldFilters.values()];
  };

  private handleChangeSort = (fieldNamme: FilterFieldName<BillCurrentListItem>) => {
    this.setState(
      state => {
        const currentSortFieldName = state.filters.SortFieldName;
        const curretSortDirection = state.filters.SortDirection;

        const newSortDirection =
          currentSortFieldName !== fieldNamme
            ? 1
            : curretSortDirection === 1
            ? 2
            : 1;

        return {
          filters: {
            ...state.filters,
            SortFieldName: fieldNamme,
            SortDirection: newSortDirection
          }
        };
      },
      () => {
        this.getData({
          Operator: this.state.filters.Operator,
          FieldInfos: this.state.filters.FieldInfos.filter(
            item => item && item.Value !== null
          )
        });
      }
    );
  };

  private proccessAutocomplete = async (filterItemData: FilterItemData) => {
    const fieldName = filterItemData.FieldName;
    const value = filterItemData.Value;

    if (AUTOCOMPLETE_FILTER_FIELDS.indexOf(fieldName) === -1) {
      return;
    }

    if (!value || value.length < 2) {
      this.setState({
        autocompleteItems: {
          [fieldName]: []
        }
      });

      return;
    }

    const {
      FieldInfos,
      Operator,
      SortFieldName,
      // tslint:disable-next-line:no-shadowed-variable
      SortDirection
    } = this.state.filters;

    const queryString = requestDataToQuery({
      SortFieldName,
      SortDirection,
      Operator,
      FieldInfos: FieldInfos.filter(item => item && item.Value !== null),
      propertyName: fieldName
    });

    try {
      this.setState({
        autocompleteItems: {
          [fieldName]: await ApiClient.getAutocompleteForClients(queryString)
        }
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  private createLink = (item: BillCurrentListItem, key: string) => {
    if (key === 'PartnerName') {
      return {
        link: this.getLinkToPartner(item),
        fieldName: "PartnerName" as keyof BillCurrentListItem
      };
    }
    return {
      link: this.getLinkToNumberCode(item),
      fieldName: "BillCode" as keyof BillCurrentListItem
    };
  };

  private resetFilter = (fieldName: keyof BillCurrentListItem) => {
    const filterItemData = DEFAULT_FILTERS.FieldInfos.find(
      ({ FieldName }) => FieldName === fieldName
    );

    if (filterItemData) {
      this.handleChangeFilter(filterItemData, true);
    }
  };

  private handleChangePage = (page: number) => {
    this.getData({
      Operator: this.state.filters.Operator,
      FieldInfos: this.state.filters.FieldInfos.filter(
        item => item && item.Value !== null
      ),
      CurrentPage: page
    });
  };

  private getLinkToPartner = (item: BillCurrentListItem) => {
    return `/client/Info/${item.PartnerFullName}#bills`;
  };

  private getLinkToNumberCode = (item: BillCurrentListItem) => {
    return `/Bill/Details/${item.BillCode}`;
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchLabelsRequest: (section: string, billType?: string) => dispatch(fetchLabelsRequest(section, billType)),
});

export default connect(undefined, mapDispatchToProps)(RaisingReportMonthly)
