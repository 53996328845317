import * as React from "react";
import { RouteComponentProps } from "react-router";
import moment from "moment";

import { Container, Header } from "../components/Views";
import Chart from "../../../components/Chart/Chart";
import CurrencyFilter from "../../../components/CurrencyFilter/CurrencyFilter";
import PeriodFilter from "../../../components/PeriodFilter/PeriodFilter";
import CompareFilter from "../../../components/CompareFilter/CompareFilter";
import {
  getTickInterval,
  handleChangeCompare,
  handleChangeCurrency,
  handleChangePeriod,
  getCurrencyCode,
  getPeriodType
} from "../../../helpers/chartHelpers";
import { AnalyticsState, DepositCountLine } from "../types";
import { getDefaultState, getLineChartData, getPeriodDates } from "../utils";
import SumInfo, { SumInfoRow } from "../components/SumInfo";
import AnalyticsApiClient from "../services/api";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { showErrorToast } from "../../../components/ErrorToast";

const nameFormatter = (line: DepositCountLine, firstDate?: string, lastDate?: string) =>
  `Количество вкладов (${line.Currency}): ${firstDate} - ${lastDate}`;

const getPeriodSums = (data: DepositCountLine[]) => {
  return data.reduce((acc: SumInfoRow[], line: DepositCountLine) => {
    const { firstDate, lastDate } = getPeriodDates(line);
    const title = `Количество вкладов за период ${firstDate} - ${lastDate} (${line.Currency})`;
    const existing = acc.find((item: SumInfoRow) => item.title === title);
    if (existing) {
      existing.sum = existing.sum + line.Count;
    } else {
      acc.push({ title, sum: line.Count });
    }
    return acc;
  }, []);
};

export interface DepositsCountProps extends RouteComponentProps {
}

export interface DepositsCountState extends AnalyticsState {
  data: DepositCountLine[],
}

export default class DepositsCount extends React.Component<DepositsCountProps, DepositsCountState> {
  private readonly handleChangeCompare: any;
  private readonly handleChangePeriod: any;
  private readonly handleChangeCurrency: any;

  constructor(props: Readonly<DepositsCountProps>) {
    super(props);

    this.state = getDefaultState();

    this.handleChangeCompare = handleChangeCompare.bind(this);
    this.handleChangeCurrency = handleChangeCurrency.bind(this);
    this.handleChangePeriod = handleChangePeriod.bind(this);
  }

  public componentDidMount() {
    this.loadData();
  }

  public render() {
    const {
      filters: {
        currency, period, dateFrom, dateTo, compare
      },
      data = [],
      loading
    } = this.state;

    return (
      <Container>
        <LoadingOverlay loading={loading === "pending"}>
          <Header>
            <CurrencyFilter active={currency} onChange={this.handleChangeCurrency} />
            <PeriodFilter
              activePeriod={period}
              dateFrom={dateFrom}
              dateTo={dateTo}
              onChange={this.handleChangePeriod}
            />
            {period !== "year" && period !== "currentYear" ? null : (
              <CompareFilter compare={compare} onChange={this.handleChangeCompare} />
            )}
          </Header>
          <Chart
            series={getLineChartData(data, nameFormatter)}
            minTickInterval={getTickInterval(period)}
            title="Количество вкладов по валютам"
            xLabel="Период"
            yLabel="Количество"
            xDateFormat={period !== "month" && period !== "currentMonth" ? "%B" : "%d %B"}
          />
          <SumInfo title="Общее количество вкладов за период" data={getPeriodSums(data)} />
        </LoadingOverlay>
      </Container>
    );
  }

  private async loadData() {
    try {
      const {
        currency, period, dateFrom, dateTo, compare
      } = this.state.filters;

      const params = {
        currency: getCurrencyCode(currency),
        periodType: getPeriodType(period),
        dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
        dateTo: moment(dateTo).format("YYYY-MM-DD"),
        compare: period !== "year" && period !== "currentYear" ? undefined : compare
      };
      this.setState({ loading: "pending" });
      const data = await AnalyticsApiClient.getDepositsCount(params);

      this.setState({ data });
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: "ok" });
    }
  }
}
