import { all, takeEvery, call, put } from "redux-saga/effects";
import { AnyAction } from "redux";

import {
  ActionTypes,
  fetchBillDetailsSuccess,
  fetchBillDetailsError,
  fetchPlannedScheduleSuccess,
  fetchPlannedScheduleError,
  fetchLoanEarlyCloseSuccess,
  fetchLoanEarlyCloseError,
  fetchPayCalendarSuccess,
  fetchPayCalendarError,
  toggleSalaryBillSuccess,
  toggleSalaryBillError
} from "./actions";
import ApiClient from "../../services/api";
import { BillDepositPlanCalendar, BillDetails, BillLoanPlanCalendar, LoanEarlyClose, PayCalendar } from "./types";

function* fetchBillDetail(action: AnyAction) {
  try {
    const data = {
      code: action.payload,
    };

    const result: BillDetails = yield call(ApiClient.getBillDetails, data);

    yield put(fetchBillDetailsSuccess(result));
  } catch (error) {
    yield put(fetchBillDetailsError(error));
  }
}

function* fetchPlannedSchedule(action: AnyAction) {
  try {
    const data = {
      id: action.payload.BillId,
    };
    const calendar = action.payload.calendar;

    if (calendar === 'loanCalendar') {
      const result: BillLoanPlanCalendar = yield call(ApiClient.getLoanCalendar, data);
      yield put(fetchPlannedScheduleSuccess({ data: result, calendar }));
    } else if (calendar === 'raisingCalendar') {
      const result: BillLoanPlanCalendar = yield call(ApiClient.getRaisingCalendar, data);
      yield put(fetchPlannedScheduleSuccess({ data: result, calendar }));
    } else if (calendar === 'depositCalendar') {
      const result: BillDepositPlanCalendar = yield call(ApiClient.getDepositCalendar, data);
      yield put(fetchPlannedScheduleSuccess({ data: result, calendar }));
    } else if (calendar === 'pensionCalendar') {
      const result: BillDepositPlanCalendar = yield call(ApiClient.getPensionCalendar, data);
      yield put(fetchPlannedScheduleSuccess({ data: result, calendar }));
    }
  } catch (error) {
    yield put(fetchPlannedScheduleError({ data: error, calendar: action.payload.calendar }));
  }
}

function* fetchLoanEarlyClose(action: AnyAction) {
  try {

    const result: LoanEarlyClose = yield call(ApiClient.getLoanEarlyClose, action.payload);

    yield put(fetchLoanEarlyCloseSuccess(result));
  } catch (error) {
    yield put(fetchLoanEarlyCloseError(error));
  }
}

function* fetchPayCalendar(action: AnyAction) {
  try {
    const data = {
      id: action.payload,
    };

    const result: PayCalendar = yield call(ApiClient.getPayCalendar, data);

    yield put(fetchPayCalendarSuccess(result));
  } catch (error) {
    yield put(fetchPayCalendarError(error));
  }
}

function* toggleBillSalary(action: AnyAction) {
  try {

    yield call(ApiClient.confirmToggleSalary, action.payload.billId);

    const data = {
      code: action.payload.billCode,
    };
    const result: BillDetails = yield call(ApiClient.getBillDetails, data);

    yield put(fetchBillDetailsSuccess(result));
    yield put(toggleSalaryBillSuccess());
  } catch (error) {
    yield put(toggleSalaryBillError(error));
  }
}


function* billDetailSaga() {
  yield all([
    yield takeEvery(ActionTypes.FETCH_DETAILS_REQUEST, fetchBillDetail),
    yield takeEvery(ActionTypes.FETCH_PLANNED_SCHEDULE_REQUEST, fetchPlannedSchedule),
    yield takeEvery(ActionTypes.FETCH_LOAN_EARLY_CLOSE_REQUEST, fetchLoanEarlyClose),
    yield takeEvery(ActionTypes.FETCH_PAY_CALENDAR_REQUEST, fetchPayCalendar),
    yield takeEvery(ActionTypes.TOGGLE_BILL_SALARY_REQUEST, toggleBillSalary),
  ]);
}

export default billDetailSaga;
