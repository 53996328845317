import moment from "moment";
import { RELATION_LABELS } from "../constants";
import { IContainsInfoForPartnerNumber } from '../types';

export const renderValue = (
  fieldName: string,
  data: { [key: string]: any }
) => {
  const value = data[fieldName];

  switch (fieldName) {
    case "PartnerId":
      if (value && data.NumberRegion && data.NumberYear) {
        return `${data.NumberRegion}-${data.NumberYear}-${value}`;
      }

    case "RegDate":
    case "BirthDate":
    case "DateOfIssue":
      if (value) {
        return moment(value).format("DD.MM.YYYY");
      }
    case "PowerOfAttorneyStartDate":
      if (value) {
        return moment(value).format("DD.MM.YYYY");
      }
    case "PowerOfAttorneyEndDate":
      if (value) {
        return moment(value).format("DD.MM.YYYY");
      }

    case "IsActive":
      if (typeof value === "boolean") {
        return Number(value);
      }

    case "RelationTypeId":
      if (value) {
        return RELATION_LABELS.get(value);
      }

    default:
      return value || "";
  }
};

export const getTableCellAlign = (fieldName: string) => {
  switch (fieldName) {
    case "PartnerNumber":
    case "Birthday":
    case "PassportNumber":
    case "InternationalPassportNumber":
    case "Gender":
    case "IsActive":
      return "center";

    default:
      return "left";
  }
};

export const getPartnerNumberFromUserdata = (userData: IContainsInfoForPartnerNumber) => {
  if (userData) {
    return `${userData.NumberRegion}-${userData.NumberYear}-${
      userData.PartnerId
    }`;
  }
};
