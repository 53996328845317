import { SmsInterface, RecipientsGroupType } from "../types";

export const SMS_LIST_FIELDS: Array<keyof SmsInterface> = [
  "Title",
  "Text",
  "RecipientsGroup",
  "ReleaseTime",
  "IsSended"
];

export const SMS_FIELDS_LABELS: Partial<Record<keyof SmsInterface, string>> = {
  Title: "Название",
  Text: "Текст рассылки",
  RecipientsGroup: "Группа получателей",
  ReleaseTime: "Время отправки",
  IsSended: "Статус"
};

export const STATUS_LABELS = new Map([
  ["Sended", "Рассылка отправлена"],
  ["Schedule", "Ожидает отправки"],
  ["Canceled", "Рассылка отменена"]
]);

export const RECIPIENTS_GROUPS = new Map<RecipientsGroupType, string>([
  [1, "Все"],
  [2, "Только мужчины"],
  [3, "Только женщины"]
]);

export const REQUIRED_CREATION_FIELDS: Array<keyof SmsInterface> = [
  "Title",
  "Text"
];

export const MESSAGES = {
  formErrors: "Исправьте ошибки в форме",
  createdSuccess: "Рассылка создана",
  updateSuccess: "Рассылка обновлена",
  deleteSuccess: "Рассылка отменена",
  deleteFail: "Не удалось отменить рассылку"
};

export const ALIGN_CELL_BY_FIELD = new Map<
  keyof SmsInterface,
  React.TdHTMLAttributes<HTMLTableCellElement>["align"]
>([["IsSended", "center"], ["ReleaseTime", "center"]]);
