import * as React from 'react';
import Link from "../../../../components/Link";
import {
  TableBody,
  TableCell,
  TableHead,
  TableCellHead,
  TableRow,
  Table
} from "../../../../components/Table";

import { Data } from "./ListHeader";
import { CURRENCY_IDS } from "../../../../constants";

interface LoanTableProps {
  data: Data[],
}

export default class LoanTable extends React.PureComponent<LoanTableProps>{
  public render() {
    const { data } = this.props;
    return (
      <Table>
        <TableHead>
          <TableCellHead>Номер счета</TableCellHead>
          <TableCellHead>ФИО</TableCellHead>
          <TableCellHead>Сумма</TableCellHead>
          <TableCellHead>Валюта</TableCellHead>
        </TableHead>
        <TableBody>
          {data.map(item => (
            <TableRow key={item.Id}>
              <TableCell><Link href={`/Bill/Details/${item.BillCode}`}>{item.BillCode}</Link></TableCell>
              <TableCell>{item.FullName}</TableCell>
              <TableCell>{Number.parseFloat(item.Sum.toString()).toLocaleString('ru', { minimumFractionDigits: 2 })}</TableCell>
              <TableCell>{CURRENCY_IDS.get(Number(item.CurrencyId))}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}
