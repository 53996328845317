import React, { Component } from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { Table, THead, TBody, TFoot, Th, Tr, Td } from '../../../../styled/Table';
import AnalyticsApiClient from '../../../../modules/Analytics/services/api';

const Container = styled(Flex).attrs({ column: true, full: true })``;
const Header = styled(Flex).attrs({ column: true })`
  padding-bottom: 20px;
`;
const Content = styled(Flex).attrs({ column: true })`
  max-width: 900px;
`;

const User = ({ FullName, Number }) => (
  <Tr>
    <Td>{Number}</Td>
    <Td>{FullName}</Td>
    <Td><a href={`https://admin.m-fond.com/client/Info/${Number}`}>Перейти в профиль</a></Td>
  </Tr>
);

export default class InactiveAccounting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const users = await AnalyticsApiClient.getSalaryInactiveAccounting();
    this.setState({ users });
  }

  render() {
    const { users } = this.state;
    return (
      <Container>
        <Header>
          <h3>Список пользователей, по которым не было движений средств последние 12 месяцев</h3>
        </Header>
        <Content>
          <Table>
            <THead>
              <Tr>
                <Th>Номер</Th>
                <Th>Имя</Th>
                <Th>Ссылка на профиль</Th>
              </Tr>
            </THead>
            <TBody>
              {users.map(user => <User key={user.Number} {...user} />)}
            </TBody>
            <TFoot>
              <Tr>
                <Td colSpan={2}>
                  Общее количество пайщиков:
                </Td>
                <Td>{users.length}</Td>
              </Tr>
            </TFoot>
          </Table>
        </Content>
      </Container>
    );
  }
}
