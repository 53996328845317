import { BillCurrentListItem, FilterFieldName, FilterItemData, SortDirection } from "../types";

export const AUTOCOMPLETE_FILTER_FIELDS = [
  "PartnerNumber"
];

export const DEPOSIT_ANALITICS_FIELD_LABELS = new Map<keyof BillCurrentListItem, string>([
  ["PartnerFullName", "Пайщик"],
  ["ContractNumber", "Договор №"],
  ["Currency", "Валюта"],
  ["OpeningSum", "Сумма размещения"],
  ["CurrentBalance", "Баланс счета"],
  ["IsGuarantee", "Поручительство"],
  ["OpeningDate", "Дата размещения"],
  ["ClosingDate", "Дата окончания"],
]);

export const DEFAULT_FILTER_FIELDS = new Map<
  FilterFieldName<BillCurrentListItem>,
  FilterItemData
  >([
  [
    "PartnerFullName",
    {
      FieldName: "PartnerFullName",
      Method: 1,
      Value: null,
      ValueType: 1
    }
  ],
  [
    "ContractNumber",
    {
      FieldName: "ContractNumber",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "Currency",
    {
      FieldName: "Currency",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  [
    "OpeningSum",
    {
      FieldName: "OpeningSum",
      Value: null,
      Method: 4,
      ValueType: 7
    }
  ],
  [
    "CurrentBalance",
    {
      FieldName: "CurrentBalance",
      Value: null,
      Method: 4,
      ValueType: 7
    }
  ],
  [
    "IsGuarantee",
    {
      FieldName: "IsGuarantee",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  [
    "OpeningDate",
    {
      FieldName: "OpeningDate",
      Value: null,
      Method: 4,
      ValueType: 4
    }
  ],
  [
    "ClosingDate",
    {
      FieldName: "ClosingDate",
      Value: null,
      Method: 4,
      ValueType: 4
    }
  ]
]);

export const DEFAULT_SORT_DIRECTION: SortDirection = 2;

export const DEFAULT_SORT_FIELD_NAME: FilterFieldName<BillCurrentListItem> =
  "OpeningDate";
