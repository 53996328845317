import { Reducer } from "redux";
import { ActionTypes } from "./actions";
import { BillDetailsState } from "./types";

const initialState: BillDetailsState = {
  loading: false,
  data: undefined,
  error: null,
  loanCalendar: {
    loading: false,
    data: undefined,
    error: null,
  },
  raisingCalendar: {
    loading: false,
    data: undefined,
    error: null,
  },
  depositCalendar: {
    loading: false,
    data: undefined,
    error: null,
  },
  loanEarlyClose: {
    loading: false,
    data: undefined,
    error: null,
  },
  payCalendar: {
    loading: false,
    data: undefined,
    error: null,
  },
  pensionCalendar: {
    loading: false,
    data: undefined,
    error: null,
  }
};

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        data: undefined,
        error: null,
      };

    case ActionTypes.FETCH_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    case ActionTypes.FETCH_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        data: undefined,
        error: action.payload,
      };


    case ActionTypes.FETCH_PLANNED_SCHEDULE_REQUEST:
      return {
        ...state,
        [action.payload.calendar]: {
          loading: true,
          data: undefined,
          error: null,
        }
      };

    case ActionTypes.FETCH_PLANNED_SCHEDULE_SUCCESS:
      return {
        ...state,
        [action.payload.calendar]: {
          loading: false,
          data: action.payload.data,
          error: null,
        }
      };

    case ActionTypes.FETCH_PLANNED_SCHEDULE_ERROR:
      return {
        ...state,
        [action.payload.calendar]: {
          loading: false,
          data: undefined,
          error: action.payload.data,
        }
      };


    case ActionTypes.FETCH_LOAN_EARLY_CLOSE_REQUEST:
      return {
        ...state,
        loanEarlyClose: {
          loading: true,
          data: undefined,
          error: null,
        }
      };

    case ActionTypes.FETCH_LOAN_EARLY_CLOSE_SUCCESS:
      return {
        ...state,
        loanEarlyClose: {
          loading: false,
          data: action.payload,
          error: null,
        }
      };

    case ActionTypes.FETCH_LOAN_EARLY_CLOSE_ERROR:
      return {
        ...state,
        loanEarlyClose: {
          loading: false,
          data: undefined,
          error: action.payload,
        }
      };


    case ActionTypes.FETCH_PAY_CALENDAR_REQUEST:
      return {
        ...state,
        payCalendar: {
          loading: true,
          data: undefined,
          error: null,
        }
      };

    case ActionTypes.FETCH_PAY_CALENDAR_SUCCESS:
      return {
        ...state,
        payCalendar: {
          loading: false,
          data: action.payload,
          error: null,
        }
      };

    case ActionTypes.FETCH_PAY_CALENDAR_ERROR:
      return {
        ...state,
        payCalendar: {
          loading: false,
          data: undefined,
          error: action.payload,
        }
      };

    default:
      return state;
  }
};

export { reducer as billDetailsReducer };
