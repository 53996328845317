import * as React from "react";
import styled, { css } from "../../styled/styled-components";
import { Omit } from "../../helpers/typingHelpers";

type Size = "s" | "m" | "l";

export interface TypographyProps
  extends Omit<React.AllHTMLAttributes<HTMLElement>, "size"> {
  size?: Size;
  error?: boolean;
  warning?: boolean;
}

function getColor<T extends TypographyProps>(props: T) {
  let color = "inherit";

  if (props.warning) {
    color = "#d39e00";
  }

  if (props.error) {
    color = "#dc3545";
  }

  return color;
}

function getSize<T extends TypographyProps>(props: T) {
  switch (props.size) {
    case "s":
      return "0.8em";

    case "l":
      return "1.2em";

    case "m":
    default:
      return "1em";
  }
}

const commonStyles = css`
  color: ${getColor};
  font-size: ${getSize};
`;

export interface ParagraphProps extends TypographyProps {}

const ParagraphView: React.SFC<ParagraphProps> = ({
  size = "m",
  warning,
  error,
  ...props
}) => <p {...props} />;

export const Paragraph = styled(ParagraphView)`
  ${commonStyles};

  margin: 0 0 10px 0;
`;

export interface StrongProps extends TypographyProps {}

const StrongView: React.SFC<StrongProps> = ({
  size = "m",
  warning,
  error,
  ...props
}) => <strong {...props} />;

export const Strong = styled(StrongView)`
  ${commonStyles};

  font-weight: bolder;
`;

export const Heading = styled.h3`
  display: inline-block;
  margin: 0 0 7px;
  padding: 0;
  text-overflow: ellipsis;
  font-size: 1.24em;
`;
