import React, { PureComponent } from "react";
import { difference } from 'lodash';
import { toast } from "react-toastify";

import LoadingOverlay from "../../../components/LoadingOverlay";
import { AccessWrapper, ButtonAdd } from "../styled/styled";
import ApiClient from "../../../services/api";
import styled from "../../../styled/styled-components";


interface ActionNamesListProps {
  activeController?: string;
  selectedActions: string[];
  addNewAccessRule: (ActionName: string) => (e: any) => void;
}

interface ActionNamesListState {
  data: string[];
  loading: boolean;
}

const Row = styled.div`
  padding: 3px;
  display: flex;
  align-items: center;
`;

export default class ActionNamesList extends PureComponent<ActionNamesListProps, ActionNamesListState> {
  public state: ActionNamesListState = {
    data: [],
    loading: false,
  };

  public componentDidUpdate(prevProps: Readonly<ActionNamesListProps>, prevState: Readonly<ActionNamesListState>, snapshot?: any): void {
    if (prevProps.activeController !== this.props.activeController) {
      this.getActionNames();
    }
  }

  public render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    const { loading, data } = this.state;
    const { addNewAccessRule, selectedActions } = this.props;
    return (
      <LoadingOverlay loading={loading}>
        <AccessWrapper>
          {difference(data, selectedActions).map((item: string) => (
            <div key={item} className="row no-gutters">
              <div className="col-3 py-2 pl-2">
                <ButtonAdd onClick={addNewAccessRule(item)}>+</ButtonAdd>
              </div>
              <Row className="col-9">
                {item}
              </Row>
            </div>))}
        </AccessWrapper>
      </LoadingOverlay>
    );
  }

  private getActionNames = async () => {
    const { activeController } = this.props;
    if (!activeController) {

      return;
    }
    this.setState({
      loading: true,
    });

    try {
      const data = await ApiClient.getGroupActionNames(activeController);
      this.setState({
        data
      });

    } catch (e) {
      toast('Произошла ошибка', {
        type: toast.TYPE.ERROR,
      })
    } finally {
      this.setState({
        loading: false,
      })
    }
  }

}
