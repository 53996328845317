import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from "redux";

import { AppState } from "../../../../store";
import {
  searchPartnersRequest,
  getLoanProductsRequest,
  getLoanProductConditionsRequest,
  cleanLoanProducts
} from "../../../../store/forms";

import LoanForm from './LoanForm';

const mapStateToProps = (state: AppState) => ({
  search: state.forms.search,
  loanForm: state.forms.loanForm,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      searchPartnersRequest,
      getLoanProductsRequest,
      getLoanProductConditionsRequest,
      cleanLoanProducts
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoanForm);
