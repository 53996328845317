import * as React from "react";
import styled from "../../styled/styled-components";
import Input, { InputProps } from "@material-ui/core/Input";

const InputView: React.FunctionComponent<InputProps> = React.forwardRef<
  HTMLInputElement,
  InputProps
>((props, ref) => <Input disableUnderline inputRef={ref} {...props} />);

export default styled(InputView).attrs({
  classes: {
    root: "Root",
    formControl: "FormControl",
    focused: "Focused",
    disabled: "Disabled",
    underline: "Underline",
    error: "Error",
    multiline: "Multiline",
    fullWidth: "FullWidth",
    input: "Input",
    inputMarginDense: "InputMarginDense",
    inputMultiline: "InputMultiline",
    inputType: "InputType",
    inputTypeSearch: "InputTypeSearch"
  }
})`
  .Input {
    height: auto;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid #ced4da;
    font-size: 16px;
    padding: 10px 12px;
    transition: all 0.2s ease-out;

    &:focus {
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    &:disabled {
      background-color: #e9ecef;
    }
  }

  .error {
    border-color: ${({ theme }) => theme.colors.errorRed};
  }
`;
