import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import styled from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';

import './PeriodFilter.scss';

import { Filter, Label, Button, BtnGroup } from '../../styled/Filter';

// import periods from '../../constants/periodCodes';

const periodLabels = {
  currentMonth: 'Текущий месяц',
  // month: 'Месяц',
  // '6 month': '6 месяцев',
  currentYear: 'Текущий год',
  year: 'Календарный год',
  // all: 'За всё время',
  custom: 'Свой интервал',
};

export default class PeriodFilter extends Component {
  buildIntervalSelector() {
    const {
      activePeriod, dateFrom, dateTo, onChange,
    } = this.props;

    const dateFromInput = (
      <div>
        <div className="period-filter__datepicker">
          <label>с</label>
          <input value={moment(dateFrom).format('YYYY-MM-DD')} readOnly />
        </div>
      </div>
    );

    const dateToInput = (
      <div>
        <div className="period-filter__datepicker">
          <label>по</label>
          <input value={moment(dateTo).format('YYYY-MM-DD')} readOnly />
        </div>
      </div>
    );

    return (
      <div className="period-filter__datepickers">
        <DatePicker
          selected={moment(dateFrom)}
          dateFormat="YYYY-MM-DD"
          onChange={date => onChange(activePeriod, date, dateTo)}
          customInput={dateFromInput}
        />
        <DatePicker
          selected={moment(dateTo)}
          dateFormat="YYYY-MM-DD"
          onChange={date => onChange(activePeriod, dateFrom, date)}
          customInput={dateToInput}
        />
      </div>
    );
  }

  render() {
    const { activePeriod, onChange } = this.props;

    const buttons = Object.keys(periodLabels).map(code => (
      <Button
        key={code}
        type="button"
        active={activePeriod === code}
        onClick={() => onChange(code)}
      >
        {periodLabels[code]}
      </Button>
    ));

    return (
      <Container>
        <Label htmlFor="period-filter">Срок:</Label>
        <BtnGroup id="period-filter">
          {buttons}
        </BtnGroup>
        {activePeriod === 'custom' ? this.buildIntervalSelector() : null}
      </Container>
    );
  }
}

const Container = styled(Filter)``;
