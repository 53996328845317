import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Heading } from "../../../../components/Typography";
import { AppState } from "../../../../store";
import { ActiveRow } from "../../../../store/forms";

const Container = styled.header`
  margin: -24px -24px 0;
  padding: 14px 15px 7px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #e7eaec;
`;

const ListHeader: React.SFC<{
  activeRow?: ActiveRow,
}> = React.memo(() => (
  <Container>
    <Heading>История действий</Heading>
  </Container>
));

const mapStateToProps = (state: AppState) => ({
  // @ts-ignore
  activeRow: state.forms.tableForm.activeRow,
});

export default connect(mapStateToProps)(ListHeader)
