import { createAction } from "typesafe-actions";
import { SectionAccess, UserData } from "./types";

export const ActionTypes = {
  AUTH_FETCH_USER_REQUEST: "@@AUTH/AUTH_FETCH_USER_REQUEST",
  AUTH_FETCH_USER_SUCCESS: "@@AUTH/AUTH_FETCH_USER_SUCCESS",
  AUTH_FETCH_USER_ERROR: "@@AUTH/AUTH_FETCH_USER_ERROR",

  GET_ACCESS_SECTIONS_REQUEST: "@@AUTH/GET_ACCESS_SECTIONS_REQUEST",
  GET_ACCESS_SECTIONS_SUCCESS: "@@AUTH/GET_ACCESS_SECTIONS_SUCCESS",
  GET_ACCESS_SECTIONS_ERROR: "@@AUTH/GET_ACCESS_SECTIONS_ERROR",

  ACCESS_DENIED: "@@AUTH/ACCESS_DENIED",
};

export const fetchUserRequest = createAction(
  ActionTypes.AUTH_FETCH_USER_REQUEST,
  resolve => () => resolve()
);

export const fetchUserSuccess = createAction(
  ActionTypes.AUTH_FETCH_USER_SUCCESS,
  resolve => (data: UserData) =>
    resolve(data)
);

export const fetchUserError = createAction(
  ActionTypes.AUTH_FETCH_USER_ERROR,
  resolve => (error: any) =>
    resolve(error)
);

export const accessDenied = createAction(
  ActionTypes.ACCESS_DENIED,
  resolve => resolve
);

export const  getAccessSectionsRequest = createAction(
  ActionTypes.GET_ACCESS_SECTIONS_REQUEST,
  resolve => () => resolve()
);

export const getAccessSectionsSuccess = createAction(
  ActionTypes.GET_ACCESS_SECTIONS_SUCCESS,
  resolve => (data: SectionAccess[]) =>
    resolve(data)
);

export const getAccessSectionsError = createAction(
  ActionTypes.GET_ACCESS_SECTIONS_ERROR,
  resolve => (error: any) =>
    resolve(error)
);
