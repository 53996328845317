import {
  ClientTabType,
  Passport,
  InternationalPassport,
  Diploma,
  Authority,
  MigrationOrResidanceCard,
  Snils,
  PartnerAddress,
  AgentPersonalData,
  PlaceOfBirth,
  UserData,
  FilterFieldName,
  FilterItemData,
  ClientListItem,
  SortDirection,
  LoanForm
} from "../types";
import MainTab from "../components/Tabs/MainTab";
import AddressTab from "../components/Tabs/AddressTab";
import DocumentsTab from "../components/Tabs/DocumentsTab";
import LoanFormTab from "../components/Tabs/LoanFormTab";
import QuestionaryTab from "../components/Tabs/QuestionaryTab";
import CopyDocuments from "../components/Tabs/CopyDocuments";
import Videoverification from "../components/Tabs/Videoverification";
import CheckList from "../components/Tabs/CheckList";

export const CLIENT_TABS_LABELS = new Map<
  ClientTabType,
  {
    label: string;
    component: React.ComponentType;
  }
>([
  ["checklist", { label: "Чек-лист клиента", component: CheckList }],
  ["main", { label: "Общее", component: MainTab }],
  ["address", { label: "Адреса", component: AddressTab }],
  ["documents", { label: "Документы", component: DocumentsTab }],
  ["loanForm", { label: "Анкета на займ", component: LoanFormTab }],
  ["questionnaire", { label: "Опросник", component: QuestionaryTab }],
  ["copyDocuments", { label: "Копии документов", component: CopyDocuments }],
  ["videoverification", { label: "Видеоверификация", component: Videoverification }]
]);

export const PASSPORT_FIELDS = new Map<keyof Passport, string>([
  ["Series", "Серия"],
  ["Number", "Номер"],
  ["DateOfIssue", "Дата выдачи"],
  ["IssuedBy", "Кем выдан"],
  ["DivisionCode", "Код подразделения"]
]);

export const INTERNATIONAL_PASSPORT_FIELDS = new Map<
  keyof InternationalPassport,
  string
>([
  ["Number", "Номер"],
  ["IssuedDate", "Дата выдачи"],
  ["ExpirationDate", "Дата окончания"]
]);

export const SNILS_FIELDS = new Map<keyof Snils, string>([["Snils", "СНИЛС"]]);

export const PLACE_OF_BIRTH_FIELDS = new Map<keyof PlaceOfBirth, string>([
  ["PlaceOfBirth", "Место рождения"]
]);

export const DIPLOMA_FIELDS = new Map<keyof Diploma, string>([
  ["Type", "Тип документа"],
  ["Number", "Номер"],
  ["IssuedBy", "Кем выдан"],
  ["DateObtain", "Дата выдачи"],
  ["DateClose", "Дата окончания"]
]);

export const AUTHORITY_FIELDS = new Map<keyof Authority, string>([
  ["Name", "Наименование"],
  ["Number", "Номер"],
  ["DateCreate", "Дата"]
]);

export const MIGRATION_FIELDS = new Map<keyof MigrationOrResidanceCard, string>(
  [
    ["Series", "Серия"],
    ["Number", "Номер"],
    ["DateStart", "Дата начала срока пребывания"],
    ["DateEnd", "Дата окончания срока пребывания"]
  ]
);

export const ADDRESS_FIELDS = new Map<keyof PartnerAddress, string>([
  ["CountryId", "Страна"],
  ["RegionId", "Регион/область"],
  ["CityId", "Город"],
  ["StreetName", "Улица"],
  ["HouseNumber", "Номер дома"],
  ["HousingNumber", "Строение"],
  ["ApartmentNumber", "Квартира"],
  ["IndexNumber", "Индекс"]
]);

export const AGENT_PERSONAL_DATA_FIELDS = new Map<
  keyof AgentPersonalData,
  string
>([
  ["LastName", "Фамилия"],
  ["FirstName", "Имя"],
  ["Patronymic", "Отчество"],
  ["Phone1", "Телефон основной"],
  ["Phone2", "Телефон дополнительный"],
  ["Email", "Адрес электронной почты"],
  ["RelationId", "Степень родства"],
  ["NationalityId", "Гражданство"]
]);

export const CLIENT_FIELD_LABELS = new Map<keyof ClientListItem, string>([
  ["FullName", "ФИО"],
  ["Phone", "Номер телефона"],
  ["Mail", "E-mail"],
  ["Birthday", "Дата рождения"],
  ["RegistrationDate", "Дата поступления"],
  ["PassportNumber", "Номер паспорта"],
  ["InternationalPassportNumber", "Номер загранпаспорта"],
  ["Gender", "Пол"],
  ["IsActive", "Статус"]
]);

export const MAIN_FORM_FIELDS = [
  new Map<keyof UserData, string>([
    ["RegDate", "Дата обращени"],
    ["LastName", "Фамилия"],
    ["FirstName", "Имя"],
    ["Patronymic", "Отчество"],
    ["Gender", "Пол"],
    ["BirthDate", "День рождения"],
  ]),
  new Map<keyof UserData, string>([
    ["PhoneNumber", "Мобильный телефон (основной)"],
    ["PhoneMobileAdd", "Мобильный телефон (резервный)"],
    ["PhoneHome", "Домашний телефон"],
    ["Email", "Адрес электронной почты"],
    ["Inn", "ИHH"],
    ["Job", "Место работы"],
    ["JobPosition", "Должность"],
    ["FillInStaff", "Сотрудник Кооператива, заполнивший анкету"],
    ["Comment", "Комментарий"]
  ])
];

export const READONLY_FIELDS = ["RegDate"];

export const REQUIRED_FIELDS = new Map([
  ["Passport", ["Series", "Number", "DateOfIssue", "IssuedBy", "DivisionCode"]],
  [
    "UserData",
    [
      "RegDate",
      "LastName",
      "FirstName",
      "BirthDate",
      "PhoneNumber",
      "Email"
    ]
  ],
  ["PartnerAddress", ["HouseNumber", "StreetName"]],
  ["PlaceOfBirth", ["PlaceOfBirth"]]
]);

export const PARTNER_TYPE_LABELS = new Map([
  [1, "Пайщик"],
  [2, "Выгодоприобретатель"],
  [3, "Бенефициарный представитель"],
  [4, "Представитель пайщика"]
]);

export const DIPLOMA_TYPES_LABELS = new Map([
  [1, "УЛМ"],
  [2, "Диплом"],
  [3, "Водительское удостоверение"]
]);

export const OPERATOR_LABELS = new Map([[1, "И"], [2, "ИЛИ"], [3, "НЕ"]]);

export const DEFAULT_SEARCH_TYPE = 1;

export const DEFAULT_FILTER_FIELDS = new Map<
  FilterFieldName<ClientListItem>,
  FilterItemData
>([
  [
    "FullName",
    {
      FieldName: "FullName",
      Method: 1,
      Value: null,
      ValueType: 1
    }
  ],
  [
    "Phone",
    {
      FieldName: "Phone",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "Mail",
    {
      FieldName: "Mail",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "Birthday",
    {
      FieldName: "Birthday",
      Value: null,
      Method: 1,
      ValueType: 4
    }
  ],
  [
    "RegistrationDate",
    {
      FieldName: "RegistrationDate",
      Value: null,
      Method: 1,
      ValueType: 4
    }
  ],
  [
    "PassportNumber",
    {
      FieldName: "PassportNumber",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "InternationalPassportNumber",
    {
      FieldName: "InternationalPassportNumber",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "Gender",
    {
      FieldName: "Gender",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  [
    "IsActive",
    {
      FieldName: "IsActive",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ]
]);

export const DEFAULT_SORT_FIELD_NAME: FilterFieldName<ClientListItem> =
  "RegistrationDate";

export const DEFAULT_SORT_DIRECTION: SortDirection = 2;

export const AUTOCOMPLETE_FILTER_FIELDS = [
  "FullName",
  "PartnerNumber",
  "Phone",
  "Mail"
];

export const LOAN_FORM_CLIENT_FIELDS = new Map<keyof LoanForm, string>([
  ["ClientFullName", "ФИО"],
  ["PhoneNumberHome", "Домашний телефон"],
  ["PhoneNumberPerson", "Мобильный телефон"],
  ["Job", "Место работы"],
  ["JobSchedule", "График работы"],
  ["JobPosition", "Должность, тип судов"],
  ["RevenueInMonth", "Доход в месяц"],
  ["RelativePersonPhoneNumber", "Контактные номера родственников"]
]);

export const LOAN_FORM_REASON_FIELDS = new Map<keyof LoanForm, string>([
  ["Reason", "Зачем нужны деньги"],
  // ["ReasonAdv", "Зачем нужны деньги"],
  ["EarlyLoans", "Есть ли еще кредиты, если да, то какие?"],
  ["OverdueLoans", "Есть / были просторчки?"]
]);

export const LOAN_FORM_CREDIT_FIELDS = new Map<keyof LoanForm, string>([
  ["Sum", "Сумма"],
  ["CurrencyId", "Валюта кредита"],
  ["LoanDate", "Срок кредита (лет / месяцев)"]
]);

export const LOAN_FORM_COMMENTS_FIELDS = new Map<keyof LoanForm, string>([
  ["Comment", "Комментарий"]
]);
