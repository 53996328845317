import styled from "../../../styled/styled-components";

export const AccessWrapper = styled.div`
  border: 1px solid black;
  height: 400px;
  overflow: scroll;
  text-align: left;
  width: 200px;
  margin-right: 10px;
`;

export const ButtonStyled = styled.div`
    font-size: 14px;
    color: #fff;
    border: 0;
    border-radius: 3px;
    height: 30px;
    width: 30px;
    padding: 0 10px;
    user-select: none;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const ButtonRemove = styled(ButtonStyled)`
    background-color: #dc3545;
`;

export const ButtonAdd = styled(ButtonStyled)`
    background-color: #1ab394;
`;
