import React, { PureComponent } from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: auto;
    background: #f0f2f5;
    min-height: 0;
    justify-content: center;
`;

export default class AccessDenied extends PureComponent {
  public render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    return (
      <Wrapper>
        <h2>Доступ закрыт</h2>
        <div>Этот раздел для вас не доступен. Обратитесь к администратору для разрешения данной проблемы</div>
      </Wrapper>
    );
  }

}
