import * as React from 'react';
import { ErrorData } from '../../types';
import { toast } from 'react-toastify';
import styled from '../../styled/styled-components';

const ErrorHeading = styled.div`
  font-size: 0.9em;
  margin-bottom: 15px;
`;

const ErrorBody = styled.div`
  font-size: 0.8em;
`;

export const ErrorMessage: React.SFC<
React.HTMLAttributes<HTMLDivElement> & { errorData: ErrorData }
> = ({ errorData, children }) => (
  <div>
    {errorData.Message && <ErrorHeading>{errorData.Message}</ErrorHeading>}
    {errorData.ExceptionMessage && <ErrorBody>{errorData.ExceptionMessage}</ErrorBody>}
    {errorData.MessageDetail && <ErrorBody>{errorData.MessageDetail}</ErrorBody>}
  </div>
);

/**
 * @deprecated
 * use ErrorProvider
 */
export const showErrorToast = (errorData: ErrorData | Error) => {
  if (errorData instanceof Error) {
    errorData = {
      Message: errorData.message,
    };
  }
  toast(<ErrorMessage errorData={errorData} />, {
    type: toast.TYPE.ERROR,
  });
};
