import moment from "moment";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { RECIPIENTS_GROUPS } from "../constants";
import { History, Location } from "history";

export const formatValue = (fieldName: string, rawValue: any) => {
  switch (fieldName) {
    case "ReleaseTime":
      const dateMoment = moment(rawValue);
      if (dateMoment.isValid()) {
        return dateMoment.format(DatePicker.prettyDateTimeFormat);
      }

    case "RecipientsGroup":
      return RECIPIENTS_GROUPS.get(rawValue);

    default:
      return rawValue;
  }
};

export const getPageFromSearch = (search: string) => {
  const urlParams = new URLSearchParams(search.toLowerCase());
  const currentPage = urlParams.get("page");

  return currentPage ? parseInt(currentPage, 10) : 1;
};

export const сutFirstPageFromQuery = (history: History, location: Location) => {
  const queryParams = new URLSearchParams(location.search.toLowerCase());

  if (queryParams.get("page") === "1") {
    queryParams.delete("page");
    history.replace(`${location.pathname}?${queryParams.toString()}`);
  }
};
