import { Reducer } from "redux";
import { ActionTypes } from "./actions";
import { BillsState } from "./types";

const initialState: BillsState = {
  commonBills: {
    data: [],
    loading: false,
    error: undefined,
  }
};

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_COMMON_BILL_FOR_OPERATION_REQUEST:
      return {
        ...state,
        commonBills: {
          data: [],
          loading: true,
          error: undefined,
        }
      };
    case ActionTypes.GET_COMMON_BILL_FOR_OPERATION_SUCCESS:
      return {
        ...state,
        commonBills: {
          data: action.payload,
          loading: false,
          error: undefined,
        }
      };
    case ActionTypes.GET_COMMON_BILL_FOR_OPERATION_ERROR:
      return {
        ...state,
        commonBills: {
          data: [],
          loading: false,
          error: action.payload,
        }
      };
    default:
      return state;
  }
};

export { reducer as billsReducer };
