import * as React from "react";
import { NONE_VALUE, IS_ACTIVE_LABELS } from "../../constants";
import styled from "../../styled/styled-components";
import Clickable from "../Clickable";
import { Check } from "styled-icons/feather";
import { Times } from "styled-icons/fa-solid";
import DatePicker from "../DatePicker/DatePicker";
import Autocomplete from "../Autocomplete";
import Select from "../Select";
import { connect } from "react-redux";
import { AppState } from "../../store";
import { LabelSection } from "../../store/labels";
import { parseServerDate, convertToUtc } from '../../utils';
import { PAYMENT_REQUEST_TYPE_LABELS } from '../../modules/PaymentRequests/constants';

export interface FilterItemData<T> {
  FieldName: T;
  Value?: any;
  Method: number;
  ValueType: number;
}
export interface ColumnFilterProps<T extends string | number | symbol> {
  filterData: FilterItemData<T>;
  onChangeFilter: (
    filterItemData: FilterItemData<T>,
    forceFiltering?: boolean
  ) => void;
  acceptFilter: () => void;
  resetFilter: (fieldName: T) => void;
  filtersShown: boolean;
  autocompleteItems: { [fieldName in T]?: string[] };
  labels: Record<string | number, LabelSection>;
  entityType?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const TypeSelect = styled.select`
  font-size: 10px;
  background: transparent;
  border: 0;
  text-align: right;
`;

const SettingsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Item = styled.div`
  margin-left: 10px;
`;

const FilterSettings: React.SFC<{
  type: number | null;
  handleChangeSearchType: React.ChangeEventHandler<HTMLSelectElement>;
  resetFieldValue: () => void;
  acceptFilter: () => void;
}> = React.memo(
  ({ type, handleChangeSearchType, resetFieldValue, acceptFilter }) => (
    <SettingsWrapper>
      {type !== null && (
        <Item>
          <TypeSelect value={type} onChange={handleChangeSearchType}>
            <option value={1}>Содержит</option>
            <option value={10}>Не содержит</option>
            <option value={2}>Начинается с</option>
            <option value={3}>Заканчивается на</option>
            <option value={4}>Равно</option>
          </TypeSelect>
        </Item>
      )}
      <Item>
        <Clickable onClick={acceptFilter}>
          <Check size="12px" />
        </Clickable>
      </Item>
      <Item>
        <Clickable onClick={resetFieldValue}>
          <Times size="14px" />
        </Clickable>
      </Item>
    </SettingsWrapper>
  )
);

class ColumnFilter<T extends string | number | symbol> extends React.Component<
  ColumnFilterProps<T>
> {
  public render() {
    return (
      <Wrapper>
        {this.props.filtersShown && (
          <FilterSettings
            type={
              this.props.filterData.ValueType !== 5
                ? this.props.filterData.Method
                : null
            }
            handleChangeSearchType={this.handleChangeType}
            resetFieldValue={this.resetFieldValue}
            acceptFilter={this.props.acceptFilter}
          />
        )}
        {this.getItem()}
      </Wrapper>
    );
  }

  private getItem = () => {
    const {
      Value: value,
      FieldName: fieldName,
      ValueType
    } = this.props.filterData;

    if (ValueType === 5) {
      const labels = this.getLabelsForSelect(fieldName.toString());
      return (
        <Select
          selectProps={{
            value:
              value === null || value === NONE_VALUE
                ? NONE_VALUE
                : Number(value),
            onChange: this.handleChange
          }}
          items={[
            {
              key: NONE_VALUE,
              label: "Все"
            },
            ...labels.map(([key, label]) => ({
              key: key.toString(),
              label
            }))
          ]}
        />
      );
    }

    if (fieldName === "IsActive") {
      return (
        <Select
          selectProps={{
            value:
              value === null || value === NONE_VALUE
                ? NONE_VALUE
                : Number(value),
            onChange: this.handleChange
          }}
          items={[
            {
              key: NONE_VALUE,
              label: "Все"
            },
            ...[...IS_ACTIVE_LABELS.entries()].map(([key, label]) => ({
              key,
              label
            }))
          ]}
        />
      );
    }

    if (fieldName === "IsRequisites") {
      return (
        <Select
          style={{ width: '220px' }}
          selectProps={{
            value:
              value === null || value === NONE_VALUE
                ? NONE_VALUE
                : value,
            onChange: this.handleChange
          }}
          items={[
          {
            key: NONE_VALUE,
            label: "Все"
          },
          ...[...PAYMENT_REQUEST_TYPE_LABELS.entries()].map(([key, label]) => ({
            key,
            label
          }))
        ]}/>
      );
    }

    if (ValueType === 4) {
      return (
        <DatePicker
          selected={value && parseServerDate(value)}
          onChange={this.handleChangeDate}
          disabledKeyboardNavigation
          isClearable
        />
      );
    }

    const getAutocompleteItems = (query: string) => {
      const items = this.props.autocompleteItems[fieldName];

      return items === undefined ? [] : (items as string[]);
    };

    const renderItem = (item: string) => item;

    return (
      <Autocomplete
        onInputChange={this.handleChangeAutocompleteInput}
        value={value}
        getItems={getAutocompleteItems}
        onSelectItem={this.handleSelectAutocompleted}
        renderItem={renderItem}
        onKeyDown={this.handleKeyDownAutocomplete}
      />
    );
  };

  private handleChangeAutocompleteInput = (value: string) => {
    if (this.props.filterData.Value !== value) {
      this.props.onChangeFilter({
        ...this.props.filterData,
        Value: value || null
      });
    }
  };

  private handleKeyDownAutocomplete = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const { key } = event;

    if (key === "Enter") {
      this.props.acceptFilter();
    }
  };

  private handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const value = event.target.value;
    const fieldNameAsString = this.props.filterData.FieldName.toString();

    this.props.onChangeFilter(
      {
        ...this.props.filterData,
        Value: value === NONE_VALUE || value === "" ? null : value
      },
      fieldNameAsString === "Gender" || fieldNameAsString === "IsActive"
    );
  };

  private handleSelectAutocompleted = (value: string) => {
    this.props.onChangeFilter(
      {
        ...this.props.filterData,
        Value: value
      },
      true
    );
  };

  private handleChangeDate = (date: Date, event: any) => {
    if (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value)) {
      const value = date ? convertToUtc(date).toISOString() : null;

      this.props.onChangeFilter({
        ...this.props.filterData,
        Value: value === NONE_VALUE || value === "" ? null : value
      });
    }
  };

  private handleChangeType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.onChangeFilter({
      ...this.props.filterData,
      Method: parseInt(event.target.value, 10)
    });
  };

  private resetFieldValue = () => {
    this.props.resetFilter(this.props.filterData.FieldName);
  };

  private getLabelsForSelect = (fieldName: string) => {
    const { labels, entityType } = this.props;
    let key;

    switch (fieldName) {
      case "Gender":
        key = "GENDER";
        break;

      case "IsActive":
        key = "IS_ACTIVE";
        break;

      case "CurrencyId":
        key = "CURRENCY";
        break;

      case "ClientType":
        key = "CLIENTS_TYPE";
        break;

      case "Currency":
        key = "CURRENCY";
        break;

      case "PrivilegeLevel":
        key = "PRIVILEGE_LEVEL_LABELS";
        break;

      case "Type": {
        if (entityType === "PAYMENT_REQUESTS") {
          key = "PAYMENT_REQUEST_BILL_TYPE";
        }
        break;
      }

      case "Status": {
        if (entityType === "PAYMENT_REQUESTS") {
          key = "PAYMENT_REQUESTS_STATUS";
        }
        break;
      }
      case 'IsRequisites':
        if (entityType === 'PAYMENT_REQUESTS') {
          key = "PAYMENT_REQUESTS_TYPE";
        }
        break;
    }

    if (key && labels[key]) {
      return [...labels[key].entries()] || [];
    }

    return [];
  };
}

const mapStateToProps = (state: AppState) => ({
  labels: state.labels.labels
});

export default function ConnectedColumnFilter<
  T extends string | number | symbol
>() {
  return connect(mapStateToProps)(ColumnFilter as React.ComponentType<
    ColumnFilterProps<T>
  >);
}
