import React, { Component } from 'react';

export default class Item extends Component {
  render() {
    const {
      icon,
      collapsed,
      title,
      onClick,
      activeKey,
      id,
      url,
    } = this.props;

    let content;

    if (url) {
      content = (
        <a href={url} className={`menu-item__header${collapsed ? '_collapsed' : ''}`}>
          <div className="menu-item__icon">{icon}</div>
          <div className="menu-item__title">{title}</div>
          <i className="menu-sub__arrow" />
        </a>
      );
    } else {
      content = (
        <div className={`menu-item__header${collapsed ? '_collapsed' : ''}`}>
          <div className="menu-item__icon">{icon}</div>
          <div className="menu-item__title">{title}</div>
          <i className="menu-sub__arrow" />
        </div>
      );
    }

    return (
      <div
        className={`menu-item ${activeKey === id ? 'menu-item_active' : ''}`}
        onClick={onClick}
      >
        {content}
      </div>
    );
  }
}
