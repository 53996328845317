export const OPERATION_TYPE_PAY_IN = 1;
export const OPERATION_TYPE_PAY_OUT = 0;

export const OPERATION_NAME_PAY_IN = "Пополнение";
export const OPERATION_NAME_PAY_OUT = "Списание";

export const ID_REPLENISHMENT_OF_PERSONAL_SAVING = 19;
export const ID_PAY_OF_PERSONAL_SAVING = 22;
export const ID_REPLENISHMENT_OF_ACCUMULATION = 29;
export const ID_LOAN_ACCOUNT_REPLENISHMENT = 139;
export const ID_SALARIES_PAYROLL = 44;
export const ID_SALARIES_INCOMING = 41;
export const ID_SALARIES_OUTCOMING_CONVERTATION = 149;

export const types = {
  [OPERATION_NAME_PAY_IN]: OPERATION_TYPE_PAY_IN,
  [OPERATION_NAME_PAY_OUT]:  OPERATION_TYPE_PAY_OUT,
};

export const operationTypeCodesBool = {
  [OPERATION_TYPE_PAY_IN]: true,
  [OPERATION_TYPE_PAY_OUT]: false
};

export default {
  [OPERATION_TYPE_PAY_IN]: "Пополнение",
  [OPERATION_TYPE_PAY_OUT]: "Списание"
};
