import React, { Component } from 'react';
import Helmet from "react-helmet";
import { RouteComponentProps } from "react-router";
import { get } from 'lodash';

import LoadingOverlay from "../../../../components/LoadingOverlay";
import Link from "../../../../components/Link";
import { ArrowLeft } from "styled-icons/fa-solid";
import SectionHeader from "../../../../components/SectionHeader";
import Switcher from '../../components/Switcher';
import RaisingForm from '../../components/RaisingForm';


export default class Raising extends Component<RouteComponentProps> {
  public render() {
    const partnerNumber = get(this.props, 'match.params.partnerNumber');
    return (
      <div>
        <Helmet defer={false}>
          <title>Создать счет привлечения средств</title>
        </Helmet>
        <SectionHeader
          heading={this.getHeading()}
          link={this.renderBackLink()}
        />
        <LoadingOverlay loading={false}>
          <div className="row mb-5">
              <Switcher active="rising" {...this.props} />
          </div>
          <div className="row">
            <div className="col-md-6">
              <RaisingForm goToList={this.goToList} partnerNumber={partnerNumber} />
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }

  private goToList = (path: string, state: any) => {
    this.props.history.replace(path, state);
  };

  private renderBackLink = () => (
    <Link onClick={this.goToList.bind(undefined, '/client', undefined)}>
      <ArrowLeft size={13} /> Вернуться к списку
    </Link>
  );

  private getHeading = () => {
    return 'Создать счет привлечения средств';
  };

}
