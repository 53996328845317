import { action, createAction } from "typesafe-actions";
import { CountryType, RegionsActionTypes, ServerResponse, StreetResponse } from "./types";
import { serializeCountries, serializeRegions, serializeCities } from "./utils";

export const fetchCountriesRequest = () =>
  action(RegionsActionTypes.FETCH_COUNTRIES_REQUEST);

export const fetchRegionsRequest = createAction(
  RegionsActionTypes.FETCH_REGIONS_REQUEST,
  resolve => (countryId: number) => resolve(countryId)
);
export const fetchCitiesRequest = createAction(
  RegionsActionTypes.FETCH_CITIES_REQUEST,
  resolve => (regionId: number, countryId: number) =>
    resolve({ regionId, countryId })
);
export const fetchStreetsRequest = createAction(
  RegionsActionTypes.FETCH_STREETS_REQUEST,
  resolve => (query: string, cityId: number) =>
    resolve({ query, cityId })
);

export const saveCountriesSuccess = (data: CountryType[]) =>
  action(RegionsActionTypes.SAVE_COUNTRIES_SUCCESS, data);

export const fetchCountriesSuccess = (data: ServerResponse) =>
  action(RegionsActionTypes.FETCH_COUNTRIES_SUCCESS, serializeCountries(data));

export const fetchRegionsSuccess = (data: ServerResponse, countryId: number) =>
  action(RegionsActionTypes.FETCH_REGIONS_SUCCESS, {
    data: serializeRegions(data),
    countryId
  });

export const fetchCitiesSuccess = (
  data: ServerResponse,
  regionId: number,
  countryId: number
) =>
  action(RegionsActionTypes.FETCH_CITIES_SUCCESS, {
    data: serializeCities(data),
    regionId,
    countryId
  });

export const fetchStreetsSuccess = (
  data: StreetResponse
) =>
  action(RegionsActionTypes.FETCH_STREETS_SUCCESS, data);
