import * as React from "react";
import Downshift, { StateChangeOptions } from "downshift";
import MenuItem from "@material-ui/core/MenuItem";
import { Popper, Paper } from "@material-ui/core";
import { Nullable } from "../../types/typeUtils";
import Input from "../Input";

interface SuggestionProps<T> {
  suggestion: T;
  index: number;
  highlightedIndex: Nullable<number>;
  selectedItem: T;
  itemProps: any;
}

const Suggestion: React.SFC<SuggestionProps<any>> = <T extends {}>({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem
}: SuggestionProps<T>) => {
  const isHighlighted = highlightedIndex === index;
  const isSelected = selectedItem === suggestion;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400
      }}
    >
      {suggestion}
    </MenuItem>
  );
};

export interface AutocompleteProps<T> {
  getItems: (query: string) => T[];
  renderItem: (item: T) => string;
  renderDropDownItem?: (item: T) => string;
  onInputChange?: (inputValue: string) => void;
  className?: string;
  onSelectItem?: (item: T) => void;
  onFocus?: (e: any) => void;
  value?: T;
  disabled?: boolean;
  error?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

export default class Autocomplete<T> extends React.PureComponent<
  AutocompleteProps<T>,
  {
    isOpen: boolean;
  }
> {
  public static defaultProps = {
    renderItem: (item: { label: string }) => (item ? item.label : "")
  };

  public state = {
    isOpen: false
  };
  private inputRefElement = React.createRef<HTMLInputElement>();
  public render() {
    return (
      <div className={this.props.className}>
        <Downshift
          onInputValueChange={this.props.onInputChange}
          onSelect={this.props.onSelectItem}
          itemToString={this.props.renderItem}
          selectedItem={this.props.value || null}
          isOpen={this.state.isOpen}
          onStateChange={this.handleChangeState}
        >
          {({
            getInputProps,
            getItemProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem
          }) => {
            const inputProps: React.InputHTMLAttributes<
              HTMLInputElement
            > = getInputProps({
              onKeyDown: highlightedIndex === null && this.props.onKeyDown
            });

            return (
              <div>
                <Input
                  fullWidth
                  inputRef={this.inputRefElement}
                  {...inputProps}
                  error={this.props.error}
                  value={inputProps.value || ""}
                  onFocus={this.handleFocus}
                  autoComplete="off"
                  disabled={this.props.disabled}
                />
                {this.inputRefElement.current && (
                  <Popper
                    open={isOpen}
                    anchorEl={this.inputRefElement.current}
                    placement="bottom-start"
                  >
                    <Paper
                      square
                      style={{
                        maxHeight: 300,
                        overflowY: "auto",
                        minHeight: "100%"
                      }}
                    >
                      {this.props
                        .getItems(inputValue || "")
                        .map((item, index) => (
                          <Suggestion
                            key={index}
                            suggestion={this.props.renderDropDownItem ? this.props.renderDropDownItem(item) : this.props.renderItem(item)}
                            index={index}
                            itemProps={getItemProps({ item })}
                            highlightedIndex={highlightedIndex}
                            selectedItem={this.props.renderItem(selectedItem)}
                          />
                        ))}
                    </Paper>
                  </Popper>
                )}
              </div>
            );
          }}
        </Downshift>
      </div>
    );
  }

  private handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.setSelectionRange(0, event.target.value.length);
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(event);
    }

    this.setState({ isOpen: true });
  };

  private handleChangeState = ({ isOpen }: StateChangeOptions<T>) => {
    if (isOpen !== undefined && isOpen !== this.state.isOpen) {
      this.setState({ isOpen });
    }
  };
}
