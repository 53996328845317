import { createSelector } from "reselect";
import { union, flattenDeep, cloneDeep, isEmpty, uniq } from 'lodash';

import { AppState } from "../index";
import { AuthState, SectionAccess, SectionAccessState, UserData } from "./types";
import { menuItems } from "./constants";

export const getAuth = (state: AppState) => state.auth;

export const getAuthData = createSelector(
  getAuth,
  (auth: AuthState) => auth.data
);

export const getReadAccess = createSelector(
  getAuthData,
  (data?: UserData) => {
    if (!data) {

      return [];
    }

    return data.AllowReadSections;
  }
);

export const getWriteAccess = createSelector(
  getAuthData,
  (data?: UserData) => {
    if (!data) {

      return [];
    }

    return data.AllowWriteSections;
  }
);

export const getAllAccessIds = createSelector(
  getReadAccess,
  getWriteAccess,
  (readAccess: number[], writeAccess: number[]) => union(readAccess, writeAccess)
);

export const getAccessSections = (state: AppState) => state.auth.accessSections;

export const getAccessSectionsData = createSelector(
  getAccessSections,
  (items: SectionAccessState) => items.data
);

export const getAccessSectionsLoading = createSelector(
  getAccessSections,
  (items: SectionAccessState) => items.loading
);

export const getAllAccess = createSelector(
  getAllAccessIds,
  getAccessSectionsData,
  (accessIds: number[], accesses: SectionAccess[]) => accesses.filter((access: SectionAccess) => accessIds.includes(access.Id))
);

export const getAllAccessUrls = createSelector(
  getAllAccess,
  (accesses: SectionAccess[]) => flattenDeep(accesses.map((access: SectionAccess) => access.Routes))
);

export const createMenuItems = createSelector(
  getAllAccessUrls,
  (accesses: Array<string | ConcatArray<string>>) => {
    const filterItems = (item: any) => item;

    const checkMenuItems = (item: any) => {
      if (item.submenu) {
        item.submenu = item.submenu.map(checkMenuItems).filter(filterItems);
        if (item.submenu.length > 0) {
          return item;
        }
      }

      if (item.url && _testUrl(accesses, item.url)) {
        return item;
      }

      return undefined
    };



    return cloneDeep(menuItems).map(checkMenuItems).filter(filterItems);
  }
);

const _testUrl = (accesses: Array<string | ConcatArray<string>>, url: string): boolean => {
  for (const item of accesses) {
    if (new RegExp(item.toString()).test(url)) {

      return true;
    }
  }

  return false;
};

export const getAccessSectionsTree = createSelector(
  getAccessSectionsData,
  (items: SectionAccess[]) => {

    const extraItems = _getParents(items).map((item: string) => ({
      Section: item,
      Parent: null,
      Routes: [],
    }));

    return unflatten([...extraItems, ...items]);
  }
);

const unflatten = (array: any, parent = {
  Section: null,
  Id: undefined,
}, tree = [] ) => {

  // @ts-ignore
  const children = array.filter((child) => child.Parent === parent.Section);

  if(!isEmpty(children)){
    if( parent.Section === null ){
      tree = children;
    } else {
      // @ts-ignore
      parent['children'] = children
    }
    // @ts-ignore
    children.forEach(child =>  unflatten( array, child ))
  } else {
    // @ts-ignore
    parent['children'] =
      [
        {
          Section: `${parent.Section} (чтение)`,
          Id: `${parent.Id}-read`,
        },
        {
          Section: `${parent.Section} (запись)`,
          Id: `${parent.Id}-write`,
        }
      ]
  }

  return tree;
};

const _getParents = (array: SectionAccess[]) => {
  const parents = array.filter((item: SectionAccess) => item.Parent).map((item: SectionAccess) => item.Parent);
  return uniq(parents)
};

export const getUrl = (state: AppState, url?: string) => url;

export const hasAccessToUrl = createSelector(
  getAllAccessUrls,
  getUrl,
  (accesses: Array<string | ConcatArray<string>>, url?:string) => {
    if (!url) {
      return true;
    }

    return _testUrl(accesses, url);
  }
);
