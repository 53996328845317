import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from "redux";

import PlannedSchedule from './PlannedSchedule';
import { AppState } from "../../../../store";
import { fetchPlannedScheduleRequest } from "../../../../store/billDetails";


const mapStateToProps = (state: AppState) => ({
  calendars: state.billDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchPlannedScheduleRequest,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(PlannedSchedule);
