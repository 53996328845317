import * as React from "react";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import SelectBase, {
  SelectProps as BaseSelectProps
} from "@material-ui/core/Select";
import Input from "../Input";
import styled from "../../styled/styled-components";

export interface SelectItem {
  key: number | string;
  label: string;
}
export interface SelectProps extends FormControlProps {
  items: SelectItem[];
  selectProps?: BaseSelectProps;
}

const StyledInput = styled(Input)`
  .Input {
    padding-right: 24px;
  }
`;

const StyledSelect = styled(props => <SelectBase {...props} />).attrs({
  classes: {
    disabled: "Disabled"
  }
})`
  .Disabled {
    background-color: #e9ecef;
  }
`;

export default class Select extends React.PureComponent<SelectProps> {
  public render() {
    const { items, selectProps, ...formControlProps } = this.props;
    return (
      <FormControl {...formControlProps}>
        <StyledSelect
          {...selectProps}
          input={<StyledInput disabled={formControlProps.disabled} />}
        >
          {items.map(item => (
            <MenuItem key={item.key} value={item.key}>
              {item.label}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    );
  }
}
