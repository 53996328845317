import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';

import { GlobalConfig } from './_config';

require('highcharts/modules/no-data-to-display')(ReactHighcharts.Highcharts);

ReactHighcharts.Highcharts.setOptions(GlobalConfig);

const baseConfig = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}% ({point.y})</b>',
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.percentage:.1f} % ({point.y})',
        style: {
          color: 'black',
        },
      },
    },
  },
  series: [{
    name: 'Brands',
    colorByPoint: true,
    data: [],
  }],
};

export default class PieChartOld extends Component {
  render() {
    const { title, data } = this.props;
    const config = {
      ...baseConfig,
      title: { text: title },
      series: [{
        ...baseConfig.series[0],
        name: title,
        data,
      }],
    };
    return <ReactHighcharts config={config} />;
  }
}
