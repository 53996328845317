import styled from "../../../styled/styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-basis: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  flex-direction: column;
`;
