import { createSelector } from "reselect";
import { AppState } from "../index";
import { CountryType, RegionsState } from "./types";

export const getRegions = (state: AppState) => state.regions;
export const getRegionsLoading = (state: AppState) => state.regions.loading;

export const getRegionsCountries = createSelector(
  getRegions,
  (data: RegionsState) => data.allCountries
);

export const getFormatedCountries = createSelector(
  getRegionsCountries,
  (countries: CountryType[] ) => countries.map((country: CountryType) => ({
    id: country.Value,
    name: country.Text,
    regions: [],
    iso2: country.CountryCode ? country.CountryCode.toLowerCase() : '',
    dialCode: country.PhoneCode,
  }))
);
