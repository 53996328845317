import React from 'react';
import Helmet from "react-helmet";
import { RouteComponentProps } from "react-router";
import { toNumber, get } from 'lodash';

import SectionHeader from "../../../components/SectionHeader";
import LoadingOverlay from "../../../components/LoadingOverlay";
import GroupForm from "../components/GroupForm";

export default class Group extends React.Component<RouteComponentProps> {
  public render() {
    const groupId = toNumber(get(this.props.match.params, 'groupId'));
    return (
      <div>
        <Helmet defer={false}>
          <title>{this.getHeading(groupId > 0)}</title>
        </Helmet>
        <SectionHeader
          heading={this.getHeading(groupId > 0)}
        />
        <LoadingOverlay loading={false}>
          <div className="row">
            <div className="col-12">
              <GroupForm groupId={groupId} goToBack={this.goToBack} />
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }

  private getHeading = (isEdit: boolean) => {
    return isEdit ? 'Редактирование группы' : 'Создание группы';
  };

  private goToBack = () => {
    this.props.history.replace('/SecurityGroup/List')
  };
}
