import * as React from "react";
import styled, { css } from "../../../styled/styled-components";
import Clickable, { ClickableProps } from "../../../components/Clickable";
import { ifProp } from "styled-tools";

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const FormColumn = styled.div`
  width: 50%;
  padding: 0 10px;
`;

export const FormLine = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  margin-bottom: 15px;
`;

export const FormError = styled.span`
  position: absolute;
  bottom: -10px;
  color: ${({ theme }) => theme.colors.errorRed};
`;

export const TabLinks = styled.div`
  margin-bottom: -1px;
`;

const TabLinkView: React.SFC<ClickableProps & { error?: boolean }> = ({
  error,
  ...props
}) => <Clickable {...props} />;

export const TabLink = styled(TabLinkView)`
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid transparent;
  border-color: ${({ theme, active }) =>
    active ? theme.colors.lightGray : "transparent"};
  border-bottom-color: ${({ theme, active }) =>
    active ? theme.colors.white : "transparent"};

  ${ifProp(
    "error",
    css`
      color: ${({ theme }) => theme.colors.errorRed};
    `
  )}
`;

export const TabContent = styled.div`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 4px;
`;

export const HeaderButtonsWrapper = styled.div`
  display: flex;
  margin-left: 20px;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
`;

export const HeaderButtonsItem = styled.span`
  margin: 5px;
`;

export const PartnerInitials = styled.h3`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  
  font-size: 1.24em;
  padding: 1rem 0;
`;
