import * as React from "react";

export interface IconProps extends React.AllHTMLAttributes<HTMLSpanElement> {
  name: string;
}

const Icon: React.SFC<IconProps> = ({ name, ...props }) => (
  <span {...props}>
    <i className={`fa fa-${name}`} />
  </span>
);

export default Icon;
