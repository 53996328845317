import * as React from "react";
import { ClientsContext } from "../../pages/Client";
import Panel from "../../../../components/Panel";
import { FormWrapper, FormColumn } from "../Views";
import {
  ADDRESS_FIELDS,
  PLACE_OF_BIRTH_FIELDS,
  REQUIRED_FIELDS
} from "../../constants";
import Form from "../../../../components/PartnersAndClients/Form";
import styled from "../../../../styled/styled-components";
import Button from "../../../../components/Button/Button";

const CopyButton = styled(Button)`
  display: block;
  margin: 0 0 0 auto;
`;

export default class AddressTab extends React.PureComponent {
  public render() {
    return (
      <ClientsContext.Consumer>
        {({
          editing,
          userData,
          handleChange = () => void 0,
          copyAddress = () => void 0,
          errors,
          valueLabels
        }) => (
          <FormWrapper>
            <FormColumn>
              <Panel type="primary" heading={"Адрес регистрации"}>
                {userData && (
                  <Form
                    data={userData.PartnerAddressRegistration}
                    fields={ADDRESS_FIELDS}
                    disabled={!editing}
                    onChange={handleChange.bind(
                      null,
                      "PartnerAddressRegistration"
                    )}
                    requiredFields={REQUIRED_FIELDS.get("PartnerAddress")}
                    requiredFieldsError={errors.get("PartnerAddress")}
                    valueLabels={valueLabels}
                  />
                )}
                <CopyButton
                  type="success"
                  disabled={!editing}
                  onClick={copyAddress}
                >
                  Копировать в адрес для корреспонденции
                </CopyButton>
              </Panel>
            </FormColumn>
            <FormColumn>
              <Panel type="primary" heading={"Адрес для корреспонденции"}>
                {userData && (
                  <Form
                    data={userData.PartnerAddressMail}
                    fields={ADDRESS_FIELDS}
                    disabled={!editing}
                    onChange={handleChange.bind(null, "PartnerAddressMail")}
                    valueLabels={valueLabels}
                  />
                )}
              </Panel>
            </FormColumn>
            <FormColumn>
              <Panel type="primary" heading={"Место рождения"}>
                {userData && (
                  <Form
                    data={userData}
                    fields={PLACE_OF_BIRTH_FIELDS}
                    disabled={!editing}
                    onChange={handleChange.bind(null, null)}
                    requiredFields={REQUIRED_FIELDS.get("PlaceOfBirth")}
                    requiredFieldsError={errors.get("PlaceOfBirth")}
                    valueLabels={valueLabels}
                  />
                )}
              </Panel>
            </FormColumn>
          </FormWrapper>
        )}
      </ClientsContext.Consumer>
    );
  }
}
