import * as React from "react";
import Dropdown from "../Dropdown";
import { Checkbox } from "../FormControls/FormControls";
import styled from "../../styled/styled-components";
import { FilterFieldName } from "../../types/PartnersAndClients";
import Label from "../Label";

const Menu = styled.div`
  padding: 8px 10px;
`;

const MenuItem = styled.label`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 8px;
  cursor: pointer;
`;

const MenuItemCheckbox = styled(Checkbox)`
  margin-right: 15px;
`;

export interface ColumnsDropdownProps<T> {
  fields: Map<FilterFieldName<T>, boolean>;
  handleChangeColumns: React.ChangeEventHandler<HTMLInputElement>;
  labels?: Map<keyof T, string>;
  labelsSection?: string;
}

export default class ColumnsDropdown<T> extends React.Component<
  ColumnsDropdownProps<T>
> {
  public render() {
    return (
      <Dropdown caption="Выбор колонок">
        <Menu>
          {[...this.props.fields.entries()].map(([fieldName, visible]) => (
            <MenuItem key={fieldName.toString()}>
              <MenuItemCheckbox
                checked={visible}
                value={fieldName.toString()}
                onChange={this.props.handleChangeColumns}
              />{" "}
              {this.props.labels ? (
                this.props.labels.get(fieldName)
              ) : (
                <Label
                  section={this.props.labelsSection}
                  value={fieldName.toString()}
                />
              )}
            </MenuItem>
          ))}
        </Menu>
      </Dropdown>
    );
  }
}
