import * as React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

const OperationTableRowView: React.SFC<
React.TableHTMLAttributes<HTMLTableRowElement> & { isRemoved?: boolean }
> = ({ isRemoved, ...props }) => <tr {...props} />;

const removedStylyes = css`
  background: #f8d7da;
  color: #721c24;
`;

export const OperationTableRow = styled(OperationTableRowView)`
  ${ifProp('isRemoved', removedStylyes)}
`;
