import { createAction } from "typesafe-actions";

import { Partner, LoanProductsParams, LoanProducts, LoanProductsConditionsParams, LoanProductConditions, ActiveRow } from './types';

export const ActionTypes = {
  SEARCH_PARTNERS_REQUESTS: "@@FORMS/SEARCH_PARTNERS_REQUESTS",
  SEARCH_PARTNERS_SUCCESS: "@@FORMS/SEARCH_PARTNERS_SUCCESS",
  SEARCH_PARTNERS_ERROR: "@@FORMS/SEARCH_PARTNERS_ERROR",

  GET_LOAN_PRODUCTS_REQUESTS: "@@FORMS/GET_LOAN_PRODUCTS_REQUESTS",
  GET_LOAN_PRODUCTS_SUCCESS: "@@FORMS/GET_LOAN_PRODUCTS_SUCCESS",
  GET_LOAN_PRODUCTS_ERROR: "@@FORMS/GET_LOAN_PRODUCTS_ERROR",
  CLEAN_LOAN_PRODUCTS: "@@FORMS/CLEAN_LOAD_PRODUCTS",

  GET_LOAN_PRODUCT_CONDITIONS_REQUESTS: "@@FORMS/GET_LOAN_PRODUCT_CONDITIONS_REQUESTS",
  GET_LOAN_PRODUCT_CONDITIONS_SUCCESS: "@@FORMS/GET_LOAN_PRODUCT_CONDITIONS_SUCCESS",
  GET_LOAN_PRODUCT_CONDITIONS_ERROR: "@@FORMS/GET_LOAN_PRODUCT_CONDITIONS_ERROR",

  UPDATE_ACTIVE_ROW: "@@LABELS/UPDATE_ACTIVE_ROW",
  CLEAR_ACTIVE_ROW: "@@LABELS/CLEAR_ACTIVE_ROW",
};


export const searchPartnersRequest = createAction(
  ActionTypes.SEARCH_PARTNERS_REQUESTS,
  resolve => (data: Partner) => resolve(data)
);

export const searchPartnersSuccess = createAction(
  ActionTypes.SEARCH_PARTNERS_SUCCESS,
  resolve => (data: Partner[]) =>
    resolve(data)
);

export const searchPartnersError = createAction(
  ActionTypes.SEARCH_PARTNERS_ERROR,
  resolve => (error: any) =>
    resolve(error)
);

export const getLoanProductConditionsRequest = createAction(
  ActionTypes.GET_LOAN_PRODUCT_CONDITIONS_REQUESTS,
  resolve => (data: LoanProductsConditionsParams) => resolve(data)
);

export const getLoanProductConditionsSuccess = createAction(
  ActionTypes.GET_LOAN_PRODUCT_CONDITIONS_SUCCESS,
  resolve => (data: LoanProductConditions) =>
    resolve(data)
);

export const getLoanProductConditionsError = createAction(
  ActionTypes.GET_LOAN_PRODUCT_CONDITIONS_ERROR,
  resolve => (error: any) =>
    resolve(error)
);

export const getLoanProductsRequest = createAction(
  ActionTypes.GET_LOAN_PRODUCTS_REQUESTS,
  resolve => (data: LoanProductsParams) => resolve(data)
);

export const getLoanProductsSuccess = createAction(
  ActionTypes.GET_LOAN_PRODUCTS_SUCCESS,
  resolve => (data: LoanProducts) =>
    resolve(data)
);

export const getLoanProductsError = createAction(
  ActionTypes.GET_LOAN_PRODUCTS_ERROR,
  resolve => (error: any) =>
    resolve(error)
);
export const cleanLoanProducts = createAction(
  ActionTypes.CLEAN_LOAN_PRODUCTS,
  resolve => () => resolve()
);


export const updateActiveRow = createAction(
  ActionTypes.UPDATE_ACTIVE_ROW,
  resolve => (item: ActiveRow) =>
    resolve(item)
);

export const clearActiveRow = createAction(
  ActionTypes.CLEAR_ACTIVE_ROW,
  resolve => () =>
    resolve()
);
