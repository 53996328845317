import * as React from "react";
import styled from "styled-components";
import Button from "../../../components/Button/Button";
import { Heading } from "../../../components/Typography";
import BackLink from '../../../components/BackLink';

const Container = styled.header`
  margin: -24px -24px 0;
  padding: 14px 15px 7px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #e7eaec;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const HeaderButton = styled(Button)`
  margin-left: 5px;
`;

const ListHeader: React.SFC<{
  showBatchOperationsButton: boolean;
  selectedPaymentRequestsCount: number;
  showBatchModal: () => void;
  showBatchCheckboxesModal: () => void;
}> = React.memo(({ showBatchOperationsButton, showBatchModal, selectedPaymentRequestsCount, showBatchCheckboxesModal }) => (
  <Container>
    <BackLink title={document.title} >
      <Heading>Запросы на списание средств</Heading>
    </BackLink>
    <ButtonsWrapper>
      {showBatchOperationsButton && <HeaderButton onClick={showBatchModal} type="success">
        Исполнить все пакетно
      </HeaderButton>}
      {selectedPaymentRequestsCount > 0 && <HeaderButton onClick={showBatchCheckboxesModal} type="success">
        Исполнить пакетно ({selectedPaymentRequestsCount})
      </HeaderButton>}
    </ButtonsWrapper>
  </Container>
));

export default ListHeader;
