import * as React from 'react';
import { omit, isEmpty, isString, get } from 'lodash';
import moment from 'moment';
import FormControl from './components/FormControl';
import {
  CalculatorFormData,
  CalculatorsState,
  CalculatorPensionFormData
} from "../../../../store/calculators";
import { NONE_VALUE } from "../../../../constants";
import { convertToUtc } from "../../../../utils";
import Button from "../../../../components/Button/Button";
import ErrorMessage from "../../../../modules/Errors/components/ErrorMessage";

import currencyCodes, { CURRENCY_RUB } from "../../../../constants/currencyCodes";
import gender, { MALE_CODE } from "../../../../constants/gender";

export interface CalculatorFormProps {
  fetchCalculatorPensionDataRequest: (data: CalculatorPensionFormData) => void;
  type: string;
  loading: boolean;
}

export interface CalculateStateErrors {
  formErrors: {
    BirthDate?: string,
    CurrencyId?: string,
    Sum?: string,
  },
  formValid: boolean,
  submited: boolean,
  printing: boolean,
}

export default class PensionChildCalculatorForm extends React.PureComponent<CalculatorFormProps & CalculatorsState> {
  public state: CalculatorPensionFormData & CalculateStateErrors = {
    Gender: MALE_CODE,
    CurrencyId: CURRENCY_RUB,
    BirthDate: moment().format("YYYY-MM-DD"),
    Sum: undefined,
    isChild: this.props.type === 'child',
    formErrors: {},
    formValid: true,
    submited: false,
    printing: false,
  };

  public render() {
    const { error, type } = this.props;
    const { BirthDate, CurrencyId, Sum,formErrors, formValid, Gender } = this.state;
    return (
      <div>
        {type === 'pension' && <FormControl
          onChange={this.handleChange('Gender')}
          value={Gender}
          data={Object.entries(gender)}
          label="Пол пайщика"
          type="select"
        />}
        <FormControl
          onChange={this.handleChangeDate}
          value={BirthDate}
          label={type === 'pension' ? "Дата рождения": 'Дата рождения ребенка'}
          type="date"
        />
        <FormControl
          onChange={this.handleChange('Sum')}
          value={Sum}
          label="Сумма размещения"
          error={formErrors.Sum}
        />
        <FormControl
          onChange={this.handleChange('CurrencyId')}
          value={CurrencyId}
          data={Object.entries(currencyCodes)}
          label="Валюта"
          type="select"
        />
        <div className="d-print-none">
        <Button type="success" onClick={this.submitForm}>
          План расчета
        </Button>
        {' '}
        <Button type="success" onClick={this.printPage}>
          Печать
        </Button>
        </div>
        {(!formValid || error) && (
          <div className="alert alert-danger mt-3 d-print-none">
            <ErrorMessage errorData={{ message: (error && error.Message) || 'Ошибка', verbose: (error && error.ExceptionMessage) || 'Необходимо заполнить все поля и проверить правильность формата данных'}} />
          </div>
        )}
      </div>
    );
  };

  public componentDidUpdate(prevProps: CalculatorFormProps, prevState: CalculatorFormData & CalculateStateErrors) {
    if (this.state.printing && prevProps.loading && !this.props.loading) {
      this.setState({
        printing: false,
      });
      window.print();
    }
  }

  private handleChange = (fieldName: string) => (e: any) => {
    const { target: { value }} = e;
    this.setState({
      [fieldName]: value,
    }, () => {
      this.validateForm(fieldName);
    });
  };

  private handleChangeDate = (date: Date, event: any) => {
    if (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value)) {
      const value = date ? convertToUtc(date).toISOString() : null;

      this.setState({
        BirthDate: value === NONE_VALUE || value === "" ? null : value
      });
    }
  };

  private submitForm = () => {
    const { fetchCalculatorPensionDataRequest, type } = this.props;
    if (this.validateForm()) {
      const omitFields = ['formErrors', 'formValid', 'submited', 'printing'];
      switch (type) {
        case 'deposit':
          break;
        default:
          fetchCalculatorPensionDataRequest(omit({ ...this.state }, omitFields));
          break;
      }
      this.setState({
        submited: true,
      })
    } else {
      this.setState({
        formValid: false,
        submited: false,
      })
    }
  };

  private printPage = () => {
    const { submited } = this.state;
    if (this.validateForm()) {
      if (!submited) {
        this.submitForm();
        this.setState({
          printing: true,
        })
      } else {
        window.print();
      }
    }
  };

  private validateForm = (fieldName?: string) => {
    const omitFields = ['formErrors', 'formValid', 'submited', 'printing', 'isChild'];
    const state = fieldName ? { [fieldName]: get(this.state, `${fieldName}`)} : omit({ ...this.state }, omitFields);
    let { formErrors } = this.state;
    const re = /^[0-9]*[.,]?[0-9]+$/;
    Object.entries(state).map(([key, value]) => {
      const isDigitField = ['CurrencyId', 'Sum'].includes(key);

      if ((!value && value !== 0) || (isString(value) && isEmpty(value.trim()))) {
        formErrors = {
          ...formErrors,
          [key]: 'Пожалуйста, заполните это поле.',
        }
      } else if (isDigitField && isString(value) && !re.test(value)) {
        formErrors = {
          ...formErrors,
          [key]: 'Пожалуйста, введите число',
        }
      } else {
        formErrors = omit(formErrors, [key]);
      }
    });

    this.setState({
      formErrors,
      formValid: true,
      submited: !fieldName
    });

    return isEmpty(formErrors);
  };
}
