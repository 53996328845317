import { connect } from 'react-redux';
import { get } from 'lodash';
import BillDetailsForm from './BillDetailsForm';
import { AppState } from "../../../../store";


const mapStateToProps = (state: AppState) => ({
  PaymentDestination: get(state.billDetails, 'data.PaymentDestination', ''),
  PartnerId: get(state.billDetails, 'data.PartnerId', ''),
  Type: {
    Value: get(state.billDetails, 'data.Type.Value', 0),
    Title: get(state.billDetails, 'data.Type.Title', '')
  },
  FuncPay: {
    Value: get(state.billDetails, 'data.FuncPay.Value', 0),
    Title: get(state.billDetails, 'data.FuncPay.Title', '')
  },
  OpenDate: get(state.billDetails, 'data.OpenDate', ''),
  CloseDate: get(state.billDetails, 'data.CloseDate', ''),
  ProductName: get(state.billDetails, 'data.ProductName', ''),
  Balance: get(state.billDetails, 'data.Balance', 0),
  Percent: get(state.billDetails, 'data.Percent', 0),
  Status: {
    Value: get(state.billDetails, 'data.Status.Value', 0),
    Title: get(state.billDetails, 'data.Status.Title', '')
  },
  Period: get(state.billDetails, 'data.Period', 0),
  LoanSum: get(state.billDetails, 'data.LoanSum', 0),
  Fine: get(state.billDetails, 'data.Fine', 0),
  Member: get(state.billDetails, 'data.Member', 0),
  BillId: get(state.billDetails, 'data.BillId', 0),
  BillCode: get(state.billDetails, 'data.BillCode', ''),
  Currency: get(state.billDetails, 'data.Currency', ''),
  PartnerNumber: get(state.billDetails, 'data.PartnerNumber', ''),
  PartnerName: get(state.billDetails, 'data.PartnerName', ''),
  ClearBalance: get(state.billDetails, 'data.ClearBalance', ''),
  Uuid: get(state.billDetails, 'data.Uuid', ''),
  LatePaymentDays: get(state.billDetails, 'data.LatePaymentDays', ''),
  IsCapital: get(state.billDetails, 'data.IsCapital', false),
});

export default connect(mapStateToProps)(BillDetailsForm);
