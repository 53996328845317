import styled, { css, ThemeType } from "../../styled/styled-components";
import { ToastContainer } from "react-toastify";

const toastClassName = "styledToast";

const STYLED_TOAST_TYPES: Array<keyof ThemeType["colors"]["panel"]> = [
  "error",
  "success"
];

const StyledToastContainer = styled(ToastContainer).attrs({
  toastClassName
})`
  &&& {
    width: 400px;
    min-height: 55px;

    .${toastClassName} {
      border: 1px solid ${({ theme }) => theme.colors.lightGray};
      border-radius: 2px;

      ${STYLED_TOAST_TYPES.map(
        type => css`
          &.Toastify__toast--${type} {
            background: ${({ theme }) => theme.colors.panel[type].background};
            color: ${({ theme }) => theme.colors.panel[type].font};
            border-color: ${({ theme }) => theme.colors.panel[type].borders};

            .Toastify__close-button--${type} {
              color: inherit;
            }
          }
        `
      )}

      &.Toastify__toast--error {
        background: ${({ theme }) => theme.colors.panel.error.background};
        color: ${({ theme }) => theme.colors.panel.error.font};
        border-color: ${({ theme }) => theme.colors.panel.error.borders};

        .Toastify__close-button--error {
          color: inherit;
        }
      }

      &.Toastify__toast-body {
        max-width: 100%;
        min-width: 0;
      }
    }
  }
`;

export default StyledToastContainer;
