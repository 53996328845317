import * as React from "react";
import { Switch } from 'antd';
import { noop } from 'lodash'
import { toast } from "react-toastify";
import styled from "styled-components";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import { ClientsContext } from "../../pages/Client";
import { FormWrapper } from "../Views";
import ApiClient from "../../../../services/api";
import Link from "../../../../components/Link";


const ItemRow = styled.div`
  margin: 10px;
  display: flex;
  align-items: center;
  
  
  
  & .ant-switch {
    background-color: #dc3545 !important;
  }
  & .ant-switch-checked {
    background-color: #1ab394 !important;
  }
  & .ant-switch-disabled {
    background-color: rgba(0, 0, 0, .65) !important;
  }
`;

const StyledSwitch = styled(Switch)`
  margin-right: 10px !important;
`;

const StyledLink = styled(Link)`
  margin-left: 10px !important;
`;

export default class CheckList extends React.PureComponent {
  public render() {
    return (
      <ClientsContext.Consumer>
        {({
            checkList,
            userData
          }) => (
          <FormWrapper>
            <LoadingOverlay loading={!checkList || !userData}>
              {checkList && userData && (
                <div>
                  <ItemRow>
                    <StyledSwitch
                      onChange={noop}
                      disabled={!checkList.VideoVerificationStatus}
                      checked={checkList.VideoVerificationStatus === 1}
                    />
                    {' '}Видео подтверждает личность пайщика и его паспорт?
                    <StyledLink routerLink href={`/applications/edit/${userData.Id}#videoverification`}>Проверить видео</StyledLink>
                  </ItemRow>
                  <ItemRow>
                    <StyledSwitch
                      onChange={noop}
                      disabled={!checkList.SecurityServiceStatus}
                      checked={checkList.SecurityServiceStatus === 1}
                    />
                    {' '}Проверка службой безопасности
                    <StyledLink routerLink href={`/applications/edit/${userData.Id}#main`}>Указать результаты проверки</StyledLink>
                  </ItemRow>
                  <ItemRow>
                    <StyledSwitch
                      onChange={noop}
                      checked={checkList.IsStatementAgree}
                    />
                    {' '}Подписан ли договор на 5 шаге
                  </ItemRow>
                  <ItemRow>
                    <StyledSwitch
                      onChange={noop}
                      checked={checkList.IsEntryFeePaid}
                    />
                    {' '}Оплата вступительного и паевого взносов
                    <StyledLink onClick={this.resendRequisites(userData.Id)}>Повторно отправить реквизиты</StyledLink>
                    <StyledLink routerLink href={`/applications/edit/${userData.Id}#main`}>Указать была ли оплата</StyledLink>
                  </ItemRow>
                </div>
              )}
            </LoadingOverlay>
          </FormWrapper>
        )}
      </ClientsContext.Consumer>
    );
  }

  private resendRequisites = (clientId: number) => async () => {
    try {
      await ApiClient.resendRequisites(clientId);
      toast("Рекивизиты успешно отправлены", { type: toast.TYPE.SUCCESS });
    } catch (e) {
      toast("Произошла ошибка при отправке реквизитов", { type: toast.TYPE.ERROR });
    }
  }
}
