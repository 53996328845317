import * as React from "react";
import Helmet from "react-helmet";
import { isEmpty, get } from 'lodash';

import LoadingOverlay from "../../../../components/LoadingOverlay";
import SectionHeader from "../../../../components/SectionHeader";
import CalculatorForm from "../../components/CalculatorForm";
import TableLoan from "../../components/TableLoan";
import { CalculatorsState } from "../../../../store/calculators";

import './print.scss';

const PAGE_TITLE = "Калькулятор займов";

export interface CalculatorProps {
  fetchCalculatorDataClear: () => void;
}

export default class LoanCalculator extends React.PureComponent<CalculatorsState & CalculatorProps> {
  public render() {
    const { loading, calculatingLoanData } = this.props;
    return (
      <div>
        <Helmet defer={false}>
          <title>{PAGE_TITLE}</title>
        </Helmet>
        <SectionHeader heading={PAGE_TITLE}/>
        <LoadingOverlay loading={loading}>
          <div className="row">
            <div className="col-lg-5 mb-4">
              <CalculatorForm type="loan"/>
            </div>
            <div className="col-lg-7">
              {!isEmpty(calculatingLoanData) &&
              <TableLoan
                Overpayment={get(calculatingLoanData, 'Overpayment', 0)}
                FullCost={get(calculatingLoanData, 'FullCost', 0)}
                PlanCalendar={get(calculatingLoanData, 'PlanCalendar', {
                  Percent: 0,
                  MemberFee: 0,
                  MonthlyPayment: 0,
                  BillLoanPlanCalendarPeriods: [],
                })}
              />}
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }

  public componentWillUnmount() {
    const { fetchCalculatorDataClear } = this.props;
    fetchCalculatorDataClear();
  }
}
