import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';

import Submenu, { StyledSubmenu } from './Submenu';
import withActiveInfo from './withActiveInfo';

class Item extends Component {
  render() {
    const {
      icon,
      title,
      url,
      submenu = [],
      active,
    } = this.props;

    return (
      <StyledItem as={url ? Link : 'div'} to={url} active={active}>
        {icon ? <StyledIcon name={icon} /> : null}
        <StyledTitle>{title}</StyledTitle>
        {submenu.length > 0 ? <Submenu items={submenu} /> : null}
      </StyledItem>
    );
  }
}

const StyledItem = styled(Flex).attrs({ justifyStart: true, row: true })`
  position: relative;
  min-width: 12.5%;
  padding: 5px;
  text-align: center;

  text-decoration: none;
  color: inherit;

  border-radius: 3px;
  border: transparent 2px solid;

  background-color: ${({ active, theme }) => active ? theme.colors.lightGray : 'inherit'};

  &:hover {
    color: inherit;
    text-decoration: none;
    border-color: ${({ theme }) => theme.colors.lightGray};

    ${StyledSubmenu} {
      display: flex;
    }
  }
`;

const StyledIcon = styled.i``;
const StyledTitle = styled(FlexItem).attrs({ grow: 1 })``;

const ItemWithActiveInfo = withActiveInfo(Item);
export default ItemWithActiveInfo;
