import React, { PureComponent } from "react";
import moment from "moment";

import LoadingOverlay from "../../../../components/LoadingOverlay";
import { parseServerDate } from "../../../../utils";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import ApiClient from "../../../../services/api";
import { toast } from "react-toastify";

export interface PlannedSettlementProps {
  BillId: string,
  type: string,
}

export interface Deposit {
  Date: string;
  Percent: number;
  Member: number;
  Pit: number;
  CurrentBalance: number;
  ClearBalance: number;
}

export interface PlannedSettlementState {
  data?: Deposit;
  loading: boolean;
  error?: any;
}


export default class PlannedSettlement extends PureComponent<PlannedSettlementProps, PlannedSettlementState> {
  public state = {
    data: {
      Date: '',
      Percent: 0,
      Member: 0,
      Pit: 0,
      CurrentBalance: 0,
      ClearBalance: 0,
    },
    loading: false,
    error: undefined,
  };

  public render() {
    const { data, loading } = this.state;
    return (
      <LoadingOverlay loading={loading}>
        <div className="mt-4">
          <div className="panel-title text-center mb-4">
            <h3 className="d-inline-flex row align-items-center">
              Баланс на
              <DatePicker useStyledInput className="ml-2" onChange={this.handleChangeDate}
                          selected={data && data.Date ? parseServerDate(data.Date) : null}/>
            </h3>

          </div>
          <div className="panel-body">
            {data.Date && <table className="table table-stripped">
              <thead>
              <tr>
                <th>Дата</th>
                <th>Чистый баланс</th>
                <th>Комп.</th>
                <th>ЧВ</th>
                <th>НДФЛ</th>
                <th>Текущий баланс</th>
              </tr>
              </thead>
              <tbody>
              <tr className="text-left">
                <td>{moment(data && data.Date).format("DD.MM.YYYY")}</td>
                <td>
                  {data && (data.ClearBalance).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                <td>
                  {data && (data.Percent).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                <td>
                  {data && (data.Member).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                <td>
                  {data && (data.Pit).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                <td>
                  {data && (data.CurrentBalance).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
              </tr>
              </tbody>
            </table>}
          </div>
        </div>
      </LoadingOverlay>
    );
  }

  public componentDidMount(): void {
    this.requestData(moment().format("YYYY-MM-DD"));
  }

  private handleChangeDate = (date: Date, event: any) => {
    if (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value)) {
      this.requestData(moment(date).format("YYYY-MM-DD"));
    }
  };

  private requestData = async (date: string) => {
    const { BillId, type } = this.props;
    try {
      this.setState({
        loading: true,
      });
      let result = [];
      switch (type) {
        case 'deposit':
          result = await ApiClient.getDepositBalance(BillId, date);
          break;
        case 'raising':
          result = await ApiClient.getRaisingBalance(BillId, date);
          break;
        case 'salaries':
          result = await ApiClient.getSalariesBalance(BillId, date);
          break;
      }
      this.setState({
        data: result,
        loading: false,
        error: undefined,
      })
    } catch (e) {
      this.setState({
        loading: false,
        error: e,
      });
      const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
      toast(errorMessage, {
        type: toast.TYPE.ERROR
      });
    }
  }
}
