import React, { PureComponent } from "react";
import moment from "moment";
import { isEmpty } from "lodash";

import LoadingOverlay from "../../../../components/LoadingOverlay";
import ApiClient from "../../../../services/api";
import { toast } from "react-toastify";

export interface PlannedSettlementProps {
  BillId: string,
}

export interface SalariesPeriod {
  EndDate: string;
  Percent: number;
  Member: number;
  Pit: number;
  CurrentBalance: number;
  Payment: number;
  CurrentBalanceEnd: number;
  ClearBalanceEnd: number;
}

export interface SalariesCalendar {
  Periods: SalariesPeriod[];
}

export interface PlannedSettlementState {
  data?: SalariesCalendar;
  loading: boolean;
  error?: any;
}


export default class AccountIncome extends PureComponent<PlannedSettlementProps, PlannedSettlementState> {
  public state = {
    data: {
      Periods: [],
    },
    loading: false,
    error: undefined,
  };

  public render() {
    const { data, loading } = this.state;
    return (
      <LoadingOverlay loading={loading}>
        <div className="mt-4">
          <div className="panel-title text-center mb-4">
            <h3 className="d-inline-flex row align-items-center">
              Доход
            </h3>

          </div>
          <div className="panel-body">
            {!isEmpty(data.Periods) && <table className="table table-stripped">
              <thead>
              <tr>
                <th>Дата</th>
                <th>Комп.</th>
                <th>ЧВ</th>
                <th>НДФЛ</th>
                <th>Доход</th>
                <th>Баланс в периоде</th>
              </tr>
              </thead>
              <tbody>
              {data.Periods.map((item: SalariesPeriod) => (<tr className="text-left">
                  <td>{moment(item.EndDate).format("DD.MM.YYYY")}</td>
                  <td>
                    {this.renderValue(item.Percent)}
                  </td>
                  <td>
                    {this.renderValue(item.Member)}
                  </td>
                  <td>
                    {this.renderValue(item.Pit)}
                  </td>
                  <td>
                    {this.renderValue(item.Payment)}
                  </td>
                  <td>
                    {(item.CurrentBalanceEnd - item.ClearBalanceEnd) ? (item.CurrentBalanceEnd - item.ClearBalanceEnd).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }) : 0}
                  </td>
                </tr>))}
              </tbody>
            </table>}
          </div>
        </div>
      </LoadingOverlay>
    );
  }

  public componentDidMount(): void {
    this.requestData();
  }

  private renderValue = (value: any) => {
    if (Number(value) > 0) {
      return value.Payment ? value.Payment.toLocaleString("ru", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) : 0;
    }

    return 0;
  }

  private requestData = async () => {
    const { BillId } = this.props;
    try {
      this.setState({
        loading: true,
      });
      const result = await ApiClient.getSalariesIncome(BillId);
      this.setState({
        data: result,
        loading: false,
        error: undefined,
      })
    } catch (e) {
      this.setState({
        loading: false,
        error: e,
      });
      const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
      toast(errorMessage, {
        type: toast.TYPE.ERROR
      });
    }
  }
}
