import React from 'react';
import { isEmpty } from 'lodash';
import { CurrencyRequests, Request } from "../../../store/batchOperations";
import { CURRENCY_IDS } from "../../../constants";
import { Table, TableBody, TableRow, TableCell, TableHead, TableCellHead } from "../../../components/Table";

interface BatchOperationsComponentProps {
  batchOperations: CurrencyRequests,
}

export default class BatchOperationsTable extends React.Component<BatchOperationsComponentProps> {
  public render(){
    const { batchOperations } = this.props;
    return (
        <Table>
            <TableHead className="row">
              <TableRow className="row">
                <TableCellHead className="col-3">Id заявки</TableCellHead>
                <TableCellHead className="col-6">ФИО пайщика</TableCellHead>
                <TableCellHead className="col-3">Сумма</TableCellHead>
              </TableRow>
            </TableHead>
          <TableBody>
            {batchOperations.Requests.map((operation: Request) => (
              <TableRow key={operation.Id} className="row">
                <TableCell className="col-3">{operation.Id}</TableCell>
                <TableCell className="col-6">{operation.FullName}</TableCell>
                <TableCell className="col-3">{this.getSumInCurrency(operation.Sum)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
    );
  }

  private getSumInCurrency = (sum: number) => {
    const { batchOperations } = this.props;
    if (!batchOperations || isEmpty(batchOperations.Requests)) {

      return null;
    }
    return sum.toLocaleString('ru', {
      style: 'currency',
      currency: CURRENCY_IDS.get(batchOperations.Currency),
      minimumFractionDigits: 2
    })
  }
}