import * as React from "react";
import styled from "styled-components";
import Button, { ButtonProps } from "../../../components/Button/Button";
import { Heading } from "../../../components/Typography";
import ButtonPrint from "../../../components/PrintButton/PrintButton";
import { ID_REPLENISHMENT_OF_PERSONAL_SAVING } from "../../../constants/operationTypeCodes";
import BackLink from "../../../components/BackLink";
import Dropdown from "../../../components/Dropdown";

const Container = styled.header`
  margin: -24px -24px 0;
  padding: 14px 15px 7px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #e7eaec;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const HeaderButton = styled(Button)`
  margin-left: 5px;
`;

const HeaderDropdown = styled.div`
  margin-left: 5px;
`;

const ListHeader: React.SFC<{
  printButton?: React.ReactNode;
  getParthnersLink: string;
}> = React.memo(({ printButton, getParthnersLink }) => (
  <Container>
    <BackLink title={document.title} >
      <Heading>Клиенты</Heading>
    </BackLink>
    <ButtonsWrapper>
      <ButtonPrint href={getParthnersLink} type="success" docType="xml">
        Загрузить список пайщиков
      </ButtonPrint>
      <HeaderDropdown>
        <Dropdown caption={"Добавить ⬇︎"}>
          <div className="p-2">
            <HeaderButton href="/client/create" routerLink type="success">
              Добавить пайщика
            </HeaderButton>
          </div>
          <div className="p-2">
            <HeaderButton href="/client/create?salary=true" routerLink type="success">
              Добавить к ЗП проекту
            </HeaderButton>
          </div>
        </Dropdown>
      </HeaderDropdown>
      <HeaderButton
        href={`/Operation/OperationProcess?operationTypeId=${ID_REPLENISHMENT_OF_PERSONAL_SAVING}&returnUrl=${encodeURIComponent(document.location.pathname)}${encodeURIComponent(document.location.search)}`}
        type="success"
      >
        Совершить операцию
      </HeaderButton>
      <HeaderButton href="/Loan/CreateLoan" type="primary">
        Открыть продукт
      </HeaderButton>
      {printButton}
    </ButtonsWrapper>
  </Container>
));

export class PrintButton extends React.Component<ButtonProps> {
  public render() {
    return (
      <HeaderButton {...this.props} type="success">
        Печать
      </HeaderButton>
    );
  }
}

export default ListHeader;
