import { BillCurrentListItem, FilterFieldName, FilterItemData, SortDirection } from "../types";

export const AUTOCOMPLETE_FILTER_FIELDS = [
  "PartnerNumber"
];

export const REPORT_MONTHLY_FIELD_LABELS = new Map<keyof BillCurrentListItem, string>([
  ["PartnerFullName", "Пайщик"],
  ["ContractNumber", "Договор №"],
  ["OpeningDate", "Дата выдачи"],
  ["ClosingDate", "Дата окончания"],
  ["StartPeriodBalance", "Баланс на начало периода"],
  ["EndPeriodBalance", "Баланс на конец периода"],
  ["FuncPay", "Тип выплат"],
  ["Currency", "Валюта"],
  ["BodyBack", "Осн. Долг"],
  ["Percent", "ПС"],
  ["MemberFee", "ЧВ"],
  ["PaymentSummary", "Итого платежи"],
  ["NumberOfPayments", "Кол-во платежей"],
]);

export const DEFAULT_FILTER_FIELDS = new Map<
  FilterFieldName<BillCurrentListItem>,
  FilterItemData
  >([
  [
    "PartnerFullName",
    {
      FieldName: "PartnerFullName",
      Method: 1,
      Value: null,
      ValueType: 1
    }
  ],
  [
    "ContractNumber",
    {
      FieldName: "ContractNumber",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "OpeningDate",
    {
      FieldName: "OpeningDate",
      Value: null,
      Method: 4,
      ValueType: 4
    }
  ],

  [
    "ClosingDate",
    {
      FieldName: "ClosingDate",
      Value: null,
      Method: 4,
      ValueType: 4
    }
  ],

  [
    "StartPeriodBalance",
    {
      FieldName: "StartPeriodBalance",
      Value: null,
      Method: 4,
      ValueType: 7
    }
  ],

  [
    "EndPeriodBalance",
    {
      FieldName: "EndPeriodBalance",
      Value: null,
      Method: 4,
      ValueType: 7
    }
  ],
  [
    "FuncPay",
    {
      FieldName: "FuncPay",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  [
    "Currency",
    {
      FieldName: "Currency",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  [
    "BodyBack",
    {
      FieldName: "BodyBack",
      Value: null,
      Method: 4,
      ValueType: 7
    }
  ],
  [
    "Percent",
    {
      FieldName: "Percent",
      Value: null,
      Method: 1,
      ValueType: 2
    }
  ],
  [
    "MemberFee",
    {
      FieldName: "MemberFee",
      Value: null,
      Method: 1,
      ValueType: 2
    }
  ],
  [
    "PaymentSummary",
    {
      FieldName: "PaymentSummary",
      Value: null,
      Method: 1,
      ValueType: 2
    }
  ],
]);

export const SUB_TABLE_FIELDS = new Map<
  FilterFieldName<BillCurrentListItem>,
  FilterItemData
  >([
  [
    "FuncPay",
    {
      FieldName: "FuncPay",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  [
    "Currency",
    {
      FieldName: "Currency",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  [
    "BodyBack",
    {
      FieldName: "BodyBack",
      Value: null,
      Method: 4,
      ValueType: 7
    }
  ],
  [
    "Percent",
    {
      FieldName: "Percent",
      Value: null,
      Method: 1,
      ValueType: 2
    }
  ],
  [
    "MemberFee",
    {
      FieldName: "MemberFee",
      Value: null,
      Method: 1,
      ValueType: 2
    }
  ],
  [
    "PaymentSummary",
    {
      FieldName: "PaymentSummary",
      Value: null,
      Method: 1,
      ValueType: 2
    }
  ],
]);

export const DEFAULT_SORT_DIRECTION: SortDirection = 2;

export const DEFAULT_SORT_FIELD_NAME: FilterFieldName<BillCurrentListItem> =
  "OpeningDate";
