import React from 'react';
import { get, uniq } from 'lodash';
import { GroupAccess } from "../types";
import ApiClient from "../../../services/api";
import AccessList from "./AccessList";
import ActionNamesList from "./ActionNamesList";
import SelectedActionNamesList from "./SelectedActionNamesList";

interface AccessFormProps {
  selectedAccess: GroupAccess[];
  addNewAccess: (item: GroupAccess) => void;
  removeAccess: (item: GroupAccess) => void;
  removeAllActionsByController: (controller: string) => void;
  addAllActionsByController: (controller: string) => void;
  SecOperationId: 1 | 2;
}

interface AccessFormState {
  controllerList: string[];
  loading: boolean;
  error?: any;
  activeController: string;
  activeSelectedController: string,
}

export default class AccessForm extends React.PureComponent<AccessFormProps, AccessFormState> {
  public state: AccessFormState = {
    loading: false,
    controllerList: [],
    activeController: '',
    activeSelectedController: '',
  };

  public componentDidMount(): void {
    this.getControllerList();
  }

  public render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    const { activeController, activeSelectedController, controllerList } = this.state;
    return (
      <div className="row d-flex align-items-center">
        <AccessList
          data={controllerList}
          changeActiveController={this.changeActiveController('activeController')}
          addAllActionsByController={this.addAllActionsByController}
          activeController={activeController}
        />
        <ActionNamesList
          activeController={activeController}
          addNewAccessRule={this.addNewAccessRule}
          selectedActions={this.getSelectedActions()}
        />
        <i className="fa fa-arrow-right fa-3x mr-2" />
        <AccessList
          data={this.getSelectedControllers()}
          changeActiveController={this.changeActiveController('activeSelectedController')}
          removeAllActionsByController={this.removeAllActionsByController}
          activeController={activeSelectedController}
        />
        <SelectedActionNamesList
          selectedActions={this.getSelectedActionsByController(activeSelectedController)}
          removeAccessRule={this.removeAccessRule}
        />
      </div>
    )
  }

  private getSelectedControllers = () => {
    const { selectedAccess } = this.props;
    return uniq(selectedAccess.filter((item: GroupAccess) => item.IsActive).map((item: GroupAccess) => item.ControllerName));
  };

  private getSelectedActions = () => {
    const { selectedAccess } = this.props;
    return selectedAccess.filter((item: GroupAccess) => item.IsActive).map((item: GroupAccess) => item.ActionName);
  };

  private getSelectedActionsByController = (controller: string) => {
    const { selectedAccess } = this.props;
    return selectedAccess.filter((item: GroupAccess) => item.ControllerName === controller && item.IsActive).map((item: GroupAccess) => item.ActionName);
  };

  private getControllerList = async () => {
    this.setState({
      loading: true,
      error: undefined,
    });

    try {
      const controllerList = await ApiClient.getGroupControllerNames();
      this.setState({
        loading: false,
        controllerList
      });
    } catch (e) {
      this.setState({
        loading: false,
        error: e,
      });
    }
  };

  private changeActiveController = (type: 'activeController' | 'activeSelectedController') => (controller: string) => (e: any) => {
    const activeController = get(this.state, type);
    if (activeController === controller) {

      return;
    }
    // @ts-ignore
    this.setState({
      [type]: controller,
    })
  };


  private addAllActionsByController = (controller: string) => (e: any) => {
    e.preventDefault();
    const { addAllActionsByController } = this.props;
    this.setState({
      activeSelectedController: controller,
    });
    addAllActionsByController(controller);
  };

  private removeAllActionsByController = (controller: string) => (e: any) => {
    e.preventDefault();
    const { removeAllActionsByController } = this.props;
    this.setState({
      activeSelectedController: '',
    });
    removeAllActionsByController(controller);
  };

  private addNewAccessRule = (ActionName: string) => (e: any) => {
    const { addNewAccess, SecOperationId } = this.props;
    addNewAccess({
      ControllerName: this.state.activeController,
      ActionName,
      SecOperationId,
      IsActive: true,
    })
  };

  private removeAccessRule = (ActionName: string) => (e: any) => {
    const { removeAccess, SecOperationId } = this.props;
    removeAccess({
      ControllerName: this.state.activeSelectedController,
      ActionName,
      SecOperationId,
      IsActive: false,
    })
  }
}
