import { connect } from 'react-redux';
import MessageListBlock from './MessageListBlock';

import { AppState } from '../../../../store';
import { getActiveChatId, getAllMessages, getAllMessagesLoading } from "../../../../store/chat";

const mapStateToProps = (state: AppState) => ({
  messages: getAllMessages(state),
  loading: getAllMessagesLoading(state),
  activeChatId: getActiveChatId(state),
});

export default connect(mapStateToProps)(MessageListBlock);
