import { BillRasingsListItem, FilterFieldName, FilterItemData, SortDirection } from "../types";

export const AUTOCOMPLETE_FILTER_FIELDS = [
  "PartnerNumber"
];

export const BILL_CURRENT_FIELD_LABELS = new Map<keyof BillRasingsListItem, string>([
  ["PartnerNumber", "Номер пайщика"],
  ["PartnerName", "ФИО"],
  ["BillCode", "Номер программы"],
  ["ProductId", "Название продукта"],
  ["Percent", "ПC, %"],
  ["MemberFee", "ЧВ, %"],
  ["OpeningSum", "Сумма открытия"],
  ["CurrentBalance", "Баланс"],
  ["CurrencyId", "Валюта"],
  ["FuncPayId", "Функция начисления"],
  ["Period", "Кол-во дней"],
  ["OpeningDate", "Дата выдачи"],
  ["ClosingDate", "Дата окончания"],
  ["UseCapital", "Капиталзация"],
  ["IsActive", "Статус"]
]);

export const DEFAULT_FILTER_FIELDS = new Map<
  FilterFieldName<BillRasingsListItem>,
  FilterItemData
  >([
  [
    "PartnerNumber",
    {
      FieldName: "PartnerNumber",
      Method: 1,
      Value: null,
      ValueType: 1
    }
  ],
  [
    "PartnerName",
    {
      FieldName: "PartnerName",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "BillCode",
    {
      FieldName: "BillCode",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "ProductId",
    {
      FieldName: "ProductId",
      Value: null,
      Method: 1,
      ValueType: 5
    }
  ],
  [
    "Percent",
    {
      FieldName: "Percent",
      Value: null,
      Method: 4,
      ValueType: 2
    }
  ],
  [
    "MemberFee",
    {
      FieldName: "MemberFee",
      Value: null,
      Method: 4,
      ValueType: 2
    }
  ],
  [
    "OpeningSum",
    {
      FieldName: "OpeningSum",
      Value: null,
      Method: 4,
      ValueType: 7
    }
  ],
  [
    "CurrentBalance",
    {
      FieldName: "CurrentBalance",
      Value: null,
      Method: 4,
      ValueType: 7
    }
  ],
  [
    "CurrencyId",
    {
      FieldName: "CurrencyId",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  [
    "FuncPayId",
    {
      FieldName: "FuncPayId",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  [
    "Period",
    {
      FieldName: "Period",
      Value: null,
      Method: 4,
      ValueType: 2
    }
  ],
  [
    "OpeningDate",
    {
      FieldName: "OpeningDate",
      Value: null,
      Method: 4,
      ValueType: 4
    }
  ],
  [
    "ClosingDate",
    {
      FieldName: "ClosingDate",
      Value: null,
      Method: 4,
      ValueType: 4
    }
  ],
  [
    "UseCapital",
    {
      FieldName: "UseCapital",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  [
    "IsActive",
    {
      FieldName: "IsActive",
      Value: null,
      Method: 0,
      ValueType: 3
    }
  ]
]);

export const DEFAULT_SORT_DIRECTION: SortDirection = 2;

export const DEFAULT_SORT_FIELD_NAME: FilterFieldName<BillRasingsListItem> =
  "OpeningDate";
