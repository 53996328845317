// import moment from "moment";

export const renderValue = (fieldName: string, data: { [key: string]: any }) => {
  const value = data[fieldName];

  switch (fieldName) {
    case 'PartnerId':
      if (value && data.NumberRegion && data.NumberYear) {
        return `${data.NumberRegion}-${data.NumberYear}-${value}`;
      }
      break;

      // case "RegDate":
      // case "BirthDate":
      // case "DateOfIssue":
      //   if (value) {
      //     return moment(value).format("DD.MM.YYYY");
      //   }

    case 'IsActive':
      if (typeof value === 'number') {
        return value;
      }
      if (typeof value === 'boolean') {
        return Number(value);
      }
      break;

    case 'UseMFPercent':
    case 'UseProgressive':
    case 'UseCapital':
    case 'IsClosed':
    case 'IsMain':
      if (typeof value === 'number') {
        return value;
      }
      if (typeof value === 'boolean') {
        return Number(value);
      }
      break;

    case 'CountryId':
    case 'RegionId':
    case 'CityId':
      return Number(value || null);

    default:
      return value || '';
  }
};

export const getTableCellAlign = (fieldName: string) => {
  switch (fieldName) {
    case 'PartnerNumber':
    case 'Birthday':
    case 'PassportNumber':
    case 'InternationalPassportNumber':
    case 'Gender':
    case 'IsActive':
      return 'center';

    default:
      return 'left';
  }
};
