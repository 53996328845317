import * as React from "react";
import styled from "../../../styled/styled-components";
import { ErrorData } from "../../../store/global";

const ErrorHeading = styled.div`
  font-size: 0.9em;
  margin-bottom: 15px;
`;

const ErrorBody = styled.div`
  font-size: 0.8em;
`;

export const ErrorMessage: React.SFC<
  React.HTMLAttributes<HTMLDivElement> & { errorData: ErrorData }
> = ({ errorData }) => (
  <div>
    {errorData.message && <ErrorHeading>{errorData.message}</ErrorHeading>}
    {errorData.verbose && <ErrorBody>{errorData.verbose}</ErrorBody>}
  </div>
);

export default ErrorMessage;
