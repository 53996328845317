import * as React from "react";
import { Country, City } from "../../store/regions";
import { connect } from "react-redux";
import { AppState } from "../../store";
import { Dispatch } from "redux";
import {
  fetchCitiesRequest,
  fetchRegionsRequest
} from "../../store/regions/actions";
import Autocomplete from "../Autocomplete";
import { Nullable } from "../../types/typeUtils";

interface CitySelectorProps {
  countryId: number;
  regionId: number;
  countries: Country[];
  fetchRegions: typeof fetchRegionsRequest;
  fetchCitites: typeof fetchCitiesRequest;
  value?: number;
  onChange?: (value: Nullable<number>) => void;
  disabled?: boolean;
}

const CitySelector: React.SFC<CitySelectorProps> = props => {
  const country = props.countries.find(({ id }) => id === props.countryId);

  if (!country) {
    return null;
  }

  if (!country.regions.length) {
    props.fetchRegions(country.id);
  }

  const region = country.regions.find(({ id }) => id === props.regionId);

  if (!region) {
    return null;
  }

  if (!region.cities.length) {
    props.fetchCitites(props.regionId, country.id);
  }

  if (region) {
    const getItems = (query: string) => {
      return region.cities.filter(
        item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
      );
    };

    const selectedCity = region.cities.find(item => item.id === props.value);
    const handleChange = (item: City) => {
      if (props.onChange) {
        props.onChange(item ? item.id : null);
      }
    };

    return (
      <Autocomplete
        onSelectItem={handleChange}
        value={selectedCity}
        getItems={getItems}
        disabled={props.disabled}
      />
    );
  }

  return null;
};

const mapStateToProps = (state: AppState) => ({
  countries: state.regions.countries
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCitites: (regionId: number, countryId: number) =>
    dispatch(fetchCitiesRequest(regionId, countryId)),
  fetchRegions: (countryId: number) => dispatch(fetchRegionsRequest(countryId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CitySelector);
