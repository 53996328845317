import * as React from 'react';
import { Tooltip, Checkbox } from 'antd';
import moment from 'moment';
import styled from '../../styled/styled-components';
import { Table, TableHead, TableBody, TableRow, TableCell, TableCellHead } from '../Table';
import { IS_ACTIVE_LABELS, GENDER_LABELS, COLUMN_LABELS, CURRENCY_IDS, PRIVILEGE_LEVEL_LABELS } from '../../constants';
import Link from '../Link';
import Clickable from '../Clickable';
import SortIcon from './SortIcon';
import ColumnFilter from './ColumnFilter';
import { FilterItemData } from '../../types/PartnersAndClients';
import { FieldInfo } from '../../types';
import Label from '../Label';
import { CLIENTS_TYPE } from "../../store/labels/constants";

export interface FiltersProps<T> {
  fields: Array<FieldInfo<T>>;
  onChangeFilter: (filterItemData: FilterItemData<T>, forceFiltering?: boolean) => void;
  acceptFilter: () => void;
  resetFilter: (fieldName: keyof T) => void;
  filtersShown: boolean;

  autocompleteItems: { [fieldName in keyof T]?: string[] };
}

export interface SortProps<T> {
  onChangeSort: (fieldName: keyof T) => void;
  sortFieldName: keyof T;
  sortDirection: 1 | 2;
}

export interface BaseListItem {
  Id: number;
  HasOverdueDocuments?: boolean;
}
export interface TableListProps<T extends BaseListItem> {
  data: T[];
  visibleFields: Map<keyof T, boolean>;
  createLink?: (
    item: T,
  ) => {
    link: string;
    fieldName: keyof T;
  };
  renderActions?: (item: T) => React.ReactNode;

  selectedRows?: T[];
  unCheckAll?: () => void;
  onSelectRow?: (item: T[], checked: boolean) => void;
  showCheckboxes?: boolean;
  checkboxDisable?: (item: T) => boolean;

  filtersProps: FiltersProps<T>;
  sortProps: SortProps<T>;
  renderValue?: (fieldName: keyof T, item: T) => React.ReactNode;
  labelsSection?: string;
  entityType?: string;
  getRowStyles?: (item: T) => React.CSSProperties | undefined;
}

export interface TableListState<T extends BaseListItem> {
  columns: Array<keyof T>;
}

export function getTableCellAlign<T extends BaseListItem>(fieldName: keyof T) {
  switch (fieldName) {
    case 'Total':
      return 'right';

    case 'PartnerNumber':
    case 'Birthday':
    case 'RegistrationDate':
    case 'PassportNumber':
    case 'InternationalPassportNumber':
    case 'Gender':
    case 'IsActive':
    case 'CurrencyId':
      return 'center';

    default:
      return 'center';
  }
};

const StyledTable = styled(Table)`
  table-layout: auto;
`;

export default class TableList<T extends BaseListItem> extends React.PureComponent<
  TableListProps<T>,
  TableListState<T>
  > {
  public state = {
    columns: [...this.props.visibleFields.entries()]
      .filter(([_fieldName, visible]) => visible)
      .map(item => item[0]),
  };

  private ColumnFilter = ColumnFilter<keyof T>();

  public componentWillReceiveProps(nextProps: TableListProps<T>) {
    if (this.props.visibleFields !== nextProps.visibleFields) {
      this.setState({
        columns: [...nextProps.visibleFields.entries()]
          .filter(([_fieldName, visible]) => visible)
          .map(item => item[0]),
      });
    }
  }
  public render() {
    return (
      <div style={{ overflowX: 'auto' }}>
        <StyledTable>
          <TableHead>{this.renderHead()}</TableHead>
          <TableBody>{this.renderRows()}</TableBody>
        </StyledTable>
      </div>
    );
  }

  private renderHead = () => {
    const ConnectedColumnFilter = this.ColumnFilter;
    const { filtersProps, sortProps, entityType, showCheckboxes, selectedRows } = this.props;
    const {
      onChangeFilter,
      acceptFilter,
      filtersShown,
      autocompleteItems,
      resetFilter,
    } = filtersProps;

    return (
      <React.Fragment>
        <TableRow>
          {showCheckboxes && <TableCell />}
          {this.state.columns.map((columnName) => {
            const filterData = filtersProps.fields.find(({ FieldName }) => FieldName === columnName,);
            if (filterData) {
              return (
                <TableCell key={columnName.toString()} style={{ verticalAlign: 'bottom' }}>
                  <ConnectedColumnFilter
                    filterData={filterData}
                    onChangeFilter={onChangeFilter}
                    acceptFilter={acceptFilter}
                    filtersShown={filtersShown}
                    autocompleteItems={autocompleteItems}
                    resetFilter={resetFilter}
                    entityType={entityType}
                  />
                </TableCell>
              );
            }
          })}
        </TableRow>

        <TableRow>
          {showCheckboxes && <TableCell><Checkbox checked={selectedRows && selectedRows.length > 0} onClick={this.onCheckedAllRows}/></TableCell>}
          {this.state.columns.map((columnName) => {
            const label = COLUMN_LABELS.get(columnName.toString());

            return (
              <TableCellHead align={getTableCellAlign(columnName)} key={columnName.toString()}>
                <Clickable
                  style={{ outline: 'none' }}
                  onClick={sortProps.onChangeSort.bind(null, columnName)}
                >
                  {label || (
                    <Label
                      section={this.props.labelsSection}
                      value={columnName as string | number}
                    />
                  )}{' '}
                  {sortProps.sortFieldName === columnName && (
                    <SortIcon sortDirection={sortProps.sortDirection} />
                  )}
                </Clickable>
              </TableCellHead>
            );
          })}
          <TableCell>Действия</TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  private onCheckedAllRows = (e: any) => {
    if (e.target.checked) {
      const { data, checkboxDisable, onSelectRow } = this.props;
      let list = [ ...data ];
      if (checkboxDisable) {
        list = list.filter((item: T) => !checkboxDisable(item));
      }
      if (onSelectRow) {
        onSelectRow(list, true);
      }

    } else {
      const { unCheckAll } = this.props;
      if (unCheckAll) {
        unCheckAll();
      }
    }
  }

  private renderRows = () => {
    const { data, selectedRows } = this.props;
    const selectedIds = selectedRows ? selectedRows.map((item) => item.Id) : [];
    return data.map((item) => {
      let style = this.props.getRowStyles ? this.props.getRowStyles(item) : {};
      if (item.HasOverdueDocuments) {
        style = {
          ...style,
          backgroundColor: '#f8d7da',
        };
      }
      if (item.HasOverdueDocuments) {
        return (
          <Tooltip key={item.Id} title="Документы просрочены">
            <TableRow style={style} >
              {this.renderRowsData(item, selectedIds)}
            </TableRow>
          </Tooltip>
        );
      }
      return (
        <TableRow style={style} key={item.Id}>
          {this.renderRowsData(item, selectedIds)}
        </TableRow>
      );
    });
  };

  private renderRowsData = (item: T, selectedIds: number[]) => {
    const { showCheckboxes, checkboxDisable } = this.props;
    const itemAction = this.props.renderActions ? this.props.renderActions(item) : null;
    return (
      <React.Fragment>
        {showCheckboxes && <TableCell><Checkbox disabled={checkboxDisable ? checkboxDisable(item) : false} checked={selectedIds.includes(item.Id)} onClick={this.onCheckCheckbox(item)} /></TableCell>}
        {this.state.columns.map((columnName, index) => (
          <TableCell align={getTableCellAlign(columnName)} key={index}>
            {this.renderValue(columnName, item) || '---'}
          </TableCell>
        ))}
        {itemAction && <TableCell>{itemAction}</TableCell>}
      </React.Fragment>
    );
  };

  private onCheckCheckbox = (item: T) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const { onSelectRow } = this.props;
    if (!onSelectRow) {
      return;
    }
    // @ts-ignore
    onSelectRow([item], e.target.checked);
  };

  private renderValue = (key: keyof T, item: T) => {
    const { createLink, renderValue } = this.props;
    const customValue = renderValue ? renderValue(key, item) : null;

    if (customValue) {
      return customValue;
    }

    if (createLink) {
      const { link, fieldName } = createLink(item);

      if (key === fieldName && link) {
        return (
          <Link routerLink href={link}>
            {item[key]}
          </Link>
        );
      }
    }

    switch (key) {
      case 'Birthday':
        return moment(item[key]).format('DD.MM.YYYY');

      case 'RegistrationDate':
        return moment(item[key]).format('DD.MM.YYYY');

      case 'OpeningDate':
        return moment(item[key]).format('DD.MM.YYYY');

      case 'PowerOfAttorneyStartDate':
        return item[key] ? moment(item[key]).format('DD.MM.YYYY') : '---';

      case 'PowerOfAttorneyEndDate':
        return item[key] ? moment(item[key]).format('DD.MM.YYYY') : '---';

      case 'Gender':
        return GENDER_LABELS.get(Number(item[key]));

      case 'PrivilegeLevel':
        return PRIVILEGE_LEVEL_LABELS.get(Number(item[key]));

      case 'IsActive':
        return IS_ACTIVE_LABELS.get(Number(item[key]));

      case 'CurrencyId':
        return CURRENCY_IDS.get(Number(item[key]));

      case 'ClientType':
        return CLIENTS_TYPE.get(Number(item[key]));

      default:
        return item[key] || '---';
    }
  };
}
