import * as React from 'react';
import { SummaryType } from './';
import styled from 'styled-components';
import CurrencyLabel from '../../components/CurrencyLabel/CurrencyLabel';
import { Table, TableBody, TableRow, TableCell } from '../../components/Table';

const SummaryTableCell = styled(TableCell)`
  padding: 10px 20px;
`;

const SummaryTableCellRight = styled(SummaryTableCell)`
  text-align: right;
`;

const SummaryTableCellHead = styled(TableCell)`
  text-align: center;
  font-size: 1.2em;
`;

const Root = styled.div`
  width: 500px;
  margin: 20px 0 0 auto;
`;

export interface SummaryProps {
  data: SummaryType[];
}

const Summary: React.SFC<SummaryProps> = props => (
  <Root>
    <strong>Всего по операциям</strong>
    <Table>
      <TableBody>
        <TableRow>
          <SummaryTableCell />
          {props.data.map(({ Currency }, index) => (
            <SummaryTableCellHead key={index}>{Currency}</SummaryTableCellHead>
          ))}
        </TableRow>
        <TableRow>
          <SummaryTableCell>Пополнения</SummaryTableCell>
          {props.data.map(({ SumPayIn, Currency }, index) => (
            <SummaryTableCellRight key={index}>
              <CurrencyLabel currency={Currency}>{SumPayIn}</CurrencyLabel>
            </SummaryTableCellRight>
          ))}
        </TableRow>
        <TableRow>
          <SummaryTableCell>Снятия</SummaryTableCell>
          {props.data.map(({ SumPayOut, Currency }, index) => (
            <SummaryTableCellRight key={index}>
              <CurrencyLabel currency={Currency}>{SumPayOut}</CurrencyLabel>
            </SummaryTableCellRight>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  </Root>
);

export default Summary;
