import * as React from "react";
import styled from "../../../styled/styled-components";
import { Heading } from "../../../components/Typography";
import Button from "../../../components/Button/Button";
import BackLink from "../../../components/BackLink";

export const Form = styled.div`
  padding: 16px;
`;

export const FormLine = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
`;

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 16px;
`;

export const ListingHeader: React.SFC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => (
  <HeaderContainer {...props}>
    <BackLink title={document.title} >
      <Heading>Управление SMS-рассылками</Heading>
    </BackLink>
    <Button routerLink href="/tools/sms/create" type="success">
      Создать рассылку
    </Button>
  </HeaderContainer>
);

export const TableMessage = styled.div`
  font-size: 1.5em;
  text-align: center;
`;

export const EditingControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 15px;
  width: 550px;
`;
