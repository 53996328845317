import * as React from "react";
import styled from "../../styled/styled-components";
import Button from "../Button/Button";
import ColumnsDropdown from "./ColumnsDropdown";
import { OPERATOR_LABELS } from "../../modules/Partner/constants";
import { Radio } from "../FormControls/FormControls";
import { FilterFieldName } from "../../types/PartnersAndClients";
import { OperatorType } from "../../types";

const Root = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: baseline;
  padding: 10px 0;
`;

const OperatorsWrapper = styled.div`
  display: flex;
  align-self: center;
`;

const OperatorLabel = styled.label`
  display: inline-block;
  margin: 0 3px;
  font-size: 1.1em;

  &:first-of-type {
    margin-left: 10px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 0 0;
`;

const Item = styled.div`
  margin-right: 10px;
`;

export interface FilterConfiguratorProps<T = any> {
  fields: Map<FilterFieldName<T>, boolean>;
  handleChangeColumns: React.ChangeEventHandler<HTMLInputElement>;
  filtersShown: boolean;
  toggleFiltersVisible: () => void;
  acceptFilter: () => void;
  resetAllFilters: () => void;
  handleChangeOperator: React.ChangeEventHandler<HTMLInputElement>;
  operatorId: OperatorType;
  labels?: Map<keyof T, string>;
  labelsSection?: string;
}

export default class FilterConfigurator<T> extends React.PureComponent<
  FilterConfiguratorProps<T>
> {
  public render() {
    const {
      operatorId,
      handleChangeOperator,
      acceptFilter,
      resetAllFilters,
      handleChangeColumns,
      fields,
      toggleFiltersVisible,
      filtersShown,
      labels,
      labelsSection
    } = this.props;

    return (
      <Root>
        <Button onClick={toggleFiltersVisible}>
          {filtersShown
            ? "Скрыть расширенные фильтры"
            : "Показать расширенные фильтры"}
        </Button>
        <Item>
          <ColumnsDropdown
            fields={fields}
            handleChangeColumns={handleChangeColumns}
            labels={labels}
            labelsSection={labelsSection}
          />
        </Item>
        <Item>
          <Button onClick={resetAllFilters}>Очистить фильтры</Button>
        </Item>
        <Item>
          <Button type="primary" onClick={acceptFilter}>
            Отфильтровать
          </Button>
        </Item>
        {filtersShown && (
          <Container>
            <Item>
              <OperatorsWrapper>
                Логический оператор:{" "}
                {[...OPERATOR_LABELS.entries()].map(([id, label]) => (
                  <OperatorLabel key={id}>
                    {label}{" "}
                    <Radio
                      name="operator"
                      checked={operatorId === id}
                      value={id}
                      onChange={handleChangeOperator}
                    />
                  </OperatorLabel>
                ))}
              </OperatorsWrapper>
            </Item>
          </Container>
        )}
      </Root>
    );
  }
}
