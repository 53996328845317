export const getCookie = (name: string) => {
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const requestDataToQuery = (obj: { [key: string]: any }, prefix?: string) => {
  const buff: string[] = [];
  function getSafeValue<T extends string | number | boolean | undefined | null>(value: T) {
    if (value === null || value === undefined) {
      return '';
    }

    return value;
  }

  Object.keys(obj).forEach((key) => {
    const paramName = prefix ? `${prefix}[${encodeURIComponent(key)}]` : encodeURIComponent(key);
    const value = obj[key];

    buff.push(value !== null && typeof value === 'object'
      ? requestDataToQuery(value, paramName)
      : `${encodeURIComponent(paramName)}=${encodeURIComponent(getSafeValue(value))}`);
  });

  return buff.join('&');
};

export const saveBlob = (blob: Blob, fileName: string) => {
  const link = document.createElement('a');
  link.style.setProperty('display', 'none');
  document.body.appendChild(link);

  const url = window.URL.createObjectURL(blob);

  link.href = url;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

export const openBlob = (file: File, fileName?: string) => {
  const url = window.URL.createObjectURL(file);
  const newWindow = window.open(url, '__blank');
  if (fileName && newWindow) {
    newWindow.document.onload = () => {
      newWindow.document.title = fileName;
    };
  }
};

export const getLocalDate = (date: Date) => {
  date.setUTCHours(0);
  return date;
};

export const formatIsoString = (string: string) => {
  if (string[string.length - 1] !== 'Z') {
    return `${string}Z`;
  }

  return string;
};

export const parseServerDate = (string: string) => {

  const [datePart] = string.split('T');
  const [year, month, date] = datePart.split('-');

  const utc = Date.UTC(Number(year), Number(month) - 1, Number(date));
  return new Date(utc);
};

export const convertToUtc = (date: Date) => {
  const utc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
  return new Date(utc);
};

export const saveArrayToLocalStorage = (array: any[], name: string): void => {
  const maxArrLength = 25;
  if (array.length > maxArrLength) {
    array.splice(0, array.length - maxArrLength);
  }
  localStorage.setItem(name, JSON.stringify(array));
};

export const getArrayFromLocalStorage = (name: string): any[] => {
  const data = localStorage.getItem(name);
  if (data) {

    return JSON.parse(data);
  }

  return [];
};

export const findGetParameter = (parameterName: string) => {
  let result: string = '';
  let tmp: any[] = [];
  const items = window.location.search.substr(1).split("&");
  // tslint:disable-next-line:prefer-for-of
  for (let index = 0; index < items.length; index++) {
    tmp = items[index].split("=");
    if (tmp[0] === parameterName) {
      result = decodeURIComponent(tmp[1])
    }
  }
  return result;
};

export const delay = (duration: number) => {
  return new Promise(resolve => {
    setTimeout(() => resolve(true), duration)
  });
};
