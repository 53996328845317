import moment from 'moment';

function getModificator(dateFrom, dateTo) {
  return moment(dateTo).diff(moment(dateFrom));
}

function normalize(data, modificator) {
  return data.map(([key, value]) => [key + modificator, value]);
}

class Builder {
  setData(data) {
    this.data = data;
    return this;
  }

  setFilters(filters) {
    this.filters = filters;
    return this;
  }

  setTitle(title) {
    this.title = title;
    return this;
  }

  getCurves() {
    const {
      dateFrom = new Date(),
      dateTo = new Date(),
      // eslint-disable-next-line no-unused-vars
      currency: currencyFilter,
      period,
    } = this.filters;
    const modificator = getModificator(dateFrom, dateTo);

    const startOf = period === 'month' || period === 'currentMonth' ? 'day' : 'month';

    return this.data.reduce((result, set) => {
      if (set.Periods.length <= 0) {
        return [];
      }

      const curves = set.Periods.reduce((r, points) => {
        if (points.length <= 0) {
          return r;
        }

        const firstDate = moment(points[0].StartDate).format('MMM YYYY');
        const lastDate = moment(points[points.length - 1].EndDate).format('MMM YYYY');

        const index = Math.floor(Math.abs(moment(points[0].StartDate).diff(moment(dateFrom))) / modificator);

        if (points[0].Genders) {
          const maleChartPoints = points.map(point => point.toChartPointForGender('Male', startOf));
          const femaleChartPoints = points.map(point =>
            point.toChartPointForGender('Female', startOf));

          const normalizedMalePoints = normalize(maleChartPoints, index * modificator);
          const normalizedFemalePoints = normalize(femaleChartPoints, index * modificator);

          const maleCurve = {
            name: `${this.title}, мужчины (${set.Currency}): ${firstDate} - ${lastDate}`,
            data: normalizedMalePoints,
          };

          const femaleCurve = {
            name: `${this.title}, женщины (${set.Currency}): ${firstDate} - ${lastDate}`,
            data: normalizedFemalePoints,
          };

          r.push(maleCurve);
          r.push(femaleCurve);
          return r;
        }

        const chartPoints = points.map(point => point.toChartPoint(startOf));
        const normalizedPoints = normalize(chartPoints, index * modificator);

        const curve = {
          name: `${this.title}(${set.Currency}): ${firstDate} - ${lastDate}`,
          data: normalizedPoints,
        };

        r.push(curve);
        return r;
      }, []);
      return [...result, ...curves];
    }, []);
  }
}

export default function factory() {
  return new Builder();
}
