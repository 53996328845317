import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import autoBind from 'react-autobind';
import { Route, Switch, Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import { bindActionCreators } from 'redux';
import { createMenuItems, fetchUserRequest, getAccessSectionsRequest, hasAccessToUrl } from '../../store/auth';

import './Full.scss';

import Finmonitoring from '../../pages/Finmonitoring';
import Publications from '../../pages/Publications/Publications';
import Publication from '../../pages/Publications/Publication/Publication';
import Menu from '../../components/Menu/Menu';
import Analytics from '../../pages/Analytics/Analytics';
import OperationStaff from '../../pages/OperationStaff/index.tsx';
import Partners from '../../modules/Partner/pages/PartnerList.tsx';
import Partner from '../../modules/Partner/pages/Partner.tsx';
import ClientsList from '../../modules/Clients/pages/ClientsList.tsx';
import Client from '../../modules/Clients/pages/Client.tsx';
import BillCurrent from '../../modules/Bill/Current/pages/Bill.tsx';
import BillSalaries from '../../modules/Bill/Salaries/pages/Bill.tsx';
import BillDeposits from '../../modules/Bill/Deposits/pages/Bill.tsx';
import BillLoans from '../../modules/Bill/Loans/pages/Bill.tsx';
import BillRaisings from '../../modules/Bill/Raisings/pages/Bill.tsx';
import DepositCalculator from '../../modules/Calculators/pages/DepositCalculator';
import LoanCalculator from '../../modules/Calculators/pages/LoanCalculator';
import RaisingsCalculator from '../../modules/Calculators/pages/RaisingsCalculator';
import PensionCalculator from '../../modules/Calculators/pages/PensionCalculator';
import ChildCalculator from '../../modules/Calculators/pages/ChildCalculator';
import CreateLoanForm from '../../modules/CreateForms/pages/Loan';
import CreateDepositForm from '../../modules/CreateForms/pages/Deposit';
import CreateRaisingForm from '../../modules/CreateForms/pages/Raising';
import OperationsForm from '../../modules/CreateForms/pages/Operations';
import BatchOperations from '../../modules/CreateForms/pages/BatchOperations';
import UsersForm from '../../modules/CreateForms/pages/Users';
import BillDetail from '../../modules/BillDetails/pages/BillDetail';
import Calendar from '../../modules/Calendar/pages/index.tsx';
import SmsList from '../../modules/Sms/pages/SmsList.tsx';
import SmsDetails from '../../modules/Sms/pages/SmsDetails.tsx';
import PushList from '../../modules/Push/pages/PushList.tsx';
import PushDetails from '../../modules/Push/pages/PushDetails.tsx';
import SalaryAnalitics from '../../modules/Reports/SalaryAnalytics/pages/SalaryAnalytics';
import ReportMonthly from '../../modules/Reports/ReportMonthly/pages/ReportMonthly';
import PeriodReport from '../../modules/Reports/PeriodReport/pages/PeriodReport';
import DepositAnalytics from '../../modules/Reports/DepositAnalytics/pages/DepositAnalytics';
import DepositPeriodReport from '../../modules/Reports/DepositPeriodReport/pages/DepositPeriodReport';
import DepositReportMonthly from '../../modules/Reports/DepositReportMonthly/pages/DepositReportMonthly';
import RaisingAnalytics from '../../modules/Reports/RaisingAnalytics/pages/RaisingAnalytics';
import RaisingPeriodReport from '../../modules/Reports/RaisingPeriodReport/pages/RaisingPeriodReport';
import RaisingReportMonthly from '../../modules/Reports/RaisingReportMonthly/pages/RaisingReportMonthly';
import LoanAnalytics from '../../modules/Reports/LoanAnalytics/pages/LoanAnalytics';
import LoanPeriodReport from '../../modules/Reports/LoanPeriodReport/pages/LoanPeriodReport';
import LoanReportMonthly from '../../modules/Reports/LoanReportMonthly/pages/LoanReportMonthly';
import OverdueReports from '../../modules/Reports/OverdueReports/pages/OverdueReports';
import InspectionReports from '../../modules/Reports/InspectionReports/pages/InspectionReports';
import LatePaymentsReports from '../../modules/Reports/LatePaymentsReports/pages/LatePaymentsReports';
import SecurityUser from '../../modules/SecurityUser/pages/SecurityUser';
import SecurityGroup from '../../modules/SecurityGroup/pages/SecurityGroup';
import EditSecurityGroup from '../../modules/SecurityGroup/pages/Group';
import Audit from '../../modules/Audits/Audit/pages/Audit';
import AuditUser from '../../modules/Audits/AuditUser/pages/AuditUser';
import Products from '../../modules/Products/pages/ProductList';
import Product from '../../modules/Products/pages/Product';
import SiteCalendars from '../../modules/SiteCalendars/pages/index';
import ChangeRefinance from '../../modules/OtherActions/pages/ChangeRefinance';
import MFPercent from '../../modules/OtherActions/pages/MFPercent';
import ChatPage from '../../modules/Chat/pages/ChatPage';

import PaymentRequests from '../../modules/PaymentRequests/pages/PaymentRequests/index.tsx';
import { TokenStorage } from '../../services/TokenStorage';
import ApiClient from '../../services/api';
import AccessDenied from '../../pages/AccessDenied/AccessDenied';
import { getCountNonExecutedRequest } from '../../store/paymentRequests';
import { getUnreadCount, startPollingMessageCount } from '../../store/chat';
// import { messaging } from '../../init-fcm';

const { Header, Sider, Content } = Layout;

class Full extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      menu: { collapsed: true },
    };
  }

  componentDidMount() {
    const { fetchUserData, getAccessSections } = this.props;
    fetchUserData();
    getAccessSections();

    // try {
    //   messaging.requestPermission()
    //     .then(async () => {
    //       const token = await messaging.getToken();
    //       await ApiClient.saveTokenWebPush(token);
    //     })
    //     .catch((err) => {
    //       console.log('Unable to get permission to notify.', err);
    //     });
    //   navigator.serviceWorker.addEventListener('message', message => console.error('message', message));
    // } catch (e) {
    //
    // }
  }

  componentDidUpdate(prevProps) {
    const {
      getCountNonExecuted, canGetCountNonExecuted, startPolling, canAccessToChats,
    } = this.props;
    if (!prevProps.canGetCountNonExecuted && canGetCountNonExecuted) {
      getCountNonExecuted();
    }

    if (!prevProps.canAccessToChats, canAccessToChats) {
      startPolling();
    }
  }

  toggle() {
    this.setState({
      menu: { collapsed: !this.state.menu.collapsed },
    });
  }

  // eslint-disable-next-line class-methods-use-this,react/sort-comp
  static async logout(e) {
    e.preventDefault();
    await ApiClient.logoutUser();
    TokenStorage.clear();
    window.location.replace('/login');
  }

  smallContent() {
    return this.props.location.pathname.includes('/Bill/Details/')
      || this.props.location.pathname.includes('/Pension/Details/')
      || this.props.location.pathname.includes('/Operation/BatchOperation')
      || this.props.location.pathname.includes('/Operation/OperationProcess');
  }

  render() {
    const { collapsed } = this.state.menu;
    const { accessDenied, auth } = this.props;

    return (
      <Layout className="full">
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          className="full__sider d-print-none"
          width={220}
          collapsedWidth={70}
        >
          {collapsed ? (
            <div className="full__logo_collapsed">
              <a href="/">SM</a>
            </div>
          ) : (
            <div className="full__logo">
              <a href="/">
                <div className="full__logo-image" />
              </a>
            </div>
          )}
          <Menu
            items={this.props.menuItems}
            countPaymentRequests={this.props.countPaymentRequests}
            unreadCount={this.props.unreadCount}
            collapsed={collapsed}
          />
        </Sider>
        <Layout className="full__layout-content">
          <Header className="full__header d-print-none">
            <button className="full__trigger" onClick={this.toggle}>
              <i className="fa fa-bars" />
            </button>
            <form action="/Account/LogOff" className="navbar-right" id="logoutForm" method="post">
              <input
                name="__RequestVerificationToken"
                type="hidden"
                value="1h2bzIyh0FFqQik-KgLiKo_72SKlibkaKsmeOLjKOhrMqdYvdNp7AaGHuGN91Wq_HYIGo792-5pdXqC-j-v_Uh6EMK16on1yRcozoYYWfA84vGMZ_8Qhff5Lr2aueBsief3ul_Y7rFtGiVfhP2xZzg2"
              />
              <div className="full__logout-bar">
                <div>
                  <span className="text-muted">{auth && auth.FullName}</span>
                </div>
                <div>
                  {/* eslint-disable-next-line max-len */}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <a onClick={Full.logout}>
                    <i className="fa fa-sign-out" />
                    Выйти
                  </a>
                </div>
              </div>
            </form>
          </Header>
          {accessDenied && <AccessDenied />}
          {!accessDenied && <Content className={`full__content ${this.smallContent() ? 'full__content-small' : ''}`}>
            <Switch>
              <Route path="/PaymentRequest" component={PaymentRequests} />
              <Route path="/tools/analytics" component={Analytics} />
              <Route path="/tools/finmonitoring" name="Finmonitoring" component={Finmonitoring} />
              <Route
                path="/tools/publications/:id"
                render={({ match }) => {
                  const { id } = match.params;
                  if (
                    id === 'new' ||
                    (Number.isInteger(parseInt(id, 10)) && parseInt(id, 10) > 0)
                  ) {
                    return <Publication id={id} />;
                  }
                  return <Redirect to="/tools/publications" />;
                }}
              />
              <Route
                exact
                path="/tools/publications"
                name="Publications"
                component={Publications}
              />
              <Route
                exact
                path="/tools/OperationStaff"
                name="Publications"
                component={OperationStaff}
              />
              <Route exact path="/client" name="Partners" component={Partners} />
              <Route
                exact
                path="/client/:mode(info|edit|create)/:partnerNumber?"
                name="Partner"
                component={Partner}
              />
              <Route exact path="/applications" name="Clients" component={ClientsList} />
              <Route
                exact
                path="/applications/:mode(info|edit|create)/:clientId?"
                name="Client"
                component={Client}
              />
              <Route exact path="/tools/sms" name="Sms" component={SmsList} />
              <Route
                exact
                path="/tools/sms/:mode(create|edit)/:id(\d+)?"
                name="SmsDetails"
                component={SmsDetails}
              />
              <Route
                exact
                path="/Calculator/Deposit"
                name="DepositCalculator"
                component={DepositCalculator}
              />
              <Route
                exact
                path="/Calculator/Loan"
                name="LoanCalculator"
                component={LoanCalculator}
              />
              <Route
                exact
                path="/Calculator/Raisings"
                name="RaisingsCalculator"
                component={RaisingsCalculator}
              />
              <Route
                exact
                path="/Calculator/Pension"
                name="PensionCalculator"
                component={PensionCalculator}
              />
              <Route
                exact
                path="/Calculator/Child"
                name="ChildCalculator"
                component={ChildCalculator}
              />
              <Route
                exact
                path="/Loan/CreateLoan/:partnerNumber?"
                name="CreateLoanForm"
                component={CreateLoanForm}
              />
              <Route
                exact
                path="/Deposit/CreateDeposit/:partnerNumber?"
                name="CreateLoanForm"
                component={CreateDepositForm}
              />
              <Route
                exact
                path="/Raising/CreateRising/:partnerNumber?"
                name="CreateRaisingForm"
                component={CreateRaisingForm}
              />
              <Route
                exact
                path="/Bill/Currents"
                name="BillCurrent"
                component={BillCurrent}
              />
              <Route
                exact
                path="/Bill/Salaries"
                name="BillSalaries"
                component={BillSalaries}
              />
              <Route
                exact
                path="/Bill/Deposits"
                name="BillDeposits"
                component={BillDeposits}
              />
              <Route
                exact
                path="/Bill/Loans"
                name="BillLoans"
                component={BillLoans}
              />
              <Route
                exact
                path="/Bill/Raisings"
                name="BillRaisings"
                component={BillRaisings}
              />
              <Route
                exact
                path="/Bill/Details/:code"
                name="BillDetail"
                component={BillDetail}
              />
              <Route
                exact
                path="/Bill/Details"
                name="BillDetailOld"
                component={BillDetail}
              />
              <Route
                exact
                path="/Reports/Calendar"
                name="Calendar"
                component={Calendar}
              />
              <Route exact path="/tools/push" name="Push" component={PushList} />
              <Route
                exact
                path="/tools/push/:mode(create|edit)/:id(\d+)?"
                name="PushDetails"
                component={PushDetails}
              />
              <Route
                exact
                path="/Reports/SalaryAnalytics"
                name="SalaryAnalitics"
                component={SalaryAnalitics}
              />
              <Route
                exact
                path="/Reports/SalaryAnalytics/ReportMonthly"
                name="ReportMonthly"
                component={ReportMonthly}
              />
              <Route
                exact
                path="/Reports/SalaryAnalytics/PeriodReport"
                name="PeriodReport"
                component={PeriodReport}
              />
              <Route
                exact
                path="/Reports/DepositAnalytics"
                name="DepositAnalytics"
                component={DepositAnalytics}
              />
              <Route
                exact
                path="/Reports/DepositAnalytics/PeriodReport"
                name="DepositPeriodReport"
                component={DepositPeriodReport}
              />
              <Route
                exact
                path="/Reports/DepositAnalytics/ReportMonthly"
                name="DepositReportMonthly"
                component={DepositReportMonthly}
              />
              <Route
                exact
                path="/Reports/RaisingAnalytics"
                name="RaisingAnalytics"
                component={RaisingAnalytics}
              />
              <Route
                exact
                path="/Reports/RaisingAnalytics/PeriodReport"
                name="RaisingPeriodReport"
                component={RaisingPeriodReport}
              />
              <Route
                exact
                path="/Reports/RaisingAnalytics/ReportMonthly"
                name="RaisingReportMonthly"
                component={RaisingReportMonthly}
              />
              <Route
                exact
                path="/Reports/LoanAnalytics"
                name="LoanAnalytics"
                component={LoanAnalytics}
              />
              <Route
                exact
                path="/Reports/LoanAnalytics/PeriodReport"
                name="LoanPeriodReport"
                component={LoanPeriodReport}
              />
              <Route
                exact
                path="/Reports/LoanAnalytics/ReportMonthly"
                name="LoanReportMonthly"
                component={LoanReportMonthly}
              />
              <Route
                exact
                path="/Reports/LoanAutoPayReports"
                name="OverdueReports"
                component={OverdueReports}
              />
              <Route
                exact
                path="/Reports/LoanCheckBalanceReports"
                name="InspectionReports"
                component={InspectionReports}
              />
              <Route
                exact
                path="/Reports/LoanLates"
                name="LatePaymentsReports"
                component={LatePaymentsReports}
              />
              <Route
                exact
                path="/Operation/OperationProcess"
                name="OperationsForm"
                component={OperationsForm}
              />
              <Route
                exact
                path="/Operation/BatchOperation/:currency"
                name="BatchOperation"
                component={BatchOperations}
              />
              <Route
                exact
                path="/SecurityUser/List"
                name="SecurityUser"
                component={SecurityUser}
              />
              <Route
                exact
                path="/SecurityGroup/List"
                name="SecurityGroup"
                component={SecurityGroup}
              />
              <Route
                exact
                path="/SecurityGroup/:mode(CreateSecurityGroup|EditSecurityGroup)/:groupId?"
                name="EditSecurityGroup"
                component={EditSecurityGroup}
              />
              <Route
                exact
                path="/SecurityUser/:mode(CreateSecurityUser|EditSecurityUser)/:userId?"
                name="UsersForm"
                component={UsersForm}
              />
              <Route exact path="/Product" name="Product" component={Products} />
              <Route
                exact
                path="/Product/:mode(info|edit|create|copy)/:productId?"
                name="Product"
                component={Product}
              />
              <Route
                exact
                path="/Audit"
                name="Audit"
                component={Audit}
              />
              <Route
                exact
                path="/Audit/:userId"
                name="AuditUser"
                component={AuditUser}
              />
              <Route
                exact
                path="/tools/calendars"
                name="SiteCalendars"
                component={SiteCalendars}
              />
              <Route
                exact
                path="/OtherAction/ChangeRefinance"
                name="ChangeRefinance"
                component={ChangeRefinance}
              />
              <Route
                exact
                path="/OtherAction/MFPercent"
                name="MFPercent"
                component={MFPercent}
              />
              <Route
                exact
                path="/chats/:id(\d+)?"
                name="ChatPage"
                component={ChatPage}
              />
              <Redirect to="/client" />
            </Switch>
          </Content>}
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth.data,
  accessDenied: state.auth.accessDenied,
  countPaymentRequests: state.paymentRequests.countNonExecutedOperations,
  unreadCount: getUnreadCount(state),
  menuItems: createMenuItems(state),
  canGetCountNonExecuted: hasAccessToUrl(state, '/PaymentRequest'),
  canAccessToChats: hasAccessToUrl(state, '/chats'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUserData: fetchUserRequest,
      getAccessSections: getAccessSectionsRequest,
      getCountNonExecuted: getCountNonExecutedRequest,
      startPolling: startPollingMessageCount,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Full);
