import { createAction } from "typesafe-actions";
import { MFPercent, RefinanceRate } from "./types";

const prefix='OTHER_ACTIONS';
export const ActionTypes = {
    GET_ALL_CHANGE_REFINANCE_REQUEST: `@@${prefix}/GET_ALL_CHANGE_REFINANCE_REQUEST`,
    GET_ALL_CHANGE_REFINANCE_SUCCESS: `@@${prefix}/GET_ALL_CHANGE_REFINANCE_SUCCESS`,
    GET_ALL_CHANGE_REFINANCE_ERROR: `@@${prefix}/GET_ALL_CHANGE_REFINANCE_ERROR`,

    SAVE_ALL_CHANGE_REFINANCE_REQUEST: `@@${prefix}/SAVE_ALL_CHANGE_REFINANCE_REQUEST`,
    SAVE_ALL_CHANGE_REFINANCE_SUCCESS: `@@${prefix}/SAVE_ALL_CHANGE_REFINANCE_SUCCESS`,
    SAVE_ALL_CHANGE_REFINANCE_ERROR: `@@${prefix}/SAVE_ALL_CHANGE_REFINANCE_ERROR`,

    GET_ALL_MF_PRECENT_REQUEST: `@@${prefix}/GET_ALL_MF_PRECENT_REQUEST`,
    GET_ALL_MF_PRECENT_SUCCESS: `@@${prefix}/GET_ALL_MF_PRECENT_SUCCESS`,
    GET_ALL_MF_PRECENT_ERROR: `@@${prefix}/GET_ALL_MF_PRECENT_ERROR`,

    SAVE_MF_PERCENT_REQUEST: `@@${prefix}/SAVE_MF_PERCENT_REQUEST`,
    SAVE_MF_PERCENT_SUCCESS: `@@${prefix}/SAVE_MF_PERCENT_SUCCESS`,
    SAVE_MF_PERCENT_ERROR: `@@${prefix}/SAVE_MF_PERCENT_ERROR`,
};

//CHANGE REFINANCE
export const getAllChangeRefinanceRequest = createAction(
    ActionTypes.GET_ALL_CHANGE_REFINANCE_REQUEST
);

export const getAllChangeRefinanceSuccess = createAction(
  ActionTypes.GET_ALL_CHANGE_REFINANCE_SUCCESS,
  resolve => (data: RefinanceRate[]) =>
    resolve(data)
);

export const getAllChangeRefinanceError = createAction(
  ActionTypes.GET_ALL_CHANGE_REFINANCE_ERROR,
  resolve => (error: any) =>
    resolve(error)
);

export const saveAllChangeRefinanceRequest = createAction(
  ActionTypes.SAVE_ALL_CHANGE_REFINANCE_REQUEST,
  resolve => (data: RefinanceRate[]) =>
    resolve(data)
);

export const saveAllChangeRefinanceSuccess = createAction(
  ActionTypes.SAVE_ALL_CHANGE_REFINANCE_SUCCESS,
  resolve => (data: RefinanceRate[]) =>
    resolve(data)
);

export const saveAllChangeRefinanceError = createAction(
  ActionTypes.SAVE_ALL_CHANGE_REFINANCE_ERROR,
  resolve => (error: any) =>
    resolve(error)
);

//MF Percent
export const getAllMFPercentRequest = createAction(
  ActionTypes.GET_ALL_MF_PRECENT_REQUEST
);

export const getAllMFPercentSuccess = createAction(
  ActionTypes.GET_ALL_MF_PRECENT_SUCCESS,
  resolve => (data: MFPercent[]) =>
    resolve(data)
);

export const getAllMFPercentError = createAction(
  ActionTypes.GET_ALL_MF_PRECENT_ERROR,
  resolve => (error: any) =>
    resolve(error)
);

export const saveMFPercentRequest = createAction(
  ActionTypes.SAVE_MF_PERCENT_REQUEST,
  resolve => (data: MFPercent[]) =>
    resolve(data)
);

export const saveMFPercentSuccess = createAction(
  ActionTypes.SAVE_MF_PERCENT_SUCCESS,
  resolve => (data: MFPercent[]) =>
    resolve(data)
);

export const saveMFPercentError = createAction(
  ActionTypes.SAVE_MF_PERCENT_ERROR,
  resolve => (error: any) =>
    resolve(error)
);
