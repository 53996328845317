import React, { PureComponent } from "react";
import moment from "moment";

import LoadingOverlay from "../../../../components/LoadingOverlay";
import ApiClient from "../../../../services/api";
import { toast } from "react-toastify";

export interface PlannedSettlementProps {
  BillId: string,
  BillCode: string,
  type: string,
}

export interface Condition {
  Date: string;
  Member: number;
  Operator: string;
  Percent: number;
  Product: string;
}

export interface BillCondition {
  BillCode: string;
  BillId: number;
  Conditions?: Condition[];
}

export interface DepositConditionChangesState {
  data: BillCondition;
  loading: boolean;
  error?: any;
}


export default class DepositConditionChanges extends PureComponent<PlannedSettlementProps, DepositConditionChangesState> {
  public state = {
    data: {
      BillId: 0,
      BillCode: '',
      Conditions: [{
        Date: '',
        Member: 0,
        Operator: '',
        Percent: 0,
        Product: '',
      }],
    },
    loading: false,
    error: undefined,
  };

  public render() {
    const { data, loading } = this.state;
    return (
      <LoadingOverlay loading={loading}>
        <div className="mt-4">
          <div className="panel-title text-center mb-4">
            <h3 className="d-inline-flex row align-items-center">
              Изменения условий
            </h3>

          </div>
          <div className="panel-body">
            {data.Conditions && <table className="table table-stripped">
              <thead>
              <tr>
                <th>Дата начала условий</th>
                <th>Применяемая ставка</th>
                <th>Применяемые ЧВ</th>
                <th>Применяемый продукт</th>
                <th>Оператор</th>
              </tr>
              </thead>
              <tbody>
              {data.Conditions.map((item: Condition) => (<tr className="text-left">
                <td>{moment(item.Date).format("DD.MM.YYYY")}</td>
                <td>
                  {item.Percent.toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                <td>
                  {item.Member.toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                <td>
                  {item.Product}
                </td>
                <td>
                  {item.Operator}
                </td>
              </tr>))}
              </tbody>
            </table>}
          </div>
        </div>
      </LoadingOverlay>
    );
  }

  public componentDidMount(): void {
    this.requestData();
  }

  private requestData = async () => {
    const { BillCode } = this.props;
    try {
      this.setState({
        loading: true,
      });
      const result = await ApiClient.getBillConditions(BillCode);
      this.setState({
        data: result,
        loading: false,
        error: undefined,
      })
    } catch (e) {
      this.setState({
        loading: false,
        error: e,
      });
      const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
      toast(errorMessage, {
        type: toast.TYPE.ERROR
      });
    }
  }
}
