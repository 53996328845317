import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import SendMessegeBlock from './SendMessegeBlock';
import { AppState } from "../../../../store";
import { getAuthData } from "../../../../store/auth";
import { getActiveChatId, addChatMessage, getReciverId, readChatMessages, getActiveChat } from "../../../../store/chat";

const mapStateToProps = (state: AppState) => ({
  activeChatId: getActiveChatId(state),
  reciverId: getReciverId(state),
  sender: getAuthData(state),
  activeChat: getActiveChat(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addChatMessage,
      readChatMessages
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SendMessegeBlock);
