import * as React from "react";
import { Country } from "../../store/regions";
import { connect } from "react-redux";
import { AppState } from "../../store";
import Autocomplete from "../Autocomplete";
import { Nullable } from "../../types/typeUtils";

interface CountriesSelector {
  countries: Country[];
  value?: number;
  onChange?: (value: Nullable<number>) => void;
  disabled?: boolean;
}

const CountriesSelector: React.SFC<CountriesSelector> = props => {
  const getItems = (query: string) =>
    props.countries.filter(
      item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
    );

  const selectedCountry = props.countries.find(item => item.id === props.value);

  const handleChange = (item: Country) => {
    if (props.onChange) {
      props.onChange(item ? item.id : null);
    }
  };

  return (
    <Autocomplete
      onSelectItem={handleChange}
      value={selectedCountry}
      getItems={getItems}
      disabled={props.disabled}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  countries: state.regions.countries
});

export default connect(mapStateToProps)(CountriesSelector);
