import React, { Component } from 'react';
import Helmet from "react-helmet";
import { RouteComponentProps } from "react-router";
import { toString, toNumber } from 'lodash';
import qs from 'query-string';

import LoadingOverlay from "../../../../components/LoadingOverlay";
import SectionHeader from "../../../../components/SectionHeader";
import OperationsForm from '../../components/OperationsForm';

export default class Operations extends Component<RouteComponentProps> {
  public render() {
    const data = qs.parse(location.search);
    const partnerNumber = data.partnerNumber ? toString(data.partnerNumber) : undefined;
    const billCode = data.billCode ? toString(data.billCode) : undefined;
    const Direction = data.direction ? data.direction === 'true' : undefined;
    const Sum = data.total ? toNumber(data.total) : undefined;
    const PaymentType = data.type ? toNumber(data.type) : undefined;
    const RequestId = data.requestId ? toNumber(data.requestId) : undefined;
    const OperationTypeId = data.operationTypeId ? toNumber(data.operationTypeId) : undefined;
    return (
      <div>
        <Helmet defer={false}>
          <title>{this.getHeading()}</title>
        </Helmet>
        <SectionHeader
          heading={this.getHeading()}
        />
        <LoadingOverlay loading={false}>
          <div className="row">
            <div className="col-md-10">
              <OperationsForm
                partnerNumber={partnerNumber}
                billCode={billCode}
                Direction={Direction}
                Sum={Sum}
                PaymentType={PaymentType}
                RequestId={RequestId}
                OperationTypeId={OperationTypeId}
                goToBack={this.goToBack}
                successCreate={this.successCreate}
              />
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }

  private getHeading = () => {
    return 'Операции';
  };

  private goToBack = () => {
    const data = qs.parse(location.search);
    const returnUrl = data.returnUrl ? toString(data.returnUrl) : undefined;
    if (returnUrl) {
      this.props.history.replace(returnUrl)
    } else {
      this.props.history.goBack();
    }
  };

  private successCreate = () => {
    const data = qs.parse(location.search);
    const returnUrl = data.returnUrl ? toString(data.returnUrl) : undefined;
    if (returnUrl) {
      this.props.history.replace(returnUrl)
    } else {
      // this.props.history.goBack();
      this.props.history.replace('/PaymentRequest');
    }
  };

}
