import React, { PureComponent } from "react";
import { ifProp } from "styled-tools";

import LoadingOverlay from "../../../components/LoadingOverlay";
import styled, { css } from "../../../styled/styled-components";
import { AccessWrapper, ButtonRemove, ButtonAdd  } from "../styled/styled";

interface RowProps {
  active: boolean;
}

const Row = styled.div<RowProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  ${ifProp("hover", css`
      &:hover {
         background-color: #d8eef9;
      }
  `)}
   ${
  ifProp("active", css`
      background-color: #1c84c6 !important;
      color: #FFFFFF !important;
      a {
        color: #FFFFFF !important;
      }
  `)}
`;

export interface AccessListProps {
  data: string[];
  activeController: string;
  changeActiveController: (controller: string) => (e: any) => void;
  addAllActionsByController?: (controller: string) => (e: any) => void;
  removeAllActionsByController?: (controller: string) => (e: any) => void;
}


export default class AccessList extends PureComponent<AccessListProps> {
  public render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    const { data, changeActiveController, activeController, addAllActionsByController, removeAllActionsByController } = this.props;
    return (
      <LoadingOverlay loading={false}>
        <AccessWrapper>
          {data.map((item: string) => (
            <Row active={activeController === item} key={item} onClick={changeActiveController(item)} className="row no-gutters">
              <div className="col-3 py-2 pl-2">
                {removeAllActionsByController && <ButtonRemove onClick={removeAllActionsByController(item)}>X</ButtonRemove>}
                {addAllActionsByController && <ButtonAdd onClick={addAllActionsByController(item)}>+</ButtonAdd>}
              </div>
              <div className="col-9">
                {item}
              </div>
            </Row>))}
        </AccessWrapper>
      </LoadingOverlay>
    );
  }

}
