import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import moment from 'moment';
import styled from '../../../../styled/styled-components';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import TableList from '../../../../components/PartnersAndClients/TableList';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import Label from '../../../../components/Label';
import Link from '../../../../components/Link';
import ConfirmModal from '../../../../components/PartnersAndClients/ConfirmModal';
import FilterConfigurator from '../../../../components/PartnersAndClients/FilterConfigurator';
import Pagination from '../../../../components/Pagination/Pagination';
import RequisitesModal from '../../components/RequisitesModal';
import ListHeader from "../../components/ListHeader";
import {CURRENCY_EUR, CURRENCY_RUB, CURRENCY_USD} from "../../../../constants/currencyCodes";
import { PAYMENT_REQUEST_TYPE_LABELS } from '../../constants';
import { AppState } from '../../../../store';
import { CurrencyId, getOperationsByCurrencyRequest, haveBatchOperations } from "../../../../store/batchOperations";
import { fetchLabelsRequest } from '../../../../store/labels/actions';
import {
  PaymentRequestData,
  PaymentRequest,
  changeFilters,
  resetAllFilters,
  resetFilter,
  changePage,
  execRequest,
  downloadPdf, changePageSize
} from '../../../../store/paymentRequests';
import {
  fetchListRequest,
  downloadDocx,
  deleteRequest,
  changeListRequestData
} from '../../../../store/paymentRequests';
import { FieldInfo } from '../../../../types';
import BatchOperationsModal from "../../components/BatchOperationsModal";
import Select from "../../../../components/Select";
import BatchOperationsCheckboxesModal from "../../components/BatchOperationsCheckboxesModal";

export interface PaymentRequestsProps {
  loading: boolean;
  haveBatchOperations: boolean;
  data: PaymentRequest[];
  requestData: PaymentRequestData;
  fetchData: () => typeof fetchListRequest;
  resetAllFilters: () => typeof resetAllFilters;
  fetchLabels: typeof fetchLabelsRequest;
  labels: { [section: string]: Map<string | number | symbol, string> };
  downloadPaymentRequest: typeof downloadDocx;
  printPaymentRequest: typeof downloadPdf;
  deletePaymentRequest: typeof deleteRequest;
  changeRequestData: typeof changeListRequestData;
  changeFilters: typeof changeFilters;
  resetFilter: typeof resetFilter;
  changePage: typeof changePage;
  changePageSize: typeof changePageSize;
  execRequest: typeof execRequest;
  getOperationsByCurrencyRequest: typeof getOperationsByCurrencyRequest;
}

export interface PaymentRequestsState {
  visibleFields: Map<keyof PaymentRequest, boolean>;
  filtersShown: boolean;
  autocompleteItems: { [fieldName in keyof PaymentRequest]?: string[] };
  showRequisitesForId: number | null;
  showBatchModal: boolean;
  showBatchCheckBoxesModal: boolean;
  selectedPaymentRequests: PaymentRequest[];
}

const Footer = styled.div`
  position: relative;
`;

const PageSizeSelect = styled(Select)`
  position: absolute !important;
  right: 0;
  top: 15px;
`;

const StatusErrorValue = styled.span`
  color: ${({ theme }) => theme.colors.errorRed};
`;

const StatusSuccessValue = styled.span`
  color: ${({ theme }) => theme.colors.successGreen};
`;

class PaymentRequests extends React.PureComponent<PaymentRequestsProps,
  PaymentRequestsState> {
  public state = {
    visibleFields: new Map<keyof PaymentRequest, boolean>([
      ['Id', true],
      ['Date', true],
      ['PartnerName', true],
      ['BillCode', false],
      ['IsRequisites', true],
      ['Total', true],
      ['CurrencyId', true],
      ['Type', true],
      ['Status', true],
      ['Operator', true]
    ]),
    filtersShown: false,
    autocompleteItems: {},
    showRequisitesForId: null,
    showBatchModal: false,
    showBatchCheckBoxesModal: false,
    selectedPaymentRequests: [],
  };

  private confirmDeleteModal = React.createRef<ConfirmModal>();

  private deleteId: number | null = null;

  public componentDidMount() {
    this.props.fetchData();

    if (!this.props.labels.CURRENCY) {
      this.props.fetchLabels('CURRENCY');
    }
    this.props.getOperationsByCurrencyRequest(CURRENCY_RUB);
    this.props.getOperationsByCurrencyRequest(CURRENCY_USD);
    this.props.getOperationsByCurrencyRequest(CURRENCY_EUR);
  }

  public render() {
    const {
      showRequisitesForId,
      showBatchModal,
      showBatchCheckBoxesModal
    } = this.state;

    const { haveBatchOperations: showBatchOperationsButton } = this.props;
    return (
      <LoadingOverlay loading={this.props.loading}>
        <Helmet
          title="Запросы на списание средств"
        />
        <ListHeader
          showBatchOperationsButton={showBatchOperationsButton}
          showBatchModal={this.showBatchModal}
          selectedPaymentRequestsCount={this.state.selectedPaymentRequests.length}
          showBatchCheckboxesModal={this.showBatchCheckBoxesModal}
        />
        <FilterConfigurator
          labelsSection="PAYMENT_REQUESTS_LIST_COLUMNS"
          fields={this.state.visibleFields}
          handleChangeColumns={this.handleChangeColumns}
          filtersShown={this.state.filtersShown}
          toggleFiltersVisible={this.toggleFiltersVisible}
          handleChangeOperator={this.handleChangeOperator}
          operatorId={this.props.requestData.Operator}
          resetAllFilters={this.props.resetAllFilters}
          acceptFilter={this.props.fetchData}
        />
        <TableList
          data={this.props.data}
          visibleFields={this.state.visibleFields}
          sortProps={{
            sortFieldName: this.props.requestData.SortFieldName,
            sortDirection: this.props.requestData.SortDirection,
            onChangeSort: this.handleChangeSort
          }}
          filtersProps={{
            fields: this.props.requestData.FieldInfos,
            onChangeFilter: this.handleChangeFilter,
            acceptFilter: this.props.fetchData,
            resetFilter: this.props.resetFilter,
            filtersShown: this.state.filtersShown,
            autocompleteItems: this.state.autocompleteItems
          }}
          showCheckboxes
          unCheckAll={this.unCheckAll}
          onSelectRow={this.onSelectRow}
          selectedRows={this.state.selectedPaymentRequests}
          checkboxDisable={this.paymentIsRequired}
          renderValue={this.renderValue}
          labelsSection="PAYMENT_REQUESTS_LIST_COLUMNS"
          renderActions={this.renderActions}
          entityType="PAYMENT_REQUESTS"
          getRowStyles={this.getTableRowStyles}
        />
        {Math.ceil(this.props.requestData.TotalPages) > 1 && (
          <Footer>
            <Pagination
              size={this.props.requestData.PageSize}
              count={this.props.requestData.TotalPages * this.props.requestData.PageSize}
              page={this.props.requestData.CurrentPage}
              callback={this.handleChangePage}
            />
            <PageSizeSelect
              selectProps={{
                value: this.props.requestData.PageSize,
                onChange: this.handleChangePageSize,
              }}
              items={[
                {
                  key: 10,
                  label: '10',
                },
                {
                  key: 25,
                  label: '25',
                },
                {
                  key: 50,
                  label: '50',
                },
                {
                  key: 100,
                  label: '100',
                },
              ]}
            />
          </Footer>
        )}
        <ConfirmModal
          onConfirm={this.deletePaymentRequest}
          onCancel={this.handleCloseConfirmModal}
          message="Вы уверены, что хотите отклонить заявку? Средства будут возвращены на счёт."
          ref={this.confirmDeleteModal}
        />
        <RequisitesModal
          isOpen={!!showRequisitesForId}
          onClose={this.hideRequisites}
          id={showRequisitesForId}
          requisites={this.getRequisitesById(showRequisitesForId)}
        />
        <BatchOperationsModal
          isOpen={showBatchModal}
          onClose={this.hideBatchModal}
        />
        <BatchOperationsCheckboxesModal
          isOpen={showBatchCheckBoxesModal}
          onClose={this.hideBatchCheckBoxesModal}
         paymentRequests={this.state.selectedPaymentRequests}
        />
      </LoadingOverlay>
    );
  }

  private handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const PageSize = event.target.value;
    this.props.changePageSize(Number(PageSize));
  };

  private onSelectRow = (paymentRequest: PaymentRequest[], checked: boolean) => {
    const { selectedPaymentRequests } = this.state;

    let data: PaymentRequest[] = [... selectedPaymentRequests];
    paymentRequest.forEach((request: PaymentRequest) => {
      data = data.filter((item: PaymentRequest) => item.Id !== request.Id)
    });

    if (checked) {
      data = [...data, ...paymentRequest];
    }

    this.setState({
      selectedPaymentRequests: data
    });
  };

  private paymentIsRequired = (item: PaymentRequest) => item.Status !== 0;

  private unCheckAll = () => this.setState({ selectedPaymentRequests: [] })

  private getRequisitesById = (id: number | null) => {
    const result = this.props.data.find(item => item.Id === id);
    if (result) {
      return result.Requisites;
    }
    return null;
  };

  private renderValue = (
    fieldName: keyof PaymentRequest,
    item: PaymentRequest
  ) => {
    switch (fieldName) {
      case 'Id':
        return item[fieldName] ? (
          <Link onClick={this.props.printPaymentRequest.bind(null, item.Id)}>
            {item[fieldName]}
          </Link>
        ) : (
          '---'
        );

      case 'CurrencyId':
        return this.props.labels.CURRENCY
          ? this.props.labels.CURRENCY.get(item[fieldName]) || '---'
          : '---';

      case 'Date':
        return item[fieldName]
          ? moment(item[fieldName]).format(DatePicker.prettyDateTimeFormat)
          : '---';

      case 'Status':
        if (item[fieldName] === 1) {
          return (
            <StatusSuccessValue>
              <Label
                value={item[fieldName]}
                section="PAYMENT_REQUESTS_STATUS"
              />
            </StatusSuccessValue>
          );
        }

        return (
          <StatusErrorValue>
            <Label value={item[fieldName]} section="PAYMENT_REQUESTS_STATUS" />
          </StatusErrorValue>
        );

      case 'Total':
        return item[fieldName].toLocaleString();

      case 'Type':
        return (
          <Label value={item[fieldName]} section="PAYMENT_REQUEST_BILL_TYPE" />
        );

      case 'BillCode':
        return item[fieldName] ? (
          <Link href={`/Bill/Details/${item[fieldName]}`}>
            {item[fieldName]}
          </Link>
        ) : (
          '---'
        );

      case 'PartnerName':
        return item[fieldName] ? (
          <Link routerLink href={`/client/info/${item.PartnerNumber}`}>
            {item[fieldName]}
          </Link>
        ) : (
          '---'
        );
      case 'IsRequisites':
        return item[fieldName]
          ? (
            <div>
              {PAYMENT_REQUEST_TYPE_LABELS.get('true')}
              <Link onClick={this.showRequisitesFor(item.Id)}>посмотреть реквизиты</Link>
            </div>
          )
          : PAYMENT_REQUEST_TYPE_LABELS.get('false');
    }
  };

  private showRequisitesFor = (id: number) => () => {
    this.setState({ showRequisitesForId: id });
  };

  private hideRequisites = () => {
    this.setState({ showRequisitesForId: null });
  };

  private showBatchModal = () => {
    this.setState({ showBatchModal: true });
  };

  private hideBatchModal = () => {
    this.setState({ showBatchModal: false });
  };

  private showBatchCheckBoxesModal = () => {
    this.setState({ showBatchCheckBoxesModal: true });
  };

  private hideBatchCheckBoxesModal = (currencyId?: CurrencyId) => {
    this.setState({ showBatchCheckBoxesModal: false });

    if (currencyId) {
      this.props.fetchData();
      const { selectedPaymentRequests } = this.state;
      const data = [ ...selectedPaymentRequests ];

      this.setState({
        selectedPaymentRequests: data.filter((item: PaymentRequest) => item.CurrencyId !== currencyId),
      })
    }
  };

  private renderActions = (item: PaymentRequest) => {
    return (
      <div>
        <div>
          <Link style={{ whiteSpace: 'nowrap' }}
                onClick={this.props.printPaymentRequest.bind(null, item.Id)}>Просмотр/Печать</Link>
        </div>
        <div>
          <Link onClick={this.props.downloadPaymentRequest.bind(null, item.Id)}>Скачать</Link>
        </div>
        {item.Status === 0 && (
          <div>
            <div>
              <Link onClick={this.execPaymentRequest.bind(null, item.Id)}>
                Исполнить
              </Link>
            </div>
            <div>
              <Link onClick={this.openDeleteModal.bind(null, item.Id)}>
                Отклонить
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  };

  private handleChangeSort = (fieldName: keyof PaymentRequest) => {
    this.props.changeRequestData('SortFieldName', fieldName);
  };

  private handleChangeFilter = (fieldInfo: FieldInfo<PaymentRequest>, forceFiltering?: boolean) => {
    forceFiltering = forceFiltering || fieldInfo.ValueType === 3;
    this.props.changeFilters(fieldInfo, forceFiltering);
  };

  private handleChangeOperator = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(event.target.value, 10);

    this.props.changeRequestData('Operator', value);
  };

  private handleChangeColumns = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: columnName, checked } = event.target;

    this.setState(state => {
      const filterFieldName = columnName as keyof PaymentRequest;
      const newVisibleFields = new Map(state.visibleFields);

      newVisibleFields.set(filterFieldName, checked);

      return {
        visibleFields: newVisibleFields
      };
    });
  };

  private toggleFiltersVisible = () => {
    this.setState(state => ({ filtersShown: !state.filtersShown }));
  };

  private openDeleteModal = (id: number) => {
    if (this.confirmDeleteModal.current) {
      this.deleteId = id;
      this.confirmDeleteModal.current.open();
    }
  };

  private handleCloseConfirmModal = () => {
    this.deleteId = null;
  };

  private deletePaymentRequest = () => {
    if (this.deleteId !== null) {
      this.props.deletePaymentRequest(this.deleteId);
      this.deleteId = null;
      this.props.getOperationsByCurrencyRequest(CURRENCY_RUB);
      this.props.getOperationsByCurrencyRequest(CURRENCY_USD);
      this.props.getOperationsByCurrencyRequest(CURRENCY_EUR);
    }
  };

  private execPaymentRequest = (id: number) => {
    this.props.execRequest(id);
    this.props.getOperationsByCurrencyRequest(CURRENCY_RUB);
    this.props.getOperationsByCurrencyRequest(CURRENCY_USD);
    this.props.getOperationsByCurrencyRequest(CURRENCY_EUR);
  };

  private handleChangePage = (page: number) => {
    this.props.changePage(page);
  };

  private getTableRowStyles = ({ Status }: PaymentRequest) => {
    if (Status === 0) {
      return {
        background: '#c8f7bf'
      };
    }
  };
}

const mapStateToProps = (state: AppState) => ({
  loading: state.paymentRequests.loading,
  data: state.paymentRequests.data,
  requestData: state.paymentRequests.requestData,
  labels: state.labels.labels,
  haveBatchOperations: haveBatchOperations(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchData: () => fetchListRequest,
      resetAllFilters: () => resetAllFilters,
      fetchLabels: fetchLabelsRequest,
      downloadPaymentRequest: downloadDocx,
      printPaymentRequest: downloadPdf,
      deletePaymentRequest: deleteRequest,
      changeRequestData: changeListRequestData,
      changeFilters,
      resetFilter,
      changePage,
      execRequest,
      getOperationsByCurrencyRequest,
      changePageSize
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentRequests);
