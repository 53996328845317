import React, { PureComponent } from "react";
import { isEmpty, get } from 'lodash';
import { ifProp } from 'styled-tools';
import moment from 'moment';

import {
  BillDepositPlanCalendarPeriod,
  BillDetailsState,
  BillLoanPlanCalendarPeriod,
  BillRaisingPlanCalendarPeriod
} from "../../../../store/billDetails";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import styled, { css } from "../../../../styled/styled-components";
import ErrorMessage from "../../../Errors/components/ErrorMessage";
import PrintButton from "../../../../components/PrintButton/PrintButton";

export interface PlannedScheduleProps {
  BillId: string,
  BillCode: string,
  calendar: string,
  calendars: BillDetailsState,
  fetchPlannedScheduleRequest: (data: { BillId: string, calendar: string }) => void,
}

export interface RowTypes {
  isPaid?: boolean;
  isSuccess?: boolean;
}

const BillTr = styled('tr')<RowTypes>`
    ${ifProp('isPaid', css`
      background-color: #dff0d8;
  `)}
      ${ifProp('isSuccess', css`
      font-weight: 600;
      background-color: rgb(0, 162, 129);
      color: aliceblue;
  `)}
`;

const AbsoluteButton = styled(PrintButton)`
    position: absolute;
    right: 0;
    top: 0;
`;

export default class PlannedSchedule extends PureComponent<PlannedScheduleProps> {

  private static localizeNumber = (num: number) => {
    return num.toLocaleString('ru', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  public render() {
    const { BillCode, calendar, calendars } = this.props;
    const resultCalendar = get(calendars, calendar);

    if (!isEmpty(resultCalendar.error)) {
      return (
          <div className="alert alert-danger mt-3 d-print-none">
            <ErrorMessage errorData={{ message: (resultCalendar.error && resultCalendar.error.Message) || 'Ошибка', verbose: (resultCalendar.error && resultCalendar.error.ExceptionMessage) || 'Кажется имеет место быть досадная ошибка.... Попробуйте обновить страницу'}} />
          </div>
      );
    }
    return (
      <div className="panel">
        <div className="panel-title text-center">
          <h3 className="position-relative mt-4">
            Плановый календарный график
            <AbsoluteButton type="success" href={`/bill/PlanCalendarToExcel?billCode=${BillCode}`}>
              <i className="fa fa-download"/>{' '}Excel
            </AbsoluteButton>
          </h3>
        </div>
        <LoadingOverlay loading={resultCalendar.loading}>
          <div className="panel-body mt-4">
            {this.renderTable()}
          </div>
        </LoadingOverlay>
      </div>);
  }

  public componentDidMount(): void {
    const { BillId, fetchPlannedScheduleRequest, calendar } = this.props;
    fetchPlannedScheduleRequest({ BillId, calendar });
  }


  private renderTable = () => {
    const { calendar, calendars } = this.props;
    const resultCalendar = get(calendars, calendar);
    if (calendar === 'loanCalendar') {
      return (
        <table className="table table-stripped">
          <thead>
          <tr>
            <th>Дата</th>
            <th>Возврат тела</th>
            <th>Проценты</th>
            <th>Членские взносы</th>
            <th>Месячный платеж</th>
            <th>Остаток тела</th>
            <th>Досрочное погашение</th>
          </tr>
          </thead>
          <tbody>
          {resultCalendar.data && resultCalendar.data.BillLoanPlanCalendarPeriods.map((item: BillLoanPlanCalendarPeriod, index: number) => (
            <BillTr key={index} isPaid={item.IsPaid} className="text-left">
              <td>{moment(item.FactPaymentDate).format("DD.MM.YYYY")}</td>
              <td>{PlannedSchedule.localizeNumber(item.BodyBack)}</td>
              <td>{PlannedSchedule.localizeNumber(item.Percent)}</td>
              <td>{PlannedSchedule.localizeNumber(item.MemberFee)}</td>
              <td>{PlannedSchedule.localizeNumber(item.MonthlyPayment)}</td>
              <td>{PlannedSchedule.localizeNumber(item.Balance)}</td>
              <td>{PlannedSchedule.localizeNumber(item.AdvancedRepayment)}</td>
            </BillTr>))}
          </tbody>
          <tfoot>
          <BillTr isSuccess>
            <td>Итого:</td>
            <td>{resultCalendar.data && PlannedSchedule.localizeNumber(resultCalendar.data.BodyBack)}</td>
            <td>{resultCalendar.data && PlannedSchedule.localizeNumber(resultCalendar.data.Percent)}</td>
            <td>{resultCalendar.data && PlannedSchedule.localizeNumber(resultCalendar.data.MemberFee)}</td>
            <td>{resultCalendar.data && PlannedSchedule.localizeNumber(resultCalendar.data.MonthlyPayment)}</td>
            <td/>
            <td>{resultCalendar.data && PlannedSchedule.localizeNumber(resultCalendar.data.AdvancedRepayment)}</td>
          </BillTr>
          </tfoot>
        </table>
      );
    }

    if (calendar === 'raisingCalendar') {
      return (
        <table className="table table-stripped">
          <thead>
          <tr>
            <th>Дата</th>
            <th>Сумма внесения</th>
            <th>Процентная ставка</th>
            <th>Компенсация</th>
            <th>К выплате</th>
            <th>Баланс</th>
          </tr>
          </thead>
          <tbody>
          {resultCalendar.data && resultCalendar.data.Periods.map((item: BillRaisingPlanCalendarPeriod, index: number) => (
            <BillTr key={index} isPaid={moment(item.PaymentDate).isBefore(moment())} className="text-left">
              <td>{moment(item.PaymentDate).format("DD.MM.YYYY")}</td>
              <td>{PlannedSchedule.localizeNumber(item.PayIn)}</td>
              <td>{PlannedSchedule.localizeNumber(item.PercentRate)}</td>
              <td>{PlannedSchedule.localizeNumber(item.Percent)}</td>
              <td>{PlannedSchedule.localizeNumber(item.Payment)}</td>
              <td>{PlannedSchedule.localizeNumber(item.CurrentBalanceEnd)}</td>
            </BillTr>))}
          </tbody>
          <tfoot>
          <BillTr isSuccess>
            <td>Итого:</td>
            <td>{resultCalendar.data && PlannedSchedule.localizeNumber(resultCalendar.data.PayIn)}</td>
            <td/>
            <td>{resultCalendar.data && PlannedSchedule.localizeNumber(resultCalendar.data.Percent)}</td>
            <td>{resultCalendar.data && PlannedSchedule.localizeNumber(resultCalendar.data.Payment)}</td>
            <td/>
          </BillTr>
          </tfoot>
        </table>
      );
    }

    if (calendar === 'depositCalendar' || calendar === 'pensionCalendar') {
      return (
        <table className="table table-stripped">
          <thead>
          <tr>
            <th>Дата</th>
            <th>Сумма внесения</th>
            <th>Процентная ставка</th>
            <th>Компенсация</th>
            <th>НДФЛ</th>
            <th>ЧВ</th>
            <th>К выплате</th>
            <th>Баланс</th>
          </tr>
          </thead>
          <tbody>
          {resultCalendar.data && resultCalendar.data.Periods.map((item: BillDepositPlanCalendarPeriod, index: number) => (
            <BillTr key={index} isPaid={moment(item.PaymentDate).isBefore(moment())} className="text-left">
              <td>{moment(item.PaymentDate).format("DD.MM.YYYY")}</td>
              <td>{PlannedSchedule.localizeNumber(item.PayIn)}</td>
              <td>{PlannedSchedule.localizeNumber(item.PercentRate)}</td>
              <td>{PlannedSchedule.localizeNumber(item.Percent)}</td>
              <td>{PlannedSchedule.localizeNumber(item.Pit)}</td>
              <td>{PlannedSchedule.localizeNumber(item.Member)}</td>
              <td>{PlannedSchedule.localizeNumber(item.Payment)}</td>
              <td>{PlannedSchedule.localizeNumber(item.CurrentBalanceEnd)}</td>
            </BillTr>))}
          </tbody>
          <tfoot>
          <BillTr isSuccess>
            <td>Итого:</td>
            <td>{resultCalendar.data && PlannedSchedule.localizeNumber(resultCalendar.data.PayIn)}</td>
            <td/>
            <td>{resultCalendar.data && PlannedSchedule.localizeNumber(resultCalendar.data.Percent)}</td>
            <td>{resultCalendar.data && PlannedSchedule.localizeNumber(resultCalendar.data.Pit)}</td>
            <td>{resultCalendar.data && PlannedSchedule.localizeNumber(resultCalendar.data.Member)}</td>
            <td>{resultCalendar.data && PlannedSchedule.localizeNumber(resultCalendar.data.Payment)}</td>
            <td/>
          </BillTr>
          </tfoot>
        </table>
      );
    }

    return (<div>error</div>)
  };
}
