import * as React from 'react';
import ReactDOM from 'react-dom';

export class DatePickerPortal extends React.Component {
  private container: HTMLDivElement;

  constructor(props: {}) {
    super(props);

    this.container = document.createElement('div');
  }

  public componentDidMount() {
    document.body.appendChild(this.container);
  }

  public componentWillUnmount() {
    document.body.removeChild(this.container);
    this.container.remove();
  }

  public render() {
    return ReactDOM.createPortal(this.props.children, this.container);
  }
}
