export const MALE_CODE = 'Male';
export const FAMALE_CODE = 'Famale';

export const MALE_NAME = 'Мужской';
export const FAMALE_NAME = 'Женский';

export default {
  [MALE_NAME]: MALE_CODE,
  [FAMALE_NAME]: FAMALE_CODE,
};
