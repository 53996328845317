import React  from 'react';
import Helmet from 'react-helmet';

import Finmonitoring from './Finmonitoring';
import FinmonitoringBlock from './FinmonitoringBlock';
import FinmonitoringWeapons from './FinmonitoringWeapons';
import './Finmonitoring.scss';

import BackLink from '../../components/BackLink';
import { TabContent, TabLink, TabLinks } from '../../modules/Partner/components/Views';


export const TABS_LABELS = new Map([
  ['main', { label: 'Террористическая деятельность', component: Finmonitoring }],
  ['block', { label: 'Блокировки', component: FinmonitoringBlock }],
  ['weapons', { label: 'Распространение оружия', component: FinmonitoringWeapons }],
]);

export default class FinmonitoringIndex extends React.Component {
  render() {
    const tabsEneteries = [...TABS_LABELS.entries()];
    const currentTabName = this.props.location.hash.replace('#', '') || 'main';
    const currentTab = TABS_LABELS.get(currentTabName);

    const Component = currentTab ? currentTab.component : null;
    return (
      <div className="finmonitoring">
        <Helmet >
          <title>Финмониторинг</title>
        </Helmet>
        <BackLink title={document.title} >
          <h4 className="finmonitoring__header">Проверка по реестрам</h4>
        </BackLink>
        <TabLinks>
          {tabsEneteries.map(([name, item]) => (
            <TabLink
              key={name}
              href={`${this.props.location.pathname}#${name}`}
              routerLink
              active={
                (!this.props.location.hash && name === 'main') ||
                this.props.location.hash === `#${name}`
              }
              component={
                this.props.location.hash === `#${name}`
                  ? clickableProps => <span {...clickableProps} />
                  : undefined
              }
            >
              {item.label}
            </TabLink>
          ))}
        </TabLinks>
        <TabContent>{Component && <Component />}</TabContent>
      </div>
    );
  }
}
