import React from 'react';
// @ts-ignore
import { ChatItem as ItemChat } from "../../../../components/ReactChatElements";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import { ChatItem } from '../../../../store/chat';
import { RouteComponentProps } from 'react-router';

interface MatchParams {
  id: string;
}

interface ChatPageProps extends RouteComponentProps<MatchParams> {
  getAllChatsRequest: () => void;
  getAllChatMessageRequest: (id: number) => void;
  readChatMessages: (chatId: number) => void;
  chats: ChatItem[];
  loading: boolean;
}

interface ChatListBlockState {
  activeItem: number,
}

export default class ChatListBlock extends React.Component<ChatPageProps, ChatListBlockState> {
  public state: ChatListBlockState = {
    activeItem: 1,
  };

  public componentDidMount(): void {
    const { getAllChatsRequest, match, getAllChatMessageRequest, readChatMessages } = this.props;
    getAllChatsRequest();
    if (match.params && Number(match.params.id)) {
      const chatId = Number(match.params.id);
      getAllChatMessageRequest(chatId);
      readChatMessages(chatId);
      this.setState({
        activeItem: chatId
      })
    } else {
      getAllChatMessageRequest(1);
      readChatMessages(1);

    }
  }

  public render() {
    const { loading, chats } = this.props;
    const { activeItem } = this.state;
    return (
      <LoadingOverlay loading={loading}>
        <div
          className={`rce-container-clist chat-list`}>
          {
            chats.map((chat) => (
              <div key={chat.id} className={activeItem === chat.id ? 'active-chat' : ''}>
                <ItemChat
                  id={chat.id}
                  {...chat}
                  onClick={this.onClickChat(chat)}
                />
              </div>
            ))
          }
        </div>
      </LoadingOverlay>
    )
  }

  private onClickChat = (chat: ChatItem) => () => {
    const { getAllChatMessageRequest, readChatMessages } = this.props;
    if (this.state.activeItem !== chat.id) {
      getAllChatMessageRequest(chat.id);
      readChatMessages(chat.id);
      this.setState({
        activeItem: chat.id
      })
    }
  }
}
