import { UserItem, FilterFieldName, FilterItemData, SortDirection } from "../types";

export const AUTOCOMPLETE_FILTER_FIELDS = [
  "PartnerNumber"
];

export const USER_FIELD_LABELS = new Map<keyof UserItem, string>([
  ["UserName", "Логин"],
  ["SecGroupName", "Группа"],
  ["CreateDate", "Дата создания"],
  ["IsActive", "Статус"]
]);

export const DEFAULT_FILTER_FIELDS = new Map<
  FilterFieldName<UserItem>,
  FilterItemData
  >([
  [
    "UserName",
    {
      FieldName: "UserName",
      Method: 1,
      Value: null,
      ValueType: 1
    }
  ],
  [
    "SecGroupName",
    {
      FieldName: "SecGroupName",
      Value: null,
      Method: 1,
      ValueType: 5
    }
  ],
  [
    "CreateDate",
    {
      FieldName: "CreateDate",
      Value: null,
      Method: 4,
      ValueType: 4
    }
  ],
  [
    "IsActive",
    {
      FieldName: "IsActive",
      Value: null,
      Method: 0,
      ValueType: 3
    }
  ]
]);

export const DEFAULT_SORT_DIRECTION: SortDirection = 2;

export const DEFAULT_SORT_FIELD_NAME: FilterFieldName<UserItem> =
  "CreateDate";
