import * as React from 'react';
import Helmet from 'react-helmet';
import { isNull, isNil, isEqual, get, trim } from 'lodash';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { ArrowLeft } from 'styled-icons/fa-solid';
import { Location, UnregisterCallback } from 'history';

import ApiClient from '../../../services/api';
import { UserData, PartnerTabType, Agent, Bill, PersonalData, LoanForm, AdditionalPhone } from '../types';
import {
  PARTNER_TABS_LABELS,
  REQUIRED_FIELDS,
  BILL_TYPES,
  MESSAGES,
  LOAN_FORM_CLIENT_FIELDS,
  LOAN_FORM_REASON_FIELDS, LOAN_FORM_CREDIT_FIELDS
} from "../constants";
import { getPartnerNumberFromUserdata } from '../utils';

import { TabContent, TabLink, TabLinks, PartnerInitials, PrivilegeLevel } from '../components/Views';
import SectionHeader from '../../../components/SectionHeader';
import Link from '../../../components/Link';
import PartnerHeaderButtons from '../components/PartnerHeaderButtons';
import ConfirmModal from '../../../components/PartnersAndClients/ConfirmModal';
import ActionsPanel from '../components/ActionsPanel';
import { showErrorToast } from '../../../components/ErrorToast';
import LoadingOverlay from '../../../components/LoadingOverlay';
import CommentPopup from '../components/CommentPopup';
import { Nullable } from "../../../types/typeUtils";
import { findGetParameter } from "../../../utils";
import { PRIVILEGE_LEVEL_LABELS } from "../../../constants";

export interface PartnerProps
  extends RouteComponentProps<{ mode: string; partnerNumber?: string }> {
}

export interface PartnerState {
  userData: UserData | null;
  cachedUserData: UserData | null;
  isChanged: boolean;
  errors: Map<string, string[]>;
  bills: Bill[];
  billTypes: Map<number, string>;
  userLabels: Map<number, string>;
  partnerTypes: Map<number, string>;
  managers: Map<number, string>;
  currencies: Map<number, string>;
  users: Map<number, string>;
  loading: boolean;
  loanForm: Nullable<LoanForm>;
  cachedLoanForm: Nullable<LoanForm>;
  allowPrintLoanForm: boolean;
}

export interface PartnerContextType {
  userData: UserData | null;
  editing: boolean;
  handleChange?: (
    ectionName: keyof UserData | [keyof UserData, string] | null,
    fieldName: string,
    value: any,
    additioanalData?: any,
  ) => void;
  errors: Map<string, string[]>;
  bills: Bill[];

  valueLabels: {
    billTypes: Map<number, string>;
    userLabels: Map<number, string>;
    partnerTypes: Map<number, string>;
    managers: Map<number, string>;
    currencies: Map<number, string>;
    users: Map<number, string>;
  };
  copyAddress?: () => void;
  loanForm: Nullable<LoanForm>;
  handleChangeLoanForm: (fieldName: keyof LoanForm, value: any) => void;
  isCanSaveLoanForm: boolean;
  saveLoanForm: () => void;
  printLoanForm: () => void;
  allowPrintLoanForm: boolean;
}

export const PartnerContext = React.createContext<PartnerContextType>({
  userData: null,
  editing: false,
  errors: new Map(),
  bills: [],
  valueLabels: {
    billTypes: BILL_TYPES,
    userLabels: new Map(),
    partnerTypes: new Map(),
    managers: new Map(),
    currencies: new Map(),
    users: new Map()
  },
  loanForm: null,
  handleChangeLoanForm: () => void 0,
  isCanSaveLoanForm: false,
  saveLoanForm: () => void 0,
  printLoanForm: () => void 0,
  allowPrintLoanForm: false,
});

const getFullNameWithInitials = (data?: PersonalData | null): string => {
  if (!data) {
    return '';
  }
  const { FirstName, LastName, Patronymic } = data;

  if (!FirstName && !LastName && !Patronymic) {
    return '';
  }

  return `${LastName} ${FirstName ? FirstName : ''} ${Patronymic ? Patronymic : ''}`;
};

export default class Partner extends React.Component<PartnerProps, PartnerState> {
  public state: PartnerState = {
    userData: null,
    cachedUserData: null,
    isChanged: false,
    errors: new Map(),
    bills: [],
    billTypes: BILL_TYPES,
    userLabels: new Map(),
    partnerTypes: new Map(),
    managers: new Map(),
    currencies: new Map(),
    users: new Map(),
    loading: false,
    loanForm: null,
    cachedLoanForm: null,
    allowPrintLoanForm: false,
  };

  private confirmCancelModal = React.createRef<ConfirmModal>();
  private confirmSaveModal = React.createRef<ConfirmModal>();

  private transitionBlocker: UnregisterCallback | null = null;

  public componentDidMount() {
    this.handleNavigate();

    if (!this.transitionBlocker) {
      this.transitionBlocker = this.props.history.block(this.handleChangeLocation);
    }

    if (this.props.match.params.mode === 'create') {
      this.getInitialPartnerData();
    } else {
      this.getUserData();
    }

    this.getOptions();
  }

  public componentDidUpdate(prevProps: PartnerProps, prevState: PartnerState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handleNavigate();
    }

    if (prevState.userData === null && this.state.userData !== null && this.state.userData.Id) {
      this.getBills();
    }
  }

  public componentWillUnmount() {
    if (this.transitionBlocker) {
      this.transitionBlocker();
    }
  }

  public componentDidCatch(error: Error) {
    showErrorToast({ Message: error.message });
  }

  public render() {
    const tabsEneteries = [...PARTNER_TABS_LABELS.entries()];
    const currentTabName = this.props.location.hash.replace('#', '') || 'main';
    const currentTab = PARTNER_TABS_LABELS.get(currentTabName as PartnerTabType);

    const Component = currentTab ? currentTab.component : null;

    return (
      <PartnerContext.Provider
        value={{
          userData: this.state.userData,
          editing: this.isEditing() && !this.state.loading,
          handleChange: this.handleChange,
          errors: this.state.errors,
          bills: this.state.bills,
          valueLabels: {
            billTypes: this.state.billTypes,
            userLabels: this.state.userLabels,
            partnerTypes: this.state.partnerTypes,
            managers: this.state.managers,
            currencies: this.state.currencies,
            users: this.state.users
          },
          copyAddress: this.copyAddress,
          handleChangeLoanForm: this.handleChangeLoanForm,
          loanForm: this.state.loanForm,
          isCanSaveLoanForm: this.isCanSaveLoanForm(),
          saveLoanForm: this.saveLoanForm,
          printLoanForm: this.printLoanForm,
          allowPrintLoanForm: this.state.allowPrintLoanForm,
        }}
      >
        <LoadingOverlay loading={this.state.loading}>
          <Helmet defer={false}>
            <title>
              {this.props.match.params.mode === 'create'
                ? 'Создание пайщика'
                : this.state.userData &&
                `${this.state.userData.FirstName} ${this.state.userData.LastName}`}
            </title>
          </Helmet>
          <SectionHeader
            heading={this.renderHeaderTitle()}
            link={this.renderBackLink()}
            content={
              <React.Fragment>
                <PartnerInitials>{getFullNameWithInitials(this.state.userData)} {this.state.userData && this.state.userData.PrivilegeLevel && <PrivilegeLevel bacgroundColor={this.getStylesForStatusPartner(this.state.userData.PrivilegeLevel)} >{PRIVILEGE_LEVEL_LABELS.get(this.state.userData.PrivilegeLevel)}</PrivilegeLevel>}</PartnerInitials>

              </React.Fragment>
            }
          >
            {this.renderHeaderButtons()}
          </SectionHeader>
          <ActionsPanel
            disabled={this.isEditing()}
            partnerId={this.state.userData ? this.state.userData.Id : undefined}
            partnerNumber={
              this.state.userData ? getPartnerNumberFromUserdata(this.state.userData) : undefined
            }
          />
          <TabLinks>
            {tabsEneteries.map(([name, item]) => (
              <TabLink
                key={name}
                href={`${this.props.location.pathname}#${name}`}
                routerLink
                active={
                  (!this.props.location.hash && name === 'main') ||
                  this.props.location.hash === `#${name}`
                }
                component={
                  this.props.location.hash === `#${name}`
                    ? clickableProps => <span {...clickableProps} />
                    : undefined
                }
                error={this.isTabHasErrors(name)}
              >
                {item.label}
              </TabLink>
            ))}
          </TabLinks>
          <TabContent>{Component && <Component />}</TabContent>
          <ConfirmModal
            message="Вы действительно хотите отменить изменения?"
            successLabel="Да"
            cancelLabel="Нет"
            onConfirm={this.handleConfirmCancel}
            ref={this.confirmCancelModal}
          />
          <ConfirmModal
            message="Сохранить изменения?"
            successLabel="Да"
            cancelLabel="Нет"
            onConfirm={this.handleConfirmSave}
            ref={this.confirmSaveModal}
          />
          {this.state.userData ? <CommentPopup comment={this.state.userData.Comment} /> : null}
        </LoadingOverlay>
      </PartnerContext.Provider>
    );
  }

  private getStylesForStatusPartner = (privilegeLevel: number) => {
    switch (Number(privilegeLevel)) {
      case 1:
        return '#4480bc';
      case 2:
        return '#5c5a5d';
      case 3:
        return '#ddbe84';
      default:
        return 'black';
    };
  };

  private isSalary = () => !!findGetParameter('salary');

  private getUserData = async () => {
    const { partnerNumber } = this.props.match.params;

    this.setState({ loading: true });
    try {
      this.handleFetchedData(await ApiClient.getPartner(partnerNumber));
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private handleFetchedData = (result: UserData) => {
    const formatted = this.formatData(result);
    this.setState({
      userData: formatted,
      cachedUserData: formatted,
      isChanged: false,
      errors: new Map()
    });
    this.getLoanForm(result.Id);
  };

  private getLoanForm = async (id: number) => {
    try {
      const result = await ApiClient.getPartnerLoanForm(id);
      this.checkAllowPrintLoanForm(result);
      const loanForm = {
        ...result,
        CurrencyId: result.CurrencyId === 0 ? 1 : result.CurrencyId
      };

      this.setState({
        loanForm,
        cachedLoanForm: loanForm
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  private handleChangeLoanForm = (fieldName: keyof LoanForm, value: any) => {
    this.setState(state => {
      const loanForm = state.loanForm || {};

      return {
        loanForm: {
          ...loanForm,
          [fieldName]: fieldName === "Sum" ? parseFloat(value) || 0 : value
        }
      };
    });
  };

  private isCanSaveLoanForm = () => {
    const { userData, loanForm, cachedLoanForm } = this.state;

    if (!userData || !loanForm) {
      return false;
    }

    return !isEqual(loanForm, cachedLoanForm);
  };

  private saveLoanForm = async () => {
    const { userData, loanForm } = this.state;

    if (!userData) {
      return;
    }

    if (!loanForm) {
      return;
    }

    const {
      CreationTime,
      Currency,
      EmployeeId,
      Employee,
      ClientFullName,
      ...requestData
    } = loanForm;

    this.setState({ loading: true });
    try {
      const result = await ApiClient.savePartnerLoanForm(
        userData.Id,
        requestData
      );
      this.checkAllowPrintLoanForm(result);
      toast("Анкета успешно сохранена", {
        type: toast.TYPE.SUCCESS
      });
      this.setState({
        loanForm: result,
        cachedLoanForm: result,
      });
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private printLoanForm = async () => {
    const { userData, loanForm } = this.state;

    if (!userData) {
      return;
    }

    if (!loanForm) {
      return;
    }

    this.setState({ loading: true });
    try {
      const result = await ApiClient.printPartnerLoanForm(userData.Id);

      const blob = new Blob([result], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      window.open(url);
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private formatData = (userData: UserData) => {
    const { Snils, PartnerQuestionnaire } = userData;
    const snilsFormatter = (value: string) => {
      const filtered = value.replace(/\D/g, '');
      const first = filtered.slice(0, 3);
      const second = filtered.slice(3, 6);
      const third = filtered.slice(6, 9);
      const fourth = filtered.slice(9, 11);

      if (!first) {
        return '';
      }
      if (!second) {
        return first;
      }
      if (!third) {
        return `${first}-${second}`;
      }
      if (!fourth) {
        return `${first}-${second}-${third}`;
      }
      return `${first}-${second}-${third} ${fourth}`;
    };
    return {
      ...userData,
      AdditionalPhones: userData.AdditionalPhones || [],
      PartnerBankBills: isNil(userData.PartnerBankBills) ? [] : userData.PartnerBankBills,
      PartnerCards: isNil(userData.PartnerCards) ? [] : userData.PartnerCards,
      PartnerQuestionnaire: PartnerQuestionnaire ? PartnerQuestionnaire : {
        MaritalStatus: '', // Семейное положение или личный статус
        HaveChildrens: false, // Наличие детей/внуков
        ChildrensAge: '', // Возраст каждого ребенка
        LivingPlace: '', // Место проживания
        SeaCareer: '', // Род деятельности на судне
        LandCareer: '', // Род деятельности на берегу
        Hobby: '', // Увлечение, хобби на берегу
        Messengers: '', // Какими месседжерами пользуется чаще всего
        SocialNetworks: '', // Какими соц сетями пользуется чаще всего
        MFondProducts: '', // Какими услугами пользовался в Морском Фонде
        Feedback: '',
      },
      Snils: snilsFormatter(Snils || '')
    };
  };

  private goToList = () => {
    this.props.history.replace('/client');
  };

  private renderHeaderTitle = () => {
    if (this.isSalary()) {

      return 'Анкета зарплатного клиента';
    }

    if (this.state.userData && Number(this.state.userData.PartnerId) >= 900000) {

      return 'Анкета зарплатного клиента';
    }

    return 'Анкета пайщика'
  }

  private renderBackLink = () => (
    <Link onClick={this.goToList}>
      <ArrowLeft size={13} /> Вернуться к списку
    </Link>
  );

  private renderHeaderButtons = () => {
    const returnUrl = encodeURIComponent(this.props.location.pathname);
    const { partnerNumber, mode } = this.props.match.params;
    const { hash } = this.props.location;
    const isDisabledSave = !this.state.isChanged && this.props.match.params.mode !== 'create';

    return (
      <PartnerHeaderButtons
        isDisabledSave={isDisabledSave}
        isEditing={mode === 'edit'}
        isCreating={mode === 'create'}
        returnUrl={returnUrl}
        partnerNumber={partnerNumber}
        hash={hash}
        handleClickSave={this.handleClickSave}
        handleClickCancel={this.handleClickCancel}
      />
    );
  };

  private copyAddress = () => {
    this.setState(state => ({
      isChanged: true,
      userData: state.userData
        ? {
          ...state.userData,
          PartnerAddressMail: {
            ...state.userData.PartnerAddressRegistration,
            Id: state.userData.PartnerAddressMail.Id,
          }
        }
        : state.userData
    }));
  };

  private handleChange = (
    sectionName: keyof UserData | [keyof UserData, string] | null,
    fieldName: string,
    value: any,
    additionalData?: any,
  ) => {
    const { userData } = this.state;
    if (!userData) {
      return;
    }

    if (additionalData && fieldName === 'PhoneNumber') {
      // @ts-ignore
      this.setState((state) => ({
        ...state,
        isChanged: true,
        userData: {
          ...state.userData,
          PhoneNumber: value.toString(),
          PhoneCountryId: additionalData.id,
        }
      }));

      return;
    }

    if (additionalData && ['PhoneHome', 'PhoneMobileAdd'].includes(fieldName)) {
      const phoneType = fieldName === 'PhoneMobileAdd' ? 1 : 2;
      let currentPhones = this.state.userData ? this.state.userData.AdditionalPhones : [];
      const additionalPhone = currentPhones.find((phone: AdditionalPhone) => phone.Type === phoneType);

      const newData = {
        ...(additionalPhone || {}),
        Number: value.toString(),
        Code: additionalData.dialCode,
        Country: additionalData.name,
        CountryId: additionalData.id,
        Type: phoneType,
        FullPhoneNumber: `+${additionalData.dialCode}${value.toString()}`
      };

      if (additionalPhone) {
        currentPhones = currentPhones.map((phone: AdditionalPhone) => {
          if (phone.Type === phoneType) {

            return newData;
          }

          return phone;
        })
      } else {
        currentPhones.push(newData);
      }
      // @ts-ignore
      this.setState((state) => ({
        ...state,
        isChanged: true,
        userData: {
          ...state.userData,
          AdditionalPhones: currentPhones,
        }
      }));

      return;
    }

    if (Array.isArray(sectionName)) {
      this.setState((state) => {
        const currentUserData = state.userData;

        if (!currentUserData) {
          return state;
        }

        const currentSection = currentUserData[sectionName[0]];

        if (!currentSection || typeof currentSection !== 'object') {
          return state;
        }

        // TODO: придумать универсальное рещение не только для агента
        const currentSubSection = (currentSection as Agent)[sectionName[1] as keyof Agent];

        if (!currentSubSection || typeof currentSubSection !== 'object') {
          return state;
        }

        return {
          ...state,
          userData: {
            ...currentUserData,
            [sectionName[0]]: {
              ...currentSection,
              [sectionName[1]]: {
                ...currentSubSection,
                [fieldName]: value
              }
            }
          },
          isChanged: true
        };
      });

      return;
    }

    if (sectionName && (userData[sectionName] || isNull(userData[sectionName]))) {
      this.setState(state => ({
        userData: {
          ...state.userData!,
          [sectionName]: {
            ...(state.userData![sectionName] as { [key: string]: any }),
            [fieldName as string]: value
          }
        },
        isChanged: true
      }));

      return;
    }

    if (userData.hasOwnProperty(fieldName)) {
      this.setState(state => ({
        userData: {
          ...state.userData!,
          [fieldName as string]: value
        },
        isChanged: true
      }));
    }
  };

  private handleClickCancel = () => {
    const confirmModal = this.confirmCancelModal.current;
    const isCreate = this.props.match.params.mode === 'create';
    const { isChanged } = this.state;

    if (!isChanged && !isCreate) {
      this.goToInfo();
    }

    if ((isChanged || isCreate) && confirmModal) {
      confirmModal.open();
    }
  };

  private resetChanges = () => {
    this.setState(
      state => ({
        userData: state.cachedUserData,
        isChanged: false
      }),
      this.goToInfo
    );
  };

  private goToInfo = (after?: 'create' | 'update') => {
    const { userData } = this.state;

    if (!userData) {
      return;
    }

    const partnerNumber = getPartnerNumberFromUserdata(userData);
    if (after === 'create' && partnerNumber) {
      this.props.history.replace(`/client/info/${partnerNumber}`, {
        after: 'create'
      });
    } else {
      this.props.history.replace(
        this.props.location.pathname.replace('edit', 'info'),
        after === 'update' && { after: 'update' }
      );
    }
  };

  private handleClickSave = () => {
    if (
      (this.state.isChanged || this.props.match.params.mode === 'create') &&
      this.confirmSaveModal.current
    ) {
      this.confirmSaveModal.current.open();
    }
  };

  private save = async () => {
    const errors = this.validate();

    if (errors.size) {
      this.setState({ errors });
      showErrorToast({ Message: 'Заполните обязательные поля' });
      return;
    }

    try {
      this.setState({ loading: true });
      const normalized = this.normalizeData(this.state.userData);
      this.handleFetchedData(await ApiClient.createOrUpdatePartner(
        normalized,
        this.props.match.params.mode === 'create'
      ));
      this.goToInfo(this.props.match.params.mode === 'create' ? 'create' : 'update');
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private normalizeData(userData: UserData | null) {
    if (!userData) {
      return userData;
    }

    const { Snils } = userData;
    const nSnils = Snils ? Snils.replace(/\D/g, '') : Snils;
    return { ...userData, Snils: nSnils };
  }

  private getInitialPartnerData = async () => {
    this.setState({ loading: true });
    try {
      if (this.isSalary()) {
        this.handleFetchedData(await ApiClient.getInitialPartnerSalaryData());
      } else {
        this.handleFetchedData(await ApiClient.getInitialPartnerData());
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private isEditing = () => this.props.match.params.mode === 'create' || this.props.match.params.mode === 'edit';

  private handleConfirmCancel = () => {
    if (this.props.match.params.mode === 'edit') {
      this.resetChanges();
    }

    if (this.props.match.params.mode === 'create') {
      this.goToList();
    }
  };

  private handleConfirmSave = () => {
    this.save();
  };

  private handleChangeLocation = (location: Location) => {
    if (
      (!this.props.location.state && location.state) ||
      this.props.location.pathname === location.pathname
    ) {
      return;
    }
    if (this.state.isChanged) {
      return 'Вы действительно хотите отменить изменения?';
    }

    if (this.props.match.params.mode === 'create') {
      return 'Вы действительно хотите отменить изменения?';
    }
  };

  private getOptions = async () => {
    try {
      const result = await ApiClient.getAllForOptions();
      const {
        UserLabels, PartnerTypes, Managers, BillTypes, Currencies, Users
      } = result as {
        [key: string]: Map<number, string>;
      };

      this.setState({
        billTypes: BillTypes,
        userLabels: UserLabels,
        partnerTypes: PartnerTypes,
        managers: Managers,
        currencies: Currencies,
        users: Users
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  private getBills = async () => {
    const id = this.state.userData ? this.state.userData.Id : null;

    if (!id) {
      return;
    }

    try {
      const result = await ApiClient.getBillsForPartner(id);

      this.setState({
        bills: result.DataSource
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  private validate = () => {
    const { userData } = this.state;
    const requiredFieldsErrors = new Map<string, string[]>();

    if (!userData) {
      return requiredFieldsErrors;
    }

    const getSection = (name: string) => {
      switch (name) {
        case 'UserData':
        case 'PlaceOfBirth':
          return userData;

        case 'PartnerAddress':
          return userData.PartnerAddressRegistration;

        default:
          return userData[name as keyof UserData];
      }
    };

    for (const item of REQUIRED_FIELDS.entries()) {
      const [sectionName, fields] = item;
      const errorFields: string[] = [];

      fields.forEach((fieldName) => {
        const section = getSection(sectionName);

        if (section && !section[fieldName as keyof typeof section]) {
          errorFields.push(fieldName);
        }
      });

      if (errorFields.length) {
        requiredFieldsErrors.set(sectionName, errorFields);
      }
    }

    return requiredFieldsErrors;
  };

  private isTabHasErrors = (name: PartnerTabType) => {
    switch (name) {
      case 'main':
        return !!this.state.errors.get('UserData');

      case 'address':
        return !!this.state.errors.get('PartnerAddress') || !!this.state.errors.get('PlaceOfBirth');

      case 'documents':
        return !!this.state.errors.get('Passport');

      default:
        return false;
    }
  };

  private handleNavigate = () => {
    const { state } = this.props.location;
    // tslint:disable-next-line:no-console
    if (typeof state !== 'object') {
      return;
    }

    switch (state.after) {
      case 'create':
      case 'client':
        toast(MESSAGES.createdSuccess, {
          type: toast.TYPE.SUCCESS
        });
        break;

      case 'update':
        toast(MESSAGES.updateSuccess, {
          type: toast.TYPE.SUCCESS
        });
    }

    this.props.history.replace({ ...this.props.location, state: undefined });
  };

  private checkAllowPrintLoanForm = (data: LoanForm): void => {
    let allow = true;
    Array.from(LOAN_FORM_CLIENT_FIELDS.keys()).forEach((fieldName: string) => {
      if (!trim(get(data, fieldName))) {
        allow = false;
      }
    });

    Array.from(LOAN_FORM_REASON_FIELDS.keys()).forEach((fieldName: string) => {
      if (!trim(get(data, fieldName))) {
        allow = false;
      }
    });

    Array.from(LOAN_FORM_CREDIT_FIELDS.keys()).forEach((fieldName: string) => {
      if (!trim(get(data, fieldName))) {
        allow = false;
      }
    });

    this.setState({
      allowPrintLoanForm: allow,
    });
  }
}
