import React, { Fragment } from 'react';

import { PartnerContext } from '../../../pages/Partner';
import CardsBlock from './CardsBlock';
import AccountsBlock from './AccountsBlock';

export default function CardsTab() {
  return (
    <PartnerContext.Consumer>
      {
        ({ editing, userData, handleChange = () => void 0 }) =>
          userData && (
            <Fragment>
              <CardsBlock editing={editing} userData={userData} handleChange={handleChange} />
              <AccountsBlock editing={editing} userData={userData} handleChange={handleChange} />
            </Fragment>
          )
      }
    </PartnerContext.Consumer>
  );
}
