import React, { Component } from 'react';
import Helmet from "react-helmet";
import { RouteComponentProps } from "react-router";
import { toNumber, get } from "lodash";

import LoadingOverlay from "../../../../components/LoadingOverlay";
import SectionHeader from "../../../../components/SectionHeader";
import CreateUsersForm from '../../components/CreateUsersForm';

export default class Users extends Component<RouteComponentProps> {
  public render() {
    const userId = toNumber(get(this.props.match.params, 'userId'));
    return (
      <div>
        <Helmet defer={false}>
          <title>{this.getHeading(userId > 0)}</title>
        </Helmet>
        <SectionHeader
          heading={this.getHeading(userId > 0)}
        />
        <LoadingOverlay loading={false}>
          <div className="row">
            <div className="col-md-6">
              <CreateUsersForm goToList={this.goToBack} userId={userId > 0 ? userId : undefined} />
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }

  private getHeading = (isEdit: boolean) => {
    return isEdit ? 'Редактирование пользователя' : 'Создание пользователя';
  };

  private goToBack = () => {
    this.props.history.replace('/SecurityUser/List')
  };
}
