import * as React from 'react';
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import { toNumber } from 'lodash';
import moment from 'moment';
import { DatePickerPortal } from './Views';
import styled from '../../styled/styled-components';
import Input from '../Input';
import StyledInput from './StyledInput';
import ru from 'date-fns/locale/ru';
import * as FormControls from "../FormControls/FormControls";
import { SimpleMaskedInputForDate } from "../FormControls/FormControls";
import { InputState } from "react-input-mask";

registerLocale('ru', ru);

export interface DatePickerProps extends ReactDatePickerProps {
  useRange?: boolean;
  selectableMonth?: boolean;
  error?: boolean;
  style?: React.AllHTMLAttributes<HTMLInputElement>['style'];
  dateFormat: string | string[];
  innerRef?: React.Ref<InstanceType<typeof ReactDatePicker>>;
  useStyledInput?: boolean;
  onClear?: () => void;
  mask?: string;
  allYear?: string;
  allMonth?: string;
}

const Wrapper = styled.div`
  .react-datepicker__input-container {
    display: block;
  }

  .react-datepicker-wrapper {
    display: block;
  }
`;

export default class DatePicker extends React.Component<DatePickerProps> {
  public static prettyDateFormat = 'DD.MM.YYYY';
  public static prettyDateTimeFormat = 'DD.MM.YYYY, HH:mm';

  public static defaultProps = {
    dateFormat: 'dd.MM.yyyy',
  };

  private portal: HTMLDivElement | null = null;

  public componentDidMount() {
    this.portal = document.createElement('div');
    document.body.appendChild(this.portal);
  }

  public componentWillUnmount() {
    if (this.portal) {
      document.body.removeChild(this.portal);
    }
  }

  public render() {
    const { onChange, useStyledInput, onClear, mask, allMonth, allYear, ...props } = this.props;
    return (
      <Wrapper style={this.props.style}>
        <ReactDatePicker
          locale="ru"
          {...props}
          onChange={this.handleChange}
          value={this.props.value}
          placeholderText={allMonth || allYear}
          customInput={this.getCustomInput()}
          popperContainer={this.renderPopper}
          ref={this.props.innerRef}
        />
      </Wrapper>
    );
  }

  private getCustomInput = () => {
    const { useStyledInput, onClear, mask } = this.props;

    if (onClear) {
      return <FilterInput onClear={onClear}/>;
    }

    if (useStyledInput) {
      return <StyledInput fullWidth/>;
    }

    if (mask) {
    return <SimpleMaskedInputForDate beforeMaskedValueChange={this.beforeMaskedValueChange} mask={mask} />
    }

    return <Input fullWidth />
  };

  private beforeMaskedValueChange = (newState: InputState, oldState: InputState, userInput: string) => {
    const { value } = newState;
    const selection = newState.selection;

    if (userInput === '.') {
      const data = value.split('.');
      const day = toNumber(data[0].replace(/_/g, ''));
      const month = toNumber(data[1].replace(/_/g, ''));
      const year = toNumber(data[2].replace(/_/g, ''));

      if (day > 0 && day < 10 && selection && selection.end < 3) {
        return {
          value: `0${day}.${data[1]}.${data[2]}`,
          selection: {
            start: 3,
            end: 3,
          },
        }
      }

      if (month > 0 && month < 10 && selection && selection.end >= 3 && selection.end < 6) {
        return {
          value: `${data[0]}.0${month}.${data[2]}`,
          selection: {
            start: 6,
            end: 6,
          },
        }
      }

      if (year > 0 && year < 10 && selection && selection.end >= 6) {
        return {
          value: `${data[0]}.${data[1]}.200${year}`,
          selection: {
            start: 10,
            end: 10,
          },
        }
      }

      const currentYear = toNumber(moment().format('YY'));

      if (year > 9 && year <= currentYear && selection && selection.end >= 6) {
        return {
          value: `${data[0]}.${data[1]}.20${year}`,
          selection: {
            start: 10,
            end: 10,
          },
        }
      }

      if (year > currentYear && year <= 99 && selection && selection.end >= 6) {
        return {
          value: `${data[0]}.${data[1]}.19${year}`,
          selection: {
            start: 10,
            end: 10,
          },
        }
      }

      if (year > 99 && year <= 999 && selection && selection.end >= 6) {
        return {
          value: `${data[0]}.${data[1]}.1${year}`,
          selection: {
            start: 10,
            end: 10,
          },
        }
      }

    }
    // let cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    // if (value.endsWith('-') && userInput !== '-' && !this.state.value.endsWith('-')) {
    //   if (cursorPosition === value.length) {
    //     cursorPosition--;
    //     selection = { start: cursorPosition, end: cursorPosition };
    //   }
    //   value = value.slice(0, -1);
    // }
    return {
      value,
      selection
    };
  }

  private renderPopper = (props: { children: React.ReactNode[] }) => (
    <DatePickerPortal>{props.children}</DatePickerPortal>
  );

  private handleChange = (date: Date, event: any) => {
    if (this.props.onChange) {
      this.props.onChange(date, event);
    }
  };
}

const FilterInput = styled(FormControls.Input).attrs({
  style: {
    width: "100%",
    padding: "3px 10px 3px 3px"
  }
})``;
