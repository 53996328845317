import * as React from "react";
import styled from "../../styled/styled-components";
import { Heading } from "../Typography";
import BackLink from "../BackLink";

const HeaderView = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  margin: 0 -16px 24px;
`;

const HeadingColumn = styled.div`
  flex-shrink: 1;
  min-width: 200px;
`;

const SectionHeader: React.SFC<
  React.HTMLAttributes<HTMLDivElement> & {
    heading: React.ReactNode;
    link?: React.ReactNode;
    content?: React.ReactNode;
  }
> = ({ heading, children, link, content, ...props }) => (
  <HeaderView {...props}>
    <HeadingColumn>
      <BackLink title={document.title} >
        <Heading>{heading}</Heading>
      </BackLink>
      {content}
    </HeadingColumn>
    {children}
  </HeaderView>
);

export default SectionHeader;
