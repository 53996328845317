import { all, call, put, takeEvery } from "redux-saga/effects";
import { AnyAction } from "redux";
import {
  ActionTypes,
  getAllChangeRefinanceSuccess,
  getAllChangeRefinanceError,
  saveAllChangeRefinanceSuccess,
  saveAllChangeRefinanceError,
  getAllMFPercentSuccess,
  getAllMFPercentError,
  saveMFPercentSuccess,
  saveMFPercentError,
} from "./actions";
import { putError } from '../global/actions';
import { MFPercent, RefinanceRate } from "./types";
import ApiClient from "../../services/api";

function* getRefinanceRate() {
  try {
    const result: RefinanceRate[] = yield call(ApiClient.getRefinanceRate);

    yield put(getAllChangeRefinanceSuccess(result));
  } catch (error) {
    yield put(putError(error));
    yield put(getAllChangeRefinanceError(error));
  }
}

function* saveRefinanceRate(action: AnyAction) {
  try {
    const result: RefinanceRate[] = yield call(ApiClient.saveRefinanceRate, action.payload);

    yield put(saveAllChangeRefinanceSuccess(result));
  } catch (error) {
    yield put(putError(error));
    yield put(saveAllChangeRefinanceError(error));
  }
}

function* getMFPercent() {
  try {
    const result: MFPercent[] = yield call(ApiClient.getMFPercent);

    yield put(getAllMFPercentSuccess(result));
  } catch (error) {
    yield put(putError(error));
    yield put(getAllMFPercentError(error));
  }
}

function* saveMFPercent(action: AnyAction) {
  try {
    const promises: any[] = [];
    const newMfs = action.payload.filter((item: MFPercent) => item.IsNew).map((item: MFPercent) => ({
      Rate: Number(item.Rate),
      DateFrom: item.DateFrom,
      CurrencyId: item.CurrencyId,
    }));

    const updatedMfs = action.payload.filter((item: MFPercent) => item.IsUpdated && !item.IsNew).map((item: MFPercent) => ({
      Id: Number(item.Id),
      Rate:  Number(item.Rate),
      DateFrom: item.DateFrom,
      CurrencyId: item.CurrencyId,
    }));
    [...newMfs, ...updatedMfs].map((item: any) => {
      promises.push(call(ApiClient.saveMFPercent, item));
    });

    yield all(promises);
    const result: MFPercent[] = yield call(ApiClient.getMFPercent);
    yield put(saveMFPercentSuccess(result));
  } catch (error) {
    yield put(putError(error));
    yield put(saveMFPercentError(error));
  }
}

function* otherActionsSaga() {
  yield all([
    yield takeEvery(ActionTypes.GET_ALL_CHANGE_REFINANCE_REQUEST, getRefinanceRate),
    yield takeEvery(ActionTypes.SAVE_ALL_CHANGE_REFINANCE_REQUEST, saveRefinanceRate),
    yield takeEvery(ActionTypes.GET_ALL_MF_PRECENT_REQUEST, getMFPercent),
    yield takeEvery(ActionTypes.SAVE_MF_PERCENT_REQUEST, saveMFPercent),
  ]);
}

export default otherActionsSaga;
