import React, { PureComponent } from "react";
import moment from 'moment';
import { isEmpty, get, last } from 'lodash';

import { PayCalendarState, PayInfo } from "../../../../store/billDetails";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import styled from "../../../../styled/styled-components";
import ErrorMessage from "../../../Errors/components/ErrorMessage";

export interface PayCalendarProps {
  payCalendar: PayCalendarState,
}

export interface PayCalendarExtraState {
  loanDays: number,
}

const Head = styled.tr`
    color: #fff;
    background-color: #337ab7;
    font-size: 10px;
`;

const Table = styled.table`
    font-size: 10px;
`;
const Footer = styled.tr`
    font-weight: bold;
`;

const PrimaryCol = styled.td`
    background-color: #d9edf7;
`;

const DangerCol = styled.td`
    background-color: #f2dede;
`;


export default class PayCalendar extends PureComponent<PayCalendarProps, PayCalendarExtraState> {
  public state: PayCalendarExtraState = {
    loanDays: 0,
  };

  public render() {
    const { payCalendar: { loading, data, error } } = this.props;
    if (!isEmpty(error)) {
      return (
        <div className="alert alert-danger mt-3 d-print-none">
          <ErrorMessage errorData={{ message: (error && error.Message) || 'Ошибка', verbose: (error && error.ExceptionMessage) || 'Кажется имеет место быть досадная ошибка.... Попробуйте обновить страницу'}} />
        </div>
      );
    }
    const lastRow = this.getLastRow();
    return (
      <LoadingOverlay loading={loading}>
        <div>
          <div className="panel-title text-center mt-4">
            <h3>Фактические платежи</h3>
          </div>
          <div className="panel-body mt-4">
            <Table className="table table-stripped table-responsive table-sm">
              <thead>
              <tr>
                <th/>
                <th colSpan={5} className="text-center">Начислено</th>
                <th colSpan={5} className="text-center">Оплата</th>
                <th colSpan={5} className="text-center">Просрочка</th>
              </tr>
              <Head>
                <th>Дата</th>
                <th>Пени</th>
                <th>Проценты</th>
                <th>ЧВ</th>
                <th>Тело займа</th>
                <th>Платеж</th>
                <th>Пени</th>
                <th>Проценты</th>
                <th>ЧВ</th>
                <th>Тело займа</th>
                <th>Платеж</th>
                <th>Пени</th>
                <th>Проценты</th>
                <th>ЧВ</th>
                <th>Тело займа</th>
                <th>Платеж</th>
                <th>Дней просрочки</th>
              </Head>
              </thead>
              <tbody className="text-left">
              {data && data.PayInfo.map((item: PayInfo, index) => (
                <tr key={index}>
                  <td>{moment(item.Date).format('DD.MM.YYYY')}</td>
                  <PrimaryCol>
                    {item.PlanPayment && (item.PlanPayment.Penalty).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </PrimaryCol>
                  <PrimaryCol>
                    {item.PlanPayment && (item.PlanPayment.Percent).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </PrimaryCol>
                  <PrimaryCol>
                    {item.PlanPayment && (item.PlanPayment.MemberFee).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </PrimaryCol>
                  <PrimaryCol>
                    {item.PlanPayment && (item.PlanPayment.BodyBack).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </PrimaryCol>
                  <PrimaryCol>
                    {item.PlanPayment && (item.PlanPayment.MonthlyPay).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </PrimaryCol>
                  <td>
                    {item.FactPayment && (item.FactPayment.Penalty).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </td>
                  <td>
                    {item.FactPayment && (item.FactPayment.Percent).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </td>
                  <td>
                    {item.FactPayment && (item.FactPayment.MemberFee).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </td>
                  <td>
                    {item.FactPayment && (item.FactPayment.BodyBack).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </td>
                  <td>
                    {item.FactPayment && (item.FactPayment.MonthlyPay).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </td>
                  <DangerCol>
                    {item.LatePayment && (item.LatePayment.Penalty).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </DangerCol>
                  <DangerCol>
                    {item.LatePayment && (item.LatePayment.Percent).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </DangerCol>
                  <DangerCol>
                    {item.LatePayment && (item.LatePayment.MemberFee).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </DangerCol>
                  <DangerCol>
                    {item.LatePayment && (item.LatePayment.BodyBack).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </DangerCol>
                  <DangerCol>
                    {item.LatePayment && (item.LatePayment.MonthlyPay).toLocaleString("ru", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </DangerCol>
                  <DangerCol>
                    {data && this.renderLateDates(index, item.Date, item.IsLate)}
                  </DangerCol>
                </tr>
              ))}
              {lastRow && <Footer>
                <td>Итого</td>
                <PrimaryCol>
                  {this.localize(this.sumField('PlanPayment.Penalty'))}
                </PrimaryCol>
                <PrimaryCol>
                  {this.localize(this.sumField('PlanPayment.Percent'))}
                </PrimaryCol>
                <PrimaryCol>
                  {this.localize(this.sumField('PlanPayment.MemberFee'))}
                </PrimaryCol>
                <PrimaryCol>
                  {this.localize(this.sumField('PlanPayment.BodyBack'))}
                </PrimaryCol>
                <PrimaryCol>
                  {this.localize(this.sumField('PlanPayment.MonthlyPay'))}
                </PrimaryCol>
                <td>
                  {this.localize(this.sumField('FactPayment.Penalty'))}
                </td>
                <td>
                  {this.localize(this.sumField('FactPayment.Percent'))}
                </td>
                <td>
                  {this.localize(this.sumField('FactPayment.MemberFee'))}
                </td>
                <td>
                  {this.localize(this.sumField('FactPayment.BodyBack'))}
                </td>
                <td>
                  {this.localize(this.sumField('FactPayment.MonthlyPay'))}
                </td>
                <DangerCol>
                  {lastRow.LatePayment && (lastRow.LatePayment.Penalty).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </DangerCol>
                <DangerCol>
                  {lastRow.LatePayment && (lastRow.LatePayment.Percent).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </DangerCol>
                <DangerCol>
                  {lastRow.LatePayment && (lastRow.LatePayment.MemberFee).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </DangerCol>
                <DangerCol>
                  {lastRow.LatePayment && (lastRow.LatePayment.BodyBack).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </DangerCol>
                <DangerCol>
                  {lastRow.LatePayment && (lastRow.LatePayment.MonthlyPay).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </DangerCol>
                <DangerCol>
                  {data && this.renderLateDates(data.PayInfo.length - 1, moment().format("YYYY-MM-DD"), lastRow.IsLate)}
                </DangerCol>
              </Footer>}
              </tbody>
            </Table>
          </div>
        </div>
      </LoadingOverlay>
    );
  }

  private sumField = (fieldName: string) => {
    const { payCalendar: { data } } = this.props;

    return data ? data.PayInfo.reduce((sum: number, item: PayInfo) => {
      return sum + get(item, fieldName, 0);
    }, 0) : 0;
  };

  private getLastRow = () => {
    const { payCalendar: { data } } = this.props;

    return data ? last(data.PayInfo) : undefined;
  };

  private getLateRowBeforeCurrent = (index: number) => {
    const { payCalendar: { data } } = this.props;

    const arr = data ? data.PayInfo : [];

    let lateIndex = index;
    let seeNotLate = false;

    for (let i = index - 1; i >= 0 && !seeNotLate; i--) {
      if (arr[i].IsLate) {
        lateIndex = i;
      } else {
        seeNotLate = true;
      }
    }

    return arr[lateIndex];
  };

  private localize = (value: number) => {
    return value.toLocaleString("ru", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  };

  private renderLateDates = (index: number, date: string, isLate: boolean) => {
    const { payCalendar: { data } } = this.props;

    if (!data || !isLate ) {

      return 'Нет';
    }

    const latestRow =  this.getLateRowBeforeCurrent(index);

    if (!latestRow ) {

      return 0;
    }

    return moment(date).diff(moment(latestRow.Date), 'days')
  }
}
