import * as React from "react";
import Helmet from 'react-helmet';
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import ListHeader from "../components/ListHeader";
import ApiClient from "../../../../services/api";
import {
  DEFAULT_FILTER_FIELDS,
  DEFAULT_SORT_FIELD_NAME,
  DEFAULT_SORT_DIRECTION,
  AUTOCOMPLETE_FILTER_FIELDS
} from "../constants";
import {
  UserItem,
  FilterFieldName,
  FilterRequest,
  OperatorType,
  FilterItemData
} from "../types";
import { showErrorToast } from "../../../../components/ErrorToast";
import TableList from "../../../../components/BillTables/TableList";
import Link from "../../../../components/Link";
import Pagination from "../../../../components/Pagination/Pagination";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import { requestDataToQuery } from "../../../../utils";
import { fetchLabelsRequest } from "../../../../store/labels/actions";

export interface UsersListProps extends RouteComponentProps {
  fetchLabelsRequest: (section: string, billType?: string) => void;
}

export interface UsersListState {
  users: UserItem[];
  filters: FilterRequest;
  cachedFilters: FilterRequest;
  visibleFields: Map<FilterFieldName<UserItem>, boolean>;
  filtersShown: boolean;
  fieldFilters: Map<FilterFieldName<UserItem>, FilterItemData>;
  autocompleteItems: {
    [fieldName in FilterFieldName<UserItem>]?: string[]
  };
  loading: boolean;
}

const DEFAULT_FILTERS = {
  CurrentPage: 1,
  FieldInfos: [
    {
      FieldName: "UserName" as FilterFieldName<UserItem>,
      Method: 1,
      Value: null,
      ValueType: 1
    },
    {
      FieldName: "SecGroupName" as FilterFieldName<UserItem>,
      Value: null,
      Method: 1,
      ValueType: 5
    },
    {
      FieldName: "CreateDate" as FilterFieldName<UserItem>,
      Value: null,
      Method: 4,
      ValueType: 4
    },
    {
      FieldName: "IsActive" as FilterFieldName<UserItem>,
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  IsPaging: true,
  Operator: 1 as OperatorType,
  PageSize: 25,
  SortDirection: DEFAULT_SORT_DIRECTION,
  SortFieldName: DEFAULT_SORT_FIELD_NAME,
  TotalPages: 1
};

const getDefaultVisibleFields = (
  map: Map<FilterFieldName<UserItem>, FilterItemData>
) => {
  const result = new Map<FilterFieldName<UserItem>, boolean>();

  map.forEach(item => {
    result.set(item.FieldName, true);
  });

  return result;
};

class Audit extends React.Component<
  UsersListProps,
  UsersListState
> {
  public state: UsersListState = {
    users: [],
    visibleFields: getDefaultVisibleFields(DEFAULT_FILTER_FIELDS),
    filtersShown: false,
    filters: DEFAULT_FILTERS,
    cachedFilters: DEFAULT_FILTERS,
    fieldFilters: DEFAULT_FILTER_FIELDS,
    autocompleteItems: {},
    loading: false
  };
  // @ts-ignore
  private usersTableElement = React.createRef<TableList<UserItem>>();

  public componentDidMount() {
    this.getData();
  }

  public render() {
    return (
      <LoadingOverlay loading={this.state.loading}>
        <Helmet defer={false}>
          <title>История действий</title>
        </Helmet>
        <ListHeader />
        <TableList
          data={this.state.users}
          visibleFields={this.state.visibleFields}
          renderActions={this.renderActions}
          sortProps={{
            sortFieldName: this.state.filters.SortFieldName,
            sortDirection: this.state.filters.SortDirection,
            onChangeSort: this.handleChangeSort
          }}
          filtersProps={{
            fields: [...this.state.fieldFilters.values()],
            onChangeFilter: this.handleChangeFilter,
            acceptFilter: this.acceptFilter,
            resetFilter: this.resetFilter,
            filtersShown: this.state.filtersShown,
            autocompleteItems: this.state.autocompleteItems
          }}
          ref={this.usersTableElement}
          labelsSection={'AUDIT_FIELD_LABELS'}
          notUseActive
          withoutFilters
        />
        {this.state.filters.TotalPages > 1 && (
          <Pagination
            size={this.state.filters.PageSize}
            count={this.state.filters.TotalPages * this.state.filters.PageSize}
            page={this.state.filters.CurrentPage}
            callback={this.handleChangePage}
          />
        )}
      </LoadingOverlay>
    );
  }

  private getData = async (
    filter: Partial<FilterRequest> = {},
    forceGoToDetails?: boolean
  ) => {

    this.setState({ loading: true });
    try {
      const result = await ApiClient.getUserLogs();

      if (forceGoToDetails && result.DataSource.length === 1) {
        this.setState({ loading: false });
      }

      this.setState({
        users: result,
      });
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private handleChangeFilter = (
    filterItemData: FilterItemData,
    forceFiltering?: boolean
  ) => {
    forceFiltering = forceFiltering || filterItemData.ValueType === 4 || filterItemData.ValueType === 5;

    this.setState(
      state => {
        const newFilters = new Map(state.fieldFilters);
        const currentFilterItem = newFilters.get(filterItemData.FieldName);

        if (currentFilterItem) {
          newFilters.set(filterItemData.FieldName, filterItemData);
        }

        return {
          fieldFilters: newFilters,
          filters: {
            ...state.filters,
            FieldInfos: this.getFieldInfos(newFilters)
          }
        };
      },
      () => {
        this.proccessAutocomplete(filterItemData);
        if (forceFiltering) {
          this.acceptFilter(false);
        }
      }
    );
  };

  private acceptFilter = (forceGoToDetails?: boolean) => {
    const { FieldInfos, Operator } = this.state.filters;

    this.getData(
      {
        Operator,
        FieldInfos: FieldInfos.filter(item => item && item.Value !== null),
        CurrentPage: 1
      },
      forceGoToDetails
    );
  };

  private getFieldInfos = (
    fieldFilters: Map<FilterFieldName<UserItem>, FilterItemData>
  ) => {
    return [...fieldFilters.values()];
  };

  private handleChangeSort = (fieldNamme: FilterFieldName<UserItem>) => {
    this.setState(
      state => {
        const currentSortFieldName = state.filters.SortFieldName;
        const curretSortDirection = state.filters.SortDirection;

        const newSortDirection =
          currentSortFieldName !== fieldNamme
            ? 1
            : curretSortDirection === 1
            ? 2
            : 1;

        return {
          filters: {
            ...state.filters,
            SortFieldName: fieldNamme,
            SortDirection: newSortDirection
          }
        };
      },
      () => {
        this.getData({
          Operator: this.state.filters.Operator,
          FieldInfos: this.state.filters.FieldInfos.filter(
            item => item && item.Value !== null
          )
        });
      }
    );
  };

  private proccessAutocomplete = async (filterItemData: FilterItemData) => {
    const fieldName = filterItemData.FieldName;
    const value = filterItemData.Value;

    if (AUTOCOMPLETE_FILTER_FIELDS.indexOf(fieldName) === -1) {
      return;
    }

    if (!value || value.length < 2) {
      this.setState({
        autocompleteItems: {
          [fieldName]: []
        }
      });

      return;
    }

    const {
      FieldInfos,
      Operator,
      SortFieldName,
      // tslint:disable-next-line:no-shadowed-variable
      SortDirection
    } = this.state.filters;

    const queryString = requestDataToQuery({
      SortFieldName,
      SortDirection,
      Operator,
      FieldInfos: FieldInfos.filter(item => item && item.Value !== null),
      propertyName: fieldName
    });

    try {
      this.setState({
        autocompleteItems: {
          [fieldName]: await ApiClient.getAutocompleteForClients(queryString)
        }
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  private renderActions = (item: UserItem) => (
    <React.Fragment>
      <Link href={this.getLinkToNumberCodeEdit(item)}>
        Подробно
      </Link>
    </React.Fragment>
  );

  private resetFilter = (fieldName: keyof UserItem) => {
    const filterItemData = DEFAULT_FILTERS.FieldInfos.find(
      ({ FieldName }) => FieldName === fieldName
    );

    if (filterItemData) {
      this.handleChangeFilter(filterItemData, true);
    }
  };

  private handleChangePage = (page: number) => {
    this.getData({
      Operator: this.state.filters.Operator,
      FieldInfos: this.state.filters.FieldInfos.filter(
        item => item && item.Value !== null
      ),
      CurrentPage: page
    });
  };

  private getLinkToNumberCodeEdit = (item: UserItem) => {
    return `/Audit/${item.UserId}`;
  };
}


const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchLabelsRequest: (section: string, billType?: string) => dispatch(fetchLabelsRequest(section, billType)),
});

export default connect(undefined, mapDispatchToProps)(Audit)
