import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';
import moment from 'moment/moment';

import { GlobalConfig, CommonConfig } from './_config';

require('highcharts/modules/no-data-to-display')(ReactHighcharts.Highcharts);

ReactHighcharts.Highcharts.setOptions(GlobalConfig);

export default class Chart extends Component {
  render() {
    const {
      series = [],
      minTickInterval = moment.duration(1, 'month').milliseconds(),
      xDateFormat,
      xLabel,
      yLabel,
      title,
    } = this.props;

    const config = {
      ...CommonConfig,
      title: { text: title },
      xAxis: {
        type: 'datetime',
        title: { text: xLabel },
        minTickInterval,
      },
      yAxis: {
        title: { text: yLabel },
      },
      series,
    };
    config.tooltip.xDateFormat = xDateFormat;

    return <ReactHighcharts config={config} />;
  }
}
