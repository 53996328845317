import moment from 'moment';

// eslint-disable-next-line no-unused-vars
function normalize(data, modificator) {
  return data.map(([key, value]) => [key + modificator, value]);
}

// eslint-disable-next-line no-unused-vars
function getCumulative(points) {
  return points.reduce((result, [x, y]) => {
    const lastIndex = result.length - 1;
    const lastY = lastIndex >= 0 ? result[lastIndex][1] : 0;
    result.push([x, y + lastY]);
    return result;
  }, []);
}

class Point {
  constructor({
    EndDate, InValue, OutValue, StartDate, Value, Genders,
  }) {
    this.EndDate = EndDate;
    this.InValue = InValue;
    this.OutValue = OutValue;
    this.StartDate = StartDate;
    this.Value = Value;
    this.Genders = Genders;
  }

  toChartPoint(startOf) {
    const correctionInterval = startOf === 'day' ? 'h' : 'd';
    return [
      moment(this.StartDate)
        .startOf(startOf)
        .add(1, correctionInterval)
        .valueOf(),
      this.Value,
    ];
  }

  toChartPointForGender(gender, startOf) {
    const correctionInterval = startOf === 'day' ? 'h' : 'd';
    const info = this.Genders.find(item => item.Gender === gender);
    return [
      moment(this.StartDate)
        .startOf(startOf)
        .add(1, correctionInterval)
        .valueOf(),
      info ? info.Value : 0,
    ];
  }
}

class CurrencyPoints {
  constructor({
    Currency, Periods, Sums, PartnersCounts,
  }) {
    this.Currency = Currency;
    this.Periods = Periods;
    this.Sums = Sums;
    this.PartnersCounts = PartnersCounts;
  }
}

const factory = (...args) => {
  const data = args.reduce((acc, val) => acc.concat(val), []);
  return data.reduce((result, {
    Currency, Periods = [], Sums = [], PartnersCounts = [],
  }) => {
    const periodsSet = Periods.map((sets = []) => {
      if (!sets) {
        return [];
      }
      return sets.map(set => new Point(set));
    });
    const sumsSet = Sums.map(sum => new Point(sum));
    const partnerCountsSet = PartnersCounts.map(counts => new Point(counts));
    const set = new CurrencyPoints({
      Currency,
      Periods: periodsSet,
      Sums: sumsSet,
      PartnersCounts: partnerCountsSet,
    });
    result.push(set);
    return result;
  }, []);
};

export default factory;
