import { all, call, put, takeEvery } from "redux-saga/effects";
import { AnyAction } from "redux";
import {
  ActionTypes,
  getCommonBillForOperationSuccess,
  getCommonBillForOperationError
} from "./actions";
import { putError } from '../global/actions';
import ApiClient from "../../services/api";

function* getCommonBillForOperation(action: AnyAction) {
  try {
    const result = yield call(ApiClient.getCommonBillForOperation, action.payload );
    yield put(getCommonBillForOperationSuccess(result));

  } catch (error) {
    yield put(putError(error));
    yield put(getCommonBillForOperationError(error));
  }
}

function* billsSaga() {
  yield all([
    yield takeEvery(ActionTypes.GET_COMMON_BILL_FOR_OPERATION_REQUEST, getCommonBillForOperation),
  ]);
}

export default billsSaga;
