import * as React from "react";
import styled from "styled-components";
import Button, { ButtonProps } from "../../../../components/Button/Button";
import { Heading } from "../../../../components/Typography";
import { connect } from "react-redux";
import { AppState } from "../../../../store";
import { ActiveRow } from "../../../../store/forms";
import BackLink from "../../../../components/BackLink";

const Container = styled.header`
  margin: -24px -24px 0;
  padding: 14px 15px 7px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #e7eaec;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const HeaderButton = styled(Button)`
  margin-left: 5px;
`;

const ListHeader: React.SFC<{
  printButton?: React.ReactNode;
  activeRow?: ActiveRow
}> = React.memo(({ printButton, activeRow }) => (
  <Container>
    <BackLink title={document.title} >
      <Heading>Зарплатные проекты</Heading>
    </BackLink>
    <ButtonsWrapper>
      <HeaderButton href="/Deposit/AddSalary" type="success">
        Добавить
      </HeaderButton>
      <HeaderButton href={activeRow ? `/Operation/OperationProcess?partnerNumber=${activeRow.PartnerNumber}&billCode=${activeRow.BillCode}&returnUrl=${encodeURIComponent(document.location.pathname)}${encodeURIComponent(document.location.search)}` : `/Operation/OperationProcess?returnUrl=${encodeURIComponent(document.location.pathname)}${encodeURIComponent(document.location.search)}` } routerLink type="success">
        Операции
      </HeaderButton>
      {printButton}
    </ButtonsWrapper>
  </Container>
));

export class PrintButton extends React.Component<ButtonProps> {
  public render() {
    return (
      <HeaderButton {...this.props} type="success">
        Печать
      </HeaderButton>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  // @ts-ignore
  activeRow: state.forms.tableForm.activeRow,
});

export default connect(mapStateToProps)(ListHeader)
