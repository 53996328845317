import React from "react";
import { get, isEmpty, noop, set } from 'lodash';
import { toast } from "react-toastify";
import moment from "moment";
import { BatchOperationsFormProps, BatchOperationsFormState, RequiredStringFields } from "./types";
import BatchOperationsTable from "../../../PaymentRequests/components/BatchOperationsTable";
import styled from "../../../../styled/styled-components";
import FormControl from "../FormControl";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import { NONE_VALUE } from "../../../../constants";
import { ALFA_BANK } from "../../../../constants/bankNames";
import { MF_LTD } from "../../../../constants/companyNames";
import Button from "../../../../components/Button/Button";
import { SubmitData } from "../../../../store/batchOperations";
import { convertToUtc } from "../../../../utils";

const RequestsWrapperTable = styled.div`
  max-height: 250px;
  overflow-y: scroll;
`;

export default class BatchOperationsForm extends React.Component<BatchOperationsFormProps> {

  public static getDerivedStateFromProps(nextProps: BatchOperationsFormProps, prevState: BatchOperationsFormState) {
    if ((prevState.BankUserId === 0 || prevState.AzonUserId === 0) && nextProps.userData && nextProps.userData.Id) {
      return {
        BankUserId: Number(nextProps.userData.Id),
        AzonUserId: Number(nextProps.userData.Id),
      };
    }

    // Return null to indicate no change to state.
    return null;
  }
  public state: BatchOperationsFormState = {
    PaymentType: 'Безналичным переводом - Альфа-Банк (по заявке)',
    BankUserId: 0,
    BankPaymentNumber: "",
    BankPaymentDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
    AzonUserId: 0,
    BankPaymentOrderNumber: "",
    AzonDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
    BankName: ALFA_BANK,
    CompanyName: MF_LTD,
    DetailInfo: "",
    IsInformPartner: false,
    formErrors: {
      BankPaymentNumber: '',
      BankPaymentOrderNumber: '',
      DetailInfo: '',
    }
  };

  public componentDidMount() {
    const { getCommonBillForOperationRequest, getEmployeesRequest } = this.props;

    getCommonBillForOperationRequest();
    getEmployeesRequest();
  }

  public componentDidUpdate(prevProps: Readonly<BatchOperationsFormProps>) {
    if (prevProps.submitting && !this.props.submitting && this.props.message) {
      toast(this.props.message, {
        type: toast.TYPE.SUCCESS
      });
      setTimeout(this.props.goToBack, 1000);
    }
  }

  public render() {
    const { paymentRequests, bill, loading, employees, goToBack, submitting } = this.props;

    if (!paymentRequests) {
      return null;
    }
    const { PaymentType, BankUserId, BankPaymentNumber, BankPaymentDate, AzonDate, AzonUserId, BankName, BankPaymentOrderNumber, CompanyName, DetailInfo, IsInformPartner, formErrors } = this.state;
    return (
      <LoadingOverlay loading={loading}>
        <FormControl onChange={noop} label="Заявки" type="component">
          <RequestsWrapperTable><BatchOperationsTable batchOperations={paymentRequests} /></RequestsWrapperTable>
        </FormControl>
        <FormControl
          onChange={noop}
          value={bill && bill.BillInfo}
          label="Cчет списания"
          disabled
        />
        <FormControl
          onChange={noop}
          value={paymentRequests.TotalSum}
          label="Сумма"
          isNumber
          disabled
        />
        <FormControl
          onChange={noop}
          value={PaymentType}
          label="Тип платежа"
          disabled
        />
        <FormControl
          onChange={noop}
          value={this.getRequestIds()}
          label="Номера заявок"
          disabled
        />
        <FormControl
          onChange={this.handleChange('BankUserId')}
          value={BankUserId}
          // @ts-ignore
          data={employees}
          label="Альфа-Банк \ Исполнитель"
          type="select"
        />
        <FormControl
          onChange={this.handleChange("BankPaymentNumber")}
          value={BankPaymentNumber}
          onFocus={this.onFocus('BankPaymentNumber')}
          error={formErrors.BankPaymentNumber}
          label="Альфа-Банк \ № платежа"
        />
        <FormControl
          onChange={this.handleChangeDate("BankPaymentDate")}
          value={BankPaymentDate}
          label="Альфа-Банк \ Дата"
          type="date"
        />
        <FormControl
          onChange={this.handleChange("AzonUserId")}
          value={AzonUserId}
          // @ts-ignore
          data={employees}
          label="АЗОН \ Исполнитель"
          type="select"
        />
        <FormControl
          onChange={this.handleChange("BankPaymentOrderNumber")}
          value={BankPaymentOrderNumber}
          onFocus={this.onFocus('BankPaymentOrderNumber')}
          error={formErrors.BankPaymentOrderNumber}
          label="АЗОН \ № ведомости"
        />
        <FormControl
          onChange={this.handleChangeDate("AzonDate")}
          value={AzonDate}
          label="АЗОН \ Дата"
          type="date"
        />
        <FormControl
          onChange={this.handleChange("BankName")}
          value={BankName}
          label="Название банка"
          disabled
        />
        <FormControl
          onChange={this.handleChange("CompanyName")}
          value={CompanyName}
          label="Название компании"
          disabled
        />
        <FormControl
          onChange={this.handleChange("DetailInfo")}
          value={DetailInfo}
          onFocus={this.onFocus('DetailInfo')}
          error={formErrors.DetailInfo}
          label="Информация по платежу"
        />
        <FormControl
          onChange={this.handleChangeCheckbox("IsInformPartner")}
          value={IsInformPartner}
          label="Информировать клиента"
          type="checkbox"
        />
        <div className="row">
          <div className="col text-right">
            <Button type="success" className="ml-3" onClick={this.submitForm} loading={submitting}>
              Провести операцию
            </Button>
            <Button type="error" className="ml-3" onClick={goToBack} loading={submitting}>
              Назад
            </Button>
          </div>
        </div>
      </LoadingOverlay>
    );
  }

  private onFocus = (fieldName: keyof RequiredStringFields) => (e: any) => {
    const { formErrors } = this.state;
    this.setState(() => ({
      formErrors: {
        ...formErrors,
        [fieldName]: ""
      }
    }));
  };

  private getRequestIds = (): string => {
    const { paymentRequests } = this.props;
    if (!paymentRequests) {

      return '';
    }
    const requestIds = paymentRequests.Requests.map((item) => item.Id);

    return requestIds.join(', ');
  };

  private handleChange = (fieldName: keyof BatchOperationsFormState) => (e: any) => {
    const { target: { value } } = e;
    this.setState({
      [fieldName]: value
    });
  };

  private handleChangeDate = (fieldName: keyof BatchOperationsFormState) => (date: Date, event: any) => {
    if (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value)) {
      const value = date ? convertToUtc(date).toISOString() : null;
      this.setState({
        [fieldName]: value === NONE_VALUE || value === "" ? null : value,
      });
    }
  };

  private handleChangeCheckbox = (fieldName: keyof BatchOperationsFormState) => (e: any) => {
    const { target: { checked } } = e;
    this.setState({
      [fieldName]: checked
    });
  };

  private validateForm = () => {
    const requiredFields = ["BankPaymentNumber", "BankPaymentOrderNumber", "DetailInfo"];

    const state = this.state;
    const formErrors = {};

    requiredFields.map((item) => {
      if (!get(state, item, "")) {
        set(formErrors, item, "Поле обязательно для заполнения");
      }
    });

    this.setState({
      formErrors
    });

    return isEmpty(formErrors);
  };

  private submitForm = () => {
    const { bill, paymentRequests, submitFormRequest } = this.props;
    if (this.validateForm() && bill && paymentRequests) {
      const { DetailInfo, IsInformPartner, BankUserId, BankPaymentNumber, BankPaymentDate, AzonUserId, BankPaymentOrderNumber, AzonDate, BankName, CompanyName } = this.state;
      const data: SubmitData = {
        BillId: bill.Id,
        Direction: false,
        OperationTypeId: 105,
        DetailInfo,
        IsInformPartner,
        OperationDate: moment().format('YYYY-MM-DD'),
        Sum: paymentRequests.TotalSum,
        OperationDetails: {
          Type: 4,
          BankUserId,
          BankPaymentNumber,
          BankPaymentDate: moment(BankPaymentDate).format('YYYY-MM-DD'),
          AzonUserId,
          BankPaymentOrderNumber,
          AzonDate: moment(AzonDate).format('YYYY-MM-DD'),
          BankName,
          CompanyName,
          RequestIds: paymentRequests.Requests.map(item => item.Id)
        }
      };
      submitFormRequest(data);
    }
  }
}
