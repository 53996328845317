import * as React from "react";
import styled from "../../styled/styled-components";
import Modal from "../Modal";
import Button from "../Button/Button";

const Message = styled.div`
  margin-bottom: 25px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  margin: 0 10px;
`;

export interface ConfirmDialogProps {
  successLabel: React.ReactNode | null;
  cancelLabel: React.ReactNode | null;
  onCancel?: () => void;
  onConfirm?: () => void;
}

export interface ConfirmDialogState {
  isOpen: boolean;
  message: React.ReactNode;
}

export default class ConfirmDialog extends React.Component<
  ConfirmDialogProps,
  ConfirmDialogState
> {
  public static defaultProps = {
    successLabel: "Подтвердить",
    cancelLabel: "Отмена"
  };

  public state: ConfirmDialogState = {
    isOpen: false,
    message: "Подтвердите действие"
  };

  private callback: ((allow: boolean) => void) | null = null;

  public render() {
    return (
      <Modal
        header="Подтвердите действие"
        isOpen={this.state.isOpen}
        onBackgroundClick={this.cancel}
        onEscapeKeydown={this.cancel}
        beforeClose={this.handleClose}
      >
        <Message>{this.renderMessage()}</Message>
        <Buttons>{this.renderButtons()}</Buttons>
      </Modal>
    );
  }

  public open = (message: string, callback: (allow: boolean) => void) => {
    this.callback = callback;
    this.setState({ isOpen: true, message });
  };

  public close = () => {
    this.setState({ isOpen: false });
  };

  private confirm = () => {
    if (this.callback) {
      this.callback(true);
    }

    this.close();
  };

  private cancel = () => {
    if (this.callback) {
      this.callback(false);
    }

    this.close();
  };

  private handleClose = () => {
    this.callback = null;
  };

  private renderMessage = () => this.state.message;

  private renderButtons = () => {
    const { successLabel, cancelLabel } = this.props;
    return (
      <React.Fragment>
        {successLabel !== null && (
          <ButtonWrapper>
            <Button type="success" onClick={this.confirm}>
              {successLabel}
            </Button>
          </ButtonWrapper>
        )}
        {cancelLabel !== null && (
          <ButtonWrapper>
            <Button type="error" onClick={this.cancel}>
              {cancelLabel}
            </Button>
          </ButtonWrapper>
        )}
      </React.Fragment>
    );
  };
}
