import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { ChevronRight } from 'styled-icons/feather';
import Item from './Item';

class Submenu extends Component {
  render() {
    const { items } = this.props;
    return (
      <Fragment>
        <StyledIcon />
        <StyledMenu>{items.map(item => <Item {...item} />)}</StyledMenu>
      </Fragment>
    );
  }
}

const StyledIcon = styled(ChevronRight)`
  display: flex;
  align-self: flex-end;
  width: 20px;
  height: 20px;
`;
const StyledMenu = styled(Flex).attrs({ justifyStart: true, full: true, column: true })`
   display: none;
   position: absolute;
   top: -4px;
   left: 195px;

   min-width: 200px;
   height: auto;

   border-radius: 3px;
   border: 2px solid ${({ theme }) => theme.colors.lightGray};
   background-color: ${({ theme }) => theme.colors.white};
 `;

export default Submenu;
export { StyledMenu as StyledSubmenu };
