import * as React from "react";
import { omit, get, set, isEmpty, isEqual, toNumber, replace } from "lodash";
import { toast } from "react-toastify";

import FormControl from "../FormControl";
import Button from "../../../../components/Button/Button";
import { IS_ACTIVE_LABELS } from "../../../../constants";

import ApiClient from "../../../../services/api";
import LoadingOverlay from "../../../../components/LoadingOverlay";

export interface UserData {
  UserName: string;
  FirstName: string;
  LastName: string;
  Patronymic: string;
  OfficeFunc: string;
  Email: string;
  Pass: string;
  ConfirmPass: string;
  Phone: string;
}

export interface UsersFormState extends UserData {
  loading: boolean;
  IsActive: number;
  SecGroupId: number;
  formErrors: UserData,
}

export interface UserFormProps {
  userId?: number;
  goToList: () => void;
  fetchLabelsRequest: (section: string, billType?: string) => void;
  labels: { [section: string]: Map<string | number | symbol, string> };
}

export default class CreateUsersForm extends React.PureComponent<UserFormProps> {
  public state: UsersFormState = {
    loading: false,
    LastName: "",
    UserName: "",
    FirstName: "",
    Patronymic: "",
    OfficeFunc: "",
    Email: "",
    SecGroupId: 16,
    Pass: "",
    ConfirmPass: "",
    IsActive: 1,
    Phone: "",
    formErrors: {
      UserName: "",
      LastName: "",
      FirstName: "",
      Patronymic: "",
      OfficeFunc: "",
      Email: "",
      Pass: "",
      ConfirmPass: "",
      Phone: ""
    }
  };

  public componentDidMount() {
    // tslint:disable-next-line:no-shadowed-variable
    const { fetchLabelsRequest, userId } = this.props;
    fetchLabelsRequest("GROUPS");
    if (userId) {
      this.getUserById(userId);
    }
  }

  public render() {
    const {
      UserName,
      LastName,
      FirstName,
      Patronymic,
      OfficeFunc,
      Pass,
      ConfirmPass,
      Email,
      SecGroupId,
      IsActive,
      Phone,
      formErrors,
      loading
    } = this.state;

    const { labels, userId } = this.props;

    const groups = get(labels, "GROUPS", new Map([]));

    const reverseMap = (item: any) => [...item.entries()].reduce((acc, [k, v]) => {
      acc.has(v) ? acc.set(v, acc.get(v).concat(k)) : acc.set(v, [k]);
      return acc;
    }, new Map());

    const data = reverseMap(groups);

    return (
      <LoadingOverlay loading={loading}>
        <FormControl
          onChange={this.handleChange("UserName")}
          onFocus={this.onFocus("UserName")}
          value={UserName}
          label="Логин"
          error={formErrors.UserName}
        />
        <FormControl
          onChange={this.handleChange("LastName")}
          onFocus={this.onFocus("LastName")}
          value={LastName}
          label="Фамилия"
          error={formErrors.LastName}
        />
        <FormControl
          onChange={this.handleChange("FirstName")}
          onFocus={this.onFocus("FirstName")}
          value={FirstName}
          label="Имя"
          error={formErrors.FirstName}
        />
        <FormControl
          onChange={this.handleChange("Patronymic")}
          onFocus={this.onFocus("Patronymic")}
          value={Patronymic}
          label="Отчество"
          error={formErrors.Patronymic}
        />
        <FormControl
          onChange={this.handleChange("Phone")}
          onFocus={this.onFocus("Phone")}
          value={Phone}
          label="Телефон"
          error={formErrors.Phone}
          type="maskInput"
          mask="+7 999 999-99-99"
        />
        <FormControl
          onChange={this.handleChange("OfficeFunc")}
          onFocus={this.onFocus("OfficeFunc")}
          value={OfficeFunc}
          label="Должность"
          error={formErrors.OfficeFunc}
        />
        <FormControl
          onChange={this.handleChange("Email")}
          onFocus={this.onFocus("Email")}
          value={Email}
          label="E-Mail"
          error={formErrors.Email}
        />
        <FormControl
          onChange={this.handleChange("SecGroupId")}
          value={SecGroupId}
          // @ts-ignore
          data={[...data]}
          label="Группа"
          type="select"
        />
        <FormControl
          onChange={this.handleChange("Pass")}
          onFocus={this.onFocus("Pass")}
          value={Pass}
          label="Пароль"
          error={formErrors.Pass}
          inputType="password"
        />
        <FormControl
          onChange={this.handleChange("ConfirmPass")}
          onFocus={this.onFocus("ConfirmPass")}
          value={ConfirmPass}
          label="Подтверждение"
          error={formErrors.ConfirmPass}
          inputType="password"
        />
        <FormControl
          onChange={this.handleChange("IsActive")}
          value={IsActive}
          // @ts-ignore
          data={[...reverseMap(IS_ACTIVE_LABELS).entries()]}
          label="Статус"
          type="select"
        />
        <div className="row">
          <div className="col justify-content-end text-right">
            <Button type="success" className="ml-3" loading={loading} onClick={this.submit}>
              {userId ? "Сохранить" : "Создать"} пользователя
            </Button>
            <Button type="success" className="ml-3" loading={loading} onClick={this.props.goToList}>
              Отменить
            </Button>
          </div>
        </div>
      </LoadingOverlay>
    );
  };

  private submit = () => {
    if (!this.validateForm()) {
      return false;
    }
    this.submitForm();
  };

  private submitForm = async () => {
    const { UserName, LastName, FirstName, Patronymic, OfficeFunc, Email, SecGroupId, Pass, ConfirmPass, IsActive, Phone } = this.state;
    const { userId } = this.props;

    const data = {
      SecUserId: userId,
      UserName,
      LastName,
      FirstName,
      Patronymic,
      OfficeFunc,
      Email,
      SecGroupId,
      IsActive: !!toNumber(IsActive),
      Phone: replace(Phone, /[ \-]/g, '')
    };

    if (!userId || Pass || ConfirmPass) {
      set(data, 'Pass', Pass);
      set(data, 'ConfirmPass', ConfirmPass);
    }

    try {
      this.setState({
        loading: true
      });
      await ApiClient.addUser(data);

      const message = userId ? 'Пользователь успешно обновлен' : 'Пользователь успешно создан';
      toast(message, {
        type: toast.TYPE.SUCCESS
      });
      setTimeout(this.props.goToList, 1000);

    } catch (e) {
      const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
      toast(errorMessage, {
        type: toast.TYPE.ERROR
      });
    } finally {
      this.setState({
        loading: false
      });
    }
  };

  private getUserById = async (id: number) => {
    try {
      this.setState({
        loading: true
      });

      const result = await ApiClient.getUserById(id);
      const phone = result.Phone;
      // if (result.Phone) {
      //   phone = `${phone.substring(0, 2)} ${phone.substring(2, 5)} ${phone.substring(5, 8)}-${phone.substring(8, 10)}-${phone.substring(10)}`;
      // }
      this.setState({
        ...result,
        IsActive: result.IsActive ? 1 : 0,
        Phone: phone,
        ConfirmPass: "",
        Pass: "",
      })

    } catch (e) {
      const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
      toast(errorMessage, {
        type: toast.TYPE.ERROR
      });
    } finally {
      this.setState({
        loading: false
      });
    }
  };

  private validateForm = () => {

    const requiredFields = ["UserName", "LastName", "FirstName", "OfficeFunc", "Email", "Phone"];

    const state = this.state;
    const formErrors = {};
    const { userId } = this.props;

    if (!userId) {
      requiredFields.push("ConfirmPass", "Pass");
    }

    requiredFields.map((item) => {
      if (!get(state, item, "")) {
        set(formErrors, item, "Поле обязательно для заполнения");
      }
    });

    if (!isEqual(state.ConfirmPass, state.Pass)) {
      set(formErrors, 'ConfirmPass', "Пароли не совпадают");
    }

    this.setState(prevState => ({
      ...prevState,
      formErrors: {
        ...state.formErrors,
        ...formErrors
      }
    }));

    if (!isEmpty(formErrors)) {
      toast("Форма не валидна", {
        type: toast.TYPE.ERROR
      });
    }

    return isEmpty(formErrors);
  };

  private handleChange = (fieldName: string) => (e: any) => {
    const { target: { value } } = e;
    this.setState({
      [fieldName]: value
    });
  };

  private onFocus = (fieldName: string) => (e: any) => {
    const { formErrors } = this.state;
    let isValid = true;
    const obj = omit(formErrors, [`${fieldName}`]);
    for (const key in obj) {
      // @ts-ignore
      if (obj[key] !== "") {
        isValid = false;
      }
    }
    this.setState(() => ({
      formErrors: {
        ...formErrors,
        [fieldName]: ""
      },
      formValid: isValid
    }));
  };
}
