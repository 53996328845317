import * as React from 'react';
import moment from "moment";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { TYPE_LABELS } from "../constants";
import { History, Location } from "history";

export const formatValue = (fieldName: string, rawValue: any) => {
  switch (fieldName) {
    case "CreationTime":
      const dateMoment = moment(rawValue);
      if (dateMoment.isValid()) {
        return dateMoment.format(DatePicker.prettyDateTimeFormat);
      }
      break;

    case "IsSended":
      if (rawValue) {
        return (<div className="text-success">Отправлено</div>)
      }
      return (<div className="text-warning">Создано</div>);

    case "TypeId":
      return TYPE_LABELS.get(rawValue);

    case "Users":
      if (rawValue === 'all') {
        return 'Всем пайщикам';
      }
      return 'По списку';

    default:
      return rawValue;
  }
};

export const getPageFromSearch = (search: string) => {
  const urlParams = new URLSearchParams(search.toLowerCase());
  const currentPage = urlParams.get("page");

  return currentPage ? parseInt(currentPage, 10) : 1;
};

export const cutFirstPageFromQuery = (history: History, location: Location) => {
  const queryParams = new URLSearchParams(location.search.toLowerCase());

  if (queryParams.get("page") === "1") {
    queryParams.delete("page");
    history.replace(`${location.pathname}?${queryParams.toString()}`);
  }
};
