import React from 'react';

import Panel from '../../../../../components/Panel';
import { ButtonAdd } from './styled';
import AccountsTable, { AccountsTableProps } from './AccountsTable';
import { UserData } from '../../../types';
import { PartnerChangeHandlerArgs } from './types';

const AccountsTableMemoized: React.FunctionComponent<AccountsTableProps> = React.memo(AccountsTable);

const getInitBankAccount = (PartnerId: string) => ({
  PartnerId,
  Bill: '',
  Bank: 'ALFA-BANK', // todo
  Comment: '',
  CurrencyId: 1,
  Id: null
});

interface AccountsBlockProps {
  editing: boolean;
  userData: UserData;
  handleChange: PartnerChangeHandlerArgs,
}

export default function AccountsBlock(props: AccountsBlockProps) {
  const { editing, userData, handleChange } = props;
  return (
    <Panel type="primary" heading={'Счета "Альфа-Банк"'}>
      {editing && (
        <ButtonAdd
          type="success"
          onClick={handleChange.bind(
            null,
            null,
            'PartnerBankBills',
            [...userData.PartnerBankBills, getInitBankAccount(userData.PartnerId)]
          )}
        >
          Добавить
        </ButtonAdd>
      )}
      <AccountsTableMemoized
        data={userData.PartnerBankBills}
        editing={editing}
        onChange={handleChange.bind(null, null, 'PartnerBankBills')}
      />
    </Panel>
  );
}
