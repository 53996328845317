import { find, first, get, isArray, isEmpty, isEqual, isNil, omit, set, toNumber, toString } from "lodash";
import moment from "moment";
import * as React from "react";
import { toast } from "react-toastify";
import Button from "../../../../components/Button/Button";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import Modal from "../../../../components/Modal";
import { NONE_VALUE } from "../../../../constants";
import bankNames, { ALFA_BANK } from "../../../../constants/bankNames";
import companyNames, { MF_LTD } from "../../../../constants/companyNames";
import operationTypeCodes, { ID_PAY_OF_PERSONAL_SAVING, ID_SALARIES_INCOMING, ID_SALARIES_OUTCOMING_CONVERTATION, operationTypeCodesBool, types as OperationTypes } from "../../../../constants/operationTypeCodes";
import paymentType from "../../../../constants/paymentType";
import ApiClient from "../../../../services/api";
import { UserData } from "../../../../store/auth";
import { Partner, PartnersSearch } from "../../../../store/forms";
import styled from "../../../../styled/styled-components";
import { convertToUtc } from "../../../../utils";
import FormControl from "../FormControl";

export interface BillInfoInterface {
  Id: number;
  BillTypeId: number;
  BillCode: string;
  ProductName: string;
  Balance: number;
  CurrencyName: string;
  BillInfo: string;
}
export interface EmployeeInterface {
  Id: number;
  FullName: string;
}

export interface OperaionsInterface {
  OperationTypeId: number;
  OperationName: string;
  ReferenceBillId: number;
  ReferenceBillCode: string;
  ReferenceBillBalance: number;
  ReferenceBillCurrencyName: string;
  OperationInfo: string;
}

export interface OperationsFormState {
  IdPartner?: string;
  Id?: number;
  Famale?: string;
  Name?: string;
  Patronymic?: string;
  OperationDate?: string;
  DetailInfo: string;
  Bills?: BillInfoInterface[];
  BillId?: number;
  Operations?: OperaionsInterface[];
  OperationTypeId?: number;
  Employees: EmployeeInterface[],
  Direction?: 0 | 1;
  PaymentType: number;
  Sum: number;
  IsInformPartner: boolean;
  // Тип платежа = Наличными
  EmployeesId?: number;
  RkoNumber: string;
  RkoDate: string;
  // Тип платежа = Безналичным переводом - Альфа-Банк
  BankUserId?: number;
  BankPaymentNumber: string;
  BankPaymentDate: string;
  AzonUserId?: number;
  BankPaymentOrderNumber: string;
  AzonDate: string;
  // Тип платежа = Безналичным переводом - Авангард
  RequestId: string;
  BankName: string,
  CompanyName: string,
  CurrencyName: string,
  formErrors: {
    IdPartner: string;
    Famale: string;
    Name: string;
    Patronymic: string;
    OperationDate: string;
    Sum: string;
    DetailInfo: string;
    IsInformPartner: string;
    RkoNumber: string;
    BankPaymentNumber: string;
    BankPaymentOrderNumber: string;
    Direction: string;
    BillId: string;
    OperationTypeId: string;
  },
  loading: boolean;
  loadingBills: boolean;
  loadingOperations: boolean;
  loadingEmployees: boolean;
  isOpen: boolean;
  Rate: number;
}

export interface OperationsFormProps {
  partnerNumber?: string;
  billCode?: string;
  searchPartnersRequest: (data: Partner) => void;
  goToBack: () => void;
  successCreate: () => void;
  search: PartnersSearch;
  Direction?: boolean;
  Sum?: number;
  PaymentType?: number;
  RequestId?: number;
  OperationTypeId?: number;
  userData?: UserData;
}

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.defaultFont}
`;
const Title = styled.div`
  width: 40%;
  font-weight: bold;
  padding: 5px 5px 5px 0;
`;
const Value = styled.div`
  width: 60%;
  padding: 5px 5px 5px 0;
`;

const EMPTY_DATA = [["-/-", ""]];
const PAYMENT_OF_PERSONAL_SAVINGS_ID = [22, 105, ID_SALARIES_OUTCOMING_CONVERTATION];

const CURRENT_BILLS_ID = 1;
// const LOANS_BILLS_ID = 4;
// const SALARIES_BILLS_ID = 5

export default class OperationsForm extends React.PureComponent<OperationsFormProps> {
  // @ts-ignore
  public state: OperationsFormState = OperationsForm.InitialState();
  // @ts-ignore
  private static InitialState() {
    return {
      IdPartner: "",
      Id: undefined,
      Famale: "",
      Name: "",
      Patronymic: "",
      OperationDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
      loading: false,
      loadingBills: false,
      loadingOperations: false,
      loadingEmployees: false,
      Bills: [],
      BillId: undefined,
      Operations: [],
      OperationTypeId: undefined,
      Employees: [],
      Direction: undefined,
      PaymentType: 0,
      Sum: undefined,
      DetailInfo: "",
      IsInformPartner: false,
      isOpen: false,
      // Тип платежа = Наличными
      EmployeesId: undefined,
      RkoNumber: "",
      RkoDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
      // Тип платежа = Безналичным переводом - Альфа-Банк
      BankUserId: undefined,
      BankPaymentNumber: "",
      BankPaymentDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
      AzonUserId: undefined,
      BankPaymentOrderNumber: "",
      AzonDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
      BankName: ALFA_BANK,
      CompanyName: MF_LTD,
      RequestId: '0',
      CurrencyName: "",
      formErrors: {
        IdPartner: "",
        Famale: "",
        Name: "",
        Patronymic: "",
        OperationDate: "",
        Sum: "",
        DetailInfo: "",
        IsInformPartner: "",
        RkoNumber: "",
        BankPaymentNumber: "",
        BankPaymentOrderNumber: "",
        Direction: "",
        BillId: "",
        OperationTypeId: "",
      },
    }
  };


  public componentDidMount() {

    let data = {};

    const { Direction, Sum, PaymentType, RequestId, OperationTypeId, } = this.props;

    if (!isNil(Direction)) {
      data = {
        ...data,
        Direction: Direction ? 1 : 0,
      }
    }

    if (Sum) {
      data = {
        ...data,
        Sum,
      }
    }

    if (PaymentType) {
      data = {
        ...data,
        PaymentType,
      }
    }

    if (RequestId) {
      data = {
        ...data,
        RequestId,
      }
    }

    if (OperationTypeId) {
      data = {
        ...data,
        OperationTypeId,
        IsInformPartner: OperationTypeId == ID_SALARIES_OUTCOMING_CONVERTATION || OperationTypeId == ID_SALARIES_INCOMING
      }
    }

    this.setState(data, () => {
      this.loadPartner();
      this.getUserEmployes();
    });
  }

  public componentDidUpdate(prevProps: OperationsFormProps, prevState: OperationsFormState) {
    const { userData } = this.props;

    let data = {};

    if (prevState.OperationTypeId != this.state.OperationTypeId)
      data = { IsInformPartner: this.state.OperationTypeId == ID_SALARIES_OUTCOMING_CONVERTATION || this.state.OperationTypeId == ID_SALARIES_INCOMING };

    if (!isEqual(prevProps.userData, userData) && userData) {
      this.setState({
        ...data,
        BankUserId: userData.Id,
        AzonUserId: userData.Id,
        EmployeesId: userData.Id,
      })
    }

    this.setState(data);
  }

  public render() {
    const {
      formErrors,
      Famale,
      IdPartner,
      Name,
      Patronymic,
      OperationDate,
      loading,
      BillId,
      Direction,
      loadingBills,
      loadingOperations,
      OperationTypeId,
      Sum,
      DetailInfo,
      IsInformPartner,
      PaymentType,
      isOpen,
      BankPaymentNumber,
      BankPaymentDate,
    } = this.state;
    const { search: { data: searchData } } = this.props;

    const bills = get(this.state, "Bills", []) || [];
    const data = bills.map((item: BillInfoInterface) => ([item.BillInfo, item.Id]));

    const operations = get(this.state, "Operations", []) || [];
    const dataOperations = operations.map((item: OperaionsInterface) => ([item.OperationInfo, item.OperationTypeId]));

    return (
      <div>
        <Modal
          header={"Вы уверены, что хотите провести операцию?"}
          isOpen={isOpen}
          onBackgroundClick={this.toogleModal}
          onEscapeKeydown={this.toogleModal}
          fullWidth
        >
          <Line><Title>Пайщик</Title><Value>{`${Famale} ${Name} (${IdPartner})`}</Value></Line>
          <Line><Title>Дата проведения</Title><Value>{moment(OperationDate).format('DD.MM.YYYY')}</Value></Line>
          <Line><Title>Счет</Title><Value>{get(find(bills, (item:BillInfoInterface) => toNumber(item.Id) === toNumber(BillId)), 'BillInfo', '')}</Value></Line>
          <Line><Title>Вид операции</Title><Value>{Direction && operationTypeCodes[Direction]}</Value></Line>
          <Line><Title>Операция</Title><Value>{get(find(operations, (item:OperaionsInterface) => toNumber(item.OperationTypeId) === toNumber(OperationTypeId)), 'OperationInfo', '')}</Value></Line>
          <Line><Title>Сумма</Title><Value>{Sum}</Value></Line>
          <Line><Title>Информация по платежу</Title><Value>{DetailInfo}</Value></Line>
          <div className="col mt-4 text-right">
            <Button type="success" className="ml-3" onClick={this.createOperation} loading={loading}>
              Ок
            </Button>
            <Button type="error" className="ml-3" onClick={this.toogleModal} loading={loading}>
              Назад
            </Button>
          </div>
        </Modal>
        <LoadingOverlay loading={loadingBills || loading || loadingOperations}>
          <FormControl
            onChange={this.handleChangeAndFind("IdPartner")}
            onFocus={this.onFocus("IdPartner")}
            handleChangeAutoSelect={this.handleChangeAutoSelect}
            fieldName="Number"
            value={IdPartner}
            label="Пайщик"
            error={formErrors.IdPartner}
            searchData={searchData}
            type="autocomplete"
          />
          <FormControl
            onChange={this.handleChangeAndFind("Famale")}
            onFocus={this.onFocus("Famale")}
            handleChangeAutoSelect={this.handleChangeAutoSelect}
            value={Famale}
            fieldName="Famale"
            label="Фамилия"
            error={formErrors.Famale}
            searchData={searchData}
            // type="autocomplete"
            disabled
          />
          <FormControl
            onChange={this.handleChangeAndFind("Name")}
            onFocus={this.onFocus("Name")}
            handleChangeAutoSelect={this.handleChangeAutoSelect}
            value={Name}
            fieldName="Name"
            label="Имя"
            error={formErrors.Name}
            searchData={searchData}
            // type="autocomplete"
            disabled
          />
          <FormControl
            onChange={this.handleChangeAndFind("Patronymic")}
            handleChangeAutoSelect={this.handleChangeAutoSelect}
            value={Patronymic || " "}
            fieldName="Patronymic"
            label="Отчество"
            error={formErrors.Patronymic}
            searchData={searchData}
            // type="autocomplete"
            disabled
          />
          <FormControl
            onChange={this.handleChangeDate("OperationDate")}
            value={OperationDate}
            label="Дата открытия"
            type="date"
          />
          <FormControl
            onChange={this.handleChange("BillId")}
            value={BillId}
            // @ts-ignore
            data={isArray(bills) && !isEmpty(bills) ? [...EMPTY_DATA, ...data] : EMPTY_DATA}
            label="Счет пайщика"
            type="select"
            onFocus={this.onFocus("BillId")}
            error={formErrors.BillId}
          />
          <FormControl
            onChange={this.handleChange("Direction")}
            onFocus={this.onFocus("Direction")}
            value={Direction}
            error={formErrors.Direction}
            // @ts-ignore
            data={[...EMPTY_DATA, ...Object.entries(OperationTypes)]}
            label="Вид операции"
            type="select"
          />
          <FormControl
            onChange={this.handleChange("OperationTypeId")}
            value={OperationTypeId}
            // @ts-ignore
            data={isArray(operations) && !isEmpty(operations) ? [...EMPTY_DATA, ...dataOperations] : EMPTY_DATA}
            label="Операция"
            type="select"
            onFocus={this.onFocus("OperationTypeId")}
            error={formErrors.OperationTypeId}
          />
          {toNumber(OperationTypeId) === ID_SALARIES_INCOMING && <React.Fragment>
              <FormControl
                onChange={this.handleChange("BankPaymentNumber")}
                onFocus={this.onFocus("BankPaymentNumber")}
                value={BankPaymentNumber}
                label="Банк \ № платежа"
                error={formErrors.BankPaymentNumber}
                required
              />
              <FormControl
                onChange={this.handleChangeDate("BankPaymentDate")}
                value={BankPaymentDate}
                label="Банк \ Дата платежа"
                type="date"
              />
            </React.Fragment>
          }
          <FormControl
            onChange={this.handleChange("Sum")}
            onFocus={this.onFocus("Sum")}
            value={Sum}
            label="Сумма"
            error={formErrors.Sum}
            isNumber
          />
          {PAYMENT_OF_PERSONAL_SAVINGS_ID.includes(toNumber(OperationTypeId)) &&
          <React.Fragment>
            <FormControl
              onChange={this.handleChange("PaymentType")}
              value={PaymentType}
              data={Object.entries(paymentType)}
              label="Тип платежа"
              type="select"
            />
            {this.additionalFields()}
          </React.Fragment>
          }
          <FormControl
            onChange={this.handleChange("DetailInfo")}
            onFocus={this.onFocus("DetailInfo")}
            value={DetailInfo}
            label="Информация по операции"
            error={formErrors.DetailInfo}
            required
            disabled={toNumber(OperationTypeId) == ID_SALARIES_OUTCOMING_CONVERTATION}
          />
          <FormControl
            onChange={this.handleChangeCheckbox("IsInformPartner")}
            value={IsInformPartner}
            label="Информировать клиента"
            error={formErrors.IsInformPartner}
            type="checkbox"
          />
          <div className="row">
            <div className="col text-right">
              <Button type="success" className="ml-3" onClick={this.submit} loading={loading}>
                Провести операцию
              </Button>
              <Button type="error" className="ml-3" onClick={this.props.goToBack} loading={loading}>
                Назад
              </Button>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
  private toogleModal = () => {
    this.setState((prevState: OperationsFormState) => ({
      isOpen: !prevState.isOpen
    }))
  };

  private clearErrors = () => {
    this.setState({
      formErrors: OperationsForm.InitialState().formErrors,
    })
  };

  private createOperation = async () => {
    this.setState({
      loading: true,
      isOpen: false,
    });

    const {
      Id, IdPartner, BillId, Direction, OperationTypeId,
      Sum, DetailInfo, IsInformPartner, OperationDate,
      PaymentType, RkoNumber, RkoDate, EmployeesId,
      BankUserId, BankPaymentNumber, BankPaymentDate,
      AzonUserId, BankPaymentOrderNumber, AzonDate,
      RequestId, BankName, CompanyName, Rate
    } = this.state;

    const bills = get(this.state, "Bills", []) || [];
    const selectBill = find(bills, (item:BillInfoInterface) => toNumber(item.Id) === toNumber(BillId));

    const operations = get(this.state, "Operations", []) || [];
    const selectedOperation = find(operations, (item:OperaionsInterface) => toNumber(item.OperationTypeId) === toNumber(OperationTypeId));

    // @ts-ignore
    if (isNil(Direction) || Direction === '') {
      toast('Произошла ошибка, обратитесь к администратору системы', {
        type: toast.TYPE.ERROR
      });

      return;
    }

    const data: { Rate?: number } & { [key: string]: any } = {
      Partner: {
        Id,
        Number: IdPartner,
      },
      Bill: {
        ...selectBill,
      },
      Direction: operationTypeCodesBool[Direction],
      OperationType: {
        ...selectedOperation,
      },
      Sum: toNumber(Sum),
      DetailInfo,
      IsInformPartner,
      OperationDate: moment(OperationDate).format('YYYY-MM-DD'),
      DirectionName: operationTypeCodes[Direction] === 'Списание' ? 'Снятие' : operationTypeCodes[Direction],
      PaymentTypesEnable: PAYMENT_OF_PERSONAL_SAVINGS_ID.includes(toNumber(OperationTypeId)),
      OperationDetails: {},
      Rate
    };

    // Контроль Direction
    if ((data.Direction && data.DirectionName !== 'Пополнение')
    || (!data.Direction && data.DirectionName !== 'Снятие')) {
      toast('Произошла ошибка, обратитесь к администратору системы', {
        type: toast.TYPE.ERROR
      });
    }

    if (data.PaymentTypesEnable) {
      switch (toNumber(PaymentType)) {
        case 1:
          data.OperationDetails = {
            Type: toNumber(PaymentType),
            RkoNumber,
            RkoDate: moment(RkoDate).format('YYYY-MM-DD'),
            BankUserId: EmployeesId,
          };

          if (this.showSpecFields()) {
            data.OperationDetails = {
              ...data.OperationDetails,
              BankName,
              CompanyName,
            }
          }

          break;
        case 2:
          data.OperationDetails = {
            Type: toNumber(PaymentType),
            BankUserId,
            BankPaymentNumber,
            BankPaymentDate: moment(BankPaymentDate).format('YYYY-MM-DD'),
            AzonUserId,
            BankPaymentOrderNumber,
            AzonDate: moment(AzonDate).format('YYYY-MM-DD'),
          };
          break;
        case 3:
          data.OperationDetails = {
            Type: toNumber(PaymentType),
            BankUserId,
            BankPaymentNumber,
            BankPaymentDate: moment(BankPaymentDate).format('YYYY-MM-DD'),
          };
          break;
        case 4:
          data.OperationDetails = {
            Type: toNumber(PaymentType),
            BankUserId,
            BankPaymentNumber,
            BankPaymentDate: moment(BankPaymentDate).format('YYYY-MM-DD'),
            AzonUserId,
            BankPaymentOrderNumber,
            AzonDate: moment(AzonDate).format('YYYY-MM-DD'),
            RequestId,
            BankName,
            CompanyName,
          };
          break;
        default:
        break;
      }
    }

    if (toNumber(OperationTypeId) === ID_SALARIES_INCOMING)
    {
      data.OperationDetails = {
        BankPaymentNumber,
        BankPaymentDate: moment(BankPaymentDate).format('YYYY-MM-DD'),
      };
    }

    if (toNumber(OperationTypeId) !== ID_SALARIES_OUTCOMING_CONVERTATION)
      delete data.Rate;

    try {
      const result = await ApiClient.createOperation(data);
      const successMessage = result.Message || "Операция проведена успешно";
      toast(successMessage, {
        type: toast.TYPE.SUCCESS
      });
      setTimeout(this.props.successCreate, 1000);
    } catch (e) {
      const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
      toast(errorMessage, {
        type: toast.TYPE.ERROR
      });
    } finally {

      this.setState({
        loading: false,
      });
    }
  };

  private showSpecFields = () => {
    const { OperationTypeId, Direction, BillId } = this.state;
    const bills = get(this.state, "Bills", []) || [];
    const selectBill = find(bills, (item:BillInfoInterface) => toNumber(item.Id) === toNumber(BillId));

    return selectBill && selectBill.BillTypeId === CURRENT_BILLS_ID && toNumber(OperationTypeId) === ID_PAY_OF_PERSONAL_SAVING && toNumber(Direction) === 0;
  };

  private additionalFields = () => {
    const {
      PaymentType,
      EmployeesId,
      RkoDate,
      RkoNumber,
      BankUserId,
      BankPaymentNumber,
      BankPaymentDate,
      AzonUserId,
      BankPaymentOrderNumber,
      AzonDate,
      RequestId,
      formErrors,
      BankName,
      CompanyName,
      OperationTypeId,
      Rate,
      Sum
    } = this.state;

    const employ = get(this.state, "Employees", []) || [];
    // @ts-ignore
    const dataEmployess = employ.map((item: EmployeeInterface) => ([item.FullName, item.Id]));

    switch (toNumber(PaymentType)) {
      case 1: {
        return (
          <React.Fragment>
            <FormControl
              onChange={this.handleChange("RkoNumber")}
              onFocus={this.onFocus("RkoNumber")}
              value={RkoNumber}
              label="Номер РКО"
              error={formErrors.RkoNumber}
              required
            />
            <FormControl
              onChange={this.handleChangeDate("RkoDate")}
              value={RkoDate}
              label="Дата РКО"
              type="date"
            />
            <FormControl
              onChange={this.handleChange("EmployeesId")}
              value={EmployeesId}
              // @ts-ignore
              data={isArray(employ) && !isEmpty(employ) ? dataEmployess : EMPTY_DATA}
              label="Выплатил(а) средства"
              type="select"
            />
          </React.Fragment>
        )
      }
      case 2: {
        return (
          <React.Fragment>
            {
              toNumber(OperationTypeId) == ID_SALARIES_OUTCOMING_CONVERTATION && <React.Fragment>
                <FormControl
                  onChange={this.handleChange("Rate")}
                  value={Rate}
                  label="Обменный курс"
                  required
                />
                <FormControl
                  onChange={() => {}}
                  value={isNaN(Rate * Sum) ? "Введите корректные значения" : Rate * Sum}
                  label="Сумма в выплате"
                  disabled
                />
                </React.Fragment>
            }
            <FormControl
              onChange={this.handleChange("BankUserId")}
              value={BankUserId}
              // @ts-ignore
              data={isArray(employ) && !isEmpty(employ) ? dataEmployess : EMPTY_DATA}
              label="Банк \ Исполнитель"
              type="select"
            />
            <FormControl
              onChange={this.handleChange("BankPaymentNumber")}
              onFocus={this.onFocus("BankPaymentNumber")}
              value={BankPaymentNumber}
              label="Банк \ № платежа"
              error={formErrors.BankPaymentNumber}
              required
            />
            <FormControl
              onChange={this.handleChangeDate("BankPaymentDate")}
              value={BankPaymentDate}
              label="Банк \ Дата платежа"
              type="date"
            />
            <FormControl
              onChange={this.handleChange("AzonUserId")}
              value={AzonUserId}
              // @ts-ignore
              data={isArray(employ) && !isEmpty(employ) ? dataEmployess : EMPTY_DATA}
              label="АЗОН \ Исполнитель"
              type="select"
            />
            <FormControl
              onChange={this.handleChange("BankPaymentOrderNumber")}
              onFocus={this.onFocus("BankPaymentOrderNumber")}
              value={BankPaymentOrderNumber}
              label="АЗОН \ № ведомости"
              error={formErrors.BankPaymentOrderNumber}
              required
            />
            <FormControl
              onChange={this.handleChangeDate("AzonDate")}
              value={AzonDate}
              label="АЗОН \ Дата ведомости"
              type="date"
            />
            {this.showSpecFields() && <React.Fragment>
              <FormControl
                onChange={this.handleChange("BankName")}
                value={BankName}
                data={Object.entries(bankNames)}
                label="Название банка"
                type="select"
              />
              <FormControl
                onChange={this.handleChange("CompanyName")}
                value={CompanyName}
                data={Object.entries(companyNames)}
                label="Название компании"
                type="select"
              />
            </React.Fragment>}
          </React.Fragment>
        )
      }
      case 4: {
        return (
          <React.Fragment>
            <FormControl
              onChange={this.handleChange("RequestId")}
              onFocus={this.onFocus("RequestId")}
              value={RequestId}
              label="№ заявки"
              disabled
            />
            <FormControl
              onChange={this.handleChange("BankUserId")}
              value={BankUserId}
              // @ts-ignore
              data={isArray(employ) && !isEmpty(employ) ? dataEmployess : EMPTY_DATA}
              label="Альфа-Банк \ Исполнитель"
              type="select"
            />
            <FormControl
              onChange={this.handleChange("BankPaymentNumber")}
              onFocus={this.onFocus("BankPaymentNumber")}
              value={BankPaymentNumber}
              label="Альфа-Банк \ № платежа"
              error={formErrors.BankPaymentNumber}
              required
            />
            <FormControl
              onChange={this.handleChangeDate("BankPaymentDate")}
              value={BankPaymentDate}
              label="Альфа-Банк \ Дата"
              type="date"
            />
            <FormControl
              onChange={this.handleChange("AzonUserId")}
              value={AzonUserId}
              // @ts-ignore
              data={isArray(employ) && !isEmpty(employ) ? dataEmployess : EMPTY_DATA}
              label="АЗОН \ Исполнитель"
              type="select"
            />
            <FormControl
              onChange={this.handleChange("BankPaymentOrderNumber")}
              onFocus={this.onFocus("BankPaymentOrderNumber")}
              value={BankPaymentOrderNumber}
              label="АЗОН \ № ведомости"
              error={formErrors.BankPaymentOrderNumber}
              required
            />
            <FormControl
              onChange={this.handleChangeDate("AzonDate")}
              value={AzonDate}
              label="АЗОН \ Дата"
              type="date"
            />
            <FormControl
              onChange={this.handleChange("BankName")}
              value={BankName}
              data={Object.entries(bankNames)}
              label="Название банка"
              type="select"
            />
            <FormControl
              onChange={this.handleChange("CompanyName")}
              value={CompanyName}
              data={Object.entries(companyNames)}
              label="Название компании"
              type="select"
            />
          </React.Fragment>
        )
      }
    }

    return '';
  };

  private loadPartner = async () => {
    const { partnerNumber, billCode } = this.props;
    if (partnerNumber) {
      const partner = await ApiClient.getPartner(partnerNumber);

      if (partner) {
        this.setState({
          Famale: get(partner, "LastName"),
          Name: get(partner, "FirstName"),
          Patronymic: get(partner, "Patronymic"),
          Id: get(partner, "Id"),
          IdPartner: partnerNumber
        }, () => this.getUserBills(billCode));
      }
    }
  };

  private handleChange = (fieldName: string) => (e: any) => {
    const { target: { value } } = e;
    const { Rate, Sum, OperationTypeId } = this.state;

    this.setState({
      [fieldName]: value
    }, () => {
      switch (fieldName) {
        case 'BillId':
          const bills = get(this.state, "Bills", []) || [];
          const selectBill = find(bills, (item:BillInfoInterface) => toNumber(item.Id) === toNumber(value));

          this.setState({
            Direction: undefined,
            OperationTypeId: undefined,
            CurrencyName: selectBill ? selectBill.CurrencyName : ""
          });

          // if (selectBill && selectBill.BillTypeId === CURRENT_BILLS_ID) {
          //   this.setState({
          //     Direction: 1, //Пополнение
          //     OperationTypeId: ID_REPLENISHMENT_OF_PERSONAL_SAVING, //Пополнение личных сбережений
          //   }, () => this.getUserOperations(selectBill.BillCode));
          // } else if (selectBill && selectBill.BillTypeId === LOANS_BILLS_ID) {
          //   this.setState({
          //     Direction: 1, //Пополнение
          //     OperationTypeId: ID_LOAN_ACCOUNT_REPLENISHMENT, //Пополнение счета займа
          //   }, () => this.getUserOperations(selectBill.BillCode));
          // } else if (selectBill && selectBill.BillTypeId === SALARIES_BILLS_ID) {
          //   this.setState({
          //     Direction: 0, //снятие
          //     OperationTypeId: ID_SALARIES_PAYROLL, //Снятие средств заработной платы
          //   }, () => this.getUserOperations(selectBill.BillCode));
          // } else {
          //   this.getUserOperations();
          // }
          this.getUserOperations();
          break;
        case 'Direction':
          this.setState({
            OperationTypeId: undefined,
          });
          // const billsDirection = get(this.state, "Bills", []) || [];
          // const selectBillDirection = find(billsDirection, (item:BillInfoInterface) => toNumber(item.Id) === toNumber(this.state.BillId));
          // if (selectBillDirection && selectBillDirection.BillTypeId === SALARIES_BILLS_ID && Number(value) === 0) {
          //   this.setState({
          //     OperationTypeId: ID_SALARIES_PAYROLL, //Снятие средств заработной платы
          //   }, () => this.getUserOperations(selectBillDirection.BillCode));
          // } else if (selectBillDirection && selectBillDirection.BillTypeId === SALARIES_BILLS_ID && Number(value) === 1) {
          //   this.setState({
          //     OperationTypeId: 41,
          //   }, () => this.getUserOperations(selectBillDirection.BillCode));
          // } else if (selectBillDirection && selectBillDirection.BillTypeId === CURRENT_BILLS_ID && Number(value) === 1) {
          //   this.setState({
          //     OperationTypeId: ID_REPLENISHMENT_OF_PERSONAL_SAVING, //Пополнение личных сбережений
          //   }, () => this.getUserOperations(selectBillDirection.BillCode));
          // }else {
          //   this.getUserOperations();
          // }
          this.getUserOperations();
          break;
        case 'PaymentType':
          this.clearErrors();
          break;
        case 'Sum':
        case 'Rate':
          if (toNumber(OperationTypeId) !== ID_SALARIES_OUTCOMING_CONVERTATION)
            break;

          const newSum = fieldName == 'Sum' ? value : Sum;
          const newRate = fieldName == 'Rate' ? value : Rate;

          this.setState({
            DetailInfo: `Конвертация USD\\EUR. Курс обмена ${newRate} рублей за 1.00 USD\\EUR. Итого: ${newSum} х ${newRate} = ${newSum * newRate}.`,
          });
        default:
          break;
      }
    });
  };

  private onFocus = (fieldName: string) => (e: any) => {
    const { formErrors } = this.state;
    let isValid = true;
    const obj = omit(formErrors, [`${fieldName}`]);
    for (const key in obj) {
      // @ts-ignore
      if (obj[key] !== '') {
        isValid = false;
      }
    }
    this.setState(() => ({
      formErrors: {
        ...formErrors,
        [fieldName]: ""
      },
      formValid: isValid,
    }));
  };

  private handleChangeAndFind = (fieldName: string) => (value: any) => {
    const { searchPartnersRequest } = this.props;
    const { IdPartner, Famale, Name, Patronymic } = this.state;
    if (get(this.state, fieldName) !== value) {
        this.setState({
          Famale: '',
          Name: '',
          Patronymic: '',
          formValid: true,
          Bills: [],
          Direction: undefined,
          BillId: undefined,
          Id: undefined,
          Operations: [],
        });
        if (value !== '') {
        const findField = fieldName === "IdPartner" ? "Number" : fieldName;
        searchPartnersRequest({
          Number: IdPartner,
          Famale,
          Name,
          Patronymic,
          [findField]: value,
        });
      }
    }
    this.setState({
      [fieldName]: value
    });
  };

  private handleChangeCheckbox = (fieldName: string) => (e: any) => {
    const { target: { checked } } = e;
    this.setState({
      [fieldName]: checked
    });
  };

  private handleChangeAutoSelect = (partner: any) => {
    this.setState({
      ...partner,
      IdPartner: partner.Number,
      Id: partner.PartnerId
    }, () => {
      // tslint:disable-next-line:no-unused-expression
      this.state.Id && this.getUserBills();
    });
  };

  private handleChangeDate = (fieldName: string) => (date: Date, event: any) => {
    const { formErrors } = this.state;
    if (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value)) {
      const value = date ? convertToUtc(date).toISOString() : null;
        this.setState({
          [fieldName]: value === NONE_VALUE || value === "" ? null : value,
          formErrors: {
            ...formErrors,
            [fieldName]: '',
          }
        });
    }
  };

  private validateForm = () => {
    let formValid = true;
    const requiredFields = ["IdPartner", "DetailInfo", "Famale", "Name", "BillId", "OperationTypeId"];

    const state = this.state;
    const { Sum, OperationTypeId, PaymentType, Direction } = this.state;
    const formErrors = {};

    if (PAYMENT_OF_PERSONAL_SAVINGS_ID.includes(toNumber(OperationTypeId))) {
      switch (toNumber(PaymentType)) {
        case 1:
          requiredFields.push('RkoNumber');
          break;
        case 2:
          requiredFields.push('BankPaymentNumber');
          requiredFields.push('BankPaymentOrderNumber');
          break;
        case 3:
          requiredFields.push('BankPaymentNumber');
          break;
        case 4:
          requiredFields.push('BankPaymentNumber');
          requiredFields.push('BankPaymentOrderNumber');
          break;
        default:
        break;
      }
    }

    if (!this.isNumeric(Sum) || toNumber(Sum) <= 0) {
      set(formErrors, 'Sum', "Разрешены только положительные числа");
    }

    // @ts-ignore
    if (isNil(Direction) || Direction === '') {
      set(formErrors, 'Direction', "Поле обязательно для заполнения");

    }

    requiredFields.map((item) => {
      if (!get(state, item, "")) {
        set(formErrors, item, "Поле обязательно для заполнения");
      }
    });

    // if (isEmpty(Bills) || !BillId) {
    //   formValid = false;
    //   toast('Данные по счетам не получены', {
    //     type: toast.TYPE.ERROR
    //   });
    // }
    //
    // if (isEmpty(Operations) || !OperationTypeId) {
    //   formValid = false;
    //   toast('Данные по операциям не получены', {
    //     type: toast.TYPE.ERROR
    //   });
    // }

    this.setState(prevState => ({
      ...prevState,
      formErrors: {
        ...state.formErrors,
        ...formErrors
      }
    }));

    return isEmpty(formErrors) && formValid;

  };

  private submit = async () => {
    if (this.validateForm()) {
      this.toogleModal();
    }
  };

  private getUserBills = async (billCode?: any) => {
    this.setState({
      loadingBills: true,
    });
    try {
      const Bills: BillInfoInterface[] = await ApiClient.getAllBillForOperation(this.state.Id);
      this.setState({ Bills, loadingBills: false });
      if (billCode) {
        const bill: BillInfoInterface | undefined = find(Bills, (item: BillInfoInterface) => billCode === item.BillCode);
        if (bill) {
          await this.setState({
            BillId: bill.Id,
          })
        } else if (first(Bills)) {
          if (billCode) {
            toast(`Счет billCode=${billCode} для пайщика не найден`, {
              type: toast.TYPE.ERROR
            });
          }
          // @ts-ignore
          await this.setState({ BillId: first(Bills).Id })
        }
      } else if (first(Bills)) {
        // @ts-ignore
        // await this.setState({ BillId: first(Bills).Id })
      }
    } catch (e) {
      await this.setState({ loadingBills: false });

      const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
      toast(errorMessage, {
        type: toast.TYPE.ERROR
      });
    }
    this.getUserOperations(billCode);
  };

  private getUserOperations = async (billCode?: any) => {
    const { BillId, Direction, OperationTypeId } = this.state;
    if (!BillId) {
      return false;
    }
    this.setState({
      loadingOperations: true,
    });
    try {
      const Operations: OperaionsInterface[] = await ApiClient.getOperationsForBill(BillId, toString(!!toNumber(Direction)));
      this.setState({ Operations, loadingOperations: false });
      if (OperationTypeId && billCode) {

        const operations = Operations || [];
        const selectedOperation = find(operations, (item:OperaionsInterface) => toNumber(item.OperationTypeId) === toNumber(OperationTypeId));
        if (!selectedOperation) {
          toast(`Операция OperationTypeId=${OperationTypeId} для счета не найдена`, {
            type: toast.TYPE.ERROR
          });
        }
      } else if (first(Operations)) {
        // @ts-ignore
        // let operationId = first(Operations).OperationTypeId;
        if (OperationTypeId) {
          const operation: OperaionsInterface | undefined = find(Operations, (item: OperaionsInterface) => OperationTypeId === item.OperationTypeId);
          if (operation) {
            // operationId = OperationTypeId;
            this.setState({ OperationTypeId })
          }
        }
        // this.setState({ OperationTypeId: operationId })
      }
    } catch (e) {
      this.setState({ loadingOperations: false });

      const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
      toast(errorMessage, {
        type: toast.TYPE.ERROR
      });
    }
  };

  private getUserEmployes = async () => {
    this.setState({
      loadingEmployees: true,
    });
    try {
      const Employees: EmployeeInterface[] = await ApiClient.getEmployees();
      this.setState({ Employees, loadingEmployees: false });
      const { userData } = this.props;
      const userId = get(userData, 'Id');
      const emploe = first(Employees);
      if (emploe) {
        // @ts-ignore
        this.setState({
          EmployeesId: userId || emploe.Id,
          AzonUserId: userId || emploe.Id,
          BankUserId: userId || emploe.Id,
        })
      }
    } catch (e) {
      this.setState({ loadingEmployees: false });

      const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
      toast(errorMessage, {
        type: toast.TYPE.ERROR
      });
    }
  };

  private isNumeric = (val: any) => {
    return !isNaN(toNumber(val));
  }
}
