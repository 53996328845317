import React from 'react';
import { SimpleInput } from '../../../../../components/FormControls/FormControls';

interface IContainsComment {
  Comment: string;
}

interface CommentFieldProps {
  editing: boolean;
  data: IContainsComment;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function CommentField({ editing, data, onChange }: CommentFieldProps) {
  return editing
    ? (
      <SimpleInput
        onChange={onChange}
        value={data.Comment}
      />
    )
    : (
      <span>{data.Comment}</span>
    );
}

export function onChangeCommentField<T extends IContainsComment>(data: T[], onChange: (data: T[]) => void) {
  return (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newData = [...data];
    newData[index].Comment = event.currentTarget.value;
    onChange(newData);
  };
}
