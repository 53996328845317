import * as React from "react";
import { Slide, toast } from "react-toastify";
import StyledToastContainer from "../../../components/StyledToastContainer";
import { connect } from "react-redux";
import { AppState } from "../../../store";
import { Dispatch, bindActionCreators } from "redux";
import { deleteError, clearErrors } from "../../../store/global/actions";
import { ErrorData } from "../../../store/global";
import { omit } from "lodash";
import ErrorMessage from "./ErrorMessage";

export interface ErrorWrapperProps {
  errors: Record<string, ErrorData>;
  deleteError: typeof deleteError;
  clearErrors: typeof clearErrors;
}

class ErrorProvider extends React.PureComponent<ErrorWrapperProps> {
  public componentDidUpdate(prevProps: ErrorWrapperProps) {
    const newErrorKeys = Object.keys(
      omit(this.props.errors, Object.keys(prevProps.errors))
    );

    if (newErrorKeys.length) {
      this.showToasts(newErrorKeys);
    }
  }

  public componentWillUnmount() {
    this.props.clearErrors();
  }

  public componentDidCatch(error: Error) {
    // tslint:disable-next-line:no-console
    console.log("componentDidCatch", error);
  }

  public render() {
    return (
      <React.Fragment>
        {this.props.children}
        <StyledToastContainer transition={Slide} />
      </React.Fragment>
    );
  }

  private showToasts = (ids: string[]) => {
    ids.forEach(id => {
      const errorData = this.props.errors[id];

      if (errorData) {
        toast(<ErrorMessage errorData={errorData} />, {
          type: toast.TYPE.ERROR,
          onClose: () => this.props.deleteError(id)
        });
      }
    });
  };
}

const mapStateToProps = (state: AppState) => ({
  errors: state.global.errors
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      deleteError,
      clearErrors
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorProvider);
