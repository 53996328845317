import React from "react";
import { Filter, OperationContext, FilterDateValue } from "./";
import * as FormControls from "../../components/FormControls/FormControls";
import styled from "../../styled/styled-components";
import currencyCodes from "../../constants/currencyCodes";
import moment from "moment";
import operationTypeCodes from "../../constants/operationTypeCodes";
import CurrencyLabel from "../../components/CurrencyLabel/CurrencyLabel";
import DateRangePicker, { DateRange } from "../../components/DateRangePicker";
import Icon from "../../components/Icon";
import {
  TableBody,
  TableCell,
  TableHead,
  TableCellHead,
  TableRow,
  Table
} from "../../components/Table";
import { OperationTableRow } from "./TableViews";
import Link from "../../components/Link";
import { PARTNER_INPUT_MASK, NONE_VALUE } from "../../constants";
import Clickable from '../../components/Clickable';
import { format, parse } from 'date-fns';

const RemoveButton = styled(Clickable)`
  position: absolute;
  color: #d70c17;
  right: -15px;
  transform: translateY(-10px);
  text-align: center;

  &:hover {
    color: #d70c17;
    opacity: 0.6;
  }
`;

const DashedLink = styled(Link)`
  text-decoration: none;
  border-bottom: 1px dashed #000080;
  &:hover, &:active, &:focus  {
    text-decoration: none;
  }
`;

const TableContent: React.SFC = () => (
  <TableBody>
    <OperationContext.Consumer>
      {({ operations, toggleModal, onRemoveClick, removedOperationIds }) =>
        operations.map(operation => {
          const isRemoved =
            operation.Canceled ||
            removedOperationIds.indexOf(operation.OperationId) > -1;
          return (
            <OperationTableRow
              isRemoved={isRemoved}
              key={operation.OperationId}
            >
              <TableCell>
                  <DashedLink
                    onClick={
                      toggleModal &&
                      toggleModal.bind(null, operation.OperationId)
                    }
                    type="link"
                  >
                    {operation.OperationId}
                  </DashedLink>
              </TableCell>
              <TableCell>
                <Link routerLink href={`/client/Info/${operation.NumberOfPartner}`}>
                  {operation.NumberOfPartner}
                </Link>
              </TableCell>
              <TableCell>
                <Link routerLink href={`/Bill/Details/${operation.BillNumber}`}>
                  {operation.BillNumber}
                </Link>
              </TableCell>
              <TableCell>
                {operation.PayOut === null && "Пополнение"}
                {operation.PayIn === null && "Списание"}
              </TableCell>
              <TableCell>{operation.OperationName}</TableCell>
              <TableCell style={{ textAlign: "right" }}>
                <CurrencyLabel currency={operation.CurrencyName}>
                  {operation.PayOut !== null
                    ? operation.PayOut
                    : operation.PayIn}
                </CurrencyLabel>
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {operation.CurrencyName}
              </TableCell>
              <TableCell>{operation.StaffName}</TableCell>
              <TableCell style={{ textAlign: "center" }}>
                {moment(operation.OperationDate).format("DD.MM.YYYY, HH:mm:ss")}
                {!isRemoved && (
                  <RemoveButton
                    onClick={
                      onRemoveClick &&
                      onRemoveClick.bind(null, operation.OperationId)
                    }
                    type="inline"
                  >
                    <Icon name="times-circle" />
                  </RemoveButton>
                )}
              </TableCell>
            </OperationTableRow>
          );
        })
      }
    </OperationContext.Consumer>
  </TableBody>
);

export interface OperationsTableProps {
  filter: Filter;
  onChangeFilter?: (
    filterName: string,
    filterValue: string | number | FilterDateValue
  ) => void;
}

export interface OperationsTableState {
  numberOfPartner: string;
  dateStart?: Date;
  dateEnd?: Date;
}

const FilterInput = styled(FormControls.Input).attrs({
  style: {
    width: "100%",
    padding: "3px 10px 3px 3px"
  }
})``;

const DEBOUNCE_DELAY = 1000;

export default class OperationsTable extends React.Component<
  OperationsTableProps,
  OperationsTableState
> {
  public static defaultProps = {
    filter: {}
  };

  public state: OperationsTableState = {
    numberOfPartner: this.props.filter.numberOfPartner || "",
    dateStart: this.props.filter.dateStart ? new Date(this.props.filter.dateStart.replace( /(\d{2}).(\d{2}).(\d{4})/, "$2/$1/$3")) : undefined,
    dateEnd: this.props.filter.dateEnd ? new Date(this.props.filter.dateEnd.replace( /(\d{2}).(\d{2}).(\d{4})/, "$2/$1/$3")) : undefined
  };

  private fieldPrefix = "OperationsTable";

  public componentWillReceiveProps(nextProps: OperationsTableProps) {
    if (nextProps.filter.dateStart !== this.props.filter.dateStart) {
      this.setState({
        dateStart: nextProps.filter.dateStart
          ? parse(nextProps.filter.dateStart, 'dd.MM.yyyy', 0)
          : undefined
      });
    }

    if (nextProps.filter.dateEnd !== this.props.filter.dateEnd) {
      this.setState({
        dateEnd: nextProps.filter.dateEnd
          ?parse(nextProps.filter.dateEnd, 'dd.MM.yyyy', 0)
          : undefined
      });
    }

    if (
      nextProps.filter.numberOfPartner !== this.props.filter.numberOfPartner
    ) {
      this.setState({
        numberOfPartner: nextProps.filter.numberOfPartner || ""
      });
    }
  }

  public componentDidUpdate(
    _prevProps: OperationsTableProps,
    prevState: OperationsTableState
  ) {
    if (
      prevState.numberOfPartner !== this.state.numberOfPartner &&
      this.props.onChangeFilter
    ) {
      if (
        this.state.numberOfPartner.length === 15 ||
        this.state.numberOfPartner.length === 0
      ) {
        this.props.onChangeFilter(
          "numberOfPartner",
          this.state.numberOfPartner
        );
      }
    }
  }

  public render() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHead>Номер операции</TableCellHead>
            <TableCellHead>Номер пайщика</TableCellHead>
            <TableCellHead>Номер счета</TableCellHead>
            <TableCellHead>Вид операции</TableCellHead>
            <TableCellHead>Операция</TableCellHead>
            <TableCellHead>Сумма</TableCellHead>
            <TableCellHead>Валюта</TableCellHead>
            <TableCellHead>ФИО оператора</TableCellHead>
            <TableCellHead>Дата проведения</TableCellHead>
          </TableRow>
          <TableRow>
            <TableCell>
              <FilterInput
                debounceTimeout={DEBOUNCE_DELAY}
                name={`${this.fieldPrefix}-operationId`}
                onChange={this.handleChangeFilters}
                value={this.props.filter.operationId || ""}
                onClear={
                  this.props.onChangeFilter &&
                  this.props.onChangeFilter.bind(null, "operationId", "")
                }
              />
            </TableCell>
            <TableCell>
              <FilterInput
                onChange={this.handleChangeNumberOfPartner}
                name={`${this.fieldPrefix}-numberOfPartner`}
                value={this.state.numberOfPartner}
                mask={PARTNER_INPUT_MASK}
                maskChar=""
                spellCheck={false}
                onClear={
                  this.props.onChangeFilter &&
                  this.props.onChangeFilter.bind(null, "numberOfPartner", "")
                }
              />
            </TableCell>
            <TableCell>
              <FilterInput
                debounceTimeout={DEBOUNCE_DELAY}
                name={`${this.fieldPrefix}-numberBill`}
                onChange={this.handleChangeFilters}
                value={this.props.filter.numberBill || ""}
                onClear={
                  this.props.onChangeFilter &&
                  this.props.onChangeFilter.bind(null, "numberBill", "")
                }
              />
            </TableCell>
            <TableCell>
              <FormControls.Select
                value={this.props.filter.type || NONE_VALUE}
                name={`${this.fieldPrefix}-type`}
                onChange={this.handleChangeFilters}
              >
                <FormControls.SelectOption value={NONE_VALUE} key="all">
                  Все
                </FormControls.SelectOption>
                {Object.entries(operationTypeCodes).map(item => (
                  <FormControls.SelectOption value={item[0]} key={item[0]}>
                    {item[1]}
                  </FormControls.SelectOption>
                ))}
              </FormControls.Select>
            </TableCell>
            <TableCell>
              <FilterInput
                debounceTimeout={DEBOUNCE_DELAY}
                name={`${this.fieldPrefix}-operationName`}
                onChange={this.handleChangeFilters}
                value={this.props.filter.operationName || ""}
                onClear={
                  this.props.onChangeFilter &&
                  this.props.onChangeFilter.bind(null, "operationName", "")
                }
              />
            </TableCell>
            <TableCell>
              <FilterInput
                debounceTimeout={DEBOUNCE_DELAY}
                name={`${this.fieldPrefix}-operationSum`}
                onChange={this.handleChangeFilters}
                value={this.props.filter.operationSum || ""}
                type="number"
                onClear={
                  this.props.onChangeFilter &&
                  this.props.onChangeFilter.bind(null, "operationSum", "")
                }
              />
            </TableCell>
            <TableCell>
              <FormControls.Select
                value={this.props.filter.currency || NONE_VALUE}
                name={`${this.fieldPrefix}-currency`}
                onChange={this.handleChangeFilters}
                style={{ width: "100%" }}
              >
                <FormControls.SelectOption value={NONE_VALUE} key="all">
                  Все
                </FormControls.SelectOption>
                {Object.entries(currencyCodes).map(item => (
                  <FormControls.SelectOption value={item[1]} key={item[1]}>
                    {item[0]}
                  </FormControls.SelectOption>
                ))}
              </FormControls.Select>
            </TableCell>
            <TableCell>
              <FilterInput
                debounceTimeout={DEBOUNCE_DELAY}
                name={`${this.fieldPrefix}-staffName`}
                onChange={this.handleChangeFilters}
                value={this.props.filter.staffName || ""}
                onClear={
                  this.props.onChangeFilter &&
                  this.props.onChangeFilter.bind(null, "staffName", "")
                }
              />
            </TableCell>
            <TableCell style={{ minWidth: 180 }}>
              <DateRangePicker
                onChangeRange={this.handleChangeDate}
                startDate={this.state.dateStart}
                endDate={this.state.dateEnd}
                value={this.renderDateValue()}
                popperPlacement="bottom"
                popperModifiers={{
                  offset: {
                    enabled: true,
                    offset: "0, 4px"
                  },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: "viewport"
                  }
                }}
                onClear={
                  this.props.onChangeFilter &&
                  this.props.onChangeFilter.bind(null, "date", {})
                }
                selectableMonth
                isClearable
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableContent />
      </Table>
    );
  }

  private handleChangeFilters = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const name = event.target.name.replace(`${this.fieldPrefix}-`, "");
    const value = event.target.value;

    if (this.props.onChangeFilter) {
      this.props.onChangeFilter(name, value);
    }
  };

  private handleChangeNumberOfPartner = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      numberOfPartner: event.target.value
    });
  };

  private handleChangeDate = (range: DateRange) => {
    this.setState({ dateStart: range.start, dateEnd: range.end }, () => {
      const dateStart = this.state.dateStart
        ? format(this.state.dateStart, 'dd.MM.yyyy')
        : undefined;
      const dateEnd = this.state.dateEnd
        ? format(this.state.dateEnd, 'dd.MM.yyyy')
        : undefined;

      if (this.props.onChangeFilter) {
        this.props.onChangeFilter("date", {
          dateStart,
          dateEnd
        });
      }
    });
  };

  private renderDateValue = () => {
    const { dateStart, dateEnd } = this.state;
    if (!dateStart && !dateEnd) {
      return "";
    }

    if (dateStart && !dateEnd) {
      return moment(dateStart).format("DD.MM.YYYY");
    }

    if (dateStart && dateEnd && dateStart < dateEnd) {
      return `${moment(dateStart).format("DD.MM.YYYY")} - ${moment(dateEnd).format("DD.MM.YYYY")}`;
    }

    if (dateStart && dateEnd && dateStart.getTime() === dateEnd.getTime()) {
      return moment(dateStart).format("DD.MM.YYYY");
    }

    return `${moment(dateStart).format("MMMM YYYY")}`;
  };
}
