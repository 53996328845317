import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';

import { GlobalConfig } from './_config';
import { PieChartSeriesOptions } from "highcharts";

require('highcharts/modules/no-data-to-display')(ReactHighcharts.Highcharts);

ReactHighcharts.Highcharts.setOptions(GlobalConfig);

const baseConfig = {
  chart: {
    plotBackgroundColor: undefined,
    plotBorderWidth: undefined,
    plotShadow: false,
    type: 'pie',
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}% ({point.y})</b>',
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.percentage:.1f} % ({point.y})',
        style: {
          color: 'black',
        },
      },
    },
  },
};

const seriesConfig = {
  colorByPoint: true,
  data: [],
};

export interface PieChartProps {
  title: string,
  series: PieChartSeriesOptions[],
}

export default class PieChart extends Component<PieChartProps> {
  public render() {
    const { title, series } = this.props;
    const config = {
      ...baseConfig,
      title: { text: title },
      series: series.map(item => ({
        ...seriesConfig,
        name: title,
        ...item,
      })),
    };

    return <ReactHighcharts config={config} />;
  }
}
