import { BillCurrentListItem, FilterFieldName, FilterItemData, SortDirection } from "../types";

export const AUTOCOMPLETE_FILTER_FIELDS = [
  "PartnerNumber"
];

export const BILL_CURRENT_FIELD_LABELS = new Map<keyof BillCurrentListItem, string>([
  ["PartnerNumber", "Номер пайщика"],
  ["PartnerName", "ФИО"],
  ["BillCode", "Номер счета"],
  ["CurrentBalance", "Сумма"],
  ["CurrencyId", "Валюта"],
  ["OpeningDate", "Дата создания"],
  ["IsActive", "Статус"]
]);

export const DEFAULT_FILTER_FIELDS = new Map<
  FilterFieldName<BillCurrentListItem>,
  FilterItemData
  >([
  [
    "PartnerNumber",
    {
      FieldName: "PartnerNumber",
      Method: 1,
      Value: null,
      ValueType: 1
    }
  ],
  [
    "PartnerName",
    {
      FieldName: "PartnerName",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "BillCode",
    {
      FieldName: "BillCode",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "CurrentBalance",
    {
      FieldName: "CurrentBalance",
      Value: null,
      Method: 4,
      ValueType: 7
    }
  ],
  [
    "CurrencyId",
    {
      FieldName: "CurrencyId",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  [
    "OpeningDate",
    {
      FieldName: "OpeningDate",
      Value: null,
      Method: 4,
      ValueType: 4
    }
  ],
  [
    "IsActive",
    {
      FieldName: "IsActive",
      Value: null,
      Method: 0,
      ValueType: 3
    }
  ]
]);

export const DEFAULT_SORT_DIRECTION: SortDirection = 2;

export const DEFAULT_SORT_FIELD_NAME: FilterFieldName<BillCurrentListItem> =
  "OpeningDate";
