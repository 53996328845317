import { createAction } from "typesafe-actions";

import { BillDetails, LoanEarlyClose, PayCalendar } from "./types";

export const ActionTypes = {
  FETCH_DETAILS_REQUEST: "@@BILL/FETCH_DETAILS_REQUEST",
  FETCH_DETAILS_SUCCESS: "@@BILL/FETCH_DETAILS_SUCCESS",
  FETCH_DETAILS_ERROR: "@@BILL/FETCH_DETAILS_ERROR",

  FETCH_PLANNED_SCHEDULE_REQUEST: "@@BILL/FETCH_PLANNED_SCHEDULE_REQUEST",
  FETCH_PLANNED_SCHEDULE_SUCCESS: "@@BILL/FETCH_PLANNED_SCHEDULE_SUCCESS",
  FETCH_PLANNED_SCHEDULE_ERROR: "@@BILL/FETCH_PLANNED_SCHEDULE_ERROR",

  FETCH_LOAN_EARLY_CLOSE_REQUEST: "@@BILL/FETCH_LOAN_EARLY_CLOSE_REQUEST",
  FETCH_LOAN_EARLY_CLOSE_SUCCESS: "@@BILL/FETCH_LOAN_EARLY_CLOSE_SUCCESS",
  FETCH_LOAN_EARLY_CLOSE_ERROR: "@@BILL/FETCH_LOAN_EARLY_CLOSE_ERROR",

  FETCH_PAY_CALENDAR_REQUEST: "@@BILL/FETCH_PAY_CALENDAR_REQUEST",
  FETCH_PAY_CALENDAR_SUCCESS: "@@BILL/FETCH_PAY_CALENDAR_SUCCESS",
  FETCH_PAY_CALENDAR_ERROR: "@@BILL/FETCH_PAY_CALENDAR_ERROR",

  TOGGLE_BILL_SALARY_REQUEST: "@@BILL/TOGGLE_BILL_SALARY_REQUEST",
  TOGGLE_BILL_SALARY_SUCCESS: "@@BILL/TOGGLE_BILL_SALARY_SUCCESS",
  TOGGLE_BILL_SALARY_ERROR: "@@BILL/TOGGLE_BILL_SALARY_ERROR",
};

export const fetchBillDetailsRequest = createAction(
  ActionTypes.FETCH_DETAILS_REQUEST,
  resolve => (data: string) => resolve(data)
);

export const fetchBillDetailsSuccess = createAction(
  ActionTypes.FETCH_DETAILS_SUCCESS,
  resolve => (data: BillDetails) =>
    resolve(data)
);

export const fetchBillDetailsError = createAction(
  ActionTypes.FETCH_DETAILS_ERROR,
  resolve => (error: any) =>
    resolve(error)
);

export const fetchPlannedScheduleRequest = createAction(
  ActionTypes.FETCH_PLANNED_SCHEDULE_REQUEST,
  resolve => (data: { BillId: string, calendar: string }) => resolve(data)
);

export const fetchPlannedScheduleSuccess = createAction(
  ActionTypes.FETCH_PLANNED_SCHEDULE_SUCCESS,
  resolve => (data: { data: any, calendar: string }) =>
    resolve(data)
);

export const fetchPlannedScheduleError = createAction(
  ActionTypes.FETCH_PLANNED_SCHEDULE_ERROR,
  resolve => (error: { data: any, calendar: string}) =>
    resolve(error)
);

export const fetchLoanEarlyCloseRequest = createAction(
  ActionTypes.FETCH_LOAN_EARLY_CLOSE_REQUEST,
  resolve => (data: { date: string, id: string }) => resolve(data)
);

export const fetchLoanEarlyCloseSuccess = createAction(
  ActionTypes.FETCH_LOAN_EARLY_CLOSE_SUCCESS,
  resolve => (data: LoanEarlyClose) =>
    resolve(data)
);

export const fetchLoanEarlyCloseError = createAction(
  ActionTypes.FETCH_LOAN_EARLY_CLOSE_ERROR,
  resolve => (error: any) =>
    resolve(error)
);

export const fetchPayCalendarRequest = createAction(
  ActionTypes.FETCH_PAY_CALENDAR_REQUEST,
  resolve => (data: string) => resolve(data)
);

export const fetchPayCalendarSuccess = createAction(
  ActionTypes.FETCH_PAY_CALENDAR_SUCCESS,
  resolve => (data: PayCalendar) =>
    resolve(data)
);

export const fetchPayCalendarError = createAction(
  ActionTypes.FETCH_PAY_CALENDAR_ERROR,
  resolve => (error: any) =>
    resolve(error)
);


export const toggleSalaryBillRequest = createAction(
  ActionTypes.TOGGLE_BILL_SALARY_REQUEST,
  resolve => (data: { billId: string, billCode: string }) => resolve(data)
);

export const toggleSalaryBillSuccess = createAction(
  ActionTypes.TOGGLE_BILL_SALARY_SUCCESS,
  resolve => () =>
    resolve()
);

export const toggleSalaryBillError = createAction(
  ActionTypes.TOGGLE_BILL_SALARY_ERROR,
  resolve => (error: any) =>
    resolve(error)
);
