import { createAction } from "typesafe-actions";
import { ServerError } from "./types";
import cuid from "cuid";
import { serializeError } from "./utils";

export const ActionTypes = {
  PUT_ERROR: "@@global/PUT_ERROR",
  DELETE_ERROR: "@@global/DELETE_ERROR",
  CLEAR_ERRORS: "@@global/CLEAR_ERRORS"
};

export const putError = createAction(
  ActionTypes.PUT_ERROR,
  resolve => (error: ServerError) => {
    const errorId = cuid();
    return resolve(serializeError(error), errorId);
  }
);

export const deleteError = createAction(
  ActionTypes.DELETE_ERROR,
  resolve => (id?: string) => resolve(undefined, id)
);

export const clearErrors = createAction(
  ActionTypes.CLEAR_ERRORS,
  resolve => resolve
);
