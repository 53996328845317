import * as React from "react";
import styled from "../../../styled/styled-components";
import Button from "../../../components/Button/Button";
import ApiClient from "../../../services/api";
import { showErrorToast } from "../../../components/ErrorToast";

const Container = styled.div`
  display: flex;
  padding: 10px 0 30px;
`;

const Wrapper = styled.div`
  margin-right: 15px;
`;

export default class PrintDocuments extends React.PureComponent<{
  clientId: number;
  sopdAvailable: boolean;
}> {
  public render() {
    return (
      <Container>
        <Wrapper>
          <Button
            disabled={!this.props.sopdAvailable}
            onClick={this.printSopd}
            type="success"
          >
            Печать СОПД
          </Button>
        </Wrapper>
        <Wrapper>
          <Button onClick={this.printBki} type="success">
            Печать согласия на запрос БКИ
          </Button>
        </Wrapper>
        <Wrapper>
          <Button onClick={this.printPartnerEntryApplication} type="success">
            Заявление на вступление в пайщики
          </Button>
        </Wrapper>
        <Wrapper>
          <Button onClick={this.printDecOfAccession} type="success">
            Заявление на присоединение к ЗП проекту
          </Button>
        </Wrapper>
      </Container>
    );
  }

  private printSopd = () => {
    this.processRequest(ApiClient.printClientSopd);
  };

  private printBki = () => {
    this.processRequest(ApiClient.printClientBki);
  };

  private printPartnerEntryApplication = () => {
    this.processRequest(ApiClient.printPartnerEntryApplication);
  };

  private printDecOfAccession = () => {
    this.processRequest(ApiClient.printDecOfAccession);
  };

  private processRequest = async (
    request: (id: number) => Promise<BlobPart>
  ) => {
    const { clientId } = this.props;
    try {
      const result = await request(clientId);

      const blob = new Blob([result], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      window.open(url);
    } catch (error) {
      showErrorToast(error);
    }
  };
}
