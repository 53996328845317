import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from "redux";

import { AppState } from "../../../../store";
import { searchPartnersRequest, getLoanProductsRequest, getLoanProductConditionsRequest } from "../../../../store/forms";

import RaisingForm from './RaisingForm';

const mapStateToProps = (state: AppState) => ({
  search: state.forms.search,
  loanForm: state.forms.loanForm,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      searchPartnersRequest,
      getLoanProductsRequest,
      getLoanProductConditionsRequest,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RaisingForm);
