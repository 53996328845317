import React from 'react';
import moment from 'moment';
import { filter } from 'lodash';

import Panel from '../../../../../components/Panel';
import { ButtonAdd } from './styled';
import CardsTable, { CardsTableProps } from './CardsTable';
import { ProductCondition, productData } from "../../../types";
import { PartnerChangeHandlerArgs } from './types';

const CardsTableMemoized: React.FunctionComponent<CardsTableProps> = React.memo(CardsTable);

const getInitCondition = (ProductConditionType: number, CurrencyId: number, orderId: number) => ({
  Id: 0,
  ProductConditionType,
  Percent: 0,
  MemberPercent: 0,
  SumFrom: undefined,
  SumTo: undefined,
  PeriodTo: 0,
  PeriodFrom: 0,
  Fine: 0,
  IsActive: false,
  StartDate: moment().toISOString(),
  EndDate: moment().toISOString(),
  CurrencyId,
  orderId,
});

interface CardsBlockProps {
  editing: boolean;
  productData: productData;
  handleChange: PartnerChangeHandlerArgs,
  getMaxOrderId?: () => number,
  currencyId: number,
}

export default class ConditionBlockBase extends React.Component<CardsBlockProps> {
  public title = "Условия размещения";
  public ProductConditionType = 1;

  public render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    // tslint:disable-next-line:no-shadowed-variable
    const { editing, productData, handleChange, getMaxOrderId } = this.props;
    const orderId = getMaxOrderId ? getMaxOrderId() : 1;
    return (
      <Panel type="primary" heading={this.title}>
        {editing && (
          <ButtonAdd
            type="success"
            onClick={handleChange.bind(
              null,
              null,
              'ProductConditions',
              [...productData.ProductConditions, getInitCondition(this.ProductConditionType, this.props.currencyId, orderId)],
              { ProductConditionType: this.ProductConditionType, CurrencyId: this.props.currencyId }
            )}
          >
            Добавить
          </ButtonAdd>
        )}
        <CardsTableMemoized
          data={this.filterConditions(productData.ProductConditions)}
          editing={editing}
          billTypeId={productData.BillTypeId}
          additionalData={{ ProductConditionType: this.ProductConditionType, CurrencyId: this.props.currencyId }}
          onChange={handleChange.bind(null, null, 'ProductConditions')}
        />
      </Panel>
    );
  }

  private filterConditions = (data: ProductCondition[]) => {
    const filtredData = filter(data, (item: ProductCondition) => item.ProductConditionType === this.ProductConditionType && item.CurrencyId === this.props.currencyId);

    // @ts-ignore
    return filtredData.sort((a: ProductCondition, b: ProductCondition) => ((a.IsActive < b.IsActive) - (b.IsActive < a.IsActive) || (b.orderId < a.orderId) - (a.orderId < b.orderId)));
  }


}
