import React, { Component } from 'react';
import Helmet from "react-helmet";
import { RouteComponentProps } from "react-router";
import { get } from 'lodash';
import qs from 'query-string';

import LoadingOverlay from "../../../../components/LoadingOverlay";
import Link from "../../../../components/Link";
import { ArrowLeft } from "styled-icons/fa-solid";
import SectionHeader from "../../../../components/SectionHeader";
import Switcher from '../../components/Switcher';
import DepositForm from '../../components/DepositForm';
import FormControl from "../../components/FormControl";
import structurProgramCodes, { DEPOSIT__PROGRAM, PENSION_PROGRAM, CHILD_PROGRAM } from "../../../../constants/structurProgramCodes";

export interface DepositState {
  type: string;
}
export default class Deposit extends Component<RouteComponentProps, DepositState> {

  public constructor(props: RouteComponentProps) {
    super(props);
    const type = get(qs.parse(this.props.location.search), 'type', '');

    this.state = {
      // @ts-ignore
      type: Deposit.getType(type.toString()),
    }

  }

  private static getType = (type?: string) => {
    switch (type) {
      case 'pension':
        return PENSION_PROGRAM;
      case 'child':
        return CHILD_PROGRAM;
      default:
        return DEPOSIT__PROGRAM;
    }
  };

  public render() {
    const { type } = this.state;
    const partnerNumber = get(this.props, 'match.params.partnerNumber');
    return (
      <div>
        <Helmet defer={false}>
          <title>Создать сберегательную программу</title>
        </Helmet>
        <SectionHeader
          heading={this.getHeading()}
          link={this.renderBackLink()}
        />
        <LoadingOverlay loading={false}>
          <div className="row mb-5">
              <Switcher active="deposit" {...this.props} />
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormControl
                onChange={this.handleChange}
                value={type}
                data={Object.entries(structurProgramCodes)}
                label="Вид размещения"
                type="select"
              />
              <DepositForm goToList={this.goToList} type={type} partnerNumber={partnerNumber} />
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }


  private handleChange = (e: any) => {
    const { target: { value } } = e;
    this.setState({
      type: value,
    })
  };

  private goToList = (path: string, state: any) => {
    this.props.history.replace(path, state);
  };

  private renderBackLink = () => (
    <Link onClick={this.goToList.bind(undefined, "/client",undefined)}>
      <ArrowLeft size={13} /> Вернуться к списку
    </Link>
  );

  private getHeading = () => {
    const { type } = this.state;
    return `Создать ${type.toLowerCase()}`;
  };

}
