import React, { PureComponent, Fragment } from "react";
import moment from "moment";
import { noop } from 'lodash'

import FormControl from '../CalculatorForm/components/FormControl';
import { CalculatingData, PeriodData } from "../../../../store/calculators";


export default class TableRaisings extends PureComponent<CalculatingData> {

  private static localizeNumber = (num: number) => {
    return num.toLocaleString('ru', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  public render() {
    const { PlanCalendar, EffectiveRate } = this.props;
    const { Periods } = PlanCalendar;
    return (
      <Fragment>
        <FormControl label="Эффективная процентная ставка" onChange={noop} value={EffectiveRate} disabled />
        <table className="table table-striped">
          <thead>
          <tr>
            <th>Дата</th>
            <th>Сумма внесения</th>
            <th>Процентная ставка</th>
            <th>Компенсация</th>
            <th>К выплате</th>
            <th>Баланс</th>
          </tr>
          </thead>
          <tbody>
          {Periods.map((item: PeriodData) => (
            <tr key={item.PaymentDate} className="text-left">
              <td>{moment(item.PaymentDate).format("DD.MM.YYYY")}</td>
              <td>{item.CurrentBalanceStart && TableRaisings.localizeNumber(item.CurrentBalanceStart)}</td>
              <td>{item.PercentRate && TableRaisings.localizeNumber(item.PercentRate)}</td>
              <td>{TableRaisings.localizeNumber(item.Percent)}</td>
              <td>{TableRaisings.localizeNumber(item.Payment)}</td>
              <td>{item.CurrentBalanceEnd && TableRaisings.localizeNumber(item.CurrentBalanceEnd)}</td>
            </tr>))}
          </tbody>
          <tfoot>
          <tr className="">
            <td>Итого:</td>
            <td>{this.props.OpeningSum && TableRaisings.localizeNumber(this.props.OpeningSum)}</td>
            <td/>
            <td>{TableRaisings.localizeNumber(PlanCalendar.Percent)}</td>
            <td>{TableRaisings.localizeNumber(PlanCalendar.Payment)}</td>
            <td/>
          </tr>
          </tfoot>
        </table>
      </Fragment>
    );
  }
}
