import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from "redux";

import { AppState } from "../../../../store";

import BatchOperationsForm from './BatchOperationsForm';
import { BatchOperationsProps } from "./types";
import {
  getBatchOperationsLoading,
  getEmployeesLoading,
  getEmployeesRequest, getEmployeesConfigureData, submitFormRequest, getSubmitMessage, getSubmitLoading
} from "../../../../store/batchOperations";
import {
  getCommonBillByCurrency,
  getCommonBillForOperationRequest,
  getCommonBillsLoading
} from "../../../../store/bills";

const mapStateToProps = (state: AppState, props: BatchOperationsProps) => ({
  bill: getCommonBillByCurrency(state, props.currencyId),
  employees: getEmployeesConfigureData(state),
  loading: getCommonBillsLoading(state) || getBatchOperationsLoading(state) || getEmployeesLoading(state),
  userData: state.auth.data,
  submitting: getSubmitLoading(state),
  message: getSubmitMessage(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCommonBillForOperationRequest,
      getEmployeesRequest,
      submitFormRequest
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BatchOperationsForm);
