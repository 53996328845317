import { ReportListItem, FilterFieldName, FilterItemData, SortDirection } from "../types";

export const AUTOCOMPLETE_FILTER_FIELDS = [
  "PartnerNumber"
];

export const OVERDUE_CURRENT_FIELD_LABELS = new Map<keyof ReportListItem, string>([
  ["Number", "Номер просрочки"],
  ["PartnerNumber", "Номер пайщика"],
  ["BillCode", "Номер счета"],
  ["FullName", "ФИО"],
  ["Sum", "Сумма"],
  ["CurrencyId", "Валюта"],
  ["CheckDate", "Дата проверки"],
  ["PaymentDate", "Дата платежа"],
  ["LateDays", "Не погашено, дней"],
  ["ContractNumber", "Номер договора"],
]);

export const DEFAULT_FILTER_FIELDS = new Map<
  FilterFieldName<ReportListItem>,
  FilterItemData
  >([
  [
    "Number",
    {
      FieldName: "Number",
      Value: null,
      Method: 4,
      ValueType: 7
    }
  ],
  [
    "PartnerNumber",
    {
      FieldName: "PartnerNumber",
      Method: 1,
      Value: null,
      ValueType: 1
    }
  ],
  [
    "BillCode",
    {
      FieldName: "BillCode",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "FullName",
    {
      FieldName: "FullName",
      Method: 1,
      Value: null,
      ValueType: 1
    }
  ],
  [
    "Sum",
    {
      FieldName: "Sum",
      Value: null,
      Method: 4,
      ValueType: 7
    }
  ],
  [
    "CurrencyId",
    {
      FieldName: "CurrencyId",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  [
    "PaymentDate",
    {
      FieldName: "PaymentDate",
      Value: null,
      Method: 4,
      ValueType: 4
    }
  ],
  [
    "LateDays",
    {
      FieldName: "LateDays",
      Value: null,
      Method: 4,
      ValueType: 7
    }
  ],
  [
    "ContractNumber",
    {
      FieldName: "ContractNumber",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
]);

export const DEFAULT_SORT_DIRECTION: SortDirection = 2;

export const DEFAULT_SORT_FIELD_NAME: FilterFieldName<ReportListItem> =
  "Number";
