import { createSelector } from "reselect";
import { AppState } from "../index";
import { OtherActionsState } from "./types";

export const getOtherActions = (state: AppState) => state.otherActions;

export const getRefinanceRateData = createSelector(
  getOtherActions,
  (data: OtherActionsState) => data.refinanceRate.data
);

export const getRefinanceRateLoading = createSelector(
  getOtherActions,
  (data: OtherActionsState) => data.refinanceRate.loading
);

export const isSavedRefinanceRateLoading = createSelector(
  getOtherActions,
  (data: OtherActionsState) => data.refinanceRate.saved
);

export const getMFPercentData = createSelector(
  getOtherActions,
  (data: OtherActionsState) => data.mfPercent.data
);

export const getMFPercenLoading = createSelector(
  getOtherActions,
  (data: OtherActionsState) => data.mfPercent.loading
);

export const isSavedMFPercent = createSelector(
  getOtherActions,
  (data: OtherActionsState) => data.mfPercent.saved
);
