import { TokenStorage } from './TokenStorage';


const WS_ENDPOINT = process.env.REACT_APP_WS_ENDPOINT;

class Wss {
  connect = () => new WebSocket(WS_ENDPOINT);

  ws = this.connect();

  getWebSocket = () => this.ws;

  authenticateChat = () => {
    this.ws && this.ws.send(JSON.stringify({
      Method: "Auth",
      Token: TokenStorage.getToken(),
    }))
  };

  sendMessage = (text, chatId) => {
    this.ws && this.ws.send(JSON.stringify({
      Method: "Send",
      Message: text,
      ChatId: chatId
    }));
  };

  closeWs = () => {
    this.ws.close();
  };
}

const wssClient = new Wss();

export default wssClient;
