import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router';
import { get } from 'lodash';
import Helmet from "react-helmet";
import { connect } from "react-redux";

import styled from "../../../styled/styled-components";
import { BillDetailsState } from "../../../store/billDetails";
import LoadingOverlay from "../../../components/LoadingOverlay";
import BillDetailsForm from '../components/BillDetailsForm';
import PlannedSchedule from '../components/PlannedSchedule';
import Button from "../../../components/Button/Button";
import { AppState } from "../../../store";
import Operations from "../components/Operations";
import PlannedSettlement from "../components/PlannedSettlement";
import DepositEarlyClose from "../components/DepositEarlyClose";
import PrintAccountStatement from "../../../components/PrintAccountStatement";
import PrintButton from "../../../components/PrintButton/PrintButton";
import Dropdown from "../../../components/Dropdown";
import { TabContent, TabLink, TabLinks } from "../../Partner/components/Views";
import BackLink from "../../../components/BackLink";
import { StyledBackLink } from "./CurrentDetail";

export interface BillDetailsProps {
  fetchBillDetailsRequest: (data: string) => void;
  BillId: string;
  PartnerNumber: string;
  BillCode: string;
  PartnerId: string;
  Currency: string;
  Period: number;
}

const BillData = styled.div`
  border-bottom: 1px solid #e7eaec;
  margin: 0 -24px;
  padding: 0 24px 7px;
`;

export type TabType =
  | "main"
  | "PlannedSchedule"
  | "PlannedSettlement"
  | "erlyclose";

export const TABS_LABELS = new Map<
  TabType,
  {
    label: string;
    component: typeof React.Component;
  }
  >([
  ["main", { label: "Операции", component: Operations }],
  ["PlannedSchedule", { label: "Плановый календарь", component: PlannedSchedule }],
  ["PlannedSettlement", { label: "Плановый расчет на дату", component: PlannedSettlement }],
  ["erlyclose", { label: "Расчет досрочного закрытия", component: DepositEarlyClose }],
]);

class RaisingDetail extends PureComponent<BillDetailsState & BillDetailsProps & RouteComponentProps> {
  public render() {
    const { loading, PartnerNumber, BillCode, Currency, Period } = this.props;

    const tabsEneteries = [...TABS_LABELS.entries()];
    const currentTabName = this.props.location.hash.replace('#', '') || 'main';
    const currentTab = TABS_LABELS.get(currentTabName as TabType);

    const Component = currentTab ? currentTab.component : null;
    return(
      <div>
        <Helmet defer={false}>
          <title>Детализация</title>
        </Helmet>
        <StyledBackLink>
          <BackLink title={document.title} >
            <h4 className="text-center">Привлечение средств</h4>
          </BackLink>
        </StyledBackLink>
        <LoadingOverlay loading={loading}>
          <BillData>
            {!loading && <BillDetailsForm />}
          </BillData>
          <div className="my-3">
            <Button type="primary" className='m-2' href={`/Operation/OperationProcess?partnerNumber=${PartnerNumber}&billCode=${BillCode}&returnUrl=${encodeURIComponent(document.location.pathname)}${encodeURIComponent(document.location.search)}`}>
              Операции
            </Button>
            <Dropdown caption={"Распечатать документ ⬇︎"}>
              <div className="p-2">
                <PrintButton type="success" className='d-block mb-2 w-100' href={`/template/RaisingsConditions?billCode=${BillCode}`}>
                  Печать условий привлечения средств
                </PrintButton>
                <PrintButton type="success" className='d-block mb-2 w-100' href={`/template/RaisingsAgreement?id=${PartnerNumber}&billcode=${BillCode}&period=${Period}&currency=${Currency}`}>
                  Печать договора привлечения средств
                </PrintButton>
                <PrintButton type="success" className='d-block mb-2 w-100' href={`/template/ContractOfGuarantee/${BillCode}`}>
                  Печать договора поручительства
                </PrintButton>
                <PrintAccountStatement BillCode={BillCode} />
              </div>
            </Dropdown>
          </div>
          <TabLinks>
            {tabsEneteries.map(([name, item]) => (
              <TabLink
                key={name}
                href={`${this.props.location.pathname}#${name}`}
                routerLink
                active={
                  (!this.props.location.hash && name === 'main') ||
                  this.props.location.hash === `#${name}`
                }
                component={
                  this.props.location.hash === `#${name}`
                    ? clickableProps => <span {...clickableProps} />
                    : undefined
                }
              >
                {item.label}
              </TabLink>
            ))}
          </TabLinks>
          <TabContent>{Component && <Component calendar="raisingCalendar" type="raising" {...this.props} />}</TabContent>
        </LoadingOverlay>
      </div>
    )
  }

}

const mapStateToProps = (state: AppState) => ({
  loading: state.billDetails.loading,
  BillId: get(state, 'billDetails.data.BillId'),
  PartnerNumber: get(state.billDetails, 'data.PartnerNumber', ''),
  BillCode: get(state.billDetails, 'data.BillCode', ''),
  PartnerId: get(state.billDetails, 'data.PartnerId', ''),
  Currency: get(state.billDetails, 'data.Currency', ''),
  Period: get(state.billDetails, 'data.Period', 0),
});

export default connect(mapStateToProps)(RaisingDetail);
