import * as React from "react";
import { PartnerContext } from "../../pages/Partner";
import {
  LOAN_FORM_CLIENT_FIELDS,
  LOAN_FORM_REASON_FIELDS,
  LOAN_FORM_CREDIT_FIELDS,
  LOAN_FORM_COMMENTS_FIELDS
} from "../../constants";
import { FormColumn, FormWrapper } from "../Views";
import Form from "../../../../components/PartnersAndClients/Form";
import Panel from "../../../../components/Panel";
import Button from "../../../../components/Button/Button";
import styled from "../../../../styled/styled-components";

const ButtonsContainer = styled.div`
  display: flex;
  padding: 15px 0;
`;

const ButtonWrapper = styled.div`
  margin-right: 10px;
`;
export default class LoanFormTab extends React.PureComponent {
  public render() {
    return (
      <PartnerContext.Consumer>
        {({
          loanForm,
          handleChangeLoanForm,
          valueLabels,
          isCanSaveLoanForm,
          saveLoanForm,
          printLoanForm,
          allowPrintLoanForm
        }) =>
          loanForm && (
            <div>
              <ButtonsContainer>
                <ButtonWrapper>
                  <Button
                    disabled={!isCanSaveLoanForm}
                    onClick={saveLoanForm}
                    type="primary"
                  >
                    Сохранить
                  </Button>
                </ButtonWrapper>
                <ButtonWrapper>
                  <Button disabled={!allowPrintLoanForm || isCanSaveLoanForm} onClick={printLoanForm} type="primary">
                    Напечатать
                  </Button>
                </ButtonWrapper>
              </ButtonsContainer>
              <FormWrapper>
                <FormColumn>
                  <Panel type="primary" heading="Клиент">
                    <Form
                      data={loanForm}
                      fields={LOAN_FORM_CLIENT_FIELDS}
                      onChange={handleChangeLoanForm}
                      valueLabels={valueLabels}
                    />
                  </Panel>
                </FormColumn>
                <FormColumn>
                  <Panel type="primary" heading="Цели кредитования">
                    <Form
                      data={loanForm}
                      fields={LOAN_FORM_REASON_FIELDS}
                      onChange={handleChangeLoanForm}
                      valueLabels={valueLabels}
                    />
                  </Panel>
                  <Panel type="primary" heading="Параметры кредита">
                    <Form
                      data={loanForm}
                      fields={LOAN_FORM_CREDIT_FIELDS}
                      onChange={handleChangeLoanForm}
                      valueLabels={valueLabels}
                    />
                  </Panel>
                </FormColumn>
                <Panel
                  type="primary"
                  heading="Примечания специалиста по работе с пайщиками"
                >
                  <Form
                    data={loanForm}
                    fields={LOAN_FORM_COMMENTS_FIELDS}
                    onChange={handleChangeLoanForm}
                    valueLabels={valueLabels}
                  />
                </Panel>
              </FormWrapper>
            </div>
          )
        }
      </PartnerContext.Consumer>
    );
  }
}
