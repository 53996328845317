import moment from 'moment';
import currecyCodes from '../constants/currencyCodes';
import { PERIOD_TYPE_DAY, PERIOD_TYPE_MONTH } from '../constants/periodTypeCodes';

export function getTickInterval(period) {
  switch (period) {
    case 'month':
    case 'currentMonth':
      return moment.duration(1, 'day').asMilliseconds();
    case 'year':
    case 'currentYear':
    case 'custom':
    default:
      return moment.duration(1, 'month').asMilliseconds();
  }
}

export function getPeriodType(period) {
  switch (period) {
    case 'currentMonth':
    case 'month':
      return PERIOD_TYPE_DAY;
    case 'currentYear':
    case 'year':
    case 'custom':
    case 'all':
    default:
      return PERIOD_TYPE_MONTH;
  }
}

export function getCurrencyCode(currency) {
  return currency !== 'all' ? currecyCodes[currency] : undefined;
}

// ------------ handlers ------------

export function handleChangeCompare(event) {
  const filters = Object.assign({}, this.state.filters);
  filters.compare = event.target.checked;
  this.setState({ filters }, () => this.loadData());
}

export function handleChangeCurrency(value) {
  const filters = Object.assign({}, this.state.filters);
  filters.currency = value;
  this.setState({ filters }, () => this.loadData());
}

export function handleChangePeriod(period, dateFrom = null, dateTo = null) {
  const filters = Object.assign({}, this.state.filters);

  filters.period = period;
  switch (period) {
    case 'currentMonth':
      filters.dateFrom = moment()
        .startOf('month')
        .valueOf();
      filters.dateTo = moment().valueOf();
      break;
    case 'month':
      filters.dateFrom = moment()
        .subtract(1, 'month')
        .valueOf();
      filters.dateTo = moment().valueOf();
      break;
    case 'currentYear':
      filters.dateFrom = moment()
        .startOf('year')
        .valueOf();
      filters.dateTo = moment()
        .endOf('month')
        .valueOf();
      break;
    case 'year':
      filters.dateFrom = moment()
        .startOf('month')
        .add(1, 'month')
        .subtract(1, 'year')
        .valueOf();
      filters.dateTo = moment()
        .endOf('month')
        .valueOf();
      break;
    case 'custom':
      filters.dateFrom = dateFrom || moment().valueOf();
      filters.dateTo = dateTo || moment().valueOf();
      break;
    case 'all':
      filters.dateFrom = null;
      filters.dateTo = moment().valueOf();
      break;
    default:
      filters.dateFrom = moment()
        .startOf('month')
        .add(1, 'month')
        .subtract(1, 'year')
        .valueOf();
      filters.dateTo = moment()
        .endOf('month')
        .valueOf();
  }

  this.setState({ filters }, () => this.loadData());
}
