import { Reducer } from "redux";
import { ActionTypes } from "./actions";
import { OtherActionsState } from "./types";

const initialState: OtherActionsState = {
  refinanceRate: {
    data: [],
    loading: false,
    error: undefined,
    saved: false,
  },
  mfPercent: {
    data: [],
    loading: false,
    error: undefined,
    saved: false,
  }
};

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_CHANGE_REFINANCE_REQUEST:
      return {
        ...state,
        refinanceRate: {
          ...state.refinanceRate,
          data: [],
          loading: true,
          error: undefined,
        }
      };
    case ActionTypes.GET_ALL_CHANGE_REFINANCE_SUCCESS:
      return {
        ...state,
        refinanceRate: {
          ...state.refinanceRate,
          data: action.payload,
          loading: false,
          error: undefined,
        }
      };
    case ActionTypes.GET_ALL_CHANGE_REFINANCE_ERROR:
      return {
        ...state,
        refinanceRate: {
          ...state.refinanceRate,
          data: [],
          loading: false,
          error: action.payload,
        }
      };

    case ActionTypes.SAVE_ALL_CHANGE_REFINANCE_REQUEST:
      return {
        ...state,
        refinanceRate: {
          ...state.refinanceRate,
          loading: true,
          saved: false,
        }
      };
    case ActionTypes.SAVE_ALL_CHANGE_REFINANCE_SUCCESS:
      return {
        ...state,
        refinanceRate: {
          ...state.refinanceRate,
          loading: false,
          saved: true,
        }
      };
    case ActionTypes.SAVE_ALL_CHANGE_REFINANCE_ERROR:
      return {
        ...state,
        refinanceRate: {
          ...state.refinanceRate,
          loading: false,
          saved: false,
        }
      };


    case ActionTypes.GET_ALL_MF_PRECENT_REQUEST:
      return {
        ...state,
        mfPercent: {
          ...state.mfPercent,
          data: [],
          loading: true,
          error: undefined,
        }
      };
    case ActionTypes.GET_ALL_MF_PRECENT_SUCCESS:
      return {
        ...state,
        mfPercent: {
          ...state.mfPercent,
          data: action.payload,
          loading: false,
          error: undefined,
        }
      };
    case ActionTypes.GET_ALL_MF_PRECENT_ERROR:
      return {
        ...state,
        mfPercent: {
          ...state.mfPercent,
          data: [],
          loading: false,
          error: action.payload,
        }
      };

    case ActionTypes.SAVE_MF_PERCENT_REQUEST:
      return {
        ...state,
        mfPercent: {
          ...state.mfPercent,
          loading: true,
          saved: false,
        }
      };
    case ActionTypes.SAVE_MF_PERCENT_SUCCESS:
      return {
        ...state,
        mfPercent: {
          ...state.mfPercent,
          loading: false,
          saved: true,
        }
      };
    case ActionTypes.SAVE_MF_PERCENT_ERROR:
      return {
        ...state,
        mfPercent: {
          ...state.mfPercent,
          loading: false,
          saved: false,
        }
      };
    default:
      return state;
  }
};

export { reducer as otherActionsReducer };
