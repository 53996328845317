import * as React from "react";
import { LoaderCircle } from "styled-icons/boxicons-regular/LoaderCircle";
import styled, { keyframes } from "../../styled/styled-components";

export interface SpinnerProps extends React.HTMLAttributes<HTMLSpanElement> {
  active?: boolean;
  iconProps?: React.SVGAttributes<SVGElement>;
}

const Container = styled.span`
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  width: 1.2em;
  height: 1.2em;
  min-width: 24px;
  min-height: 24px;
  padding: 2px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const StyledLoaderCircle = styled(LoaderCircle)`
  width: 100%;
  height: 100%;
  animation: ${rotate} 2s linear infinite;
`;

const Spinner: React.SFC<SpinnerProps> = ({
  active = true,
  iconProps,
  ...props
}) => (
  <Container {...props}>
    {active && <StyledLoaderCircle size="100%" {...iconProps} />}
  </Container>
);

export default Spinner;
