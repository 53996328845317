import { TabTypeMFPercent } from "../types";
import { CurrencyId } from "../../../types";

export const MESSAGES = {
  updateSuccess: "Ставки рефинансирования успешно обновлены",
  updateSuccessMFPercent: "Ставки МФ успешно обновлены",
};

export const TABS_LABELS_MF_PERCENT = new Map<
  TabTypeMFPercent,
  {
    label: string;
    filterData: CurrencyId;
  }
  >([
  ["rub", { label: "RUB", filterData: 1 }],
  ["usd", { label: "USD", filterData: 2 }],
  ["eur", { label: "EUR", filterData: 3 }],
]);
