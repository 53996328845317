import * as React from "react";
import { DebounceInput } from "react-debounce-input";
import ReactInputMask, { MaskOptions } from "react-input-mask";
import { InputView } from "./Views";

export type InputElement = HTMLInputElement | DebounceInput | ReactInputMask;
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    Partial<MaskOptions> {
  debounceTimeout?: number;
  value?: string | number;
  innerRef?: <T = InputElement>(elem: T | null) => void;
  onClear?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  error?: boolean;
}

const Input: React.SFC<InputProps> = props => {
  const {
    mask,
    maskChar,
    alwaysShowMask,
    formatChars,
    permanents,
    style,
    ...propsWithoutMask
  } = props;

  const maskProps = {
    mask,
    maskChar,
    alwaysShowMask,
    formatChars,
    permanents
  };

  const {
    debounceTimeout,
    onChange = () => undefined,
    innerRef,
    ...inputProps
  } = propsWithoutMask;

  if (props.debounceTimeout) {
    const innerInput = (anyDeboundeProps: any) => (
      <InputView style={style} {...anyDeboundeProps} />
    );
    return (
      <DebounceInput
        ref={innerRef}
        onChange={onChange}
        debounceTimeout={debounceTimeout}
        element={innerInput}
        {...inputProps}
      />
    );
  }

  if (mask) {
    return (
      <ReactInputMask
        ref={innerRef}
        onChange={onChange}
        {...inputProps}
        {...maskProps}
        mask={mask}
      >
        {({ className, ...maskInputProps }: any) => (
          <InputView style={style} {...maskInputProps} />
        )}
      </ReactInputMask>
    );
  }

  return (
    <InputView
      style={style}
      innerRef={innerRef}
      onChange={onChange}
      {...inputProps}
    />
  );
};

export default Input;
