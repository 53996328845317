import { createSelector } from "reselect";
import { first } from 'lodash'
import { AppState } from "../index";
import { Bill, BillsState, } from "./types";
import { currencyTypes } from "../../constants/currencyCodes";
import { CurrencyId } from "../batchOperations";

export const getBills = (state: AppState) => state.bills;
export const getCurrencyName = (_: any, currencyId: CurrencyId) => currencyTypes[currencyId];


export const getCommonBills = createSelector(
  getBills,
  (data: BillsState) => data.commonBills.data
);

export const getCommonBillsLoading = createSelector(
  getBills,
  (data: BillsState) => data.commonBills.loading
);

export const getCommonBillByCurrency = createSelector(
  getCommonBills,
  getCurrencyName,
  (items: Bill[], currencyName: string) => {
    const filtredData = items.filter((item: Bill) => item.CurrencyName === currencyName);

    return first(filtredData);
  }
);