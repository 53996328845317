import React, { Component } from "react";
import styled from "styled-components";

export interface SumInfoRow {
  title: string,
  sum: number,
}

interface SumInfoProps {
  title: string,
  data: SumInfoRow[],
}

const Row = (props: SumInfoRow) => (
  <StyledRow>
    <RowTitle>{props.title}:</RowTitle>
    <RowValue>{props.sum}</RowValue>
  </StyledRow>
);

export default class SumInfo extends Component<SumInfoProps> {
  public render() {
    const {
      data = [],
      title
    } = this.props;

    const rows = data.map((props: SumInfoRow) => (<Row key={props.title} {...props} />));

    return (
      <Container>
        <Title>{title} за период</Title>
        <Content>
          {data.length > 0 ? rows : <NoResults>Нет данных</NoResults>}
        </Content>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.h3``;
const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
  
  width: 100%;
  max-width: 600px;
`;
const RowTitle = styled.h5``;
const RowValue = styled.div``;
const NoResults = styled.h4``;
