import * as React from "react";
import { ifProp } from "styled-tools";
import ReactInputMask from "react-input-mask";
import styled, { css } from "../../styled/styled-components";
import _Input from "./Input";
import { fieldStyles, CheckboxView, TextareaView, RadioView } from "./Views";

export const Input = _Input;

const SimpleSelectView: React.SFC<
  React.SelectHTMLAttributes<HTMLSelectElement> & {
  greenBorder?: boolean;
}
  > = ({ greenBorder, ...props }) => <select {...props} />;

export const Select = styled(SimpleSelectView)`
  ${fieldStyles};
  padding: 6px 12px;
  -webkit-appearance: textfield;
`;
export const SelectOption = styled.option``;

export const Checkbox = styled(CheckboxView)`
  display: inline-block;
  width: auto;
  height: auto;
`;

export const Textarea = styled(TextareaView)`
  ${fieldStyles};
  outline: none;
  resize: ${(ifProp("disableResize"), "none")};
`;

export const Radio = styled(RadioView)`
  position: relative;
  display: inline-block;
  vertical-align: baseline;
  width: auto;
  height: auto;
  margin: 0 5px;
`;

const SimpleInputView: React.SFC<
  React.InputHTMLAttributes<HTMLInputElement> & {
    error?: boolean;
    refInput?: React.RefObject<HTMLInputElement>;
    greenBorder?: boolean;
  }
> = ({ error, refInput, greenBorder, ...props }) => <input ref={refInput} {...props} />;

export const SimpleInput = styled(SimpleInputView)`
  ${fieldStyles}

  ${ifProp(
    "error",
    css`
      border-color: ${({ theme }) => theme.colors.errorRed};
    `
  )}
`;

export const SimpleMaskedInput = styled(ReactInputMask)`
  ${fieldStyles}
  ${ifProp(
    "customError",
          css`
              border-color: ${({ theme }) => theme.colors.errorRed};
            `
    )}
`;

export const SimpleMaskedInputForDate = styled(ReactInputMask)`
    height: auto;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #ced4da;
    font-size: 16px;
    padding: 7px 12px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    font-weight: 300;
    color: black;
    &:disabled {
      color: rgba(0, 0, 0, 0.38);
    }
  ${ifProp(
    "customError",
          css`
              border-color: ${({ theme }) => theme.colors.errorRed};
            `
    )}
`;
