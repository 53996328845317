import React, { PureComponent } from "react";
import moment from "moment";
import { isEmpty } from "lodash";

import { LoanEarlyCloseState } from "../../../../store/billDetails";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import { parseServerDate } from "../../../../utils";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import ErrorMessage from "../../../Errors/components/ErrorMessage";

export interface LoanEarlyCloseProps {
  BillId: string,
  loanEarlyClose: LoanEarlyCloseState,
  fetchLoanEarlyCloseRequest: (data: { date: string, id: string }) => void,
}


export default class LoanEarlyClose extends PureComponent<LoanEarlyCloseProps> {
  public render() {
    const { loanEarlyClose: { loading, data, error } } = this.props;
    if (!isEmpty(error)) {
      return (
        <div className="alert alert-danger mt-3 d-print-none">
          <ErrorMessage errorData={{ message: (error && error.Message) || 'Ошибка', verbose: (error && error.ExceptionMessage) || 'Кажется имеет место быть досадная ошибка.... Попробуйте обновить страницу'}} />
        </div>
      );
    }

    return (
      <LoadingOverlay loading={loading}>
        <div className="mt-4">
          <div className="panel-title text-center mb-4">
            <h3 className="d-inline-flex row align-items-center">
              Досрочное закрытие
              <DatePicker useStyledInput className="ml-2" onChange={this.handleChangeDate}
                          selected={data && data.Date ? parseServerDate(data.Date) : null}/>
            </h3>

          </div>
          <div className="panel-body">
            {!isEmpty(data) && <table className="table table-stripped">
              <thead>
              <tr>
                <th>Дата</th>
                <th>Возврат тела</th>
                <th>Проценты</th>
                <th>Членские взносы</th>
                <th>Пени</th>
                <th>Сумма долга</th>
              </tr>
              </thead>
              <tbody>
              <tr className="text-left">
                <td>{moment(data && data.Date).format("DD.MM.YYYY")}</td>
                <td>
                  {data && (data.BodyBack).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                <td>
                  {data && (data.Percent).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                <td>
                  {data && (data.MemberFee).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                <td>
                  {data && (data.Penalty).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
                <td>
                  {data && (data.MonthlyPay).toLocaleString("ru", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>
              </tr>
              </tbody>
            </table>}
          </div>
        </div>
      </LoadingOverlay>
    );
  }

  public componentDidMount(): void {
    const { BillId, fetchLoanEarlyCloseRequest } = this.props;
    fetchLoanEarlyCloseRequest({ id: BillId, date: moment().format("YYYY-MM-DD") });
  }

  private handleChangeDate = (date: Date, event: any) => {
    const { BillId, fetchLoanEarlyCloseRequest } = this.props;
    if (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value)) {
      fetchLoanEarlyCloseRequest({ id: BillId, date: moment(date).format("YYYY-MM-DD") });
    }
  };
}
