import React, { PureComponent } from "react";
import cuid from "cuid";
import moment from "moment";

import { BillDetails } from "../../../../store/billDetails";
import styled from "../../../../styled/styled-components";
import CurrencyLabel from "../../../../components/CurrencyLabel/CurrencyLabel";
import { YESNO } from "../../../../store/labels/constants";

const OperatorLabel = styled.label`
    font-weight: bold;
`;

export interface FormItemProps {
  label: string,
  component: string,
  value: number[],
  notShowCondition?: boolean,
}

export default class BillDetailsForm extends PureComponent<BillDetails> {
  public render() {
    const {
      Type: { Value }
    } = this.props;
    return (
      <div className="row">
        {this.prepareData().map((item: any[], index: number) => (
          <div className="col-md-6 mt-3" key={index}>
            {/* tslint:disable-next-line:no-shadowed-variable */}
            {item.filter((item: FormItemProps) => item.value.includes(Value) && !item.notShowCondition).map((item: FormItemProps) => (
              <div className="row" key={cuid()}>
                <OperatorLabel className="col-md-6">{item.label}</OperatorLabel>
                {item.component}
              </div>))}
          </div>))}
      </div>
    );
  }

  private prepareData = () => {
    const {
      PartnerName,
      PartnerNumber,
      BillCode,
      OpenDate,
      FuncPay: { Title: TitleFuncPay },
      Status: { Title: TitleStatus },
      Percent,
      Member,
      Fine,
      ProductName,
      Balance,
      Currency,
      LoanSum,
      Period,
      CloseDate,
      ClearBalance,
      PaymentDestination,
      Uuid,
      LatePaymentDays,
      IsCapital,
    } = this.props;
    return [
      [
        {
          label: "Пайщик",
          component: <div className="col-md-6">{PartnerName}</div>,
          value: [1, 2, 3, 4, 5, 7, 9],
        },
        {
          label: "Номер пайщика",
          component: (<a className="col-md-6" href={`/client/Info/${PartnerNumber}`}>
            <span>{PartnerNumber}</span>
          </a>),
          value: [1, 2, 3, 4, 5, 7, 9],
        },
        {
          label: "Код счета",
          component: <div className="col-md-6">{BillCode}</div>,
          value: [1, 2, 3, 4, 5, 7, 9],
        },
        {
          label: "Дата открытия",
          component: <div className="col-md-6">{moment(OpenDate).format("DD.MM.YYYY")}</div>,
          value: [1, 2, 3, 4, 5, 7, 9],
        }
      ],
      [
        {
          label: "Продукт",
          component: <div className="col-md-6">{ProductName}</div>,
          value: [1, 2, 3, 4, 5, 7, 9],
        },
        {
          label: "Баланс",
          component: (Currency &&
            <div className="col-md-6">
              <CurrencyLabel currency={Currency}>
                {Balance}
              </CurrencyLabel>
            </div>
          ),
          value: [1, 2, 3, 4, 5, 7, 9],
        },
        {
          label: "Валюта",
          component: <div className="col-md-6">{Currency}</div>,
          value: [1, 2, 3, 4, 5, 7, 9],
        },
        {
          label: "Статус",
          component: <div className="col-md-6">{TitleStatus}</div>,
          value: [1, 2, 3, 4, 5, 7, 9],
        }
      ],
      [
        {
          label: "Функция начисления",
          component: <div className="col-md-6">{TitleFuncPay}</div>,
          value: [4, 5, 7, 3, 9],
        },
        {
          label: "Капитализация",
          component: <div className="col-md-6">{YESNO.get(Number(IsCapital))}</div>,
          value: [3, 7],
        },
        {
          label: "Процентная ставка",
          component: <div className="col-md-6">{Percent && (Percent / 100).toLocaleString('ru', {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}</div>,
          value: [4, 5, 7, 3, 9],
        },
        {
          label: "Членский взнос",
          component: <div className="col-md-6">{Member && (Member / 100).toLocaleString('ru', {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}</div>,
          value: [4, 5, 3, 9],
        },
        {
          label: "Пеня",
          component: <div className="col-md-6">{Fine && (Fine / 100).toLocaleString('ru', {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}</div>,
          value: [4],
        }
      ],
      [
        {
          label: "Сумма займа",
          component: (Currency &&
            <div className="col-md-6">
              <CurrencyLabel currency={Currency}>
                {LoanSum}
              </CurrencyLabel>
            </div>
          ),
          value: [4],
        },
        {
          label: "Срок (месяцы)",
          component: <div className="col-md-6">{Period}</div>,
          value: [4],
        },
        {
          label: "Срок (дни)",
          component: <div className="col-md-6">{Period}</div>,
          value: [7, 3, 9],
        },
        {
          label: "Дата закрытия",
          component: <div className="col-md-6">{moment(CloseDate).format("DD.MM.YYYY")}</div>,
          value: [4, 7, 3, 9],
        },
        {
          label: "Баланс с доходом на сегодня",
          component: (Currency &&
            <div className="col-md-6">
              <CurrencyLabel currency={Currency}>
                {ClearBalance}
              </CurrencyLabel>
            </div>
          ),
          value: [5],
        },
        {
          label: "Назначение платежа",
          component: <div className="col-md-6">{PaymentDestination}</div>,
          value: [5],
        },
        {
          label: "UUID счета",
          component: (Uuid && <div className="col-md-6">{Uuid}</div>),
          value: [4],
        },
        {
          label: "Количество дней просрочки",
          component: (LatePaymentDays > 0 && <div className="col-md-6">{LatePaymentDays}</div>),
          value: [4],
          notShowCondition: !(LatePaymentDays > 0)
        }
      ]
    ];

  };
}
