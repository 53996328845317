import { Reducer } from "redux";
import { ActionTypes } from "./actions";
import { BatchOperationsState, CurrencyRequests } from "./types";

const initialState: BatchOperationsState = {
  batchOperations: {
    loading: false,
    error: undefined,
    data: [],
  },
  employees: {
    loading: false,
    error: undefined,
    data: [],
  },
  submiting: false,
  message: '',
  error: '',
};

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_OPERATIONS_BY_CURRENCY_REQUEST:
      let { data: dataRequest } = state.batchOperations;
      if (action.payload.Currency) {
        dataRequest = dataRequest.filter((item: CurrencyRequests) => item.Currency !== action.payload);
      }
      return {
        ...state,
        batchOperations: {
          ...state.batchOperations,
          loading: true,
          data: dataRequest,
        }
      };
    case ActionTypes.GET_ALL_OPERATIONS_BY_CURRENCY_SUCCESS:
      const data = state.batchOperations.data.filter((item: CurrencyRequests) => item.Currency !== action.payload.Currency );
      return {
        ...state,
        batchOperations: {
          ...state.batchOperations,
          data: [...data, action.payload],
          loading: false,
        }
      };
    case ActionTypes.GET_ALL_OPERATIONS_BY_CURRENCY_ERROR:
      return {
        ...state,
        batchOperations: {
          ...state.batchOperations,
          loading: false,
          error: action.payload
        }
      };

    case ActionTypes.GET_EMPLOYEES_REQUEST:
      return {
        ...state,
        employees: {
          ...state.employees,
          loading: true,
          data: [],
          error: undefined,
        }
      };
    case ActionTypes.GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: {
          ...state.employees,
          loading: false,
          data: action.payload,
          error: undefined,
        }
      };
    case ActionTypes.GET_EMPLOYEES_ERROR:
      return {
        ...state,
        employees: {
          ...state.employees,
          loading: false,
          data: [],
          error: action.payload,
        }
      };

    case ActionTypes.SUBMIT_BATCH_FORM_REQUEST:
      return {
        ...state,
        message: '',
        submiting: true,
        error: undefined,
      };
    case ActionTypes.SUBMIT_BATCH_FORM_SUCCESS:
      return {
        ...state,
        message: action.payload,
        submiting: false,
        error: undefined,
      };
    case ActionTypes.SUBMIT_BATCH_FORM_ERROR:
      return {
        ...state,
        message: '',
        submiting: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export { reducer as batchOperationsReducer };
