import React, { PureComponent } from "react";
import { toast } from "react-toastify";
import Button, { ButtonProps } from "../Button/Button";
import ApiClient from "../../services/api";

import './style.scss';

export interface PrintButtonState {
  loading: boolean;
}

export interface PrintButtonProps {
  docType?: string;
}

export default class PrintButton extends PureComponent<ButtonProps & PrintButtonProps, PrintButtonState> {
  public state: PrintButtonState = {
    loading: false,
  };

  public render() {
    const { href, docType, ...props } = this.props;
    return (
      <Button { ...props } onClick={this.printDocument} loading={this.state.loading} disabled={this.state.loading}>
        {this.props.children}
      </Button>
    );
  }

  private printDocument = async () => {
    const { href, docType } = this.props;
    const { loading }  = this.state;

    if (loading) {
      return ;
    }

    try {
      this.setState({
        loading: true,
      });
      await ApiClient.downloadFile(href, docType);
    } catch (e) {
      let errorMessage = "Произошла ошибка. Попробуйте снова";
      if (e) {
        errorMessage = e.ExceptionMessage || e.Message;
      }
      toast(errorMessage, {
        type: toast.TYPE.ERROR
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

}
