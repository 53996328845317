import React from 'react';
import { Tree } from 'antd';
import { isEmpty } from 'lodash';
const { TreeNode } = Tree;

interface TreeAccessFormProps {
  treeAccess: any[];
  checkedKeys: string[];
  onCheck: (checkedKeys: any) => void;
}

interface TreeAccessFormState {
  expandedKeys: string[];
  selectedKeys: string[];
  autoExpandParent: boolean;
}

export default class TreeAccessForm extends React.Component<TreeAccessFormProps>{
  public state: TreeAccessFormState = {
    expandedKeys: [],
    autoExpandParent: true,
    selectedKeys: [],
  };

  public render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    if (isEmpty(this.props.treeAccess)) {

      return null;
    }

    return (
      <Tree
        checkable
        onExpand={this.onExpand}
        expandedKeys={this.state.expandedKeys}
        autoExpandParent={this.state.autoExpandParent}
        onCheck={this.props.onCheck}
        checkedKeys={this.props.checkedKeys}
        onSelect={this.onSelect}
        selectedKeys={this.state.selectedKeys}
      >
        {this.renderTreeNodes(this.props.treeAccess)}
      </Tree>
    );
  }

  private onExpand = (expandedKeys: any) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };


  private onSelect = (selectedKeys: any, info: any) => {
    // console.log('onSelect', info);
    // this.setState({ selectedKeys });
  };

  private renderTreeNodes = (data: any) =>
    data.map((item: any, index: number) => {
      if (item.children) {
        return (
          <TreeNode title={item.Section} key={`${item.Id}-${index}`} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.Id} title={item.Section} />;
    });

}
