import * as React from 'react';
import { RouteComponentProps, StaticContext } from 'react-router';
import ApiClient from '../../../services/api';
import { SmsInterface } from '../types';
import { ListingHeader, TableMessage } from '../components/Views';
import {
  Table,
  TableHead,
  TableRow,
  TableCellHead,
  TableBody,
  TableCell,
} from '../../../components/Table';
import {
  SMS_LIST_FIELDS,
  SMS_FIELDS_LABELS,
  ALIGN_CELL_BY_FIELD,
  MESSAGES,
  STATUS_LABELS,
} from '../constants';
import Button from '../../../components/Button/Button';
import { formatValue, getPageFromSearch, сutFirstPageFromQuery } from '../utils';
import Pagination from '../../../components/Pagination/Pagination';
import { toast } from 'react-toastify';
import Helmet from 'react-helmet';

export interface SmsListProps
  extends RouteComponentProps<{}, StaticContext, { afterDelete?: boolean }> {}

export interface SmsListState {
  data: SmsInterface[];
  fetchError: string | null;
  loading: boolean;
  page: number;
  totalCount: number;
}

export default class SmsList extends React.Component<SmsListProps, SmsListState> {
  public static PAGE_SIZE = 10;

  constructor(props: SmsListProps) {
    super(props);

    сutFirstPageFromQuery(props.history, props.location);

    this.state = {
      data: [],
      fetchError: null,
      loading: false,
      page: getPageFromSearch(this.props.location.search),
      totalCount: 0,
    };
  }

  public componentDidMount() {
    this.getData();

    if (this.props.location.state && this.props.location.state.afterDelete) {
      this.props.history.replace({ ...this.props.location, state: {} });
      toast(MESSAGES.deleteSuccess, {
        type: toast.TYPE.SUCCESS,
      });
    }
  }

  public componentWillReceiveProps(nextProps: SmsListProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        page: getPageFromSearch(nextProps.location.search),
      });

      сutFirstPageFromQuery(nextProps.history, nextProps.location);
    }
  }

  public componentDidUpdate(_prevProps: SmsListProps, prevState: SmsListState) {
    if (prevState.page !== this.state.page) {
      this.getData();
    }
  }

  public render() {
    return (
      <div>
        <Helmet defer={false}>
          <title>SMS рассылки</title>
        </Helmet>
        <ListingHeader />
        <Table>
          <TableHead>
            <TableRow>
              {SMS_LIST_FIELDS.map(field => (
                <TableCellHead align={ALIGN_CELL_BY_FIELD.get(field) || 'left'} key={field}>
                  {SMS_FIELDS_LABELS[field]}
                </TableCellHead>
              ))}
              <TableCellHead />
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.data.length === 0 && (
              <TableRow>
                <TableCell colSpan={SMS_LIST_FIELDS.length}>
                  {this.state.loading ? this.renderLoadingMessage() : this.renderNotFoundMessage()}
                </TableCell>
              </TableRow>
            )}
            {this.state.data.map(item => (
              <TableRow key={item.Id}>
                {SMS_LIST_FIELDS.map(field => (
                  <TableCell align={ALIGN_CELL_BY_FIELD.get(field) || 'left'} key={field}>
                    {field === 'IsSended'
                      ? !item.IsSended && item.ReleaseTime === null
                        ? STATUS_LABELS.get('Canceled')
                        : item.IsSended
                        ? STATUS_LABELS.get('Sended')
                        : STATUS_LABELS.get('Schedule')
                      : formatValue(field, item[field])}
                  </TableCell>
                ))}
                <TableCell align="right">
                  <Button routerLink href={`/tools/sms/edit/${item.Id}`} type="primary">
                    Подробнее
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {this.state.totalCount > SmsList.PAGE_SIZE && (
          <Pagination
            page={this.state.page}
            size={SmsList.PAGE_SIZE}
            count={this.state.totalCount}
            callback={this.handleChangePage}
          />
        )}
      </div>
    );
  }

  private getData = async () => {
    this.setState({
      loading: true,
    });
    try {
      const { SmsDistributions, TotalRecords } = await ApiClient.getSmsList(this.state.page);

      this.setState({
        data: SmsDistributions,
        fetchError: null,
        totalCount: TotalRecords,
      });
    } catch (error) {
      this.setState({
        fetchError: error.message,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  private renderNotFoundMessage = () => <TableMessage>Рассылок не найдено...</TableMessage>;

  private renderLoadingMessage = () => <TableMessage>Загрузка...</TableMessage>;

  private handleChangePage = (page: number) => {
    const queryParams = new URLSearchParams(this.props.location.search);

    queryParams.set('page', page.toString());
    this.props.history.replace(`${location.pathname}?${queryParams.toString()}`);
  };
}
