import { ServerResponse } from "./types";

export const serializeLabels = (response: ServerResponse) => {
  const result = new Map<string | number, string>();

  response.forEach(({ Value, Text }) => {
    result.set(Value, Text);
  });

  return result;
};
