import React from "react";
import * as dateFns from "date-fns";
import ru from "date-fns/locale/ru";
import moment from "moment";
import { clone, isEmpty, find, remove } from "lodash";
import { Table, Collapse } from "reactstrap";

import "./index.scss";
import apiClient from "../../../../services/api";
import Button from "../../../../components/Button/Button";
import Link from "../../../../components/Link";


class Calendar extends React.Component {
  state = {
    currentMonth: new Date(),
    currentDate: new Date(),
    selectedDate: new Date(),
    dataMonth: {},
    details: [],
    openedItems: []
  };

  componentDidMount() {
    this.getDataMonth();
  }

  renderHeader() {
    const dateFormat = "LLLL yyyy";

    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={this.prevMonth}>
            chevron_left
          </div>
        </div>
        <div className="col col-center">
          <span>{dateFns.format(this.state.currentMonth, dateFormat, { locale: ru })}</span>
        </div>
        <div className="col col-end" onClick={this.nextMonth}>
          <div className="icon">chevron_right</div>
        </div>
      </div>
    );
  }

  renderDays() {
    const dateFormat = "iiii";
    const days = [];

    let startDate = dateFns.startOfWeek(this.state.currentMonth, { locale: ru });

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {dateFns.format(dateFns.addDays(startDate, i), dateFormat, { locale: ru })}
        </div>
      );
    }

    return <div className="days row">{days}</div>;
  }

  renderCells() {
    const { currentMonth, selectedDate, currentDate } = this.state;
    const monthStart = dateFns.startOfMonth(currentMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart, { locale: ru });
    const endDate = dateFns.endOfWeek(monthEnd, { locale: ru });

    const dateFormat = "dd";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat, { locale: ru });
        const cloneDay = clone(day);
        days.push(
          <div
            className={`col cell ${
              !dateFns.isSameMonth(day, monthStart)
                ? "disabled"
                : dateFns.isSameDay(day, selectedDate) ? "selected" : ""
              } ${
              !dateFns.isSameMonth(day, monthStart)
                ? "disabled"
                : dateFns.isSameDay(day, currentDate) ? "currentDate" : ""
              } ${
              !dateFns.isSameMonth(day, monthStart)
                ? "disabled"
                : this.checkHaveDay(cloneDay)
              }`}
            key={day}
            onClick={() => this.onDateClick(cloneDay)}
          >
            <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span>
          </div>
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }

  onDateClick = day => {
    this.setState({
      selectedDate: day
    }, () => {
      this.props.onChangeCalendar(dateFns.format(day, "yyyy-MM-dd", { locale: ru }));
    });
  };

  nextMonth = () => {
    this.setState({
      currentMonth: dateFns.addMonths(this.state.currentMonth, 1)
    }, () => {
      this.getDataMonth();
    });
  };

  prevMonth = () => {
    this.setState({
      currentMonth: dateFns.subMonths(this.state.currentMonth, 1)
    }, () => {
      this.getDataMonth();
    });
  };

  render() {
    const { onChangeCalendar } = this.props;
    return (
      <div className="row">
        <div className="col-md-6">
          <div className="calendar">
            {this.renderHeader()}
            {this.renderDays()}
            {this.renderCells()}
          </div>

        </div>
        <div className="col-md-6">
          {/*{this.renderTable()}*/}
          <Button onClick={onChangeCalendar.bind(null, dateFns.format(this.state.currentMonth, "yyyy-MM", { locale: ru }))} type="primary" className="mt-3">
            Весь {dateFns.format(this.state.currentMonth, "LLLL yyyy", { locale: ru })}
          </Button>
          <Button onClick={onChangeCalendar.bind(null, dateFns.format(this.state.currentMonth, "yyyy", { locale: ru }))} type="primary" className="mt-3 ml-2">
            Весь год {dateFns.format(this.state.currentMonth, "yyyy", { locale: ru })}
          </Button>
          <Button onClick={onChangeCalendar.bind(null, null)} type="success" className="mt-3 ml-2">
            Сбросить фильтр
          </Button>
          <div className="row mt-3">
            <div className="col-2 currentDate">
            </div>
            <div className="col-10">
              Текущий день
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-2 payment-date">
            </div>
            <div className="col-10">
              Есть какое-то событие - платеж по займу, СП или ИЗ
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-2">
              <div className='blink text-center w-25 h-25'>
                <div className="number">31</div>
              </div>
            </div>
            <div className="col-10">
              Закрытие счета СП или ИЗ
            </div>
          </div>
        </div>
      </div>
    );
  }

  getDataMonth = async () => {
    const { currentMonth } = this.state;
    const { changeLoading } = this.props;
    changeLoading(true);

    const monthStart = dateFns.startOfMonth(currentMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);
    const result = await apiClient.getReportsCalendar({
      start: moment(startDate).format("YYYY-MM-DD"),
      end: moment(endDate).format("YYYY-MM-DD")
    });

    this.setState({ dataMonth: result, details: [] });

    changeLoading(false);

  };

  getDayDetails = async (day) => {
    const { changeLoading } = this.props;
    changeLoading(true);

    const result = await apiClient.getReportsCalendarDetails({
      date: moment(day).format("YYYY-MM-DD")
    });

    this.setState({ details: [result] });

    changeLoading(false);

  };


  getMonthDetails = async () => {
    const { changeLoading, toogleShowAllMonth } = this.props;
    const { currentMonth } = this.state;
    changeLoading(true);
    toogleShowAllMonth();

    const result = await apiClient.getReportsCalendarMonthDetails({
      date: moment(currentMonth).format("YYYY-MM")
    });

    this.setState({ details: result });

    changeLoading(false);

  };

  getYearDetails = async () => {
    const { changeLoading, toogleShowAllYear } = this.props;
    const { currentMonth } = this.state;
    changeLoading(true);
    toogleShowAllYear();

    const result = await apiClient.getReportsCalendarYearDetails(moment(currentMonth).format("YYYY"));
    var details = Object.values(result).reduce(function(res, arr) {
      return [...res, ...arr];
    }, []);

    this.setState({ details });

    changeLoading(false);

  };

  checkHaveDay = (day) => {

    const { dataMonth } = this.state;
    const compareDay = moment(day);
    let className = "";

    if (isEmpty(dataMonth) || isEmpty(dataMonth.PaymentDates)) {
      return false;
    }
    const date = find(dataMonth.PaymentDates, (item) => {
      return moment(item.Date).isSame(compareDay);
    });

    if (!isEmpty(date)) {
      className += " payment-date";

      if (date.IsClosed) {
        className += " blink";
      }
    }


    return className;
  };

  renderTable = () => {
    const { details, openedItems } = this.state;
    if (isEmpty(details) || isEmpty(details[0])) {
      return;
    }
    return (
      <Table hover striped>
        <thead>
        <tr>
          <th>Название</th>
          <th>Сумма</th>
          <th>Дата</th>
        </tr>
        </thead>
        <tbody>
        {details.map((detailsMonth) => detailsMonth.map((item) => (
          <React.Fragment key={`${item.Date}-${item.Name}`}>
            <tr onClick={this.toggleItem(item)} className="cursor-pointer">
              <td>{item.Name}</td>
              <td>• {item.PaymentCollections.map((PaymentCollection) => (
                <React.Fragment key={`${PaymentCollection.Total}-${PaymentCollection.CurrencyName}`}> {PaymentCollection.Total} {PaymentCollection.CurrencyName} •</React.Fragment>))}</td>
              <td>{moment(item.Date).format("DD.MM.YYYY")} {this.checkCloses(item) ? ' (есть закрытия)' : ''}</td>
            </tr>
            {openedItems.includes(`${item.Date}-${item.Name}`) && <tr>
              <td colSpan={3}>
                <Collapse isOpen>
                  <Table>
                    <thead>
                    <tr>
                      <th>Счет</th>
                      <th>ФИО</th>
                      <th>Сумма</th>
                      <th>Закрытие</th>
                    </tr>
                    </thead>
                    <tbody>
                    {item.PaymentCollections.map((PaymentCollection) => PaymentCollection.Payments.map((Payment) => (
                      <tr key={`${Payment.BillCode}-${Payment.Total}-${Payment.Currency}`} className={Payment.IsClosed ? 'payment-date' : ''}>
                        <td>
                          <Link routerLink href={`/Bill/Details/${Payment.BillCode}`}>
                            {Payment.BillCode}
                          </Link>
                        </td>
                        <td>{Payment.PartnerName}</td>
                        <td className="no-wrap">{`${Payment.Total} ${Payment.Currency}`}</td>
                        <td className="text-center">{Payment.IsClosed ? 'X' : ''}</td>
                      </tr>
                    )))}
                    </tbody>
                  </Table>
                </Collapse>
              </td>
            </tr>}
          </React.Fragment>
        )))}
        </tbody>
      </Table>
    );
  };

  toggleItem = (item) => () => {
    const { openedItems } = this.state;


    const items = clone(openedItems);
    const key = `${item.Date}-${item.Name}`;
    if (items.includes(key)) {
      remove(items, (n) => (n === key));
    } else {
      items.push(key);
    }

    return this.setState({
      openedItems: items
    });
  };

  checkCloses = (item) => {
    let result = false;
    if (isEmpty(item.PaymentCollections)) {
      return false;
    }

    item.PaymentCollections.forEach((PaymentCollection) => PaymentCollection.Payments.forEach((Payment) => {
      if (Payment.IsClosed) {
        result = true;
      }
    }));

    return result;
  }
}

export default Calendar;
