import React, { Component } from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import moment from 'moment';

const defaultExtractor = item => item.Value;

export default class CurrencySums extends Component {
  render() {
    const {
      data = [],
      title,
      field,
      valueExtractor = defaultExtractor,
    } = this.props;
    const rows = data.reduce((result, item) => {
      if (!item[field]) return result;
      const elements = item[field].map((point) => {
        const firstDate = moment(point.StartDate).format('MMM YYYY');
        const lastDate = moment(point.EndDate).format('MMM YYYY');

        return (
          <StyledRow key={`${item.Currency}-${firstDate}`}>
            <RowTitle>{title} за период {firstDate} - {lastDate} ({item.Currency}):</RowTitle>
            <RowValue>{valueExtractor(point)}</RowValue>
          </StyledRow>
        );
      });
      return [...result, ...elements];
    }, []);

    return (
      <Container>
        <Title>{title} за период</Title>
        <Content>
          {rows.length > 0 ? rows : <NoResults>Нет данных</NoResults>}
        </Content>
      </Container>
    );
  }
}

const Container = styled(Flex).attrs({ column: true })``;
const Title = styled.h3``;
const Content = styled(Flex).attrs({ column: true })``;
const StyledRow = styled(Flex).attrs({ row: true, alignBaseline: true, justifyAround: true })`
  width: 100%;
  max-width: 600px;
`;
const RowTitle = styled.h5``;
const RowValue = styled.div``;
const NoResults = styled.h4``;
