import { all, call, put, takeEvery } from "redux-saga/effects";
import { AnyAction } from "redux";
import {
  getOperationsByCurrencySuccess,
  getOperationsByCurrencyError,
  ActionTypes,
  getEmployeesSuccess,
  getEmployeesError,
  submitFormSuccess,
  submitFormError
} from "./actions";
import { putError } from '../global/actions';
import { CurrencyRequests } from "./types";
import ApiClient from "../../services/api";

function* getOperations(action: AnyAction) {
  try {
    const result: CurrencyRequests = yield call(ApiClient.getPaymentRequestByCurrency, action.payload );

    yield put(getOperationsByCurrencySuccess(result));
  } catch (error) {
    yield put(putError(error));
    yield put(getOperationsByCurrencyError(error));
  }
}

function* getEmployees(action: AnyAction) {
  try {
    const result: CurrencyRequests = yield call(ApiClient.getEmployees);

    yield put(getEmployeesSuccess(result));
  } catch (error) {
    yield put(putError(error));
    yield put(getEmployeesError(error));
  }
}

function* submitBatchForm(action: AnyAction) {
  try {
    const result = yield call(ApiClient.createGroupOperation, action.payload);

    yield put(submitFormSuccess(result.Message));
  } catch (error) {
    yield put(putError(error));
    yield put(submitFormError(error));
  }
}

function* batchOperationsSaga() {
  yield all([
    yield takeEvery(ActionTypes.GET_ALL_OPERATIONS_BY_CURRENCY_REQUEST, getOperations),
    yield takeEvery(ActionTypes.GET_EMPLOYEES_REQUEST, getEmployees),
    yield takeEvery(ActionTypes.SUBMIT_BATCH_FORM_REQUEST, submitBatchForm),
  ]);
}

export default batchOperationsSaga;
