import axios from './axios.ts';
import { openBlob, saveBlob } from '../utils/index.ts';

const fileReader = (file) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new Error('Файл поврежден'));
    };

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.readAsText(file);
  });
};

const fileErrorStrategy = async (error) => {
  if (error.response) {
    try {
      const { data } = error.response;

      const file = await fileReader(data);
      const { ExceptionMessage: Message } = JSON.parse(file);

      return Promise.reject(Message);
    } catch (readError) {
      return Promise.reject(new Error('Произошла неизвестная ошибка'));
    }
  } else {
    return Promise.reject(new Error('Произошла неизвестная ошибка'));
  }
};

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

class ApiClient {
  static getLastCheckResult(params) {
    return axios
      .get(`${API_ENDPOINT}/FinMonitoring`, { params: { ordering: 'desc', ...params } })
      .then(result => result.data);
  }

  static getLastCheckResultWeapons(params) {
    return axios
      .get(`${API_ENDPOINT}/FinMonitoring/weapon-distribution`, { params: { ordering: 'desc', ...params } })
      .then(result => result.data);
  }

  static getLastCheckResultBlock(params) {
    return axios
      .get(`${API_ENDPOINT}/FinMonitoring/illegal-block`, { params: { ordering: 'desc', ...params } })
      .then(result => result.data);
  }

  static uploadFileForCheck(data, onUploadProgress) {
    return axios
      .post(`${API_ENDPOINT}/FinMonitoring/CheckAsync`, data, { onUploadProgress })
      .then(result => result.data);
  }

  static uploadFileForCheckWeapons(data, onUploadProgress) {
    return axios
      .post(`${API_ENDPOINT}/FinMonitoring/weapon-distribution`, data, { onUploadProgress })
      .then(result => result.data);
  }

  static uploadFileForCheckBlock(data, onUploadProgress) {
    return axios
      .post(`${API_ENDPOINT}/FinMonitoring/illegal-block`, data, { onUploadProgress })
      .then(result => result.data);
  }

  static getPublications(type) {
    let url = '';
    if (type !== 'all') {
      url = `/${type}`;
    }
    return axios
      .get(`${API_ENDPOINT}/publications${url}`, { params: { ordering: 'desc' } })
      .then(result => result.data);
  }

  static createPublication(data) {
    return axios.post(`${API_ENDPOINT}/publications`, data);
  }

  static updatePublication(id, data) {
    return axios.post(`${API_ENDPOINT}/publications/${id}`, data);
  }

  static getPublication(id) {
    return axios.get(`${API_ENDPOINT}/publications/${id}`).then(result => result.data);
  }

  static deletePublication(id) {
    return axios.get(`${API_ENDPOINT}/publications/delete/${id}`).then(result => result.data);
  }

  static uploadImage(item) {
    let data = item;
    if (!(item instanceof FormData)) {
      data = new FormData();
      data.append('image', item);
    }
    return axios
      .post(`${API_ENDPOINT}/images/addimage`, data)
      .then(result => ({ data: { link: result.data } }));
  }

  static getOperations(params = {}) {
    const { page, ...filter } = params;
    const hasFilter = Object.keys(filter).length > 0;
    const requestData = {};

    if (typeof page === 'number') {
      requestData.page = page;
    }

    if (hasFilter) {
      if (filter.numberOfPartner) {
        const [IdRegion, IdYears, IdPartners] = filter.numberOfPartner.split('-');

        requestData.IdRegion = IdRegion;
        requestData.IdYears = IdYears;
        requestData.IdPartners = IdPartners;
      }

      requestData.CurrencyId = filter.currency;
      requestData.OperationId = filter.operationId;
      requestData.NumberBill = filter.numberBill;
      requestData.NumberOfPartner = filter.numberOfPartner;
      requestData.StaffName = filter.staffName;
      requestData.OperationName = filter.operationName;
      requestData.StartOperationDate = filter.dateStart;
      requestData.EndOperationDate = filter.dateEnd;
      requestData.OperationType = filter.type;
      requestData.OperationSum = filter.operationSum;
    }

    const brokenRequestData = {};

    Object.entries(requestData).forEach(([key, value]) => {
      if (value !== undefined) {
        brokenRequestData[key] = value;
      }
    });

    return axios
      .get(`${API_ENDPOINT}/operations`, {
        params: brokenRequestData,
      })
      .then(result => result.data);
  }

  static getOperationDetail(id) {
    return axios.get(`${API_ENDPOINT}/operations/${id}`).then(result => result.data);
  }

  static removeOperation(id) {
    return axios.patch(`${API_ENDPOINT}/operations/${id}`).then(result => result.data);
  }

  static downloadOperations(params = {}) {
    const { ...filter } = params;
    const hasFilter = Object.keys(filter).length > 0;
    const requestData = {};

    if (hasFilter) {
      if (filter.numberOfPartner) {
        const [IdRegion, IdYears, IdPartners] = filter.numberOfPartner.split('-');

        requestData.IdRegion = IdRegion;
        requestData.IdYears = IdYears;
        requestData.IdPartners = IdPartners;
      }

      requestData.CurrencyId = filter.currency;
      requestData.OperationId = filter.operationId;
      requestData.NumberBill = filter.numberBill;
      requestData.NumberOfPartner = filter.numberOfPartner;
      requestData.StaffName = filter.staffName;
      requestData.OperationName = filter.operationName;
      requestData.StartOperationDate = filter.dateStart;
      requestData.EndOperationDate = filter.dateEnd;
      requestData.OperationType = filter.type;
      requestData.OperationSum = filter.operationSum;
    }

    const brokenRequestData = {};

    Object.entries(requestData).forEach(([key, value]) => {
      if (value !== undefined) {
        brokenRequestData[key] = value;
      }
    });

    return axios
      .get(`${API_ENDPOINT}/operations/download`, {
        params: brokenRequestData,
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'operations.xml');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  static getSmsList(Page = 1) {
    return axios
      .get(`${API_ENDPOINT}/sms-distributions${Page && Page > 1 ? `?Page=${Page}` : ''}`)
      .then(result => result.data);
  }

  static getPushList() {
    return axios
      .get(`${API_ENDPOINT}/push-distributions`)
      .then(result => result.data);
  }

  static getSmsDetails(id) {
    return axios
      .get(`${API_ENDPOINT}/sms-distributions/${id}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getPushDetails(id) {
    return axios
      .get(`${API_ENDPOINT}/push-distributions/${id}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getPushDetailsUsers() {
    return axios
      .get(`${API_ENDPOINT}/push-distributions/partners`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static createOrUpdateSmsDistributions(data = {}) {
    const { Id, IsSended, ...requestData } = data;
    const isUpdate = !!Id;
    return axios[isUpdate ? 'patch' : 'post'](
      `${API_ENDPOINT}/sms-distributions${isUpdate ? `/${Id}` : ''}`,
      requestData,
    )
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static createOrUpdatePushDistributions(data = {}) {
    const { Id, IsSended, ...requestData } = data;
    const isUpdate = !!Id;
    return axios[isUpdate ? 'patch' : 'post'](
      `${API_ENDPOINT}/push-distributions${isUpdate ? `/${Id}` : ''}`,
      requestData,
    )
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static deleteSmsDistribution(id) {
    if (id === undefined) {
      return Promise.reject(new Error({ Message: 'Произошла ошибка' }));
    }

    return axios
      .delete(`${API_ENDPOINT}/sms-distributions/${id}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static sendPushDistribution(id) {
    if (id === undefined) {
      return Promise.reject(new Error({ Message: 'Произошла ошибка' }));
    }

    return axios
      .post(`${API_ENDPOINT}/push-distributions/${id}/send`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getPartners(query) {
    return axios
      .get(`${API_ENDPOINT}/partners${query ? `?${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getPartner(partnerNumber) {
    return axios
      .get(`${API_ENDPOINT}/partners/${partnerNumber}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getPartnerLoanForm(id) {
    return axios
      .get(`${API_ENDPOINT}/partners/${id}/loan-form`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getProduct(ProductId) {
    return axios
      .get(`${API_ENDPOINT}/Product/Get?id=${ProductId}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static copyProduct(ProductId) {
    return axios
      .get(`${API_ENDPOINT}/Product/Copy?id=${ProductId}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static createOrUpdatePartner(data, isCreate) {
    const uri = isCreate ? `${API_ENDPOINT}/partner/save` : `${API_ENDPOINT}/partners`;

    return axios[isCreate ? 'post' : 'put'](uri, data)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static createOrUpdateProduct(data, isCreate) {
    const uri = `${API_ENDPOINT}/Product/${isCreate ? 'Insert' : 'Update'}`;

    return axios.post(uri, data)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getInitialPartnerData() {
    return axios
      .get(`${API_ENDPOINT}/partners/new`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getInitialPartnerSalaryData() {
    return axios
      .get(`${API_ENDPOINT}/clients/new-salary`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  /**
   * @deprecated
   * use `getLabels` method
   */
  static getAllForOptions() {
    const transformResponse = (mapName, rawData) => ({
      name: mapName,
      rawData,
    });

    return Promise.all([
      axios.get(`${API_ENDPOINT}/User/GetAllForOptions`, {
        transformResponse: transformResponse.bind(null, 'UserLabels'),
      }),
      axios.get(`${API_ENDPOINT}/PartnerType/GetAllForOptions`, {
        transformResponse: transformResponse.bind(null, 'PartnerTypes'),
      }),
      axios.get(`${API_ENDPOINT}/User/GetManagersForOptions`, {
        transformResponse: transformResponse.bind(null, 'Managers'),
      }),
      axios.get(`${API_ENDPOINT}/BillType/GetAllForOptions`, {
        transformResponse: transformResponse.bind(null, 'BillTypes'),
      }),
      axios.get(`${API_ENDPOINT}/Currency/GetAllForOptions`, {
        transformResponse: transformResponse.bind(null, 'Currencies'),
      }),
      axios.get(`${API_ENDPOINT}/User/GetAllForOptions`, {
        transformResponse: transformResponse.bind(null, 'Users'),
      }),
    ])
      .then((responseArray) => {
        const result = {};

        responseArray.forEach((response) => {
          const map = new Map();
          const { rawData } = response.data;
          const data = typeof rawData === 'object' ? rawData : JSON.parse(rawData);

          data.forEach(item => map.set(item.Value, item.Text));

          result[response.data.name] = map;
        });

        return result;
      })
      .catch(error => error);
  }

  static getBillsForPartner(partnerId) {
    return axios
      .get(`${API_ENDPOINT}/Bill/BillsForPartner?partnerId=${partnerId}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getAutocompleteForPartners(query) {
    return axios
      .get(`${API_ENDPOINT}/Partner/AutoComplete${query ? `?${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getClients(query) {
    return axios
      .get(`${API_ENDPOINT}/clients${query ? `?${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getClient(clientId) {
    return axios
      .get(`${API_ENDPOINT}/clients/${clientId}?v=${new Date().getTime()}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getCheckList(clientId) {
    return axios
      .get(`${API_ENDPOINT}/clients/${clientId}/checklist`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static updateCheckList(clientId, data) {
    return axios
      .patch(`${API_ENDPOINT}/clients/${clientId}/checklist`, data)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static resendRequisites(clientId) {
    return axios
      .get(`${API_ENDPOINT}/clients/${clientId}/resend-requisites`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static createOrUpdateClient(data) {
    const isUpdate = !!data.Id;
    return axios[isUpdate ? 'patch' : 'put'](`${API_ENDPOINT}/clients`, data)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getInitialClientData() {
    return axios
      .get(`${API_ENDPOINT}/clients/new`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static rejectClientData(id) {
    return axios
      .put(`${API_ENDPOINT}/clients/${id}/toggle-active`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static deleteClientData(id) {
    return axios
      .delete(`${API_ENDPOINT}/clients/${id}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getClientLoanForm(id) {
    return axios
      .get(`${API_ENDPOINT}/clients/${id}/loan-form`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getClientCopyDocuments(id) {
    return axios
      .get(`${API_ENDPOINT}/clients/${id}/documents`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static saveClientLoanForm(id, data) {
    return axios
      .patch(`${API_ENDPOINT}/clients/${id}/loan-form`, data)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static savePartnerLoanForm(id, data) {
    return axios
      .patch(`${API_ENDPOINT}/partners/${id}/loan-form`, data)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static saveToPartner(id) {
    return axios
      .get(`${API_ENDPOINT}/clients/${id}/save-to-partner`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static saveToPartnerSalary(id) {
    return axios
      .get(`${API_ENDPOINT}/clients/${id}/save-to-salary-client`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getAutocompleteForClients(query) {
    return axios
      .get(`${API_ENDPOINT}/clients/auto-complete${query ? `?${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getCountries() {
    return axios
      .get(`${API_ENDPOINT}/Region/Countries`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getRegions(countryId) {
    return axios
      .get(`${API_ENDPOINT}/Region/Regions?countryId=${countryId}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getCities(regionId) {
    return axios
      .get(`${API_ENDPOINT}/Region/Cities?regionId=${regionId}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getStreets(query, cityId) {
    return axios
      .get(`${API_ENDPOINT}/region/streets?filter=${query}&cityId=${cityId}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static printHistoryTable(id, dateF, dateS) {
    return axios
      .get(`${API_ENDPOINT}/bill/BillHistoryPrint?id=${id}&dateF=${dateF}&dateS=${dateS}`, {
        responseType: 'blob',
      })
      .then(result => result.data)
      .catch(fileErrorStrategy);
  }

  static printClientLoanForm(id) {
    return axios
      .get(`${API_ENDPOINT}/clients/${id}/loan-form/print`, {
        responseType: 'blob',
      })
      .then(result => result.data)
      .catch(fileErrorStrategy);
  }

  static printPartnerLoanForm(id) {
    return axios
      .get(`${API_ENDPOINT}/partners/${id}/loan-form/print`, {
        responseType: 'blob',
      })
      .then(result => result.data)
      .catch(fileErrorStrategy);
  }

  static printClientSopd(id) {
    return axios
      .get(`${API_ENDPOINT}/clients/${id}/sopd/print`, {
        responseType: 'blob',
      })
      .then(result => result.data)
      .catch(fileErrorStrategy);
  }

  static printClientBki(id) {
    return axios
      .get(`${API_ENDPOINT}/clients/${id}/bki/print`, {
        responseType: 'blob',
      })
      .then(result => result.data)
      .catch(fileErrorStrategy);
  }

  static printConsentPersonalDataProcessing(partnerNumber) {
    return axios
      .get(`${API_ENDPOINT}/template/ConsentPersonalDataProcessing/${partnerNumber}`, {
        responseType: 'blob',
      })
      .then(result => result.data)
      .catch(fileErrorStrategy);
  }

  static printDecisionKPK(partnerNumber) {
    return axios
      .get(`${API_ENDPOINT}/template/DecisionKPK/${partnerNumber}`, {
        responseType: 'blob',
      })
      .then(result => result.data)
      .catch(fileErrorStrategy);
  }

  static printExcludingDecisionKPK(partnerNumber) {
    return axios
      .get(`${API_ENDPOINT}/template/ExcludingDecisionKPK/${partnerNumber}`, {
        responseType: 'blob',
      })
      .then(result => result.data)
      .catch(fileErrorStrategy);
  }

  static printStatementExclusionFromShareholders(partnerNumber) {
    return axios
      .get(`${API_ENDPOINT}/template/StatementExclusionFromShareholders/${partnerNumber}`, {
        responseType: 'blob',
      })
      .then(result => result.data)
      .catch(fileErrorStrategy);
  }

  static printPowerOfAttorney(partnerNumber, number) {
    return axios
      .get(`${API_ENDPOINT}/template/PowerOfAttorney/${partnerNumber}?agent=${number}`, {
        responseType: 'blob',
      })
      .then(result => result.data)
      .catch(fileErrorStrategy);
  }

  static printClientTitlePage(partnerNumber) {
    return axios
      .get(`${API_ENDPOINT}/partners/${partnerNumber}/title-page`, {
        responseType: 'blob',
      })
      .then(result => result.data)
      .catch(fileErrorStrategy);
  }

  static printConsentBureau(partnerId) {
    return axios
      .get(`${API_ENDPOINT}/template/ConsentBureau/${partnerId}`, {
        responseType: 'blob',
      })
      .then(result => result.data)
      .catch(fileErrorStrategy);
  }

  static printPartnerEntryApplication(partnerId) {
    return axios
      .get(`${API_ENDPOINT}/template/PartnerEntryApplication/${partnerId}/`, {
        responseType: 'blob',
      })
      .then(result => result.data)
      .catch(fileErrorStrategy);
  }

  static printIndividualQuestionnaire(partnerId) {
    return axios
      .get(`${API_ENDPOINT}/template/IndividualQuestionnaire/${partnerId}/`, {
        responseType: 'blob',
      })
      .then(result => result.data)
      .catch(fileErrorStrategy);
  }

  static printDecOfAccession(partnerNumber) {
    return axios
      .get(`${API_ENDPOINT}/template/DecOfAccession/${partnerNumber}/`, {
        responseType: 'blob',
      })
      .then(result => result.data)
      .catch(fileErrorStrategy);
  }

  static printAccessionAgr() {
    return axios
      .get(`${API_ENDPOINT}/template/AccessionAgr`, {
        responseType: 'blob',
      })
      .then(result => result.data)
      .catch(fileErrorStrategy);
  }

  static printRequisitesAlfaBank(partnerId, currency) {
    return axios
      .get(`${API_ENDPOINT}/template/RequisitesAlfaBank/${partnerId}/${currency}`, {
        responseType: 'blob',
      })
      .then(result => result.data)
      .catch(fileErrorStrategy);
  }
  static activatePartner(partnerId) {
    return axios
      .post(`${API_ENDPOINT}/accounts/${partnerId}/activate`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  /** PaymentRequests */
  static getPaymentRequests(query) {
    return axios
      .get(`${API_ENDPOINT}/payments-requests${query ? `?${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static downloadPaymentRequest(id) {
    return axios
      .get(`${API_ENDPOINT}/payments-requests/${id}/download`, {
        responseType: 'blob',
      })
      .then((result) => {
        const contentType = result.headers['content-type'] || 'application/octet-stream';
        const filename = result.headers['x-filename'] || `${id}_PaymentRequest.docx`;

        try {
          const blob = new Blob([result.data], {
            type: contentType,
          });

          saveBlob(blob, filename);
          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }
      })
      .catch(fileErrorStrategy);
  }

  static printPaymentRequest(id) {
    return axios
      .get(`${API_ENDPOINT}/payments-requests/${id}/print`, {
        responseType: 'blob',
      })
      .then((result) => {
        const filename = result.headers['x-filename'] || `${id}_PaymentRequest.pdf`;
        const file = new File([result.data], filename, { type: 'application/pdf' });
        openBlob(file, filename);
        return Promise.resolve();
      })
      .catch(fileErrorStrategy);
  }

  static execPaymentRequest(id) {
    return axios
      .post(`${API_ENDPOINT}/payments-requests/${id}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static deletePaymentRequest(id) {
    return axios
      .delete(`${API_ENDPOINT}/payments-requests/${id}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getNonExecutedCount() {
    return axios
      .get(`${API_ENDPOINT}/payments-requests/non-executed-count?timestamp=${new Date().getTime()}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getLabels(section, billType = '') {
    let path = null;

    switch (section) {
      case 'USERS':
        path = '/User/GetAllForOptions';
        break;

      case 'PARTNER_TYPES':
        path = '/PartnerType/GetAllForOptions';
        break;

      case 'PRODUCT_TYPES':
        path = '/ProductType/GetAllForOptions';
        break;

      case 'MANAGERS':
        path = '/User/GetManagersForOptions';
        break;

      case 'BILL_TYPES':
        path = '/BillType/GetAllForOptions';
        break;

      case 'CURRENCY':
        path = '/Currency/GetAllForOptions';
        break;

      case 'PRODUCT':
        path = '/Product/GetAllForOptions';
        break;

      case 'FUNC_PAY':
        path = '/FuncPay/GetAllForOptions';
        break;

      case 'OPERATIONS':
        path = `/Operation/BillOperationTypes?billCode=${billType}`;
        break;

      case 'GROUPS':
        path = '/group/GetAllForOptions';
        break;

      default:
        path = null;
        break;
    }

    if (!path) {
      return Promise.reject(Error('incorrect section name'));
    }

    if (billType && section !== 'OPERATIONS') {
      path += `?billType=${billType}`;
    }

    return axios
      .get(`${API_ENDPOINT}${path}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getDepositCalculator(data) {
    return axios
      .get(`${API_ENDPOINT}/calculators/deposits`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getRaisingCalculator(data) {
    return axios
      .get(`${API_ENDPOINT}/calculators/raisings`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getPensionCalculator(data) {
    return axios
      .get(`${API_ENDPOINT}/calculators/pension`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getDepositBalance(billId, date) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetDepositBalance`, { params: { id: billId, date } })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getDepositCloseCalendar(billId, date) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetDepositCloseCalendar`, { params: { id: billId, date } })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static closeDepositCalendar(billId, date) {
    return axios
      .get(`${API_ENDPOINT}/bill/ConfirmEarlyCloseDeposit`, { params: { id: billId, date } })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static closeRaisingCalendar(billId, date) {
    return axios
      .get(`${API_ENDPOINT}/bill/ConfirmEarlyCloseRaising`, { params: { id: billId, date } })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getRaisingCloseCalendar(billId, date) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetRaisingCloseCalendar?id=${billId}&date=${date}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getRaisingBalance(billId, date) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetRaisingBalance`, { params: { id: billId, date } })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getSalariesBalance(billId, date) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetSalaryBalance`, { params: { id: billId, date } })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getSalariesIncome(billId) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetSalaryCalendar?id=${billId}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getBillConditions(billCode) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetBillConditions/?code=${billCode}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getLoanCalculator(data) {
    return axios
      .get(`${API_ENDPOINT}/calculators/loans`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getBillDetails(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetDetails`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getLoanCalendar(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetLoanCalendar`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getRaisingCalendar(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetRaisingCalendar`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getDepositCalendar(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetDepositCalendar`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getPensionCalendar(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetPensionCalendar?id=${data.id}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getLoanEarlyClose(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetLoanEarlyClose`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getPayCalendar(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetPayCalendar`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static clientBaseFilter(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/ClientBaseFilter`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getLoanProducts(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetLoanProducts`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getLoanProductConditions(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetLoanProductConditions`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static createLoan(data) {
    return axios
      .post(`${API_ENDPOINT}/bill/CreateLoan`, data)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getDepositProducts(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetDepositProducts`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getRaisingProducts(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetRaisingProducts`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getDepositProductConditions(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetDepositProductConditions`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getRaisingProductConditions(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetRaisingProductConditions`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static createDeposit(data) {
    return axios
      .post(`${API_ENDPOINT}/bill/CreateDeposit`, data)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static createRaising(data) {
    return axios
      .post(`${API_ENDPOINT}/bill/CreateRaising`, data)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getCurrentBalance(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetCurrentBalance`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getPensionProducts(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetPensionProducts`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getPensionProductConditions(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetPensionProductConditions`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getPartnerChilds(idAspPartner) {
    return axios
      .get(`${API_ENDPOINT}/partners/${idAspPartner}/relatives`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static createPension(data) {
    return axios
      .post(`${API_ENDPOINT}/bill/CreatePension`, data)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getLoanCode(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetLoanCode`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getDepositCode(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetDepositCode`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getPensionCode(data) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetPensionCode`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getCurrentBills(query) {
    return axios
      .get(`${API_ENDPOINT}/bill/Currents${query ? `?${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getSalariesBills(query) {
    return axios
      .get(`${API_ENDPOINT}/bill/Salaries${query ? `?${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getDepositsBills(query) {
    return axios
      .get(`${API_ENDPOINT}/bill/Deposits${query ? `?${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getOperationsBills(query, BillCode) {
    return axios
      .get(`${API_ENDPOINT}/Operation/BillOperations?billCode=${BillCode}${query ? `&${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getLoansBills(query) {
    return axios
      .get(`${API_ENDPOINT}/bill/Loans${query ? `?${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getRaisingsBills(query) {
    return axios
      .get(`${API_ENDPOINT}/bill/Raisings${query ? `?${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getLoanAutoPayReports(query) {
    return axios
      .get(`${API_ENDPOINT}/Report/LoanAutoPayReports${query ? `?${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getLoanLates(query) {
    return axios
      .get(`${API_ENDPOINT}/Report/LoanLates${query ? `?${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getLoanCheckBalanceReports(query) {
    return axios
      .get(`${API_ENDPOINT}/Report/LoanCheckBalanceReports${query ? `?${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getTodayLoanCheckBalance() {
    return axios
      .get(`${API_ENDPOINT}/Report/TodayLoanCheckBalance`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getTodayLoanAutoPayLate() {
    return axios
      .get(`${API_ENDPOINT}/Report/TodayLoanAutoPayLate`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getReportsCalendar(data) {
    return axios
      .get(`${API_ENDPOINT}/reports/calendar`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getReportsCalendarDetails(data) {
    return axios
      .get(`${API_ENDPOINT}/reports/calendar/details`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getReportsCalendarMonthDetails(data) {
    return axios
      .get(`${API_ENDPOINT}/reports/calendar/month/details`, { params: data })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getReportsCalendarYearDetails(year) {
    return axios
      .get(`${API_ENDPOINT}/reports/calendar/${year}/details`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getConsolidatedDepositsReport(query, date) {
    return axios
      .get(`${API_ENDPOINT}/SalaryAnalytics/GetConsolidatedDepositsReport?ReportDate=${date}${query ? `&${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getConsolidatedDepositAnalyticsReport(query, date) {
    return axios
      .get(`${API_ENDPOINT}/DepositAnalytics/GetConsolidatedDepositsReport?ReportDate=${date}${query ? `&${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getConsolidatedLoanAnalyticsReport(query, date) {
    return axios
      .get(`${API_ENDPOINT}/LoanAnalytics/GetConsolidatedLoanReport?ReportDate=${date}${query ? `&${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getMonthPaymentAggregationReport(query, dateStart, dateEnd) {
    return axios
      .get(`${API_ENDPOINT}/SalaryAnalytics/GetMonthPaymentAggregationReport?startReportMonth=${dateStart}&endReportMonth=${dateEnd}${query ? `&${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getMonthPaymentAggregationDepositReport(query, dateStart, dateEnd) {
    return axios
      .get(`${API_ENDPOINT}/DepositAnalytics/GetMonthPaymentAggregationReport?startReportMonth=${dateStart}&endReportMonth=${dateEnd}${query ? `&${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getMonthPaymentAggregationLoanReport(query, dateStart, dateEnd) {
    return axios
      .get(`${API_ENDPOINT}/LoanAnalytics/GetMonthPaymentAggregationReport?startReportMonth=${dateStart}&endReportMonth=${dateEnd}${query ? `&${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getDepositsPaymentsReport(query, dateStart, dateEnd) {
    return axios
      .get(`${API_ENDPOINT}/SalaryAnalytics/GetDepositsPaymentsReport?reportStartDate=${dateStart}&reportEndDate=${dateEnd}${query ? `&${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getDepositsAnaliticsPaymentsReport(query, dateStart, dateEnd) {
    return axios
      .get(`${API_ENDPOINT}/DepositAnalytics/GetDepositsPaymentsReport?reportStartDate=${dateStart}&reportEndDate=${dateEnd}${query ? `&${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getConsolidateRaisingAnalyticsReport(query, date) {
    return axios
      .get(`${API_ENDPOINT}/RaisingsAnalytics/GetConsolidatedRaisingsReport?ReportDate=${date}${query ? `&${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getRaisingAnaliticsPaymentsReport(query, dateStart, dateEnd) {
    return axios
      .get(`${API_ENDPOINT}/RaisingsAnalytics/GetRaisingsPaymentsReport?reportStartDate=${dateStart}&reportEndDate=${dateEnd}${query ? `&${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getMonthPaymentAggregationRaisingReport(query, dateStart, dateEnd) {
    return axios
      .get(`${API_ENDPOINT}/RaisingsAnalytics/GetMonthPaymentAggregationReport?startReportMonth=${dateStart}&endReportMonth=${dateEnd}${query ? `&${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getLoanAnaliticsPaymentsReport(query, dateStart, dateEnd) {
    return axios
      .get(`${API_ENDPOINT}/LoanAnalytics/GetLoansPaymentsReport?reportStartDate=${dateStart}&reportEndDate=${dateEnd}${query ? `&${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static loginUser(data) {
    return axios
      .post(`${API_ENDPOINT}/auth/authorize`, data)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static logoutUser() {
    return axios
      .post(`${API_ENDPOINT}/auth/logout`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getUser() {
    return axios
      .get(`${API_ENDPOINT}/auth`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response));
  }

  static getBillForOperation(Id) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetBillForOperation?partnerId=${Id}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getAllBillForOperation(Id) {
    return axios
      .get(`${API_ENDPOINT}/bill/GetAllBillForOperation?partnerId=${Id}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getOperationsForBill(Id, direction) {
    return axios
      .get(`${API_ENDPOINT}/operations/get-operations-for-bill?billId=${Id}&direction=${direction}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getEmployees() {
    return axios
      .get(`${API_ENDPOINT}/User/GetEmployees`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static createOperation(data) {
    return axios
      .post(`${API_ENDPOINT}/operations/create-operation`, data)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getUsers(query) {
    return axios
      .get(`${API_ENDPOINT}/user${query ? `?${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getUsersLogs(query, id) {
    return axios
      .get(`${API_ENDPOINT}/Audit/GetUserLogs?id=${id}&${query ? `${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static deactivateUser(Id) {
    return axios
      .delete(`${API_ENDPOINT}/user/${Id}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static deleteProduct(Id) {
    return axios
      .get(`${API_ENDPOINT}/Product/Delete?id=${Id}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static addUser(data) {
    const { SecUserId } = data;
    const isUpdate = !!SecUserId;
    return axios[isUpdate ? 'patch' : 'post'](`${API_ENDPOINT}/user`, data)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getUserById(id) {
    return axios
      .get(`${API_ENDPOINT}/user/${id}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getGroups(query) {
    return axios
      .get(`${API_ENDPOINT}/group/GetAllGroups${query ? `?${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static deleteGroup(Id) {
    return axios
      .delete(`${API_ENDPOINT}/group/${Id}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getProducts(query) {
    return axios
      .get(`${API_ENDPOINT}/Product/GetAll${query ? `?${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getUserLogs() {
    return axios
      .get(`${API_ENDPOINT}/Audit/GetLastUsersLogs`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static printPkoOrRko(id) {
    return axios
      .get(`${API_ENDPOINT}/bill/PrintPkoOrRko?operationId=${id}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static downloadFile(url, docType) {
    return axios
      .get(`${API_ENDPOINT}${url}`, {
        responseType: 'blob',
      })
      .then((result) => {
        let filename;
        let contentType;
        let file;
        switch (docType) {
          case 'xml':
            file = new Blob([result.data], { type: 'text/xml' });
            filename = result.headers['x-filename'] || 'partner_list.xml';
            // file = new File([result.data], filename);
            saveBlob(file, filename);
            break;

          default:
            filename = result.headers['x-filename'] || 'sm-group-unknown-file.pdf';
            contentType = result.headers['content-type'] || 'application/octet-stream';
            file = new File([result.data], filename, { type: contentType });
            openBlob(file, filename);
            break;
        }
        return Promise.resolve();
      })
      .catch(fileErrorStrategy);
  }

  static downloadBlobFile(url) {
    return axios
      .get(url, {
        responseType: 'blob',
      });
  }

  static getCalculationsProductByType(type) {
    return axios
      .get(`${API_ENDPOINT}/calculators/products/${type}?timestamp=${new Date().getTime()}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static addCalculationsProduct(Id, type) {
    return axios
      .post(`${API_ENDPOINT}/calculators/products/${Id}/${type}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static removeCalculationsProduct(Id) {
    return axios
      .delete(`${API_ENDPOINT}/calculators/products/${Id}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getGroupControllerNames() {
    return axios
      .get(`${API_ENDPOINT}/group/ControllerNames`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getGroupActionNames(controllerName) {
    return axios
      .get(`${API_ENDPOINT}/group/ActionNames?controllerName=${controllerName}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static createOrUpdateGroup(data = {}) {
    const { GroupId } = data;
    return axios[GroupId ? 'patch' : 'post'](
      `${API_ENDPOINT}/group`,
      data,
    )
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getGroupById(id) {
    return axios
      .get(`${API_ENDPOINT}/group/GetGroup?id=${id}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getPaymentRequestByCurrency(currencyId) {
    return axios
      .get(`${API_ENDPOINT}/payments-requests/requests-by-currency?currency=${currencyId}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getCommonBillForOperation() {
    return axios
      .get(`${API_ENDPOINT}/bill/GetCommonBillForOperation`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static createGroupOperation(data) {
    return axios
      .post(`${API_ENDPOINT}/operations/create-group-operation`, data)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getRefinanceRate() {
    return axios
      .get(`${API_ENDPOINT}/tools/refinance`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static saveRefinanceRate(data) {
    return axios
      .put(`${API_ENDPOINT}/tools/refinance`, data)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getAccessSections() {
    return axios
      .get(`${API_ENDPOINT}/group/GetSections`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getMFPercent() {
    return axios
      .get(`${API_ENDPOINT}/tools/mf-percents`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static saveMFPercent(data) {
    const { Id } = data;
    const isUpdate = !!Id;
    return axios[isUpdate ? 'patch' : 'post'](
      `${API_ENDPOINT}/tools/mf-percents`,
      data,
    );
  }

  static getAllChats() {
    return axios.get(`${API_ENDPOINT}/xmpp/common-history`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getChatsMessages(chatId) {
    return axios.get(`${API_ENDPOINT}/xmpp/chat-history?chatId=${chatId}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getUnreadedChatsCount() {
    return axios
      .get(`${API_ENDPOINT}/xmpp/unreaded-chats-count?timestamp=${new Date().getTime()}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static readChats(chatId) {
    return axios.patch(`${API_ENDPOINT}/xmpp/set-chat-readed/${chatId}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static saveTokenWebPush(Token) {
    return axios.post(`${API_ENDPOINT}/web-push/save-token`, {
      Token,
    })
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static confirmToggleSalary(billId) {
    return axios.get(`${API_ENDPOINT}/bill/ConfirmToggleSalary?id=${billId}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }

  static getCalendarTableFilters(query) {
    return axios
      .get(`${API_ENDPOINT}/reports/calendar/table${query ? `?${query}` : ''}`)
      .then(result => result.data)
      .catch(({ response }) => Promise.reject(response.data));
  }
}

export default ApiClient;
