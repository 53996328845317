import { Reducer } from "redux";
import { PaymentRequestsState } from "./types";
import { ActionTypes } from "./actions";

const initialState: PaymentRequestsState = {
  loading: false,
  data: [],
  requestData: {
    CurrentPage: 1,
    FieldInfos: [
      { FieldName: "Id", Value: null, Method: 4, ValueType: 2 },
      { FieldName: "Date", Value: null, Method: 4, ValueType: 4 },
      { FieldName: "PartnerName", Value: null, Method: 1, ValueType: 1 },
      { FieldName: "BillCode", Value: null, Method: 1, ValueType: 1 },
      { FieldName: "Total", Value: null, Method: 4, ValueType: 7 },
      { FieldName: "CurrencyId", Value: null, Method: 0, ValueType: 5 },
      { FieldName: "Type", Value: null, Method: 0, ValueType: 5 },
      { FieldName: "Status", Value: null, Method: 0, ValueType: 5 },
      { FieldName: "Operator", Value: null, Method: 1, ValueType: 1 },
      { FieldName: "IsRequisites", Value: null, Method: 4, ValueType: 3 }
    ],
    IsPaging: true,
    Operator: 1,
    PageSize: 25,
    SortDirection: 2,
    SortFieldName: "Date",
    TotalPages: 1
  },
  countNonExecutedOperations: 0,
};

const reducer: Reducer<PaymentRequestsState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.FETCH_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.FETCH_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.DataSource,
        requestData: action.payload.FilterRequest
      };

    case ActionTypes.FETCH_LIST_ERROR:
      return {
        ...state,
        loading: false
      };

    case ActionTypes.CHANGE_LIST_REQUEST_DATA:
      return {
        ...state,
        requestData: {
          ...state.requestData,
          SortDirection: (() => {
            if (action.payload.hasOwnProperty("SortDirection")) {
              return action.payload.SortDirection;
            }

            if (action.payload.hasOwnProperty("SortFieldName")) {
              if (
                action.payload.SortFieldName === state.requestData.SortFieldName
              ) {
                return state.requestData.SortDirection === 1 ? 2 : 1;
              } else {
                return initialState.requestData.SortDirection;
              }
            }

            return state.requestData.SortDirection;
          })(),
          CurrentPage: initialState.requestData.CurrentPage,
          ...action.payload
        }
      };

    case ActionTypes.CHANGE_FILTERS:
      return {
        ...state,
        requestData: {
          ...state.requestData,
          CurrentPage: action.meta
            ? initialState.requestData.CurrentPage
            : state.requestData.CurrentPage,
          FieldInfos: state.requestData.FieldInfos.map(item => {
            if (item.FieldName === action.payload.FieldName) {
              return action.payload;
            }

            return item;
          })
        }
      };

    case ActionTypes.RESET_FILTER:
      return {
        ...state,
        requestData: {
          ...state.requestData,
          CurrentPage: initialState.requestData.CurrentPage,
          FieldInfos: state.requestData.FieldInfos.map((item, index) => {
            if (item.FieldName === action.meta) {
              return initialState.requestData.FieldInfos[index];
            }

            return item;
          })
        }
      };

    case ActionTypes.RESET_ALL_FILTERS:
      return {
        ...state,
        requestData: {
          ...state.requestData,
          FieldInfos: initialState.requestData.FieldInfos
        }
      };

    case ActionTypes.CHANGE_PAGE:
      return {
        ...state,
        requestData: {
          ...state.requestData,
          CurrentPage: action.payload
        }
      };

    case ActionTypes.CHANGE_PAGE_SIZE:
      return {
        ...state,
        requestData: {
          ...state.requestData,
          PageSize: action.payload
        }
      };

    case ActionTypes.EXEC_REQUEST:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.EXEC_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case ActionTypes.EXEC_ERROR:
      return {
        ...state,
        loading: false
      };

    case ActionTypes.GET_COUNT_NON_EXECUTED_SUCCESS:
      return {
        ...state,
        countNonExecutedOperations: action.payload,
      };

    case ActionTypes.GET_COUNT_NON_EXECUTED_ERROR:
      return {
        ...state,
        countNonExecutedOperations: 0,
      };

    default:
      return state;
  }
};

export { reducer as paymentRequestsReducer };
