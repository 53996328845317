import { connect } from 'react-redux';

import PayCalendar from './PayCalendar';
import { AppState } from "../../../../store";



const mapStateToProps = (state: AppState) => ({
  payCalendar: state.billDetails.payCalendar,
});

export default connect(mapStateToProps)(PayCalendar);
