import React from 'react';
import Helmet from "react-helmet";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { cloneDeep } from 'lodash';
import styled from "styled-components";
import moment from "moment";

import LoadingOverlay from "../../../components/LoadingOverlay";
import { Heading } from "../../../components/Typography";
import { AppState } from "../../../store";
import {
  getAllChangeRefinanceRequest, getAllChangeRefinanceSuccess,
  getRefinanceRateData,
  getRefinanceRateLoading, isSavedRefinanceRateLoading, saveAllChangeRefinanceRequest
} from "../../../store/otherActions";
import { ChangeRefinanceProps } from "../types";
import { Table, TableHead, TableRow, TableCellHead, TableCell, TableBody } from "../../../components/Table";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { SimpleInput } from "../../../components/FormControls/FormControls";
import Button from "../../../components/Button/Button";
import { convertToUtc, parseServerDate } from "../../../utils";
import { toast } from "react-toastify";
import { MESSAGES } from "../constants";
import BackLink from "../../../components/BackLink";

const Container = styled.header`
  margin-bottom: 20px;
  padding: 14px 15px 7px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #e7eaec;
`;

class ChangeRefinance extends React.Component<ChangeRefinanceProps> {
  public componentDidMount() {
    const { getAllChangeRefinanceRequest: getAllChangeRefinance } = this.props;
    getAllChangeRefinance();
  }

  public componentDidUpdate(prevProps: Readonly<ChangeRefinanceProps>) {
    if (!prevProps.isSaved && this.props.isSaved) {
      toast(MESSAGES.updateSuccess, {
        type: toast.TYPE.SUCCESS
      });
    }
  }

  public render() {
    const { loading, refinanceRateData } = this.props;
    return (
      <LoadingOverlay className="col-6" loading={loading}>
        <Helmet defer={false}>
          <title>Ставка рефинансирования</title>
        </Helmet>
        <Container>
          <BackLink title={document.title} >
            <Heading>Ставка рефинансирования</Heading>
          </BackLink>
          <Button onClick={this.addNewItem} type="success">
             Добавить
          </Button>
        </Container>
        <Table>
          <TableHead>
            <TableRow>
              <TableCellHead>Дата начала</TableCellHead>
              <TableCellHead>Значение</TableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {refinanceRateData.map((item, index) => (
              <TableRow key={`${index}`}>
                <TableCell>
                  <DatePicker useStyledInput onChange={this.handleChangeDate(index)} selected={parseServerDate(item.DateFrom)} />
                </TableCell>
                <TableCell>
                  <SimpleInput type={'number'} onChange={this.handleChange(index)} greenBorder error={!item.Rate} value={item.Rate} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button className="mt-3" onClick={this.saveData} type="success">
          Сохранить
        </Button>
      </LoadingOverlay>
    )
  }

  private handleChangeDate = (index: number) => (date: Date, event: any) => {
    if (date && (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value))) {
      const { refinanceRateData, getAllChangeRefinanceSuccess: updateData } = this.props;
      const data = cloneDeep(refinanceRateData);
      data[index].DateFrom = convertToUtc(date).toISOString();
      updateData(data);
    }
  };

  private handleChange = (index: number) => (e: any) => {
    const { target: { value } } = e;
    const { refinanceRateData, getAllChangeRefinanceSuccess: updateData } = this.props;
    const data = cloneDeep(refinanceRateData);
    data[index].Rate = value;
    updateData(data);
  };

  private addNewItem = () => {
    const { refinanceRateData, getAllChangeRefinanceSuccess: updateData } = this.props;
    const newItem = {
      DateFrom: moment().startOf('day').toISOString(),
      Rate: 0,
    };
    updateData([...refinanceRateData, newItem]);
  };

  private saveData = () => {
    const { refinanceRateData, saveAllChangeRefinanceRequest: saveRefinanceData } = this.props;
    saveRefinanceData(refinanceRateData);
  }
}

const mapStateToProps = (state: AppState) => ({
  loading: getRefinanceRateLoading(state),
  refinanceRateData: getRefinanceRateData(state),
  isSaved: isSavedRefinanceRateLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAllChangeRefinanceRequest,
      getAllChangeRefinanceSuccess,
      saveAllChangeRefinanceRequest,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeRefinance);
