import React from 'react';
import Helmet from "react-helmet";

import SectionHeader from "../../../../components/SectionHeader";
import ChatListBlock from "../../components/ChatListBlock";
import MessageListBlock from "../../components/MessageListBlock";
import styled from "styled-components";
import SendMessegeBlock from "../../components/SendMessegeBlock";


const PAGE_TITLE = "Сообщения";

const ChatWrapper = styled.div`
  height: calc(100vh - 264px);
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(0,0,0,0.5);
`;

const ChatItems = styled.div`
  border-right: 1px solid #e3e3e3;
`;

export default class ChatPage extends React.Component{
  public render() {
    return (
      <div>
        <Helmet>
          <title>{PAGE_TITLE}</title>
        </Helmet>
        <SectionHeader heading={PAGE_TITLE}/>
          <ChatWrapper className="row no-gutters">
            <ChatItems className="col-4 no-gutters">
              <ChatListBlock />
            </ChatItems>
            <div className="col-8">
              <MessageListBlock />
              <SendMessegeBlock />
            </div>
          </ChatWrapper>
      </div>
    )
  }
}
