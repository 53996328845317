import React, { PureComponent } from 'react';
import { get } from 'lodash';
import { ifProp } from 'styled-tools';
import { RouteComponentProps } from "react-router";


import Clickable from "../../../../components/Clickable";
import styled, { css } from "../../../../styled/styled-components";
const Link = styled(Clickable)`
  color: black;
  border: 1px solid rgb(83, 176, 149);
  padding: 10px 50px;
  font-size: 18px;
    ${ifProp('active', css`
      color: white;
      background-color: rgb(83, 176, 149);
  `)}
`;

const LeftLink = styled(Link)`
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 10px 83px;
`;

const RightLink = styled(Link)`
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
`;

const Central = styled.div`
  margin: 0 auto;
`;

export interface SwitcherProps extends RouteComponentProps{
  active: string;
}

export default class Switcher extends PureComponent<SwitcherProps> {
  public render() {
    const { active } = this.props;
    const partnerNumber = get(this.props, 'match.params.partnerNumber');
    return (
      <Central>
        <LeftLink routerLink active={active === 'loan'} href={partnerNumber ? `/Loan/CreateLoan/${partnerNumber}` : '/Loan/CreateLoan'}>
          Займ
        </LeftLink>
        <Link routerLink active={active === 'deposit'} href={partnerNumber ? `/Deposit/CreateDeposit/${partnerNumber}` : `/Deposit/CreateDeposit`}>
          Размещение
        </Link>
        <RightLink routerLink active={active === 'rising'} href={partnerNumber ? `/Raising/CreateRising/${partnerNumber}` : `/Raising/CreateRising`}>
          Инвестиционный займ
        </RightLink>
      </Central>
    );
  }
}
