import * as React from "react";
import styled from "../../styled/styled-components";

export interface TypeInterface {
  type: PanelType;
}

const RootView: React.SFC<
  React.HTMLAttributes<HTMLDivElement> & TypeInterface
> = ({ type, ...props }) => <div {...props} />;

const Root = styled(RootView)`
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid;
  border-color: ${({ theme, type = "default" as PanelType }) => {
    switch (type) {
      case "primary":
        return theme.colors.primaryColor;

      case "default":
      default:
        return theme.colors.lightGray;
    }
  }};
  border-radius: 4px;
`;

const HeaderView: React.SFC<
  React.HTMLAttributes<HTMLDivElement> & TypeInterface
> = ({ type, ...props }) => <div {...props} />;

const Header = styled(HeaderView)`
  background: ${({ theme, type = "default" as PanelType }) => {
    switch (type) {
      case "primary":
        return theme.colors.primaryColor;

      case "default":
      default:
        return theme.colors.lightGray;
    }
  }};
  padding: 10px 15px;
  color: ${({ theme, type = "default" as PanelType }) => {
    switch (type) {
      case "primary":
        return theme.colors.white;
    }
  }};
  min-height: 40px;
`;

const Content = styled.div`
  padding: 20px;
`;

export type PanelType = "default" | "primary";

export interface PanelProps extends Partial<TypeInterface> {
  heading?: React.ReactNode;
}

export default class Panel extends React.Component<PanelProps> {
  public static defaultProps = {
    type: "default"
  };

  public render() {
    return (
      <Root type={this.props.type || "default"}>
        <Header type={this.props.type || "default"}>
          {this.props.heading}
        </Header>
        <Content>{this.props.children}</Content>
      </Root>
    );
  }
}
