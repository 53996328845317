import {
  PartnerTabType,
  Passport,
  InternationalPassport,
  Diploma,
  Authority,
  MigrationOrResidanceCard,
  PersonalDocuments,
  PartnerAddress,
  AgentPersonalData,
  PlaceOfBirth,
  UserData,
  PartnerListItem,
  SortDirection,
  RelativePartner,
  Bill,
  PartnerQuestionnaireState,
} from "../types";
import MainTab from "../components/Tabs/MainTab";
import AddressTab from "../components/Tabs/AddressTab";
import DocumentsTab from "../components/Tabs/DocumentsTab";
import AgentFirstTab from "../components/Tabs/AgentFirstTab";
import AgentSecondTab from "../components/Tabs/AgentSecondTab";
import RelativesTab from "../components/Tabs/RelativesTab";
import BillsTab from "../components/Tabs/BillsTab";
import QuestionaryTab from "../components/Tabs/QuestionaryTab";
import LoanFormTab from "../components/Tabs/LoanFormTab";
import {
  FilterFieldName,
  FilterItemData
} from "../../../types/PartnersAndClients";
import CardsTab from '../components/Tabs/CardsTab';
import { LoanForm } from "../../Clients/types";

export const PARTNER_TABS_LABELS = new Map<
  PartnerTabType,
  {
    label: string;
    component: React.ComponentType;
  }
>([
  ["main", { label: "Общее", component: MainTab }],
  ["address", { label: "Адреса", component: AddressTab }],
  ["documents", { label: "Документы", component: DocumentsTab }],
  ["bills", { label: "Счета", component: BillsTab }],
  ["confidant1", { label: "Доверенное лицо(1)", component: AgentFirstTab }],
  ["confidant2", { label: "Доверенное лицо(2)", component: AgentSecondTab }],
  ["relatives", { label: "Родственники/близкие", component: RelativesTab }],
  ["cards", { label: "Счета и карты клиента", component: CardsTab }],
  ["questionnaire", { label: "Опросник", component: QuestionaryTab }],
  ["loanForm", { label: "Анкета на займ", component: LoanFormTab }],
]);

export const PASSPORT_FIELDS = new Map<keyof Passport, string>([
  ["Series", "Серия"],
  ["Number", "Номер"],
  ["DateOfIssue", "Дата выдачи"],
  ["IssuedBy", "Кем выдан"],
  ["DivisionCode", "Код подразделения"]
]);

export const INTERNATIONAL_PASSPORT_FIELDS = new Map<
  keyof InternationalPassport,
  string
>([
  ["Number", "Номер"],
  ["IssuedDate", "Дата выдачи"],
  ["ExpirationDate", "Дата окончания"]
]);

export const SNILS_FIELDS = new Map<keyof PersonalDocuments, string>([["Snils", "СНИЛС"], ["Inn", "ИHH"]]);

export const PLACE_OF_BIRTH_FIELDS = new Map<keyof PlaceOfBirth, string>([
  ["PlaceOfBirth", "Место рождения"]
]);

export const DIPLOMA_FIELDS = new Map<keyof Diploma, string>([
  ["Type", "Тип документа"],
  ["Number", "Номер"],
  ["IssuedBy", "Кем выдан"],
  ["DateObtain", "Дата выдачи"],
  ["DateClose", "Дата окончания"]
]);

export const AUTHORITY_FIELDS = new Map<keyof Authority, string>([
  ["Name", "Наименование"],
  ["Number", "Номер"],
  ["DateCreate", "Дата"]
]);

export const MIGRATION_FIELDS = new Map<keyof MigrationOrResidanceCard, string>(
  [
    ["Series", "Серия"],
    ["Number", "Номер"],
    ["DateStart", "Дата начала срока пребывания"],
    ["DateEnd", "Дата окончания срока пребывания"]
  ]
);

export const ADDRESS_FIELDS = new Map<keyof PartnerAddress, string>([
  ["CountryId", "Страна"],
  ["RegionId", "Регион/область"],
  ["CityId", "Город"],
  ["StreetName", "Улица"],
  ["HouseNumber", "Номер дома"],
  ["HousingNumber", "Строение"],
  ["ApartmentNumber", "Квартира"],
  ["IndexNumber", "Индекс"]
]);

export const AGENT_PERSONAL_DATA_FIELDS = new Map<
  keyof AgentPersonalData,
  string
>([
  ["LastName", "Фамилия"],
  ["FirstName", "Имя"],
  ["Patronymic", "Отчество"],
  ["Phone1", "Телефон основной"],
  ["Phone2", "Телефон дополнительный"],
  ["Email", "Адрес электронной почты"],
  ["RelationId", "Степень родства"],
  ["NationalityId", "Гражданство"],
  ["PowerOfAttorneyStartDate", "Дата выдачи доверенности"],
  ["PowerOfAttorneyEndDate", "Дата окончания доверенности"]
]);

export const PARTNER_FIELD_LABELS = new Map<keyof PartnerListItem, string>([
  ["FullName", "ФИО"],
  ["PrivilegeLevel", "Статус пайщика"],
  ["PartnerNumber", "Номер пайщика"],
  ["Phone", "Номер телефона"],
  ["Mail", "E-mail"],
  ["Birthday", "Дата рождения"],
  ["PassportNumber", "Номер паспорта"],
  ["InternationalPassportNumber", "Номер загранпаспорта"],
  ["Gender", "Пол"],
  ["IsActive", "Статус"],
  ["ClientType", "Тип клиента"],
  ["AgentOneFullName", "Доверенное лицо 1 (ФИО)"],
  ["PowerOfAttorneyStartDate", "Дата начала доверенности"],
  ["PowerOfAttorneyEndDate", "Дата окончания доверенности"]
]);

export const MAIN_FORM_FIELDS = [
  new Map<keyof UserData, string>([
    ["PartnerId", "Номер пайщика"],
    ["RegDate", "Дата регистрации"],
    ["LastName", "Фамилия"],
    ["FirstName", "Имя"],
    ["Patronymic", "Отчество"],
    ["LastNameEn", "Фамилия (лат.)"],
    ["FirstNameEn", "Имя (лат.)"],
    ["LastNameGenetive", "Фамилия в р.п."],
    ["FirstNameGenetive", "Имя в р.п."],
    ["PatronymicGenetive", "Отчество в р.п."],
    ["Gender", "Пол"],
    ["PartnerTypeId", "Тип пайщика"],
    ["BirthDate", "День рождения"],
    ["PlaceOfBirth", "Место рождения"],
    ["NationalityId", "Гражданство (подданство)"]
  ]),
  new Map<keyof UserData, string>([
    ["PhoneNumber", "Мобильный телефон (основной)"],
    ["PhoneMobileAdd", "Мобильный телефон (резервный)"],
    ["PhoneHome", "Домашний телефон"],
    ["Email", "Адрес электронной почты"],
    ["Job", "Место работы"],
    ["JobPosition", "Должность"],
    ["IsActive", "Статус"],
    ["SecretCode", "Секретное слово"],
    [
      "ResponsableStaff",
      "Сотрудник Кооператива, ответственный за работу с Пайщиком"
    ],
    ["FillInStaff", "Сотрудник Кооператива, заполнивший анкету"],
    ["Comment", "Комментарий"]
  ])
];

export const QUESTINARY_FORM_FIELDS = [
  new Map<keyof PartnerQuestionnaireState, string>([
    ["MaritalStatus", "Семейное положение или личный статус"],
    ["HaveChildrens", "Наличие детей/внуков"],
    ["ChildrensAge", "Возраст каждого ребенка"],
    ["LivingPlace", "Место проживания"],
    ["SeaCareer", "Род деятельности на судне"],
    ["LandCareer", "Род деятельности на берегу"],
    ["Hobby", "Увлечение, хобби на берегу"],
    ["Messengers", "Какими месседжерами пользуется чаще всего"],
    ["SocialNetworks", "Какими соц сетями пользуется чаще всего"],
    ["MFondProducts", "Какими услугами пользовался в Морском Фонде"],
    ["Feedback", "Как узнали о Морском Фонде"],
  ])
];

export const READONLY_FIELDS = ["PartnerId", "RegDate", "MFondProducts"];

export const REQUIRED_FIELDS = new Map([
  ["Passport", ["Series", "Number", "DateOfIssue", "IssuedBy", "DivisionCode"]],
  [
    "UserData",
    [
      "RegDate",
      "LastName",
      "FirstName",
      "BirthDate",
      "PhoneNumber",
      "Email",
      "PlaceOfBirth"
    ]
  ],
  ["PartnerAddress", ["HouseNumber", "StreetName"]]
]);

export const PARTNER_TYPE_LABELS = new Map([
  [1, "Пайщик"],
  [2, "Выгодоприобретатель"],
  [3, "Бенефициарный представитель"],
  [4, "Представитель пайщика"]
]);

export const RELATION_LABELS = new Map([
  [1, "Сын"],
  [2, "Дочь"],
  [3, "Супруг"],
  [4, "Супруга"],
  [5, "Родственник"],
  [6, "Друг"],
  [7, "Знакомый"],
  [8, "Внук"],
  [9, "Внучка"],
  [10, "Брат"],
  [11, "Сестра"],
  [12, "Мать"],
  [13, "Отец"],
  [14, "Бабушка"],
  [15, "Дедушка"],
  [16, "Гражданский брак"]
]);

export const DIPLOMA_TYPES_LABELS = new Map([
  [1, "УЛМ"],
  [2, "Диплом"],
  [3, "Водительское удостоверение"]
]);

export const SECURITY_SERVICE_STATUS = new Map([
  [0, "Не проводилась проверка"],
  [1, "Проверка пройдена"],
  [2, "Проверка не пройдена"]
]);

export const FEE_PAID_STATUS = new Map([
  [0, "Не оплачено"],
  [1, "Оплачено"],
]);

export const OPERATOR_LABELS = new Map([[1, "И"], [2, "ИЛИ"], [3, "НЕ"]]);

export const DEFAULT_SEARCH_TYPE = 1;

export const DEFAULT_FILTER_FIELDS = new Map<
  FilterFieldName<PartnerListItem>,
  FilterItemData<PartnerListItem>
>([
  [
    "FullName",
    {
      FieldName: "FullName",
      Method: 1,
      Value: null,
      ValueType: 1
    }
  ],
  [
    "PrivilegeLevel",
    {
      FieldName: "PrivilegeLevel",
      Method: 0,
      Value: null,
      ValueType: 5
    }
  ],
  [
    "PartnerNumber",
    {
      FieldName: "PartnerNumber",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "Phone",
    {
      FieldName: "Phone",
      Value: null,
      Method: 10,
      ValueType: 1
    }
  ],
  [
    "Mail",
    {
      FieldName: "Mail",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "Birthday",
    {
      FieldName: "Birthday",
      Value: null,
      Method: 1,
      ValueType: 4
    }
  ],
  [
    "PassportNumber",
    {
      FieldName: "PassportNumber",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "InternationalPassportNumber",
    {
      FieldName: "InternationalPassportNumber",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "AgentOneFullName",
    {
      FieldName: "AgentOneFullName",
      Value: null,
      Method: 1,
      ValueType: 1,
      NotDefaultVisible: true,
    }
  ],
  [
    "PowerOfAttorneyStartDate",
    {
      FieldName: "PowerOfAttorneyStartDate",
      Value: null,
      Method: 1,
      ValueType: 4,
      NotDefaultVisible: true,
    }
  ],
  [
    "PowerOfAttorneyEndDate",
    {
      FieldName: "PowerOfAttorneyEndDate",
      Value: null,
      Method: 1,
      ValueType: 4,
      NotDefaultVisible: true,
    }
  ],
  [
    "ClientType",
    {
      FieldName: "ClientType",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  [
    "Gender",
    {
      FieldName: "Gender",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  [
    "IsActive",
    {
      FieldName: "IsActive",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
]);

export const DEFAULT_SORT_FIELD_NAME: FilterFieldName<PartnerListItem> =
  "FullName";

export const DEFAULT_SORT_DIRECTION: SortDirection = 1;

export const RELATIVES_PARTNERS_FIELDS = new Map<keyof RelativePartner, string>(
  [
    ["FullName", "ФИО"],
    ["RelationTypeId", "Степень родства"],
    ["Phone", "Телефон"],
    ["BirthDate", "Дата рождения"],
    ["Age", "Возраст"],
    ["Document", "Документ"],
    ["Comment", "Комментарий"],
  ]
);

export const BILL_FIELDS = new Map<keyof Bill, string>([
  ["BillCode", "Номер счета"],
  ["BillTypeId", "Тип"],
  ["CurrencyId", "Валюта"],
  ["OpeningSum", "Сумма открытия"],
  ["CurrentBalance", "Баланс"],
  ["OpeningDate", "Дата открытия"],
  ["ClosingDate", "Дата закрытия"],
  ["IsActive", "Статус"]
]);

export const BILL_TYPES = new Map([
  [5, "Cчета зарплатных программ"],
  [4, "Счета займов"],
  [8, "Счета исполнения заявок"],
  [2, "Счета паенакоплений"],
  [9, "Счета пенсионных/детских накоплений"],
  [7, "Счета привлечения средств"],
  [3, "Счета сберегательных программ"],
  [1, "Текущие счета"]
]);

export const AUTOCOMPLETE_FILTER_FIELDS = [
  "FullName",
  "PartnerNumber",
  "Phone",
  "Mail"
];

export const MESSAGES = {
  createdSuccess: "Пайщик успешно создан",
  updateSuccess: "Данные пайщика обновлены"
};

export const LOAN_FORM_CLIENT_FIELDS = new Map<keyof LoanForm, string>([
  ["ClientFullName", "ФИО"],
  ["PhoneNumberHome", "Домашний телефон"],
  ["PhoneNumberPerson", "Мобильный телефон"],
  ["Job", "Место работы"],
  ["JobSchedule", "График работы"],
  ["JobPosition", "Должность, тип судов"],
  ["RevenueInMonth", "Доход в месяц"],
  ["RelativePersonPhoneNumber", "Контактные номера родственников"]
]);

export const LOAN_FORM_REASON_FIELDS = new Map<keyof LoanForm, string>([
  ["Reason", "Зачем нужны деньги"],
  // ["ReasonAdv", "Зачем нужны деньги"],
  ["EarlyLoans", "Есть ли еще кредиты, если да, то какие?"],
  ["OverdueLoans", "Есть / были просторчки?"]
]);

export const LOAN_FORM_CREDIT_FIELDS = new Map<keyof LoanForm, string>([
  ["Sum", "Сумма"],
  ["CurrencyId", "Валюта кредита"],
  ["LoanDate", "Срок кредита (лет / месяцев)"]
]);

export const LOAN_FORM_COMMENTS_FIELDS = new Map<keyof LoanForm, string>([
  ["Comment", "Комментарий"]
]);
