import React, { Component } from 'react';

import './CompareFilter.scss';

export default class CompareFilter extends Component {
  render() {
    const { compare, onChange } = this.props;
    return (
      <div className="compare-filter">
        <input
          className="compare-filter__input"
          type="checkbox"
          name="compare"
          id="compare"
          checked={compare}
          onChange={onChange}
        />
        <label className="compare-filter__label" htmlFor="compare">Сравнить с аналогичным периодом</label>
      </div>
    );
  }
}
