import React, { Component } from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import moment from 'moment';
import CurrencyFilter from '../../../../components/CurrencyFilter/CurrencyFilter';
import PeriodFilter from '../../../../components/PeriodFilter/PeriodFilter';
import CompareFilter from '../../../../components/CompareFilter/CompareFilter';
import {
  getCurrencyCode, getPeriodType,
  getTickInterval,
  handleChangeCompare,
  handleChangeCurrency,
  handleChangePeriod,
} from '../../../../helpers/chartHelpers';
import Chart from '../../../../components/Chart/Chart';
import CurrencySums from '../../conponents/CurrencySums';
import factory from '../../../../domain/analytics';
import chartDataBuilder from '../../../../domain/analytics/chartDataBuilder';
import PieChart from '../../../../components/Chart/PieChartOld';
import AnalyticsApiClient from '../../../../modules/Analytics/services/api';

const sumsExtractor = gender => ({ Genders = [] }) => {
  const obj = Genders.find(item => item.Gender === gender);
  if (obj) {
    return obj.Value;
  }
  return 0;
};

const getGenderPieData = (data) => {
  const genderPieData = [];

  if (data.length > 0) {
    const genderCounts = data.reduce((result, current) => {
      const counts = current.Sums.reduce((r, c) => {
        const maleInfo = c.Genders.find(i => i.Gender === 'Male');
        const maleCount = maleInfo ? maleInfo.Value : 0;

        const femaleInfo = c.Genders.find(i => i.Gender === 'Female');
        const femaleCount = femaleInfo ? femaleInfo.Value : 0;

        return { m: r.m + maleCount, f: r.f + femaleCount };
      }, { m: 0, f: 0 });

      return { m: result.m + counts.m, f: result.f + counts.f };
    }, { m: 0, f: 0 });

    if (genderCounts.m > 0) {
      genderPieData.push({ name: 'Мужчины', y: genderCounts.m });
    }
    if (genderCounts.f > 0) {
      genderPieData.push({ name: 'Женщины', y: genderCounts.f });
    }
  }

  return genderPieData;
};

export default class Gender extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {
        currency: 'USD',
        period: 'year',
        dateFrom: moment().startOf('month').add(1, 'month').subtract(1, 'y').format('YYYY-MM-DD'),
        dateTo: moment().endOf('month').format('YYYY-MM-DD'),
        compare: false,
      },
      chartData: [],
      data: [],
    };

    this.handleChangeCompare = handleChangeCompare.bind(this);
    this.handleChangeCurrency = handleChangeCurrency.bind(this);
    this.handleChangePeriod = handleChangePeriod.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const {
      currency,
      period,
      dateFrom,
      dateTo,
      compare,
    } = this.state.filters;

    const params = {
      currency: getCurrencyCode(currency),
      periodType: getPeriodType(period, dateFrom, dateTo),
      dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
      dateTo: moment(dateTo).format('YYYY-MM-DD'),
      compare: period !== 'year' && period !== 'currentYear' ? null : compare,
    };
    const rawData = await AnalyticsApiClient.getLoansGender(params);
    const data = factory(rawData);

    const builder = chartDataBuilder()
      .setTitle('Динамика')
      .setData(data)
      .setFilters(this.state.filters);

    this.setState({ chartData: builder.getCurves(), data });
  }

  render() {
    const {
      filters: {
        currency,
        period,
        dateFrom,
        dateTo,
        compare,
      },
      chartData,
      data = [],
    } = this.state;

    return (
      <Container>
        <Header>
          <CurrencyFilter active={currency} onChange={this.handleChangeCurrency} />
          <PeriodFilter
            activePeriod={period}
            dateFrom={dateFrom}
            dateTo={dateTo}
            onChange={this.handleChangePeriod}
          />
          {period !== 'year' && period !== 'currentYear'
            ? null
            : <CompareFilter compare={compare} onChange={this.handleChangeCompare} />}
        </Header>
        <Chart
          series={chartData}
          minTickInterval={getTickInterval(period)}
          title="Половозрастная характеристика"
          xLabel="Период"
          yLabel="Количество"
          xDateFormat={period !== 'month' && period !== 'currentMonth' ? '%B' : '%d %B'}
        />
        <PieChart
          title="Распределение вкладов по полу"
          data={getGenderPieData(data)}
        />
        <CurrencySums
          data={data}
          field="Sums"
          title="Общее количество мужчин"
          valueExtractor={sumsExtractor('Male')}
        />
        <CurrencySums
          data={data}
          field="Sums"
          title="Общее количество женщин"
          valueExtractor={sumsExtractor('Female')}
        />
      </Container>
    );
  }
}

const Container = styled(Flex).attrs({ column: true, full: true })``;
const Header = styled(Flex).attrs({ column: true })``;
