/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';

import './Pagination.scss';

export default class Pagination extends Component {
  render() {
    const {
      page, size, count, callback,
    } = this.props;

    if (count < 1 || size < 1) {
      return null;
    }

    const pagesCount = Math.ceil(count / size);

    const startArrow = (
      <li onClick={() => callback(1)}>
        <a>{'<<'}</a>
      </li>
    );
    const prevPrevPage = (
      <li onClick={() => callback(page - 2)}>
        <a>{page - 2}</a>
      </li>
    );
    const prevPage = (
      <li onClick={() => callback(page - 1)}>
        <a>{page - 1}</a>
      </li>
    );
    const current = <li className="active">{page}</li>;
    const nextPage = (
      <li onClick={() => callback(page + 1)}>
        <a>{page + 1}</a>
      </li>
    );
    const nextNextPage = (
      <li onClick={() => callback(page + 2)}>
        <a>{page + 2}</a>
      </li>
    );
    const endArrow = (
      <li onClick={() => callback(pagesCount)}>
        <a>{'>>'}</a>
      </li>
    );

    return (
      <ul className="pages">
        {page > 2 && pagesCount > 3 ? startArrow : null}
        {page === pagesCount && pagesCount > 2 ? prevPrevPage : null}
        {page > 1 ? prevPage : null}
        {current}
        {page < pagesCount ? nextPage : null}
        {page === 1 && pagesCount > 2 ? nextNextPage : null}
        {page < pagesCount - 1 && pagesCount > 3 ? endArrow : null}
      </ul>
    );
  }
}
