import React from 'react';
// @ts-ignore
import wssClient from "../../../../services/wss";
import { UserData } from "../../../../store/auth";
import { Message } from "../../../../store/chat";
import moment from "moment";
import { Button, Input } from 'antd';
import styled from "styled-components";

interface SendMessegeBlockProps {
  activeChatId: number,
  activeChat?: Message,
  reciverId?: number,
  sender?: UserData,
  addChatMessage: (data: Message) => void,
  readChatMessages: (chatId: number) => void,
}

interface SendMessegeBlockState {
  message: string,
}

const Wrapper = styled.div`
  background-color: rgb(241, 244, 250);
  display: flex;
  align-items: center;
  height: 101px;
`;

const TextAreaWrapper = styled.div`
  margin: 10px;
  width: 85%;
`;

const TextArea = styled(Input.TextArea)`
  resize: none !important;

`;

const ButtonWrapper = styled.div`
`;

const StyledButton = styled(Button)`
  border: none !important;
  background-color: rgb(241, 244, 250) !important;
  font-size: 20px !important;
`;

export default class SendMessegeBlock extends React.Component<SendMessegeBlockProps>{
  public state: SendMessegeBlockState = {
    message: '',
  };
  private ws?: WebSocket = wssClient.getWebSocket();

  public componentDidMount() {
    if (this.ws) {
      this.ws.onopen = () => {
        wssClient.authenticateChat();
      };

      this.ws.onmessage = (e) => {
        const data = JSON.parse(e.data);
        if (data.Method === 'Receive') {
          const { addChatMessage } = this.props;

          addChatMessage({
            Id: 0,
            IsMassive: false,
            ChatName: data.FromUserName,
            UserToName: '',
            ChatId: data.ChatId ? data.ChatId : 1,
            UserFromId: data.FromUser,
            UserFromName: data.FromUserName,
            UserToId: data.ToUser,
            Date: data.DateTime,
            Message: data.Message,
            Type: !data.ChatId || !data.FromUser ? 2 : 1,
            IsReaded: false,
          });
        }

        if (data.Status === 'Error') {
          wssClient.authenticateChat();
        }

      };

      this.ws.onclose = () => {
        this.ws = wssClient.connect();
      }
    }
  }

  public componentWillUnmount(): void {
    wssClient.closeWs();
    this.ws = undefined;
  }

  public render() {
    const { sender, reciverId } = this.props;

    // 1 - chatId для системы
    if (!sender || !reciverId || reciverId === 1) {

      return null;
    }
    return (
      <Wrapper>
        <TextAreaWrapper>
          <TextArea
            value={this.state.message}
            onFocus={this.onFocus}
            onChange={this.onChange}
            autoSize={false}
            onPressEnter={this.onSend}
          />
        </TextAreaWrapper>
        <ButtonWrapper>
          <StyledButton onClick={this.onSend}><i className="fa fa-paper-plane-o" /></StyledButton>
        </ButtonWrapper>
      </Wrapper>
    );
  }

  private onChange = ({ target: { value } }: any) => {
    this.setState({ message: value });
  };

  private onFocus = () => {
    const { readChatMessages, activeChatId } = this.props;
    readChatMessages(activeChatId);
  };

  private onSend = () => {
    const { activeChatId, addChatMessage, sender, reciverId, activeChat } = this.props;
    const { message } = this.state;

    if (!sender || !activeChatId || !reciverId || !message) {

      return;
    }

    wssClient.sendMessage(message, activeChatId);
    addChatMessage({
      Id: 0,
      IsMassive: false,
      UserToName: activeChat ? activeChat.ChatName : '',
      ChatName: activeChat ? activeChat.ChatName : '',
      ChatId: activeChatId,
      UserFromId: sender.Id,
      UserFromName: sender.FullName,
      UserToId: reciverId,
      Date: moment().format("YYYY-MM-DDTHH:mm:ss"),
      Message: message || '',
      Type: 1,
      IsReaded: true,
    });

    this.setState({
      message: '',
    })
  }
}
