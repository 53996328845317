import * as React from "react";
import Helmet from "react-helmet";
import { isEmpty, get } from 'lodash';

import LoadingOverlay from "../../../../components/LoadingOverlay";
import SectionHeader from "../../../../components/SectionHeader";
import CalculatorForm from "../../components/CalculatorForm";
import TableRaisings from "../../components/TableRaisings";
import { CalculatorsState } from "../../../../store/calculators";

import './print.scss';

const PAGE_TITLE = "Калькулятор инвестиционного займа";

export interface CalculatorProps {
  fetchCalculatorDataClear: () => void;
}

export default class RaisingsCalculator extends React.PureComponent<CalculatorsState & CalculatorProps> {
  public render() {
    const { loading, calculatingData } = this.props;
    return (
      <div>
        <Helmet defer={false}>
          <title>{PAGE_TITLE}</title>
        </Helmet>
        <SectionHeader heading={PAGE_TITLE}/>
        <LoadingOverlay loading={loading}>
          <div className="row">
            <div className="col-lg-5 mb-4">
              <CalculatorForm type="raising"/>
            </div>
            <div className="col-lg-7">
              {!isEmpty(calculatingData) && <TableRaisings EffectiveRate={get(calculatingData, 'EffectiveRate', 0)} OpeningSum={get(calculatingData, 'OpeningSum', 0)} PlanCalendar={get(calculatingData, 'PlanCalendar', {
                Percent: 0,
                Member: 0,
                Pit: 0,
                Payment: 0,
                Periods: [],
              })} />}
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }

  public componentWillUnmount() {
    const { fetchCalculatorDataClear } = this.props;
    fetchCalculatorDataClear();
  }
}
