import * as React from "react";
import { get, isEmpty, isArray, set, toNumber, isNaN, omit } from "lodash";
import moment from "moment";
import { ifProp } from "styled-tools";

import FormControl from "../FormControl";
import {
  LoanFormsState,
  Partner, PartnersSearch,
  LoanProducts, LoanProduct
} from "../../../../store/forms";
import { convertToUtc } from "../../../../utils";
import Button from "../../../../components/Button/Button";
import ErrorMessage from "../../../../modules/Errors/components/ErrorMessage";
import styled, { css } from "../../../../styled/styled-components";

import currencyCodes, { currencyTypes } from "../../../../constants/currencyCodes";
import ApiClient from "../../../../services/api";
import { toast } from 'react-toastify';
import { NONE_VALUE } from "../../../../constants";
import Modal from "../../../../components/Modal";

export interface LoanFormState {
  IdAspPartner?: number;
  IdPartner?: string;
  Famale?: string;
  Name?: string;
  Patronymic?: string;
  OpeningDate?: string;
  CurrencyId?: number;
  Sum?: number;
  RisingDay?: string;
  NdflCount?: boolean;
  InformPartner?: boolean;
  ProductCondId?: number;
  ProductId?: number;
  IsMain?: boolean;
  Percent?: number;
  MemberPercent?: number;
  FuncPayName?: string;
  Products: any;
  Fine?: number;
  loading: boolean;
  showModal: boolean;
  valueTerm: number;
  formErrors: {
    IdAspPartner: string;
    IsMain: string;
    ProductCondId: string;
    IdPartner: string;
    Famale: string;
    Name: string;
    Patronymic: string;
    OpeningDate: string;
    CurrencyId: string;
    Sum: string;
    RisingDay: string;
    InformPartner: string;
    ProductId: string;
    Percent: string;
    MemberPercent: string;
    FuncPayName: string;
    Fine?: string;
  },
  formValid: boolean;
  errorMessage: string;
  formLoading: boolean;
}

export interface LoanFormProps {
  partnerNumber?: string;
  searchPartnersRequest: (data: Partner) => void;
  goToList: (path: string, state: any) => void;
  search: PartnersSearch;
  loanForm: LoanFormsState;
}

interface WrapperProps {
  loading?: boolean;
}

const LoadingWrapper = styled.div<WrapperProps>`
    ${ifProp("loading", css`
      background-color: #eee;
  `)}
`;

export default class RaisingForm extends React.PureComponent<LoanFormProps> {
  public state:  LoanFormState = {
    Products: [],
    IdAspPartner: undefined,
    IsMain: false,
    ProductCondId: undefined,
    IdPartner: "",
    Famale: "",
    Name: "",
    Patronymic: "",
    OpeningDate: convertToUtc((new Date())).toISOString(),
    CurrencyId: 1,
    Sum: undefined,
    RisingDay: undefined,
    InformPartner: false,
    ProductId: undefined,
    Percent: undefined,
    MemberPercent: undefined,
    FuncPayName: "",
    Fine: undefined,
    loading: false,
    showModal: false,
    valueTerm: 0,
    formErrors: {
      IdAspPartner: "",
      IsMain: "",
      ProductCondId: "",
      IdPartner: "",
      Famale: "",
      Name: "",
      Patronymic: "",
      OpeningDate: "",
      CurrencyId: "",
      Sum: "",
      RisingDay: "",
      InformPartner: "",
      ProductId: "",
      Percent: "",
      MemberPercent: "",
      FuncPayName: "",
      Fine: "",
    },
    formValid: true,
    errorMessage: "",
    formLoading: false
  };

  public componentDidMount() {
    this.loadPartner();
  }

  public render() {
    const {
      formErrors,
      CurrencyId,
      Famale,
      IdPartner,
      Name,
      Patronymic,
      OpeningDate,
      Sum,
      RisingDay,
      InformPartner,
      Percent,
      Fine,
      FuncPayName,
      MemberPercent,
      IsMain,
      ProductId,
      formValid,
      errorMessage,
      formLoading,
      loading,
      ProductCondId,
      valueTerm,
      showModal
    } = this.state;
    const { search: { data: searchData } } = this.props;
    const products = get(this.state, "Products", []) || [];
    const data = products.map((item: LoanProduct) => ([item.Text, item.Value]));

    return (
      <div>
        <Modal
          isOpen={showModal}
          fullWidth
        >
          <div>Выбран срок заведения продукта {RisingDay} дней.
            Если вы вводили срок в месяцах, необходимо вернутся к редактированию и проверить указанный срок</div>
          <div className="col mt-4 text-right">
            <Button type="success" className="ml-3" onClick={this.submitForm} loading={loading}>
              Открыть продукт
            </Button>
            <Button type="error" className="ml-3" onClick={this.closeModal} loading={loading}>
              Вернутся к редактированию
            </Button>
          </div>
        </Modal>
        <FormControl
          onChange={this.handleChangeAndFind("IdPartner")}
          onFocus={this.onFocus("IdPartner")}
          handleChangeAutoSelect={this.handleChangeAutoSelect}
          fieldName="Number"
          value={IdPartner}
          label="Пайщик"
          error={formErrors.IdPartner}
          searchData={searchData}
          type="autocomplete"
        />
        <FormControl
          onChange={this.handleChangeAndFind("Famale")}
          onFocus={this.onFocus("Famale")}
          handleChangeAutoSelect={this.handleChangeAutoSelect}
          value={Famale}
          fieldName="Famale"
          label="Фамилия"
          error={formErrors.Famale}
          searchData={searchData}
          // type="autocomplete"
          disabled
        />
        <FormControl
          onChange={this.handleChangeAndFind("Name")}
          onFocus={this.onFocus("Name")}
          handleChangeAutoSelect={this.handleChangeAutoSelect}
          value={Name}
          fieldName="Name"
          label="Имя"
          error={formErrors.Name}
          searchData={searchData}
          // type="autocomplete"
          disabled
        />
        <FormControl
          onChange={this.handleChangeAndFind("Patronymic")}
          handleChangeAutoSelect={this.handleChangeAutoSelect}
          value={Patronymic}
          fieldName="Patronymic"
          label="Отчество"
          error={formErrors.Patronymic}
          searchData={searchData}
          // type="autocomplete"
          disabled
        />
        <FormControl
          onChange={this.handleChangeDate("OpeningDate")}
          value={OpeningDate}
          label="Дата открытия"
          type="date"
          disabled
        />
        <FormControl
          onChange={this.handleChange("CurrencyId", true)}
          value={CurrencyId}
          data={Object.entries(currencyCodes)}
          label="Валюта"
          type="select"
          handleBlur={this.loadProducts}
        />
        <FormControl
          onChange={this.handleChange("Sum")}
          onFocus={this.onFocus("Sum")}
          value={Sum}
          label="Сумма размещения"
          error={formErrors.Sum}
          isNumber
          handleBlur={this.loadProducts}
        />
        <FormControl
          onChange={this.handleChange("RisingDay")}
          onFocus={this.onFocus("RisingDay")}
          value={RisingDay}
          label="Срок"
          valueTerm={valueTerm}
          onChangeTerm={this.handleChange("valueTerm")}
          error={formErrors.RisingDay}
          isNumber
          handleBlur={this.loadProducts}
          isTerm
        />
        <FormControl
          onChange={this.handleChangeCheckbox("InformPartner")}
          value={InformPartner}
          label="Информировать клиента"
          error={formErrors.InformPartner}
          type="checkbox"
        />
        <LoadingWrapper loading={loading ? loading : undefined}>
          <FormControl
            onChange={this.handleChange("ProductId")}
            value={ProductId}
            data={isArray(products) && !isEmpty(products) ? data : [["Нет", "Нет"]]}
            label="Продукт"
            type="select"
          />
          <FormControl
            onChange={this.handleChangeCheckbox("IsMain", true)}
            value={IsMain}
            label="Только основные продукты"
            error={formErrors.IsMain}
            type="checkbox"
          />
          <FormControl
            onChange={this.handleChange("Percent")}
            value={Percent}
            label="Процентная ставка"
            error={formErrors.Percent}
            disabled
          />
          <FormControl
            onChange={this.handleChange("MemberPercent")}
            value={MemberPercent}
            label="Членский взнос"
            error={formErrors.MemberPercent}
            disabled
          />
          <FormControl
            onChange={this.handleChange("FuncPayName")}
            value={FuncPayName}
            label="Тип выплат"
            error={formErrors.FuncPayName}
            disabled
          />
          <FormControl
            onChange={this.handleChange("Fine")}
            value={Fine}
            label="Пеня"
            error={formErrors.Fine}
            disabled
          />
        </LoadingWrapper>
        {(!formValid || errorMessage) && (
          <div className="alert alert-danger mt-3 d-print-none">
            <ErrorMessage
              errorData={{ message: "Ошибка", verbose: errorMessage || "Произошла ошибка. Попробуйте снова" }}/>
          </div>
        )}
        <div className="row">
          <div className="col justify-content-end">
            <Button type="success" loading={formLoading} disabled={!IdPartner || !Famale || !Name || !RisingDay || !OpeningDate || !ProductCondId} onClick={this.print}>
              Печать договора
            </Button>
            <Button type="success" className="ml-3" loading={formLoading} disabled={!formValid} onClick={this.submit}>
              Создать
            </Button>
          </div>
        </div>
      </div>
    );
  };

  private loadPartner = async () => {
    const { partnerNumber } = this.props;
    if (partnerNumber) {
      const partner = await ApiClient.getPartner(partnerNumber);

      if (partner) {
        this.setState({
          Famale: get(partner, "LastName"),
          Name: get(partner, "FirstName"),
          Patronymic: get(partner, "Patronymic"),
          IdPartner: partnerNumber
        });
      }
    }
  };

  private submit = () => {
    if (!this.validateForm()) {
      return false;
    }

    const { valueTerm, RisingDay } = this.state;

    if (Number(valueTerm) === 0 && Number(RisingDay) <= 60) {
      this.setState({
        showModal: true,
      });

      return;
    }

    this.submitForm();
  };

  private closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  private print = async () => {
    const { IdPartner, CurrencyId, Sum, ProductCondId, OpeningDate } = this.state;
    const period = this.getPeriodInDay();
    await this.setState({ formLoading: true });
    await ApiClient.downloadFile(`/Template/RaisingsAgreement?id=${IdPartner}&period=${period}&currency=${get(currencyTypes, `${CurrencyId}`)}&openingSum=${Sum}&prodCond=${ProductCondId}&openingDate=${moment(OpeningDate).format("YYYY.MM.DD")}`)
    await this.setState({ formLoading: false });
  };

  private submitForm = async () => {
    this.setState({ formLoading: true });
    const {
      IsMain,
      ProductCondId,
      Famale,
      Name,
      Patronymic,
      OpeningDate,
      CurrencyId,
      Sum,
      InformPartner,
      ProductId,
      Percent,
      MemberPercent,
      FuncPayName,
      IdPartner
    } = this.state;
    const { goToList } = this.props;
      try {
        const { Id: IdAspPartner } = await ApiClient.getPartner(IdPartner);
        if (!IdAspPartner) {
          throw new Error("Пайщик не найден");
        }

        const result = await ApiClient.createRaising({
          IdAspPartner,
          IsMain,
          ProductCondId,
          IdPartner,
          Famale,
          Name,
          Patronymic,
          OpeningDate: moment(OpeningDate).format("DD.MM.YYYY"),
          CurrencyId,
          Sum: Sum && Sum.toString().replace('.', ','),
          RisingDay: this.getPeriodInDay(),
          NdflCount: true,
          InformPartner,
          ProductId,
          Percent,
          MemberPercent,
          FuncPayName,
        });
        this.setState({ formLoading: false });
        toast(result.Result || 'Сберегательная программа успешно создана', {
          type: toast.TYPE.SUCCESS
        });
        goToList(`/Bill/Details/${result.BillCode}`,undefined);
      } catch (e) {
        const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
        this.setState({
          formLoading: false,
          errorMessage,
        });
        toast(errorMessage, {
          type: toast.TYPE.ERROR
        });
      }

  };

  private validateForm = () => {

    const requiredFields = ["IdPartner", "Famale", "Name", "Sum"];

    const state = this.state;
    const { Sum } = this.state;
    const formErrors = {};

    if (!this.isNumeric(Sum)) {
      set(formErrors, 'Sum', "Данные не валидны или пайщик не найден");
    }

    requiredFields.map((item) => {
      if (!get(state, item, "")) {
        set(formErrors, item, "Поле обязательно для заполнения");
      }
    });


    this.setState(prevState => ({
      ...prevState,
      formErrors: {
        ...state.formErrors,
        ...formErrors
      },
      formValid: isEmpty(formErrors),
      errorMessage: ""
    }));

    return isEmpty(formErrors);
  };

  private handleChange = (fieldName: string, watcher: boolean = false) => (e: any) => {
    const { target: { value } } = e;
    this.setState({
      [fieldName]: value
    }, () => {
      if (watcher) {
        this.loadProducts();
      }
      if (fieldName === "ProductId") {
        this.loadProductConditions();
      }
    });
  };

  private onFocus = (fieldName: string) => (e: any) => {
    const { formErrors } = this.state;
    let isValid = true;
    const obj = omit(formErrors, [`${fieldName}`]);
    for (const key in obj) {
      // @ts-ignore
      if (obj[key] !== '') {
        isValid = false;
      }
    }
    this.setState(() => ({
      formErrors: {
        ...formErrors,
        [fieldName]: ""
      },
      formValid: isValid,
    }));
  };

  private handleChangeAndFind = (fieldName: string) => (value: any) => {
    const { searchPartnersRequest } = this.props;
    const { IdPartner, Famale, Name, Patronymic } = this.state;
    if (get(this.state, fieldName) !== value) {
        this.setState({
          Famale: '',
          Name: '',
          Patronymic: '',
          formValid: true,
          errorMessage: '',
        });
        if (value !== '') {
        const findField = fieldName === "IdPartner" ? "Number" : fieldName;
        searchPartnersRequest({
          Number: IdPartner,
          Famale,
          Name,
          Patronymic,
          [findField]: value
        });
      }
    }
    this.setState({
      [fieldName]: value
    });
  };

  private handleChangeCheckbox = (fieldName: string, watcher: boolean = false) => (e: any) => {
    const { target: { checked } } = e;
    this.setState({
      [fieldName]: checked
    }, () => {
      if (watcher) {
        this.loadProducts();
      }
    });
  };

  private loadProducts = async () => {
    const { IsMain, Sum, CurrencyId, RisingDay } = this.state;
      if (Sum && RisingDay) {
        this.setState({
          loading: true
        });
        try {
          const result: LoanProducts = await ApiClient.getRaisingProducts({
            sum: Sum,
            currency: CurrencyId,
            period: this.getPeriodInDay(),
            isMain: IsMain
          });
          this.setState((prevState) => ({
            ...prevState,
            ...result,
            loading: false,
            formValid: true,
            errorMessage: '',
          }));
        } catch (e) {
          this.setState({
            loading: false,
            formValid: false,
            errorMessage: e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова"
          }, this.cleanProducts);

        }
      }
  };

  private cleanProducts = () => {
    this.setState({
      Products: []
    });
    this.cleanProductConditions();
  };

  private cleanProductConditions = () => {
    this.setState({
      Percent: undefined,
      MemberPercent: undefined,
      FuncPayName: "",
      Fine: undefined
    })
  };

  private loadProductConditions = async () => {
    const { IsMain, Sum, CurrencyId, RisingDay, ProductId } = this.state;
      if (Sum && RisingDay && ProductId) {
        this.setState({
          loading: true
        });
        try {
          const result: LoanProducts = await ApiClient.getRaisingProductConditions({
            productId: ProductId,
            sum: Sum,
            currency: CurrencyId,
            period: this.getPeriodInDay(),
            isMain: IsMain
          });

          this.setState((prevState) => ({
            ...prevState,
            ...result,
            loading: false,
            formValid: true,
            errorMessage: '',
          }));

        } catch (e) {
          this.setState({
            loading: false,
            formValid: false,
            errorMessage: e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова"
          }, this.cleanProductConditions);
        }
      }
  };

  private handleChangeAutoSelect = (partner: any) => {
    this.setState({
      ...partner,
      IdPartner: partner.Number
    });
  };

  private handleChangeDate = (fieldName: string) => (date: Date, event: any) => {
    const { OpeningDate, formErrors } = this.state;
    if (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value)) {
      const value = date ? convertToUtc(date).toISOString() : null;
      if (value && moment(value).isSameOrAfter(moment(OpeningDate))) {
        const RisingDay = moment(value).diff(moment(OpeningDate), "days");
        this.setState({
          [fieldName]: value === NONE_VALUE || value === "" ? null : value,
          RisingDay,
          formErrors: {
            ...formErrors,
            [fieldName]: '',
          }
        });
      } else {
        this.setState({
          formErrors: {
            ...formErrors,
            [fieldName]: "Дата закрытия не может быть раньше даты открытия",
          }
        })
      }
    }
  };

  private isNumeric = (val: any) => {
    return !isNaN(toNumber(val));
  };

  private getPeriodInDay = () => {
    const { RisingDay, valueTerm } = this.state;

    if (Number(valueTerm) === 0) {

      return RisingDay;
    }

    return Math.floor(Number(RisingDay) / 12) * 365 + (Number(RisingDay) % 12 * 30);
  }
}
