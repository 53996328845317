export const GlobalConfig = {
  global: {
    useUTC: false,
  },
  lang: {
    loading: 'Загрузка...',
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
    exportButtonTitle: 'Экспорт',
    printButtonTitle: 'Печать',
    rangeSelectorFrom: 'С',
    rangeSelectorTo: 'По',
    rangeSelectorZoom: 'Период',
    downloadPNG: 'Скачать PNG',
    downloadJPEG: 'Скачать JPEG',
    downloadPDF: 'Скачать PDF',
    downloadSVG: 'Скачать SVG',
    printChart: 'Напечатать график',
    resetZoom: 'Отдалить',
    resetZoomTitle: 'Отдалить',
    thousandsSep: ' ',
    decimalPoint: ',',
    noData: 'Нет данных',
  },
  noData: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px',
      color: '#303030',
    },
  },
};

export const CommonConfig = {
  chart: {
    type: 'line',
    zoomType: 'x',
  },
  plotOptions: {
    series: {
      pointStart: Date.UTC(2000, 1, 22),
      pointInterval: 24 * 3600 * 1000, // one day
    },
  },
  tooltip: {
    crosshairs: true,
    shared: true,
    useHTML: true,
    headerFormat: '<small>{point.key}</small><table>',
    pointFormat: '<tr><td style="color: {series.color}">{series.name}: </td>' +
    '<td style="text-align: right"><b>{point.y}</b></td></tr>',
    footerFormat: '</table>',
    valueDecimals: 2,
    // xDateFormat: '%d %B',
  },
};
