import React, { Component } from 'react';
import Badge from '@material-ui/core/Badge';
import SubMenu from './SubMenu';
import Item from './Item';

import './Menu.scss';

export default class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: '',
    };
  }

  handleClick(key) {
    let newKey = key;
    if (newKey === this.state.active || this.state.active.indexOf(newKey) === 0) {
      const keys = newKey.split('-');
      keys.pop();
      newKey = keys.join('-');
    }
    this.setState({ active: newKey });
  }

  buildMenu(menu, baseKey) {
    return menu.map((item, index) => {
      const key = `${baseKey}-${index}`;
      let icon = item.icon
        ? <i className={`fa fa-${item.icon}`} />
        : null;
      const title = <span>{item.title}</span>;

      if (item.url === '/PaymentRequest') {
        icon = (<Badge badgeContent={this.props.countPaymentRequests} color="secondary">{icon}</Badge>);
      }

      if (item.url === '/chats') {
        icon = (<Badge badgeContent={this.props.unreadCount} color="secondary">{icon}</Badge>);
      }

      if (item.submenu) {
        return (
          <SubMenu
            key={key}
            id={key}
            icon={icon}
            collapsed={this.props.collapsed}
            title={title}
            onClick={() => this.handleClick(key)}
            activeKey={this.state.active}
          >
            {this.buildMenu(item.submenu, key)}
          </SubMenu>
        );
      }
      return (
        <Item
          key={key}
          id={key}
          icon={icon}
          collapsed={this.props.collapsed}
          title={title}
          onClick={() => this.handleClick(key)}
          activeKey={this.state.active}
          url={item.url}
        />
      );
    });
  }

  render() {
    return <div className="menu">{this.buildMenu(this.props.items, 'menu')}</div>;
  }
}
