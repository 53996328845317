import * as React from "react";
import { RouteComponentProps } from "react-router";
import moment from "moment";

import { Container, Header } from "../components/Views";
import CurrencyFilter from "../../../components/CurrencyFilter/CurrencyFilter";
import PeriodFilter from "../../../components/PeriodFilter/PeriodFilter";
import CompareFilter from "../../../components/CompareFilter/CompareFilter";
import Chart from "../../../components/Chart/Chart";
import {
  getCurrencyCode,
  getPeriodType,
  getTickInterval,
  handleChangeCompare,
  handleChangeCurrency,
  handleChangePeriod
} from "../../../helpers/chartHelpers";
import { GenderType, GenderLine, AnalyticsState } from "../types";
import PieChart from "../../../components/Chart/PieChart";
import SumInfo, { SumInfoRow } from "../components/SumInfo";
import { getDefaultState, getLineChartData, getPieChartData } from "../utils";
import AnalyticsApiClient from "../services/api";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { showErrorToast } from "../../../components/ErrorToast";

export interface DepositsGenderProps extends RouteComponentProps {
}

export interface DepositsGenderState extends AnalyticsState {
  data: GenderLine[],
}

const translateGender = (gender: GenderType): string => {
  switch (gender) {
    case "Male":
      return "Мужчины";
    case "Female":
      return "Женщины";
    default:
      return "-";
  }
};

const nameFormatter = (line: GenderLine, firstDate?: string, lastDate?: string) =>
  `Количество вкладов (${line.Currency}, ${translateGender(line.Gender)}): ${firstDate} - ${lastDate}`;

const commonValueAccumulator = (a: any, line: GenderLine) => {
  a.y = a.y + line.Count;
  a.name = `${translateGender(line.Gender)} за все время`;
  return a;
};

const partValueMapper = (line: GenderLine, firstDate: string, lastDate: string) => ({
  y: line.Count,
  name: `${translateGender(line.Gender)} за период ${firstDate} - ${lastDate} (${line.Currency})`
});

const getPeriodSums = (data: GenderLine[], gender: GenderType) => {
  const lines = data.filter((line: GenderLine) => line.Gender === gender);
  return lines.reduce((acc: SumInfoRow[], line: GenderLine) => {
    const firstDate = moment(line.StartDate).format("MMM YYYY");
    const lastDate = moment(line.EndDate).format("MMM YYYY");
    const title = `${translateGender(gender)} за период ${firstDate} - ${lastDate}`;
    const existing = acc.find((item: SumInfoRow) => item.title === title);
    if (existing) {
      existing.sum = existing.sum + line.Count;
    } else {
      acc.push({ title, sum: line.Count });
    }
    return acc;
  }, []);
};

export default class DepositsGender extends React.Component<DepositsGenderProps, DepositsGenderState> {
  private readonly handleChangeCompare: any;
  private readonly handleChangePeriod: any;
  private readonly handleChangeCurrency: any;

  constructor(props: Readonly<DepositsGenderProps>) {
    super(props);

    this.state = getDefaultState();

    this.handleChangeCompare = handleChangeCompare.bind(this);
    this.handleChangeCurrency = handleChangeCurrency.bind(this);
    this.handleChangePeriod = handleChangePeriod.bind(this);
  }

  public componentDidMount() {
    this.loadData();
  }

  public render() {
    const {
      filters: {
        currency,
        period,
        dateFrom,
        dateTo,
        compare
      },
      data = [],
      loading
    } = this.state;

    return (
      <Container>
        <LoadingOverlay loading={loading === "pending"}>
          <Header>
            <CurrencyFilter active={currency} onChange={this.handleChangeCurrency} />
            <PeriodFilter
              activePeriod={period}
              dateFrom={dateFrom}
              dateTo={dateTo}
              onChange={this.handleChangePeriod}
            />
            {period !== "year" && period !== "currentYear"
              ? null
              : <CompareFilter compare={compare} onChange={this.handleChangeCompare} />}
          </Header>
          <Chart
            series={getLineChartData(data, nameFormatter)}
            minTickInterval={getTickInterval(period)}
            title="Половозрастная характеристика"
            xLabel="Период"
            yLabel="Количество"
            xDateFormat={period !== "month" && period !== "currentMonth" ? "%B" : "%d %B"}
          />
          <PieChart
            title="Распределение вкладов по полу"
            series={getPieChartData(data, "Gender", commonValueAccumulator, partValueMapper)}
          />
          <SumInfo title="Общее количество мужчин за период" data={getPeriodSums(data, "Male")} />
          <SumInfo title="Общее количество женщин за период" data={getPeriodSums(data, "Female")} />
        </LoadingOverlay>
      </Container>
    );
  }

  private async loadData() {
    try {
      const {
        currency,
        period,
        dateFrom,
        dateTo,
        compare
      } = this.state.filters;

      const params = {
        currency: getCurrencyCode(currency),
        periodType: getPeriodType(period),
        dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
        dateTo: moment(dateTo).format("YYYY-MM-DD"),
        compare: period !== "year" && period !== "currentYear" ? undefined : compare
      };
      this.setState({ loading: "pending" });
      const data = await AnalyticsApiClient.getDepositsGender(params);

      this.setState({ data });
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: "ok" });
    }
  }
}
