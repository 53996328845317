import * as React from 'react';
import {
  Select, SelectOption,
  SimpleInput
} from "../../../../../../components/FormControls/FormControls";
import styled from "../../../../../../styled/styled-components";
import DatePicker from "../../../../../../components/DatePicker/DatePicker";
import { parseServerDate } from "../../../../../../utils";

export interface FormControlProps {
  label: string,
  value?: string | number,
  type?: string,
  data?: Array<[any, any]>,
  error?: string,
  onChange: (e: any, event?: any) => void;
  disabled?: boolean;
}

const OperatorLabel = styled.label`
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
    font-weight: bold;
`;

export default class FormControl extends React.PureComponent<FormControlProps> {
  public static defaultProps = {
    value: '',
  };

  public render() {
    const { label, error } = this.props;
    return (
      <div className="row text-right mb-3">
        <div className="col-3">
        <OperatorLabel>
          {label}
        </OperatorLabel>
        </div>
        <div className="col-9">
          {this.getControl()}
          {error && (<span className="text-danger">{error}</span>)}
        </div>
      </div>
    );
  }

  private getControl = () => {
    const { onChange, value, type, data, error, disabled } = this.props;
    switch (type) {
      case 'select':
        return (
          <Select greenBorder disabled={disabled} onChange={onChange} value={value} style={{ width: "100%" }}>
            {data && data.map(([id, label]) => (
              <SelectOption key={label} value={label}>
                {id}
              </SelectOption>
            ))}
          </Select>
        );
        case 'date':
        return <DatePicker disabled={disabled} useStyledInput onChange={onChange} selected={value && typeof value === 'string' ? parseServerDate(value) : null} />;
      default:
        return <SimpleInput disabled={disabled} onChange={onChange} greenBorder error={!!error} value={value} />
    }
  }
}
