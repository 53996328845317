import { createSelector } from "reselect";
import { remove } from 'lodash';
import moment from "moment";

import { AppState } from "../index";
import { ChatsState, AllChats, Message, ChatItem } from "./types";
import { getAuthData, UserData } from "../auth";

export const getChats = (state: AppState) => state.chat;
export const getActiveChatId = (state: AppState) => state.chat.activeChat;
export const getUnreadCount = (state: AppState) => state.chat.unreadCount;

export const getAllChats = createSelector(
  getChats,
  (chats: ChatsState) => chats.allChats
);

export const getAllChatsMessages = createSelector(
  getAllChats,
  (chats: AllChats) => {

    const data = [ ...chats.data ].map((item: Message) => ({
      id: item.ChatId,
      title: item.ChatName,
      subtitle: item.Message,
      date: new Date(item.Date),
      unread: item.IsReaded ? 0 : 1,
    })).sort((a: ChatItem, b: ChatItem ) => b.date.getTime() - a.date.getTime());

    const systemChat = remove(data, (item: ChatItem) => item.id === 1);

    return [ ...systemChat, ...data ];
  }
);

export const getReciverId = createSelector(
  getAllChats,
  getAuthData,
  getActiveChatId,
  (chats: AllChats, user?: UserData, activeChat?: number) => {
    for (const item of chats.data) {
      if (activeChat && item.ChatId === activeChat && user) {

        return item.UserFromId === user.Id ? item.UserToId : item.UserFromId;
      }
    }

    return undefined;
  }
);

export const getActiveChat = createSelector(
  getAllChats,
  getActiveChatId,
  (chats: AllChats, activeChat?: number) => {
    for (const item of chats.data) {
      if (activeChat && item.ChatId === activeChat) {

        return item;
      }
    }

    return undefined;
  }
);

export const getAllChatsLoading = createSelector(
  getAllChats,
  (chats: AllChats) => chats.loading
);

export const getChatMessages = createSelector(
  getChats,
  (chats: ChatsState) => chats.chatMessages
);

export const getAllMessages = createSelector(
  getChatMessages,
  getAuthData,
  (chats: AllChats, user?: UserData) => {
    return chats.data.map((item: Message) => ({
      title: item.UserFromName,
      position: user && item.UserFromId === item.ChatId ? 'left' : 'right',
      type: item.Type === 1 || item.Type === 4 ? 'text' : 'system',
      text: item.Message,
      date: new Date(item.Date),
      dateString: moment(item.Date).format('DD-MM-YY HH:MM')
    }))
  }
);

export const getAllMessagesLoading = createSelector(
  getChatMessages,
  (chats: AllChats) => chats.loading,
);
