import { Reducer } from "redux";
import { LabelsState } from "./types";
import { ActionTypes } from "./actions";
import {
  PAYMENT_REQUESTS_LIST_COLUMNS,
  COMMON,
  PAYMENT_REQUESTS_STATUS,
  PAYMENT_REQUEST_BILL_TYPE,
  PAYMENT_REQUESTS_TYPE,
  GENDER,
  IS_ACTIVE,
  CURRENCY,
  OPERATION_TYPES,
  REPORT_FIELD_LABELS,
  ACTIVITY, YESNO, CLIENTS_TYPE, YESNO_BOOLEAN, EVENT_NAMES
} from "./constants";
import { BILL_CURRENT_FIELD_LABELS as BILL_SALARIES_FIELD_LABELS } from "../../modules/Bill/Salaries/constants";
import { BILL_CURRENT_FIELD_LABELS as BILL_DEPOSITS_FIELD_LABELS } from "../../modules/Bill/Deposits/constants";
import { BILL_CURRENT_FIELD_LABELS as BILL_LOANS_FIELD_LABELS } from "../../modules/Bill/Loans/constants";
import { BILL_CURRENT_FIELD_LABELS as BILL_RAISINGS_FIELD_LABELS } from "../../modules/Bill/Raisings/constants";
import { BILL_CURRENT_FIELD_LABELS as OPERATIONS_FIELD_LABELS } from "../../modules/BillDetails/components/Operations/components/Table/constants";
import { REPORT_MONTHLY_FIELD_LABELS } from "../../modules/Reports/ReportMonthly/constants";
import { DEPOSIT_ANALITICS_FIELD_LABELS } from "../../modules/Reports/DepositAnalytics/constants";
import { DEPOSIT_REPORT_MONTHLY_FIELD_LABELS } from "../../modules/Reports/DepositPeriodReport/constants";
import { LOAN_ANALITICS_FIELD_LABELS } from "../../modules/Reports/LoanAnalytics/constants";
import { LOAN_REPORT_MONTHLY_FIELD_LABELS } from "../../modules/Reports/LoanPeriodReport/constants";
import { OVERDUE_CURRENT_FIELD_LABELS } from "../../modules/Reports/OverdueReports/constants";
import { LATE_CURRENT_FIELD_LABELS } from "../../modules/Reports/LatePaymentsReports/constants";
import { USER_FIELD_LABELS } from "../../modules/SecurityUser/constants";
import { GROUP_FIELD_LABELS } from "../../modules/SecurityGroup/constants";
import { BILL_TYPES, PRODUCT_FIELD_LABELS } from "../../modules/Products/constants";
import { AUDIT_FIELD_LABELS } from "../../modules/Audits/Audit/constants";
import { AUDIT_USER_FIELD_LABELS } from "../../modules/Audits/AuditUser/constants";
import { PRIVILEGE_LEVEL_LABELS } from "../../constants";
import { CALENDAR_FIELD_LABELS } from "../../modules/Calendar/components/Table/constants";

const initialState: LabelsState = {
  loading: false,
  labels: {
    COMMON,
    ACTIVITY,
    PAYMENT_REQUESTS_LIST_COLUMNS,
    PAYMENT_REQUESTS_STATUS,
    PAYMENT_REQUEST_BILL_TYPE,
    PAYMENT_REQUESTS_TYPE,
    GENDER,
    IS_ACTIVE,
    CURRENCY,
    BILL_SALARIES_FIELD_LABELS,
    BILL_DEPOSITS_FIELD_LABELS,
    BILL_LOANS_FIELD_LABELS,
    BILL_RAISINGS_FIELD_LABELS,
    OPERATIONS_FIELD_LABELS,
    OPERATION_TYPES,
    REPORT_FIELD_LABELS,
    REPORT_MONTHLY_FIELD_LABELS,
    DEPOSIT_ANALITICS_FIELD_LABELS,
    DEPOSIT_REPORT_MONTHLY_FIELD_LABELS,
    LOAN_ANALITICS_FIELD_LABELS,
    LOAN_REPORT_MONTHLY_FIELD_LABELS,
    OVERDUE_CURRENT_FIELD_LABELS,
    LATE_CURRENT_FIELD_LABELS,
    USER_FIELD_LABELS,
    GROUP_FIELD_LABELS,
    AUDIT_FIELD_LABELS,
    AUDIT_USER_FIELD_LABELS,
    PRODUCT_FIELD_LABELS,
    BILL_TYPES,
    YESNO,
    YESNO_BOOLEAN,
    CLIENTS_TYPE,
    PRIVILEGE_LEVEL_LABELS,
    CALENDAR_FIELD_LABELS,
    EVENT_NAMES
  }
};

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_LABELS_REQUESTS:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.FETCH_LABELS_SUCCESS:
      return {
        ...state,
        loading: false,
        labels: {
          ...state.labels,
          [action.meta]: action.payload
        }
      };

    case ActionTypes.FETCH_LABELS_ERROR:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};

export { reducer as labelsReducer };
