import React, { PureComponent, Fragment } from "react";
import moment from 'moment';
import { noop } from 'lodash'

import FormControl from '../CalculatorForm/components/FormControl';
import { CalculatingLoanData } from "../../../../store/calculators";


export default class TableLoan extends PureComponent<CalculatingLoanData> {
  public render() {
    const { Overpayment, FullCost, PlanCalendar } = this.props;
    const { BillLoanPlanCalendarPeriods, Percent, MemberFee, MonthlyPayment } = PlanCalendar;
    return (
      <Fragment>
        <FormControl label="Полная стоимость займ" onChange={noop} value={FullCost} disabled />
        <FormControl label="Удорожание (переплата)" onChange={noop} value={Overpayment} disabled />
        <table className="table table-striped">
          <thead>
          <tr>
            <th>Дата платежа</th>
            <th>Остаток основного долга</th>
            <th>Погашение основного долга</th>
            <th>Выплата процентов</th>
            <th>Удержание членских взносов</th>
            <th>Сумма платежа</th>
          </tr>
          </thead>
          <tbody>
          {BillLoanPlanCalendarPeriods.map(item => (
            <tr>
              <td>{moment(item.FactPaymentDate).format("DD.MM.YYYY")}</td>
              <td>{item.Balance}</td>
              <td>{item.BodyBack}</td>
              <td>{item.Percent}</td>
              <td>{item.MemberFee}</td>
              <td>{item.MonthlyPayment}</td>
            </tr>
          ))}
          </tbody>
          <tfoot>
          <tr>
            <td>Итого:</td>
            <td/>
            <td/>
            <td>{ Percent}</td>
            <td>{ MemberFee }</td>
            <td>{ MonthlyPayment }</td>
          </tr>
          </tfoot>
        </table>
      </Fragment>
    );
  }
}