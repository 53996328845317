import * as React from "react";
import { switchProp, prop } from "styled-tools";
import Spinner from "react-spinkit";
import Clickable, { ClickableProps } from "../Clickable";
import styled, { css } from "../../styled/styled-components";

export type ButtonType = "default" | "primary" | "success" | "inline" | "error" | "warning";
export interface ButtonProps extends ClickableProps {
  type?: ButtonType;
  loading?: boolean;
}

const buttonStyles = css`
  font-size: 14px;
  color: ${switchProp(prop("type", "default"), {
    default: "#212529",
    success: "#fff",
    primary: "#fff",
    warning: "#fff",
    error: "#fff"
  })};
  background-color: ${switchProp(prop("type", "default"), {
    default: "#f8f9fa",
    success: "#1ab394",
    primary: "#007bff",
    warning: "#f8ac59",
    error: "#dc3545"
  })};
  border: 0;
  border-radius: 3px;
  height: 34px;
  min-width: 120px;
  padding: 0 10px;
  user-select: none;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${switchProp(prop("type", "default"), {
      default: "#e2e6ea",
      success: "#18a689",
      warning: "#f8ac59",
      primary: "#0069d9",
      error: "#c82333"
    })};
    color: ${switchProp(prop("type", "default"), {
      default: "#212529",
      success: "#fff",
      warning: "#fff",
      primary: "#fff",
      error: "#fff"
    })};
    text-decoration: none;
  }

  &:active {
    box-shadow: inset 0 1px 2px 2px rgba(0, 0, 0, 0.6);
    outline: none;
  }
`;

const ButtonSpinner = styled(Spinner).attrs({ name: "circle" })`
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
`;

const ButtonView: React.SFC<ButtonProps> = ({
  type = "default",
  loading,
  children,
  ...props
}) => (
  <Clickable {...props}>
    {loading && <ButtonSpinner />}
    {children}
  </Clickable>
);

const Button = styled(ButtonView)`
  ${buttonStyles}
`;

export default Button;
