import * as React from "react";
import Button from "../Button/Button";
import Popover from "@material-ui/core/Popover";

export type DropdownCaptionState = "opened" | "closed";

type CaptionRenderProps = (
  state: DropdownCaptionState,
  open: () => void,
  close: () => void
) => React.ReactNode;

export interface DropdownProps {
  caption: CaptionRenderProps | React.ReactNode;
}

export interface DropdownState {
  opened: boolean;
}

export default class Dropdown extends React.Component<
  DropdownProps,
  DropdownState
> {
  public state: DropdownState = {
    opened: false
  };

  private domContainer: HTMLDivElement | null = null;
  private captionElement = React.createRef<HTMLSpanElement>();

  constructor(props: DropdownProps) {
    super(props);

    this.domContainer = document.createElement("div");

    document.body.appendChild(this.domContainer);
  }

  public render() {
    return (
      <React.Fragment>
        <span ref={this.captionElement}>{this.renderCaption()}</span>
        <Popover
          open={
            !!this.captionElement.current &&
            !!this.props.children &&
            this.state.opened
          }
          anchorEl={this.captionElement.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          onClose={this.close}
          disableAutoFocus
          disableRestoreFocus
          style={{zIndex: 28}}
        >
          {this.props.children}
        </Popover>
      </React.Fragment>
    );
  }

  public open = () => {
    this.setState({ opened: true });
  };

  public close = () => {
    this.setState({ opened: false });
  };

  private renderCaption = () => {
    const { caption } = this.props;
    if (typeof caption === "function") {
      return (caption as CaptionRenderProps)(
        this.state.opened ? "opened" : "closed",
        this.open,
        this.close
      );
    }

    return <Button onClick={this.handleClickCaption}>{caption}</Button>;
  };

  private handleClickCaption = () => {
    this.setState(state => ({ opened: !state.opened }));
  };
}
