import {
  ProductTabType,
  productData,
  ProductListItem,
  SortDirection,
} from "../types";
import MainTab from "../components/Tabs/MainTab";
import {
  FilterFieldName,
  FilterItemData
} from "../../../types/PartnersAndClients";
import ConditionsRubTab from '../components/Tabs/ConditionsRubTab';
import ConditionsUsdTab from '../components/Tabs/ConditionsUsdTab';
import ConditionsEurTab from '../components/Tabs/ConditionsEurTab';

export const PARTNER_TABS_LABELS = new Map<
  ProductTabType,
  {
    label: string;
    component: React.ComponentType;
  }
>([
  ["main", { label: "Общие данные", component: MainTab }],
  ["productRub", { label: "RUB", component: ConditionsRubTab }],
  ["productUsd", { label: "USD", component: ConditionsUsdTab }],
  ["productEur", { label: "EUR", component: ConditionsEurTab }],
]);

export const PRODUCT_FIELD_LABELS = new Map<keyof ProductListItem, string>([
  ["Name", "Название продукта"],
  ["BillTypeId", "Тип продукта"],
  ["CurrencyId", "Валюта"],
  ["Currencies", "Валюта"],
  ["FuncPayId", "Платежи"],
  ["IsActive", "Статус"],
]);

export const MAIN_FORM_FIELDS = [
  new Map<keyof productData, string>([
    ["Name", "Название продукта"],
    ["BillTypeId", "Тип продукта"],
    ["FuncPayId", "Тип выплат"],
    ["UseCapital", "Капитализация"],
    ["UseMFPercent", "Использовать МФ%"],
    ["UseProgressive", "Использовать прогрессивные ставки"],
    ["IsActive", "Активный"],
    ["IsMain", "Основной продукт"]
  ])
];

export const REQUIRED_FIELDS = new Map([
  [
    "productData",
    [
      "Name",
      "BillTypeId",
      "FuncPayId"
    ]
  ]
]);

export const READONLY_FIELDS = new Map([
  [
    "productData",
    [
      "BillTypeId",
      "FuncPayId"
    ]
  ]
]);

export const OPERATOR_LABELS = new Map([[1, "И"], [2, "ИЛИ"], [3, "НЕ"]]);

export const DEFAULT_SEARCH_TYPE = 1;

export const DEFAULT_FILTER_FIELDS = new Map<
  FilterFieldName<ProductListItem>,
  FilterItemData<ProductListItem>
>([
  [
    "Name",
    {
      FieldName: "Name",
      Method: 1,
      Value: null,
      ValueType: 1
    }
  ],
  [
    "BillTypeId",
    {
      FieldName: "BillTypeId",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  [
    "Currencies",
    {
      FieldName: "Currencies",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  [
    "FuncPayId",
    {
      FieldName: "FuncPayId",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  [
    "IsActive",
    {
      FieldName: "IsActive",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
]);

export const DEFAULT_SORT_FIELD_NAME: FilterFieldName<ProductListItem> =
  "Name";

export const DEFAULT_SORT_DIRECTION: SortDirection = 1;

export const BILL_TYPES = new Map([
  [5, "Cчета зарплатных программ"],
  [4, "Счета займов"],
  [8, "Счета исполнения заявок"],
  [2, "Счета паенакоплений"],
  [9, "Счета пенсионных/детских накоплений"],
  [7, "Счета привлечения средств"],
  [3, "Счета сберегательных программ"],
  [1, "Текущие счета"]
]);

export const MESSAGES = {
  createdSuccess: "Продукт успешно создан",
  updateSuccess: "Данные продукта обновлены"
};
