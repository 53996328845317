import { BillDepositListItem, FilterFieldName, FilterItemData, SortDirection } from "../types";

export const AUTOCOMPLETE_FILTER_FIELDS = [
];

export const BILL_CURRENT_FIELD_LABELS = new Map<keyof BillDepositListItem, string>([
  ["Id", "Номер операции"],
  ["Date", "Дата"],
  ["Type", "Операция"],
  ["Direction", "Тип операции"],
  ["BillCodeFrom", "Счет списания"],
  ["BillCodeTo", "Счет пополнения"],
  ["Total", "Сумма операции"],
  ["CurrentBalance", "Баланс"],
]);

export const DEFAULT_FILTER_FIELDS = new Map<
  FilterFieldName<BillDepositListItem>,
  FilterItemData
  >([
  [
    "Id",
    {
      FieldName: "Id",
      Method: 4,
      Value: null,
      ValueType: 2
    }
  ],
  [
    "Date",
    {
      FieldName: "Date",
      Value: null,
      Method: 4,
      ValueType: 4
    }
  ], [
    "Type",
    {
      FieldName: "Type",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  [
    "Direction",
    {
      FieldName: "Direction",
      Value: null,
      Method: 4,
      ValueType: 3
    }
  ],
  [
    "BillCodeFrom",
    {
      FieldName: "BillCodeFrom",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "BillCodeTo",
    {
      FieldName: "BillCodeTo",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "Total",
    {
      FieldName: "Total",
      Value: null,
      Method: 4,
      ValueType: 2
    }
  ],
  [
    "CurrentBalance",
    {
      FieldName: "CurrentBalance",
      Value: null,
      Method: 4,
      ValueType: 2
    }
  ]
]);

export const DEFAULT_SORT_DIRECTION: SortDirection = 2;

export const DEFAULT_SORT_FIELD_NAME: FilterFieldName<BillDepositListItem> =
  "Id";
