import BatchOperations, { BatchOperationsProps } from './BatchOperations';
import { connect } from "react-redux";
import { AppState } from "../../../../store";
import {
  CurrencyId,
  getBatchOperationsByCurrency, getOperationsByCurrencyRequest,
} from "../../../../store/batchOperations";
import { bindActionCreators, Dispatch } from "redux";

const mapStateToProps = (state: AppState, props: BatchOperationsProps) => {
  return {
    paymentRequests: getBatchOperationsByCurrency(state, Number(props.match.params.currency) as CurrencyId)
  }
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getOperationsByCurrencyRequest,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BatchOperations);
