import * as React from "react";
import { ClientsContext } from "../../pages/Client";
import { MAIN_FORM_FIELDS, REQUIRED_FIELDS } from "../../constants";
import { FormColumn, FormWrapper } from "../Views";
import Form from "../../../../components/PartnersAndClients/Form";
import FormItem from "../../../../components/PartnersAndClients/FormItem";
import Panel from "../../../../components/Panel";

export default class MainTab extends React.PureComponent {
  public render() {
    return (
      <ClientsContext.Consumer>
        {({
          editing,
          userData,
          checkList,
          handleChange = () => void 0,
          handleChangeCheckList = () => void 0,
          errors,
          valueLabels,
        }) => (
          <React.Fragment>
            {checkList && <Panel type="primary">
              <FormWrapper>
              <FormColumn>
                <FormItem
                  key={'IsEntryFeePaid'}
                  disabled={!editing}
                  fieldName={'IsEntryFeePaid'}
                  data={checkList}
                  label={'Оплата вступительного и паевого взносов'}
                  isRequired={false}
                  error={false}
                  valueLabels={valueLabels}
                  onChange={handleChangeCheckList}
                />
                <FormItem
                  key={'SecurityServiceStatus'}
                  disabled={!editing}
                  fieldName={'SecurityServiceStatus'}
                  data={checkList}
                  label={'Проверка службой безопасности'}
                  isRequired={false}
                  error={false}
                  valueLabels={valueLabels}
                  onChange={handleChangeCheckList}
                />
              </FormColumn>
              <FormColumn>
                <FormItem
                  key={'EntryFeeComment'}
                  disabled={!editing}
                  fieldName={'EntryFeeComment'}
                  data={checkList}
                  label={'Комментарий'}
                  isRequired={false}
                  error={false}
                  valueLabels={valueLabels}
                  onChange={handleChangeCheckList}
                />
              </FormColumn>
              </FormWrapper>
            </Panel>}
            <FormWrapper>
            {userData &&
              MAIN_FORM_FIELDS.map((fieldSet, index) => (
                <FormColumn key={index}>
                  <Form
                    data={userData}
                    fields={fieldSet}
                    disabled={!editing}
                    onChange={handleChange.bind(null, null)}
                    requiredFields={REQUIRED_FIELDS.get("UserData")}
                    requiredFieldsError={errors.get("UserData")}
                    valueLabels={valueLabels}
                  />
                </FormColumn>
              ))}
          </FormWrapper>
          </React.Fragment>
        )}
      </ClientsContext.Consumer>
    );
  }
}
