import { ServerResponse, Country, City, Region } from "./types";

export function serializeCountries(data: ServerResponse): Country[] {
  return data.map(item => ({
    id: item.Value,
    label: item.Text,
    regions: []
  }));
}

export function serializeRegions(data: ServerResponse): Region[] {
  return data.map(item => ({
    id: item.Value,
    label: item.Text,
    cities: []
  }));
}

export function serializeCities(data: ServerResponse): City[] {
  return data.map(item => ({
    id: item.Value,
    label: item.Text
  }));
}
