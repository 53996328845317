import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Form, FormLine, EditingControlsContainer } from "../components/Views";
import {
  SMS_LIST_FIELDS,
  SMS_FIELDS_LABELS,
  RECIPIENTS_GROUPS,
  REQUIRED_CREATION_FIELDS,
  MESSAGES,
  STATUS_LABELS
} from "../constants";
import { SmsInterface, RecipientsGroupType } from "../types";
import Input from "../../../components/FormControls/Input";
import DatePicker from "../../../components/DatePicker/DatePicker";
import moment from "moment";
import {
  Select,
  SelectOption,
  Textarea
} from "../../../components/FormControls/FormControls";
import ApiClient from "../../../services/api";
import Button from "../../../components/Button/Button";
import { toast } from "react-toastify";
import { isEqual } from "lodash";
import { formatValue } from "../utils";
import { ArrowLeft } from "styled-icons/fa-solid/ArrowLeft";
import Link from "../../../components/Link";
import SectionHeader from "../../../components/SectionHeader";
import { FormLabel } from "../../../components/FormLabel";
import { showErrorToast } from "../../../components/ErrorToast";
import Helmet from 'react-helmet';

export type SmsDetailsFields = Partial<SmsInterface>;

export interface SmsDetailsProps
  extends RouteComponentProps<
    { mode: "edit" | "create"; id?: string },
    any,
    { afterCreating?: boolean }
  > {}

export interface SmsDetailsState {
  cachedDetails?: SmsInterface;
  details: SmsDetailsFields;
  loading: boolean;
  formErrors: Array<[keyof SmsInterface, string]>;
  createdId: string | null;
  isCanceled: boolean;
}

export default class SmsDetails extends React.Component<
  SmsDetailsProps,
  SmsDetailsState
> {
  public state: SmsDetailsState = {
    details: {
      Title: "",
      Text: "",
      RecipientsGroup: 1
    },
    loading: false,
    formErrors: [],
    createdId: null,
    isCanceled: false
  };

  public componentDidMount() {
    this.handleNavigate();
  }

  public componentDidUpdate(prevProps: SmsDetailsProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handleNavigate();
    }
  }

  public render() {
    const isChanged =
      this.state.cachedDetails &&
      !isEqual(this.state.details, this.state.cachedDetails);

    return (
      <div>
        <Helmet >
          <title>SMS рассылки</title>
        </Helmet>
        <SectionHeader
          heading={this.getHeading()}
          link={this.renderBackLink()}
        />
        <Form>
          {SMS_LIST_FIELDS.filter(
            item => !(item === "IsSended" && this.isCreate())
          ).map(field => (
            <FormLine key={field}>
              <FormLabel
                width="33%"
                required={REQUIRED_CREATION_FIELDS.includes(field)}
              >
                {SMS_FIELDS_LABELS[field]}
              </FormLabel>
              {this.getFormComponent(field)}
            </FormLine>
          ))}
          {!this.state.isCanceled && (
            <FormLine>
              {this.isCreate() ? (
                <Button
                  disabled={
                    !!this.state.formErrors.length || this.state.loading
                  }
                  onClick={this.submitForm}
                  type="success"
                >
                  Создать
                </Button>
              ) : (
                !this.state.details.IsSended && (
                  <EditingControlsContainer>
                    <Button
                      onClick={this.submitForm}
                      disabled={!isChanged || this.state.loading}
                      type="success"
                    >
                      Сохранить
                    </Button>
                    {isChanged && (
                      <Button
                        disabled={this.state.loading}
                        onClick={this.cancelChanges}
                      >
                        Отменить изменения
                      </Button>
                    )}
                    <Button
                      onClick={this.deleteSelf}
                      disabled={this.state.loading}
                      type="error"
                    >
                      Отменить рассылку
                    </Button>
                  </EditingControlsContainer>
                )
              )}
            </FormLine>
          )}
        </Form>
      </div>
    );
  }

  private isCreate = () => this.props.match.params.mode === "create";

  private getData = async (idFromUrl: string) => {
    this.setState({
      loading: true
    });

    try {
      const id = parseInt(idFromUrl, 10);
      const result = await ApiClient.getSmsDetails(id);
      this.setState({
        cachedDetails: result,
        details: result,
        isCanceled: !result.IsSended && result.ReleaseTime === null
      });
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({
        loading: false
      });
    }
  };

  private getHeading = () => {
    if (this.isCreate()) {
      return "Новая рассылка";
    }

    return `Рассылка ${this.props.match.params.id}`;
  };

  private getFormComponent = (field: keyof SmsInterface) => {
    const rawValue = this.state.details[field];
    const formatedValue = formatValue(field, rawValue || "");
    const error = this.state.formErrors.find(item => item[0] === field);
    const { IsSended } = this.state.details;

    switch (field) {
      case "ReleaseTime":
        const nowMoment = new Date();
        const momentValue =
          typeof rawValue === "string" ? new Date(rawValue) : null;

        return (
          <DatePicker
            minDate={nowMoment}
            selected={momentValue}
            value={formatedValue || "сейчас"}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Время"
            todayButton={"Сегодня"}
            onChange={this.handleChangeTime}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            error={!!error}
            disabled={IsSended || this.state.loading || this.state.isCanceled}
          />
        );

      case "RecipientsGroup":
        return (
          <Select
            value={this.state.details.RecipientsGroup || 1}
            onChange={this.handleChangeRecipientsGroup}
            disabled={IsSended || this.state.loading || this.state.isCanceled}
          >
            {[...RECIPIENTS_GROUPS].map(([key, label]) => (
              <SelectOption key={key} value={key}>
                {label}
              </SelectOption>
            ))}
          </Select>
        );

      case "IsSended":
        return (
          <Input
            value={
              this.state.isCanceled
                ? STATUS_LABELS.get("Canceled")
                : this.state.details.IsSended
                ? STATUS_LABELS.get("Sended")
                : STATUS_LABELS.get("Schedule")
            }
            name={field}
            onChange={this.handleChangeTextValue}
            style={{ width: 400 }}
            disabled
          />
        );

      case "Text":
        return (
          <Textarea
            value={formatedValue || ""}
            name={field}
            onChange={this.handleChangeTextValue}
            style={{ width: 400, height: 180 }}
            required
            error={!!error}
            disabled={IsSended || this.state.loading || this.state.isCanceled}
            rows={3}
            disableResize
          />
        );

      default:
        return (
          <Input
            value={formatedValue || ""}
            name={field}
            onChange={this.handleChangeTextValue}
            style={{ width: 400 }}
            required
            error={!!error}
            disabled={IsSended || this.state.loading || this.state.isCanceled}
          />
        );
    }
  };

  private handleChangeTextValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    this.setState(state => ({
      formErrors: [],
      details: {
        ...state.details,
        [name]: value
      }
    }));
  };

  private handleChangeRecipientsGroup = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = parseInt(event.target.value as string, 10);
    this.setState(state => ({
      details: {
        ...state.details,
        RecipientsGroup: value as RecipientsGroupType
      }
    }));
  };

  private submitForm = async () => {
    if (this.state.loading) {
      return;
    }
    const { details } = this.state;
    const isCreate = details.Id === undefined;
    const isValidForm = await this.isValidForm(details);

    if (isValidForm) {
      this.setState({ loading: true });

      try {
        const result:
          | { Id: number }
          | SmsInterface = await ApiClient.createOrUpdateSmsDistributions({
          ...this.state.details
        });

        const { Id } = result;

        if (isCreate) {
          this.handleCreate(Id as number);
        } else {
          this.handleUpdate(result as SmsInterface);
        }
      } catch (error) {
        showErrorToast(error);
      } finally {
        this.setState({ loading: false });
      }
    } else {
      const releaseTimeError = this.state.formErrors.find(
        item => item[0] === "ReleaseTime"
      );
      toast(MESSAGES.formErrors, {
        type: toast.TYPE.ERROR
      });

      if (releaseTimeError) {
        toast(releaseTimeError[1], { type: toast.TYPE.ERROR });
      }
    }
  };

  private isValidForm = (data: SmsDetailsState["details"]) => {
    const formErrors: Array<[keyof SmsInterface, string]> = [];
    const { Title, Text, ReleaseTime } = data;

    if (!Title) {
      formErrors.push(["Title", "Поле не может быть пустым"]);
    }

    if (!Text) {
      formErrors.push(["Text", "Поле не может быть пустым"]);
    }

    if (ReleaseTime) {
      const momentReleaseTime = moment(ReleaseTime as string);
      const nowMoment = moment();

      if (momentReleaseTime.isValid() && nowMoment.isAfter(momentReleaseTime)) {
        formErrors.push([
          "ReleaseTime",
          `Укажите время позже чем ${nowMoment.format(
            DatePicker.prettyDateTimeFormat
          )}`
        ]);
      }
    }

    if (formErrors.length) {
      this.setState({
        formErrors
      });
      return Promise.resolve(false);
    } else {
      Promise.resolve(true);
    }

    return Promise.resolve(formErrors.length === 0);
  };

  private handleChangeTime = (date: Date) => {
    const value = date.toISOString();

    this.setState(state => ({
      details: {
        ...state.details,
        ReleaseTime: value
      }
    }));
  };

  private cancelChanges = () => {
    if (this.state.cachedDetails) {
      this.setState(state => ({
        details: state.cachedDetails || state.details,
        formErrors: []
      }));
    }
  };

  private deleteSelf = async () => {
    const { Id, IsSended } = this.state.details;

    if (Id === undefined || IsSended) {
      return;
    }

    this.setState({ loading: true });

    try {
      const { Result } = await ApiClient.deleteSmsDistribution(Id);

      this.setState({ loading: false }, () => {
        Result
          ? this.handleDelete()
          : showErrorToast({ Message: MESSAGES.deleteFail });
      });
    } catch (error) {
      showErrorToast(error);
      this.setState({ loading: false });
    }
  };

  private handleCreate = (id: number) => {
    this.props.history.push(`/tools/sms/edit/${id}`, {
      afterCreating: true
    });
  };

  private handleUpdate = (details: SmsInterface) => {
    toast(MESSAGES.updateSuccess, {
      type: toast.TYPE.SUCCESS
    });
    this.setState({
      loading: false,
      cachedDetails: details,
      details
    });
  };

  private handleNavigate = () => {
    if (this.isCreate()) {
      return;
    }

    if (this.props.location.state && this.props.location.state.afterCreating) {
      toast(MESSAGES.createdSuccess, {
        type: toast.TYPE.SUCCESS,
        onClose: () => {
          this.props.history.replace({
            ...this.props.history.location,
            state: {
              afterCreating: false
            }
          });
        }
      });
    }

    if (this.props.match.params.id) {
      this.getData(this.props.match.params.id);
    }
  };

  private handleDelete = () => {
    this.goToList({ afterDelete: true });
  };

  private goToList = (state: any) => {
    this.props.history.replace("/tools/sms", state);
  };

  private renderBackLink = () => (
    <Link onClick={this.goToList.bind(undefined, undefined)}>
      <ArrowLeft size={13} /> Вернуться к списку
    </Link>
  );
}
