import { ServerError } from "./types";

export const serializeError = (error: ServerError | string | Error) => {
  if (error instanceof Error) {
    return error;
  }

  if (typeof error === "string") {
    return {
      message: error
    };
  }

  if (error.InnerException && error.InnerException.Message) {
    return {
      message: error.InnerException.Message,
      verbose:
        error.InnerException.MessageDetail ||
        error.InnerException.ExceptionMessage
    };
  }

  return {
    message: error.Message,
    verbose: error.MessageDetail || error.ExceptionMessage
  };
};
