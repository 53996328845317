import { all, call, put, select, takeLatest, takeEvery, take, race } from 'redux-saga/effects';
import { ListServerResponse } from './types';
import {
  fetchListSuccess,
  fetchListError,
  downloadSuccess,
  downloadError,
  ActionTypes,
  execRequest,
  execError,
  deleteSuccess,
  deleteError,
  fetchListRequest,
  execSuccess, getCountNonExecutedSuccess, getCountNonExecutedError,
} from './actions';
import ApiClient from '../../services/api';
import { delay, requestDataToQuery } from '../../utils';
import { putError } from '../global/actions';
import { AnyAction } from 'redux';

function* fetchList(action: AnyAction) {
  if (action.type === ActionTypes.CHANGE_FILTERS && !action.meta) {
    return;
  }

  const apiRequest: (query?: string) => ListServerResponse = ApiClient.getPaymentRequests;

  try {
    const requestData = yield select(({ paymentRequests }) => paymentRequests.requestData);
    const result = yield call(apiRequest, requestDataToQuery(requestData));

    yield put(fetchListSuccess(result));
  } catch (error) {
    yield put(fetchListError(error));
    yield put(putError(error));
  }
}

function* downloadDocx(action: AnyAction) {
  const id = action.payload;
  try {
    yield call(ApiClient.downloadPaymentRequest, id);
    yield put(downloadSuccess);
  } catch (error) {
    yield put(downloadError);
    yield put(putError(error));
  }
}

function* downloadPdf(action: AnyAction) {
  const id = action.payload;
  try {
    yield call(ApiClient.printPaymentRequest, id);
    yield put(downloadSuccess);
  } catch (error) {
    yield put(downloadError);
    yield put(putError(error));
  }
}

function* execPaymentRequest(action: ReturnType<typeof execRequest>) {
  const id = action.payload;

  try {
    const result = yield call(ApiClient.execPaymentRequest, id);
    yield put(execSuccess(result));
  } catch (error) {
    yield put(execError);
    yield put(putError(error));
  }
}

function* deletePaymentRequest(action: ReturnType<typeof execRequest>) {
  const id = action.payload;

  try {
    yield call(ApiClient.deletePaymentRequest, id);
    yield put(deleteSuccess);
    yield put(fetchListRequest);
  } catch (error) {
    yield put(putError(error));
    yield put(deleteError);
  }
}

function* getNonExecutedCount() {
  try {
    const result = yield call(ApiClient.getNonExecutedCount);
    yield put(getCountNonExecutedSuccess(result.Count || 0));
  } catch (error) {
    yield put(putError(error));
    yield put(getCountNonExecutedError(error));
  }
}

function* watchFetchList() {
  yield takeLatest(
    [
      ActionTypes.FETCH_LIST_REQUEST,
      ActionTypes.CHANGE_LIST_REQUEST_DATA,
      ActionTypes.RESET_ALL_FILTERS,
      ActionTypes.RESET_FILTER,
      ActionTypes.CHANGE_FILTERS,
      ActionTypes.CHANGE_PAGE,
      ActionTypes.CHANGE_PAGE_SIZE,
    ],
    fetchList,
  );
}

function* pollSaga() {
  while (true) {
    try {
      yield getNonExecutedCount();

      yield call(delay, 60000);
    } catch (error) {
      yield put({
        type: ActionTypes.GET_COUNT_NON_EXECUTED_STOP,
        error
      });
    }
  }
}

function* pollSagaWatch() {
  while (true) {
    const action = yield take(ActionTypes.GET_COUNT_NON_EXECUTED_REQUEST);
    // @ts-ignore
    yield race([call(pollSaga, action), take(ActionTypes.GET_COUNT_NON_EXECUTED_STOP)]);
  }
}

function* watchDownloadDocx() {
  yield takeEvery(ActionTypes.DOCX_DOWNLOAD_REQUEST, downloadDocx);
}

function* watchDownloadPdf() {
  yield takeEvery(ActionTypes.PDF_DOWNLOAD_REQUEST, downloadPdf);
}

function* watchExecPaymentRequest() {
  yield takeEvery(ActionTypes.EXEC_REQUEST, execPaymentRequest);
}

function* watchDeletePaymentRequest() {
  yield takeEvery(ActionTypes.DELETE_REQUEST, deletePaymentRequest);
}

function* paymentRequestsSaga() {
  yield all([
    pollSagaWatch(),
    watchFetchList(),
    watchDownloadDocx(),
    watchDownloadPdf(),
    watchExecPaymentRequest(),
    watchDeletePaymentRequest()
  ]);
}

export default paymentRequestsSaga;
