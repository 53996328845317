import { PushInterface, RecipientsGroupType, TypePushLabels } from "../types";

export const PUSH_LIST_FIELDS: Array<keyof PushInterface> = [
  "Title",
  "CreationTime",
  "Users",
  "TypeId",
  "IsSended"
];

export const PUSH_LIST_CREATEFIELDS: Array<keyof PushInterface> = [
  "TypeId",
  "PublicationShowTypeID",
  "PublicationID",
  "ButtonText",
  "ButtonLink",
  "Users",
  "Title",
  "Body",
  "PageId"
];

export const DEFAULT_FIELDS: Array<keyof PushInterface> = [
  "Title",
  "ShowTitle",
  "Body",
  "Users",
  "TypeId",
];

export const NEWS_FIELDS: Array<keyof PushInterface> = [
  "PublicationShowTypeID",
];

export const OPEN_PAGE_FIELDS: Array<keyof PushInterface> = [
  "PageId",
];

export const OPEN_NEWS_FIELDS: Array<keyof PushInterface> = [
  "PublicationID",
  "ButtonText",
  "ButtonLink",
];

export const OPEN_TEXT_FIELDS: Array<keyof PushInterface> = [
  "PayloadTitle",
  "PayloadBody",
];

export const PUSH_FIELDS_LABELS: Partial<Record<keyof PushInterface, string>> = {
  Title: "Название уведомления",
  CreationTime: "Дата создания",
  Users: "Кому",
  TypeId: "Тип уведомления",
  IsSended: "Статус",
  PublicationID: "Id публикации / новости",
  ButtonText: "Текст кнопки",
  ButtonLink: "Ссылка за кнопкой",
  PageId: "Страница в приложении",
  Body: "Содержание уведомления",
  PublicationShowTypeID: "Действие при нажатии",
  PayloadTitle: "Тайтл попапа",
  PayloadBody: "Текст попапа",
  ShowTitle: "Включать тайтл пуша",
};

export const STATUS_LABELS = new Map([
  [false, "Отправлено"],
  [true, "Создано"]
]);

export const PUBLICATIONS_SHOW_TYPE_ID = new Map([
  [1, "Открыть новость"],
  [2, "Отобразить текст"]
]);

export const TYPE_LABELS = new Map<TypePushLabels, string>([
  // [1, "Чат"],
  [2, "Новость"],
  [3, "Открыть страницу в приложении"],
  // [4, "Подтверждение"]
]);

export const PAGE_LABELS = new Map<string, string>([
  ['1', "Чат"],
  ['2', "Платежи"],
  ['2.1', "Перевод на зп карту"],
  ['2.2', "Перевод между счетами"],
  ['2.3', "Перевод другому пайщику"],
  ['2.4', "Погашение займа"],
  ['2.5', "Пополнение вклада"],
  ['2.6', "Открыть вклад"],
  ['2.7', "Погашение займа"],
  ['3', "Сканирование чека"],
  ['4', "Операции"],
  ['5', "Список партнеров"],
  ['6', "Просмотр счетов"],
  ['7', "Настройки (Профиль)"],
  ['8', "Распоряжения"],
]);

export const RECIPIENTS_GROUPS = new Map<RecipientsGroupType, string>([
  [1, "Все"],
  [2, "Только мужчины"],
  [3, "Только женщины"]
]);

export const REQUIRED_CREATION_FIELDS: Array<keyof PushInterface> = [
  "Title",
  "Body",
  "Users"
];

export const MESSAGES = {
  formErrors: "Исправьте ошибки в форме",
  createdSuccess: "PUSH-уведомление создано",
  updateSuccess: "PUSH-уведомление обновлено",
  deleteSuccess: "PUSH-уведомление отменено",
  deleteFail: "Не удалось отменить рассылку"
};

export const ALIGN_CELL_BY_FIELD = new Map<
  keyof PushInterface,
  React.TdHTMLAttributes<HTMLTableCellElement>["align"]
>([["IsSended", "center"], ["Title", "center"]]);
