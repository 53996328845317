export const menuItems = [
  {
    icon: 'comment',
    title: 'Сообщения',
    url: '/chats',
  },
  {
    icon: 'calendar',
    title: 'Календарь',
    url: '/Reports/Calendar',
  },
  {
    icon: 'calculator',
    title: 'Калькулятор',
    submenu: [
      { title: 'Калькулятор СП', url: '/Calculator/Deposit' },
      { title: 'Калькулятор Займа', url: '/Calculator/Loan' },
      { title: 'Калькулятор Инвестиционных займов', url: '/Calculator/Raisings' },
      { title: 'Калькулятор Детской программы', url: '/Calculator/Child' },
      { title: 'Калькулятор Пенсионной программы', url: '/Calculator/Pension' },
    ],
  },
  {
    icon: 'user',
    title: 'Заявки',
    url: '/applications',
  },
  {
    icon: 'users',
    title: 'Клиенты',
    url: '/client',
  },
  {
    icon: 'money',
    title: 'Счета',
    submenu: [
      { title: 'Текущие счета', url: '/Bill/Currents' },
      { title: 'Зарплатная программа', url: '/Bill/Salaries' },
      { title: 'Сберегательные программы', url: '/Bill/Deposits' },
      { title: 'Займы', url: '/Bill/Loans' },
      { title: 'Привлечение средств', url: '/Bill/Raisings' },
    ],
  },
  {
    icon: 'bar-chart',
    title: 'Отчеты',
    submenu: [
      {
        title: 'Отчеты по ЗП',
        submenu: [
          { title: 'Сводный реестр', url: '/Reports/SalaryAnalytics' },
          { title: 'Начисления', url: '/Reports/SalaryAnalytics/PeriodReport' },
          { title: 'Начисления помесячно', url: '/Reports/SalaryAnalytics/ReportMonthly' },
        ],
      },
      {
        title: 'Отчеты по СП',
        submenu: [
          { title: 'Сводный реестр', url: '/Reports/DepositAnalytics' },
          { title: 'Начисления', url: '/Reports/DepositAnalytics/PeriodReport' },
          { title: 'Начисления помесячно', url: '/Reports/DepositAnalytics/ReportMonthly' },
        ],
      },
      {
        title: 'Отчеты по ИЗ',
        submenu: [
          { title: 'Сводный реестр', url: '/Reports/RaisingAnalytics' },
          { title: 'Начисления', url: '/Reports/RaisingAnalytics/PeriodReport' },
          { title: 'Начисления помесячно', url: '/Reports/RaisingAnalytics/ReportMonthly' },
        ],
      },
      {
        title: 'Отчеты по займам',
        submenu: [
          { title: 'Сводный реестр', url: '/Reports/LoanAnalytics' },
          { title: 'Начисления', url: '/Reports/LoanAnalytics/PeriodReport' },
          { title: 'Начисления помесячно', url: '/Reports/LoanAnalytics/ReportMonthly' },
          { title: 'Отчеты о просрочках', url: '/Reports/LoanAutoPayReports' },
          { title: 'Отчеты о проверках', url: '/Reports/LoanCheckBalanceReports' },
          { title: 'Просрочки', url: '/Reports/LoanLates' },
        ],
      },
      {
        title: 'Аналитика',
        url: '/tools/analytics',
      },
    ],
  },
  {
    icon: 'gear',
    title: 'Система',
    submenu: [
      { title: 'Финмониторинг', url: '/tools/finmonitoring' },
      { title: 'Операции', url: '/tools/OperationStaff' },
      { title: 'Ставка рефинансирования', url: '/OtherAction/ChangeRefinance' },
      { title: 'Ставка МФ', url: '/OtherAction/MFPercent' },
      { title: 'Публикации', url: '/tools/publications' },
      { title: 'PUSH уведомления', url: '/tools/push' },
      { title: 'SMS-рассылки', url: '/tools/sms' },
      {
        title: 'Калькуляторы на сайте',
        url: '/tools/calendars',
      },
      { title: 'Продукты', url: '/Product' },
      { title: 'Код валют', url: '/CatalogInform/CurrenciesCatalog' },
      { title: 'Типы счетов', url: '/CatalogInform/BillsCatalog' },
      { title: 'Типы операций', url: '/CatalogInform/OperationView' },
      { title: 'Тарифы', url: '/Settings/Tariffs' },
      {
        title: 'Пользователи',
        submenu: [
          { title: 'Пользователи', url: '/SecurityUser/List' },
          { title: 'Группы', url: '/SecurityGroup/List' },
        ],
      },
      {
        title: 'Шаблоны',
        submenu: [
          { title: 'Печатные формы', url: '/FileCollection' },
          { title: 'СМС уведомления', url: '/' },
          { title: 'Почта', url: '/' },
        ],
      },
      { title: 'История действий', url: '/Audit' },
      { title: 'CheckList', url: '/CheckList' },
    ],
  },
  {
    icon: 'book',
    title: 'Запросы на списание средств',
    url: '/PaymentRequest',
  },
];
