import { ReportListItem, FilterFieldName, FilterItemData, SortDirection } from "../types";

export const AUTOCOMPLETE_FILTER_FIELDS = [
  "PartnerNumber"
];

export const LATE_CURRENT_FIELD_LABELS = new Map<keyof ReportListItem, string>([
  ["PartnerNumber", "Номер пайщика"],
  ["BillCode", "Номер счета"],
  ["PartnerName", "ФИО"],
  ["SumLate", "Сумма задолженности"],
  ["CurrencyId", "Валюта"],
]);

export const DEFAULT_FILTER_FIELDS = new Map<
  FilterFieldName<ReportListItem>,
  FilterItemData
  >([
  [
    "PartnerNumber",
    {
      FieldName: "PartnerNumber",
      Method: 1,
      Value: null,
      ValueType: 1
    }
  ],
  [
    "BillCode",
    {
      FieldName: "BillCode",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "PartnerName",
    {
      FieldName: "PartnerName",
      Method: 1,
      Value: null,
      ValueType: 1
    }
  ],
  [
    "SumLate",
    {
      FieldName: "SumLate",
      Value: null,
      Method: 4,
      ValueType: 7
    }
  ],
  [
    "CurrencyId",
    {
      FieldName: "CurrencyId",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
]);

export const DEFAULT_SORT_DIRECTION: SortDirection = 1;

export const DEFAULT_SORT_FIELD_NAME: FilterFieldName<ReportListItem> =
  "PartnerName";
