interface BaseEntity {
  id: number;
  label: string;
}

export interface City extends BaseEntity {}

export interface Region extends BaseEntity {
  cities: City[];
}

export interface Country extends BaseEntity {
  regions: Region[];
}

export interface RegionsState {
  readonly loading: boolean;
  readonly countries: Country[];
  readonly streets: string[];
  readonly allCountries: CountryType[];
}

export const RegionsActionTypes = {
  FETCH_COUNTRIES_REQUEST: "@@regions/FETCH_COUNTRIES_REQUEST",
  FETCH_COUNTRIES_SUCCESS: "@@regions/FETCH_COUNTRIES_SUCCESS",
  FETCH_COUNTRIES_ERROR: "@@regions/FETCH_COUNTRIES_ERROR",
  SAVE_COUNTRIES_SUCCESS: "@@regions/SAVE_COUNTRIES_SUCCESS",

  FETCH_REGIONS_REQUEST: "@@regions/FETCH_REGIONS_REQUEST",
  FETCH_REGIONS_SUCCESS: "@@regions/FETCH_REGIONS_SUCCESS",
  FETCH_REGIONS_ERROR: "@@regions/FETCH_REGIONS_ERROR",

  FETCH_CITIES_REQUEST: "@@regions/FETCH_CITIES_REQUEST",
  FETCH_CITIES_SUCCESS: "@@regions/FETCH_CITIES_SUCCESS",
  FETCH_CITIES_ERROR: "@@regions/FETCH_CITIES_ERROR",

  FETCH_STREETS_REQUEST: "@@regions/FETCH_STREETS_REQUEST",
  FETCH_STREETS_SUCCESS: "@@regions/FETCH_STREETS_SUCCESS",
  FETCH_STREETS_ERROR: "@@regions/FETCH_STREETS_ERROR"
};

export type ServerResponse = Array<{
  Value: number;
  Text: string;
}>;

export type StreetResponse = string[];

export interface CountryType {
  PhoneCode: string;
  CountryCode: string;
  Value: number;
  Text: string;
}
