import * as React from 'react';
import Modal from '../../components/Modal';
import Button from '../../components/Button/Button';
import { Paragraph, Strong } from '../../components/Typography';
import styled from 'styled-components';
import ApiClient from '../../services/api';

export interface RemoveDialogProps {
  onRemoveOperation?: (id: number) => void;
}

export interface RemoveDialogState {
  isOpen: boolean;
  operationId: number | null;
  result: string | null;
  error: boolean;
  loading: boolean;
}

const ButtonsContainer = styled.div`
  display: flex;
  margin: 10px 0;
`;

const ButtonWrapper = styled.div`
  margin-right: 10px;
`;

export default class RemoveDialog extends React.Component<RemoveDialogProps, RemoveDialogState> {
  public state: RemoveDialogState = {
    isOpen: false,
    operationId: null,
    result: null,
    error: false,
    loading: false,
  };

  public render() {
    return (
      <Modal
        header="Подтвердите отмену операции"
        isOpen={this.state.isOpen}
        onBackgroundClick={this.close}
        onEscapeKeydown={this.close}
        afterClose={this.handleClose}
      >
        {this.renderContent()}
      </Modal>
    );
  }

  public open = (operationId: number) => {
    this.setState({ isOpen: true, operationId });
  };

  public close = () => {
    this.setState({ isOpen: false });
  };

  private renderContent = () => {
    const { result, error, operationId } = this.state;

    if (!result && !error && typeof operationId === 'number') {
      return this.renderQuestion(operationId);
    }

    return this.renderResult(result, error);
  };

  private renderQuestion = (operationId: number) => (
    <div>
      <Paragraph>
        Вы действительно хотите отменить операцию <Strong>№{operationId}</Strong>?
      </Paragraph>
      <ButtonsContainer>
        <ButtonWrapper>
          <Button loading={this.state.loading} onClick={this.handleSubmitClick} type="primary">
            Да
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button onClick={this.close} type="default">
            Нет
          </Button>
        </ButtonWrapper>
      </ButtonsContainer>
    </div>
  );

  private renderResult = (result: string | null, error: boolean | null) => (
    <div>
      <Paragraph error={!!error} dangerouslySetInnerHTML={{ __html: result || '' }} />
      <ButtonWrapper>
        <Button onClick={this.close} type="primary">
          OK
        </Button>
      </ButtonWrapper>
    </div>
  );

  private handleClose = () => {
    this.setState({
      isOpen: false,
      operationId: null,
      result: null,
      error: false,
      loading: false,
    });
  };

  private handleSubmitClick = () => {
    const { operationId, loading } = this.state;

    if (loading) {
      return;
    }

    if (operationId !== null) {
      this.removeOperation(operationId);
    }
  };

  private removeOperation = async (operationId: number) => {
    this.setState({ loading: true });

    try {
      const result: { Message: string } = await ApiClient.removeOperation(operationId);
      this.setState({ result: result.Message });
      if (this.props.onRemoveOperation) {
        this.props.onRemoveOperation(operationId);
      }
    } catch (error) {
      this.setState({
        result: error.response.data.Message,
        error: true,
      });
    } finally {
      this.setState({ loading: false });
    }
  };
}
