import React, { Component } from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';

import TopItem from './TopItem';
import { Provider } from './context';

export default class Menu extends Component {
  render() {
    const { items = [], activeId } = this.props;
    return (
      <Provider value={{ activeId }}>
        <Container>
          {items.map(item => <TopItem {...item} />)}
        </Container>
      </Provider>
    );
  }
}

const Container = styled(Flex).attrs({ justifyStart: true, full: true, row: true })`
  z-index: 1000;
`;
