import { createAction } from "typesafe-actions";
import { Message } from "./types";

const prefix = "CHAT";
export const ActionTypes = {
  CHATS_GET_ALL_REQUEST: `@@${prefix}/CHATS_GET_ALL_REQUEST`,
  CHATS_GET_ALL_SUCCESS: `@@${prefix}/CHATS_GET_ALL_SUCCESS`,
  CHATS_GET_ALL_ERROR: `@@${prefix}/CHATS_GET_ALL_ERROR`,

  GET_ALL_CHAT_MESSAGE_REQUEST: `@@${prefix}/GET_ALL_CHAT_MESSAGE_REQUEST`,
  GET_ALL_CHAT_MESSAGE_SUCCESS: `@@${prefix}/GET_ALL_CHAT_MESSAGE_SUCCESS`,
  GET_ALL_CHAT_MESSAGE_ERROR: `@@${prefix}/GET_ALL_CHAT_MESSAGE_ERROR`,

  ADD_CHAT_MESSGE: `@@${prefix}/ADD_CHAT_MESSGE`,

  UPDATE_UNREADED_MESSAGES_COUNT: `@@${prefix}/UPDATE_UNREADED_MESSAGES_COUNT`,
  POOL_START: `@@${prefix}/POOL_START`,
  POOL_STOP: `@@${prefix}/POOL_STOP`,

  READ_CHAT_MESSAGES: `@@${prefix}/READ_CHAT_MESSAGES`,
  READ_CHAT_MESSAGES_SUCCESS: `@@${prefix}/READ_CHAT_MESSAGES_SUCCESS`,
};

export const getAllChatsRequest = createAction(
  ActionTypes.CHATS_GET_ALL_REQUEST,
  resolve => () => resolve()
);

export const getAllChatsRequestSuccess = createAction(
  ActionTypes.CHATS_GET_ALL_SUCCESS,
  resolve => (data: Message[]) =>
    resolve(data)
);

export const getAllChatsRequestError = createAction(
  ActionTypes.CHATS_GET_ALL_ERROR,
  resolve => (error: any) =>
    resolve(error)
);

export const getAllChatMessageRequest = createAction(
  ActionTypes.GET_ALL_CHAT_MESSAGE_REQUEST,
  resolve => (chatId: number) => resolve(chatId)
);

export const getAllChatMessageSuccess = createAction(
  ActionTypes.GET_ALL_CHAT_MESSAGE_SUCCESS,
  resolve => (data: Message[]) =>
    resolve(data)
);

export const getAllChatMessageError = createAction(
  ActionTypes.GET_ALL_CHAT_MESSAGE_ERROR,
  resolve => (error: any) =>
    resolve(error)
);

export const addChatMessage = createAction(
  ActionTypes.ADD_CHAT_MESSGE,
  resolve => (data: Message) =>
    resolve(data)
);

export const updateUnreadCount = createAction(
  ActionTypes.UPDATE_UNREADED_MESSAGES_COUNT,
  resolve => (count: number) =>
    resolve(count)
);

export const readChatMessages = createAction(
  ActionTypes.READ_CHAT_MESSAGES,
  resolve => (chatId: number) =>
    resolve(chatId)
);

export const startPollingMessageCount = createAction(
  ActionTypes.POOL_START
);
