export const CURRENCY_RUB = 1;
export const CURRENCY_USD = 2;
export const CURRENCY_EUR = 3;

export const currencyTypes = {
  [CURRENCY_RUB]: 'RUB',
  [CURRENCY_USD]: 'USD',
  [CURRENCY_EUR]: 'EUR',
};

export default {
  RUB: CURRENCY_RUB,
  USD: CURRENCY_USD,
  EUR: CURRENCY_EUR,
};
