import React, { Fragment } from 'react';
import { isNaN, toNumber } from 'lodash';

import { Table, TableBody, TableCell, TableCellHead, TableHead, TableRow } from '../../../../../components/Table';
import { ProductCondition } from '../../../types';
import Button from '../../../../../components/Button/Button';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
// import FormField from './FormField';
import styled from "../../../../../styled/styled-components";
import { SimpleInput, Checkbox } from "../../../../../components/FormControls/FormControls";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const Input = styled(SimpleInput)`
  width: 100%;
  margin: 0 1rem;
`;

const PARTNER_CARD_FIELDS = new Map<string, string>([
  ['SumFrom', 'Сумма от'],
  ['SumTo', 'Сумма до'],
  ['PeriodFrom', 'Период от, дни'],
  ['PeriodTo', 'Период до, дни'],
  ['Percent', 'Проценты, %'],
  ['MemberPercent', 'ЧВ, %'],
  ['IsActive', 'Активный'],
]);

const PARTNER_CARD_FIELDS_LOAN = new Map<string, string>([
  ['SumFrom', 'Сумма от'],
  ['SumTo', 'Сумма до'],
  ['PeriodFrom', 'Период от, мес'],
  ['PeriodTo', 'Период до, мес'],
  ['Percent', 'Проценты, %'],
  ['MemberPercent', 'ЧВ, %'],
  ['IsActive', 'Активный'],
]);

export interface CardsTableProps {
  data: ProductCondition[];
  additionalData: any;
  editing: boolean;
  onChange: (cards: ProductCondition[], additionalData?: any) => void;
  billTypeId?: number;
}


export default function CardsTable(props: CardsTableProps) {
  const { data, editing, onChange, additionalData, billTypeId } = props;

  const confirmDialogRef = React.createRef<ConfirmDialog>();

  const deleteRow = (index: number) => () => {
    if (!data[index]) {
      return;
    }

    if (!confirmDialogRef.current) {
      return;
    }

    const deleteItem = () => {
      const newCards = data.filter(
        (_item, _index) => _index !== index
      );
      onChange(newCards, additionalData);
    };

    if (data[index].Id === null) {
      deleteItem();
    } else {
      confirmDialogRef.current.open(
        'Вы действительно хотите удалить выбранный элемент?',
        (allow: boolean) => allow && deleteItem()
      );
    }
  };

  const handleChangeConditionField = onChangeConditionField(data, onChange, additionalData);
  const handleChangeIsActive = onChangeIsActive(data, onChange, additionalData);

  const fields = billTypeId === 4 ? PARTNER_CARD_FIELDS_LOAN : PARTNER_CARD_FIELDS;

  return (
    <Fragment>
      <Table>
        <TableHead>
          <TableRow>
            {[...fields.entries()].map(([fieldName, label]) => (
              <TableCellHead key={fieldName}>
                {label}
              </TableCellHead>
            ))}
            {editing && <TableCellHead>Действие</TableCellHead>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={item.Id || -index} notActive={!item.IsActive}>
              {[...fields.entries()].filter(([fieldName, label]) => (fieldName !== 'IsActive')).map(([fieldName, label]) => (
                <TableCell key={fieldName} align="center">
                  {editing
                  ? (
                  <Container>
                    <Input
                      // @ts-ignore
                      onChange={handleChangeConditionField(index)(fieldName)}
                      // @ts-ignore
                      value={item[fieldName]}
                    />
                  </Container>
                  )
                  : (
                      // @ts-ignore
                  <span>{item[fieldName]}</span>
                  )}
                </TableCell>
              ))}
              <TableCell align="center">
                {editing
                  ? (
                    <Container>
                      <Checkbox
                        onChange={handleChangeIsActive(index)('IsActive')}
                        checked={item.IsActive}
                      />
                    </Container>
                  )
                  : (
                    <span>{item.IsActive ? "Активный" : "Неактивный"}</span>
                  )}
              </TableCell>
              {editing && item.Id === 0 && (
                <TableCell align="center">
                  <Button
                    onClick={deleteRow(index)}
                    type="success"
                  >
                    Удалить
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmDialog ref={confirmDialogRef} />
    </Fragment>
  );
}


export function onChangeConditionField(data: ProductCondition[], onChange: (cards: ProductCondition[], additionalData?: any) => void, additionalData?: any) {
  return (index: number) =>
    (key: keyof ProductCondition) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const isNumeric = (val: any) => {
            return !isNaN(toNumber(val));
          };
        const newData = [...data];
        const { value: val } = event.currentTarget;
        const value = val.replace('.', ',');
        if (['SumFrom', 'SumTo'].includes(key) && !isNumeric(value.replace(',', '.'))) {
          return;
        }
        // @ts-ignore
        newData[index][key] = value.replace('.', ',');
        onChange(newData, additionalData);
      };
}

export function onChangeIsActive(data: ProductCondition[], onChange: (cards: ProductCondition[], additionalData?: any) => void, additionalData?: any) {
  return (index: number) =>
    (key: 'IsActive') =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const newData = [...data];
        const { checked } = event.currentTarget;
        // @ts-ignore
        newData[index][key] = checked;
        onChange(newData, additionalData);
      };
}
