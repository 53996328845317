import * as React from 'react';
import { omit, isEmpty, isString, get, isArray, debounce } from 'lodash';
import FormControl from './components/FormControl';
import { CalculatorFormData, CalculatorsState } from "../../../../store/calculators";
import { NONE_VALUE } from "../../../../constants";
import { convertToUtc } from "../../../../utils";
import Button from "../../../../components/Button/Button";
import ErrorMessage from "../../../../modules/Errors/components/ErrorMessage";

import funcPay, { funcPayLoan, funcPayRaising } from "../../../../constants/funcPay";
import currencyCodes from "../../../../constants/currencyCodes";
import { LoanProduct, LoanProducts } from "../../../../store/forms";
import ApiClient from "../../../../services/api";
import styled, { css } from "../../../../styled/styled-components";
import { ifProp } from "styled-tools";
import { toast } from "react-toastify";

export interface CalculatorFormProps {
  fetchCalculatorDataRequest: (data: CalculatorFormData) => void;
  fetchCalculatorLoanDataRequest: (data: CalculatorFormData) => void;
  fetchCalculatorRaisingDataRequest: (data: CalculatorFormData) => void;
  type: string;
  loading: boolean;
}

interface WrapperProps {
  loading?: boolean;
}

const LoadingWrapper = styled.div<WrapperProps>`
    ${ifProp("loading", css`
      background-color: #eee;
  `)}
`;

export interface CalculateStateErrors {
  formErrors: {
    OpenDate?: string,
    CurrencyId?: string,
    Sum?: string,
    FuncPay?: string,
    Percent?: string,
    MemberPercent?: string,
    MonthCount?: string,
  },
  formValid: boolean,
  submited: boolean,
  printing: boolean,
  ProductId?: number,
  Products: any;
  loadingProducts: boolean;
}

const FuncPayByType: any = {
  'deposit': 1,
  'loan': 3,
  'raising': 21,
};

export default class CalculatorForm extends React.PureComponent<CalculatorFormProps & CalculatorsState> {
  public state: CalculatorFormData & CalculateStateErrors = {
    OpenDate: convertToUtc(new Date()).toISOString(),
    CurrencyId: 1,
    Sum: undefined,
    FuncPay: FuncPayByType[this.props.type],
    Percent: undefined,
    MemberPercent: undefined,
    MonthCount: undefined,
    formErrors: {},
    formValid: true,
    submited: false,
    loadingProducts: false,
    printing: false,
    ProductId: undefined,
    Products: [],
  };

  public render() {
    const { error, type } = this.props;
    const { OpenDate, CurrencyId, Sum, MonthCount, FuncPay, Percent, MemberPercent, formErrors, formValid, ProductId, loadingProducts } = this.state;
    const products = get(this.state, "Products", []) || [];
    const data = products.map((item: LoanProduct) => ([item.Text, item.Value]));
    return (
      <div>
        <FormControl
          onChange={e => {this.handleChange(e, 'CurrencyId')}}
          value={CurrencyId}
          data={Object.entries(currencyCodes)}
          label="Валюта"
          type="select"
        />
        <FormControl
          onChange={e => {this.handleChange(e, 'Sum')}}
          value={Sum}
          label="Сумма"
          error={formErrors.Sum}
        />
        <FormControl
          onChange={e => {this.handleChange(e, 'MonthCount')}}
          value={MonthCount}
          label={type === 'loan' ? 'Срок (месяцев)' : 'Срок (дней)' }
          error={formErrors.MonthCount}
        />
        <FormControl
          onChange={e => {this.handleChange(e,"ProductId")}}
          value={ProductId}
          data={isArray(products) && !isEmpty(products) ? data : [["Нет", "Нет"]]}
          label="Продукт"
          type="select"
        />
        <LoadingWrapper loading={loadingProducts}>
        <FormControl
          onChange={e => {this.handleChange(e, 'FuncPay')}}
          data={Object.entries(type === 'deposit' ? funcPay : type === 'raising' ? funcPayRaising : funcPayLoan)}
          value={FuncPay}
          label="График платежей"
          type="select"
          disabled
        />
        <FormControl
          onChange={e => {this.handleChange(e, 'Percent')}}
          value={Percent}
          label="Процентная ставка"
          error={formErrors.Percent}
          disabled
        />
        <FormControl
          onChange={e => {this.handleChange(e, 'MemberPercent')}}
          value={MemberPercent}
          label="Членские взносы"
          error={formErrors.MemberPercent}
          disabled
        />
        </LoadingWrapper>
        <FormControl
          onChange={this.handleChangeDate}
          value={OpenDate}
          label="Дата открытия"
          type="date"
        />
        <div className="d-print-none">
        <Button type="success" onClick={this.submitForm}>
          План расчета
        </Button>
        {' '}
        <Button type="success" onClick={this.printPage}>
          Печать
        </Button>
        </div>
        {(!formValid || error) && (
          <div className="alert alert-danger mt-3 d-print-none">
            <ErrorMessage errorData={{ message: (error && error.Message) || 'Ошибка', verbose: (error && error.ExceptionMessage) || 'Необходимо заполнить все поля и проверить правильность формата данных'}} />
          </div>
        )}
      </div>
    );
  };

  public componentDidUpdate(prevProps: CalculatorFormProps, prevState: CalculatorFormData & CalculateStateErrors) {
    if (this.state.printing && prevProps.loading && !this.props.loading) {
      this.setState({
        printing: false,
      });
      window.print();
    }
  }

  private handleChange = (e: any, fieldName: string) => {
    const { target: { value }} = e;
    this.setState({
      [fieldName]: value,
    }, () => {
      this.validateForm(fieldName);
      if (['CurrencyId', 'Sum', 'MonthCount'].includes(fieldName)) {
        this.debounceLoadingProduct();
      }
      if (fieldName === 'ProductId') {
        this.loadProductConditions();
      }
    });
  };

  private loadConditions = async() => {
    const { CurrencyId, Sum, MonthCount } = this.state;
    if (!Sum || !MonthCount) {
      return;
    }
    const { type } = this.props;
    try {
      this.setState({
        loadingProducts: true,
      });
      let result: LoanProducts;
      switch (type) {
        case 'deposit':
          result = await ApiClient.getDepositProducts({
            sum: Sum,
            currency: CurrencyId,
            period: MonthCount,
            isMain: false,
          });
          break;
        case 'loan':
          result = await ApiClient.getLoanProducts({
            sum: Sum,
            currency: CurrencyId,
            period: MonthCount,
            isMain: false,
          });
          break;
        case 'raising':
          result = await ApiClient.getRaisingProducts({
            sum: Sum,
            currency: CurrencyId,
            period: MonthCount,
            isMain: false,
          });
          break;
        default:
          break;
      }
      this.setState((prevState) => ({
        ...prevState,
        ...result,
        FuncPay: result.FuncPayId,
        loadingProducts: false,
      }));
    } catch (e) {
      const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
      this.setState({
        loadingProducts: false,
      });
      toast(errorMessage, {
        type: toast.TYPE.ERROR
      });
    }
  };

  private debounceLoadingProduct = debounce(this.loadConditions, 700);

  private loadProductConditions = async () => {
    const { Sum, CurrencyId, MonthCount, ProductId } = this.state;
    const { type } = this.props;
    if (Sum && MonthCount && ProductId) {
      this.setState({
        loadingProducts: true
      });
      let result: LoanProducts;
      try {
        switch (type) {
          case 'deposit':
            result = await ApiClient.getDepositProductConditions({
              productId: ProductId,
              sum: Sum,
              currency: CurrencyId,
              period: MonthCount,
              isMain: false
            });
            break;
          case 'loan':
            result = await ApiClient.getLoanProductConditions({
              productId: ProductId,
              sum: Sum,
              currency: CurrencyId,
              period: MonthCount,
              isMain: false
            });
            break;
          case 'raising':
            result = await ApiClient.getRaisingProductConditions({
              productId: ProductId,
              sum: Sum,
              currency: CurrencyId,
              period: MonthCount,
              isMain: false
            });
            break;
          default:
            break;
        }
        await this.setState((prevState) => ({
          ...prevState,
          ...result,
          FuncPay: result.FuncPayId,
          loadingProducts: false,
        }));
      } catch (e) {
        const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
        this.setState({
          loadingProducts: false,
        });
        toast(errorMessage, {
          type: toast.TYPE.ERROR
        });
      }
    }
  };

  private handleChangeDate = (date: Date, event: any) => {
    if (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value)) {
      const value = date ? convertToUtc(date).toISOString() : null;

      this.setState({
        OpenDate: value === NONE_VALUE || value === "" ? null : value
      });
    }
  };

  private submitForm = () => {
    const { fetchCalculatorDataRequest, fetchCalculatorLoanDataRequest, fetchCalculatorRaisingDataRequest, type } = this.props;
    if (this.validateForm()) {
      const omitFields = ['formErrors', 'formValid', 'submited', 'printing', 'loadingProducts', 'ProductId', 'Products', 'FuncPayName', 'Fine', 'ProductName', 'ProductCondId', 'SumFrom', 'SumTo', 'PeriodTo'];
      switch (type) {
        case 'deposit':
          fetchCalculatorDataRequest(omit({ ...this.state }, omitFields));
          break;
        case 'loan':
          fetchCalculatorLoanDataRequest(omit({ ...this.state }, omitFields));
          break;
        case 'raising':
          fetchCalculatorRaisingDataRequest(omit({ ...this.state }, omitFields));
          break;
        default:
          break;
      }
      this.setState({
        submited: true,
      })
    } else {
      this.setState({
        formValid: false,
        submited: false,
      })
    }
  };

  private printPage = () => {
    const { submited } = this.state;
    if (this.validateForm()) {
      if (!submited) {
        this.submitForm();
        this.setState({
          printing: true,
        })
      } else {
        window.print();
      }
    }
  };

  private validateForm = (fieldName?: string) => {
    const omitFields = ['formErrors', 'formValid', 'submited', 'printing', 'loadingProducts', 'ProductId', 'Products', 'UseCapital'];
    const state = fieldName ? { [fieldName]: get(this.state, `${fieldName}`)} : omit({ ...this.state }, omitFields);
    let { formErrors } = this.state;
    const re = /^[0-9]*[.,]?[0-9]+$/;
    Object.entries(state).map(([key, value]) => {
      const isDigitField = ['CurrencyId', 'Sum', 'MonthCount', 'FuncPay', 'Percent', 'MemberPercent'].includes(key);

      if ((!value && value !== 0) || (isString(value) && isEmpty(value.trim()))) {
        formErrors = {
          ...formErrors,
          [key]: 'Пожалуйста, заполните это поле.',
        }
      } else if (isDigitField && isString(value) && !re.test(value)) {
        formErrors = {
          ...formErrors,
          [key]: 'Пожалуйста, введите число',
        }
      } else {
        formErrors = omit(formErrors, [key]);
      }
    });

    this.setState({
      formErrors,
      formValid: true,
      submited: !fieldName
    });

    return isEmpty(formErrors);
  };
}
