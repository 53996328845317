import * as React from 'react';
import { RouteComponentProps, StaticContext } from 'react-router';
import { toast } from 'react-toastify';
import Helmet from 'react-helmet';

import ApiClient from '../../../services/api';
import Link from "../../../components/Link";
import { PushInterface } from '../types';
import { ListingHeader, TableMessage } from '../components/Views';
import {
  Table,
  TableHead,
  TableRow,
  TableCellHead,
  TableBody,
  TableCell,
} from '../../../components/Table';
import {
  PUSH_LIST_FIELDS,
  PUSH_FIELDS_LABELS,
  ALIGN_CELL_BY_FIELD,
  MESSAGES,
} from '../constants';
import { formatValue, getPageFromSearch, cutFirstPageFromQuery } from '../utils';
import Pagination from '../../../components/Pagination/Pagination';
import LoadingOverlay from "../../../components/LoadingOverlay";

export interface PushListProps
  extends RouteComponentProps<{}, StaticContext, {
  afterDelete?: boolean;
  afterCreating?: boolean;
  afterUpdating?: boolean;
}> {}

export interface PushListState {
  data: PushInterface[];
  fetchError: string | null;
  loading: boolean;
  page: number;
  totalCount: number;
  sending: boolean;
}

export default class PushList extends React.Component<PushListProps, PushListState> {
  public static PAGE_SIZE = 10;
  public static IS_PAGING = false;

  constructor(props: PushListProps) {
    super(props);

    cutFirstPageFromQuery(props.history, props.location);

    this.state = {
      data: [],
      fetchError: null,
      loading: false,
      page: getPageFromSearch(this.props.location.search),
      totalCount: 0,
      sending: false,
    };
  }

  public componentDidMount() {
    this.getData();

    if (this.props.location.state && this.props.location.state.afterCreating) {
      this.props.history.replace({ ...this.props.location, state: {} });
      toast(MESSAGES.createdSuccess, {
        type: toast.TYPE.SUCCESS,
      });
    }

    if (this.props.location.state && this.props.location.state.afterUpdating) {
      this.props.history.replace({ ...this.props.location, state: {} });
      toast(MESSAGES.updateSuccess, {
        type: toast.TYPE.SUCCESS,
      });
    }
  }

  public componentWillReceiveProps(nextProps: PushListProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        page: getPageFromSearch(nextProps.location.search),
      });

      cutFirstPageFromQuery(nextProps.history, nextProps.location);
    }
  }

  public componentDidUpdate(_prevProps: PushListProps, prevState: PushListState) {
    if (prevState.page !== this.state.page) {
      this.getData();
    }
  }

  public render() {
    return (
      <div>
        <Helmet defer={false}>
          <title>PUSH уведомления</title>
        </Helmet>
        <ListingHeader />
        <LoadingOverlay loading={this.state.sending}>
        <Table>
          <TableHead>
            <TableRow>
              {PUSH_LIST_FIELDS.map(field => (
                <TableCellHead align={ALIGN_CELL_BY_FIELD.get(field) || 'left'} key={field}>
                  {PUSH_FIELDS_LABELS[field]}
                </TableCellHead>
              ))}
              <TableCellHead >
                Действия
              </TableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.data.length === 0 && (
              <TableRow>
                <TableCell colSpan={PUSH_LIST_FIELDS.length}>
                  {this.state.loading ? this.renderLoadingMessage() : this.renderNotFoundMessage()}
                </TableCell>
              </TableRow>
            )}
            {this.state.data.map(item => (
              <TableRow key={item.Id}>
                {PUSH_LIST_FIELDS.map(field => (
                  <TableCell align={ALIGN_CELL_BY_FIELD.get(field) || 'left'} key={field}>
                    {field === 'Title' ?
                      <Link routerLink href={`/tools/push/edit/${item.Id}`} type="primary">
                        {item[field]}
                      </Link>
                      : formatValue(field, item[field])}
                  </TableCell>
                ))}
                <TableCell align="center">
                  {item.IsSended ? 'Нет доступных действий' : <Link onClick={this.sendPush(item.Id)} type="primary">
                    Отправить
                  </Link>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </LoadingOverlay>
        {PushList.IS_PAGING && this.state.totalCount > PushList.PAGE_SIZE && (
          <Pagination
            page={this.state.page}
            size={PushList.PAGE_SIZE}
            count={this.state.totalCount}
            callback={this.handleChangePage}
          />
        )}
      </div>
    );
  }

  private getData = async () => {
    this.setState({
      loading: true,
    });
    try {
      const result = await ApiClient.getPushList();

      this.setState({
        data: result,
        fetchError: null,
      });
    } catch (error) {
      this.setState({
        fetchError: error.message,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  private renderNotFoundMessage = () => <TableMessage>Рассылок не найдено...</TableMessage>;

  private renderLoadingMessage = () => <TableMessage>Загрузка...</TableMessage>;

  private handleChangePage = (page: number) => {
    const queryParams = new URLSearchParams(this.props.location.search);

    queryParams.set('page', page.toString());
    this.props.history.replace(`${location.pathname}?${queryParams.toString()}`);
  };

  private sendPush = (Id: number) => async () => {
    this.setState({
      sending: true,
    });

    try {
      await ApiClient.sendPushDistribution(Id);
      const { data } = this.state;

      this.setState({
        data: data.map((item: any) => {
          if (item.Id === Id) {
            return {
              ...item,
              IsSended: true,
            }
          }
          return item;
        })
      });
      toast('Push отправлен', {
        type: toast.TYPE.SUCCESS
      });
    } catch (error) {
      toast(error.Message, {
        type: toast.TYPE.ERROR
      });
    } finally {
      this.setState({
        sending: false,
      });
    }

  }
}
