import * as React from "react";
import { ClientsContext } from "../../pages/Client";
import Panel from "../../../../components/Panel";
import { FormWrapper, FormColumn } from "../Views";
import Form from "../../../../components/PartnersAndClients/Form";
import {
  PASSPORT_FIELDS,
  INTERNATIONAL_PASSPORT_FIELDS,
  SNILS_FIELDS,
  DIPLOMA_FIELDS,
  AUTHORITY_FIELDS,
  MIGRATION_FIELDS,
  REQUIRED_FIELDS
} from "../../constants";

export default class DocumentsTab extends React.PureComponent {
  public render() {
    return (
      <ClientsContext.Consumer>
        {({
          editing,
          userData,
          handleChange = () => void 0,
          valueLabels,
          errors
        }) => (
          <FormWrapper>
            <FormColumn>
              <Panel type="primary" heading={"Удостоверение личности"}>
                <Panel heading="Гражданский паспорт">
                  {userData && (
                    <Form
                      data={userData.Passport}
                      fields={PASSPORT_FIELDS}
                      disabled={!editing}
                      onChange={handleChange.bind(null, "Passport")}
                      requiredFields={REQUIRED_FIELDS.get("Passport")}
                      requiredFieldsError={errors.get("Passport")}
                      valueLabels={valueLabels}
                    />
                  )}
                </Panel>
                <Panel heading="Загранпаспорт">
                  {userData && (
                    <Form
                      data={userData.InternationalPassport}
                      fields={INTERNATIONAL_PASSPORT_FIELDS}
                      disabled={!editing}
                      onChange={handleChange.bind(
                        null,
                        "InternationalPassport"
                      )}
                      valueLabels={valueLabels}
                    />
                  )}
                </Panel>
              </Panel>
            </FormColumn>
            <FormColumn>
              <Panel type="primary" heading="СНИЛС">
                {userData && (
                  <Form
                    data={userData}
                    fields={SNILS_FIELDS}
                    disabled={!editing}
                    onChange={handleChange.bind(null, null)}
                    valueLabels={valueLabels}
                  />
                )}
              </Panel>
              <Panel type="primary" heading="Второй документ">
                {userData && (
                  <Form
                    data={userData.Diploma}
                    fields={DIPLOMA_FIELDS}
                    disabled={!editing}
                    onChange={handleChange.bind(null, "Diploma")}
                    valueLabels={valueLabels}
                  />
                )}
              </Panel>
              <Panel
                type="primary"
                heading="Данные документа, подтверждающего наличие соответствующих полномочий"
              >
                {userData && (
                  <Form
                    data={userData.Authority}
                    fields={AUTHORITY_FIELDS}
                    disabled={!editing}
                    onChange={handleChange.bind(null, "Authority")}
                    valueLabels={valueLabels}
                  />
                )}
              </Panel>
            </FormColumn>
            <FormColumn>
              <Panel
                type="primary"
                heading="Данные миграционной карты иностранного гражданина или лица без гражданства, находящегося в РФ"
              >
                {userData && (
                  <Form
                    data={userData.Migration}
                    fields={MIGRATION_FIELDS}
                    disabled={!editing}
                    onChange={handleChange.bind(null, "Migration")}
                    valueLabels={valueLabels}
                  />
                )}
              </Panel>
            </FormColumn>
            <FormColumn>
              <Panel
                type="primary"
                heading="Данные документа, подтверждающего право иностранного гражданина или лица без гражданства на пребывание в РФ"
              >
                {userData && (
                  <Form
                    data={userData.ResidanceCard}
                    fields={MIGRATION_FIELDS}
                    disabled={!editing}
                    onChange={handleChange.bind(null, "ResidanceCard")}
                    valueLabels={valueLabels}
                  />
                )}
              </Panel>
            </FormColumn>
          </FormWrapper>
        )}
      </ClientsContext.Consumer>
    );
  }
}
