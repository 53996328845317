import * as React from "react";
import { LabelSection } from "../../store/labels";
import { connect } from "react-redux";
import { AppState } from "../../store";

export interface LabelProps {
  labels: Record<string | number, LabelSection>;
  value: string | number;
  section?: string;
}

const Label: React.FunctionComponent<LabelProps> = ({
  labels,
  value,
  section
}) => {
  let label;

  if (section && labels[section]) {
    label = labels[section].get(value);
  }

  if (!label) {
    label = labels.COMMON.get(value) || value;
  }

  return <React.Fragment>{label}</React.Fragment>;
};

const mapStateToProps = (state: AppState) => ({
  labels: state.labels.labels
});

export default connect(mapStateToProps)(Label);
