import * as React from "react";
import { RouteComponentProps } from "react-router";
import Helmet from "react-helmet";
import { toast } from "react-toastify";
import { noop, isArray, get, isEmpty, join, find, toNumber } from "lodash";
import ReactSelect from "react-select";
import { ArrowLeft } from "styled-icons/fa-solid/ArrowLeft";

import { Form, FormLine, EditingControlsContainer } from "../components/Views";
import { PushInterface, UserInterface } from "../types";
import Input from "../../../components/FormControls/Input";
import {
  Select,
  SelectOption,
  Textarea
} from "../../../components/FormControls/FormControls";
import ApiClient from "../../../services/api";
import Button from "../../../components/Button/Button";
import { formatValue } from "../utils";
import Link from "../../../components/Link";
import SectionHeader from "../../../components/SectionHeader";
import { FormLabel } from "../../../components/FormLabel";
import { showErrorToast } from "../../../components/ErrorToast";

import {
  NEWS_FIELDS,
  PUSH_FIELDS_LABELS,
  TYPE_LABELS,
  REQUIRED_CREATION_FIELDS,
  MESSAGES,
  PAGE_LABELS,
  PUBLICATIONS_SHOW_TYPE_ID,
  DEFAULT_FIELDS,
  OPEN_NEWS_FIELDS,
  OPEN_PAGE_FIELDS,
  OPEN_TEXT_FIELDS,
} from "../constants";

import "./index.scss";
import styled from "../../../styled/styled-components";
import { CheckboxView } from "../../../components/FormControls/Views";

export const Checkbox = styled(CheckboxView)`
  display: inline-block;
  width: auto;
  height: auto;
`;

export type PushDetailsFields = Partial<PushInterface>;

export interface PushDetailsProps
  extends RouteComponentProps<{ mode: "edit" | "create"; id?: string },
    any,
    { afterCreating?: boolean }> {
}

export interface PushDetailsState {
  details: PushDetailsFields;
  loading: boolean;
  formErrors: Array<[keyof PushInterface, string]>;
  createdId: string | null;
  Users?: [UserInterface],
  selectedUsers?: any,
  selectedNews?: any,
  news?: any,
  isAllUsers: boolean,
}

export default class PushDetails extends React.Component<PushDetailsProps,
  PushDetailsState> {
  public state: PushDetailsState = {
    details: {
      Body: "",
      Title: "",
      Users: "",
      TypeId: 2,
      PublicationID: undefined,
      ButtonText: "",
      ButtonLink: "",
      PageId: 1,
      PublicationShowTypeID: 1,
      PayloadTitle: "",
      PayloadBody: "",
      ShowTitle: true,
    },
    Users: undefined,
    loading: false,
    formErrors: [],
    createdId: null,
    selectedUsers: null,
    news: null,
    selectedNews: null,
    isAllUsers: false
  };

  public componentDidMount() {
    this.handleNavigate();
  }

  public componentDidUpdate(prevProps: PushDetailsProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handleNavigate();
    }
  }

  public render() {
    return (
      <div>
        <Helmet defer={false}>
          <title>Push уведомления</title>
        </Helmet>
        <SectionHeader
          heading={this.getHeading()}
          link={this.renderBackLink()}
        />
        <Form>
          {DEFAULT_FIELDS.map(field => (
            <FormLine key={field}>
              <FormLabel
                width="33%"
                required={REQUIRED_CREATION_FIELDS.includes(field)}
              >
                {PUSH_FIELDS_LABELS[field]}
              </FormLabel>
              {this.getFormComponent(field)}
            </FormLine>
          ))}
          {this.state.details.TypeId === 2 && NEWS_FIELDS.map(field => (
            <FormLine key={field}>
              <FormLabel
                width="33%"
                required={REQUIRED_CREATION_FIELDS.includes(field)}
              >
                {PUSH_FIELDS_LABELS[field]}
              </FormLabel>
              {this.getFormComponent(field)}
            </FormLine>
          ))}
          {this.state.details.TypeId === 3 && OPEN_PAGE_FIELDS.map(field => (
            <FormLine key={field}>
              <FormLabel
                width="33%"
                required={REQUIRED_CREATION_FIELDS.includes(field)}
              >
                {PUSH_FIELDS_LABELS[field]}
              </FormLabel>
              {this.getFormComponent(field)}
            </FormLine>
          ))}
          {this.state.details.TypeId === 2 && this.state.details.PublicationShowTypeID === 1 && OPEN_NEWS_FIELDS.map(field => (
            <FormLine key={field}>
              <FormLabel
                width="33%"
                required={REQUIRED_CREATION_FIELDS.includes(field)}
              >
                {PUSH_FIELDS_LABELS[field]}
              </FormLabel>
              {this.getFormComponent(field)}
            </FormLine>
          ))}
          {this.state.details.TypeId === 2 && this.state.details.PublicationShowTypeID === 2 && OPEN_TEXT_FIELDS.map(field => (
            <FormLine key={field}>
              <FormLabel
                width="33%"
                required={REQUIRED_CREATION_FIELDS.includes(field)}
              >
                {PUSH_FIELDS_LABELS[field]}
              </FormLabel>
              {this.getFormComponent(field)}
            </FormLine>
          ))}
          <FormLine>
            {this.isCreate() ? (
              <Button
                disabled={
                  !!this.state.formErrors.length || this.state.loading
                }
                onClick={this.submitForm}
                type="success"
              >
                Создать
              </Button>
            ) : (
              !this.state.details.IsSended && (
                <EditingControlsContainer>
                  <Button
                    onClick={this.submitForm}
                    disabled={this.state.loading}
                    type="success"
                    className="m-3"
                  >
                    Сохранить
                  </Button>
                  <Button
                    onClick={this.submitAndSaveForm}
                    disabled={this.state.loading}
                    type="error"
                    className="m-3"
                  >
                    Сохранить и отправить
                  </Button>
                </EditingControlsContainer>
              )
            )}
          </FormLine>
        </Form>
      </div>
    );
  }

  private isCreate = () => this.props.match.params.mode === "create";

  private getData = async (idFromUrl: string) => {
    this.setState({
      loading: true
    });

    try {
      const id = parseInt(idFromUrl, 10);
      const result = await ApiClient.getPushDetails(id);
      let additionalResult = result.Payload && JSON.parse(result.Payload);
      additionalResult = additionalResult || {};
      if (additionalResult.PubId) {
        additionalResult.PublicationID = additionalResult.PubId;
      }
      if (additionalResult.Title) {
        additionalResult.PayloadTitle = additionalResult.Title;
      }
      if (additionalResult.Body) {
        additionalResult.PayloadBody = additionalResult.Body;
      }

      if (additionalResult.PageID) {
        additionalResult.PageId = parseFloat(additionalResult.PageID);
      }

      this.setState((state) => ({
        details: {
          ...state.details,
          ...additionalResult,
          ...result,
        }
      }));
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.getSelected();
      this.getSelectedNews();
      this.setState({
        loading: false
      });
    }
  };

  private getUsers = async () => {
    this.setState({
      loading: true
    });

    try {
      const result = await ApiClient.getPushDetailsUsers();
      this.setState({
        Users: result
      });
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.getSelected();
      this.setState({
        loading: false
      });
    }
  };

  private getPublications = async () => {
    this.setState({
      loading: true
    });

    try {
      const publications = await ApiClient.getPublications('all');
      this.setState({
        news: [
          ...publications.publications,
        ]
      });
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.getSelectedNews();
      this.setState({
        loading: false
      });
    }
  };

  private getSelected = () => {
    if (this.isCreate()) {
      return;
    }

    if (isEmpty(this.state.details.Users) || isEmpty(this.state.Users)) {
      return;
    }
    if (this.state.details.Users) {
      let isAllUsers = false;
      const selectedUsers = this.state.details.Users.split(", ").map((item: string) => {
        if (item === "all") {
          isAllUsers = true;
          return {
            value: "all",
            label: "Всем пайщикам"
          };
        }

        const user = find(this.state.Users, ((i: any) => {
          return toNumber(i.Id) === toNumber(item);
        }));

        if (user) {
          return {
            value: user.Id,
            label: user.ShortName
          };
        }

        return {
          value: item,
          label: 'Неизвестный пайщик'
        };
      });

      this.setState({
        selectedUsers,
        isAllUsers
      });
    }
  };

  private getSelectedNews = () => {
    if (this.isCreate()) {
      return;
    }

    if (isEmpty(this.state.details.PublicationID) || isEmpty(this.state.news)) {
      return;
    }

    if (this.state.details.PublicationID) {
      const selectedNews = find(this.state.news, ((i: any) => {
        return toNumber(i.Id) === toNumber(this.state.details.PublicationID);
      }));


      if (selectedNews) {
        this.setState({
          selectedNews: {
            value: selectedNews.Id,
            label: selectedNews.Title,
          }
        });
      }
    }
  };

  private getHeading = () => {
    if (this.isCreate()) {
      return "Новое PUSH уведомление";
    }

    return `PUSH уведомление ${this.props.match.params.id}`;
  };

  private getFormComponent = (field: keyof PushInterface) => {
    const rawValue = this.state.details[field];
    const formatedValue = formatValue(field, rawValue || "");
    const error = this.state.formErrors.find(item => item[0] === field);
    const { IsSended } = this.state.details;

    switch (field) {
      case "TypeId":
        return (
          <Select
            value={this.state.details.TypeId || 1}
            onChange={this.handleChangeSelect}
            disabled={IsSended || this.state.loading}
            style={{ width: 400 }}
            name={field}
          >
            {[...TYPE_LABELS].map(([key, label]) => (
              <SelectOption key={key} value={key}>
                {label}
              </SelectOption>
            ))}
          </Select>
        );

      case "PageId":
        return (
          <Select
            value={this.state.details.PageId || 1}
            onChange={this.handleChangeSelect}
            name={field}
            disabled={IsSended || this.state.loading}
            style={{ width: 400 }}
          >
            {[...PAGE_LABELS].map(([key, label]) => (
              <SelectOption key={key} value={key}>
                {label}
              </SelectOption>
            ))}
          </Select>
        );

      case "PublicationShowTypeID":
        return (
          <Select
            value={this.state.details.PublicationShowTypeID || 1}
            onChange={this.handleChangeSelect}
            name={field}
            disabled={IsSended || this.state.loading}
            style={{ width: 400 }}
          >
            {[...PUBLICATIONS_SHOW_TYPE_ID].map(([key, label]) => (
              <SelectOption key={key} value={key}>
                {label}
              </SelectOption>
            ))}
          </Select>
        );

      case "IsSended":
        return (
          <Input
            value={rawValue ? "Отправлено" : "Создано"}
            name={field}
            onChange={noop}
            style={{ width: 400 }}
            disabled
          />
        );

      case "ShowTitle":
        return (
          <Checkbox onChange={this.handleChangeCheckbox} name={field} checked={!!rawValue} />
        );

      case "Body":
        return (
          <Textarea
            value={formatedValue || ""}
            name={field}
            onChange={this.handleChangeTextValue}
            style={{ width: 400, height: 180 }}
            required
            error={!!error}
            disabled={IsSended || this.state.loading}
            rows={3}
            disableResize
          />
        );

      case "PayloadBody":
        return (
          <Textarea
            value={formatedValue || ""}
            name={field}
            onChange={this.handleChangeTextValue}
            style={{ width: 400, height: 180 }}
            required
            error={!!error}
            disabled={IsSended || this.state.loading}
            rows={3}
            disableResize
          />
        );

      case "Users":
        const users = get(this.state, "Users", []);

        // @ts-ignore
        const options = isArray(users) && users.map((item: UserInterface) => {
          return {
            value: item.Id,
            label: item.ShortName
          };
        });

        let resultOption = [];

        if (isEmpty(this.state.selectedUsers)) {
          resultOption = [{ value: "all", label: "Всем пайщикам" }, ...options];
        } else if (this.state.isAllUsers) {
          resultOption = [{ value: "all", label: "Всем пайщикам" }];
        } else {
          resultOption = options;
        }

        return (
          <ReactSelect
            isMulti
            name="Users"
            value={this.state.selectedUsers}
            options={resultOption}
            className="users-multi-select"
            placeholder={"Выберите пользователей"}
            classNamePrefix="select"
            onChange={this.onChangeSelect}
            isDisabled={IsSended || this.state.loading}
          />
        );

      case "PublicationID":

        const news = get(this.state, "news", []);
        // @ts-ignore
        const optionsNews = isArray(news) && news.map((item) => {
          return {
            value: item.Id,
            label: item.Title
          };
        });

        return (
          <ReactSelect
            name="PublicationID"
            value={this.state.selectedNews}
            options={optionsNews || []}
            className="users-multi-select"
            placeholder={"Выберите публикацию"}
            classNamePrefix="select"
            onChange={this.onChangeSelectNews}
            isDisabled={IsSended || this.state.loading}
          />
        );

      default:
        return (
          <Input
            value={formatedValue || ""}
            name={field}
            onChange={this.handleChangeTextValue}
            style={{ width: 400 }}
            required
            error={!!error}
            disabled={IsSended || this.state.loading}
          />
        );
    }
  };

  private onChangeSelect = (value: any, action: any) => {
    switch (action.action) {
      case "clear":
        this.setState({
          selectedUsers: null,
          isAllUsers: false
        });
        break;

      case "select-option":
        if (action.option.value === "all") {
          this.setState({
            selectedUsers: value,
            isAllUsers: true
          });
        } else {
          this.setState({
            selectedUsers: value,
            isAllUsers: false
          });
        }
        break;

      case "remove-value":
        if (action.removedValue.value === "all") {
          this.setState({
            selectedUsers: value,
            isAllUsers: false
          });
        } else {
          this.setState({
            selectedUsers: value
          });
        }
        break;

      default:
        break;
    }
  };

  private onChangeSelectNews = (value: any) => {
    this.setState({
      selectedNews: value,
    })
  };

  private handleChangeCheckbox = (e: any) => {
    const { target: { checked, name } } = e;
    this.setState(state => ({
      formErrors: [],
      details: {
        ...state.details,
        [name]: checked
      }
    }));
  };

  private handleChangeTextValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    this.setState(state => ({
      formErrors: [],
      details: {
        ...state.details,
        [name]: value
      }
    }));
  };

  private handleChangeSelect = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name } = event.target;
    const value = name === 'PageId' ? parseFloat(event.target.value as string) : parseInt(event.target.value as string, 10);
    this.setState(state => ({
      details: {
        ...state.details,
        [name]: value
      }
    }));
  };

  private submitForm = async () => {
    if (this.state.loading) {
      return;
    }
    const details = this.getDetails();
    const isCreate = details.Id === undefined;
    const isValidForm = await this.isValidForm(details);

    if (isValidForm) {
      this.setState({ loading: true });

      try {
        await ApiClient.createOrUpdatePushDistributions(details);

        if (isCreate) {
          this.handleCreate();
        } else {
          this.handleUpdate();
        }
      } catch (error) {
        showErrorToast(error);
      } finally {
        this.setState({ loading: false });
      }
    } else {
      toast(MESSAGES.formErrors, {
        type: toast.TYPE.ERROR
      });

    }
  };

  private getDetails = () => {
    const { details } = this.state;
    const defaultDetails = {
      Id: details.Id,
      IsSended: details.IsSended,
      Users: isArray(this.state.selectedUsers) ? this.state.isAllUsers ? "all" : join(this.state.selectedUsers.map((i: any) => (i.value)), ", ") : '',
      TypeId: details.TypeId,
      Title: details.Title,
      Body: details.Body,
      ShowTitle: details.ShowTitle,
    };

    if (details.TypeId === 3) {
      return {
        ...defaultDetails,
        PageId: details.PageId,
      }
    }

    if (details.PublicationShowTypeID === 1) {
      return {
        ...defaultDetails,
        PublicationID: this.state.selectedNews && this.state.selectedNews.value,
        ButtonText: details.ButtonText,
        ButtonLink: details.ButtonLink,
        PublicationShowTypeID: details.PublicationShowTypeID,
      }
    }

    return {
      ...defaultDetails,
      PayloadTitle: details.PayloadTitle,
      PayloadBody: details.PayloadBody,
      PublicationShowTypeID: details.PublicationShowTypeID,
    }
  };

  private isValidForm = (data: PushDetailsState["details"]) => {
    const formErrors: Array<[keyof PushInterface, string]> = [];
    const { Title, Body, Users } = data;

    if (!Title) {
      formErrors.push(["Title", "Поле не может быть пустым"]);
    }

    if (!Body) {
      formErrors.push(["Body", "Поле не может быть пустым"]);
    }

    if (!Users) {
      formErrors.push(["Users", "Поле не может быть пустым"]);
    }

    if (formErrors.length) {
      this.setState({
        formErrors
      });
      return Promise.resolve(false);
    } else {
      Promise.resolve(true);
    }

    return Promise.resolve(formErrors.length === 0);
  };

  private submitAndSaveForm = async () => {
    if (this.state.loading) {
      return;
    }
    const details = this.getDetails();
    const isCreate = details.Id === undefined;
    // @ts-ignore
    const isValidForm = await this.isValidForm(details);

    if (isValidForm) {
      this.setState({ loading: true });

      try {
        const result:
          | { Id: number }
          | PushInterface = await ApiClient.createOrUpdatePushDistributions(details);

        const { Id } = result;

        await ApiClient.sendPushDistribution(Id);

        if (isCreate) {
          this.handleCreate();
        } else {
          this.handleUpdate();
        }
      } catch (error) {
        showErrorToast(error);
      } finally {
        this.setState({ loading: false });
      }
    } else {
      toast(MESSAGES.formErrors, {
        type: toast.TYPE.ERROR
      });

    }
  };

  private handleCreate = () => {
    this.goToList({
      afterCreating: true
    });
  };

  private handleUpdate = () => {
    this.goToList({
      afterUpdating: true
    });
  };

  private handleNavigate = () => {
    this.getUsers();
    this.getPublications();

    if (this.isCreate()) {
      return;
    }

    if (this.props.match.params.id) {
      this.getData(this.props.match.params.id);
    }
  };

  private goToList = (state: any) => {
    this.props.history.replace("/tools/push", state);
  };

  private renderBackLink = () => (
    <Link onClick={this.goToList.bind(undefined, undefined)}>
      <ArrowLeft size={13}/> Вернуться к списку
    </Link>
  );
}
