import * as React from "react";
import styled from "../../../styled/styled-components";
import Input, { InputProps } from "@material-ui/core/Input";

const InputView: React.FunctionComponent<InputProps> = React.forwardRef<
  HTMLInputElement,
  InputProps
>((props, ref) => <Input disableUnderline inputRef={ref} {...props} />);

export default styled(InputView).attrs({
  classes: {
    root: "Root",
    formControl: "FormControl",
    focused: "Focused",
    disabled: "Disabled",
    underline: "Underline",
    error: "Error",
    fullWidth: "FullWidth",
    input: "Input",
  }
})`
  .Input {
    height: 20px;
    font-size: 14px;
    background: #fff;
    border: 1px solid #e5e6e7;
    border-radius: 1px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    padding: 6px 12px;

    &:focus {
      border-color: #1ab394;
      outline: none;
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.fields.disabled.background};
      color: ${({ theme }) => theme.colors.fields.disabled.font};
      border-color: ${({ theme }) => theme.colors.fields.disabled.borders};
      cursor: not-allowed;
    }
  }

  .error {
    border-color: ${({ theme }) => theme.colors.errorRed};
  }
`;
