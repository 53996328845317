import { createAction } from "typesafe-actions";
import { CurrencyId, CurrencyRequests, SubmitData } from "./types";

export const ActionTypes = {
    GET_ALL_OPERATIONS_BY_CURRENCY_REQUEST: "@@BATCH_OPERATIONS/GET_ALL_OPERATIONS_BY_CURRENCY_REQUEST",
    GET_ALL_OPERATIONS_BY_CURRENCY_SUCCESS: "@@BATCH_OPERATIONS/GET_ALL_OPERATIONS_BY_CURRENCY_SUCCESS",
    GET_ALL_OPERATIONS_BY_CURRENCY_ERROR: "@@BATCH_OPERATIONS/GET_ALL_OPERATIONS_BY_CURRENCY_ERROR",

    GET_EMPLOYEES_REQUEST: "@@BATCH_OPERATIONS/GET_EMPLOYEES_REQUEST",
    GET_EMPLOYEES_SUCCESS: "@@BATCH_OPERATIONS/GET_EMPLOYEES_SUCCESS",
    GET_EMPLOYEES_ERROR: "@@BATCH_OPERATIONS/GET_EMPLOYEES_ERROR",

    SUBMIT_BATCH_FORM_REQUEST: "@@BATCH_OPERATIONS/SUBMIT_BATCH_FORM_REQUEST",
    SUBMIT_BATCH_FORM_SUCCESS: "@@BATCH_OPERATIONS/SUBMIT_BATCH_FORM_SUCCESS",
    SUBMIT_BATCH_FORM_ERROR: "@@BATCH_OPERATIONS/SUBMIT_BATCH_FORM_ERROR",
};

export const getOperationsByCurrencyRequest = createAction(
    ActionTypes.GET_ALL_OPERATIONS_BY_CURRENCY_REQUEST,
    resolve => (currencyId: CurrencyId) => resolve(currencyId)
);

export const getOperationsByCurrencySuccess = createAction(
    ActionTypes.GET_ALL_OPERATIONS_BY_CURRENCY_SUCCESS,
    resolve => (data: CurrencyRequests) =>
        resolve(data)
);

export const getOperationsByCurrencyError = createAction(
    ActionTypes.GET_ALL_OPERATIONS_BY_CURRENCY_ERROR,
    resolve => (error: any) =>
        resolve(error)
);

export const getEmployeesRequest = createAction(
    ActionTypes.GET_EMPLOYEES_REQUEST
);

export const getEmployeesSuccess = createAction(
    ActionTypes.GET_EMPLOYEES_SUCCESS,
    resolve => (data: CurrencyRequests) =>
        resolve(data)
);

export const getEmployeesError = createAction(
    ActionTypes.GET_EMPLOYEES_ERROR,
    resolve => (error: any) =>
        resolve(error)
);

export const submitFormRequest = createAction(
  ActionTypes.SUBMIT_BATCH_FORM_REQUEST,
  resolve => (data: SubmitData) => resolve(data)
);

export const submitFormSuccess = createAction(
  ActionTypes.SUBMIT_BATCH_FORM_SUCCESS,
  resolve => (message: string) =>
    resolve(message)
);

export const submitFormError = createAction(
  ActionTypes.SUBMIT_BATCH_FORM_ERROR,
  resolve => (error: any) =>
    resolve(error)
);