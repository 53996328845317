import * as React from "react";
import Helmet from "react-helmet";

import LoadingOverlay from "../../../components/LoadingOverlay";
import SectionHeader from "../../../components/SectionHeader";
import Calendar from "../components/Calendar";
import Table from "../components/Table/pages/Table";


const PAGE_TITLE = "Календарь";

export interface ReportCalendarState {
  loading: boolean;
  showAllMonth: boolean;
  showAllYear: boolean;
  resetFilter: boolean;
  date: string | null;
}

export default class ReportCalendar extends React.PureComponent<any, ReportCalendarState> {

  public state: ReportCalendarState = {
    loading: false,
    showAllMonth: false,
    showAllYear: false,
    resetFilter: false,
    date: null,
  };

  public render() {
    const { loading } = this.state;
    return (
      <div>
        <Helmet defer={false}>
          <title>{PAGE_TITLE}</title>
        </Helmet>
        <SectionHeader heading={PAGE_TITLE}/>
        <LoadingOverlay loading={loading}>
            <Calendar
              changeLoading={this.changeLoading}
              onChangeCalendar={this.onChangeCalendar}
            />
            <Table
              requestDate={this.state.date}
            />
        </LoadingOverlay>
      </div>
    );
  }

  private changeLoading = (loading: boolean) => {
    this.setState({
      loading,
    })
  };

  private onChangeCalendar = (date: string | null) => {
    this.setState({
      date,
    })
  };
}
