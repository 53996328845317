import * as React from "react";
import { PartnerContext } from "../../pages/Partner";
import {
  Table,
  TableHead,
  TableRow,
  TableCellHead,
  TableCell
} from "../../../../components/Table";
import { BILL_FIELDS } from "../../constants";
import moment from "moment";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import Link from "../../../../components/Link";
import styled from "../../../../styled/styled-components";
import { Bill } from "../../types";
import { IS_ACTIVE_LABELS } from "../../../../constants";
import Button from "../../../../components/Button/Button";

interface BillsTableProps {
  bills: Bill[];
  billTypes: Map<number, string>;
  currencies: Map<number, string>;
}

const BillCode = styled(Link)`
  white-space: nowrap;
`;

const getTableCellAlign = (fieldName: keyof Bill) => {
  switch (fieldName) {
    case "OpeningDate":
    case "ClosingDate":
    case "CurrencyId":
    case "IsActive":
      return "center";

    case "CurrentBalance":
    case "OpeningSum":
      return "right";

    default:
      return "left";
  }
};

const renderValue = (
  fieldName: keyof Bill,
  bill: Bill,
  billTypes: Map<number, string>,
  currencies: Map<number, string>
) => {
  const value = bill[fieldName];

  switch (fieldName) {
    case "BillCode":
      return <BillCode href={`/Bill/Details/${value}`}>{value}</BillCode>;

    case "OpeningDate":
    case "ClosingDate":
      if (typeof value === "string") {
        return moment(value).format(DatePicker.prettyDateFormat);
      }
      return "---";

    case "CurrentBalance":
    case "OpeningSum":
      if (typeof value === "number") {
        return value.toLocaleString();
      }
      return "---";

    case "BillTypeId":
      if (typeof value === "number") {
        let billName;
        if (value === 9 && bill.BillCode.includes('-02-950-')) {
          billName = 'Счета детских накоплений'
        } else if (value === 9 && bill.BillCode.includes('-02-900-')) {
          billName = 'Счета пенсионных накоплений'
        } else {
          billName = billTypes.get(value);
        }

        return `${billName}${[3, 4, 7].includes(bill.BillTypeId) && bill.ProductName ? ` (${bill.ProductName})` : ''}`;
      }

    case "CurrencyId":
      if (typeof value === "number") {
        return currencies.get(value);
      }

    case "IsActive":
      if (typeof value === "boolean") {
        return IS_ACTIVE_LABELS.get(Number(value));
      }

    default:
      return value;
  }
};

const BillsTable: React.SFC<BillsTableProps> = React.memo(
  ({ bills, billTypes, currencies }) => {
    const [showBills, setShowBills] = React.useState(false);
    const handleButtonClick = () => {
      setShowBills(!showBills);
    };
    return (
      <Table style={{ tableLayout: "auto" }}>
        <TableHead>
          <TableRow>
            {[...BILL_FIELDS.entries()].map(([fieldName, label]) => (
              <TableCellHead
                align={getTableCellAlign(fieldName)}
                key={fieldName}
              >
                {label}
              </TableCellHead>
            ))}
          </TableRow>
        </TableHead>
        <TableHead>
          {bills.filter(bill => bill.IsActive).map(bill => (
            <TableRow key={bill.Id}>
              {[...BILL_FIELDS.keys()].map((fieldName, index) => (
                <TableCell key={index} align={getTableCellAlign(fieldName)}>
                  {renderValue(fieldName, bill, billTypes, currencies)}
                </TableCell>
              ))}
            </TableRow>
          ))}
          {showBills && bills.filter(bill => !bill.IsActive).map(bill => (
            <TableRow key={bill.Id}>
              {[...BILL_FIELDS.keys()].map((fieldName, index) => (
                <TableCell key={index} align={getTableCellAlign(fieldName)}>
                  {renderValue(fieldName, bill, billTypes, currencies)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <Button type="primary" className="mt-2" onClick={handleButtonClick}>{showBills ? 'Скрыть' : 'Показать'} неактивные счета</Button>
      </Table>
    );
  }
);

export default class BillsTab extends React.PureComponent {
  public render() {
    return (
      <PartnerContext.Consumer>
        {({ bills, valueLabels }) => (
          <BillsTable
            bills={bills}
            billTypes={valueLabels.billTypes}
            currencies={valueLabels.currencies}
          />
        )}
      </PartnerContext.Consumer>
    );
  }
}
