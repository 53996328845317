import * as React from "react";
import StyledModal from "styled-react-modal";
import styled, { css } from "styled-components";
import Icon from "../Icon";
import Clickable from "../Clickable";
import { ifProp } from "styled-tools";

const modalStyles = css`
  max-width: 80%;
  max-height: 80%;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  ${ifProp("fullWidth", css`
    width: 50%;
  `)}
`;

const ModalView = StyledModal.styled`${modalStyles}`;

const ModalContent = styled.div`
  overflow: auto;
`;

const ModalHeader = styled.div`
  width: 100%;
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  border-bottom: 2px solid #dee2e6;
  font-size: 1.3em;
`;

const ModalCloseButton = styled(Clickable)`
  &:hover {
    opacity: 0.6;
  }
`;

const Wrapper = styled.div`
  padding: 20px;
`;

export interface ModalProps {
  isOpen: boolean;
  allowScroll?: boolean;
  afterOpen?: () => void;
  afterClose?: () => void;
  beforeOpen?: Promise<void> | (() => void);
  beforeClose?: Promise<void> | (() => void);
  onEscapeKeydown?: (event: Event) => void;
  onBackgroundClick?: (event: React.MouseEvent<HTMLElement>) => void;
  header?: React.ReactNode;
  fullWidth?: boolean;
}

const Modal: React.SFC<ModalProps> = ({ children, header, ...props }) => (
  <ModalView {...props}>
    {header && (
      <ModalHeader>
        <div>{header}</div>
        <ModalCloseButton onClick={props.onBackgroundClick}>
          <Icon name="times" />
        </ModalCloseButton>
      </ModalHeader>
    )}
    <ModalContent>
      <Wrapper>{children}</Wrapper>
    </ModalContent>
  </ModalView>
);

export default Modal;
