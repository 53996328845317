import * as React from "react";
import { SortDirection } from "../../modules/Clients/types";
import styled, { css } from "../../styled/styled-components";
import { SortUp, SortDown } from "styled-icons/boxicons-regular";

const SortIconView = styled.span`
  display: inline-block;
  line-height: 1;
  vertical-align: text-top;
`;

const IconStyles = css`
  width: 14px;
  height: 14px;
`;

const StyledSortUp = styled(SortUp)`
  ${IconStyles}
`;

const StyledSortDown = styled(SortDown)`
  ${IconStyles}
`;

const SortIcon: React.SFC<{ sortDirection: SortDirection }> = ({
  sortDirection
}) => (
  <SortIconView>
    {sortDirection === 1 ? <StyledSortDown /> : <StyledSortUp />}
  </SortIconView>
);

export default SortIcon;
