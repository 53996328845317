import { RegionsState, RegionsActionTypes } from "./types";
import { Reducer } from "redux";

const initialState: RegionsState = {
  countries: [],
  loading: false,
  streets: [],
  allCountries: [],
};

const reducer: Reducer<RegionsState> = (state = initialState, action) => {
  switch (action.type) {
    case RegionsActionTypes.FETCH_COUNTRIES_REQUEST:
    case RegionsActionTypes.FETCH_REGIONS_REQUEST:
      return { ...state, loading: true };

    case RegionsActionTypes.FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.payload.sort((a: any, b: any) => {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          return 0;
        })
      };

    case RegionsActionTypes.FETCH_REGIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: state.countries.map(item => {
          if (item.id === action.payload.countryId) {
            return {
              ...item,
              regions: action.payload.data.sort((a: any, b: any) => {
                if (a.label > b.label) {
                  return 1;
                }
                if (a.label < b.label) {
                  return -1;
                }
                return 0;
              })
            };
          }

          return item;
        })
      };

    case RegionsActionTypes.FETCH_CITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: state.countries.map(country => {
          if (country.id === action.payload.countryId) {
            return {
              ...country,
              regions: country.regions.map(region => {
                if (region.id === action.payload.regionId) {
                  return {
                    ...region,
                    cities: action.payload.data.sort((a: any, b: any) => {
                      if (a.label > b.label) {
                        return 1;
                      }
                      if (a.label < b.label) {
                        return -1;
                      }
                      return 0;
                    })
                  };
                }
                return region;
              })
            };
          }

          return country;
        })
      };

    case RegionsActionTypes.FETCH_STREETS_SUCCESS:
      return {
        ...state,
        loading: false,
        streets: action.payload,
      };

    case RegionsActionTypes.SAVE_COUNTRIES_SUCCESS:
      return {
        ...state,
        allCountries: action.payload
      };

    default:
      return state;
  }
};

export { reducer as regionsReducer };
