import { action, createAction } from 'typesafe-actions';
import {
  ListServerResponse,
  PaymentRequestData,
  PaymentRequest,
  ExecServerResponse,
} from './types';
import { Omit } from '../../helpers/typingHelpers';
import { FieldInfo } from '../../types';
import { navigateToOperationExec } from './utils';

const prefix = 'paymentRequests';
export const ActionTypes = {
  FETCH_LIST_REQUEST: `@@${prefix}/FETCH_LIST_REQUEST`,
  FETCH_LIST_SUCCESS: `@@${prefix}/FETCH_LIST_SUCCESS`,
  FETCH_LIST_ERROR: `@@${prefix}/FETCH_LIST_ERROR`,

  CHANGE_LIST_REQUEST_DATA: `@@${prefix}/CHANGE_LIST_REQUEST_DATA`,

  DOCX_DOWNLOAD_REQUEST: `@@${prefix}/DOCX_DOWNLOAD_REQUEST`,
  PDF_DOWNLOAD_REQUEST: `@@${prefix}/PDF_DOWNLOAD_REQUEST`,
  DOWNLOAD_SUCCESS: `@@${prefix}/DOWNLOAD_SUCCESS`,
  DOWNLOAD_ERROR: `@@${prefix}/DOWNLOAD_ERROR`,

  EXEC_REQUEST: `@@${prefix}/EXEC_REQUEST`,
  EXEC_SUCCESS: `@@${prefix}/EXEC_SUCCESS`,
  EXEC_ERROR: `@@${prefix}EXEC_ERROR`,

  DELETE_REQUEST: `@@${prefix}/DELETE_REQUEST`,
  DELETE_SUCCESS: `@@${prefix}/DELETE_SUCCESS`,
  DELETE_ERROR: `@@${prefix}/DELETE_ERROR`,

  CHANGE_FILTERS: `@@${prefix}/CHANGE_FILTERS`,
  RESET_ALL_FILTERS: `@@${prefix}/RESET_ALL_FILTERS`,
  RESET_FILTER: `@@${prefix}/RESET_FILTER`,

  CHANGE_PAGE: `@@${prefix}/CHANGE_PAGE`,
  CHANGE_PAGE_SIZE: `@@${prefix}/CHANGE_PAGE_SIZE`,

  GET_COUNT_NON_EXECUTED_REQUEST: `@@${prefix}/GET_COUNT_NON_EXECUTED_REQUEST`,
  GET_COUNT_NON_EXECUTED_SUCCESS: `@@${prefix}/GET_COUNT_NON_EXECUTED_SUCCESS`,
  GET_COUNT_NON_EXECUTED_ERROR: `@@${prefix}/GET_COUNT_NON_EXECUTED_ERROR`,
  GET_COUNT_NON_EXECUTED_STOP: `@@${prefix}/GET_COUNT_NON_EXECUTED_STOP`,
};

export const fetchListRequest = action(ActionTypes.FETCH_LIST_REQUEST);

export const fetchListSuccess = (data: ListServerResponse) =>
  action<string, ListServerResponse>(ActionTypes.FETCH_LIST_SUCCESS, data);

export const fetchListError = (error: any) => action(ActionTypes.FETCH_LIST_ERROR, error);

export const downloadDocx = createAction(
  ActionTypes.DOCX_DOWNLOAD_REQUEST,
  resolve => (id: number) => resolve(id),
);
export const downloadPdf = createAction(ActionTypes.PDF_DOWNLOAD_REQUEST, resolve => (id: number) =>
  resolve(id));
export const downloadSuccess = action(ActionTypes.DOWNLOAD_SUCCESS);
export const downloadError = action(ActionTypes.DOWNLOAD_ERROR);

export const execRequest = createAction(ActionTypes.EXEC_REQUEST, resolve => (id: number) =>
  resolve(id));

export const execError = action(ActionTypes.EXEC_ERROR);

export const execSuccess = (data: ExecServerResponse) => {
  navigateToOperationExec(data);

  return action(ActionTypes.EXEC_SUCCESS);
};

export const deleteRequest = createAction(ActionTypes.DELETE_REQUEST, resolve => (id: number) =>
  resolve(id));

export const deleteSuccess = action(ActionTypes.DELETE_SUCCESS);
export const deleteError = action(ActionTypes.DELETE_ERROR);

export const changeFilters = createAction(
  ActionTypes.CHANGE_FILTERS,
  resolve => (fieldInfo: FieldInfo<PaymentRequest>, forceUpdate?: boolean) =>
    resolve(fieldInfo, forceUpdate || fieldInfo.ValueType === 4 || fieldInfo.ValueType === 5),
);

export const changeListRequestData = createAction(
  ActionTypes.CHANGE_LIST_REQUEST_DATA,
  resolve => (field: keyof Omit<PaymentRequestData, 'FieldInfos'>, value: any) =>
    resolve({ [field]: value }),
);

export const resetAllFilters = action(ActionTypes.RESET_ALL_FILTERS);

export const resetFilter = createAction(
  ActionTypes.RESET_FILTER,
  resolve => (fieldName: keyof PaymentRequest) => resolve(null, fieldName),
);

export const changePage = createAction(ActionTypes.CHANGE_PAGE, resolve => (page: number) =>
  resolve(page));

export const changePageSize = createAction(ActionTypes.CHANGE_PAGE_SIZE, resolve => (page: number) =>
  resolve(page));

export const getCountNonExecutedRequest = createAction(
  ActionTypes.GET_COUNT_NON_EXECUTED_REQUEST);

export const getCountNonExecutedSuccess = createAction(
  ActionTypes.GET_COUNT_NON_EXECUTED_SUCCESS,
  resolve => (count: number) => resolve(count)
);

export const getCountNonExecutedError = createAction(
  ActionTypes.GET_COUNT_NON_EXECUTED_ERROR,
  resolve => (error: any) => resolve(error)
);
