import React, { PureComponent } from "react";
import Table from './components/Table/pages/Table';
import styled from "../../../../styled/styled-components";
import Button from "../../../../components/Button/Button";
import Modal from "../../../../components/Modal";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import { convertToUtc, parseServerDate } from "../../../../utils";
import { NONE_VALUE } from "../../../../constants";
// import ApiClient from "../../../../services/api";

import moment from 'moment';
import PrintButton from "../../../../components/PrintButton/PrintButton";
// import { toast } from "react-toastify";

export interface TableProps {
  BillCode: string;
  BillId: string;
}

export interface TableState {
  showModal: boolean;
  dateFrom?: string;
  dateTo?: string;
}


const AbsoluteButton = styled(Button)`
    position: absolute;
    right: 0;
    top: 0;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Buttons = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

export default class Operations extends PureComponent<TableProps, TableState> {

  public state = {
    showModal: false,
    dateFrom: moment().subtract(1, 'years').toISOString(),
    dateTo: moment().toISOString(),
  };


  public render() {
    const { dateFrom, dateTo, showModal } = this.state;
    const { BillId } = this.props;
    return (
      // @ts-ignore
      <Wrapper><AbsoluteButton type="success" onClick={this.toggleModal} ><i className="fa fa-print"/>{' '}Печать детализации</AbsoluteButton><div><div className="panel-title text-center mt-4"><h3>Операции</h3><Table {...this.props} /></div></div>
        <Modal
          header="Важно! По умолчанию берётся период за год!"
          isOpen={showModal}
          onBackgroundClick={this.toggleModal}
          onEscapeKeydown={this.toggleModal}
        >
          <div className='row align-items-center ml-2'>
            За период с
            <DatePicker useStyledInput style={{'width': '150px', 'marginLeft': '5px', 'marginRight': '5px'}} onChange={this.privateChangeDateFrom} selected={dateFrom && typeof dateFrom === 'string' ? parseServerDate(dateFrom) : null} />
            по
            <DatePicker useStyledInput style={{'width': '150px', 'marginLeft': '5px', 'marginRight': '5px'}} onChange={this.privateChangeDateTo} selected={dateTo && typeof dateTo === 'string' ? parseServerDate(dateTo) : null} />
          </div>
          <Buttons>
            <PrintButton
              type="success"
              href={`/bill/BillHistoryPrint?id=${BillId}&dateF=${moment(dateFrom).format('YYYY-MM-DD')}&dateS=${moment(dateTo).format('YYYY-MM-DD')}`}
              // onClick={this.printDetalisation}
            >
              Печать
            </PrintButton>
          </Buttons>
        </Modal>
      </Wrapper>
    );
  }


  private toggleModal = () => {
    this.setState((state) => ({
      showModal: !state.showModal,
    }))
  };

  private privateChangeDateFrom = (date: Date, event: any) => {
    if (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value)) {
      const value = date ? convertToUtc(date).toISOString() : undefined;
      this.setState({
        'dateFrom': value === NONE_VALUE || value === "" ? undefined : value,
      });
    }
  };

  private privateChangeDateTo = (date: Date, event: any) => {
    if (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value)) {
      const value = date ? convertToUtc(date).toISOString() : undefined;
      this.setState({
        'dateTo': value === NONE_VALUE || value === "" ? undefined : value,
      });
    }
  };

  // private printDetalisation = async () => {
  //   const { BillId } = this.props;
  //   const { dateFrom, dateTo } = this.state;
  //   try {
  //
  //
  //     const result = await ApiClient.printHistoryTable(BillId, moment(dateTo).format('YYYY-MM-DD'), moment(dateFrom).format('YYYY-MM-DD'));
  //     const blob = new Blob([result], { type: "text/html" });
  //     const url = window.URL.createObjectURL(blob);
  //
  //     window.open(url);
  //   } catch (e) {
  //     const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
  //     toast(errorMessage, {
  //       type: toast.TYPE.ERROR
  //     });
  //   }
  // }
}
