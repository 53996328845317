import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router';
import { get } from 'lodash';
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import styled from "../../../styled/styled-components";
import {
  BillDetailsState,
  BillType,
  toggleSalaryBillRequest
} from "../../../store/billDetails";
import LoadingOverlay from "../../../components/LoadingOverlay";
import BillDetailsForm from '../components/BillDetailsForm';
import PlannedSettlement from '../components/PlannedSettlement';
import ClearBalance from '../components/ClearBalance';
import DepositConditionChanges from '../components/DepositConditionChanges';
import AccountIncome from '../components/AccountIncome';
import Button from "../../../components/Button/Button";
import { AppState } from "../../../store";
import Operations from "../components/Operations";
import Modal from "../../../components/Modal";
import PrintAccountStatement from "../../../components/PrintAccountStatement";
import PrintButton from "../../../components/PrintButton/PrintButton";
import Dropdown from "../../../components/Dropdown";
import { TabContent, TabLink, TabLinks } from "../../Partner/components/Views";
import { ID_SALARIES_PAYROLL } from "../../../constants/operationTypeCodes";
import BackLink from "../../../components/BackLink";
import { StyledBackLink } from "./CurrentDetail";


export interface BillDetailsProps {
  fetchBillDetailsRequest: (data: string) => void;
  BillId: string;
  PartnerNumber: string;
  BillCode: string;
  PartnerId: string;
  Balance: number;
  Status: BillType;
  toggleSalaryBillRequest: (data: { billId: string, billCode: string }) => void;
}

export interface BillDetailsLocalState {
  showModal: boolean;
}

const BillData = styled.div`
  border-bottom: 1px solid #e7eaec;
  margin: 0 -24px;
  padding: 0 24px 7px;
`;

const Buttons = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

export type TabType =
  | "main"
  | "AccountIncome"
  | "ClearBalance"
  | "DepositConditionChanges"
  | "PlannedSettlement";

export const TABS_LABELS = new Map<
  TabType,
  {
    label: string;
    component: typeof React.Component;
  }
  >([
  ["main", { label: "Операции", component: Operations }],
  ["AccountIncome", { label: "Доход по счету", component: AccountIncome }],
  ["ClearBalance", { label: "Чистый баланс", component: ClearBalance }],
  ["DepositConditionChanges", { label: "Изменения условий", component: DepositConditionChanges }],
  ["PlannedSettlement", { label: "Расчет текущего баланса на дату", component: PlannedSettlement }],
]);

class SalariesDetail extends PureComponent<BillDetailsState & BillDetailsProps & RouteComponentProps, BillDetailsLocalState> {

  public state = {
    showModal: false,
  };

  public render() {
    const { loading, PartnerNumber, BillCode, Status: { Value } } = this.props;
    const { showModal } = this.state;

    const tabsEneteries = [...TABS_LABELS.entries()];
    const currentTabName = this.props.location.hash.replace('#', '') || 'main';
    const currentTab = TABS_LABELS.get(currentTabName as TabType);

    const Component = currentTab ? currentTab.component : null;
    return(
      <div>
        <Helmet defer={false}>
          <title>Детализация</title>
        </Helmet>
        <StyledBackLink>
          <BackLink title={document.title} >
            <h4 className="text-center">Зарплатная программа</h4>
          </BackLink>
        </StyledBackLink>
        <LoadingOverlay loading={loading}>
          <BillData>
            {!loading && <BillDetailsForm />}
          </BillData>
          <div className="my-3">
            <Button type="primary" className='m-2' href={`/Operation/OperationProcess?operationTypeId=${ID_SALARIES_PAYROLL}&partnerNumber=${PartnerNumber}&billCode=${BillCode}&returnUrl=${encodeURIComponent(document.location.pathname)}${encodeURIComponent(document.location.search)}`}>
              Операции
            </Button>
            <Dropdown caption={"Распечатать документ ⬇︎"}>
              <div className="p-2">
                <PrintButton type="primary" className='d-block mb-2 w-100' href={`/Template/DecOfAccession/${PartnerNumber}`}>
                  Печать заявления о присоединении
                </PrintButton>
                <PrintButton type="primary" className='d-block mb-2 w-100' href={`/template/AccessionAgr?billcode=${BillCode}`}>
                  Печать договора присоединения
                </PrintButton>
                <PrintAccountStatement BillCode={BillCode} />
              </div>
            </Dropdown>
            <Button type={!Value ? 'warning' : 'success'} className='m-2' onClick={this.toggleModal}>
              {!Value ? 'Закрыть' : 'Открыть'} счет
            </Button>
          </div>
          <TabLinks>
            {tabsEneteries.map(([name, item]) => (
              <TabLink
                key={name}
                href={`${this.props.location.pathname}#${name}`}
                routerLink
                active={
                  (!this.props.location.hash && name === 'main') ||
                  this.props.location.hash === `#${name}`
                }
                component={
                  this.props.location.hash === `#${name}`
                    ? clickableProps => <span {...clickableProps} />
                    : undefined
                }
              >
                {item.label}
              </TabLink>
            ))}
          </TabLinks>
          <TabContent>{Component && <Component {...this.props} type="salaries" />}</TabContent>
          <Modal
            header={!Value ? 'Закрытие зарплатной программы' : 'Открытие зарплатной программы'}
            isOpen={showModal}
            onBackgroundClick={this.toggleModal}
            onEscapeKeydown={this.toggleModal}
          >
            <div className='mx-2'>
              Вы уверены, что хотите {!Value ? ' закрыть ' : ' октрыть '} счет?
            </div>
            <Buttons>
              <Button type="success" className="mr-2" onClick={this.toogleSalaryBill}>
                Да
              </Button>
              <Button type="default" onClick={this.toggleModal}>
                Нет
              </Button>
            </Buttons>
          </Modal>
        </LoadingOverlay>
      </div>
    )
  }

  private toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  private toogleSalaryBill = () => {
    const { toggleSalaryBillRequest, BillCode, BillId } = this.props;
    toggleSalaryBillRequest({ billId: BillId, billCode: BillCode });
    this.toggleModal();
  }
}

const mapStateToProps = (state: AppState) => ({
  loading: state.billDetails.loading,
  BillId: get(state, 'billDetails.data.BillId'),
  PartnerNumber: get(state.billDetails, 'data.PartnerNumber', ''),
  BillCode: get(state.billDetails, 'data.BillCode', ''),
  PartnerId: get(state.billDetails, 'data.PartnerId', ''),
  Balance: get(state.billDetails, 'data.Balance', 0),
  Status: {
    Value: get(state.billDetails, 'data.Status.Value', 0),
    Title: get(state.billDetails, 'data.Status.Title', '')
  },
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleSalaryBillRequest,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SalariesDetail);
