import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import moment from 'moment';

import ApiClient from "../../../../../services/api";
import {
  DEFAULT_FILTER_FIELDS,
  DEFAULT_SORT_FIELD_NAME,
  DEFAULT_SORT_DIRECTION,
  // AUTOCOMPLETE_FILTER_FIELDS,
  CALENDAR_FIELD_LABELS
} from "../constants";
import {
  BillDepositListItem,
  FilterFieldName,
  FilterRequest,
  OperatorType,
  FilterItemData
} from "../types";
import FilterConfigurator from "../../../../../components/BillTables/FilterConfigurator";
import { showErrorToast } from "../../../../../components/ErrorToast";
import TableList from "../../../../../components/BillTables/TableList";
import Pagination from "../../../../../components/Pagination/Pagination";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import { requestDataToQuery } from "../../../../../utils";
import { fetchLabelsRequest } from "../../../../../store/labels/actions";
export interface BillListProps {
  requestDate: string | null;
}

export interface BillListState {
  bills: BillDepositListItem[];
  filters: FilterRequest;
  cachedFilters: FilterRequest;
  visibleFields: Map<FilterFieldName<BillDepositListItem>, boolean>;
  filtersShown: boolean;
  fieldFilters: Map<FilterFieldName<BillDepositListItem>, FilterItemData>;
  autocompleteItems: {
    [fieldName in FilterFieldName<BillDepositListItem>]?: string[]
  };
  loading: boolean;
}

const DEFAULT_FILTERS = {
  CurrentPage: 1,
  FieldInfos: [
    {
      FieldName: "Id" as FilterFieldName<BillDepositListItem>,
      Method: 1,
      Value: null,
      ValueType: 2
    },
    {
      FieldName: "Date" as FilterFieldName<BillDepositListItem>,
      Value: null,
      Method: 4,
      ValueType: 4
    },
    {
      FieldName: "Type" as FilterFieldName<BillDepositListItem>,
      Value: null,
      Method: 0,
      ValueType: 5
    },
    {
      FieldName: "Direction" as FilterFieldName<BillDepositListItem>,
      Value: null,
      Method: 4,
      ValueType: 3
    },
    {
      FieldName: "BillCodeFrom" as FilterFieldName<BillDepositListItem>,
      Value: null,
      Method: 1,
      ValueType: 1
    },
    {
      FieldName: "BillCodeTo" as FilterFieldName<BillDepositListItem>,
      Value: null,
      Method: 1,
      ValueType: 1
    },
    {
      FieldName: "Total" as FilterFieldName<BillDepositListItem>,
      Value: null,
      Method: 1,
      ValueType: 2
    },
    {
      FieldName: "CurrentBalance" as FilterFieldName<BillDepositListItem>,
      Value: null,
      Method: 1,
      ValueType: 2
    },
  ],
  IsPaging: true,
  Operator: 1 as OperatorType,
  PageSize: 25,
  SortDirection: DEFAULT_SORT_DIRECTION,
  SortFieldName: DEFAULT_SORT_FIELD_NAME,
  TotalPages: 1
};

const getDefaultVisibleFields = (
  map: Map<FilterFieldName<BillDepositListItem>, FilterItemData>
) => {
  const result = new Map<FilterFieldName<BillDepositListItem>, boolean>();

  map.forEach(item => {
    result.set(item.FieldName, true);
  });

  return result;
};

class Table extends React.Component<
  BillListProps,
  BillListState
> {
  public state: BillListState = {
    bills: [],
    visibleFields: getDefaultVisibleFields(DEFAULT_FILTER_FIELDS),
    filtersShown: false,
    filters: DEFAULT_FILTERS,
    cachedFilters: DEFAULT_FILTERS,
    fieldFilters: DEFAULT_FILTER_FIELDS,
    autocompleteItems: {},
    loading: false
  };


  private listFieldLabels = CALENDAR_FIELD_LABELS;
  // @ts-ignore
  private billsTableElement = React.createRef<TableList<BillDepositListItem>>();

  public componentDidMount() {
    this.getData();
  }

  public componentDidUpdate(prevProps: BillListProps): void {
    const { requestDate } = this.props;
    if (prevProps.requestDate !== requestDate) {
      this.handleChangeFilter(    {
        FieldName: "Date",
        Method: requestDate && requestDate.length < 8 ? 1 : 4,
        Value: requestDate,
        ValueType: 4
      })
    }
  }

  public render() {
    return (
      <LoadingOverlay loading={this.state.loading}>
        <FilterConfigurator
          labels={this.listFieldLabels}
          fields={this.state.visibleFields}
          handleChangeColumns={this.handleChangeColumns}
          filtersShown={this.state.filtersShown}
          toggleFiltersVisible={this.toggleFiltersVisible}
          handleChangeOperator={this.handleChangeOperator}
          operatorId={this.state.filters.Operator}
          resetAllFilters={this.resetAllFilters}
          acceptFilter={this.acceptFilter}
        />
        <TableList
          data={this.state.bills}
          notUseActive
          visibleFields={this.state.visibleFields}
          sortProps={{
            sortFieldName: this.state.filters.SortFieldName,
            sortDirection: this.state.filters.SortDirection,
            onChangeSort: this.handleChangeSort
          }}
          filtersProps={{
            fields: [...this.state.fieldFilters.values()],
            onChangeFilter: this.handleChangeFilter,
            acceptFilter: this.acceptFilter,
            resetFilter: this.resetFilter,
            filtersShown: this.state.filtersShown,
            autocompleteItems: this.state.autocompleteItems
          }}
          createLink={this.createLink}
          ref={this.billsTableElement}
          labelsSection={'CALENDAR_FIELD_LABELS'}
        />
        {this.state.filters.TotalPages > 1 && (
          <Pagination
            size={this.state.filters.PageSize}
            count={this.state.filters.TotalPages * this.state.filters.PageSize}
            page={this.state.filters.CurrentPage}
            callback={this.handleChangePage}
          />
        )}
      </LoadingOverlay>
    );
  }

  private getData = async (
    filter: Partial<FilterRequest> = {},
    forceGoToDetails?: boolean
  ) => {
    const queryString = requestDataToQuery({
      SortFieldName: this.state.filters.SortFieldName,
      SortDirection: this.state.filters.SortDirection,
      CurrentPage: this.state.filters.CurrentPage,
      PageSize: this.state.filters.PageSize,
      IsPaging: this.state.filters.IsPaging,
      ...filter});

    this.setState({ loading: true });
    try {
      const result = await ApiClient.getCalendarTableFilters(queryString);

      if (forceGoToDetails && result.DataSource.length === 1) {
        this.setState({ loading: false }, () => {
          // this.props.history.replace(
          //   this.getLinkToDetails(result.DataSource[0])
          // );
        });
      }

      this.setState({
        bills: result.DataSource,
        filters: result.FilterRequest,
        cachedFilters: result.FilterRequest,
        fieldFilters: this.extractFieldFilters(result.FilterRequest)
      });
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private handleChangeColumns = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: columnName, checked } = event.target;

    this.setState(state => {
      const filterFieldName = columnName as FilterFieldName<BillDepositListItem>;
      const newVisibleFields = new Map(state.visibleFields);

      newVisibleFields.set(filterFieldName, checked);

      return {
        visibleFields: newVisibleFields
      };
    });
  };

  private toggleFiltersVisible = () => {
    this.setState(state => ({ filtersShown: !state.filtersShown }));
  };

  private handleChangeOperator = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(event.target.value, 10);

    this.setState(state => ({
      filters: {
        ...state.filters,
        Operator: value as OperatorType
      }
    }));
  };

  private handleChangeFilter = (
    filterItemData: FilterItemData,
    forceFiltering?: boolean
  ) => {
    forceFiltering = forceFiltering || filterItemData.ValueType === 4 || filterItemData.ValueType === 5;
    const forceGoToDetails = false;
    // if (forceFiltering && filterItemData.FieldName === "Date") {
    //   forceGoToDetails = true;
    // }

    // Для работы кнопок "весь месяц" и "весь год" проверяем длину
    if (filterItemData.FieldName === "Date" && filterItemData.Value && filterItemData.Value.length > 7) {
      filterItemData.Value = moment(filterItemData.Value).format('YYYY-MM-DD');
    }

    this.setState(
      state => {
        const newFilters = new Map(state.fieldFilters);
        const currentFilterItem = newFilters.get(filterItemData.FieldName);

        if (currentFilterItem) {
          newFilters.set(filterItemData.FieldName, filterItemData);
        }

        return {
          fieldFilters: newFilters,
          filters: {
            ...state.filters,
            FieldInfos: this.getFieldInfos(newFilters)
          }
        };
      },
      () => {
        // this.proccessAutocomplete(filterItemData);
        if (forceFiltering) {
          this.acceptFilter(forceGoToDetails);
        }
      }
    );
  };

  private acceptFilter = (forceGoToDetails?: boolean) => {
    const { FieldInfos, Operator } = this.state.filters;

    this.getData(
      {
        Operator,
        FieldInfos: FieldInfos.filter(item => item && item.Value !== null),
        CurrentPage: 1
      },
      forceGoToDetails
    );
  };

  private resetAllFilters = () => {
    this.getData();
  };

  private extractFieldFilters = (filterRequest: FilterRequest) => {
    const fieldsData = new Map(DEFAULT_FILTER_FIELDS);

    filterRequest.FieldInfos.forEach(item => {
      if (item && fieldsData.get(item.FieldName)) {
        fieldsData.set(item.FieldName, item);
      }
    });

    return fieldsData;
  };

  private getFieldInfos = (
    fieldFilters: Map<FilterFieldName<BillDepositListItem>, FilterItemData>
  ) => {
    return [...fieldFilters.values()];
  };

  private handleChangeSort = (fieldNamme: FilterFieldName<BillDepositListItem>) => {
    this.setState(
      state => {
        const currentSortFieldName = state.filters.SortFieldName;
        const curretSortDirection = state.filters.SortDirection;

        const newSortDirection =
          currentSortFieldName !== fieldNamme
            ? 1
            : curretSortDirection === 1
            ? 2
            : 1;

        return {
          filters: {
            ...state.filters,
            SortFieldName: fieldNamme,
            SortDirection: newSortDirection
          }
        };
      },
      () => {
        this.getData({
          Operator: this.state.filters.Operator,
          FieldInfos: this.state.filters.FieldInfos.filter(
            item => item && item.Value !== null
          )
        });
      }
    );
  };

  private resetFilter = (fieldName: keyof BillDepositListItem) => {
    const filterItemData = DEFAULT_FILTERS.FieldInfos.find(
      ({ FieldName }) => FieldName === fieldName
    );

    if (filterItemData) {
      this.handleChangeFilter(filterItemData, true);
    }
  };

  private handleChangePage = (page: number) => {
    this.getData({
      Operator: this.state.filters.Operator,
      FieldInfos: this.state.filters.FieldInfos.filter(
        item => item && item.Value !== null
      ),
      CurrentPage: page
    });
  };

  private createLink = (item: BillDepositListItem) => {
    return {
      link: this.getLinkToDepositView(item),
      fieldName: "BillCode" as keyof BillDepositListItem
    };
  };

  private getLinkToDepositView = (item: BillDepositListItem) => {
    return `/Bill/Details/${item.BillCode}`;
  };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchLabelsRequest: (section: string, billType?: string) => dispatch(fetchLabelsRequest(section, billType)),
});

export default connect(undefined, mapDispatchToProps)(Table)
