import * as React from "react";
import { isEmpty } from 'lodash';
import { saveAs } from 'file-saver';


import LoadingOverlay from "../../../../components/LoadingOverlay";
import { ClientsContext } from "../../pages/Client";
import { FormWrapper } from "../Views";
import ApiClient from "../../../../services/api";

const types: any = {
  Passport: 'Паспорт РФ',
  InternationalPassport: 'Загранпаспорт',
  Ulm: 'УЛМ',
  SailingBook: 'Мореходная книжка',
  Snils: 'СНИЛС',
  Inn: 'ИНН',
};

export default class CopyDocuments extends React.PureComponent {
  public render() {
    return (
      <ClientsContext.Consumer>
        {({
            copyDocuments,
          }) => (
          <FormWrapper>
            <LoadingOverlay loading={false}>
              {isEmpty(copyDocuments) && <div>Клиент не предоставил копии документов</div>}
              {!isEmpty(copyDocuments) &&
                <div>
                  {copyDocuments && copyDocuments.map((item) => (
                    <div className="p-2">
                      <a onClick={(e: any) => {
                        e.preventDefault();
                        this.download(item);
                      }} >{types[item.DocumentType]}</a>
                    </div>
                  ))}
                </div>
              }
            </LoadingOverlay>
          </FormWrapper>
        )}
      </ClientsContext.Consumer>
    );
  }

  private download = async (item: any) => {
    const { data } = await ApiClient.downloadBlobFile(item.Url);
    saveAs(data, `${item.DocumentType}${item.Extension}`);
  }
}
