import React from 'react';
import { SimpleMaskedInput } from '../../../../../components/FormControls/FormControls';
import { PartnerCard } from '../../../types';
import styled from '../../../../../styled/styled-components';

const getFullCardNumber = (item: PartnerCard) => `${item.FirstDigits} ****\u00A0**** ${item.LastDigits}`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const Input = styled(SimpleMaskedInput)`
  width: 4rem;
  margin: 0 1rem;
`;

interface CardNumberFieldProps {
  editing: boolean;
  data: PartnerCard;
  onChange: (key: 'LastDigits' | 'FirstDigits') => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function CardNumberField(props: CardNumberFieldProps) {
  const { editing, data, onChange } = props;
  return editing
    ? (
      <Container>
        <Input
          onChange={onChange('FirstDigits')}
          value={data.FirstDigits}
          mask="9999"
        />
        <span> ****&nbsp;**** </span>
        <Input
          onChange={onChange('LastDigits')}
          value={data.LastDigits}
          mask="9999"
        />
      </Container>
    )
    : (
      <span>{getFullCardNumber(data)}</span>
    );
}

export function onChangeCardNumberField(data: PartnerCard[], onChange: (data: PartnerCard[]) => void) {
  return (index: number) =>
    (key: 'LastDigits' | 'FirstDigits') =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const newData = [...data];
        const { value } = event.currentTarget;
        newData[index][key] = value;
        onChange(newData);
      };
}
