import * as React from "react";
import { Helmet } from 'react-helmet';
import { isEqual, get, trim } from "lodash";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";

import ApiClient from "../../../services/api";
import { UserData, ClientTabType, Agent, LoanForm, CheckList } from "../types";
import { TabContent, TabLink, TabLinks } from "../components/Views";
import { CLIENT_TABS_LABELS, REQUIRED_FIELDS } from "../constants";
import SectionHeader from "../../../components/SectionHeader";
import Link from "../../../components/Link";
import { ArrowLeft } from "styled-icons/fa-solid";
import ConfirmModal from "../../../components/PartnersAndClients/ConfirmModal";
import ClientHeaderButtons from "../components/ClientHeaderButtons";
import { Location, UnregisterCallback } from "history";
import { Nullable } from "../../../types/typeUtils";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { getPartnerNumberFromUserdata } from "../../Partner/utils";
import PrintDocuments from "../components/PrintDocuments";
import { showErrorToast } from "../../../components/ErrorToast";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { LOAN_FORM_CLIENT_FIELDS, LOAN_FORM_CREDIT_FIELDS, LOAN_FORM_REASON_FIELDS } from "../constants";
import { AdditionalPhone } from "../../Partner/types";
export interface Document {
  DocumentType: string;
  Url: string;
  Extension: string;
}

export interface ClientProps
  extends RouteComponentProps<{ mode: string; clientId?: string }> {}

export interface ClientState {
  userData: UserData | null;
  checkList: CheckList | null;
  cachedCheckList: CheckList | null;
  cachedUserData: UserData | null;
  isChanged: boolean;
  errors: Map<string, string[]>;
  userLabels: Map<number, string>;
  managers: Map<number, string>;
  currencies: Map<number, string>;
  users: Map<number, string>;
  loanForm: Nullable<LoanForm>;
  cachedLoanForm: Nullable<LoanForm>;
  allowPrintLoanForm: boolean;
  loading: boolean;
  copyDocuments?: Document[];
  showRejectConfirmModal: number | null;
  showDeleteConfirmModal: number | null;
}

export interface ClientContextType {
  userData: UserData | null;
  checkList: CheckList | null;
  editing: boolean;
  copyDocuments?: Document[];
  handleChange?: (
    ectionName: keyof UserData | [keyof UserData, string] | null,
    fieldName: string,
    value: any,
    additioanalData?: any
  ) => void;
  errors: Map<string, string[]>;
  valueLabels: {
    userLabels: Map<number, string>;
    managers: Map<number, string>;
    currencies: Map<number, string>;
    users: Map<number, string>;
  };
  loanForm: Nullable<LoanForm>;
  allowPrintLoanForm: boolean;
  handleChangeLoanForm: (fieldName: keyof LoanForm, value: any) => void;
  handleChangeCheckList: (fieldName: keyof CheckList, value: any) => void;
  isCanSaveLoanForm: boolean;
  saveLoanForm: () => void;
  printLoanForm: () => void;
  copyAddress?: () => void;
}

export const ClientsContext = React.createContext<ClientContextType>({
  userData: null,
  checkList: null,
  editing: false,
  errors: new Map(),
  copyDocuments: undefined,
  valueLabels: {
    userLabels: new Map(),
    managers: new Map(),
    currencies: new Map(),
    users: new Map()
  },
  loanForm: null,
  handleChangeLoanForm: () => void 0,
  handleChangeCheckList: () => void 0,
  isCanSaveLoanForm: false,
  saveLoanForm: () => void 0,
  printLoanForm: () => void 0,
  copyAddress: () => void 0,
  allowPrintLoanForm: false,
});

export default class Client extends React.Component<ClientProps, ClientState> {
  public state: ClientState = {
    userData: null,
    checkList: null,
    cachedCheckList: null,
    cachedUserData: null,
    isChanged: false,
    errors: new Map(),
    userLabels: new Map(),
    managers: new Map(),
    currencies: new Map(),
    users: new Map(),
    loanForm: null,
    cachedLoanForm: null,
    showRejectConfirmModal: null,
    showDeleteConfirmModal: null,
    loading: false,
    copyDocuments: undefined,
    allowPrintLoanForm: false,
  };

  private confirmCancelModal = React.createRef<ConfirmModal>();
  private confirmSaveModal = React.createRef<ConfirmModal>();

  private confirmDialog = React.createRef<ConfirmDialog>();
  private confirmRejectModal = React.createRef<ConfirmModal>();
  private confirmDeleteModal = React.createRef<ConfirmModal>();


  private transitionBlocker: UnregisterCallback | null = null;

  public componentDidMount() {
    if (!this.transitionBlocker) {
      this.transitionBlocker = this.props.history.block(
        this.handleChangeLocation
      );
    }

    if (this.props.match.params.mode === "create") {
      this.getClientData();
    } else {
      this.getUserData();
    }

    this.getOptions();
  }
  public componentWillUnmount() {
    if (this.transitionBlocker) {
      this.transitionBlocker();
    }
  }

  public render() {
    const tabsEneteries = [...CLIENT_TABS_LABELS.entries()];
    const currentTabName = this.props.location.hash.replace("#", "") || "checklist";
    const currentTab = CLIENT_TABS_LABELS.get(currentTabName as ClientTabType);

    const Component = currentTab ? currentTab.component : null;
    const IsDeleted = this.state.userData ? this.state.userData.IsDeleted : false;

    return (
      <ClientsContext.Provider
        value={{
          userData: this.state.userData,
          checkList: this.state.checkList,
          editing: this.isEditing(),
          handleChange: this.handleChange,
          handleChangeLoanForm: this.handleChangeLoanForm,
          handleChangeCheckList: this.handleChangeCheckList,
          errors: this.state.errors,
          loanForm: this.state.loanForm,
          valueLabels: {
            userLabels: this.state.userLabels,
            managers: this.state.managers,
            currencies: this.state.currencies,
            users: this.state.users
          },
          isCanSaveLoanForm: this.isCanSaveLoanForm(),
          saveLoanForm: this.saveLoanForm,
          printLoanForm: this.printLoanForm,
          copyAddress: this.copyAddress,
          copyDocuments: this.state.copyDocuments,
          allowPrintLoanForm: this.state.allowPrintLoanForm,
        }}
      >
        <LoadingOverlay loading={this.state.loading}>
          <Helmet defer={false}>
            <title>
              {this.props.match.params.mode === 'create'
                ? 'Создание клиента'
                : this.state.userData &&
                  `${this.state.userData.FirstName} ${this.state.userData.LastName}`}
            </title>
          </Helmet>
          <SectionHeader heading="Анкета клиента" link={this.renderBackLink()}>
            {this.renderHeaderButtons()}
          </SectionHeader>
          <TabLinks>
            {tabsEneteries.map(([name, item]) => (
              <TabLink
                key={name}
                href={this.props.location.pathname + "#" + name}
                routerLink
                active={
                  (!this.props.location.hash && name === "checklist") ||
                  this.props.location.hash === "#" + name
                }
                component={
                  this.props.location.hash === "#" + name
                    ? clickableProps => <span {...clickableProps} />
                    : undefined
                }
                error={this.isTabHasErrors(name)}
              >
                {item.label}
              </TabLink>
            ))}
          </TabLinks>
          <TabContent>
            {this.renderPrintButtons()}
            {Component && <Component />}
          </TabContent>
          <ConfirmModal
            message="Вы действительно хотите отменить изменения?"
            successLabel="Да"
            cancelLabel="Нет"
            onConfirm={this.handleConfirmCancel}
            ref={this.confirmCancelModal}
          />
          <ConfirmModal
            message="Сохранить изменения?"
            successLabel="Да"
            cancelLabel="Нет"
            onConfirm={this.handleConfirmSave}
            ref={this.confirmSaveModal}
          />
          <ConfirmModal
            message={IsDeleted ? "Вы действительно хотите восстановить заявку?" : "Вы действительно хотите отклонить заявку?"}
            successLabel="Да"
            cancelLabel="Нет"
            onConfirm={this.rejectApplication}
            onCancel={this.closeRejectConfirmModal}
            ref={this.confirmRejectModal}
          />
          <ConfirmModal
            message="Вы действительно хотите удалить заявку на всегда?"
            successLabel="Да"
            cancelLabel="Нет"
            onConfirm={this.deleteApplication}
            onCancel={this.closeDeleteConfirmModal}
            ref={this.confirmDeleteModal}
          />
          <ConfirmDialog ref={this.confirmDialog} />
        </LoadingOverlay>
      </ClientsContext.Provider>
    );
  }

  private getUserData = async () => {
    const { clientId } = this.props.match.params;

    this.setState({ loading: true });
    try {
      this.handleFetchedData(await ApiClient.getClient(clientId));
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private getCheckList = async (clientId: number) => {

    if (!clientId) {

      this.setState({
        checkList: {
          ClientId: 0,
          VideoVerificationStatus: 0,
          SecurityServiceStatus: 0,
          IsEntryFeePaid: false,
          IsStatementAgree: false,
          EntryFeeComment: '',
        },
        cachedCheckList: {
          ClientId: 0,
          VideoVerificationStatus: 0,
          SecurityServiceStatus: 0,
          IsEntryFeePaid: false,
          IsStatementAgree: false,
          EntryFeeComment: '',
        }
      })
      return ;
    }

    this.setState({ loading: true });
    try {
      const checkList = await ApiClient.getCheckList(clientId);
      this.setState({
        checkList,
        cachedCheckList: checkList
      })
    } catch (error) {
      showErrorToast(error);
    }
  };


  private handleFetchedData = (result: UserData) => {
    const formatted = this.formatData(result);
    this.setState({
      userData: formatted,
      cachedUserData: formatted,
      isChanged: false,
      errors: new Map()
    });

    this.getLoanForm(result.Id);
    this.getCopyDocuments(result.Id);
    this.getCheckList(result.Id);
  };

  private formatData = (userData: UserData) => {
    const { Snils, PartnerQuestionnaire } = userData;
    const snilsFormatter = (value: string) => {
      const filtered = value.replace(/\D/g, '');
      const first = filtered.slice(0, 3);
      const second = filtered.slice(3, 6);
      const third = filtered.slice(6, 9);
      const fourth = filtered.slice(9, 11);

      if (!first) {
        return '';
      }
      if (!second) {
        return first;
      }
      if (!third) {
        return `${first}-${second}`;
      }
      if (!fourth) {
        return `${first}-${second}-${third}`;
      }
      return `${first}-${second}-${third} ${fourth}`;
    };
    return {
      ...userData,
      AdditionalPhones: userData.AdditionalPhones || [],
      PartnerQuestionnaire: PartnerQuestionnaire ? PartnerQuestionnaire : {
        MaritalStatus: '', // Семейное положение или личный статус
        HaveChildrens: false, // Наличие детей/внуков
        ChildrensAge: '', // Возраст каждого ребенка
        LivingPlace: '', // Место проживания
        SeaCareer: '', // Род деятельности на судне
        LandCareer: '', // Род деятельности на берегу
        Hobby: '', // Увлечение, хобби на берегу
        Messengers: '', // Какими месседжерами пользуется чаще всего
        SocialNetworks: '', // Какими соц сетями пользуется чаще всего
        MFondProducts: '', // Какими услугами пользовался в Морском Фонде
        Feedback: '',
      },
      Snils: snilsFormatter(Snils || '')
    };
  };

  private goToList = () => {
    this.props.history.replace("/applications");
  };

  private renderBackLink = () => (
    <Link onClick={this.goToList}>
      <ArrowLeft size={13} /> Вернуться к списку
    </Link>
  );

  private renderHeaderButtons = () => {
    const returnUrl = encodeURIComponent(this.props.location.pathname);
    const { clientId, mode } = this.props.match.params;
    const { hash } = this.props.location;
    const isDisabledSave =
      !this.state.isChanged && this.props.match.params.mode !== "create";

    const clickSaveToPartnerHandler =
      typeof clientId === "string"
        ? this.handleClickSaveToPartner.bind(null, parseInt(clientId, 10), false)
        : () => void 0;

    const rejectModal =
      typeof clientId === "string"
        ? this.openRejectConfirmModal.bind(null, parseInt(clientId, 10))
        : () => void 0;

    const deletetModal =
      typeof clientId === "string"
        ? this.openDeleteConfirmModal.bind(null, parseInt(clientId, 10))
        : () => void 0;

    const clickSaveToPartnerRaising =
      typeof clientId === "string"
        ? this.handleClickSaveToPartner.bind(null, parseInt(clientId, 10), true)
        : () => void 0;

    return (
      <ClientHeaderButtons
        isDisabledSave={isDisabledSave}
        isDeleted={this.state.userData ? this.state.userData.IsDeleted : true}
        isEditing={mode === "edit"}
        isCreating={mode === "create"}
        returnUrl={returnUrl}
        clientId={clientId}
        hash={hash}
        handleClickSave={this.handleClickSave}
        handleClickCancel={this.handleClickCancel}
        handleRejectApplication={rejectModal}
        handleDeleteApplication={deletetModal}
        handleClickSaveToPartner={clickSaveToPartnerHandler}
        handleClickSaveToPartnerRaising={clickSaveToPartnerRaising}
      />
    );
  };

  private rejectApplication = async () => {
    const { showRejectConfirmModal } = this.state;
    if (!showRejectConfirmModal) {
      return ;
    }

    this.setState({ loading: true });
    await ApiClient.rejectClientData(showRejectConfirmModal);
    this.props.history.replace(`/applications`);
  };

  private deleteApplication = async () => {
    const { showDeleteConfirmModal } = this.state;
    if (!showDeleteConfirmModal) {
      return ;
    }

    this.setState({ loading: true });
    await ApiClient.deleteClientData(showDeleteConfirmModal);
    this.props.history.replace(`/applications`);
  };

  private openRejectConfirmModal = (id: number) => {
    this.setState({
      showRejectConfirmModal: id
    }, () => {
      const confirmModal = this.confirmRejectModal.current;
      if (confirmModal && id) {
        confirmModal.open();
      }
    })
  };

  private openDeleteConfirmModal = (id: number) => {
    this.setState({
      showDeleteConfirmModal: id
    }, () => {
      const confirmModal = this.confirmDeleteModal.current;
      if (confirmModal && id) {
        confirmModal.open();
      }
    })
  };

  private closeRejectConfirmModal = () => {
    this.setState({
      showRejectConfirmModal: null,
    })
  };

  private closeDeleteConfirmModal = () => {
    this.setState({
      showDeleteConfirmModal: null,
    })
  };

  private copyAddress = () => {
    this.setState(state => ({
      isChanged: true,
      userData: state.userData
        ? {
            ...state.userData,
            PartnerAddressMail: state.userData.PartnerAddressRegistration
          }
        : state.userData
    }));
  };

  private handleChange = (
    sectionName: keyof UserData | [keyof UserData, string] | null,
    fieldName: string,
    value: any,
    additionalData?: any,
  ) => {
    const { userData } = this.state;
    if (!userData) {
      return;
    }

    if (additionalData && fieldName === 'PhoneNumber') {
      // @ts-ignore
      this.setState((state) => ({
        ...state,
        isChanged: true,
        userData: {
          ...state.userData,
          PhoneNumber: value.toString(),
          PhoneCountryId: additionalData.id,
        }
      }));

      return;
    }

    if (additionalData && ['PhoneHome', 'PhoneMobileAdd'].includes(fieldName)) {
      const phoneType = fieldName === 'PhoneMobileAdd' ? 1 : 2;
      let currentPhones = this.state.userData ? this.state.userData.AdditionalPhones : [];
      const additionalPhone = currentPhones.find((phone: AdditionalPhone) => phone.Type === phoneType);

      const newData = {
        ...(additionalPhone || {}),
        Number: value.toString(),
        Code: additionalData.dialCode,
        Country: additionalData.name,
        CountryId: additionalData.id,
        Type: phoneType,
        FullPhoneNumber: `+${additionalData.dialCode}${value.toString()}`
      };

      if (additionalPhone) {
        currentPhones = currentPhones.map((phone: AdditionalPhone) => {
          if (phone.Type === phoneType) {

            return newData;
          }

          return phone;
        })
      } else {
        currentPhones.push(newData);
      }
      // @ts-ignore
      this.setState((state) => ({
        ...state,
        isChanged: true,
        userData: {
          ...state.userData,
          AdditionalPhones: currentPhones,
        }
      }));

      return;
    }

    if (Array.isArray(sectionName)) {
      this.setState(state => {
        const currentUserData = state.userData;

        if (!currentUserData) {
          return state;
        }

        const currentSection = currentUserData[sectionName[0]];

        if (!currentSection || typeof currentSection !== "object") {
          return state;
        }

        // TODO: придумать универсальное рещение не только для агента
        const currentSubSection = (currentSection as Agent)[
          sectionName[1] as keyof Agent
        ];

        if (!currentSubSection || typeof currentSubSection !== "object") {
          return state;
        }

        return {
          ...state,
          userData: {
            ...currentUserData,
            [sectionName[0]]: {
              ...currentSection,
              [sectionName[1]]: {
                ...currentSubSection,
                [fieldName]: value
              }
            }
          }
        };
      });

      return;
    }

    if (sectionName && userData[sectionName]) {
      this.setState(state => ({
        userData: {
          ...state.userData!,
          [sectionName]: {
            ...(state.userData![sectionName] as { [key: string]: any }),
            [fieldName as string]: value
          }
        },
        isChanged: true
      }));

      return;
    }

    if (userData.hasOwnProperty(fieldName)) {
      this.setState(state => ({
        userData: {
          ...state.userData!,
          [fieldName as string]: value
        },
        isChanged: true
      }));

      return;
    }
  };

  private handleClickCancel = () => {
    const confirmModal = this.confirmCancelModal.current;
    const isCreate = this.props.match.params.mode === "create";
    const { isChanged } = this.state;

    if (!isChanged && !isCreate) {
      this.goToInfo();
    }

    if ((isChanged || isCreate) && confirmModal) {
      confirmModal.open();
    }
  };

  private resetChanges = () => {
    this.setState(
      state => ({
        userData: state.cachedUserData,
        checkList: state.cachedCheckList,
        isChanged: false
      }),
      this.goToInfo
    );
  };

  private goToInfo = (newId?: number) => {
    if (newId !== undefined) {
      this.props.history.replace(`/applications/info/${newId}`);
    } else {
      this.props.history.replace(
        this.props.location.pathname.replace("edit", "info")
      );
    }
  };

  private handleClickSave = () => {
    if (
      (this.state.isChanged || this.props.match.params.mode === "create") &&
      this.confirmSaveModal.current
    ) {
      this.confirmSaveModal.current.open();
    }
  };

  private save = async () => {
    const errors = this.validate();
    const { isChanged } = this.state;

    if (errors.size) {
      this.setState({ errors });

      showErrorToast({ Message: "Заполните обязательные поля" });
      return;
    }

    this.setState({
      loading: true
    });
    try {
      const normalized = this.normalizeData(this.state.userData);
      const result = await ApiClient.createOrUpdateClient(normalized);
      await ApiClient.updateCheckList(result.Id, this.state.checkList);
      this.handleFetchedData(result);
      this.goToInfo(result.Id);
      toast("Данные успешно обновлены", { type: toast.TYPE.SUCCESS });
    } catch (error) {
      showErrorToast(error);
      this.setState({ isChanged });
    } finally {
      this.setState({ loading: false });
    }
  };

  private normalizeData(userData: UserData | null) {
    if (!userData) {
      return userData;
    }

    const { Snils } = userData;
    const nSnils = Snils ? Snils.replace(/\D/g, '') : Snils;
    return { ...userData, Snils: nSnils };
  }

  private getClientData = async () => {
    this.setState({ loading: true });
    try {
      this.handleFetchedData(await ApiClient.getInitialClientData());
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private isEditing = () => {
    return (
      this.props.match.params.mode === "create" ||
      this.props.match.params.mode === "edit"
    );
  };

  private handleConfirmCancel = () => {
    if (this.props.match.params.mode === "edit") {
      this.resetChanges();
    }

    if (this.props.match.params.mode === "create") {
      this.goToList();
    }
  };

  private handleConfirmSave = () => {
    this.setState({
      isChanged: false,
    }, () => this.save());
  };

  private handleChangeLocation = (location: Location) => {
    if (
      (!this.props.location.state && location.state) ||
      this.props.location.pathname === location.pathname
    ) {
      return;
    }

    if (this.state.isChanged) {
      return "Вы действительно хотите отменить изменения?";
    }

    if (this.props.match.params.mode === "create") {
      return "Вы действительно хотите отменить изменения?";
    }
  };

  private getOptions = async () => {
    try {
      const result = await ApiClient.getAllForOptions();
      const { UserLabels, Managers, Currencies, Users } = result as {
        [key: string]: Map<number, string>;
      };

      this.setState({
        userLabels: UserLabels,
        managers: Managers,
        currencies: Currencies,
        users: Users
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  private validate = () => {
    const { userData } = this.state;
    const requiredFieldsErrors = new Map<string, string[]>();

    if (!userData) {
      return requiredFieldsErrors;
    }

    const getSection = (name: string) => {
      switch (name) {
        case "UserData":
        case "PlaceOfBirth":
          return userData;

        case "PartnerAddress":
          return userData.PartnerAddressRegistration;

        default:
          return userData[name as keyof UserData];
      }
    };

    for (const item of REQUIRED_FIELDS.entries()) {
      const [sectionName, fields] = item;
      const errorFields: string[] = [];

      fields.forEach(fieldName => {
        const section = getSection(sectionName);

        if (section && !section[fieldName as keyof typeof section]) {
          errorFields.push(fieldName);
        }
      });

      if (errorFields.length) {
        requiredFieldsErrors.set(sectionName, errorFields);
      }
    }

    return requiredFieldsErrors;
  };

  private isTabHasErrors = (name: ClientTabType) => {
    switch (name) {
      case "main":
        return !!this.state.errors.get("UserData");

      case "address":
        return (
          !!this.state.errors.get("PartnerAddress") ||
          !!this.state.errors.get("PlaceOfBirth")
        );

      case "documents":
        return !!this.state.errors.get("Passport");

      default:
        return false;
    }
  };

  private getLoanForm = async (id: number) => {
    try {
      const result = await ApiClient.getClientLoanForm(id);
      const loanForm = {
        ...result,
        CurrencyId: result.CurrencyId === 0 ? 1 : result.CurrencyId
      };

      this.setState({
        loanForm,
        cachedLoanForm: loanForm
      });
      this.checkAllowPrintLoanForm(result);
    } catch (error) {
      showErrorToast(error);
    }
  };

  private getCopyDocuments = async (id: number) => {
    try {
      const copyDocuments = await ApiClient.getClientCopyDocuments(id);

      this.setState({
        copyDocuments,
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  private handleClickSaveToPartner = (id: number, isRaising: boolean) => {
    if (this.confirmDialog.current) {
      this.confirmDialog.current.open(
        isRaising ? "Вы действительно хотите перевести заявку в ЗП проект?" : "Вы действительно хотите перевести клиента в пайщики?",
        (allow: boolean) => {
          if (allow) {
            this.saveToPartner(id, isRaising);
          }
        }
      );
    }
  };

  private saveToPartner = async (id: number, isSalary: boolean) => {
    this.setState({ loading: true });
    try {
      let result: UserData;

      if (isSalary) {
        result = await ApiClient.saveToPartnerSalary(id);
      } else {
        result = await ApiClient.saveToPartner(id);
      }
      const partnerNumber = getPartnerNumberFromUserdata(result);

      if (partnerNumber) {
        this.goToPartner(partnerNumber);
      }
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private goToPartner = (partnerNumber: string) => {
    this.props.history.replace(`/client/info/${partnerNumber}`, {
      after: "client"
    });
  };

  private handleChangeLoanForm = (fieldName: keyof LoanForm, value: any) => {
    this.setState(state => {
      const loanForm = state.loanForm || {};

      return {
        loanForm: {
          ...loanForm,
          [fieldName]: fieldName === "Sum" ? parseFloat(value) || 0 : value
        }
      };
    });
  };

  private handleChangeCheckList = (fieldName: keyof CheckList, value: any) => {
    // @ts-ignore
    this.setState(state => {
      const checkList = state.checkList || {};

      return {
        isChanged: true,
        checkList: {
          ...checkList,
          [fieldName]: value
        }
      };
    });
  };

  private isCanSaveLoanForm = () => {
    const { userData, loanForm, cachedLoanForm } = this.state;

    if (!userData || !loanForm) {
      return false;
    }

    return !isEqual(loanForm, cachedLoanForm);
  };
  private saveLoanForm = async () => {
    const { userData, loanForm } = this.state;

    if (!userData) {
      return;
    }

    if (!loanForm) {
      return;
    }

    const {
      CreationTime,
      Currency,
      EmployeeId,
      Employee,
      ClientFullName,
      ...requestData
    } = loanForm;

    this.setState({ loading: true });
    try {
      const result = await ApiClient.saveClientLoanForm(
        userData.Id,
        requestData
      );
      this.checkAllowPrintLoanForm(result);
      toast("Анкета успешно сохранена", {
        type: toast.TYPE.SUCCESS
      });
      this.setState({
        loanForm: result,
        cachedLoanForm: result,
      });
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private printLoanForm = async () => {
    const { userData, loanForm } = this.state;

    if (!userData) {
      return;
    }

    if (!loanForm) {
      return;
    }

    this.setState({ loading: true });
    try {
      const result = await ApiClient.printClientLoanForm(userData.Id);

      const blob = new Blob([result], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      window.open(url);
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private renderPrintButtons = () => {
    const { userData, cachedUserData } = this.state;

    if (cachedUserData && userData && typeof userData.Id === "number") {
      return (
        <PrintDocuments
          sopdAvailable={!!cachedUserData.PartnerAddressRegistration.CityId}
          clientId={userData.Id}
        />
      );
    }
  };

  private checkAllowPrintLoanForm = (data: LoanForm): void => {
    let allow = true;
    Array.from(LOAN_FORM_CLIENT_FIELDS.keys()).forEach((fieldName: string) => {
      if (!trim(get(data, fieldName))) {
        allow = false;
      }
    });

    Array.from(LOAN_FORM_REASON_FIELDS.keys()).forEach((fieldName: string) => {
      if (!trim(get(data, fieldName))) {
        allow = false;
      }
    });

    Array.from(LOAN_FORM_CREDIT_FIELDS.keys()).forEach((fieldName: string) => {
      if (!trim(get(data, fieldName))) {
        allow = false;
      }
    });

    this.setState({
      allowPrintLoanForm: allow,
    });
  }
}
