import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import styled from 'styled-components';
import Flex from 'styled-flex-component';

import Chart from '../../../../components/Chart/Chart';
import CurrencyFilter from '../../../../components/CurrencyFilter/CurrencyFilter';
import PeriodFilter from '../../../../components/PeriodFilter/PeriodFilter';
import CompareFilter from '../../../../components/CompareFilter/CompareFilter';
import {
  getTickInterval,
  handleChangeCompare,
  handleChangeCurrency,
  handleChangePeriod,
  getCurrencyCode,
  getPeriodType,
} from '../../../../helpers/chartHelpers';
import factory from '../../../../domain/analytics';
import chartDataBuilder from '../../../../domain/analytics/chartDataBuilder';
import AnalyticsApiClient from '../../../../modules/Analytics/services/api';

export default class Currencies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: {
        currency: 'USD',
        period: 'year',
        dateFrom: moment()
          .startOf('month')
          .add(1, 'month')
          .subtract(1, 'y')
          .format('YYYY-MM-DD'),
        dateTo: moment()
          .endOf('month')
          .format('YYYY-MM-DD'),
        compare: false,
      },
      chartData: [],
      data: [],
    };

    this.handleChangeCompare = handleChangeCompare.bind(this);
    this.handleChangeCurrency = handleChangeCurrency.bind(this);
    this.handleChangePeriod = handleChangePeriod.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const {
      currency, period, dateFrom, dateTo, compare,
    } = this.state.filters;

    const params = {
      currency: getCurrencyCode(currency),
      periodType: getPeriodType(period, dateFrom, dateTo),
      dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
      dateTo: moment(dateTo).format('YYYY-MM-DD'),
      compare: period !== 'year' && period !== 'currentYear' ? null : compare,
    };
    const rawData = await AnalyticsApiClient.getDepositsAverage(params);
    const data = factory(rawData);

    const builder = chartDataBuilder()
      .setTitle('Средний размер вкладов')
      .setData(data)
      .setFilters(this.state.filters);

    this.setState({ chartData: builder.getCurves(), data });
  }

  render() {
    const {
      filters: {
        currency, period, dateFrom, dateTo, compare,
      },
      chartData,
      // eslint-disable-next-line no-unused-vars
      data = [],
    } = this.state;

    return (
      <Container>
        <Header>
          <CurrencyFilter active={currency} onChange={this.handleChangeCurrency} />
          <PeriodFilter
            activePeriod={period}
            dateFrom={dateFrom}
            dateTo={dateTo}
            onChange={this.handleChangePeriod}
          />
          {period !== 'year' && period !== 'currentYear' ? null : (
            <CompareFilter compare={compare} onChange={this.handleChangeCompare} />
          )}
        </Header>
        <Chart
          series={chartData}
          minTickInterval={getTickInterval(period)}
          title="Средний размер вкладов"
          xLabel="Период"
          yLabel="Количество"
          xDateFormat={period !== 'month' && period !== 'currentMonth' ? '%B' : '%d %B'}
        />
      </Container>
    );
  }
}

const Container = styled(Flex).attrs({ column: true, full: true })``;
const Header = styled(Flex).attrs({ column: true })``;
