import * as React from 'react';
import { isBoolean, isString, isArray, get } from 'lodash';
import {
  Select, SelectOption,
  SimpleInput, Checkbox,
  SimpleMaskedInput
} from "../../../../components/FormControls/FormControls";
import styled from "../../../../styled/styled-components";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import { parseServerDate } from "../../../../utils";
import Autocomplete from "../../../../components/Autocomplete";
import { terms } from "../../../../constants/periodCodes";

export interface FormControlProps {
  label: string,
  value?: string | number | boolean,
  type?: string,
  inputType?: string,
  data?: Array<[any, any]>,
  searchData?: any,
  error?: string,
  fieldName?: string,
  mask?: string,
  onChange: (e: any, event?: any) => void;
  onChangeTerm?: (e: any, event?: any) => void;
  onFocus?: (e: any) => void;
  handleBlur?: (e: any) => void;
  handleChangeAutoSelect?: (Partner: any) => void;
  disabled?: boolean;
  isNumber?: boolean;
  required?: boolean;
  maxDate?: Date;
  minDate?: Date;
  isTerm?: boolean;
  valueTerm?: number;
}

const OperatorLabel = styled.label`
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
    font-weight: bold;
`;

const Required = styled.span`
  color: red;
`;

export default class FormControl extends React.PureComponent<FormControlProps> {
  public static defaultProps = {
    value: '',
    inputType: 'text',
  };

  public render() {
    const { label, error, type, required, isTerm, onChangeTerm, disabled, valueTerm } = this.props;
    return (
      <React.Fragment>
        <div className="row text-right mb-3">
          <div className="col-4">
          <OperatorLabel>
            {label}{required && <Required>*</Required>}
          </OperatorLabel>
          </div>
          {!isTerm && <div className={`col-8${type === 'checkbox' ? ' d-flex align-items-center' : ''}`}>
            {this.getControl()}
            {error && (<span className="text-danger">{error}</span>)}
          </div>}
          {isTerm && <React.Fragment>
            <div className="col-4">
              {this.getControl()}
              {error && (<span className="text-danger">{error}</span>)}
            </div>
            <div className="col-4 pl-0">
              <Select greenBorder onChange={onChangeTerm}  disabled={disabled} value={valueTerm} style={{ width: "100%" }}>
                {Object.entries(terms).map(([id, label]) => (
                  <SelectOption key={label} value={label}>
                    {id}
                  </SelectOption>
                ))}
              </Select>
            </div>
          </React.Fragment>}

        </div>
      </React.Fragment>
    );
  }

  private getControl = () => {
    const { onChange, value, type, data, error, disabled, isNumber: onlyNums, handleChangeAutoSelect, handleBlur, onFocus, maxDate, minDate, inputType, mask } = this.props;
    switch (type) {
      case 'select':
        return (
          !isBoolean(value) && <Select greenBorder onChange={onChange}  disabled={disabled} onFocus={onFocus} value={value} style={{ width: "100%" }}>
            {data && data.map(([id, label]) => (
              <SelectOption key={label} value={label}>
                {id}
              </SelectOption>
            ))}
          </Select>
        );
      case 'date':
        return <DatePicker disabled={disabled} maxDate={maxDate} minDate={minDate} useStyledInput onChange={onChange} selected={value && typeof value === 'string' ? parseServerDate(value) : null} />;
      case 'checkbox':
        return <Checkbox disabled={disabled} onChange={onChange} checked={isBoolean(value) && value} />;
      case 'component':
        return this.props.children;
      case 'autocomplete':
        const { fieldName } = this.props;
        if (!fieldName) {
          return;
        }
        const getAutocompleteItems = (query: string) => {
          const { searchData } = this.props;
          return isArray(searchData) ? searchData : [];
        };

        const renderItem = (item: any) => {
          // tslint:disable-next-line:no-shadowed-variable
          const { fieldName } = this.props;

          return `${get(item, fieldName || '', '')}`;
        };

        const renderDropDownItem = (item: any) => {
          return `${get(item, 'Famale', '')} ${get(item, 'Name', '')} ${get(item, 'Patronymic', '')} (${get(item, 'Number', '')})`;
        };


        return (
          <Autocomplete
            onInputChange={onChange}
            value={value && isString(value) ? {[fieldName]: value} : ''}
            getItems={getAutocompleteItems}
            renderDropDownItem={renderDropDownItem}
            onSelectItem={handleChangeAutoSelect}
            renderItem={renderItem}
            disabled={disabled}
            error={!!error}
            onFocus={onFocus}
          />
        );
      case 'maskInput':
        // @ts-ignore
        return !isBoolean(value) && mask && <SimpleMaskedInput mask={mask} disabled={disabled} onBlur={handleBlur} onChange={onChange} onFocus={onFocus} greenBorder customError={!!error} value={value} />

      default:
        return !isBoolean(value) && <SimpleInput type={ onlyNums ? 'number' : inputType } disabled={disabled} onBlur={handleBlur} onChange={onChange} onFocus={onFocus} greenBorder error={!!error} value={value} />
    }
  };

}
