import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from "redux";

import { AppState } from "../../../../store";
import { fetchCalculatorPensionDataRequest } from "../../../../store/calculators";

import PensionChildCalculatorForm from './PensionChildCalculatorForm';

const mapStateToProps = (state: AppState) => ({
  error: state.calculators.error,
  loading: state.calculators.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchCalculatorPensionDataRequest
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PensionChildCalculatorForm);
