import * as React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider as AlertProvider, ProviderOptions as AlertProviderOptions } from "react-alert";
import { ModalProvider } from "styled-react-modal";

import "bootstrap/dist/css/bootstrap.min.css";
// import 'animate.css/animate.min.scss';
// import 'bootstrap/dist/js/bootstrap.bundle';

import "antd/dist/antd.css";
import "font-awesome/css/font-awesome.min.css";

// import './css/style.scss';
// import './css/custom.scss';

import "./scss/style.scss";

import { ThemeProvider } from "./styled/styled-components";
import theme from "./styled/theme";

import Full from "./views/Full/Full";
import AlertTemplate from "./components/AlertTemplate/AlertTemplate";
import ConfirmDialog from "./components/ConfirmDialog";
import GlobalStyles from "./styled/GlobalStyles";
import { fetchCountriesRequest } from "./store/regions/actions";
import { Dispatch } from "redux";
import ErrorProvider from "./modules/Errors/components/ErrorProvider";
import Helmet from "react-helmet";
import { TokenStorage } from "./services/TokenStorage";
import Login from "./pages/Login/Login";

const options: Partial<AlertProviderOptions> = {
  timeout: 5000,
  position: "top right"
};

const confirmDialog = React.createRef<ConfirmDialog>();

const getUserConfirmation = (message: string, callback: (ok: boolean) => void) => {
  if (confirmDialog.current) {
    confirmDialog.current.open(message, callback);
  }
};

interface MainProps {
  fetchCountriesRequest: typeof fetchCountriesRequest;
}

interface MainState {
  isAuthenticated: boolean;
}

class Main extends React.Component<MainProps, MainState> {
  public state: MainState = {
    isAuthenticated: TokenStorage.isAuthenticated()
  };

  public componentDidMount() {
    this.props.fetchCountriesRequest();
  }

  public render() {
    const { isAuthenticated } = this.state;
    return (
      <ModalProvider>
        <Helmet >
          <title>SM Group</title>
        </Helmet>
        <ThemeProvider theme={theme}>
          <ErrorProvider>
            <AlertProvider template={AlertTemplate} {...options}>
              <React.Fragment>
                <BrowserRouter getUserConfirmation={getUserConfirmation}>
                  {isAuthenticated ?
                    <Switch>
                      <Route path="/" component={Full}/>
                    </Switch>
                    : <Login updateIsAuthenticated={this.updateIsAuthenticated}/>}
                </BrowserRouter>
                <ConfirmDialog ref={confirmDialog}/>
                <GlobalStyles/>
              </React.Fragment>
            </AlertProvider>
          </ErrorProvider>
        </ThemeProvider>
      </ModalProvider>
    );
  }

  private updateIsAuthenticated = () => {
    this.setState({
      isAuthenticated: TokenStorage.isAuthenticated()
    });
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCountriesRequest: () => dispatch(fetchCountriesRequest())
});

export default connect(
  undefined,
  mapDispatchToProps
)(Main);
