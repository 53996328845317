import * as React from 'react';
import DatePicker, { DatePickerProps } from '../DatePicker/DatePicker';
import styled from '../../styled/styled-components';
import Button from '../Button/Button';
import { format } from 'date-fns';
import { Omit } from '../../helpers/typingHelpers';
import ru from 'date-fns/locale/ru';
import ReactDatePicker from 'react-datepicker';

export interface DateRange {
  start?: Date;
  end?: Date;
}

export type EditingMode = 'start' | 'end';

export interface DateRangePickerProps extends Omit<DatePickerProps, 'onChange' | 'dateFormat'> {
  onChangeRange?: (range: DateRange) => void;
  selectableMonth?: boolean;
  startDate?: Date;
  endDate?: Date;
}

export interface DateRangePickerState {
  editing: EditingMode;
  start?: Date;
  end?: Date;
  currentMonth: Date;
}

const DatePickerFooter = styled.div`
  padding: 10px;
  text-align: center;
`;

export default class DateRangePicker extends React.Component<
  DateRangePickerProps,
  DateRangePickerState
  > {
  public state: DateRangePickerState = {
    editing: 'start',
    currentMonth: new Date(),
    start: this.props.startDate,
    end: this.props.endDate,
  };

  private reactDatePickerRef = React.createRef<InstanceType<typeof ReactDatePicker>>();

  public componentWillReceiveProps(nextProps: DateRangePickerProps) {
    if (this.props.startDate && nextProps.startDate) {
      if (this.props.startDate.getTime() !== nextProps.startDate.getTime()) {
        this.setState({
          start: nextProps.startDate,
        });
      }
    }

    if (this.props.endDate && nextProps.endDate) {
      if (this.props.endDate.getTime() !== nextProps.endDate.getTime()) {
        this.setState({
          end: nextProps.endDate,
        });
      }
    }
  }

  public render() {
    return (
      <DatePicker
        {...this.props}
        dateFormat=""
        onChange={this.handleChange}
        startDate={this.state.start}
        endDate={this.state.end}
        selectsStart={this.state.editing === 'start'}
        selectsEnd={this.state.editing === 'end'}
        shouldCloseOnSelect={this.state.editing === 'end'}
        onMonthChange={this.hanleChangeMonth}
        innerRef={this.reactDatePickerRef}
      >
        {this.props.selectableMonth && this.renderFooter()}
      </DatePicker>
    );
  }

  private renderFooter = () => (
    <DatePickerFooter>
      <Button onClick={this.selectMonth} type="primary">
        Весь {format(this.state.currentMonth, 'LLLL yyyy', { locale: ru })}
      </Button>
    </DatePickerFooter>
  );

  private handleChange = (date: Date) => {
    this.setState(
      state => ({
        start: state.editing === 'start' ? date : state.start,
        end: date,
        editing: state.editing === 'start' ? 'end' : 'start',
      }),
      () => {
        this.handleChangeState();
        this.tryClose();
      },
    );
  };

  private hanleChangeMonth = (date: Date) => {
    this.setState({ currentMonth: date });
  };

  private selectMonth = () => {
    this.setState(
      (state) => {
        const month = state.currentMonth;
        const startOfMonth = new Date(month.getFullYear(), month.getMonth(), 1);
        const endOfMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0);

        return {
          start: startOfMonth,
          end: endOfMonth,
          editing: 'start',
        };
      },
      () => {
        this.handleChangeState();
        this.tryClose();
      },
    );
  };

  private handleChangeState = () => {
    if (this.state.end && this.props.onChangeRange) {
      this.props.onChangeRange({
        start: this.state.start,
        end: this.state.end,
      });
    }
  };

  private tryClose = () => {
    if (this.reactDatePickerRef.current) {
      this.reactDatePickerRef.current.setState({ open: false });
    }
  };
}
