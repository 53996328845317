import * as React from 'react';
import moment from 'moment';
import styled from '../../styled/styled-components';
import { connect} from 'react-redux';
import { Table, TableHead, TableBody, TableRow, TableCell, TableCellHead } from './Table';
import { IS_ACTIVE_LABELS, GENDER_LABELS, COLUMN_LABELS, CURRENCY_IDS } from '../../constants';
import Link from '../Link';
import Clickable from '../Clickable';
import SortIcon from './SortIcon';
import ColumnFilter from './ColumnFilter';
import { FilterItemData } from '../../types/PartnersAndClients';
import { FieldInfo } from '../../types';
import Label from '../Label';
import { AppState } from "../../store";
import { Dispatch } from "redux";
import { updateActiveRow, ActiveRow, clearActiveRow } from "../../store/forms";
import { isEqual, isNil } from 'lodash';

export interface FiltersProps<T> {
  fields: Array<FieldInfo<T>>;
  onChangeFilter: (filterItemData: FilterItemData<T>, forceFiltering?: boolean) => void;
  acceptFilter: () => void;
  resetFilter: (fieldName: keyof T) => void;
  filtersShown: boolean;

  autocompleteItems: { [fieldName in keyof T]?: string[] };
}

export interface SortProps<T> {
  onChangeSort: (fieldName: keyof T) => void;
  sortFieldName: keyof T;
  sortDirection: 1 | 2;
}

export interface BaseListItem {
  Id: number;
  HasOverdueDocuments?: boolean;
  Direction?: boolean;
}
export interface TableListProps<T extends BaseListItem> {
  data: T[];
  activeRow?: ActiveRow;
  notUseActive?: boolean;
  styledOperations?: boolean;
  visibleFields: Map<keyof T, boolean>;
  createLink?: (
    item: T,
    key: keyof T,
  ) => {
    link: string;
    fieldName: keyof T;
    onClick?: () => void;
  };
  renderActions?: (item: T) => React.ReactNode;
  labels: { [section: string]: Map<string | number | symbol, string> };
  filtersProps: FiltersProps<T>;
  sortProps: SortProps<T>;
  renderValue?: (fieldName: keyof T, item: T) => React.ReactNode;
  labelsSection?: string;
  entityType?: string;
  getRowStyles?: (item: T) => React.CSSProperties | undefined;
  updateActiveRow: (item: T) => void;
  clearActiveRow: () => void;
  withoutFilters?: boolean;
  notUseColumnLabel?: boolean;
}

export interface TableListState<T extends BaseListItem> {
  columns: Array<keyof T>;
}

export function getTableCellAlign<T extends BaseListItem>(fieldName: keyof T) {
  switch (fieldName) {
    case 'PartnerName':
    case 'UserName':
      return 'left';
    case 'InternationalPassportNumber':
    case 'Gender':
    case 'IsActive':
    case 'Currency':
    case 'Total':
      return 'center';

    default:
      return 'center';
  }
};

const StyledTable = styled(Table)`
  table-layout: auto;
`;

class TableList<T extends BaseListItem> extends React.PureComponent<
  TableListProps<T>,
  TableListState<T>
  > {
  public state = {
    columns: [...this.props.visibleFields.entries()]
      .filter(([_fieldName, visible]) => visible)
      .map(item => item[0]),
  };

  private ColumnFilter = ColumnFilter<keyof T>();

  public componentWillReceiveProps(nextProps: TableListProps<T>) {
    if (!isEqual(this.props.data, nextProps.data)) {
      const { clearActiveRow } = this.props;
      clearActiveRow();
    }
    if (this.props.visibleFields !== nextProps.visibleFields) {
      this.setState({
        columns: [...nextProps.visibleFields.entries()]
          .filter(([_fieldName, visible]) => visible)
          .map(item => item[0]),
      });
    }
  }

  public render() {
    return (
      <div style={{ overflowX: 'auto' }}>
        <StyledTable>
          <TableHead>{this.renderHead()}</TableHead>
          <TableBody>{this.renderRows()}</TableBody>
        </StyledTable>
      </div>
    );
  }

  private renderHead = () => {
    const ConnectedColumnFilter = this.ColumnFilter;
    const { filtersProps, sortProps, entityType, renderActions, withoutFilters, notUseColumnLabel } = this.props;
    const {
      onChangeFilter,
      acceptFilter,
      filtersShown,
      autocompleteItems,
      resetFilter,
    } = filtersProps;

    return (
      <React.Fragment>
        <TableRow>
          {this.state.columns.map((columnName) => {
            const filterData = filtersProps.fields.find(({ FieldName }) => FieldName === columnName,);
            if (filterData && !withoutFilters) {
              return (
                <TableCell key={columnName.toString()} style={{ verticalAlign: 'bottom' }}>
                  <ConnectedColumnFilter
                    filterData={filterData}
                    onChangeFilter={onChangeFilter}
                    acceptFilter={acceptFilter}
                    filtersShown={filtersShown}
                    autocompleteItems={autocompleteItems}
                    resetFilter={resetFilter}
                    entityType={entityType}
                  />
                </TableCell>
              );
            }
          })}
        </TableRow>

        <TableRow>
          {this.state.columns.map((columnName) => {
            const label = notUseColumnLabel ? null :COLUMN_LABELS.get(columnName.toString());

            return (
              <TableCellHead align={'center'} key={columnName.toString()}>
                <Clickable
                  style={{ outline: 'none' }}
                  onClick={sortProps.onChangeSort.bind(null, columnName)}
                >
                  {label || (
                    <Label
                      section={this.props.labelsSection}
                      value={columnName as string | number}
                    />
                  )}{' '}
                  {sortProps.sortFieldName === columnName && (
                    <SortIcon sortDirection={sortProps.sortDirection} />
                  )}
                </Clickable>
              </TableCellHead>
            );
          })}
          {renderActions && <TableCell>Действия</TableCell>}
        </TableRow>
      </React.Fragment>
    );
  };

  private renderRows = () => {
    const { data, activeRow, styledOperations } = this.props;
    return data.map((item) => {
      const itemAction = this.props.renderActions ? this.props.renderActions(item) : null;
      let style = this.props.getRowStyles ? this.props.getRowStyles(item) : {};
      if (item.HasOverdueDocuments) {
        style = {
          ...style,
          backgroundColor: '#f8d7da',
        };
      }
      if (styledOperations) {
        if (item.Direction) {
          style = {
            ...style,
            backgroundColor: '#bbfab9',
          };
        } else {
          style = {
            ...style,
            backgroundColor: '#f8d7da',
          };
        }
      }
      return (
        <TableRow style={style} key={item.Id} onClick={this.changeActiveRow(item)} hover active={activeRow && item.Id === activeRow.Id}>
          {this.state.columns.map((columnName, index) => (
            <TableCell align={getTableCellAlign(columnName)} key={index}>
              {this.renderValue(columnName, item) || '---'}
            </TableCell>
          ))}
          {itemAction && <TableCell>{itemAction}</TableCell>}
        </TableRow>
      );
    });
  };

  private changeActiveRow = (item: T) => () => {
    const { updateActiveRow, activeRow, clearActiveRow, notUseActive } = this.props;
    if (notUseActive) {
      return;
    }
    if (activeRow && item.Id === activeRow.Id) {
      clearActiveRow();
    } else {
      // @ts-ignore
      updateActiveRow({
        // @ts-ignore
        PartnerNumber: item.PartnerNumber,
        Id: item.Id,
        // @ts-ignore
        BillCode: item.BillCode,
      })
    }
  };

  private renderValue = (key: keyof T, item: T) => {
    const { createLink, renderValue, labels } = this.props;
    const customValue = renderValue ? renderValue(key, item) : null;

    if (customValue) {
      return customValue;
    }

    if (createLink) {
      const { link, fieldName, onClick = false } = createLink(item, key);

      if (key === fieldName && onClick) {
        return (
          <Link onClick={onClick}>
            {item[key]}
          </Link>
        );
      }

      if (key === fieldName && link) {
        return (
          <Link routerLink href={link}>
            {item[key]}
          </Link>
        );
      }
    }
    if (key !== 'FuncPay' && key !== 'IsGuarantee' && isNil(item[key])) {
      return "---";
    }

    switch (key) {
      case 'Birthday':
        return moment(item[key]).format('DD.MM.YYYY');

      case 'OpeningDate':
        if (!item[key]) {
          return '---';
        }
        return moment(item[key]).format('DD.MM.YYYY');

      case 'ClosingDate':
        return moment(item[key]).format('DD.MM.YYYY');

      case 'Modified':
        return moment(item[key]).format('DD.MM.YYYY HH:mm:ss');

      case 'PaymentDate':
        return moment(item[key]).format('DD.MM.YYYY');

      case 'CreateDate':
        return moment(item[key]).format('DD.MM.YYYY');

      case 'CheckDate':
        return moment(item[key]).format('DD.MM.YYYY');

      case 'Date':
        return moment(item[key]).format('DD.MM.YYYY');

      case 'Gender':
        return GENDER_LABELS.get(Number(item[key]));

      case 'IsActive':
        return IS_ACTIVE_LABELS.get(Number(item[key]));

      case 'IsClosed':
        return labels.YESNO_BOOLEAN.get(item[key].toString());

      case 'CurrencyId':
        return CURRENCY_IDS.get(Number(item[key]));

      case 'UseCapital':
        return labels.YESNO_BOOLEAN.get(item[key].toString());

      case 'Currency':
        return CURRENCY_IDS.get(Number(item[key])) || item[key];

      case 'CurrentBalance':
        return Number.parseFloat(item[key].toString()).toLocaleString('ru', { minimumFractionDigits: 2 });

      case 'Percent':
        return Number.parseFloat(item[key].toString()).toLocaleString('ru', { minimumFractionDigits: 2 });

      case 'MemberFee':
        return Number.parseFloat(item[key].toString()).toLocaleString('ru', { minimumFractionDigits: 2 });

      case 'Member':
        return Number.parseFloat(item[key].toString()).toLocaleString('ru', { minimumFractionDigits: 2 });

      case 'Pit':
        return Number.parseFloat(item[key].toString()).toLocaleString('ru', { minimumFractionDigits: 2 });

      case 'ClearBalance':
        return Number.parseFloat(item[key].toString()).toLocaleString('ru', { minimumFractionDigits: 2 });

      case 'Balance':
        return Number.parseFloat(item[key].toString()).toLocaleString('ru', { minimumFractionDigits: 2 });

      case 'InitialLoanSum':
        return Number.parseFloat(item[key].toString()).toLocaleString('ru', { minimumFractionDigits: 2 });

      case 'StartPeriodBalance':
        return Number.parseFloat(item[key].toString()).toLocaleString('ru', { minimumFractionDigits: 2 });

      case 'EndPeriodBalance':
        return Number.parseFloat(item[key].toString()).toLocaleString('ru', { minimumFractionDigits: 2 });

      case 'OpeningSum':
        return Number.parseFloat(item[key].toString()).toLocaleString('ru', { minimumFractionDigits: 2 });

      case 'ProductId':
        return labels.PRODUCT ? labels.PRODUCT.get(Number(item[key])) : item[key];

      case 'FuncPayId':
        return labels.FUNC_PAY ? labels.FUNC_PAY.get(Number(item[key])) : item[key];

      case 'Action':
        return labels.ACTIVITY ? labels.ACTIVITY.get(Number(item[key])) : item[key];

      case 'BillTypeId':
        return labels.BILL_TYPES ? labels.BILL_TYPES.get(Number(item[key])) : item[key];

      case 'FuncPay':
        if (!item[key]) {
          return 'Все';
        }
        return labels.FUNC_PAY ? labels.FUNC_PAY.get(Number(item[key])) : item[key];

      case 'Type':
        return labels.OPERATIONS ? labels.OPERATIONS.get(Number(item[key])) : item[key];

      case 'IsGuarantee':
        return labels.YESNO ? labels.YESNO.get(Number(item[key])) : item[key];

      case 'Direction':
        return item[key] ? 'Приходная' : 'Расходная';

      default:
        return item[key] || '---';
    }
  };
}

const mapStateToProps = (state: AppState) => ({
  // @ts-ignore
  activeRow: state.forms.tableForm.activeRow,
  labels: state.labels.labels,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateActiveRow: (item: ActiveRow) => dispatch(updateActiveRow(item)),
  clearActiveRow: () => dispatch(clearActiveRow()),
});

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(TableList);
