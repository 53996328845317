export const TABLE_LABELS = new Map([
  ['Date', true],
  ['PartnerName', true],
  ['BillCode', true],
  ['Total', true],
  ['CurrencyId', true],
  ['Status', true],
  ['Type', true],
  ['Operator', true]
]);

export const PAYMENT_REQUEST_TYPE_LABELS = new Map([
  ['true', 'Выплата по реквизитам'],
  ['false', 'На ЗП карту']
]);
