import { BillDepositListItem, FilterFieldName, FilterItemData, SortDirection } from "../types";

export const AUTOCOMPLETE_FILTER_FIELDS = [
];

export const CALENDAR_FIELD_LABELS = new Map<keyof BillDepositListItem, string>([
  ["Date", "Дата"],
  ["Name", "Событие"],
  ["BillCode", "Счет"],
  ["PartnerName", "ФИО"],
  ["Total", "Сумма"],
  ["Currency", "Валюта"],
  ["IsClosed", "Зыкрытие"],
]);

export const DEFAULT_FILTER_FIELDS = new Map<
  FilterFieldName<BillDepositListItem>,
  FilterItemData
  >([
  [
    "Date",
    {
      FieldName: "Date",
      Method: 4,
      Value: null,
      ValueType: 4
    }
  ],
  [
    "Name",
    {
      FieldName: "Name",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ],
  [
    "BillCode",
    {
      FieldName: "BillCode",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "PartnerName",
    {
      FieldName: "PartnerName",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "Total",
    {
      FieldName: "Total",
      Value: null,
      Method: 4,
      ValueType: 2
    }
  ],
  [
    "Currency",
    {
      FieldName: "Currency",
      Value: null,
      Method: 1,
      ValueType: 1
    }
  ],
  [
    "IsClosed",
    {
      FieldName: "IsClosed",
      Value: null,
      Method: 0,
      ValueType: 5
    }
  ]
]);

export const DEFAULT_SORT_DIRECTION: SortDirection = 2;

export const DEFAULT_SORT_FIELD_NAME: FilterFieldName<BillDepositListItem> =
  "Date";
