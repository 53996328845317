import * as React from 'react';
import { FileDownload } from 'styled-icons/fa-solid';
import Button, { ButtonProps } from '../../components/Button/Button';

const DownloadButton: React.SFC<ButtonProps> = ({ children, type, ...props }) => (
  <Button type="primary" {...props}>
    Скачать <FileDownload size={12} />
  </Button>
);

export default DownloadButton;
