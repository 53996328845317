import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import autoBind from 'react-autobind';
import moment from 'moment';
import ReactSelect from 'react-select';
import Helmet from 'react-helmet';
import { withAlert } from 'react-alert';
import { Switch, Modal } from 'antd';
import Button from '../../../components/Button/Button';
import { isArray, isEmpty, get, trim, join } from 'lodash';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import './Publication.scss';

import apiClient from '../../../services/api';
import ModalSpinner from '../../../components/ModalSpinner/ModalSpinner';
import UploadButton from '../../../components/UploadButton/UploadButton';
import { parseServerDate } from '../../../utils';
import DatePicker from '../../../components/DatePicker/DatePicker';
import BackLink from '../../../components/BackLink';
import styled from '../../../styled/styled-components';

const Required = styled.span`
  color: red;
  margin-right: 3px;
`;

class Publication extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      ...props,
      status: 'ok',
      type: 'draft',
      htmlData: '',
      editorState: EditorState.createEmpty(),
      StartDate: moment().format('YYYY-MM-DD'),
      EndDate: '',

      successMessage: '',
      errorMessage: null,
      errorModel: null,
      uploadStatus: null,
      pushCheck: false,
      selectedUsers: null,
      Users: undefined,
    };

    this.getUsers = this.getUsers.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
  }

  async componentDidMount() {
    if (this.state.id !== 'new') {
      try {
        this.setState({ status: 'waiting' });
        const result = await apiClient.getPublication(this.state.id);

        this.form.Title.value = result.Title;
        this.form.Preview.value = result.Preview;
        this.form.IsInPublish.checked = result.IsInPublish;
        this.form.IsNews.checked = result.IsNews;
        this.form.IsNotification.checked = result.IsNotification;
        // this.form.IsActive.checked = result.IsActive;

        const contentBlock = htmlToDraft(result.Content);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

        this.setState({
          status: 'ok',
          editorState: EditorState.createWithContent(contentState),
          StartDate: moment(result.StartDate).format('YYYY-MM-DD'),
          EndDate: result.EndDate ? moment(result.EndDate).format('YYYY-MM-DD') : null,
          ImgLink: result.ImgLink,
        });
      } catch (e) {
        this.setState({ status: 'error' });
      }
    }
    this.getUsers();
  }

  onEditorStateChange(editorState) {
    this.setState({
      editorState,
    });
  }

  getImgLinkFromContent(content) {
    const el = document.createElement('html');
    el.innerHTML = content;
    const images = el.getElementsByTagName('img');
    if (images && images.length > 0) {
      return images[0].getAttribute('src');
    }
    return null;
  }

  async handleSubmit(event) {
    event.stopPropagation();
    event.preventDefault();

    try {
      const {
        editorState,
        id,
        StartDate,
        EndDate,
        ImgLink,
        type,
        htmlData,
      } = this.state;

      let content = '';
      if (type === 'draft') {
        content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      } else {
        content = htmlData;
      }

      const imgLink = ImgLink || this.getImgLinkFromContent(content);

      const data = {
        Title: this.form.Title.value,
        Preview: this.form.Preview.value,
        Content: content,
        IsInPublish: this.form.IsInPublish.checked,
        IsNews: this.form.IsNews.checked,
        IsNotification: this.form.IsNotification.checked,
        StartDate,
        EndDate,
        IsActive: true,
        ImgLink: imgLink,
      };

      this.setState({ status: 'waiting', successMessage: '' });
      // eslint-disable-next-line no-unused-vars
      let result;
      if (id === 'new') {
        result = await apiClient.createPublication(data);
      } else {
        result = await apiClient.updatePublication(id, data);
      }

      if (this.state.pushCheck) {
        const defaultDetails = {
          Id: undefined,
          IsSended: false,
          Users: isArray(this.state.selectedUsers) ? this.state.isAllUsers ? 'all' : join(this.state.selectedUsers.map(i => (i.value)), ', ') : '',
          TypeId: 2,
          Title: this.form.PushTitle.value,
          Body: this.form.PushBody.value,
          ShowTitle: !!trim(this.form.PushTitle.value),
          PublicationID: result.Id,
          ButtonText: this.form.ButtonTitle.value,
          ButtonLink: this.form.ButtonLink.value,
          PublicationShowTypeID: 1,
        };

        const push = await apiClient.createOrUpdatePushDistributions(defaultDetails);

        if (this.form.PushSend.checked) {
          await apiClient.sendPushDistribution(push.Id);
        }
      }

      this.setState({
        status: 'ok',
        successMessage: 'Успешно',
        errorMessage: null,
        errorModel: null,
      }, () => {
        this.props.alert.success('Успешно');
        this.props.history.push('/tools/publications');
      });
    } catch (e) {
      console.error('e', e);
      let message;
      const model = {};

      if (e.response && e.response.data && e.response.data.Message) {
        message = e.response.data.Message;
        if (e.response.data.ModelState) {
          Object.keys(e.response.data.ModelState)
            .forEach((key) => {
              const field = key.split('.').pop();
              if (field !== 'model') {
                model[field] = e.response.data.ModelState[key][0];
              }
            });
        }
      } else {
        message = 'Неизвестная ошибка';
      }
      this.props.alert.error(message);
      this.setState({
        status: 'error',
        errorMessage: message,
        errorModel: model,
      });
    }
  }

  uploadImageCallback(file) {
    return apiClient.uploadImage(file);
  }

  async handleDelete(event) {
    event.stopPropagation();
    event.preventDefault();

    try {
      const { id } = this.state;
      await apiClient.deletePublication(id);
      this.props.history.push('/tools/publications');
    } catch (e) {
      let message;
      const model = {};

      if (e.response && e.response.data && e.response.data.Message) {
        message = e.response.data.Message;
      } else {
        message = 'Неизвестная ошибка';
      }
      this.setState({
        status: 'error',
        errorMessage: message,
        errorModel: model,
      });
    }
  }

  handleChangeStartDate(date) {
    this.setState({ StartDate: date ? date.toISOString() : null });
  }

  handleChangeEndDate(date) {
    this.setState({ EndDate: date ? date.toISOString() : null });
  }

  successUploadPreview(result) {
    this.setState({ ImgLink: result.data.link, uploadStatus: null });
  }

  handleChangeCheckbox(e) {
    const { target: { checked, name } } = e;
    this.setState({ [name]: checked });
  }

  onChangeTextArea(e) {
    this.setState({ htmlData: e.target.value })
  }

  removePreview(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ ImgLink: null });
  }

  async getUsers() {
    try {
      const result = await apiClient.getPushDetailsUsers();
      this.setState({
        Users: result,
      });
    } catch (error) {
      this.setState({
        status: 'error',
        errorMessage: 'Неизвестная ошибка',
      });
    }
  }

  onChangeSelect(value, action) {
    switch (action.action) {
      case 'clear':
        this.setState({
          selectedUsers: null,
          isAllUsers: false,
        });
        break;

      case 'select-option':
        if (action.option.value === 'all') {
          this.setState({
            selectedUsers: value,
            isAllUsers: true,
          });
        } else {
          this.setState({
            selectedUsers: value,
            isAllUsers: false,
          });
        }
        break;

      case 'remove-value':
        if (action.removedValue.value === 'all') {
          this.setState({
            selectedUsers: value,
            isAllUsers: false,
          });
        } else {
          this.setState({
            selectedUsers: value,
          });
        }
        break;

      default:
        break;
    }
  }

  confirm = (event) => {
    event.preventDefault();
    const _this = this;
    return Modal.confirm({
      title: 'Подтвердите удаление публикации',
      content: 'После выполнения действия восстановить публикацию будет не возможно',
      okText: 'Удалить',
      cancelText: 'Отмена',
      onOk() {
        return _this.handleDelete(event);
      },
    });
  }

  onChangeSwtich(checked) {
    const { editorState, htmlData } = this.state;
    if (checked) {
      const contentBlock = htmlToDraft(htmlData);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

      this.setState({
        editorState: EditorState.createWithContent(contentState),
      });

    } else {
      const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));

      this.setState({ htmlData: content });

    }
    const type = checked ? 'draft' : 'html';
    this.setState({ type });
  }

  render() {
    const {
      id,
      status,
      editorState,
      errorMessage,
      errorModel,
      successMessage,
      uploadStatus,

      StartDate,
      EndDate,
      ImgLink,

    } = this.state;

    const users = get(this.state, 'Users', []);

    // @ts-ignore
    const options = isArray(users) && users.map(item => ({
      value: item.Id,
      label: item.ShortName,
    }));

    let resultOption = [];

    if (isEmpty(this.state.selectedUsers)) {
      resultOption = [{ value: 'all', label: 'Всем пайщикам' }, ...options];
    } else if (this.state.isAllUsers) {
      resultOption = [{ value: 'all', label: 'Всем пайщикам' }];
    } else {
      resultOption = options;
    }

    return (
      <div className="publication">
        <Helmet defer={false}>
          <title>Добавление/редактирование публикации</title>
        </Helmet>
        <ModalSpinner show={status === 'waiting'} />
        <div className="publication__header">
          <BackLink default={{ url: '/tools/publications', title: 'Список публикаций' }}>
            <h4>{id === 'new' ? 'Добавление' : 'Редактирование'} публикации</h4>
          </BackLink>
        </div>
        <hr />
        <div className="publication__content">
          <form
            className="publication__form"
            ref={(form) => { this.form = form; }}
          >
            <label htmlFor="StartDate">Дата начала публикации:</label>
            <DatePicker
              selected={StartDate && parseServerDate(StartDate)}
              onChange={this.handleChangeStartDate}
              disabledKeyboardNavigation
              isClearable
            />
            <div className="error">{errorModel ? errorModel.StartDate : null}</div>

            <label htmlFor="dateTo">Дата конца публикации:</label>
            <DatePicker
              selected={EndDate && parseServerDate(EndDate)}
              onChange={this.handleChangeEndDate}
              disabledKeyboardNavigation
              isClearable
            />
            <div className="error">{errorModel ? errorModel.EndDate : null}</div>

            <label htmlFor="title"><Required>*</Required>Заголовок:</label>
            <input className="publication__field-title" type="text" name="Title" id="Title" />
            <div className="error">{errorModel ? errorModel.Title : null}</div>

            <label htmlFor="preview"><Required>*</Required>Краткий текст публикации:</label>
            <input className="publication__field-preview" type="text" name="Preview" id="Preview" />
            <div className="error">{errorModel ? errorModel.Preview : null}</div>

            <div className="publication__img-link-block">
              <label>Превью:</label>
              <div className="publication__cover-wrapper">
                {ImgLink && <img className="publication__cover" alt="preview" src={ImgLink} />}
                <div className="row">
                  <UploadButton
                    name={ImgLink ? 'Изменить' : 'Добавить'}
                    actionUpload={apiClient.uploadImage}
                    onUploading={() => this.setState({ uploadStatus: 'waiting' })}
                    onSuccess={this.successUploadPreview}
                    onError={(e) => this.setState(prevState => ({
                      ...prevState,
                      uploadStatus: 'error',
                      errorModel: {
                        ...(errorModel || {}),
                        ImgeLink: e.response.data.Message,
                      },
                    }))}
                    statusClassName="publication__img-link-upload-status"
                  />
                  {ImgLink && <button
                    className="publication__button-delete"
                    onClick={this.removePreview}
                  >
                    Удалить
                  </button>}
                  {uploadStatus === 'error' ? <div className="error">Не удалось загрузить файл</div> : null}
                  <div className="error">{errorModel ? errorModel.ImgeLink : null}</div>
                </div>
              </div>
            </div>
            <label htmlFor="content"><Required>*</Required>Полный текст публикации:{' '}
              <Switch
                onChange={this.onChangeSwtich}
                style={{ width: 'fit-content' }}
                checkedChildren={'draft'}
                unCheckedChildren={'html'}
                defaultChecked
              />
            </label>

            {this.state.type === 'draft' ? <Editor
              wrapperClassName="publication__field-content-wrapper"
              editorClassName="publication__field-content-editor"
              editorState={editorState}
              onEditorStateChange={this.onEditorStateChange}
              toolbar={{
                image: {
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback: this.uploadImageCallback,
                  previewImage: true,
                  inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                  alt: {
                    present: false,
                    mandatory: false
                  },
                  defaultSize: {
                    height: 'auto',
                    width: 'auto',
                  },
                },
              }}
            /> : <textarea rows={11} onChange={this.onChangeTextArea} value={this.state.htmlData} /> }
            <div className="error">{errorModel ? errorModel.Content : null}</div>
            <label htmlFor="type">Тип:</label>
            <div className="checkbox-field">
              <input type="checkbox" id="IsNews" name="IsNews" />&nbsp;&nbsp;
              <label htmlFor="IsActive">Новость</label>
            </div>
            <div className="checkbox-field">
              <input type="checkbox" id="IsNotification" name="IsNotification" />&nbsp;&nbsp;
              <label htmlFor="IsNotification">Уведомление</label>
            </div>
            <div className="error">{errorModel ? errorModel.Type : null}</div>
            <label>Статус:</label>
            <div className="checkbox-field">
              <input type="checkbox" id="IsInPublish" name="IsInPublish" />&nbsp;&nbsp;
              <label htmlFor="IsInPublish">Опубликовано</label>
            </div>
            <label>PUSH уведомления:</label>
            <div className="checkbox-field">
              <input id="Push" name="pushCheck" checked={this.state.pushCheck} onChange={this.handleChangeCheckbox} type="checkbox" />&nbsp;&nbsp;
              <label htmlFor="Push">Создать также PUSH сообщение</label>
            </div>
            {this.state.pushCheck && <Fragment>
              <label htmlFor="PushTitle">Название уведомления:</label>
              <input className="publication__field-title" type="text" name="PushTitle" id="PushTitle" />
              <label htmlFor="PushBody">Содержание уведомления:</label>
              <textarea className="publication__field-preview" name="PushBody" id="PushBody" />
              <label htmlFor="PushBody">Кому:</label>
              <ReactSelect
                isMulti
                name="Users"
                value={this.state.selectedUsers}
                options={resultOption}
                className="publication__field-preview"
                placeholder="Выберите пользователей"
                classNamePrefix="select"
                onChange={this.onChangeSelect}
              />
              <label htmlFor="PushTitle">Текст кнопки:</label>
              <input className="publication__field-title" type="text" name="ButtonTitle" id="ButtonTitle" />
              <label htmlFor="ButtonLink">Ссылка за кнопкой:</label>
              <input className="publication__field-preview" name="ButtonLink" id="ButtonLink" />
              <div className="checkbox-field mt-3">
                <input id="PushSend" name="PushSend" type="checkbox" />&nbsp;&nbsp;
                <label htmlFor="PushSend">Отправить пуш</label>
              </div>
            </Fragment>}
            {/* <div className="checkbox-field"> */}
            {/* <input type="checkbox" id="IsActive" name="IsActive" />&nbsp;&nbsp; */}
            {/* <label htmlFor="IsActive">Активно</label> */}
            {/* </div> */}
            <div className="publication__buttons">
              <button
                className="publication__button-save"
                onClick={this.handleSubmit}
              >
                {id === 'new' ? 'Создать' : 'Сохранить'}
              </button>
              {
                id === 'new'
                  ? null
                  : (
                    <Button
                      className="publication__button-delete"
                      onClick={this.confirm}
                    >
                      Удалить
                    </Button>
                  )
              }
            </div>
            <div className="error">{errorMessage}</div>
            <div className="success">{successMessage}</div>
          </form>
        </div>
      </div>
    );
  }
}

const PublicationWithRouter = withRouter(Publication);
const PublicationWithAlerts = withAlert(PublicationWithRouter);
export default PublicationWithAlerts;
