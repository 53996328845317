import * as React from "react";
import styled, { css } from "../../styled/styled-components";
import { NavLink } from "react-router-dom";
import { ifProp } from "styled-tools";
import { AppState } from "../../store";
import { hasAccessToUrl } from "../../store/auth";
import { connect } from "react-redux";
import { Dispatch } from "redux";

export interface ClickableProps
  extends React.AnchorHTMLAttributes<HTMLElement>,
    React.ButtonHTMLAttributes<HTMLElement> {
  component?: React.ComponentType<ClickableProps>;
  routerLink?: boolean;
  active?: boolean;
  disabled?: boolean;
  notShowButton?: boolean;
  dispatch?: Dispatch;
}

export const resetButtonStyles = css`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  text-align: inherit;
  cursor: pointer;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  ${ifProp(
    "disabled",
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `
  )}

  transition: all 0.2s ease-in;
`;

class ClickableView extends React.Component<ClickableProps> {
  public render() {
    const { component, routerLink, active, notShowButton, dispatch, ...props } = this.props;

    if (notShowButton) {

      return null;
    }

    if (component) {
      const Component = component;
      return <Component {...props} />;
    }

    if (props.href && routerLink) {
      const { href, ...routerLinkProps } = props;

      return <NavLink to={href} {...routerLinkProps} />;
    }

    if (props.href && !routerLink) {
      return <a {...props} />;
    }

    return <button {...props} />;
  }
}

const Clickable = styled(ClickableView)`
  ${resetButtonStyles}
`;

const mapStateToProps = (state: AppState, props: ClickableProps) => ({
  notShowButton: !hasAccessToUrl(state, props.href)
});

export default connect(
  mapStateToProps
)(Clickable);
