import React from 'react';
import Helmet from "react-helmet";
import uuid from 'uuid';
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { cloneDeep, toString } from 'lodash';
import styled from "styled-components";
import moment from "moment";

import LoadingOverlay from "../../../components/LoadingOverlay";
import { Heading } from "../../../components/Typography";
import { AppState } from "../../../store";
import {
  getAllMFPercentRequest, getAllMFPercentSuccess,
  getMFPercentData,
  getMFPercenLoading, isSavedMFPercent, saveMFPercentRequest,
} from "../../../store/otherActions";
import { MFPercentProps, TabTypeMFPercent } from "../types";
import { Table, TableHead, TableRow, TableCellHead, TableCell, TableBody } from "../../../components/Table";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { SimpleInput } from "../../../components/FormControls/FormControls";
import Button from "../../../components/Button/Button";
import { convertToUtc, parseServerDate } from "../../../utils";
import { toast } from "react-toastify";
import { MESSAGES, TABS_LABELS_MF_PERCENT } from "../constants";
import BackLink from "../../../components/BackLink";
import { TabContent, TabLink, TabLinks } from "../../Partner/components/Views";

const Container = styled.header`
  margin-bottom: 20px;
  padding: 14px 15px 7px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #e7eaec;
`;

const TITLE = 'Ставка морского фонда';

class MFPercent extends React.Component<MFPercentProps> {
  public componentDidMount() {
    const { getAllMFPercentRequest: getAllMFPercent } = this.props;
    getAllMFPercent();
  }

  public componentDidUpdate(prevProps: Readonly<MFPercentProps>) {
    if (!prevProps.isSaved && this.props.isSaved) {
      toast(MESSAGES.updateSuccessMFPercent, {
        type: toast.TYPE.SUCCESS
      });
    }
  }

  public render() {
    const { loading, mfPercent } = this.props;
    const tabsEneteries = [...TABS_LABELS_MF_PERCENT.entries()];
    const currentTabName = this.props.location.hash.replace('#', '') || 'rub';
    const currentTab = TABS_LABELS_MF_PERCENT.get(currentTabName as TabTypeMFPercent);

    const filterData = currentTab ? currentTab.filterData : null;
    return (
      <LoadingOverlay className="col-md-6" loading={loading}>
        <Helmet defer={false}>
          <title>{TITLE}</title>
        </Helmet>
        <Container>
          <BackLink title={document.title} >
            <Heading>{TITLE}</Heading>
          </BackLink>
          <Button onClick={this.addNewItem} type="success">
             Добавить
          </Button>
        </Container>
        <TabLinks>
          {tabsEneteries.map(([name, item]) => (
            <TabLink
              key={name}
              href={`${this.props.location.pathname}#${name}`}
              routerLink
              active={
                (!this.props.location.hash && name === 'rub') ||
                this.props.location.hash === `#${name}`
              }
              component={
                this.props.location.hash === `#${name}`
                  ? clickableProps => <span {...clickableProps} />
                  : undefined
              }
            >
              {item.label}
            </TabLink>
          ))}
        </TabLinks>
        <TabContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCellHead>Дата начала</TableCellHead>
                <TableCellHead>Значение</TableCellHead>
              </TableRow>
            </TableHead>
            <TableBody>
              {mfPercent.filter(item => item.CurrencyId === filterData).map((item, index) => (
                <TableRow key={`${item.Id}`}>
                  <TableCell>
                    <DatePicker useStyledInput onChange={this.handleChangeDate(item.Id)} selected={parseServerDate(item.DateFrom)} />
                  </TableCell>
                  <TableCell>
                    <SimpleInput type={'number'} onChange={this.handleChange(item.Id)} greenBorder error={!item.Rate} value={item.Rate} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TabContent>
        <Button className="mt-3" onClick={this.saveData} type="success">
          Сохранить
        </Button>
      </LoadingOverlay>
    )
  }

  private handleChangeDate = (id: string) => (date: Date, event: any) => {
    if (date && (!event.target.value || /^(\d{2}\.){2}\d{4}$/.test(event.target.value))) {
      const { mfPercent, getAllMFPercentSuccess: updateData } = this.props;
      let data = cloneDeep(mfPercent);
      data = data.map((item) => {
        if (toString(item.Id) === toString(id)) {
          return {
            ...item,
            DateFrom: convertToUtc(date).toISOString(),
            IsUpdated: true,
          }
        }

        return item;
      });
      updateData(data);
    }
  };

  private handleChange = (id: string) => (e: any) => {
    const { target: { value } } = e;
    const { mfPercent, getAllMFPercentSuccess: updateData } = this.props;
    let data = cloneDeep(mfPercent);
    data = data.map((item) => {
      if (toString(item.Id) === toString(id)) {
        return {
          ...item,
          Rate: value,
          IsUpdated: true,
        }
      }

      return item;
    });
    updateData(data);
  };

  private addNewItem = () => {
    const { mfPercent, getAllMFPercentSuccess: updateData } = this.props;
    const currentTabName = this.props.location.hash.replace('#', '') || 'rub';
    const currentTab = TABS_LABELS_MF_PERCENT.get(currentTabName as TabTypeMFPercent);

    const CurrencyId = currentTab ? currentTab.filterData : null;
    const newItem = {
      DateFrom: moment().startOf('day').toISOString(),
      Rate: 0,
      CurrencyId,
      IsNew: true,
      Id: uuid.v1(),
    };
    // @ts-ignore
    updateData([...mfPercent, newItem]);
  };

  private saveData = () => {
    const { mfPercent, saveMFPercentRequest: saveMFPercent } = this.props;
    saveMFPercent(mfPercent);
  }
}

const mapStateToProps = (state: AppState) => ({
  loading: getMFPercenLoading(state),
  mfPercent: getMFPercentData(state),
  isSaved: isSavedMFPercent(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getAllMFPercentRequest,
      getAllMFPercentSuccess,
      saveMFPercentRequest,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MFPercent);
