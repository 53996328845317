import * as React from "react";
import styled from "styled-components";


import LoadingOverlay from "../../../../components/LoadingOverlay";
import { ClientsContext } from "../../pages/Client";
import { FormWrapper } from "../Views";
import ApiClient from "../../../../services/api";
import FormItem from "../../../../components/PartnersAndClients/FormItem";

const VerificationCodeWrapper = styled.div`
  margin-bottom: 15px;
`;
const VerificationCode = styled.div`
  font-weight: bold;
  font-size: 20px;
`;

export default class Videoverification extends React.PureComponent {
  public state: any = {
    src: '',
  };
  public render() {
    return (
      <ClientsContext.Consumer>
        {({
            copyDocuments,
            userData,
            editing,
            handleChangeCheckList = () => void 0,
            checkList,
            valueLabels
          }) => (
          <FormWrapper>
            <LoadingOverlay loading={false}>
              {copyDocuments && userData && checkList && (
                <div>
                  <FormItem
                    key={'VideoVerificationStatus'}
                    disabled={!editing}
                    fieldName={'VideoVerificationStatus'}
                    data={checkList}
                    label={'Видео подтверждает личность пайщика и его паспорт?'}
                    isRequired={false}
                    error={false}
                    valueLabels={valueLabels}
                    onChange={handleChangeCheckList}
                  />
                  <VerificationCodeWrapper>Уникальный код дя верификации: <VerificationCode>{userData.VerificationCode}</VerificationCode></VerificationCodeWrapper>

                  {this.checkVideo(copyDocuments) ?
                  <React.Fragment>
                    <div>Видео клиента: </div>
                    <video
                      id="video"
                      width={600}
                      controls
                      crossOrigin="anonymous"
                      src={this.state.src}
                      data-src={this.findVerification(copyDocuments)}
                    />
                  </React.Fragment> : <div>Клиент не предоставил видео</div>}
                </div>
              )}
            </LoadingOverlay>
          </FormWrapper>
        )}
      </ClientsContext.Consumer>
    );
  }

  private checkVideo = (items: any[]) => {
    const VideoVerification = items.filter((item) => item.DocumentType === 'VideoVerification');
    if (VideoVerification.length) {
      return VideoVerification[0];
    }

    return '';
  };

  private findVerification = (items: any[]) => {
    if (this.state.src || !this.checkVideo(items)) {
      return '';
    }

    this.getBlobVideo(this.checkVideo(items));
  };

  private getBlobVideo = async (item: any) => {
    const { data } = await ApiClient.downloadBlobFile(item.Url);
    if (data) {
      this.setState({
        src: window.URL.createObjectURL(data),
      });
    }

    return '';
  }
}
