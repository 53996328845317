import * as React from "react";
import { HeaderButtonsWrapper, HeaderButtonsItem } from "./Views";
import Button from "../../../components/Button/Button";

interface ClientHeaderButtonsProps {
  isEditing: boolean;
  isCreating: boolean;
  isDisabledSave: boolean;
  isDeleted: boolean;
  handleClickSave: () => void;
  handleClickCancel: () => void;
  handleClickSaveToPartner: () => void;
  handleClickSaveToPartnerRaising: () => void;
  handleRejectApplication: () => void;
  handleDeleteApplication: () => void;
  clientId?: string;
  returnUrl?: string;
  hash?: string;
}

const ClientHeaderButtons: React.SFC<ClientHeaderButtonsProps> = React.memo(
  ({
     isEditing,
     isCreating,
     isDisabledSave,
     clientId,
     handleClickSave,
     handleClickCancel,
     handleClickSaveToPartner,
     handleClickSaveToPartnerRaising,
     handleRejectApplication,
     handleDeleteApplication,
     hash,
     isDeleted
   }) => (
    <HeaderButtonsWrapper>
      {isEditing || isCreating && !isDeleted && (
        <React.Fragment>
          <HeaderButtonsItem>
            <Button
              onClick={handleClickSave}
              disabled={isDisabledSave}
              type="primary"
            >
              Сохранить
            </Button>
          </HeaderButtonsItem>
          <HeaderButtonsItem>
            <Button onClick={handleClickCancel} type="error">
              Оменить
            </Button>
          </HeaderButtonsItem>
        </React.Fragment>)}
      {!isEditing && !isCreating && !isDeleted && (<HeaderButtonsItem>
        <Button
          routerLink
          href={`/applications/edit/${clientId}${hash}`}
          type="primary"
        >
          Редактировать
        </Button>
      </HeaderButtonsItem>)}
      {!isDeleted && <HeaderButtonsItem>
        <Button
          disabled={isEditing || isCreating}
          onClick={handleClickSaveToPartner}
          type="success"
        >
          Принять в пайщики
        </Button>
      </HeaderButtonsItem>}
      {!isDeleted && <HeaderButtonsItem>
        <Button
          disabled={isEditing || isCreating}
          onClick={handleClickSaveToPartnerRaising}
          type="success"
        >
          Принять в ЗП проект
        </Button>
      </HeaderButtonsItem>}
      {!isDeleted && <HeaderButtonsItem>
        <Button
          disabled={isEditing || isCreating}
          onClick={handleRejectApplication}
          type="success"
        >
          Отклонить
        </Button>
      </HeaderButtonsItem>}

      {isDeleted && (<HeaderButtonsItem>
        <Button
          onClick={handleRejectApplication}
          type="primary"
        >
          Восстановить
        </Button>
      </HeaderButtonsItem>)}
      {isDeleted && (<HeaderButtonsItem>
        <Button
          onClick={handleDeleteApplication}
          type="error"
        >
          Удалить навсегда
        </Button>
      </HeaderButtonsItem>)}
    </HeaderButtonsWrapper>
  )
);

export default ClientHeaderButtons;
