import React, { Component } from 'react';
import autoBind from 'react-autobind';
import ProgressBar from 'react-progressbar';
import Button from '../../components/Button/Button';

import './UploadButton.scss';

export default class UploadButton extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      status: 'ok',
      progress: 0,
    };
  }

  progressCallback(event) {
    const progress = event.total ? Math.round((event.loaded * 100) / event.total) : 0;
    this.setState({ progress });
  }

  async uploadFile() {
    try {
      this.setState({ status: 'waiting' });
      this.props.onUploading();
      const data = new FormData();
      data.append('file', this.inputElement.files[0]);
      const result = await this.props.actionUpload(data, this.progressCallback);
      this.props.onSuccess(result, this.inputElement.files[0]);
      this.setState({ status: 'ok' });
    } catch (e) {
      console.error(e);
      this.setState({ status: 'error' });
      this.props.onError(e);
    } finally {
      this.inputElement.value = '';
    }
  }

  render() {
    const { statusClassName } = this.props;
    const { status, progress } = this.state;
    return (
      <div className="upload-button">
        <div className="row">
          <input
            type="file"
            ref={(input) => {
              this.inputElement = input;
            }}
            onChange={this.uploadFile}
            hidden
          />
          <div className="col-5">
            <Button
              loading={status === 'waiting'}
              className="upload-button__button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.inputElement.click();
              }}
            >
              {this.props.name}
            </Button>
          </div>
          <div className={statusClassName || 'col-7'}>
            {status === 'waiting' ? <div>Загрузка: <ProgressBar completed={progress} /></div> : null}
            {status === 'error' ? <div>Не удалось загрузить файл</div> : null}
          </div>
        </div>
      </div>
    );
  }
}
