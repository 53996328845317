import styled, { css } from 'styled-components';
import Flex from 'styled-flex-component';

import { Button as BaseButton, sharedBtnGroup } from '../Button';

const Filter = styled(Flex).attrs({ row: true, justifyStart: true, alignBaseline: true })`
  min-height: 40px;
  width: auto;
  padding: 5px 5px;
  flex-wrap: wrap;
`;

const Label = styled.label`
  flex-basis: 10%;
  flex-shrink: 0;
`;

const Button = styled(BaseButton)`
  border-color: ${({ theme }) => theme.colors.filters.buttonBorder};
  background-color: ${({ theme }) => theme.colors.filters.buttonBg};
  color: ${({ theme }) => theme.colors.filters.textInverted};
  
  &:hover {
    border-color: ${({ theme }) => theme.colors.filters.buttonBorderHovered};
    background-color: ${({ theme }) => theme.colors.filters.buttonBgHovered};
  }
  
  ${({ active }) => active && css`
    border-color: ${({ theme }) => theme.colors.filters.buttonBorderActive};
    background-color: ${({ theme }) => theme.colors.filters.buttonBgActive};
  `}
`;

const BtnGroup = styled.div`
  ${sharedBtnGroup(Button)};
  flex-shrink: 0;
`;

export {
  Filter,
  Label,
  Button,
  BtnGroup,
};
