import {
  FilterFieldName,
  FilterItemData
} from "../../../types/PartnersAndClients";
import { Nullable } from "../../../types/typeUtils";
import { CurrencyId, OperatorType } from '../../../types';

export type ProductTabType =
  | "main"
  | "productRub"
  | "productUsd"
  | "productEur";

export type UserComment = Nullable<string | null>;

export interface PartnerCard {
  PartnerId: number;
  FirstDigits: string;
  LastDigits: string;
  ExpirationDate: string;
  Comment: string;
  Id: number | null;
}

export interface PartnerBankAccount {
  PartnerId: number;
  Bill: string;
  Bank: string;
  Comment: string;
  Id: number | null;
  CurrencyId: CurrencyId;
}

export interface ProductCondition {
  Id: number;
  ProductConditionType: number;
  Percent: number;
  MemberPercent: number;
  SumFrom: number;
  SumTo: number;
  PeriodTo: number;
  PeriodFrom: number;
  Fine: number;
  IsActive: boolean,
  StartDate: string,
  EndDate: string,
  CurrencyId: number,
  orderId: number,
}

export interface productData {
  Id: number;
  Name: string;
  FuncPayId?: number;
  BillTypeId?: number;
  IsActive: boolean;
  IsMain: boolean;
  UseMFPercent: boolean;
  UseCapital: boolean;
  UseProgressive: boolean;
  ProductConditions: ProductCondition[];
}

export type FieldType<T> = string extends keyof T ? keyof T : any;

export interface ProductListItem {
  Id: number;
  Name: string;
  BillTypeId: number;
  CurrencyId: number;
  Currencies: string;
  FuncPayId: number;
  IsActive: boolean;
  IsMain: boolean;
}

export interface FilterRequest {
  Operator: OperatorType;
  SortFieldName: FilterFieldName<ProductListItem>;
  SortDirection: SortDirection;
  CurrentPage: number;
  PageSize: number;
  IsPaging: boolean;
  TotalPages: number;
  FieldInfos: Array<FilterItemData<ProductListItem>>;
}

export type SortDirection = 1 | 2;
