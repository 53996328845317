import React from 'react';
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { withRouter } from 'react-router-dom';
import { get, cloneDeep, remove, omit } from 'lodash';
import { toast } from "react-toastify";
import { Group, GroupAccess } from "../types";
import styled from "../../../styled/styled-components";
import { Checkbox, SimpleInput } from "../../../components/FormControls/FormControls";
import AccessForm from "./AccessForm";
import Button from "../../../components/Button/Button";
import ApiClient from "../../../services/api";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { AppState } from "../../../store";
import { getAccessSectionsTree } from "../../../store/auth";
import TreeAccessForm from "./TreeAccessForm";
import { TabContent, TabLink, TabLinks } from "../../Partner/components/Views";

interface GroupFormProps extends RouteComponentProps{
  groupId: number;
  goToBack: () => void;
  treeAccess: any[];
}

interface GroupFormState extends Group{
  loading: boolean;
  checkedKeys: string[]
}

const OperatorLabel = styled.label`
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
    font-weight: bold;
`;

export type TabType =
  | "main"
  | "sectionRules"
  | "fullRules";

export const TABS_LABELS = new Map<
  TabType,
  {
    label: string;
  }
  >([
  ["main", { label: "Основные" }],
  ["sectionRules", { label: "Права" }],
  ["fullRules", { label: "Расширенные права" }],
]);

class GroupForm extends React.Component<GroupFormProps, GroupFormState> {
  public state: GroupFormState = {
    IsActive: false,
    GroupId: undefined,
    GroupName: '',
    WriteAccess: [],
    ReadAccess: [],
    loading: false,
    WriteSections: [],
    ReadSections: [],
    checkedKeys: [],
  };

  public componentDidMount(): void {
    const { groupId } = this.props;
    if (groupId) {
      this.getData(groupId);
    }
  }

  public render(): React.ReactElement<any> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    const { GroupName, IsActive, WriteAccess, ReadAccess, loading, GroupId } = this.state;
    const { treeAccess } = this.props;

    const tabsEneteries = [...TABS_LABELS.entries()];
    const currentTabName = this.props.location.hash.replace('#', '') || 'main';

    return (
      <LoadingOverlay loading={loading}>
        <TabLinks>
          {tabsEneteries.map(([name, item]) => (
            <TabLink
              key={name}
              href={`${this.props.location.pathname}#${name}`}
              routerLink
              active={
                (!this.props.location.hash && name === 'main') ||
                this.props.location.hash === `#${name}`
              }
              component={
                this.props.location.hash === `#${name}`
                  ? clickableProps => <span {...clickableProps} />
                  : undefined
              }
            >
              {item.label}
            </TabLink>
          ))}
        </TabLinks>
        <TabContent>
          {currentTabName === 'main' && (
            <React.Fragment>
              <div className="row text-right mb-3">
                <div className="col-1">
                  <OperatorLabel>
                    Название группы
                  </OperatorLabel>
                </div>
                <div className="col-11">
                  <SimpleInput onChange={this.handleChange('GroupName')} greenBorder value={GroupName} />
                </div>
              </div>
              <div className="row text-right mb-3">
                <div className="col-1">
                  <OperatorLabel>
                    Активна
                  </OperatorLabel>
                </div>
                <div className="col-11 d-flex align-items-center">
                  <Checkbox onChange={this.handleChangeCheckbox('IsActive')} checked={IsActive} />
                </div>
              </div>
            </React.Fragment>
          )}
          {currentTabName === 'sectionRules' && (
            <div className="row text-right mb-3">
              <div className="col-1">
                <OperatorLabel>
                  Права доступа
                </OperatorLabel>
              </div>
              <div className="col-11 text-left">
                <TreeAccessForm treeAccess={treeAccess} onCheck={this.onCheck} checkedKeys={this.state.checkedKeys} />
              </div>
            </div>
          )}
          {currentTabName === 'fullRules' && (<React.Fragment>
            <div className="row text-right mb-3">
              <div className="col-1">
                <OperatorLabel>
                  Права на запись
                </OperatorLabel>
              </div>
              <div className="col-11">
                <AccessForm
                  SecOperationId={2}
                  selectedAccess={WriteAccess}
                  addNewAccess={this.addNewAccess('WriteAccess')}
                  removeAccess={this.removeAccess('WriteAccess')}
                  removeAllActionsByController={this.removeAllActionsByController('WriteAccess')}
                  addAllActionsByController={this.addAllActionsByController('WriteAccess', 2)}
                />
              </div>
            </div>
            <div className="row text-right mb-3">
              <div className="col-1"/>
              <div className="col-11 text-left">
                <Button type="warning" onClick={this.copyRules}>Скопировать в права на чтение</Button>
              </div>
            </div>
            <div className="row text-right mb-3">
              <div className="col-1">
                <OperatorLabel>
                  Права на чтение
                </OperatorLabel>
              </div>
              <div className="col-11">
                <AccessForm
                  SecOperationId={1}
                  selectedAccess={ReadAccess}
                  addNewAccess={this.addNewAccess('ReadAccess')}
                  removeAccess={this.removeAccess('ReadAccess')}
                  removeAllActionsByController={this.removeAllActionsByController('ReadAccess')}
                  addAllActionsByController={this.addAllActionsByController('ReadAccess', 1)}
                />
              </div>
            </div>
          </React.Fragment>)}
        </TabContent>
        <div className="d-flex mt-3">
          <Button type="success" onClick={this.submit}>{GroupId ? 'Сохранить' : 'Создать'}</Button>
        </div>
      </LoadingOverlay>
    );
  }

  private handleChange = (fieldName: 'GroupName') => (e: any) => {
    const { target: { value } } = e;
    this.setState({
      [fieldName]: value
    });
  };

  private handleChangeCheckbox = (fieldName: 'IsActive') => (e: any) => {
    const { target: { checked } } = e;
    this.setState({
      [fieldName]: checked
    });
  };

  private addNewAccess = (type: 'WriteAccess' | 'ReadAccess') => (item: GroupAccess) => {
    const data = cloneDeep(get(this.state, type));
    remove(data, (access: GroupAccess) => access.ControllerName === item.ControllerName && access.ActionName === item.ActionName);
    // @ts-ignore
    this.setState({
      [type]: [...data, item],
    })
  };

  private removeAccess = (type: 'WriteAccess' | 'ReadAccess') => (item: GroupAccess) => {
    const data = cloneDeep(get(this.state, type));
    const newData = data.map((access: GroupAccess) => {
      if (access.ControllerName === item.ControllerName && access.ActionName === item.ActionName) {
        return {
          ...item,
          IsActive: false,
        }
      }
      return access;
    });
    // @ts-ignore
    this.setState({
      [type]: newData,
    })
  };

  private addAllActionsByController = (type: 'WriteAccess' | 'ReadAccess', SecOperationId: 1 | 2) => async (controller: string) => {
    this.setState({
      loading: true,
    });

    if (!controller) {

      return;
    }
    this.setState({
      loading: true,
    });

    try {
      const actions = await ApiClient.getGroupActionNames(controller);
      const data = cloneDeep(get(this.state, type));
      remove(data, (access: GroupAccess) => access.ControllerName === controller);

      const newData: GroupAccess[] = actions.map((action: string) => ({
        ControllerName: controller,
        ActionName: action,
        SecOperationId,
        IsActive: true,
      }));

      // @ts-ignore
      this.setState({
        [type]: [...data, ...newData],
      })
    } catch (e) {
      toast('Произошла ошибка', {
        type: toast.TYPE.ERROR,
      })
    } finally {
      this.setState({
        loading: false,
      })
    }
  };

  private removeAllActionsByController = (type: 'WriteAccess' | 'ReadAccess') => (controller: string) => {
    const data = cloneDeep(get(this.state, type));

    const newData: GroupAccess[] = data.map((item: GroupAccess) => {
      if (item.ControllerName === controller) {

        return {...item, IsActive: false };
      }

      return item;
    });

    // @ts-ignore
    this.setState({
      [type]: newData,
    })
  };

  private submit = async () => {
    this.setState({
      loading: true,
    });
    if (!this.state.GroupName.trim()) {
      toast('Необходимо заполнить название группы', {
        type: toast.TYPE.ERROR,
      });

      return;
    }
    try {
      const { checkedKeys, GroupId } = this.state;
      const data = omit(this.state, ['loading', 'checkedKeys']);
      data.WriteSections = checkedKeys.filter((item: string) => item.includes('-write')).map((item: string) => parseInt(item, 10));
      data.ReadSections = checkedKeys.filter((item: string) => item.includes('-read')).map((item: string) => parseInt(item, 10));

      await ApiClient.createOrUpdateGroup(data);
      toast(GroupId ? 'Группа успешно обновлена' : 'Группа успешно создана', {
        type: toast.TYPE.SUCCESS,
      });
      setTimeout(this.props.goToBack, 1000);
    } catch (e) {
      toast('Произошла ошибка', {
        type: toast.TYPE.ERROR,
      })
    } finally {
      this.setState({
        loading: false,
      })
    }
  };

  private getData = async (groupId: number) => {
    this.setState({
      loading: true,
    });
    try {
      const result: Group = await ApiClient.getGroupById(groupId);
      const readAccess = result.ReadSections.map((item: number) => `${item}-read`);
      const writeAccess = result.WriteSections.map((item: number) => `${item}-write`);
      this.setState({
        ...result,
        checkedKeys: [...readAccess, ...writeAccess],
      })
    } catch (e) {
      toast('Произошла ошибка', {
        type: toast.TYPE.ERROR,
      })
    } finally {
      this.setState({
        loading: false,
      })
    }
  };

  private copyRules = () => {
    // @ts-ignore
    const writeData: GroupAccess[] = cloneDeep(this.state.WriteAccess).filter((item: GroupAccess) => item.IsActive).map((item: GroupAccess) => ({
      ...item,
      SecOperationId: 1,
      SecurityAccessId: 0,
    }));
    const readData = cloneDeep(this.state.ReadAccess);

    readData.forEach((item: GroupAccess) => {
      remove(writeData, (access: GroupAccess) => item.ControllerName === access.ControllerName && item.ActionName === access.ActionName && access.IsActive)
    });

    this.setState({
      ReadAccess: [...readData, ...writeData],
    })
  }

  private onCheck = (checkedKeys: any) => {
    this.setState({ checkedKeys });
  };
}

const mapStateToProps = (state: AppState) => ({
  treeAccess: getAccessSectionsTree(state),
});
export default withRouter(connect(mapStateToProps)(GroupForm));
