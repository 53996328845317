import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { isNull } from 'lodash';

import { AppState } from "../../store";
import {
  fetchStreetsRequest
} from "../../store/regions/actions";
import Autocomplete from "../Autocomplete";
import { Nullable } from "../../types/typeUtils";
import { StreetResponse } from "../../store/regions";

interface StreetSelectorProps {
  value?: string;
  onChange?: (value: Nullable<string>) => void;
  disabled?: boolean;
  fetchStreets: (query: string, cityId: number) => void;
  streets: StreetResponse;
  cityId?: number;
}

const StreetSelector: React.SFC<StreetSelectorProps> = props => {
    const getItems = (query: string) => {
      if (query.trim().length > 0 && props.cityId) {
        props.fetchStreets(query, props.cityId);
      }
      return props.streets;
    };

    const handleChange = (item: string) => {
      if (props.onChange && !isNull(item)) {
        props.onChange(item);
      }
    };

    const renderItem = (item: string) => {
      return item;
    };

    return (
      <Autocomplete
        onSelectItem={handleChange}
        value={props.value}
        onInputChange={handleChange}
        getItems={getItems}
        disabled={!props.cityId || props.disabled}
        renderItem={renderItem}
      />
    );
  };

const mapStateToProps = (state: AppState) => ({
  streets: state.regions.streets
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchStreets: (query: string, cityId: number) => dispatch(fetchStreetsRequest(query, cityId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StreetSelector);
