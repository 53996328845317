import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Flex from 'styled-flex-component';
import Helmet from 'react-helmet';

import styled from '../../styled/styled-components.ts';

import {
  Amount as SalaryAmount,
  Balance as SalaryBalance,
  Gender as SalaryGender,
  InactiveAccounting as SalaryInactiveAccounting,
  InactiveRefill as SalaryInactiveRefill,
  Partners as SalaryPartners,
} from './Salary';

import {
  Average as DepositsAverage,
} from './Deposits';

import {
  Average as LoansAverage,
  Count as LoansCount,
  Currencies as LoansCurrencies,
  // Duration as LoansDuration,
  Gender as LoansGender,
} from './Loans';

import StyledMenu from '../../components/StyledMenu';
import DepositsGender from '../../modules/Analytics/pages/DepositsGender.tsx';
import DepositsDuration from '../../modules/Analytics/pages/DepositsDuration.tsx';
import DepositsCount from '../../modules/Analytics/pages/DepositsCount.tsx';
import DepositsCurrencies from '../../modules/Analytics/pages/DepositsCurrencies.tsx';
import SalarySums from '../../modules/Analytics/pages/SalarySums.tsx';
import BackLink from '../../components/BackLink';

const menu = [
  {
    title: 'Зарплатный проект',
    url: '/tools/analytics/salary',
    submenu: [
      { title: 'Динамика выручки', url: '/tools/analytics/salary/amount' },
      { title: 'Динамика числа пользователей', url: '/tools/analytics/salary/partners' },
      { title: 'Динамика остатков по счетам', url: '/tools/analytics/salary/balance' },
      { title: 'Остатки по счетам', url: '/tools/analytics/salary/sums' },
      { title: 'Половозрастная характеристика', url: '/tools/analytics/salary/gender' },
      { title: 'Пайщики без движения средств', url: '/tools/analytics/salary/inactive-accounting' },
      { title: 'Пайщики без поступлений', url: '/tools/analytics/salary/inactive-refill' },
    ],
  },
  {
    title: 'Вклады',
    url: '/tools/analytics/deposits',
    submenu: [
      { title: 'Валюты', url: '/tools/analytics/deposits/currencies' },
      { title: 'Средние значения', url: '/tools/analytics/deposits/average' },
      { title: 'Количество продуктов', url: '/tools/analytics/deposits/count' },
      { title: 'Продукты по периодам', url: '/tools/analytics/deposits/duration' },
      { title: 'Половозрастная характеристика', url: '/tools/analytics/deposits/gender' },
    ],
  },
  {
    title: 'Займы',
    url: '/tools/analytics/loans',
    submenu: [
      { title: 'Валюты', url: '/tools/analytics/loans/currencies' },
      { title: 'Средние значения', url: '/tools/analytics/loans/average' },
      { title: 'Количество новых займов', url: '/tools/analytics/loans/count' },
      // { title: 'Продукты по периодам', url: '/tools/analytics/loans/duration' },
      { title: 'Половозрастная характеристика', url: '/tools/analytics/loans/gender' },
    ],
  },
];

class Analytics extends Component {
  render() {
    const { pathname } = this.props.location;
    return (
      <Container>
        <BackLink />
        <Helmet >
          <title>Аналитика</title>
        </Helmet>
        <Header>
          <StyledMenu items={menu} activeId={pathname} />
        </Header>
        <hr />
        <Content>
          <Switch>
            <Route path="/tools/analytics/salary/amount" component={SalaryAmount} />
            <Route path="/tools/analytics/salary/partners" component={SalaryPartners} />
            <Route path="/tools/analytics/salary/balance" component={SalaryBalance} />
            <Route path="/tools/analytics/salary/sums" component={SalarySums} />
            <Route path="/tools/analytics/salary/gender" component={SalaryGender} />
            <Route
              path="/tools/analytics/salary/inactive-accounting"
              component={SalaryInactiveAccounting}
            />
            <Route
              path="/tools/analytics/salary/inactive-refill"
              component={SalaryInactiveRefill}
            />
            <Redirect from="/tools/analytics/salary" to="/tools/analytics/salary/amount" />

            <Route path="/tools/analytics/deposits/currencies" component={DepositsCurrencies} />
            <Route path="/tools/analytics/deposits/average" component={DepositsAverage} />
            <Route path="/tools/analytics/deposits/count" component={DepositsCount} />
            <Route path="/tools/analytics/deposits/duration" component={DepositsDuration} />
            <Route path="/tools/analytics/deposits/gender" component={DepositsGender} />
            <Redirect from="/tools/analytics/deposits" to="/tools/analytics/deposits/currencies" />

            <Route path="/tools/analytics/loans/currencies" component={LoansCurrencies} />
            <Route path="/tools/analytics/loans/average" component={LoansAverage} />
            <Route path="/tools/analytics/loans/count" component={LoansCount} />
            {/* <Route path="/tools/analytics/loans/duration" component={LoansDuration} /> */}
            <Route path="/tools/analytics/loans/gender" component={LoansGender} />
            <Redirect from="/tools/analytics/loans" to="/tools/analytics/loans/currencies" />

            <Redirect from="/tools/analytics" to="/tools/analytics/salary" />
          </Switch>
        </Content>
      </Container>
    );
  }
}

const Container = styled(Flex).attrs({ center: true, full: true, column: true })``;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Content = styled(Flex).attrs({ center: true, full: true, column: true })`
  width: 100%;
`;

const AnalyticsWithRouter = withRouter(Analytics);
export default AnalyticsWithRouter;
