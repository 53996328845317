import * as React from 'react';
import Modal from '../../components/Modal';
import ApiClient from '../../services/api';
import styled from 'styled-components';
import moment from 'moment';
import Button from "../../components/Button/Button";
import { toast } from "react-toastify";

const DETAILS_LABELS = {
  Id: 'Номер операции',
  PartnerNumber: 'Номер пайщика',
  PartnerName: 'ФИО пайщика',
  BillCode: 'Номер счета',
  Direction: 'Вид операции',
  OperationType: 'Операция',
  Sum: 'Сумма',
  Currency: 'Валюта',
  Operator: 'ФИО оператора',
  Date: 'Дата проведения',
  OperationDetails: 'Информация по платежу',
  TypeName: 'Тип платежа',
  RequestId: '№ заявки',
  BankUser: 'Альфа-Банк  \\ Исполнитель',
  BankPaymentNumber: 'Альфа-Банк \\ № платежа',
  BankPaymentDate: 'Альфа-Банк \\ Дата',
  AzonUser: 'АЗОН \\ Исполнитель',
  BankPaymentOrderNumber: 'АЗОН \\ № ведомости',
  AzonDate: 'АЗОН \\ Дата',
  DetailsInfo: 'Информация по платежу',
};

const Label = styled.div`
  display: table-cell;
  padding: 0 10px 10px 0;
  font-weight: bolder;
`;

const Value = styled.div`
  display: table-cell;
  padding: 0 0 10px 10px;
  font-size: 1.1em;
`;

const DetailItem = styled.div`
  display: table-row;
  align-items: baseline;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-height: 250px;
`;

const Column = styled.div`
  display: table;
  margin-right: 15px;
`;

export interface DetailsInfo {
  TypeName: string;
  RequestId: number;
  BankUser: string;
  BankPaymentNumber: number;
  BankPaymentDate: string;
  AzonUser: string;
  BankPaymentOrderNumber: number;
  AzonDate: string;
}

export interface OperationDetails {
  BillCode: string;
  Currency: string;
  Date: string;
  DetailsInfo: string | null;
  Direction: string;
  Id: number;
  OperationDetails: string | null;
  OperationType: string;
  Operator: string;
  PartnerId: string;
  PartnerName: string;
  PartnerNumber: string;
  Sum: number;
}

export interface DetailsModalProps {}

export interface DetailsModalState {
  isOpen: boolean;
  operationDetails: OperationDetails | null;
  loadingPrint: boolean;
}

export default class DetailsModal extends React.Component<DetailsModalProps, DetailsModalState> {
  public state: DetailsModalState = {
    isOpen: false,
    loadingPrint: false,
    operationDetails: null,
  };

  public render() {
    const { operationDetails } = this.state;
    return (
      <Modal
        header="Отчет по операции"
        isOpen={this.state.isOpen}
        onBackgroundClick={this.close}
        onEscapeKeydown={this.close}
        afterClose={this.handleClose}
      >
        <Container>{this.renderDetails()}</Container>
        <div>
          {operationDetails && ['Предоставление займа по Договору займа', 'Пополнение счета займа'].includes(operationDetails.OperationType) &&
          <Button loading={this.state.loadingPrint} type="primary" onClick={this.printRKO}>Печать РКО / ПКО</Button>}
        </div>
      </Modal>
    );
  }

  public open = (operationId?: number) => {
    this.setState({ isOpen: true });
    if (typeof operationId === 'number') {
      this.getOperationDetails(operationId);
    }
  };

  public close = () => {
    this.setState({ isOpen: false });
  };

  private getOperationDetails = async (id: number) => {
    const result = (await ApiClient.getOperationDetail(id)) as OperationDetails;

    const operationDetails = Object.assign({}, result, result.OperationDetails || {});

    delete operationDetails.OperationDetails;

    this.setState({
      operationDetails,
    });
  };

  private printRKO = async () => {
    const { operationDetails } = this.state;
    if (!operationDetails) {
      return ;
    }
    this.setState({
      loadingPrint: true,
    });

    try {
      await ApiClient.printPkoOrRko(operationDetails.Id);
      toast('Успешно', {
        type: toast.TYPE.SUCCESS
      });
    } catch (e) {
      const errorMessage = e.ExceptionMessage || e.Message || "Произошла ошибка. Попробуйте снова";
      toast(errorMessage, {
        type: toast.TYPE.ERROR
      });
    } finally {
      this.setState({
        loadingPrint: false,
      })
    }
  };

  private renderDetails = () => {
    const { operationDetails } = this.state;
    if (!operationDetails) {
      return null;
    }
    const operationDetailsKeys = Object.entries(operationDetails).filter(([key]) => DETAILS_LABELS[key as keyof typeof DETAILS_LABELS]);

    const renderColumn = (items: typeof operationDetailsKeys) =>
      items.map(([key, value]) => {
        const label = DETAILS_LABELS[key as keyof typeof DETAILS_LABELS];

        if (['Date', 'BankPaymentDate', 'AzonDate'].indexOf(key) > -1 && value) {
          value = moment(value).format('DD.MM.YYYY, HH:mm:ss');
        }

        if (key === 'Sum' && value) {
          value = value.toLocaleString();
        }

        return (
          <DetailItem key={key}>
            <Label>{label}:</Label>
            <Value>{value}</Value>
          </DetailItem>
        );
      });

    const median = Math.round(operationDetailsKeys.length / 2);

    return (
      <React.Fragment>
        <Column>{renderColumn(operationDetailsKeys.slice(0, median))}</Column>
        <Column>{renderColumn(operationDetailsKeys.slice(median))}</Column>
      </React.Fragment>
    );
  };

  private handleClose = () => {
    this.setState({ operationDetails: null });
  };
}
