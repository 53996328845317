import * as React from 'react';
import Helmet from 'react-helmet';
import { isNull, isEqual, get,
  filter,
} from 'lodash';
import { RouteComponentProps } from 'react-router';
import { toast } from 'react-toastify';
import { ArrowLeft } from 'styled-icons/fa-solid';
import { Location, UnregisterCallback } from 'history';

import ApiClient from '../../../services/api';
import {
  ProductCondition,
  productData, ProductTabType } from "../types";
import { PARTNER_TABS_LABELS, REQUIRED_FIELDS, BILL_TYPES, MESSAGES } from '../constants';

import { TabContent, TabLink, TabLinks, PartnerInitials } from '../components/Views';
import SectionHeader from '../../../components/SectionHeader';
import Link from '../../../components/Link';
import PartnerHeaderButtons from '../components/PartnerHeaderButtons';
import ConfirmModal from '../../../components/PartnersAndClients/ConfirmModal';
import { showErrorToast } from '../../../components/ErrorToast';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { Dispatch } from "redux";
import { fetchLabelsRequest } from "../../../store/labels/actions";
import { connect } from "react-redux";
import { AppState } from "../../../store";

export interface PartnerProps
  extends RouteComponentProps<{ mode: string; productId?: string }> {
  fetchLabelsRequest: (section: string, billType?: string) => void;
  labels: { [section: string]: Map<number, string> };
}

export interface ProductState {
  productData: productData | null;
  cachedproductData: productData | null;
  isChanged: boolean;
  errors: Map<string, string[]>;
  BillTypeId: Map<number, string>;
  FuncPayId: Map<number, string>;
  loading: boolean;
}

export interface PartnerContextType {
  productData: productData | null;
  editing: boolean;
  isModeEdit: boolean;
  getMaxOrderId?: () => number;
  handleChange?: (
    sectionName: keyof productData | [keyof productData, string] | null,
    fieldName: string,
    value: any,
    additionalData?: any,
  ) => void;
  errors: Map<string, string[]>;

  valueLabels: {
    BillTypeId: Map<number, string>;
    FuncPayId: Map<number, string>;
  };
}

export const PartnerContext = React.createContext<PartnerContextType>({
  productData: null,
  editing: false,
  isModeEdit: false,
  errors: new Map(),
  valueLabels: {
    BillTypeId: BILL_TYPES,
    FuncPayId: BILL_TYPES,
  }
});

const getFullNameWithInitials = (data?: productData | null): string => {
  if (!data) {
    return '';
  }

  return `${data.Name}`;
};

class Product extends React.Component<PartnerProps, ProductState> {
  public state: ProductState = {
    productData: null,
    cachedproductData: null,
    isChanged: false,
    errors: new Map(),
    BillTypeId: BILL_TYPES,
    FuncPayId: BILL_TYPES,
    loading: false
  };

  private confirmCancelModal = React.createRef<ConfirmModal>();
  private confirmSaveModal = React.createRef<ConfirmModal>();

  private transitionBlocker: UnregisterCallback | null = null;

  public componentDidMount() {
    this.handleNavigate();

    if (!this.transitionBlocker) {
      this.transitionBlocker = this.props.history.block(this.handleChangeLocation);
    }

    switch (this.props.match.params.mode) {
      case 'create':
        this.getInitialProductData();
        break;

      case 'copy':
        this.copyProductData();
        break;

      default:
        this.getProductData();
        break;
    }
  }

  public componentDidUpdate(prevProps: PartnerProps, prevState: ProductState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handleNavigate();
    }

    if (!isEqual(this.props.labels.FUNC_PAY, this.state.FuncPayId)) {
      this.setState({ FuncPayId: this.props.labels.FUNC_PAY });
      if (this.props.labels.FUNC_PAY) {
        // tslint:disable-next-line:no-shadowed-variable
        // @ts-ignore
        this.setState(prevState => ({ productData: { ...prevState.productData, FuncPayId: this.props.labels.FUNC_PAY.has(prevState.productData && prevState.productData.FuncPayId) ? prevState.productData.FuncPayId : undefined, } }));

      }
    }

    const { fetchLabelsRequest } = this.props;
    if (get(this.state, 'productData.BillTypeId') && !isEqual(get(this.state, 'productData.BillTypeId'), get(prevState, 'productData.BillTypeId'))) {
      fetchLabelsRequest('FUNC_PAY', get(this.state, 'productData.BillTypeId'));
    }
  }

  public componentWillUnmount() {
    if (this.transitionBlocker) {
      this.transitionBlocker();
    }
  }

  public componentDidCatch(error: Error) {
    showErrorToast({ Message: error.message });
  }

  public render() {
    const tabsEneteries = [...PARTNER_TABS_LABELS.entries()];
    const currentTabName = this.props.location.hash.replace('#', '') || 'main';
    const currentTab = PARTNER_TABS_LABELS.get(currentTabName as ProductTabType);

    const Component = currentTab ? currentTab.component : null;

    return (
      <PartnerContext.Provider
        value={{
          productData: this.state.productData,
          editing: this.isEditing() && !this.state.loading,
          isModeEdit: this.isModeEdit(),
          handleChange: this.handleChange,
          getMaxOrderId: this.getMaxOrderId,
          errors: this.state.errors,
          valueLabels: {
            BillTypeId: this.state.BillTypeId,
            FuncPayId: this.state.FuncPayId,
          }
        }}
      >
        <LoadingOverlay loading={this.state.loading}>
          <Helmet defer={false}>
            <title>
              {this.getProductName()}
            </title>
          </Helmet>
          <SectionHeader
            heading={this.getProductName()}
            link={this.renderBackLink()}
            content={<PartnerInitials>{getFullNameWithInitials(this.state.productData)}</PartnerInitials>}
          >
            {this.renderHeaderButtons()}
          </SectionHeader>
          <TabLinks>
            {tabsEneteries.map(([name, item]) => (
              <TabLink
                key={name}
                href={`${this.props.location.pathname}#${name}`}
                routerLink
                active={
                  (!this.props.location.hash && name === 'main') ||
                  this.props.location.hash === `#${name}`
                }
                component={
                  this.props.location.hash === `#${name}`
                    ? clickableProps => <span {...clickableProps} />
                    : undefined
                }
                error={this.isTabHasErrors(name)}
              >
                {item.label}
              </TabLink>
            ))}
          </TabLinks>
          <TabContent>{Component && <Component />}</TabContent>
          <ConfirmModal
            message="Вы действительно хотите отменить изменения?"
            successLabel="Да"
            cancelLabel="Нет"
            onConfirm={this.handleConfirmCancel}
            ref={this.confirmCancelModal}
          />
          <ConfirmModal
            message="Сохранить изменения?"
            successLabel="Да"
            cancelLabel="Нет"
            onConfirm={this.handleConfirmSave}
            ref={this.confirmSaveModal}
          />
        </LoadingOverlay>
      </PartnerContext.Provider>
    );
  }

  private getMaxOrderId = () =>  {
    const data = this.state.productData;
    const productCondition = data && data.ProductConditions;
    let maxOrder = 0;
    if (productCondition) {
      productCondition.forEach((condition: ProductCondition) => {
        maxOrder = condition.orderId > maxOrder ? condition.orderId : maxOrder;
      });
    }

    return maxOrder + 1;
  };

  private getProductData = async () => {
    const { productId } = this.props.match.params;

    this.setState({ loading: true });
    try {
      const result = await ApiClient.getProduct(productId);
      result.ProductConditions = result.ProductConditions.map((item: any, index: number) => ({...item, orderId: index}));
      this.handleFetchedData(result);
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };


  private copyProductData = async () => {
    const { productId } = this.props.match.params;

    this.setState({ loading: true });
    try {
      const result = await ApiClient.copyProduct(productId);
      result.ProductConditions = result.ProductConditions.map((item: any, index: number) => ({...item, orderId: index}));
      this.handleFetchedData(result);
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private getProductName = () => {
    switch (this.props.match.params.mode) {
      case 'create':
        return 'Создание Продукт';

      case 'info':
        return `Информация о продукте`;

      case 'copy':
        return `Копировать продукт`;

      case 'edit':
        return `Редактирование продукта`;
    }
  };

  private handleFetchedData = (result: productData) => {
    this.setState({
      productData: result,
      cachedproductData: result,
      isChanged: false,
      errors: new Map()
    });
  };

  private goToList = () => {
    this.props.history.replace('/Product');
  };

  private renderBackLink = () => (
    <Link onClick={this.goToList}>
      <ArrowLeft size={13} /> Вернуться к списку
    </Link>
  );

  private renderHeaderButtons = () => {
    const returnUrl = encodeURIComponent(this.props.location.pathname);
    const { productId, mode } = this.props.match.params;
    const { hash } = this.props.location;
    const isDisabledSave = !this.state.isChanged && this.props.match.params.mode !== 'create';

    return (
      <PartnerHeaderButtons
        isDisabledSave={isDisabledSave}
        isEditing={mode === 'edit'}
        isCreating={mode === 'create' || mode === 'copy'}
        returnUrl={returnUrl}
        productId={productId}
        hash={hash}
        handleClickSave={this.handleClickSave}
        handleClickCancel={this.handleClickCancel}
      />
    );
  };

  private handleChange = (
    sectionName: keyof productData | [keyof productData, string] | null,
    fieldName: string,
    value: any,
    additionalData?: any,
  ) => {

    const { productData } = this.state;
    if (!productData) {
      return;
    }

    if (Array.isArray(sectionName)) {
      this.setState((state) => {
        const currentProductData = state.productData;

        if (!currentProductData) {
          return state;
        }

        const currentSection = currentProductData[sectionName[0]];

        if (!currentSection || typeof currentSection !== 'object') {
          return state;
        }

        return {
          ...state,
          productData: {
            ...currentProductData,
            [sectionName[0]]: {
              ...currentSection,
              [sectionName[1]]: {
                [fieldName]: value
              }
            }
          },
          isChanged: true
        };
      });

      return;
    }

    if (sectionName && (productData[sectionName] || isNull(productData[sectionName]))) {
      this.setState(state => ({
        productData: {
          ...state.productData!,
          [sectionName]: {
            ...(state.productData![sectionName] as { [key: string]: any }),
            [fieldName as string]: value
          }
        },
        isChanged: true
      }));

      return;
    }

    if (fieldName === 'ProductConditions') {
      const data = filter([...productData.ProductConditions], (item: ProductCondition) => (item.ProductConditionType !== additionalData.ProductConditionType || item.CurrencyId !== additionalData.CurrencyId));
      const newData = filter([...value], (item: ProductCondition) => (item.ProductConditionType === additionalData.ProductConditionType && item.CurrencyId === additionalData.CurrencyId));
      this.setState(state => ({
        productData: {
          ...state.productData!,
          [fieldName as string]: [
            ...data,
            ...newData
          ]
        },
        isChanged: true
      }));

      return;
    }

    if (productData.hasOwnProperty(fieldName)) {
      this.setState(state => ({
        productData: {
          ...state.productData!,
          [fieldName as string]: value
        },
        isChanged: true
      }));
    }
  };

  private handleClickCancel = () => {
    const confirmModal = this.confirmCancelModal.current;
    const isCreate = this.props.match.params.mode === 'create';
    const { isChanged } = this.state;

    if (!isChanged && !isCreate) {
      this.goToInfo();
    }

    if ((isChanged || isCreate) && confirmModal) {
      confirmModal.open();
    }
  };

  private resetChanges = () => {
    this.setState(
      state => ({
        productData: state.cachedproductData,
        isChanged: false
      }),
      this.goToInfo
    );
  };

  private goToInfo = (after?: 'create' | 'update') => {
    const { productData } = this.state;

    if (!productData) {
      return;
    }

    const partnerNumber = productData.Id;
    if (after === 'create' && partnerNumber) {
      this.props.history.replace(`/Product/info/${partnerNumber}`, {
        after: 'create'
      });
    } else {
      this.props.history.replace(
        this.props.location.pathname.replace('edit', 'info'),
        after === 'update' && { after: 'update' }
      );
    }
  };

  private handleClickSave = () => {
    if (
      (this.state.isChanged || this.props.match.params.mode === 'create') &&
      this.confirmSaveModal.current
    ) {
      this.confirmSaveModal.current.open();
    }
  };

  private save = async () => {
    const errors = this.validate();

    if (errors.size) {
      this.setState({ errors });
      showErrorToast({ Message: 'Заполните обязательные поля' });
      return;
    }

    try {
      this.setState({ loading: true });
      this.handleFetchedData(await ApiClient.createOrUpdateProduct(
        this.state.productData,
        ['create', 'copy'].includes(this.props.match.params.mode)
      ));
      this.goToInfo(['create', 'copy'].includes(this.props.match.params.mode) ? 'create' : 'update');
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private getInitialProductData = async () => {
    this.setState({ loading: true });
    try {
      this.setState({
        productData: {
          Id: 0,
          Name: '',
          FuncPayId: undefined,
          BillTypeId: undefined,
          IsActive: true,
          IsMain: true,
          UseMFPercent: false,
          UseProgressive: false,
          UseCapital: false,
          ProductConditions: [],
        }

      })
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private isEditing = () => this.props.match.params.mode === 'create' || this.props.match.params.mode === 'edit' || this.props.match.params.mode === 'copy';
  private isModeEdit = () => this.props.match.params.mode === 'edit';

  private handleConfirmCancel = () => {
    if (this.props.match.params.mode === 'edit') {
      this.resetChanges();
    }

    if (this.props.match.params.mode === 'create' || this.props.match.params.mode === 'copy') {
      this.goToList();
    }
  };

  private handleConfirmSave = () => {
    this.save();
  };

  private handleChangeLocation = (location: Location) => {
    if (
      (!this.props.location.state && location.state) ||
      this.props.location.pathname === location.pathname
    ) {
      return;
    }
    if (this.state.isChanged) {
      return 'Вы действительно хотите отменить изменения?';
    }

    if (this.props.match.params.mode === 'create') {
      return 'Вы действительно хотите отменить изменения?';
    }
  };

  private validate = () => {
    const { productData } = this.state;
    const requiredFieldsErrors = new Map<string, string[]>();

    if (!productData) {
      return requiredFieldsErrors;
    }

    const getSection = (name: string) => {
      switch (name) {
        case 'productData':
          return productData;

        default:
          return productData[name as keyof productData];
      }
    };

    for (const item of REQUIRED_FIELDS.entries()) {
      const [sectionName, fields] = item;
      const errorFields: string[] = [];

      fields.forEach((fieldName) => {
        const section = getSection(sectionName);

        if (section && !section[fieldName as keyof typeof section]) {
          errorFields.push(fieldName);
        }
      });

      if (errorFields.length) {
        requiredFieldsErrors.set(sectionName, errorFields);
      }
    }

    return requiredFieldsErrors;
  };

  private isTabHasErrors = (name: ProductTabType) => {
    switch (name) {
      case 'main':
        return !!this.state.errors.get('productData');

      default:
        return false;
    }
  };

  private handleNavigate = () => {
    const { state } = this.props.location;
    // tslint:disable-next-line:no-console
    if (typeof state !== 'object') {
      return;
    }

    switch (state.after) {
      case 'create':
      case 'client':
        toast(MESSAGES.createdSuccess, {
          type: toast.TYPE.SUCCESS
        });
        break;

      case 'update':
        toast(MESSAGES.updateSuccess, {
          type: toast.TYPE.SUCCESS
        });
    }

    this.props.history.replace({ ...this.props.location, state: undefined });
  };
}

const mapStateToProps = (state: AppState) => ({
  labels: state.labels.labels
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchLabelsRequest: (section: string, billType?: string) => dispatch(fetchLabelsRequest(section, billType)),
});

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Product)
