import { Reducer } from "redux";
import { ActionTypes } from "./actions";
import { GlobalState } from "./types";
import { omit } from "lodash";

const initialState: GlobalState = {
  errors: {}
};

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.PUT_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.meta]: action.payload
        }
      };

    case ActionTypes.DELETE_ERROR:
      return {
        ...state,
        errors: omit(state.errors, [action.meta])
      };

    case ActionTypes.CLEAR_ERRORS:
      return {
        ...state,
        errors: {}
      };

    default:
      return state;
  }
};

export { reducer as globalReducer };
