import React, { Component } from 'react';

import './AlertTemplate.scss';

export default class AlertTemplate extends Component {
  render() {
    // the style contains only the margin given as offset
    // options contains all alert given options
    // message is the alert message...
    // close is a function that closes the alert
    const { style, options, message, close } = this.props;

    return (
      <div
        className={`alert-template alert-template_${options.type}`}
        role="presentation"
        style={style}
        onClick={close}
      >
        <div className="alert-template__icon">
          {options.type === 'success' && <i className="fa fa-check" />}
          {options.type === 'error' && <i className="fa fa-exclamation-triangle" />}
        </div>
        <div className="alert-template__message">{message}</div>
      </div>
    );
  }
}
