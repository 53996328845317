import React from 'react';
// @ts-ignore
import { MessageList } from "../../../../components/ReactChatElements";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import { MessagesList } from "../../../../store/chat";

interface MessageListBlockProps {
  messages: MessagesList[];
  loading: boolean;
  activeChatId: number;
}

export default class MessageListBlock extends React.Component<MessageListBlockProps> {

  public render() {
    const { messages, loading, activeChatId } = this.props;

    return (
      <LoadingOverlay loading={loading}>
        <MessageList
          className={`message-list ${activeChatId === 1 ? 'message-list-full' : ''}`}
          lockable={true}
          toBottomHeight={'100%'}
          downButton={true}
          dataSource={messages} />
      </LoadingOverlay>
    )
  }
}
