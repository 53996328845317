import * as React from "react";
import ApiClient from "../../services/api";

type ItemMap = Map<number, string>;

type ResponseType = Array<{ Value: number; Text: string }>;
export interface RegionsProviderProps {
  children: (
    data: {
      countries: ItemMap;
      regions: ItemMap;
      cities: ItemMap;
    },
    loading: boolean
  ) => React.ReactNode;
  countryId?: number;
  regionId?: number;
  cityId?: number;
}

export interface RegionsProviderState {
  countries: ItemMap;
  regions: ItemMap;
  cities: ItemMap;
  loading: boolean;
}

export default class RegionsProvider extends React.PureComponent<
  RegionsProviderProps,
  RegionsProviderState
> {
  private static cancellationReason = "cancellation";

  public state = {
    countries: new Map(),
    regions: new Map(),
    cities: new Map(),
    loading: false
  };
  private cancellationTokens: { [key: string]: (reason: string) => void } = {};
  public componentDidMount() {
    this.getCountries();
  }

  public componentDidUpdate(prevProps: RegionsProviderProps) {
    const { countryId, regionId } = prevProps;

    if (
      typeof this.props.countryId === "number" &&
      this.props.countryId !== countryId
    ) {
      this.getRegions();
    }

    if (
      typeof this.props.regionId === "number" &&
      this.props.regionId !== regionId
    ) {
      this.getCities();
    }
  }
  public render() {
    const { countries, regions, cities, loading } = this.state;
    const data = {
      countries,
      regions,
      cities
    };

    return this.props.children(data, loading);
  }

  private getCountries = () => {
    // const token = "countries";
    // this.request(ApiClient.getCountries, "countries").then(data =>
    //   this.handleRequest(token, data as ResponseType)
    // );
  };

  private getRegions = () => {
    if (typeof this.props.countryId !== "number") {
      return;
    }

    const token = "regions";

    this.request(
      ApiClient.getRegions.bind(null, this.props.countryId),
      token
    ).then(data => this.handleRequest(token, data as ResponseType));
  };

  private getCities = () => {
    if (typeof this.props.regionId !== "number") {
      return;
    }

    const token = "cities";

    this.request(
      ApiClient.getCities.bind(null, this.props.regionId),
      token
    ).then(data => this.handleRequest(token, data as ResponseType));
  };

  private handleRequest = (token: string, data: ResponseType) => {
    const serializedData = this.serializeData(data);
    switch (token) {
      case "countries":
        this.setState({
          countries: serializedData,
          regions: new Map(),
          cities: new Map()
        });

        this.getRegions();
        break;

      case "regions":
        this.setState({
          regions: serializedData,
          cities: new Map()
        });

        this.getCities();
        break;

      case "cities":
        this.setState({
          cities: serializedData
        });
        break;
    }
  };

  private startLoading = () => this.setState({ loading: true });
  private stopLoading = () => this.setState({ loading: false });

  private request = async (
    request: () => Promise<ResponseType>,
    token: string
  ) => {
    this.startLoading();

    if (this.cancellationTokens[token]) {
      this.cancellationTokens[token](RegionsProvider.cancellationReason);
    }

    return Promise.race([
      request(),
      new Promise(
        (_resolve, reject) => (this.cancellationTokens[token] = reject)
      )
    ])
      .then(result => {
        delete this.cancellationTokens[token];
        this.stopLoading();
        return result;
      })
      .catch(error => {
        delete this.cancellationTokens[token];
        this.stopLoading();
        if (error !== RegionsProvider.cancellationReason) {
          Promise.reject(error);
        }
      });
  };

  private serializeData = (data: ResponseType) => {
    const result = new Map<number, string>();

    if (data) {
      for (const item of data) {
        result.set(item.Value, item.Text);
      }
    }

    return result;
  };
}
