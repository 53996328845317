import * as React from "react";
import styled from "styled-components";
import { isEmpty } from 'lodash';
import Button, { ButtonProps } from "../../../../components/Button/Button";
import { Heading } from "../../../../components/Typography";
import { connect } from "react-redux";
import { AppState } from "../../../../store";
import { ActiveRow } from "../../../../store/forms";
import Modal from "../../../../components/Modal";
import LoanTable from "./LoanTable";
import ApiClient from "../../../../services/api";
import BackLink from "../../../../components/BackLink";

const Container = styled.header`
  margin: -24px -24px 0;
  padding: 14px 15px 7px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #e7eaec;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const HeaderButton = styled(Button)`
  margin-left: 5px;
`;

interface ListHeaderProps {
  printButton: React.ReactNode,
  activeRow?: ActiveRow,
}

export interface Data {
  Id: number;
  PartnerNumber: string;
  BillCode: string;
  FullName: string;
  Sum: string;
  CurrencyId: number;
}

interface ListHeaderState {
  activeTodayLoanCheckBalance: boolean,
  activeTodayLoanAutoPayLate: boolean,
  dataCheck?: Data[],
  dataPayLate?: Data[],
}

class ListHeader extends React.Component<ListHeaderProps, ListHeaderState>{
  public state = {
    activeTodayLoanCheckBalance: false,
    activeTodayLoanAutoPayLate: false,
    dataCheck: [],
    dataPayLate: [],
  };

  public componentDidMount(): void {
    this.getData();
  }

  public render() {
    const { activeRow, printButton } = this.props;
    const { activeTodayLoanCheckBalance, activeTodayLoanAutoPayLate, dataCheck, dataPayLate } = this.state;
    const returnUrl = `${encodeURIComponent(document.location.pathname)}${encodeURIComponent(document.location.search)}`;
    const operationUrl = `/Operation/OperationProcess?returnUrl=${returnUrl}`;
    return (
      <Container>
        <BackLink title={document.title} >
          <Heading>Займы</Heading>
        </BackLink>
        <ButtonsWrapper>
          <HeaderButton onClick={this.toogleTodayLoanModal}  href="#" type="success">
            !
          </HeaderButton>
          <HeaderButton onClick={this.toogleTodayLoanAutoPayLate} href="#" type="warning">
            !
          </HeaderButton>
          <HeaderButton href="/Reports/LoanLates" type="error">
            !
          </HeaderButton>
          <HeaderButton href="/Loan/CreateLoan" routerLink type="success">
            Добавить
          </HeaderButton>
          <HeaderButton href={activeRow ? `${operationUrl}&partnerNumber=${activeRow.PartnerNumber}&billCode=${activeRow.BillCode}` : `${operationUrl}` } routerLink type="success">
            Операции
          </HeaderButton>
          {printButton}
        </ButtonsWrapper>
        <Modal
          isOpen={activeTodayLoanCheckBalance}
          header={"Отчеты о проверках"}
          onBackgroundClick={this.toogleTodayLoanModal}
        >
          {dataCheck && !isEmpty(dataCheck) ? <LoanTable data={dataCheck} /> : 'Сегодня нет отчетов о проверках'}
        </Modal>

        <Modal
          isOpen={activeTodayLoanAutoPayLate}
          header={"Отчеты о просрочках"}
          onBackgroundClick={this.toogleTodayLoanAutoPayLate}
        >
          {dataPayLate && !isEmpty(dataPayLate) ? <LoanTable data={dataPayLate} /> : 'Сегодня нет отчетов о просрочках'}
        </Modal>
      </Container>
    );
  }

  private getData = async () => {
    const dataCheck = await ApiClient.getTodayLoanAutoPayLate();
    const dataPayLate = await ApiClient.getTodayLoanCheckBalance();
    this.setState({
      dataCheck, dataPayLate
    })
  };

  private toogleTodayLoanModal = () => {
    this.setState(state => ({
      activeTodayLoanCheckBalance: !state.activeTodayLoanCheckBalance,
    }))
  };

  private toogleTodayLoanAutoPayLate = () => {
    this.setState(state => ({
      activeTodayLoanAutoPayLate: !state.activeTodayLoanAutoPayLate,
    }))
  };
}


export class PrintButton extends React.Component<ButtonProps> {
  public render() {
    return (
      <HeaderButton {...this.props} type="success">
        Печать
      </HeaderButton>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  // @ts-ignore
  activeRow: state.forms.tableForm.activeRow,
});

export default connect(mapStateToProps)(ListHeader)
