import React from 'react';
import { isEqual } from 'lodash';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { ArrowLeft } from "styled-icons/fa-solid";
import Link from "../Link";
import styled from "../../styled/styled-components";
import { getArrayFromLocalStorage, saveArrayToLocalStorage } from "../../utils";

export const HISTORY_ARR = 'history';

const LinkBlock = styled(Link)`
  display: block;
`;

export interface DefaultUrl {
  url: string;
}

interface BackLinkProps extends RouteComponentProps{
  default?: DefaultUrl,
  title?: string,
}

class BackLink extends React.Component<BackLinkProps> {

  public state: any = {
    history: [],
  }

  public componentDidMount(): void {
    this.addToHistory();

    this.checkDocumentTitle();

  }

  public componentDidUpdate(prevProps: Readonly<BackLinkProps>, prevState: Readonly<{}>, snapshot?: any): void {

    if (!isEqual(this.props.location, prevProps.location)) {
      this.addToHistory();
    }
  }

  checkDocumentTitle = () => {
    // @ts-ignore
    let timerId: NodeJS.Timeout = undefined;
    if (document.title === 'SM Group') {
      timerId = setInterval(() => {
        if (document.title === 'SM Group') {

          return;
        }
        this.addToHistory();
        clearInterval(timerId);

      }, 1000)
    }
  }

  public render() {
    const lastUrl = this.getPrevUrl();
    return (
      <div>
        {this.props.children}
        {lastUrl && <LinkBlock onClick={this.goToRoute(lastUrl.url)}>
          <ArrowLeft size={13}/> Вернуться к "{lastUrl.title}"
        </LinkBlock>}
      </div>
    )
  }

  private getUrl = () => {
    const { location: { pathname, search, hash } } = this.props;

    return { url: `${pathname}${search}${hash}`, title: document.title};
  }

  private getPrevUrl = () => {
    const history = getArrayFromLocalStorage(HISTORY_ARR);
    if (history.length > 1) {
      const lastHistoryItem = history[history.length - 2];

      return lastHistoryItem;
    }

    const { default: defUrl } = this.props;
    if (defUrl) {

      return defUrl;
    }

    return null;
  }

  private addToHistory = () => {
    const history = getArrayFromLocalStorage(HISTORY_ARR);
    if (history.length === 0) {
      history.push(this.getUrl());
      this.setState({ history });
      saveArrayToLocalStorage(history, HISTORY_ARR);

      return;
    }
    const currentUrl = this.getUrl();
    const lastHistoryItem = history[history.length - 1];

    if (currentUrl.url === lastHistoryItem.url) {

      if (currentUrl.title !== lastHistoryItem.title) {
        history[history.length - 1] = currentUrl;
        this.setState({ history });
        saveArrayToLocalStorage(history, HISTORY_ARR);
      }

      return;
    }

    if (currentUrl.url !== lastHistoryItem.url && lastHistoryItem.url.includes(this.props.location.pathname)) {
      history[history.length - 1] = currentUrl;
      this.setState({ history });
      saveArrayToLocalStorage(history, HISTORY_ARR);

      return;
    }


    history.push(this.getUrl());
    this.setState({ history });
    saveArrayToLocalStorage(history, HISTORY_ARR);

    return;
  }

  private goToRoute = (url: string) => () => {
    const history = getArrayFromLocalStorage(HISTORY_ARR);
    history.splice(-1, 2);
    saveArrayToLocalStorage(history, HISTORY_ARR);
    this.props.history.replace(url);
  }
}

export default withRouter((props: BackLinkProps) => <BackLink {...props}/>);
