import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { get, isEmpty, isString, isEqual } from 'lodash';
import { toast } from "react-toastify";
import qs from 'query-string';

import LoanDetail from './LoanDetail';
import RaisingDetail from './RaisingDetail';
import DepositDetail from './DepositDetail';
import CurrentDetail from './CurrentDetail';
import PensionDetail from './PensionDetail';
import SalariesDetail from './SalariesDetail';
import ShareDetail from './ShareDetail';

import { AppState } from "../../../store";
import { fetchBillDetailsRequest } from "../../../store/billDetails";
import LoadingOverlay from "../../../components/LoadingOverlay";
import ErrorMessage from "../../Errors/components/ErrorMessage";


export interface BillDetailsProps {
  fetchBillDetailsRequest: (data: string) => void;
  loading: boolean;
  Type: number;
  error: any;
}

class BillDetail extends PureComponent<RouteComponentProps & BillDetailsProps> {

  public componentDidMount(): void {
    // tslint:disable-next-line:no-shadowed-variable
    const { match, fetchBillDetailsRequest, location } = this.props;
    const code = get(match, 'params.code');
    if (!code) {
      const params = qs.parse(location.search);
      if (params.code && isString(params.code)) {
        fetchBillDetailsRequest(params.code);
      }
    } else {
      fetchBillDetailsRequest(code);
    }
  }

  public componentDidUpdate(prevProps: BillDetailsProps): void {
    // tslint:disable-next-line:no-shadowed-variable
    const { match: newMatch, fetchBillDetailsRequest } = this.props;
    // @ts-ignore
    const { match } = prevProps;
    let code = get(match, 'params.code');
    if (!code) {
      const params = qs.parse(location.search);
      if (params.code && isString(params.code)) {
        code = params.code;
      }
    }

    let newCode = get(newMatch, 'params.code');
    if (!newCode) {
      const paramsNew = qs.parse(location.search);
      if (paramsNew.code && isString(paramsNew.code)) {
        newCode = paramsNew.code;
      }
    }

    if (!isEqual(code, newCode)) {
      fetchBillDetailsRequest(newCode);
    }
  }

  public render() {
    const { match, history, location, staticContext, loading, Type, error } = this.props;

    if (!isEmpty(error)) {
      return (
        <div className="alert alert-danger mt-3 d-print-none">
          <ErrorMessage errorData={{ message: (error && error.Message) || 'Ошибка', verbose: (error && error.ExceptionMessage) || 'Кажется имеет место быть досадная ошибка.... Попробуйте обновить страницу'}} />
        </div>
      );
    }

    if (loading || !Type) {
      return (<LoadingOverlay loading={loading} />)
    }

    switch (Type) {
      case 1:
        return (
          // Текущие
          // @ts-ignore
          <CurrentDetail match={match} history={history} location={location} staticContext={staticContext} />
          );
      case 2:

        return (
          // Паенакопления
          // @ts-ignore
          <ShareDetail match={match} history={history} location={location} staticContext={staticContext} />
          );
      case 3:
        return(
          // СП
          // @ts-ignore
          <DepositDetail match={match} history={history} location={location} staticContext={staticContext} />
        );
      case 4:
        return(
          // Займы
          // @ts-ignore
          <LoanDetail match={match} history={history} location={location} staticContext={staticContext} />
        );
      case 5:
        return(
          // Зарплатные
          // @ts-ignore
          <SalariesDetail match={match} history={history} location={location} staticContext={staticContext} />
        );
      case 7:
        return(
          // ИЗ
          // @ts-ignore
          <RaisingDetail match={match} history={history} location={location} staticContext={staticContext} />
        );
      case 9:
        return(
          // Пенсионные и детские
          // @ts-ignore
          <PensionDetail match={match} history={history} location={location} staticContext={staticContext} />
        );
      default:
        toast("Произошла ошибка. Попробуйте снова", {
          type: toast.TYPE.ERROR
        });
        // history.goBack();
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  loading: state.billDetails.loading,
  Type: get(state.billDetails, 'data.Type.Value', ''),
  error: get(state.billDetails, 'error'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchBillDetailsRequest,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(BillDetail);
