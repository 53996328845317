import * as React from "react";
import styled from "styled-components";
import Helmet from 'react-helmet';
import { RouteComponentProps } from "react-router";

import FilterConfigurator from "../../../../components/BillTables/FilterConfigurator";
import { showErrorToast } from "../../../../components/ErrorToast";
import TableList from "../../../../components/BillTables/TableList";
import Pagination from "../../../../components/Pagination/Pagination";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import { requestDataToQuery } from "../../../../utils";
import { Heading } from "../../../../components/Typography";

import {
  DEFAULT_FILTER_FIELDS,
  DEFAULT_SORT_FIELD_NAME,
  DEFAULT_SORT_DIRECTION,
  AUTOCOMPLETE_FILTER_FIELDS,
  LATE_CURRENT_FIELD_LABELS
} from "../constants";
import {
  ReportListItem,
  FilterFieldName,
  FilterRequest,
  OperatorType,
  FilterItemData
} from "../types";
import ApiClient from "../../../../services/api";
import BackLink from "../../../../components/BackLink";

const Container = styled.header`
  margin: -24px -24px 0;
  padding: 14px 15px 7px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid #e7eaec;
`;

export interface BillListProps extends RouteComponentProps {
}

export interface BillListState {
  bills: ReportListItem[];
  filters: FilterRequest;
  cachedFilters: FilterRequest;
  visibleFields: Map<FilterFieldName<ReportListItem>, boolean>;
  filtersShown: boolean;
  fieldFilters: Map<FilterFieldName<ReportListItem>, FilterItemData>;
  autocompleteItems: {
    [fieldName in FilterFieldName<ReportListItem>]?: string[]
  };
  loading: boolean;
}

const DEFAULT_FILTERS = {
  CurrentPage: 1,
  FieldInfos: [
    {
      FieldName: "PartnerNumber" as FilterFieldName<ReportListItem>,
      Method: 1,
      Value: null,
      ValueType: 1
    },
    {
      FieldName: "PartnerName" as FilterFieldName<ReportListItem>,
      Value: null,
      Method: 1,
      ValueType: 1
    },
    {
      FieldName: "BillCode" as FilterFieldName<ReportListItem>,
      Value: null,
      Method: 1,
      ValueType: 1
    },
    {
      FieldName: "ProductId" as FilterFieldName<ReportListItem>,
      Value: null,
      Method: 1,
      ValueType: 1
    },
    {
      FieldName: "Percent" as FilterFieldName<ReportListItem>,
      Value: null,
      Method: 1,
      ValueType: 2
    },
    {
      FieldName: "MemberFee" as FilterFieldName<ReportListItem>,
      Value: null,
      Method: 1,
      ValueType: 2
    },
    {
      FieldName: "CurrentBalance" as FilterFieldName<ReportListItem>,
      Value: null,
      Method: 4,
      ValueType: 7
    },
    {
      FieldName: "CurrencyId" as FilterFieldName<ReportListItem>,
      Value: null,
      Method: 0,
      ValueType: 5
    },
    {
      FieldName: "FuncPayId" as FilterFieldName<ReportListItem>,
      Value: null,
      Method: 0,
      ValueType: 5
    },

    {
      FieldName: "OpeningDate" as FilterFieldName<ReportListItem>,
      Value: null,
      Method: 4,
      ValueType: 4
    },

    {
      FieldName: "IsActive" as FilterFieldName<ReportListItem>,
      Value: null,
      Method: 0,
      ValueType: 3
    },

  ],
  IsPaging: true,
  Operator: 1 as OperatorType,
  PageSize: 25,
  SortDirection: DEFAULT_SORT_DIRECTION,
  SortFieldName: DEFAULT_SORT_FIELD_NAME,
  TotalPages: 1
};

const getDefaultVisibleFields = (
  map: Map<FilterFieldName<ReportListItem>, FilterItemData>
) => {
  const result = new Map<FilterFieldName<ReportListItem>, boolean>();

  map.forEach(item => {
    result.set(item.FieldName, true);
  });

  return result;
};

export default class LatePaymentsReports extends React.Component<
  BillListProps,
  BillListState
> {
  public state: BillListState = {
    bills: [],
    visibleFields: getDefaultVisibleFields(DEFAULT_FILTER_FIELDS),
    filtersShown: false,
    filters: DEFAULT_FILTERS,
    cachedFilters: DEFAULT_FILTERS,
    fieldFilters: DEFAULT_FILTER_FIELDS,
    autocompleteItems: {},
    loading: false
  };

  private listFieldLabels = LATE_CURRENT_FIELD_LABELS;
  // @ts-ignore
  private billsTableElement = React.createRef<TableList<ReportListItem>>();

  public componentDidMount() {
    this.getData();
  }

  public render() {
    return (
      <LoadingOverlay loading={this.state.loading}>
        <Helmet defer={false}>
          <title>Просроченные платежи</title>
        </Helmet>
        <Container>
          <BackLink title={document.title} >
            <Heading>Просроченные платежи</Heading>
          </BackLink>
        </Container>

        <FilterConfigurator
          labels={this.listFieldLabels}
          fields={this.state.visibleFields}
          handleChangeColumns={this.handleChangeColumns}
          filtersShown={this.state.filtersShown}
          toggleFiltersVisible={this.toggleFiltersVisible}
          handleChangeOperator={this.handleChangeOperator}
          operatorId={this.state.filters.Operator}
          resetAllFilters={this.resetAllFilters}
          acceptFilter={this.acceptFilter}
        />
        <TableList
          data={this.state.bills}
          visibleFields={this.state.visibleFields}
          createLink={this.createLink}
          sortProps={{
            sortFieldName: this.state.filters.SortFieldName,
            sortDirection: this.state.filters.SortDirection,
            onChangeSort: this.handleChangeSort
          }}
          filtersProps={{
            fields: [...this.state.fieldFilters.values()],
            onChangeFilter: this.handleChangeFilter,
            acceptFilter: this.acceptFilter,
            resetFilter: this.resetFilter,
            filtersShown: this.state.filtersShown,
            autocompleteItems: this.state.autocompleteItems
          }}
          ref={this.billsTableElement}
          labelsSection={'LATE_CURRENT_FIELD_LABELS'}
        />
        {this.state.filters.TotalPages > 1 && (
          <Pagination
            size={this.state.filters.PageSize}
            count={this.state.filters.TotalPages * this.state.filters.PageSize}
            page={this.state.filters.CurrentPage}
            callback={this.handleChangePage}
          />
        )}
      </LoadingOverlay>
    );
  }

  private getData = async (
    filter: Partial<FilterRequest> = {},
    forceGoToDetails?: boolean
  ) => {
    const queryString = requestDataToQuery({
      SortFieldName: this.state.filters.SortFieldName,
      SortDirection: this.state.filters.SortDirection,
      CurrentPage: this.state.filters.CurrentPage,
      PageSize: this.state.filters.PageSize,
      IsPaging: this.state.filters.IsPaging,
      ...filter
    });

    this.setState({ loading: true });
    try {
      const result = await ApiClient.getLoanLates(queryString);

      if (forceGoToDetails && result.DataSource.length === 1) {
        this.setState({ loading: false });
      }

      this.setState({
        bills: result.DataSource,
        filters: result.FilterRequest,
        cachedFilters: result.FilterRequest,
        fieldFilters: this.extractFieldFilters(result.FilterRequest)
      });
    } catch (error) {
      showErrorToast(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  private handleChangeColumns = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: columnName, checked } = event.target;

    this.setState(state => {
      const filterFieldName = columnName as FilterFieldName<ReportListItem>;
      const newVisibleFields = new Map(state.visibleFields);

      newVisibleFields.set(filterFieldName, checked);

      return {
        visibleFields: newVisibleFields
      };
    });
  };

  private toggleFiltersVisible = () => {
    this.setState(state => ({ filtersShown: !state.filtersShown }));
  };

  private handleChangeOperator = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(event.target.value, 10);

    this.setState(state => ({
      filters: {
        ...state.filters,
        Operator: value as OperatorType
      }
    }));
  };

  private handleChangeFilter = (
    filterItemData: FilterItemData,
    forceFiltering?: boolean
  ) => {
    forceFiltering = forceFiltering || filterItemData.ValueType === 4 || filterItemData.ValueType === 5
    let forceGoToDetails = false;
    if (forceFiltering && filterItemData.FieldName === "PartnerNumber") {
      forceGoToDetails = true;
    }

    this.setState(
      state => {
        const newFilters = new Map(state.fieldFilters);
        const currentFilterItem = newFilters.get(filterItemData.FieldName);

        if (currentFilterItem) {
          newFilters.set(filterItemData.FieldName, filterItemData);
        }

        return {
          fieldFilters: newFilters,
          filters: {
            ...state.filters,
            FieldInfos: this.getFieldInfos(newFilters)
          }
        };
      },
      () => {
        this.proccessAutocomplete(filterItemData);
        if (forceFiltering) {
          this.acceptFilter(forceGoToDetails);
        }
      }
    );
  };

  private acceptFilter = (forceGoToDetails?: boolean) => {
    const { FieldInfos, Operator } = this.state.filters;

    this.getData(
      {
        Operator,
        FieldInfos: FieldInfos.filter(item => item && item.Value !== null),
        CurrentPage: 1
      },
      forceGoToDetails
    );
  };

  private resetAllFilters = () => {
    this.getData();
  };

  private extractFieldFilters = (filterRequest: FilterRequest) => {
    const fieldsData = new Map(DEFAULT_FILTER_FIELDS);

    filterRequest.FieldInfos.forEach(item => {
      if (fieldsData.get(item.FieldName)) {
        fieldsData.set(item.FieldName, item);
      }
    });

    return fieldsData;
  };

  private getFieldInfos = (
    fieldFilters: Map<FilterFieldName<ReportListItem>, FilterItemData>
  ) => {
    return [...fieldFilters.values()];
  };

  private handleChangeSort = (fieldNamme: FilterFieldName<ReportListItem>) => {
    this.setState(
      state => {
        const currentSortFieldName = state.filters.SortFieldName;
        const curretSortDirection = state.filters.SortDirection;

        const newSortDirection =
          currentSortFieldName !== fieldNamme
            ? 1
            : curretSortDirection === 1
            ? 2
            : 1;

        return {
          filters: {
            ...state.filters,
            SortFieldName: fieldNamme,
            SortDirection: newSortDirection
          }
        };
      },
      () => {
        this.getData({
          Operator: this.state.filters.Operator,
          FieldInfos: this.state.filters.FieldInfos.filter(
            item => item && item.Value !== null
          )
        });
      }
    );
  };

  private proccessAutocomplete = async (filterItemData: FilterItemData) => {
    const fieldName = filterItemData.FieldName;
    const value = filterItemData.Value;

    if (AUTOCOMPLETE_FILTER_FIELDS.indexOf(fieldName) === -1) {
      return;
    }

    if (!value || value.length < 2) {
      this.setState({
        autocompleteItems: {
          [fieldName]: []
        }
      });

      return;
    }

    const {
      FieldInfos,
      Operator,
      SortFieldName,
      // tslint:disable-next-line:no-shadowed-variable
      SortDirection
    } = this.state.filters;

    const queryString = requestDataToQuery({
      SortFieldName,
      SortDirection,
      Operator,
      FieldInfos: FieldInfos.filter(item => item && item.Value !== null),
      propertyName: fieldName
    });

    try {
      this.setState({
        autocompleteItems: {
          [fieldName]: await ApiClient.getAutocompleteForClients(queryString)
        }
      });
    } catch (error) {
      showErrorToast(error);
    }
  };

  private createLink = (item: ReportListItem, key: string) => {
    if (key === 'PartnerNumber') {
      return {
        link: this.getLinkToPartner(item),
        fieldName: "PartnerNumber" as keyof ReportListItem
      };
    }
    return {
      link: this.getLinkToNumberCode(item),
      fieldName: "BillCode" as keyof ReportListItem
    };
  };

  private resetFilter = (fieldName: keyof ReportListItem) => {
    const filterItemData = DEFAULT_FILTERS.FieldInfos.find(
      ({ FieldName }) => FieldName === fieldName
    );

    if (filterItemData) {
      this.handleChangeFilter(filterItemData, true);
    }
  };

  private handleChangePage = (page: number) => {
    this.getData({
      Operator: this.state.filters.Operator,
      FieldInfos: this.state.filters.FieldInfos.filter(
        item => item && item.Value !== null
      ),
      CurrentPage: page
    });
  };

  private getLinkToPartner = (item: ReportListItem) => {
    return `/client/Info/${item.PartnerNumber}`;
  };

  private getLinkToNumberCode = (item: ReportListItem) => {
    return `/Bill/Details/${item.BillCode}`;
  };
}
