import React from 'react';
import styled from '../../../styled/styled-components';

import Modal from '../../../components/Modal';
import { IRequisitesInfo } from '../../../store/paymentRequests';

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.defaultFont}
`;
const Title = styled.div`
  flex-basis: 40%;
  font-weight: bold;
  padding: 5px 5px 5px 0;
`;
const Value = styled.div`
  padding: 5px 5px 5px 0;
`;

interface RequisitesModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: number | null;
  requisites: IRequisitesInfo | null;
}

const defaultValue = '----';

const RequisitesModal = ({ id, requisites, isOpen, onClose }: RequisitesModalProps) => id && requisites && (
  <Modal
    header={`Реквизиты заявки ${id}`}
    isOpen={isOpen}
    onBackgroundClick={onClose}
    onEscapeKeydown={onClose}
  >
    <Line><Title>Сумма</Title><Value>{requisites.Sum}</Value></Line>
    <Line><Title>Дата создания</Title><Value>{requisites.CreationTime}</Value></Line>
    <Line><Title>Наименование получателя</Title><Value>{requisites.TargetName}</Value></Line>
    <Line><Title>БИК банка получателя</Title><Value>{requisites.BIK}</Value></Line>
    <Line><Title>Банк получателя</Title><Value>{requisites.BankName}</Value></Line>
    <Line><Title>Корреспондентский счет банка получателя</Title><Value>{requisites.BankBill}</Value></Line>
    <Line><Title>ИНН</Title><Value>{requisites.INN ? requisites.INN : defaultValue}</Value></Line>
    <Line><Title>КПП</Title><Value>{requisites.KPP ? requisites.KPP : defaultValue}</Value></Line>
    <Line><Title>Сумма</Title><Value>{requisites.TargetBill}</Value></Line>
    <Line><Title>Код (для ЖКХ)</Title><Value>{requisites.CodeHCS}</Value></Line>
    <Line><Title>Назначение перевода</Title><Value>{requisites.Reason}</Value></Line>
  </Modal>
) || null;

export default RequisitesModal;
