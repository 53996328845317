import { ExecServerResponse } from './types';
import { requestDataToQuery } from '../../utils';

export const navigateToOperationExec = (data: ExecServerResponse) => {
  if (window && document) {
    const query = requestDataToQuery({
      ...data,
      returnUrl: document.location.pathname,
    });

    const origin = process.env.NODE_ENV === 'development' ? 'http://admin.m-test.online' : '';
    const pathName = '/Operation/OperationProcess';
    document.location.replace(`${origin + pathName}?${query}`);
  }
};
